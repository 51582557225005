import minutesToName from 'fsd/shared/lib/minutesToName';

import { FormatTime } from '../model/types';

const parseFormatTime = (format: FormatTime) => {
  const timeInTopName = minutesToName(format.time_in_top_minutes);
  const timeInFeedName = minutesToName(format.time_in_feed_minutes);
  return {
    timeInTopName,
    timeInFeedName,
    with: {
      short: `${timeInTopName?.short ?? ''}/${timeInFeedName?.short ?? ''}`,
      long: `${timeInTopName?.long ?? ''}/${timeInFeedName?.long ?? ''}`
    }
  };
};

export default parseFormatTime;
