export type MediaQueryString = `(${string}: ${string})`;

class MediaQuery {
  value;

  type;

  mediaQuery;

  constructor(type: string, value: string) {
    this.value = value;
    this.type = type;
    this.mediaQuery = this.createMediaQuery(type, value);
  }

  createMediaQuery(type: string, value: string): MediaQueryString {
    return `(${type}: ${value})`;
  }

  [Symbol.toPrimitive](hint: 'string' | 'number' | 'default'): MediaQueryString | number {
    if (hint === 'string') {
      return this.toString();
    }
    return NaN;
  }

  toString(): MediaQueryString {
    return this.mediaQuery;
  }
}

export default MediaQuery;
