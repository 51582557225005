import styled from 'styled-components';

export const Body = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.alpineGoat};
`;

export const BodyTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
`;

export const BodyBlock = styled.div`
  margin-top: 24px;
`;
