import { RefObject } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';

const adjustTableHeight = (tableRef: RefObject<any>, virtualHeight: number) => {
  if (!tableRef.current) return;

  // calculate the height for the pseudo element after the table
  const existingPseudoElement = window.getComputedStyle(tableRef.current, '::after');
  const existingPseudoHeight = parseFloat(existingPseudoElement.height) || 0;
  const tableHeight = tableRef.current.clientHeight - existingPseudoHeight;
  const pseudoHeight = Math.max(virtualHeight - tableHeight, 0);
  document.documentElement.style.setProperty('--pseudo-height', `${pseudoHeight}px`);
  return pseudoHeight;
};

export const useScrollHeaderSticky = (
  parentRef: RefObject<HTMLDivElement>,
  tableHeight: number,
  virtualItems: VirtualItem[]
) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [isScrollNearBottom, setIsScrollNearBottom] = useState(false);

  const handlePseudoResize = useCallback(() => {
    return adjustTableHeight(tableRef, tableHeight);
  }, [tableRef, tableHeight]);

  // callback to handle scrolling, checking if we are near the bottom
  const handleScroll = useCallback(() => {
    if (parentRef.current) {
      const scrollPosition = parentRef.current?.scrollTop;
      const visibleHeight = parentRef.current?.clientHeight;
      setIsScrollNearBottom(scrollPosition > tableHeight * 0.95 - visibleHeight);
    }
  }, [parentRef, tableHeight]);

  // add an event listener on the scrollable parent container and resize the
  // pseudo element whenever the table renders with new data
  useEffect(() => {
    const scrollable = parentRef.current;
    if (scrollable) scrollable.addEventListener('scroll', handleScroll);
    handlePseudoResize();

    return () => {
      if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, handlePseudoResize]);

  // if we are near the bottom of the table, resize the pseudo element each time
  // the length of virtual items changes (which is effectively the number of table
  // rows rendered to the DOM). This ensures we don't scroll too far or too short.
  useEffect(() => {
    if (isScrollNearBottom) handlePseudoResize();
  }, [isScrollNearBottom, virtualItems.length, handlePseudoResize]);

  return {
    tableRef
  };
};
