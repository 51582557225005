import htmlToPDFMake from 'html-to-pdfmake';
import { createPdf, TCreatedPdf } from 'pdfmake/build/pdfmake';

const fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  }
};

export type CreatePdfFromHTMLResult = TCreatedPdf;

export default (html: string): CreatePdfFromHTMLResult | undefined => {
  try {
    const data = htmlToPDFMake(html, {
      tableAutoSize: true
    });
    return createPdf({ content: data }, undefined, fonts);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
