type Item = {
  year: number;
  avaliableMonths: number[];
};

export interface IListViewCalendar {
  value?: string;
  onChange?: (date: string) => void;
  showOneMonth?: boolean;
  dates?: Item[];
}

export const MONTH_MAP = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

export const DEFAULT_DATES = [
  {
    year: 2023,
    avaliableMonths: range(1, new Date().getMonth() + 1)
  },
  {
    year: 2022,
    avaliableMonths: range(1, 12)
  },
  {
    year: 2021,
    avaliableMonths: range(1, 12)
  }
];

export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  return [{ year, avaliableMonths: [month] }];
};

export const isSelectedItem = (value: string | undefined, year: number, month: number) => {
  if (!value) {
    return false;
  }
  const [y, m] = value.split('-');

  if (year === +y && month === +m) {
    return true;
  }

  return false;
};

function range(start: number, end: number) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}
