import styled from 'styled-components';

export const Root = styled.div<{ editableChannel?: boolean }>`
  width: 263px;
  height: 48px;
  border-radius: 12px 12px 0px 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 10px;
  gap: 4px;
  color: ${({ theme }) => theme.blackRock};
  & + .ant-tabs-tab-remove {
    top: 50% !important;
    right: 10px !important;
    transform: translateY(-50%) !important;
  }
`;

export const Name = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 114%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
