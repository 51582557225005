import { useLocation, useNavigate } from 'react-router-dom';
import { blockNames } from 'fsd/shared/lib/helpers/constants/channelConstants';
import { cutNum, getIcon } from 'fsd/shared/lib/helpers/tools';
import { CompactChart } from 'fsd/shared/ui/CompactChart';
import { EmptyLine } from 'fsd/shared/ui/EmptyLine';

import { MetaProps } from '../../types';

import * as S from '../../styles';

const InfoBlock = ({
  totalCount,
  title,
  er,
  today,
  yesterday,
  week,
  month,
  numbersColor,
  minusColor,
  icon,
  compact,
  graphData,
  chartRef,
  showTotalCount,
  hideToday
}: MetaProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateFn = () => {
    if (compact) {
      navigate(-1);
    } else {
      navigate(title, { state: { background2: location } });
    }
  };

  return (
    <S.BlockContainer onClick={navigateFn}>
      <S.InfoContainer compact={compact}>
        <S.BlockHeader>
          <S.GridContainer>
            <div style={{ height: compact ? 17 : 34 }}>
              <div style={{ position: 'absolute' }}>
                <S.BlockHeaderStyle compact={compact}>
                  <S.BlockHeaderCount compact={compact}>
                    <span>{showTotalCount ? cutNum(totalCount) : <EmptyLine />}</span>
                  </S.BlockHeaderCount>
                  <S.BlockHeaderDesc>
                    {getIcon(icon)}
                    <span>{blockNames[title]}</span>
                  </S.BlockHeaderDesc>
                </S.BlockHeaderStyle>
              </div>
            </div>
            {er ? (
              <>
                <div />
                <S.ErContainer compact={compact} $end>
                  <S.ErCount compact={compact}>{er ? er.erd : 0}</S.ErCount>
                  <S.ErDescription>ERD</S.ErDescription>
                </S.ErContainer>
                <S.ErContainer compact={compact} $end>
                  <S.ErCount compact={compact}>{er ? er.erm : 0}</S.ErCount>
                  <S.ErDescription>ERM</S.ErDescription>
                </S.ErContainer>
              </>
            ) : (
              <>
                <div />
                <div />
                <div />
              </>
            )}

            {/* Первая строка */}
            {!hideToday && (
              <S.CountContainer>
                <S.Count color={(today && today > 0) || !minusColor ? numbersColor : minusColor}>
                  {today ? cutNum(today) : <EmptyLine />}
                </S.Count>
                <S.CountDescription>Сегодня</S.CountDescription>
              </S.CountContainer>
            )}
            <S.CountContainer>
              <S.Count color={yesterday > 0 || !minusColor ? numbersColor : minusColor}>
                {yesterday ? cutNum(yesterday) : <EmptyLine />}
              </S.Count>
              <S.CountDescription>Вчера</S.CountDescription>
            </S.CountContainer>
            <S.CountContainer>
              <S.Count color={week > 0 || !minusColor ? numbersColor : minusColor}>
                {week ? cutNum(week) : <EmptyLine />}
              </S.Count>
              <S.CountDescription>Неделя</S.CountDescription>
            </S.CountContainer>
            <S.CountContainer>
              <S.Count color={month > 0 || !minusColor ? numbersColor : minusColor}>
                {month ? cutNum(month) : <EmptyLine />}
              </S.Count>
              <S.CountDescription>Месяц</S.CountDescription>
            </S.CountContainer>
          </S.GridContainer>
        </S.BlockHeader>
      </S.InfoContainer>
      {!compact && (
        <div>
          <CompactChart data={graphData} chartref={chartRef} wrapperHeight={75} graphWidth={87} />
        </div>
      )}
    </S.BlockContainer>
  );
};

export default InfoBlock;
