import { InfoCircleOutlined } from '@ant-design/icons';
import { CreativeInviteLinkField, CreativePreviewModal } from 'fsd/entities/creative';
import { ReplaceCreative } from 'fsd/features/replaceCreative';
import { IconAttach } from 'fsd/shared/icons';
import { copyURL } from 'fsd/shared/lib/helpers/tools';
import { IMessageData } from 'fsd/shared/ui/ChatMessage/types';
import { LinkField } from 'fsd/shared/ui/LinkField';

import * as S from './styles';

const data: IMessageData = {
  media: [
    {
      src: 'https://pm1.aminoapps.com/7182/c6d7ba86d1dffc15418243339440f5b0a5ee7660r1-655-509v2_00.jpg',
      type: 'image'
    },
    {
      src: 'https://pm1.aminoapps.com/7182/c6d7ba86d1dffc15418243339440f5b0a5ee7660r1-655-509v2_00.jpg',
      type: 'image'
    },
    {
      src: 'https://pm1.aminoapps.com/7182/c6d7ba86d1dffc15418243339440f5b0a5ee7660r1-655-509v2_00.jpg',
      type: 'image'
    }
  ]
};

// TODO: принимать ссылку пропсом, добавить erid
const ModalFooter = () => {
  return (
    <>
      <CreativeInviteLinkField url="https://t.me/+lBY272Au2pRmOWJi" />
      <ReplaceCreative />
    </>
  );
};

const Playground = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <button type="button" onClick={handleModalOpen}>
        modal
      </button>
      <CreativePreviewModal
        open={openModal}
        data={data}
        footer={<ModalFooter />}
        onClose={handleModalClose}
      />
    </>
  );
};

export default Playground;
