import { FC } from 'react';
import { Tooltip } from 'antd';
import {
  ChannelWithExchangeInfo,
  usePatchV1UserChannelMutation,
  usePostV1UserChannelVerificationByChannelIdMutation
} from 'api/api';
import { createUsitefulClassName } from 'fsd/shared/modules/usiteful';

import scenarios from './scenarios';

import * as S from './ButtonSwitcher.styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

const ButtonSwitcher: FC<IProps> = ({ channel }) => {
  const dispatch = useAppDispatch();

  const [updateChannel, { isLoading: isUpdateFetching }] = usePatchV1UserChannelMutation();
  const [sendVerification, { isLoading: isVerificationLoading }] =
    usePostV1UserChannelVerificationByChannelIdMutation();

  const scenario = useMemo(
    () =>
      scenarios.find((scenario) => {
        const res = scenario.conditionFn({ channel });
        return res;
      }),
    [channel]
  );

  const handleClick = useCallback(() => {
    if (scenario) {
      scenario.action?.({ updateChannel, dispatch, channel, sendVerification });
    }
  }, [channel, dispatch, updateChannel, sendVerification, scenario]);

  return (
    <Tooltip title={scenario?.tooltip?.title} placement="topLeft">
      <div>
        <S.Root
          disabled={scenario?.button?.disabled || isUpdateFetching || isVerificationLoading}
          type={scenario?.button?.type || 'primary'}
          size="small"
          block
          onClick={handleClick}
          className={createUsitefulClassName(
            'management',
            'channel',
            'active-with-verify-button',
            scenario?.key?.toString()
          )}
        >
          {scenario?.button?.children || 'Опубликовать канал'}
        </S.Root>
      </div>
    </Tooltip>
  );
};

export default memo(ButtonSwitcher);
