/* eslint-disable react/no-array-index-key */
import { FC, ReactNode } from 'react';
import { BalanceInfo } from 'fsd/entities/balance';
import { BalanceTypes } from 'fsd/entities/balance/model/types';

import * as S from './Card.styles';

type Item = {
  key: string;
  type: BalanceTypes;
  value: number;
};

type Props = {
  items: Item[];
  button?: ReactNode;
};

const Card: FC<Props> = ({ items, button }) => {
  return (
    <S.Root>
      <S.InfoGrid>
        {items.map(({ key, ...item }) => (
          <BalanceInfo key={key} {...item} />
        ))}
      </S.InfoGrid>
      {button && <S.ButtonWrapper>{button}</S.ButtonWrapper>}
    </S.Root>
  );
};

export default Card;
