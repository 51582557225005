import { Form as _Form } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root: typeof _Form = styled(_Form)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 26px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 16px;
  }
`;
