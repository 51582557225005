import { FC } from 'react';
import { Status } from 'api/api';
import {
  CreativeInviteLinkField,
  CreativePreviewModalOpen,
  isCanReplaceCreative
} from 'fsd/entities/creative';
import { createPostTelegramLink, PostViewModalOpen } from 'fsd/entities/post';
import { ReplaceCreative } from 'fsd/features/replaceCreative';
import { SendPurchaseCreativeButton } from 'fsd/features/sendPurchaseCreative';
import { Button } from 'fsd/shared/ui/Buttons';
import { OutlinedButton } from 'fsd/shared/ui/Buttons/OutlinedButton';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';

import { DefaultColumnCellProps } from '../../../types';
import { SendCreativeToBot } from 'fsd/features/sendCreativeToBot';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  const channelId = purchase.channel.info?.id;
  const channelName = purchase.channel.info?.name;
  const telegramMessageId = purchase.telegram_message_id;
  const isHavePost = channelId && telegramMessageId;
  if (isHavePost) {
    return (
      <PostViewModalOpen
        channelId={channelId}
        postId={telegramMessageId}
        actions={{
          inviteLink: <CreativeInviteLinkField url={purchase.invite_link} />,
          view: channelName && telegramMessageId && (
            <Button
              size="large"
              type="primary"
              target="_blank"
              href={createPostTelegramLink(channelName, telegramMessageId)}
            >
              Посмотреть пост в Telegram
            </Button>
          )
        }}
      />
    );
  }

  if (purchase.status === 'post_requested') {
    return (
      <OutlinedButton block startIcon={<LoaderSpinner color="currentColor" />} disabled>
        Креатив
      </OutlinedButton>
    );
  }

  if (purchase.status === ('new' as Status)) {
    return <SendPurchaseCreativeButton purchaseId={purchase.purchase_id} />;
  }
  return (
    <CreativePreviewModalOpen
      purchaseId={purchase.purchase_id}
      actions={{
        inviteLink: <CreativeInviteLinkField url={purchase.invite_link} />,
        replace: purchase.status && isCanReplaceCreative(purchase.status) && (
          <ReplaceCreative purchaseId={purchase.purchase_id} />
        ),
        sendToBot: <SendCreativeToBot purchaseId={purchase.purchase_id} />
      }}
    />
  );
};

export default memo(Cell);
