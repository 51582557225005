import { Channel, ChannelsRead } from 'api/api';
import { ISiteInfo } from 'fsd/shared/lib/getSiteInfo';
import { useDebouncedCallback } from 'use-debounce';

import { FIND } from '../../config/find';
import createBase64ChannelIcon from '../createBase64ChannelIcon';

import useFindByTgNameOrTgLink from './useFindByTgNameOrTgLink';
import useFindExternalLink from './useFindExternalLink';
import useLink, { LinkType } from './useLink';

export type FindItem = {
  key: string;
  channelId?: string;
  name: string;
  link?: string;
  icon?: string;
  type: LinkType;
};

type Error = {
  input: boolean | string;
  message: string;
};

const channelToItem = (channel: Channel): FindItem => {
  return {
    key: channel.id,
    channelId: channel.id,
    name: channel.title,
    link: channel.link,
    icon: channel.icon_url && createBase64ChannelIcon(channel.icon_url),
    type: 'tg_adv'
  };
};

const siteInfoToItem = (siteInfo: ISiteInfo): FindItem => {
  return {
    key: siteInfo.link,
    icon: siteInfo.favicon,
    name: siteInfo.name,
    link: siteInfo.link,
    type: 'external_adv'
  };
};

type DebouncedPromiseCallbacks<T> = {
  success?: (data: T) => void;
  finally?: () => void;
};

export default () => {
  const [selectedItem, setSelectedItem] = useState<FindItem | undefined>();
  const { link: value, set: setValue, type, getType } = useLink();
  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    channels,
    find: findByNameOrLink,
    isFetching: isFetchingTgNameOrTgLink
  } = useFindByTgNameOrTgLink();
  const {
    find: findExternalLink,
    siteInfo: externalLinkInfo,
    isFetching: isFetchingExternalLink,
    isFound: isFoundExternalLink
  } = useFindExternalLink();

  const isFetching = isFetchingTgNameOrTgLink || isFetchingExternalLink;

  const isNotFound = useMemo(
    () =>
      value.length >= FIND.MIN_LINK_OR_NAME_LENGTH &&
      type === 'tg_adv' &&
      !isLoading &&
      !channels?.length,
    [value.length, type, isLoading, channels?.length]
  );

  const findByNameOrLinkDebounced = useDebouncedCallback(
    (value: string, callbacks?: DebouncedPromiseCallbacks<ChannelsRead | undefined>) => {
      findByNameOrLink(value)
        ?.then((res) => {
          callbacks?.success?.(res);
        })
        .finally(() => callbacks?.finally?.());
    },
    400
  );

  const findExternalLinkDebounced = useDebouncedCallback(
    (link: string, callbacks?: DebouncedPromiseCallbacks<ISiteInfo | undefined>) => {
      findExternalLink(link)
        .then((res) => callbacks?.success?.(res))
        .finally(() => callbacks?.finally?.());
    },
    400
  );

  const set = useCallback(
    // eslint-disable-next-line consistent-return
    (value: string, autoClear?: boolean) => {
      setValue(value);
      switch (getType(value)) {
        case 'tg_adv': {
          setIsLoading(true);
          findByNameOrLinkDebounced(value, { finally: () => setIsLoading(false) });
          if (autoClear) {
            setSelectedItem(undefined);
          }
          break;
        }
        case 'link-tg_adv': {
          setIsLoading(true);
          findByNameOrLinkDebounced(value, {
            success: (channels) => {
              if (!channels?.length) {
                setSelectedItem({
                  key: value,
                  icon: undefined,
                  link: value,
                  name: value,
                  type: 'tg_adv'
                });
              } else if (autoClear) {
                setSelectedItem(undefined);
              }
            },
            finally: () => setIsLoading(false)
          });
          break;
        }
        case 'external_adv': {
          setIsLoading(true);
          findExternalLinkDebounced(value, {
            finally: () => {
              setIsLoading(false);
            }
          });
          setSelectedItem(undefined);
          break;
        }
        default:
          break;
      }
    },
    [setValue, getType, findByNameOrLinkDebounced, findExternalLinkDebounced]
  );

  const items = useMemo(() => {
    switch (type) {
      case 'tg_adv': {
        return channels?.map(channelToItem);
      }
      case 'link-tg_adv': {
        return channels?.map(channelToItem);
      }
      case 'external_adv': {
        return externalLinkInfo ? [siteInfoToItem(externalLinkInfo)] : [];
      }
      default:
        return [];
    }
  }, [channels, type, externalLinkInfo]);

  // eslint-disable-next-line consistent-return
  const error = useMemo((): Error | undefined => {
    if (value.length && value.length < FIND.MIN_LINK_OR_NAME_LENGTH) {
      return {
        message: 'Введите хотя бы три символа',
        input: false
      };
    }
    if (
      type === 'external_adv' &&
      !isLoading &&
      !isFoundExternalLink &&
      value.length >= FIND.MIN_LINK_OR_NAME_LENGTH
    )
      return {
        message: 'Такой страницы не существует. Проверьте ссылку',
        input: true
      };
  }, [isLoading, isFoundExternalLink, type, value.length]);

  return {
    value,
    set,
    type,
    items,
    isNotFound,
    isFetching,
    isLoading,
    setSelectedItem,
    selectedItem,
    isFoundExternalLink,
    error,
    setIsEdited,
    isEdited
  };
};
