import { useRef, useState } from 'react';
import { Id, useGetV1ChannelsByChannelIdQuery } from 'api/api';
import { Chart } from 'chart.js';
import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { getFormatsByChannel } from 'fsd/entities/channel';
import { useGetUserChannelFormatsQuery } from 'fsd/entities/format';
import { createNow } from 'fsd/shared/lib/helpers/tools/dates';
import { LoadButton } from 'fsd/shared/ui/Buttons';

import AddToPurchase from './components/add-to-purchase/ui/AddToPurchase';
import ParticipantsBlock from './components/ParticipantsBlock';
import ReachBlock from './components/ReachBlock';
import StatisticBlock from './components/StatisticBlock';
import { useLoadHistoricalMetricsController } from './hooks';

import * as S from './styles';

export type Props = {
  channelId: Id;
};

export const useChannelPageLogic = (channelId: string) => {
  const chartRef = useRef<Chart>(null);
  const [nowTimestamp] = useState(createNow());

  const { data: channel, isLoading: isLoadingChannel } = useGetV1ChannelsByChannelIdQuery(
    {
      channelId: channelId
    },
    {
      skip: !channelId
    }
  );

  const formatsId = useMemo(
    () => (channel ? getFormatsByChannel(channel).map((format) => format.format_id) : []),
    [channel]
  );

  const { data: formats = [] } = useGetUserChannelFormatsQuery(
    { formatsId },
    {
      skip: !formatsId.length
    }
  );

  const { isShowButton, isButtonLoading, handleButtonClick } = useLoadHistoricalMetricsController(
    channel?.info,
    channelId,
    nowTimestamp
  );

  return {
    channel,
    isLoadingChannel,
    nowTimestamp,
    chartRef,
    isShowButton,
    isButtonLoading,
    handleButtonClick,
    formats
  };
};

const ChannelPage = ({ channelId }: Props) => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  const { channel, isLoadingChannel, nowTimestamp, chartRef, isButtonLoading, handleButtonClick } =
    useChannelPageLogic(channelId);

  return (
    <>
      {channel && <AddToPurchase channel={channel} />}
      <S.ChannelContainer
        style={
          cartContainerId
            ? {
                paddingBottom: 80
              }
            : undefined
        }
      >
        <S.ParticipantsWrapper>
          <ParticipantsBlock
            channel={channel?.info}
            isLoadingChannel={isLoadingChannel}
            channelId={channelId}
            now={nowTimestamp}
            chartRef={chartRef}
          />
        </S.ParticipantsWrapper>
        {/* <ViewsBlock
          channel={channel}
          isLoadingChannel={isLoadingChannel}
          channelId={channelId}
          now={nowTimestamp}
        /> */}
        <S.ReachWrapper>
          <ReachBlock
            channel={channel?.info}
            isLoadingChannel={isLoadingChannel}
            channelId={channelId}
            now={nowTimestamp}
          />
        </S.ReachWrapper>
        {/* <MentionsBlock
          channel={channel?.info}
          isLoadingChannel={isLoadingChannel}
          channelId={channelId}
          now={nowTimestamp}
        /> */}
        <S.StatisticWrapper>
          <StatisticBlock
            channelId={channelId}
            now={nowTimestamp}
            channel={channel?.info}
            isLoadingChannel={isLoadingChannel}
            chartRef={chartRef}
          />
        </S.StatisticWrapper>

        {false && (
          <S.ButtonContainer>
            <LoadButton isLoading={isButtonLoading} onClick={handleButtonClick}>
              Загрузить данные за все время
            </LoadButton>
          </S.ButtonContainer>
        )}
      </S.ChannelContainer>
    </>
  );
};

export default memo(ChannelPage);
