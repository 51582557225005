import { QRCode } from 'antd5';
import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
  border-radius: 12px;
  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`;

export const QR = styled(QRCode)`
  position: absolute;
  border: none;
  padding: 0;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.alpineGoat};
  width: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 100%;
    height: auto;
  }
`;
