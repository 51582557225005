import { ColumnComponentExporter } from '../type';

import Cell from './Cell';
import Title from './Title';
import Value from './Value';

export default {
  Title,
  Cell,
  Value
} satisfies ColumnComponentExporter & { Value: typeof Value };
