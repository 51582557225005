import { FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';

import CountrySelect from '../CountrySelect';
import LanguageSelect from '../LanguageSelect';

import * as S from './styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

export const LanguageColumn: FC<IProps> = ({ channel }) => {
  return (
    <S.LanguageWrapper>
      <div>
        <CountrySelect channel={channel} />
      </div>
      <div>
        <LanguageSelect channel={channel} />
      </div>
    </S.LanguageWrapper>
  );
};
