import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

const Content = styled.section`
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  @media ${MEDIA_DEVICE.mobileL.to} {
    min-height: min-content;
    margin-left: 0;
    padding: 10px 16px 0px 16px;
    margin-top: 0;
  }
`;

export default Content;
