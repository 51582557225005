import { FC } from 'react';
import { usePatchV1UserMutation, UserRole } from 'api/api';
import { UserRoleSelect } from 'fsd/entities/user';
import { URLs } from 'fsd/shared/config/URLs';
import { Button } from 'fsd/shared/ui/Buttons';

import * as S from './styles';

type Props = {
  onSuccess?: () => void;
};

const Form: FC<Props> = ({ onSuccess }) => {
  const [updateUser, { isLoading }] = usePatchV1UserMutation();
  const [role, setRole] = useState<UserRole | undefined>();

  const handleFinish = useCallback(() => {
    if (role) {
      updateUser({
        userUpdate: {
          role
        }
      })
        .unwrap()
        .then(() => onSuccess?.());
    }
  }, [onSuccess, role, updateUser]);

  return (
    <S.Root>
      <UserRoleSelect value={role} onChange={setRole} />
      <S.Text>
        Нажимая на кнопку зарегестрироваться вы соглашаетесь с <br />
        <a href="https://tg.market/agreement-and-cookies" target="_blank" rel="noreferrer">
          Правилами пользования Сервиса
        </a>
      </S.Text>
      <S.Footer>
        <Button
          block
          type="primary"
          size="large"
          onClick={handleFinish}
          disabled={isLoading || !role}
        >
          Завершить регистрацию
        </Button>
      </S.Footer>
    </S.Root>
  );
};

export default memo(Form);
