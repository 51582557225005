import { theme } from 'theme/theme';
import styled from 'styled-components';

export const EmptyDataTextWrapper = styled.div`
  position: absolute;
  top: calc(50%);
  left: 50%;
  transform: translate(-50%, calc(50% - 18px));
  color: ${theme.bayFog};
  font-size: 14px;
  line-height: 16px;
`;

export const EmptyDataText = () => {
  return <EmptyDataTextWrapper>Нет данных</EmptyDataTextWrapper>;
};
