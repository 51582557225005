import { FC, memo } from 'react';

import { CPM as CPMCell } from '../../../../Row/components/CPM';
import { RowDataContext } from '../../../lib/rowDataContext';
import { DefaultColumnCellProps } from '../type';

import * as S from '../../../../Row/styles';

type Props = DefaultColumnCellProps;

const Cpm: FC<Props> = ({ channel }) => {
  const rowData = useContext(RowDataContext);

  return (
    <S.ChartCell className='usiteful__catalog__cpm-cell"'>
      {rowData && <CPMCell channel={channel} selectedFormatId={rowData.formatId} />}
    </S.ChartCell>
  );
};

export default memo(Cpm);
