import { Tabs as _Tabs } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  --padding-x: var(--page-container-padding-x);
  width: 100%;
  padding: 0 var(--padding-x);
  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 0;
  }
`;

export const Tabs: typeof _Tabs = styled(_Tabs)`
  .ant-tabs-tab {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-nav::before {
    border-color: ${({ theme }) => theme.sweetFlower};
    width: calc(100% + var(--padding-x) * 2);
    transform: translateX(calc(var(--padding-x) * -1));
  }
  .ant-tabs-tab-active {
    font-weight: 500;
  }
`;
