import styled from 'styled-components';

import { theme } from 'theme/theme';

export const ListViewCalendarContainer = styled.div`
  width: 200px;
  min-width: 200px;
  max-height: 520px;
  overflow-y: auto;
  padding: 4px 0;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 18px;

  color: ${theme.blackRock};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0 4px;
  margin: 0;
`;

export const ListItem = styled.li<{ selected?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 12px 22px;
  border-radius: 4px;

  background: ${(props) => (props.selected ? theme.alpineGoat : null)};

  &:hover {
    background: ${theme.alpineGoat};
  }
`;
