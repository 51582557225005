import { FC } from 'react';
import { calcPurchaseCPMPlan } from 'fsd/entities/purchase';
import { formatNumber } from 'fsd/shared/lib/helpers/tools';
import { Table } from 'fsd/shared/ui/Table';

import { TableContext } from '../../../lib/tableContext';
import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  const tableData = useContext(TableContext);
  const result = calcPurchaseCPMPlan(
    purchase,
    tableData?.metrics.reaches?.channels?.find((c) => c.channel_id === purchase.channel.info.id)
  );
  return typeof result !== 'undefined' ? (
    <>{formatNumber(result, 1).toString().replace('.0', '')} ₽</>
  ) : (
    <Table.NoCellData />
  );
};

export default memo(Cell);
