import { FC } from 'react';

import ParticipantsDiffCell from '../../../../Row/components/ParticipantsDiff';
import useParticipantsMetrics from '../../../lib/useParticipantsMetrics';
import { DefaultColumnCellProps } from '../type';

import * as S from '../../../../Row/styles';

type Props = DefaultColumnCellProps;

const ParticipantsDiff: FC<Props> = ({ channel }) => {
  const { tableData, ...participants } = useParticipantsMetrics(channel);

  return (
    <S.ChartCell2>
      {tableData && (
        <ParticipantsDiffCell
          channelId={channel.info.id}
          dateRange={tableData.dateRange}
          timeWindow={tableData.timeWindow}
          channelIds={tableData.channelIds}
          historicalMetricsLoaded={tableData.historicalMetricsLoaded}
          isLoading={!tableData.channelMetrics?.data || !tableData.participantsDiffMetrics?.data}
          participantsDiff={participants.channelParticipantsDiffMetrics}
        />
      )}
    </S.ChartCell2>
  );
};

export default memo(ParticipantsDiff);
