import { ComponentProps, FC, PropsWithChildren } from 'react';

import * as S from './styles';

type Props = ComponentProps<typeof S.Root>;

const BottomBlock: FC<PropsWithChildren<Props>> = ({ children, ...restProps }) => {
  return (
    <S.Root {...restProps}>
      <S.Content>{children}</S.Content>
    </S.Root>
  );
};

export default memo(BottomBlock);
