import { FC, PropsWithChildren } from 'react';
import { IconInfoCircle } from 'fsd/shared/icons';

import { Description } from '../Description';

import * as S from './InfoBlock.styles';

type Props = PropsWithChildren<S.RootProps>;

const InfoBlock: FC<Props> = ({ children, variant, size, ...restProps }) => {
  return (
    <S.Root {...restProps} variant={variant} size={size}>
      <S.Icon width={16} color={theme.persianBlue}>
        <IconInfoCircle />
      </S.Icon>
      <S.Content>{children}</S.Content>
    </S.Root>
  );
};

export default memo(InfoBlock);
