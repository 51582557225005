import { FC } from 'react';
import { CheckboxGroup } from 'fsd/shared/ui/Checkbox';

import { ExportFieldKeys } from '../../../types/export';

import items from './items';

type Props = {
  value: ExportFieldKeys;
  onChange: (value: ExportFieldKeys) => void;
};

const Fields: FC<Props> = ({ value, onChange }) => {
  return <CheckboxGroup value={value} onChange={onChange} items={items} />;
};

export default Fields;
