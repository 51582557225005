import { usePostV1ExchangeByContainerIdReportGoogleMutation } from 'api/api';
import stringsToBooleanObject from 'fsd/shared/lib/stringsToBooleanObject';

import { BaseExportData } from './types';

export default () => {
  const [isSynchronized, setIsSynchronized] = useState(false);
  const [exportMutation, { isLoading, data }] =
    usePostV1ExchangeByContainerIdReportGoogleMutation();

  const synchronizedTimeout = useRef<ReturnType<typeof setTimeout>>();

  const synchronized = useCallback(() => {
    clearTimeout(synchronizedTimeout.current);
    setIsSynchronized(true);
    synchronizedTimeout.current = setTimeout(() => {
      setIsSynchronized(false);
    }, 1000 * 10);
  }, []);

  const exportGoogleSheets = useCallback(
    async (data: BaseExportData) => {
      await exportMutation({
        body: {
          fields: stringsToBooleanObject(data.fields)
        },
        containerId: data.containerId
      })
        .unwrap()
        .then(() => {
          synchronized();
        });
    },
    [exportMutation, synchronized]
  );

  useEffect(() => {
    return () => {
      clearTimeout(synchronizedTimeout.current);
    };
  }, []);

  return [exportGoogleSheets, { isLoading, data, isSynchronized }] as const;
};
