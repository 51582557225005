import { FC, HTMLAttributes } from 'react';
import { IconMenu } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './styles';

type Props = HTMLAttributes<HTMLButtonElement>;

const Button: FC<Props> = (props) => {
  return (
    <S.Root type="button" aria-label="Menu" {...props}>
      <Icon color={theme.persianBlue} width={24}>
        <IconMenu />
      </Icon>
    </S.Root>
  );
};

export default memo(Button);
