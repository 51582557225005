import { FC } from 'react';
import { Field } from 'react-final-form';
import { Checkbox } from 'antd';
import { parseFormatTime, useGetUserChannelFormatsQuery } from 'fsd/entities/format';

import * as S from '../../styles';

const FormatTypeFilter: FC = () => {
  const { data: formats, refetch } = useGetUserChannelFormatsQuery({ formatsId: [1, 2, 3] });

  const sortedFormats = useMemo(
    () => [...(formats ?? [])].sort((a, b) => a.format_id - b.format_id),
    [formats]
  );

  return (
    <Field
      name="format_ids"
      type="text"
      render={({ input, meta }) => (
        <S.SmallTabsCheckbox>
          <Checkbox.Group {...input}>
            {sortedFormats.map((format) => (
              <S.CheckButton value={format.format_id} key={format.format_id}>
                <div className="checkbox-button">{parseFormatTime(format).with.short}</div>
              </S.CheckButton>
            ))}
          </Checkbox.Group>
          {meta.touched && meta.error && <S.Error>{meta.error}</S.Error>}
        </S.SmallTabsCheckbox>
      )}
    />
  );
};

export default FormatTypeFilter;
