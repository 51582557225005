import { IconSettings } from 'fsd/shared/icons';
import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import styled from 'styled-components';

import Layout from '../../Layout';

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
`;

export const Paper = styled(Layout.Content)`
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px;

  flex-direction: column;
`;

export const WrapperLoader = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;

  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;
`;

export const StyledIconSettings = styled(IconSettings)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.persianBlue};
`;

export const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

export const AboutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const LanguageTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const SelectLabel = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  .ant-select-dropdown-menu {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ant-select-selection-item {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const ErrorTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.maWhite};
`;
