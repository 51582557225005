import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import BottomBlock from 'fsd/shared/ui/BottomBlock';
import styled from 'styled-components';

export const Root = styled(BottomBlock)`
  & {
    left: 64px;
  }
  @media ${MEDIA_DEVICE.mobileL.to} {
    left: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  @media ${MEDIA_DEVICE.mobileL.to} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex: 1;
  white-space: nowrap;
  @media ${MEDIA_DEVICE.mobileL.to} {
    flex-wrap: wrap;
    gap: 8px 14px;
    padding: 0 20px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  flex: 1;
  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 100%;
  }
  & > * {
    flex: 1;
  }
`;

export const CloseWrapper = styled.div`
  flex: none;
  @media ${MEDIA_DEVICE.mobileL.to} {
    position: absolute;
    top: 6px;
    right: 16px;
  }
`;
