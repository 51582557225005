import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'fsd/shared/ui/Modal';

import SinglePost from 'components/SinglePost';

import * as S from './styles';

const ChannelPost: FC = () => {
  const params = useParams();
  const channelId = String(params.id);
  const postId = Number(params.post_id);

  const navigate = useNavigate();
  return (
    <Modal className="filters-modal filters-modal--mini" open onCancel={() => navigate(-1)}>
      <S.Root>
        <S.PostTitle>Пост</S.PostTitle>
        <SinglePost channelId={channelId} postId={postId} compact={false} />
      </S.Root>
    </Modal>
  );
};

export default memo(ChannelPost);
