import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { ChannelRead, Id } from 'api/api';
import { useAppDispatch } from 'fsd/app/redux/hooks';
import { openTab } from 'slices/channelTabs/ChannelTabsSlice';

export const useLocationController = ({
  channel,
  onRowClick
}: {
  channel: ChannelRead;
  onRowClick: (channelId: Id) => void;
}) => {
  const [refState, setRefState] = useState<any>(null);
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current && !refState) {
      setRefState(ref.current);
    }
  }, [channel, ref, refState]);

  const dispatch = useAppDispatch();

  const openActiveServiceTab = useCallback(() => {
    onRowClick(channel.id);
  }, [channel.id, onRowClick]);

  const openActiveBrowserTab = useCallback(() => {
    window.open(`${window.location.origin}/channel/${channel.id}`, '_blank');
  }, [channel.id]);

  const openActiveBrowserWindow = useCallback(() => {
    // Danger! It's a Trick!
    // here we shoud open new window
    // browser will open it in new window or tab, depends on user settings
    // so we need to dispatch click event on ref with shiftKey
    const event = new MouseEvent('click', {
      shiftKey: true
    });
    refState?.dispatchEvent(event);
  }, [refState]);

  const openNotActiveServiceTab = useCallback(() => {
    dispatch(openTab({ tabId: channel.id.toString(), openInBackground: true }));
  }, [channel.id, dispatch]);

  const handleNavigation: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const ctrl = e.ctrlKey || e.metaKey;
      const shift = e.shiftKey;

      if (ctrl && shift) {
        e.nativeEvent.preventDefault();
        openActiveBrowserTab();
        return;
      }

      if (ctrl) {
        e.nativeEvent.preventDefault();
        openNotActiveServiceTab();
        return;
      }

      // just click
      if (!shift) {
        e.nativeEvent.preventDefault();
        openActiveServiceTab();
      }

      // if shift we should open in new window by default browser settings, so we don't need to do anything
    },
    [openActiveBrowserTab, openActiveServiceTab, openNotActiveServiceTab]
  );

  return {
    ref,
    refState,
    openActiveBrowserTab,
    openActiveServiceTab,
    openNotActiveServiceTab,
    openActiveBrowserWindow,
    handleNavigation
  };
};
