import {
  ChannelExchangeFormats,
  Format,
  PurchaseRead,
  usePatchV1ExchangePurchaseUpdateMutation
} from 'api/api';
import { getFormatsByChannel, parseChannelFormatsWithFormats } from 'fsd/entities/channel';
import { createIdByFormatTime, useGetUserChannelFormatsQuery } from 'fsd/entities/format';
import { isCanEditPurchaseFormat } from 'fsd/entities/purchase';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

const createPurchaseFormatId = (format: Pick<Format, 'time_in_ad_hours' | 'time_in_top_hours'>) => {
  return Number(`${format.time_in_top_hours}010${format.time_in_ad_hours}`);
};

export default (purchase: PurchaseRead) => {
  const formatsId = useMemo(
    () => getFormatsByChannel(purchase.channel).map((format) => format.format_id),
    [purchase.channel]
  );
  const [updatePurchase] = usePatchV1ExchangePurchaseUpdateMutation();
  const { data: formatsInfo = [], isFetching: isFormatFetching } = useGetUserChannelFormatsQuery(
    { formatsId },
    {
      skip: !formatsId.length
    }
  );

  const isDisabled = purchase.status && !isCanEditPurchaseFormat(purchase.status);

  const { exchange_info: exchangeInfo } = purchase.channel;
  const channelFormats = exchangeInfo?.formats as ChannelExchangeFormats[];

  const [currentFormatId, setCurrentFormatId] = useState<number | undefined>(undefined);

  const formatsWithInfo = useMemo(() => {
    return parseChannelFormatsWithFormats(channelFormats, formatsInfo).map((format) => {
      return {
        ...format,
        _id: createIdByFormatTime({
          time_in_feed_minutes: format.feed,
          time_in_top_minutes: format.top
        })
      };
    });
  }, [formatsInfo, channelFormats]);

  const handleSelect = useCallback(
    (formatId?: number) => {
      const format = formatsWithInfo?.find((f) => f._id === formatId);
      if (!format || !format.feed || !format.top) return;
      setCurrentFormatId(formatId);

      updatePurchase({
        updatePurchase: {
          ...purchase,
          cost: format.priceNumber,
          format: {
            time_in_ad_hours: format.feed,
            time_in_top_hours: format.top
          },
          note: purchase.note ?? null,
          time: purchase.time ?? null,
          date: purchase.common_purchase_data?.date ?? null,
          cpm_amount: purchase.common_purchase_data?.cpm_amount ?? null,
          cps_amount: purchase.common_purchase_data?.cps_amount ?? null
        }
      })
        .unwrap()
        .catch(() => {
          CustomToast({
            type: 'error',
            message: <ToastMessage title="При обновлении стоимости произошла ошибка" />
          });
        });
    },
    [formatsWithInfo, purchase, updatePurchase]
  );

  useEffect(() => {
    if (purchase?.format) {
      setCurrentFormatId(createPurchaseFormatId(purchase.format));
    }
  }, [purchase.format]);

  return {
    isDisabled,
    formatId: currentFormatId,
    formats: formatsWithInfo,
    isFormatFetching,
    handleSelect
  };
};
