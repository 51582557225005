import { FC, PropsWithChildren, ReactNode } from 'react';

import { Info } from '../../Info';

import * as S from './styles';

type FieldBlockProps = {
  label: ReactNode;
  info?: ReactNode;
};

const FieldBlock: FC<PropsWithChildren<FieldBlockProps>> = ({ label, info, children }) => {
  return (
    <S.InfoBlock>
      <S.TextContainer>
        <p>{label}</p>
        {info && <Info color={theme.gray4}>{info}</Info>}
      </S.TextContainer>
      {children}
    </S.InfoBlock>
  );
};

export default FieldBlock;
