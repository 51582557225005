import { HistoryStatistic2 } from 'api/api';
import { getMonth } from 'date-fns';
import { ru } from 'date-fns/locale';
import { combineArrays, formatDate, getDatesArray } from 'fsd/shared/lib/helpers/tools';
import { getDaysValuesByStatistic } from 'fsd/shared/lib/helpers/tools/getDaysValuesByStatistic';
import { theme } from 'theme/theme';

export const useGetV1MentionsData = (payload: HistoryStatistic2 | undefined) => {
  const mentions = payload || {
    from: '',
    to: '',
    accuracy: 'day',
    history: []
  };

  const isHistoryEmtpy = !mentions.history || mentions.history.length === 0;

  const getStatisticAndGraphData = () => {
    const mentionsDatesArr = getDatesArray(mentions.from, mentions.to, 'day', true);
    const mentionsStatistic = combineArrays(mentionsDatesArr, mentions?.history);

    const graphData = mentions.history
      ? [
          {
            type: 'bar',
            label: 'Mentions',
            barThickness: 9,
            borderColor: theme.baingani,
            data: mentionsStatistic.map((item) => {
              return {
                x: +item.date,
                y: item.value
              };
            })
          }
        ]
      : [];

    return {
      mentionsStatistic: formatDate(mentionsStatistic),
      graphData
    };
  };

  const { mentionsStatistic, graphData } = getStatisticAndGraphData();

  const { today, yesterday, week, month, all } = getDaysValuesByStatistic(mentionsStatistic, 'sum');

  const currentMonth = getMonth(new Date());

  const months: { name: string; value: number }[] = [];
  const pastMonths: { name: string; value: number }[] = [];

  for (let i = 0; i <= currentMonth; i++) {
    months.push({ name: ru.localize?.month(i), value: i });
  }

  months.reverse();

  for (let i = 0; i < 12; i++) {
    pastMonths.push({ name: ru.localize?.month(i), value: i });
  }

  pastMonths.reverse();

  return {
    mentions,
    mentionsStatistic,
    mentionsToday: today,
    mentionsYesterday: yesterday,
    mentionsWeek: week,
    mentionsMonth: month,
    mentionsAll: all,
    graphData,
    mentionsHistoryEmtpy: isHistoryEmtpy
  };
};
