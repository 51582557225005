import { useEffect, useRef, useState } from 'react';
import { useGetV1UserChannelsQuery } from 'api/api';
import { useOnClickOutside } from 'fsd/shared/lib/hooks/useOnClickOutside';

export const useChannelTableLogic = () => {
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  const { data: channels, refetch, isLoading } = useGetV1UserChannelsQuery();

  const filteredChannels = useMemo(() => {
    if (!channels) return [];

    return channels.filter((channel) =>
      channel.info.title.toLowerCase().includes(searchStr.toLowerCase())
    );
  }, [channels, searchStr]);

  useEffect(() => {
    refetch();
  }, [isChannelModalOpen, refetch]);

  // const addChannelToList = (channel: Channel) => {
  //   setFullChannels((prev) => [...prev, channel]);
  // };

  const toggleCascader = useCallback(() => setIsChannelModalOpen((prevState) => !prevState), []);

  const closeCascader = useCallback(() => setIsChannelModalOpen(false), []);

  const wrappedRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrappedRef, closeCascader);

  // const renderRow = (channels: string[]) => {
  //   return (
  //     <>
  //       {channels.map((channelId: Id) => {
  //         return <Row channelId={channelId} addChannelToList={addChannelToList} key={channelId} />;
  //       })}
  //     </>
  //   );
  // };

  // const searchList = channels?.filter((channel) => {
  //   return channel.info.name.toLowerCase().includes(searchStr.toLowerCase());
  // });
  return {
    isChannelModalOpen,
    setIsChannelModalOpen,
    searchStr,
    setSearchStr,
    toggleCascader,
    wrappedRef,
    channels: filteredChannels,
    isLoadingChannels: isLoading
  };
};
