import { ComponentProps, FC, PropsWithChildren } from 'react';
import { Button } from 'fsd/shared/ui/Buttons';

type Props = ComponentProps<typeof Button>;

const AddButton: FC<PropsWithChildren<Props>> = ({ children, ...restProps }) => {
  return (
    <Button type="primary" size="middle" block {...restProps}>
      {children ?? 'Добавить'}
    </Button>
  );
};

export default memo(AddButton);
