import { css } from 'styled-components';

export const BaseCellContentStyles = css`
  padding-left: calc(var(--table-col-space) / 2) !important;
  padding-right: calc(var(--table-col-space) / 2) !important;
  .ant-table-cell:first-child & {
    padding-left: var(--table-col-space) !important;
  }
  .ant-table-cell:last-child & {
    padding-right: var(--table-col-space) !important;
  }
`;
