import { LogoWithText } from 'fsd/shared/ui/Logo';
import { SideBarMobile as SharedSideBarMobile } from 'fsd/shared/ui/SideBar';

import footerItems from '../config/footerItems';
import items from '../config/items';

const SideBarMobile = () => {
  return (
    <SharedSideBarMobile
      logo={<LogoWithText />}
      items={items}
      footer={{
        items: footerItems
      }}
    />
  );
};

export default memo(SideBarMobile);
