import { Input } from 'antd';
import styled from 'styled-components';

import { BaseStyles } from '../styles';

export const Root = styled(Input)<{
  $borderBottom?: boolean;
  $fontWeight?: number;
  $isError?: boolean;
  $styleType?: 'white';
}>`
  ${BaseStyles}
`;
