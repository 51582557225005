import { FC, ReactNode } from 'react';

import Content from './Content';

import * as S from './styles';

type ModalProps = {
  open: boolean;
  channelId: string;
  postId: number;
  actions?: {
    view?: ReactNode;
    inviteLink?: ReactNode;
  };
  onClose: () => void;
};

const PreviewModal: FC<ModalProps> = ({ open, actions, onClose, channelId, postId }) => {
  return (
    <S.Modal
      destroyOnClose
      title="Опубликованный пост"
      open={open}
      footer={
        <>
          {actions?.inviteLink}
          {actions?.view}
        </>
      }
      onClose={onClose}
    >
      <Content channelId={channelId} postId={postId} />
    </S.Modal>
  );
};

export default memo(PreviewModal);
