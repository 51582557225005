import { IconSpeaker } from 'fsd/shared/icons';
import { GraphData } from 'types/chart';

import { ChartJS } from 'components/ChartWithZoom';

import * as S from './styles';

type GraphBlockProps = {
  graphData: GraphData;
};

export const GraphBlockMentions = (props: GraphBlockProps) => {
  const { graphData } = props;

  function findMinMaxX(dataArray: { x: number; y: number }[]) {
    let minX = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY;

    dataArray.forEach((point: { x: number; y: number }) => {
      if (point.x < minX) {
        minX = point.x;
      }
      if (point.x > maxX) {
        maxX = point.x;
      }
    });

    return { minX, maxX };
  }

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  let minWith5Percent;
  let maxWith5Percent;

  if (graphData.length > 0 && Object.prototype.hasOwnProperty.call(graphData[0], 'data')) {
    const { minX, maxX } = findMinMaxX(graphData[0].data);

    maxWith5Percent = maxX + oneDayInMilliseconds;
    minWith5Percent = minX - oneDayInMilliseconds;
  }

  return (
    <S.GraphWrapper>
      <S.Title>
        <S.InteractiveChartFilter>
          <S.BigIconTableParamsBlue>
            <IconSpeaker />
          </S.BigIconTableParamsBlue>
          <S.ChartFilterLabel>Количество упоминаний</S.ChartFilterLabel>
        </S.InteractiveChartFilter>
      </S.Title>
      <div>
        <ChartJS
          data={graphData}
          suggestedMinX={minWith5Percent}
          suggestedMaxX={maxWith5Percent}
          hasScaleLines
        />
      </div>
    </S.GraphWrapper>
  );
};
