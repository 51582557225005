/* eslint-disable no-case-declarations */
import { format } from 'date-fns';
import { dateFormat } from 'fsd/shared/lib/helpers/constants/dateFormats';
import { createNewMSKDate } from 'fsd/shared/lib/helpers/tools/dates';

import { parseDate } from './getDaysValuesByStatistic';

type statisticItem = {
  date: string;
  value: number;
};

export const checkIsDataExsist = (
  statistic: statisticItem[],
  period?: 'today' | 'yesterday' | 'week' | 'month'
): boolean => {
  if (statistic.length < 1) return false;
  const result = statistic.length >= 1;

  if (period) {
    switch (period) {
      case 'today':
        const todayDate = format(createNewMSKDate(), dateFormat);
        const todayValue = statistic.find((item) => item.date === todayDate);
        return !!todayValue;
      case 'yesterday':
        const yesterdayDate = format(
          createNewMSKDate().setDate(createNewMSKDate().getDate() - 1),
          dateFormat
        );
        const yesterdayValue = statistic.find((item) => item.date === yesterdayDate);
        return !!yesterdayValue;
      case 'week':
        const weekDate = createNewMSKDate().setDate(createNewMSKDate().getDate() - 8);
        let hasWeekValues = false;

        statistic.forEach((item) => {
          if (hasWeekValues) {
            // skip iteration if we already have values
            return;
          }
          const itemDate = parseDate(item.date);
          const periodDate = new Date(weekDate);

          if (itemDate >= periodDate) {
            hasWeekValues = true;
          }
        });
        return hasWeekValues;
      case 'month':
        const monthDate = createNewMSKDate().setDate(createNewMSKDate().getDate() - 30);
        let hasMonthValues = false;

        statistic.forEach((item) => {
          if (hasMonthValues) {
            // skip iteration if we already have values
            return;
          }
          const itemDate = parseDate(item.date);
          const periodDate = new Date(monthDate);

          if (itemDate >= periodDate) {
            hasMonthValues = true;
          }
        });
        return hasMonthValues;
    }
  }

  return result;
};
