import { FC, PropsWithChildren } from 'react';
import { FormProps } from 'antd';

import * as S from '../styles';

type Props = FormProps;

const Root: FC<PropsWithChildren<Props>> = ({ children, ...restProps }) => {
  return <S.Root {...restProps}>{children}</S.Root>;
};

export default memo(Root);
