import { FC } from 'react';
import { useUpdatePurchaseFormatSelectLogic } from 'fsd/features/updatePurchaseFormat';
import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import { Table } from 'fsd/shared/ui/Table';

import { DefaultColumnCellProps } from '../../../types';

import * as S from './Cell.styles';

const { Option } = Select;

type Props = DefaultColumnCellProps;
const Cell: FC<Props> = ({ purchase }) => {
  const { formatId, isDisabled, handleSelect, formats } =
    useUpdatePurchaseFormatSelectLogic(purchase);

  if (!purchase.channel.exchange_info?.is_exchange_active) {
    return <Table.NoCellData />;
  }

  return (
    <S.StyledSelect
      bordered={false}
      disabled={isDisabled}
      value={formatId}
      optionLabelProp="customLabel"
      onSelect={handleSelect}
      dropdownStyle={{
        fontSize: '14px',
        borderRadius: '8px',
        padding: '4px 4px 4px',
        minWidth: '211px'
      }}
    >
      {formats?.map((item) => {
        return (
          <Option value={item._id} key={item._id} customLabel={item.format}>
            <S.OptionWrap>
              <S.Format>{item.format}</S.Format>
              <S.Price>{item.price} ₽</S.Price>
            </S.OptionWrap>
          </Option>
        );
      })}
    </S.StyledSelect>
  );
};

export default memo(Cell);
