import { useMemo } from 'react';
import { HistoryStatistic, HistoryStatistic2 } from 'api/api';
import {
  calcErd,
  calcErm,
  combineArrays,
  formatDate,
  getDatesArray
} from 'fsd/shared/lib/helpers/tools';
import { getDaysValuesByStatistic } from 'fsd/shared/lib/helpers/tools/getDaysValuesByStatistic';
import { theme } from 'theme/theme';

export const getGraphDataByParticipantsAndViews = (
  views: HistoryStatistic | HistoryStatistic2,
  participants: HistoryStatistic | HistoryStatistic2,
  combine?: boolean
) => {
  const viewsDatesArr = getDatesArray(views.from, views.to, views.accuracy, true);
  const viewsStatistic = combineArrays(viewsDatesArr, views?.history || []);

  const participantsDatesArr = getDatesArray(
    participants.from,
    participants.to,
    participants.accuracy,
    true
  );
  const participantsStatistic = combineArrays(participantsDatesArr, participants?.history || []);

  const getFilteredViews = () => {
    return viewsStatistic.map((item) => {
      const users = participantsStatistic.filter(({ date }) => date === item.date);
      return {
        date: item?.date,
        views: item?.value,
        participants: users[0]?.value
      };
    });
  };

  const getCombinedViews = () => {
    // get unique dates
    const typedViewsStatistic = viewsStatistic.map((item) => {
      return {
        ...item,
        type: 'views'
      };
    });
    const typedParticipantsStatistic = participantsStatistic.map((item) => {
      return {
        ...item,
        type: 'participants'
      };
    });
    const combined = [...typedViewsStatistic, ...typedParticipantsStatistic];
    const dates = combined.map((item) => item.date);
    const uniqueDates = [...new Set(dates)];
    // get normalized value by date
    const result = uniqueDates.map((date) => {
      const values = combined.filter((item) => item.date === date);
      const views = values.find((item) => item.type === 'views');
      const participants = values.find((item) => item.type === 'participants');
      return {
        date,
        views: views?.value || 0,
        participants: participants?.value || 0
      };
    });

    return result;
  };

  const preparedData = combine ? getCombinedViews() : getFilteredViews();

  const graphData = [
    {
      type: 'line',
      label: 'Participants',
      borderColor: theme.persianBlue,
      hidden: true,
      data: preparedData.map((item) => {
        return {
          x: +item?.date,
          y: item?.participants
        };
      })
    },
    {
      type: 'line',
      label: 'Views',
      data: preparedData.map((item) => {
        return {
          x: +item?.date,
          y: item?.views
        };
      })
    }
  ];

  return {
    graphData,
    viewsStatistic: formatDate(viewsStatistic),
    participantsStatistic: formatDate(participantsStatistic)
  };
};

export const useViewsAndParticipantsStatistic = (
  viewsPayload: HistoryStatistic2 | HistoryStatistic | undefined,
  participantsPayload: HistoryStatistic2 | HistoryStatistic | undefined,
  options?: {
    combine: boolean;
  }
) => {
  const views = viewsPayload || {
    from: '',
    to: '',
    accuracy: 'day',
    history: []
  };
  const participants = participantsPayload || {
    from: '',
    to: '',
    accuracy: 'day',
    history: []
  };

  const { viewsStatistic, participantsStatistic, graphData } = useMemo(
    () => getGraphDataByParticipantsAndViews(views, participants, options?.combine || false),
    [views, participants, options?.combine]
  );

  const { today, yesterday, week, month, all } = getDaysValuesByStatistic(viewsStatistic, 'avg');

  const erd = calcErd(viewsStatistic, participantsStatistic);
  const erm = calcErm(viewsStatistic, participantsStatistic);

  const isHistoryEmtpy = !views.history || views.history.length === 0;

  return {
    graphData,
    viewsStatistic,
    viewsToday: today,
    viewsYesterday: yesterday,
    viewsWeek: week,
    viewsMonth: month,
    viewsAll: all,
    viewsIsHistoryEmtpy: isHistoryEmtpy,
    erd,
    erm
  };
};
