import { ServiceWorkerUpdaterProps } from '@3m1/service-worker-updater';
import { useGetV1UserQuery } from 'api/api';
import dayjs from 'dayjs';
import { useAuth } from 'fsd/app/providers/Auth';
import { isProd } from 'fsd/shared/lib/featureFlags';
import useUsitefulScript from 'fsd/shared/lib/hooks/useUsitefulScript';
import { WelcomeWidget } from 'fsd/widgets/Welcome';

import { useTopLevelChannelPooling } from '../entities/channel/lib/useTopLevelChannelPooling';
import { IconAlertCircle } from '../shared/icons';
import { Layout } from '../shared/ui/Layout';
import { Notification } from '../shared/ui/Notification';

import { withProviders } from './providers';
import Routes from './routes';

import 'dayjs/locale/ru';

dayjs.locale('ru');

function checkBasicAuth() {
  const isShowBasicAuth = !import.meta.env.VITE_REACT_APP_IS_PROD;
  const localStoragePassword = window.localStorage.getItem('basic-custom-password') || '';
  const secretPassword = import.meta.env.VITE_REACT_APP_NETLIFY_SECRET || '';
  const isPasswordCorrect = localStoragePassword === secretPassword;

  return {
    isShowBasicAuth,
    isPasswordCorrect,
    secretPassword
  };
}

function App(props: ServiceWorkerUpdaterProps) {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  const { isAuth } = useAuth();

  useTopLevelChannelPooling();

  const { data: user } = useGetV1UserQuery();
  const { username } = user || {};

  useUsitefulScript({
    userId: username,
    onError: (e: Error) => console.warn('ERROR CONFIGURING USITEFUL SCRIPT', e),
    onSuccess: () => console.log('USITEFUL SCRIPT CONFIGURED')
  });

  if (!isProd()) {
    const { isShowBasicAuth, isPasswordCorrect, secretPassword } = checkBasicAuth();

    if (isShowBasicAuth && !isPasswordCorrect) {
      const inputPassword = prompt('password');
      if (inputPassword === secretPassword) {
        window.localStorage.setItem('basic-custom-password', inputPassword || '');
      } else {
        return null;
      }
    }
  }

  return (
    <Layout>
      {newServiceWorkerDetected && isAuth && (
        <Notification
          icon={<IconAlertCircle />}
          text="Вышла новая версия платформы. Необходимо обновить страницу"
          btn={{
            text: 'Обновить',
            onClick: onLoadNewServiceWorkerAccept
          }}
        />
      )}
      <WelcomeWidget />
      <Routes />
    </Layout>
  );
}

export default withProviders(App);
