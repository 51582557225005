import React, { FC, memo } from 'react';
import { Field } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';
import { FormApi } from 'final-form';
import { flattenObj } from 'fsd/shared/lib/helpers/tools';
import { Switch } from 'fsd/shared/ui/Switch';

import { FilterState } from '../../../../types/channels/types';
import { FormValues } from '../../types';

import * as S from './TopFilter.styles';

type Props = {
  form?: FormApi<FormValues, Partial<FormValues>>;
  values?: FilterState;
};

const TopFilter: FC<Props> = ({ form, values }) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <S.Root>
      <Field
        name="is_top_tgmarket"
        type="checkbox"
        render={({ input }) => (
          <>
            <Switch
              id="is_top_tgmarket"
              {...input}
              onChange={(value) => {
                setSearchParams(
                  flattenObj({
                    ...values,
                    pagination: {
                      offset: 0,
                      limit: 20
                    },
                    is_top_tgmarket: value ? true : null
                  })
                );
                form?.change('pagination', {
                  offset: 0,
                  limit: 20
                });

                input.onChange(value ? true : null);
                form?.submit();
              }}
            />
            Топ Tgmarket
          </>
        )}
      />
    </S.Root>
  );
};

export default memo(TopFilter);
