import { FC } from 'react';
import { useRepeatPurchase } from 'fsd/entities/purchase';
import { IconRepost } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';

type Props = {
  containerId: string;
  purchaseId: string;
};

const RepeatButton: FC<Props> = ({ containerId, purchaseId }) => {
  const [repeat, { isLoading }] = useRepeatPurchase();
  const handleClick = useCallback(() => {
    repeat({ containerId, purchaseId });
  }, [containerId, purchaseId, repeat]);

  return (
    <Button
      type="primary"
      block
      onClick={handleClick}
      disabled={isLoading}
      startIcon={<IconRepost />}
    >
      Повторить
    </Button>
  );
};

export default memo(RepeatButton);
