import { FC } from 'react';
import { usePostV1ExchangePurchasesContainerNewCreateMutation } from 'api/api';
import {
  FindChannelOrExternalService,
  FindChannelOrExternalServiceProvider,
  useChannelOrExternalService
} from 'fsd/entities/channel';
import createContainerName from 'fsd/entities/container/lib/createContainerName';
import normalizeLink from 'fsd/shared/lib/normalizeLink';

import AddButton from '../AddButton';

import * as S from './styles';

type Props = {
  channelId?: string;
  onSuccess?: (containerId: string) => void;
};

const ToNewForm: FC<Props> = ({ onSuccess, channelId }) => {
  const channelOrExternalService = useChannelOrExternalService();
  const [createPurchaseContainer] = usePostV1ExchangePurchasesContainerNewCreateMutation();

  const handleFinish = useCallback(() => {
    const { selectedItem } = channelOrExternalService;
    if (selectedItem) {
      createPurchaseContainer({
        createPurchaseList: {
          container_channel_id: selectedItem.channelId ?? '',
          title: createContainerName(selectedItem.name),
          purchase_channel_id_list: channelId ? [channelId] : [],
          budget: 0,
          currency: 'rub',
          container_type: selectedItem.type,
          source_link: selectedItem.link ? normalizeLink(selectedItem.link) : ''
        }
      })
        .unwrap()
        .then((res) => {
          const { containerId } = res[0];
          if (containerId) {
            onSuccess?.(containerId);
          }
        });
    }
  }, [channelId, channelOrExternalService, createPurchaseContainer, onSuccess]);

  return (
    <FindChannelOrExternalServiceProvider value={channelOrExternalService}>
      <S.Root onFinish={handleFinish}>
        <FindChannelOrExternalService />
        <AddButton
          disabled={!channelOrExternalService.selectedItem || channelOrExternalService.isEdited}
          htmlType="submit"
        />
      </S.Root>
    </FindChannelOrExternalServiceProvider>
  );
};

export default memo(ToNewForm);
