import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Item = styled(Button).attrs({ size: 'large' })`
  flex: 1;
`;
