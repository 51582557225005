import { memo, RefObject, useState } from 'react';
import { Popover, Tooltip } from 'antd';
import { ChannelRead, Id } from 'api/api';
import { Chart } from 'chart.js';
import { IconCalendar, IconFullscreen, IconMinimize, IconReposts } from 'fsd/shared/icons';
import Skeleton from 'fsd/shared/ui/Skeleton';
import { Switch } from 'fsd/shared/ui/Switch';

import { ListViewCalendar } from 'components/ListViewCalendar';

import { EmptyTable } from './components/EmptyTable';
import Table from './components/Table';
import { useDateFilterController, useGetInfinityScrollData } from './hooks';

import * as LS from './styles';

type Props = {
  channelId: Id;
  channel: ChannelRead | undefined;
  isLoadingChannel: boolean;
  now: string;
  chartRef: RefObject<Chart>;
  isMobile?: boolean;
};

const StatisticBlock = ({
  channelId,
  channel,
  isLoadingChannel,
  now,
  chartRef,
  isMobile
}: Props) => {
  const [isRepost, setIsRepost] = useState(true);
  const [isZoomed, setIsZoomed] = useState(false);

  const { isPickerOpen, setIsPickerOpen, onDateChange, selectedDate, filterDate, dates } =
    useDateFilterController(now, channelId, channel, chartRef);

  const { values, hasMoreUp, hasMoreDown, isLoading, isFirstLoading, loadMore, setIsLoading } =
    useGetInfinityScrollData(channelId, filterDate, !!channel?.historical_metrics_loaded);

  if (isFirstLoading || isLoadingChannel) {
    return (
      <div style={{ gridRow: 'span 3' }}>
        <Skeleton type={isMobile ? 'mobile-list' : 'list'} id={4} />
      </div>
    );
  }

  return (
    <LS.BlockContainer zoom={isZoomed} className="usiteful__channel-page__statistics-widget">
      <LS.HeaderWrapper>
        <LS.HeaderContainer>
          <LS.Title>
            <span> {isMobile ? 'По часам' : 'Статистика по часам'}</span>
            <Tooltip title="Выбрать месяц">
              <LS.IconWrapper className="usiteful__channel-page__statistics-widget-calendar">
                <LS.BigIconRepost>
                  <IconCalendar onClick={() => setIsPickerOpen((prev) => !prev)} />
                </LS.BigIconRepost>

                <Popover
                  overlayClassName="contextmenu-popover"
                  autoAdjustOverflow
                  placement="rightTop"
                  getPopupContainer={(elem) => elem}
                  showArrow={false}
                  trigger="click"
                  open={isPickerOpen}
                  onOpenChange={(e) => setIsPickerOpen(e)}
                  content={<ListViewCalendar onChange={onDateChange} dates={dates} />}
                />
              </LS.IconWrapper>
            </Tooltip>
          </LS.Title>
          <LS.Title>
            <Tooltip
              overlayClassName="tooltip-witch-switcher"
              title={
                isRepost
                  ? 'Выключить отображение репостов в других каналах'
                  : 'Включить отображение репостов в других каналах'
              }
            >
              <LS.SwitchWrap>
                <LS.BigIconRepost>
                  <IconReposts />
                </LS.BigIconRepost>
                <LS.Label htmlFor="withReposts">
                  <LS.RepostsLabel>С репостами</LS.RepostsLabel>
                </LS.Label>
                <Switch
                  className="usiteful__channel-page__statistics-widget-switch"
                  checked={isRepost}
                  id="withReposts"
                  onChange={() => {
                    setIsRepost((prev) => !prev);
                  }}
                />
              </LS.SwitchWrap>
            </Tooltip>
            {isMobile && (
              <LS.IconWrap onClick={() => setIsZoomed((prev) => !prev)}>
                {isZoomed ? <IconMinimize /> : <IconFullscreen />}
              </LS.IconWrap>
            )}
          </LS.Title>
        </LS.HeaderContainer>
      </LS.HeaderWrapper>

      <LS.ContentContainer>
        {values && values.length ? (
          <Table
            values={values || []}
            startDay={filterDate}
            channelId={channelId}
            hasMoreUp={hasMoreUp}
            hasMoreDown={hasMoreDown}
            isLoading={isLoading}
            isRepost={isRepost}
            loadMore={loadMore}
            setIsLoading={setIsLoading}
            chartRef={chartRef}
          />
        ) : (
          <EmptyTable length={18} />
        )}
      </LS.ContentContainer>
    </LS.BlockContainer>
  );
};

export default memo(StatisticBlock);
