import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'fsd/app/redux/hooks';
import { IconClose } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';
import { LayoutContent } from 'fsd/shared/ui/Layout';
import { CartBottomInfoWidget } from 'fsd/widgets/CartBottomInfo';
import { closeTab, openTab } from 'slices/channelTabs/ChannelTabsSlice';

import { TabsProps } from 'components/Tabs';
import ChannelPage from 'pages/channel/ChannelPage';

import Catalog from '../../pages/catalog/components/Catalog';
import ChannelTab from '../../pages/catalog/containers/ChannelTab';
import { CatalogProps } from '../../pages/catalog/index';

import { getChannelId } from './utils/get-channel-id';
import { getTabId } from './utils/get-tab-id';
import { ChannelCatalogTabLabel } from './ChannelCatalogTabLabel';
import { catalogTabId } from './constants';

import '../../fsd/shared/ui/Tooltip/Tooltip.less';

import * as S from './styles';

const MIN_TAB_WIDTH = 113;

type ChannelPageProps = {
  isChannelPage: boolean;
  catalogProps?: never;
};

type CatalogPageProps = {
  catalogProps: CatalogProps['catalogProps'];
  isChannelPage?: never;
};

type Props = CatalogPageProps | ChannelPageProps;

export const ChannelTabs: FC<Props> = ({ catalogProps, isChannelPage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: channelIdUrlParam } = useParams();

  const { channelIds, activeTabId } = useAppSelector((state) => state.channelTabs);

  useEffect(() => {
    const tabId = channelIdUrlParam ? getTabId(channelIdUrlParam) : catalogTabId;

    dispatch(openTab({ tabId }));
  }, [channelIdUrlParam, dispatch]);

  const items: TabsProps['items'] = [
    {
      label: 'Каталог каналов',
      key: catalogTabId,
      closable: false,
      children: isChannelPage || !catalogProps ? null : <Catalog catalogProps={catalogProps} />
    },
    ...channelIds.map((channelId) => ({
      label: <ChannelTab isCollapsed channelId={channelId} />,
      key: getTabId(channelId),
      closable: true,
      closeIcon: (
        <Icon width={13} color="">
          <IconClose />
        </Icon>
      ),
      children: isChannelPage ? <ChannelPage channelId={channelId} /> : null
    }))
  ];

  const handleOnChange = useCallback(
    (tabId: string) => {
      if (tabId === catalogTabId) {
        navigate('/');
        return;
      }

      const channelId = getChannelId(tabId);
      navigate(`/channel/${channelId}`);
    },
    [navigate]
  );

  const handleOnRemove = useCallback(
    (tabId: string) => {
      if (tabId === catalogTabId) {
        return;
      }

      if (activeTabId === tabId) {
        const activeChannelId = getChannelId(activeTabId);
        const activeChannelIndex = channelIds.findIndex(
          (channelId) => channelId === activeChannelId
        );

        if (activeChannelIndex === -1 || channelIds.length === 1) {
          navigate('/');
        } else {
          const nextChannelIndex =
            activeChannelIndex === channelIds.length - 1
              ? activeChannelIndex - 1
              : activeChannelIndex + 1;
          const nextChannelId = channelIds[nextChannelIndex];

          navigate(`/channel/${nextChannelId}`);
        }
      }

      dispatch(closeTab({ tabId }));
    },
    [activeTabId, channelIds, dispatch, navigate]
  );

  return (
    <>
      <LayoutContent>
        <S.StyledCatalogTabs
          onRemove={handleOnRemove}
          onChange={handleOnChange}
          activeKey={activeTabId}
          items={items}
          CatalogTab={ChannelCatalogTabLabel}
          Tab={ChannelTab}
          catalogTabId={catalogTabId}
          getChannelId={getChannelId}
          minTabWidth={MIN_TAB_WIDTH}
        />
      </LayoutContent>
      <CartBottomInfoWidget page="catalog" />
    </>
  );
};
