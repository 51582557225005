import { FC } from 'react';
import { Tooltip } from 'antd';
import { useChannelCategories } from 'fsd/entities/channel/lib/useChannelCategories';
import { ChannelActions } from 'fsd/entities/channel/ui/ChannelActions';
import { IconVerified } from 'fsd/shared/icons';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import { DefaultColumnCellProps } from '../type';

import * as S from '../../../../Row/styles';

type Props = DefaultColumnCellProps;

const Info: FC<Props> = ({ channel: { info, exchange_info: exchangeInfo } }) => {
  const isMobile = useIsMobile();
  const categories = useChannelCategories(info.categories);
  const titleIsTooLong = info.title.length > 20;

  return (
    <S.NameCellWrapper>
      <S.ChannelIconWrap>
        {info.icon_url ? (
          <S.ChannelIcon>
            <img src={`data:image/png;base64,${info.icon_url}`} alt="" />
          </S.ChannelIcon>
        ) : (
          <S.ChannelFakeIcon />
        )}
      </S.ChannelIconWrap>

      <S.ChannelInfoWrap>
        <S.ChannelNameWrapper>
          {titleIsTooLong ? (
            <Tooltip title={info.title}>
              <S.ChannelName className="no-translation">
                {`${info.title.substring(0, 20)}...`}
              </S.ChannelName>
            </Tooltip>
          ) : (
            <S.ChannelName className="no-translation"> {info.title}</S.ChannelName>
          )}

          {exchangeInfo?.verification_status === 'verified' && <IconVerified />}
          {info.rating && <S.Rating>{info.rating}</S.Rating>}
        </S.ChannelNameWrapper>

        <S.ChannelCategory>{categories}</S.ChannelCategory>

        {!isMobile && (
          <S.ChannelActionWrapper>
            <ChannelActions channel={info} exchangeInfo={exchangeInfo} />
          </S.ChannelActionWrapper>
        )}
      </S.ChannelInfoWrap>
    </S.NameCellWrapper>
  );
};

export default memo(Info);
