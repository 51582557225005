import { NavLink } from 'react-router-dom';
import { IconAdd, IconSpaceman } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';

import * as S from './styles';

const EmptyList = () => {
  return (
    <S.Root>
      <S.Img>
        <IconSpaceman color={theme.persianViolet} />
      </S.Img>
      <S.Title>Вы пока не создали ни одной кампании</S.Title>
      <S.Text>
        После добавления <br />
        они будут отображаться здесь
      </S.Text>

      <NavLink to="/">
        <Button startIcon={<IconAdd />} block type="primary">
          Перейти в каталог
        </Button>
      </NavLink>
    </S.Root>
  );
};

export default memo(EmptyList);
