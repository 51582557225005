import checkImageAllow from './checkImageAllow';
import normalizeLink from './normalizeLink';

export interface ISyncSiteInfo {
  favicon: string;
  link: string;
  url: URL;
  name: string;
}
export interface ISiteInfo extends ISyncSiteInfo {
  isHaveFavicon: boolean;
}

const FAVICON_SIZE = 256;

export const getSyncSiteInfo = (link: string): ISyncSiteInfo => {
  const normalizedLink = normalizeLink(link);
  const url = new URL(normalizedLink);
  const favicon = `https://www.google.com/s2/favicons?domain=${url.origin}&sz=${FAVICON_SIZE}`;
  return {
    favicon,
    link,
    url,
    name: url.hostname.replace('www.', '')
  };
};

export const getSiteInfo = async (link: string, signal?: AbortSignal): Promise<ISiteInfo> => {
  const syncSiteInfo = getSyncSiteInfo(link);

  await fetch(syncSiteInfo.link, {
    mode: 'no-cors',
    signal
  });

  let isHaveFavicon = false;

  try {
    const res = await checkImageAllow(syncSiteInfo.favicon);
    if (res) {
      isHaveFavicon = true;
    }
  } catch (error) {
    isHaveFavicon = false;
  }

  return {
    isHaveFavicon,
    ...syncSiteInfo
  };
};

export const useGetSiteInfo = () => {
  const [siteInfo, setSiteInfo] = useState<ISiteInfo | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [isFound, setIsFound] = useState(true);

  const get = useCallback((link: string) => {
    setIsFetching(true);
    return new Promise<ISiteInfo | undefined>((resolve) => {
      if (link.length >= 3) {
        getSiteInfo(link)
          .then((res) => {
            setIsFound(true);
            setSiteInfo(res);
            resolve(res);
          })
          .catch(() => {
            resolve(undefined);
            setIsFound(false);
            setSiteInfo(undefined);
          })
          .finally(() => {
            setIsFetching(false);
          });
      } else {
        resolve(undefined);
        setSiteInfo(undefined);
        setIsFetching(false);
      }
    });
  }, []);

  return {
    get,
    siteInfo,
    isFetching,
    isFound
  };
};
