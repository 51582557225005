import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 8px;
  padding: 4px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 32px 0px rgba(0, 16, 61, 0.16);
`;

export default Root;
