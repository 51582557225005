import { Tooltip } from 'antd';
import { IconClock, IconUsers } from 'fsd/shared/icons';
import { theme } from 'theme/theme';
import { Icon } from 'fsd/shared/ui/Icon';
import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

export const EmptyDataTableRow = styled.div`
  background: ${({ theme }) => theme.alpineGoat};
  border-radius: 6px;
  width: 100%;
  height: 33px;
`;

export const EmptyDataText = styled.div`
  border-radius: 6px;
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.bayFog};
`;

export const TableHeaderContainer = styled.div`
  background: ${({ theme }) => theme.alpineGoat};
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 18px;
`;

export const GrowOne = styled.div`
  flex-grow: 1;
`;

export const GrowMany = styled.div`
  flex-grow: 8;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.blackRock};
`;

export const EmptyTable = ({ length }: { length: number }) => {
  return (
    <TableWrapper>
      <TableHeaderContainer>
        <GrowOne className="usiteful__channel-page__statistics-widget-column-time">
          <Tooltip title="Время события">
            <Icon width={16} color={theme.persianBlue}>
              <IconClock />
            </Icon>
          </Tooltip>
        </GrowOne>

        <GrowOne className="usiteful__channel-page__statistics-widget-column-users">
          <Tooltip title="Прирост подписчиков с учётом отписок">
            <Icon width={16} color={theme.persianBlue}>
              <IconUsers />
            </Icon>
          </Tooltip>
        </GrowOne>
        <GrowMany className="usiteful__channel-page__statistics-widget-column-event">
          Событие
        </GrowMany>
      </TableHeaderContainer>

      {Array.from({ length }).map((_, index) => {
        if (index === Math.floor(length / 4)) {
          return <EmptyDataText>Нет данных</EmptyDataText>;
        }
        return <EmptyDataTableRow key={index} />;
      })}
    </TableWrapper>
  );
};
