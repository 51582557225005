import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import styled, { css } from 'styled-components';

type RootProps = { isColumn: boolean };

export const OptionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const Root = styled(Select)<RootProps>`
  max-height: 32px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default !important;
      * {
        cursor: default !important;
      }
      .ant-select-arrow {
        display: none;
      }
    `}

  .ant-select-dropdown-menu {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ant-select-selector {
    max-height: 32px;
  }

  .ant-select-selection-item {
    max-height: 32px;

    display: flex;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #4807ea;
    padding-right: ${({ disabled }) => (disabled ? '0px' : '22px')} !important;
    ${OptionContent} {
      font-size: 14px;
      font-weight: 400;
      color: ${theme.blackRock};
    }
  }
`;

export const Format = styled.div`
  font-size: 14px;
  color: ${theme.blackRock};
`;
export const Price = styled.div<{ disabled?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ disabled, theme }) => (disabled ? theme.violetPowder : theme.persianBlue)};
`;
