import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import { ChannelTabs } from 'components/ChannelTabs';
import MobileChannelPage from 'pages/channelMobile';

const Channel = () => {
  const isMobile = useIsMobile();

  return isMobile ? <MobileChannelPage /> : <ChannelTabs isChannelPage />;
};

export default memo(Channel);
