/* eslint-disable no-nested-ternary */
import { ChangeEventHandler, ComponentProps, FC, MouseEventHandler } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { IconAlertCircle, IconAttach, IconSearch } from 'fsd/shared/icons';
import { InputClearIcon } from 'fsd/shared/ui/Input';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';

import { FindContext } from '../../../lib/find/findContext';
import { FindItem } from '../../../lib/find/useFind';

import Label from './Label';
import LabelInput from './LabelInput';
import NotFound from './NotFound';

import * as S from './styles';

type Props = ComponentProps<typeof S.Root>;

type Option = DefaultOptionType & { data: FindItem };

const Input: FC<Props> = (restProps) => {
  const [open, setOpen] = useState(false);
  const labelInputRef = useRef<HTMLInputElement | null>(null);
  const [serviceValue, setServiceValue] = useState<Option>();
  const {
    value,
    set,
    items,
    isFetching,
    isNotFound,
    selectedItem,
    setSelectedItem,
    error,
    isEdited,
    setIsEdited
  } = useContext(FindContext)!;

  const edit = useCallback(() => {
    setIsEdited(true);
    set(serviceValue?.data?.name ?? '', isEdited);
    setTimeout(() => labelInputRef.current?.select(), 0);
  }, [setIsEdited, set, serviceValue?.data?.name, isEdited]);

  const cancelEdit = useCallback(() => {
    setIsEdited(false);
  }, [setIsEdited]);

  const clear = useCallback(() => {
    setServiceValue(undefined);
    setSelectedItem(undefined);
    set('');
    cancelEdit();
  }, [setSelectedItem, set, cancelEdit]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      set(e.target.value.trimStart(), true);
    },
    [set]
  );

  const options = useMemo((): Option[] => {
    return items.map((item) => {
      return {
        label: <Label name={item.name} icon={item.icon} type={item.type} />,
        value: item.key,
        data: item
      };
    });
  }, [items]);

  const handleSelect = useCallback(
    (value: string, option: Option) => {
      setServiceValue(option);
      setSelectedItem(items.find((i) => i.key === value));
      cancelEdit();
    },
    [items, setSelectedItem, cancelEdit]
  );

  const handleEditClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      edit();
    },
    [edit]
  );

  const handleEditCancelClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      cancelEdit();
    },
    [cancelEdit]
  );

  const handleEditInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      handleChange(e);
    },
    [handleChange]
  );

  const handleClearClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      clear();
    },
    [clear]
  );

  return (
    <S.Root {...restProps}>
      <S.AutoComplete
        value={value}
        open={serviceValue && !isEdited ? false : open}
        options={options}
        notFoundContent={isNotFound ? <NotFound /> : null}
        // @ts-ignore
        onSelect={handleSelect}
        onDropdownVisibleChange={(open) => setOpen(open)}
      >
        {serviceValue ? (
          <S.LabelInField>
            <S.FieldIcon width={20} color={theme.persianBlue}>
              <IconAttach />
            </S.FieldIcon>
            <Label
              name={
                isEdited ? (
                  <LabelInput value={value} ref={labelInputRef} onChange={handleEditInputChange} />
                ) : (
                  serviceValue.data.name
                )
              }
              icon={serviceValue.data.icon}
              type={serviceValue.data.type}
            />
            <S.RightWrapper>
              {isEdited && <S.Clear onClick={handleClearClick} />}
              <S.FieldDivider />
              {isEdited ? (
                <S.FieldButton onClick={handleEditCancelClick}>Отменить</S.FieldButton>
              ) : (
                <S.FieldButton onClick={handleEditClick}>Изменить</S.FieldButton>
              )}
            </S.RightWrapper>
          </S.LabelInField>
        ) : (
          <S.Input
            value={value}
            placeholder="Название, ссылка на ваш канал или сайт"
            onChange={handleChange}
            size="large"
            isError={!!error?.input}
            allowClear={{
              clearIcon: <InputClearIcon />
            }}
            prefix={
              <S.InputIcon
                color={
                  isFetching || selectedItem
                    ? theme.persianBlue
                    : error?.input
                      ? theme.hotspot
                      : theme.bayFog
                }
                width={20}
              >
                {isFetching ? (
                  <LoaderSpinner color="currentColor" />
                ) : error?.input ? (
                  <IconAlertCircle />
                ) : selectedItem ? (
                  <IconAttach />
                ) : (
                  <IconSearch />
                )}
              </S.InputIcon>
            }
          />
        )}
      </S.AutoComplete>
      {error?.message && <S.Error>{error.message}</S.Error>}
    </S.Root>
  );
};

export default memo(Input);
