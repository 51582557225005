import { ComponentProps, FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';
import { useCheckChannelInCart } from 'fsd/entities/cart';
import { ChannelFormatSelect } from 'fsd/entities/channel';

import Add from '../Add';
import Remove from '../Remove';

import * as S from './styles';

type ForceValue = Exclude<ComponentProps<typeof ChannelFormatSelect>['forceValue'], undefined>;

type Props = {
  channel: ChannelWithExchangeInfo;
  layout?: S.RootProps['$layout'];
} & Pick<ComponentProps<typeof ChannelFormatSelect>, 'value' | 'onSelect'>;
const Form: FC<Props> = ({ channel, value, onSelect, layout }) => {
  const [checkChannelInCart] = useCheckChannelInCart();

  const channelInCart = useMemo(
    () => checkChannelInCart(channel.info.id),
    [channel.info.id, checkChannelInCart]
  );

  const isAdded = Boolean(channelInCart);

  const forceValue = useCallback<ForceValue>(
    (formats) => {
      if (!channelInCart?.format) return undefined;
      return formats.find(
        (f) =>
          `${f.time_in_top_minutes}/${f.time_in_feed_minutes}` ===
          `${channelInCart.format.time_in_top_hours}/${channelInCart.format.time_in_ad_hours}`
      )?.format_id;
    },
    [channelInCart?.format]
  );

  return (
    <S.Root $layout={layout}>
      <ChannelFormatSelect
        channelFormats={channel.exchange_info?.formats ?? []}
        value={value}
        forceValue={forceValue}
        onSelect={onSelect}
        disabled={isAdded}
      />
      {isAdded ? (
        <Remove channelId={channel.info.id} />
      ) : (
        <Add channelId={channel.info.id} formatId={value} />
      )}
    </S.Root>
  );
};

export default memo(Form);
