import { ChannelWithExchangeInfo } from 'api/api';
import { getFirstActiveChannelFormat } from 'fsd/entities/channel';

export default (channel: ChannelWithExchangeInfo) => {
  const defaultActiveFormat = useMemo(
    () => getFirstActiveChannelFormat(channel?.exchange_info?.formats ?? [])?.format_id,
    [channel?.exchange_info?.formats]
  );

  const [formatId, setFormatId] = useState<number | undefined>(defaultActiveFormat);

  useEffect(() => {
    setFormatId(defaultActiveFormat);
  }, [defaultActiveFormat]);

  return { formatId, setFormatId, channel };
};
