import { ComponentType } from 'react';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { useIsMobileDevice } from 'fsd/shared/lib/isMobileDevice';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';
import { OrientationLockerProvider } from 'fsd/shared/modules/orientationLocker';
import { OrientationLockerFallbackWidget } from 'fsd/widgets/OrientationLockerFallback';

export const withOrientationLocker = (Component: ComponentType<{}>) => () => {
  const isMobileDevice = useIsMobileDevice();
  const isTablet = useMediaQuery(MEDIA_DEVICE.tablet.to);
  const isHeight = useMediaQuery('(max-height: 800px)');
  return (
    <OrientationLockerProvider
      orientation="landscape"
      fallback={<OrientationLockerFallbackWidget />}
      enabled={isMobileDevice && isTablet && isHeight}
    >
      <Component />
    </OrientationLockerProvider>
  );
};
