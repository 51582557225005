import { ComponentProps, FC } from 'react';
import { IconEye } from 'fsd/shared/icons';
import { OutlinedButton } from 'fsd/shared/ui/Buttons/OutlinedButton';

import PreviewModal from '.';

type Props = {
  purchaseId: string;
  actions?: ComponentProps<typeof PreviewModal>['actions'];
};

const PreviewModalOpen: FC<Props> = ({ purchaseId, actions }) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <PreviewModal open={open} onClose={handleClose} purchaseId={purchaseId} actions={actions} />
      <OutlinedButton block startIcon={<IconEye />} onClick={handleClick}>
        Креатив
      </OutlinedButton>
    </>
  );
};

export default PreviewModalOpen;
