import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import type { EditableConfig, Tab } from 'rc-tabs/lib/interface';
import OperationNode, { OperationNodeProps } from 'rc-tabs/lib/TabNavList/OperationNode';
import TabNode from 'rc-tabs/lib/TabNavList/TabNode';

import { TabsNavMore } from '../TabsNavMore/TabsNavMore';
import { Folder } from 'fsd/shared/ui/Folder';

type Props = {
  activeKey: string;
  children?: (node: React.ReactElement) => React.ReactElement;
  editable?: EditableConfig;
  hiddenTabs: Tab[];
  id: string;
  onTabClick: (activeKey: string) => void;
  onVisibleEndChange: (visibleEnd: number) => void;
  prefixCls: string;
  catalogTabId: string;
  visibleTabs: Tab[];
  minTabWidth: number;
};

const TabBar = (props: Props) => {
  const {
    activeKey,
    children,
    editable,
    hiddenTabs,
    id,
    onTabClick,
    onVisibleEndChange,
    prefixCls,
    visibleTabs,
    catalogTabId,
    minTabWidth
  } = props;
  const tabListRef = useRef<HTMLDivElement>(null);
  const hasDropdown = hiddenTabs.length > 0;

  const handleListHolderResize = () => {
    const tabBarWidth = tabListRef.current?.offsetWidth;

    if (!tabBarWidth) {
      return;
    }

    const activeTabWidth = activeKey === catalogTabId ? 200 : 289;
    const availableWidth = tabBarWidth - activeTabWidth;
    const visibleEnd = Math.floor(availableWidth / minTabWidth);

    onVisibleEndChange(visibleEnd);
  };

  useEffect(handleListHolderResize, [activeKey]);

  return (
    <ResizeObserver onResize={handleListHolderResize}>
      <div ref={tabListRef} role="tablist" className={classNames(`${prefixCls}-nav`)}>
        <div className={`${prefixCls}-nav-list`}>
          {visibleTabs.map((tab) => {
            const { key } = tab;
            return (
              <TabNode
                id={id}
                prefixCls={prefixCls}
                key={key}
                tab={tab}
                closable={tab.closable}
                editable={editable}
                active={key === activeKey}
                renderWrapper={children}
                onClick={() => onTabClick(key)}
                onFocus={() => {
                  //
                }}
              />
            );
          })}

          <OperationNode
            {...props}
            key="operation-node"
            rtl={false}
            mobile={false}
            more={{
              icon: <Folder.Counter count={hiddenTabs.length} />
            }}
            prefixCls={prefixCls}
            tabs={hiddenTabs}
            className={classNames({
              [`${prefixCls}-nav-operations-hidden`]: !hasDropdown
            })}
            tabMoving={false}
          />
        </div>
      </div>
    </ResizeObserver>
  );
};

export default memo(TabBar);
