import { MinMaxType } from 'types/channels/types';

import { generateDateRangeByTimeWindow } from './dates';

// eslint-disable-next-line consistent-return
function convertMinMaxToArray(data: MinMaxType) {
  if (data) {
    if (data.maxItems && !data.minItems) {
      data.minItems = 0;
    }
    const min = Number(data.minItems) ? Number(data.minItems) : 0;
    const max = Number(data.maxItems) ? Number(data.maxItems) : 0;
    return [min, max];
  }
}

// eslint-disable-next-line consistent-return
function convertMinMaxToStringArray(data: MinMaxType) {
  if (data) {
    if (data.maxItems && !data.minItems) {
      data.minItems = '';
    }

    // TODO wait back for change contract
    const min = data.minItems !== undefined && data.minItems !== null ? String(data.minItems) : '';
    const max = data.maxItems !== undefined && data.maxItems !== null ? String(data.maxItems) : '';

    if (min === '' && max === '') return [];

    return [min, max];
  }
}

// TODO: make a pure function
export function fixData(data: any, now: string) {
  const fields = [
    'participants',
    'avg-daily-reaches',
    'views',
    'mentions',
    'er',
    'avg_daily_err',
    'cpm'
    // 'min_price',
    // 'max_price'
  ];
  const booleanFields = ['is_public', 'is_verified'];
  const booleanNullableFields = ['is_exchange_active', 'is_top_tgmarket'];

  const fieldsToString = ['prices'];

  fields.forEach((element) => {
    if (Object.hasOwn(data, element)) {
      data[element] = convertMinMaxToArray(data[element]);
    }
  });

  fieldsToString.forEach((element) => {
    if (Object.hasOwn(data, element)) {
      const value = convertMinMaxToStringArray(data[element]);
      data[element] = value?.length ? value : null;
    }
  });

  data.pagination = {
    offset: Number(data.pagination.offset),
    limit: Number(data.pagination.limit)
  };

  // NOTE: VERY unclear logic with lang filter. when implementing future changes needs to be refactored
  if (!Array.isArray(data.languages)) {
    data.languages = [data.languages];
  }

  data.languages = data.languages?.map?.((i: number | string | null) => {
    if (typeof i === 'string') {
      return Number(i);
    }
    return i;
  });

  data.languages = data.languages.filter((i: number | string | null) => i || i === 0); // filter out all values like NaN null etc

  if (
    !data.languages ||
    data.languages.length === 0 ||
    (data.languages.length === 1 && !data.languages?.[0])
  ) {
    delete data.languages;
  }

  if (data.countries) {
    data.countries = [Number(data.countries)];
  }

  booleanFields.forEach((element) => {
    if (Object.hasOwn(data, element)) {
      data[element] = data[element] === true;
    }
  });

  booleanNullableFields.forEach((element) => {
    if (Object.hasOwn(data, element)) {
      data[element] = data[element] ? true : null;
    }
  });

  return {
    ...data,
    date_range: generateDateRangeByTimeWindow(data.date_range, now),
    time_zone: data.time_zone || 'Europe/Moscow'
  };
}
