import { FC } from 'react';
import { IconCloseCircleFill } from 'fsd/shared/icons';

import { Icon } from '../Icon';

type Props = {
  size?: number;
};

const ClearIcon: FC<Props> = ({ size = 20 }) => (
  <Icon width={size}>
    <IconCloseCircleFill />
  </Icon>
);

export default memo(ClearIcon);
