import styled from 'styled-components';

import { Icon } from '../../../Icon';

export const Root = styled(Icon)`
  position: relative;
`;

export const Count = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
