import { FC } from 'react';
import { useGetV1ExchangeMultipliersQuery } from 'api/api';
import { calcPriceWithMultiplier } from 'fsd/entities/format';
import { URLs } from 'fsd/shared/config/URLs';

import * as S from './CostCommission.styles';

type Props = {
  cost: number;
};

const CostCommission: FC<Props> = ({ cost }) => {
  const { data } = useGetV1ExchangeMultipliersQuery();
  return (
    <S.Root>
      <S.Content>
        <S.Title>Вы получите</S.Title>
        <S.CaptionWrapper>
          <S.CaptionInfo>
            <p>
              Комиссия сервиса. <br />С деталями можете ознакомиться в{' '}
              <a target="_blank" href={URLs.OFFERS} rel="noreferrer">
                договоре
              </a>
            </p>
          </S.CaptionInfo>
          <span>-комиссия</span>
        </S.CaptionWrapper>
      </S.Content>
      <S.Value>
        {data?.admin_multiplier
          ? `${Math.ceil(calcPriceWithMultiplier(cost, data.admin_multiplier)).toLocaleString('ru')} ₽`
          : '-'}
      </S.Value>
    </S.Root>
  );
};

export default CostCommission;
