import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setAuthToken } from 'fsd/entities/auth';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import { useGetV1UserQuery, usePostV1SigninTgMutation } from '../../api/api';
import { useAuth } from '../../fsd/app/providers/Auth';

const LoginPage = () => {
  const [getToken] = usePostV1SigninTgMutation();

  const navigate = useNavigate();

  const { refetch } = useGetV1UserQuery();
  const { isAuth, setAuth } = useAuth();
  const [isNewToken, setIsNewToken] = useState(false);

  const [searchParams] = useSearchParams();
  const authTokenBySearchParams = useMemo(() => searchParams?.get('token') ?? '', [searchParams]);

  const catchErr = useCallback(
    (e: any) => {
      if (e.status === 'FETCH_ERROR' || e.name === 'AbortError') {
        CustomToast({
          type: 'error',
          message: (
            <ToastMessage
              title="Ошибка авторизации"
              description={'Причина: нет\n' + '                соединения с интернетом'}
            />
          ),
          options: {
            toastId: 'error no internet'
          }
        });
      } else {
        CustomToast({
          type: 'error',
          message: (
            <ToastMessage
              title="Ошибка авторизации"
              description={'Причина:\n' + '                Просроченный токен'}
            />
          ),
          options: {
            toastId: 'error old token'
          }
        });
      }

      navigate('/');
    },
    [navigate]
  );

  useEffect(() => {
    if (authTokenBySearchParams && !isNewToken) {
      getToken({ body: { token: authTokenBySearchParams } })
        .unwrap()
        .then(({ token }) => {
          setAuthToken(token);
          setIsNewToken(true);
          setAuth(true);
        })
        .catch(catchErr);
    }
  }, [authTokenBySearchParams, catchErr, getToken, isAuth, isNewToken, refetch, setAuth]);

  const [isWelcome, setIsWelcome] = useState(false);

  useEffect(() => {
    if (isAuth) {
      const redirectUrl = searchParams.get('redirect');
      navigate(redirectUrl ? `/${redirectUrl}` : '/');
      if (!isWelcome) {
        CustomToast({
          type: 'success',
          message: <ToastMessage title="Добро пожаловать в Tgmarket! 🎉" />,
          options: {
            autoClose: 2000
          }
        });
        setIsWelcome(true);
      }
    }
  }, [isAuth, isWelcome, navigate, searchParams]);

  return <div />;
};

export default memo(LoginPage);
