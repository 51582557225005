import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import { Channel } from 'api/api';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import { theme } from 'theme/theme';

import * as S from './styles';

type ChannelCardWrapper = PropsWithChildren & {
  channelId: string;
  onItemClick?(channelId: string): void;
};

const ChannelCardWrapper: FC<ChannelCardWrapper> = ({ children, channelId, onItemClick }) => {
  const isMobile = useIsMobile();
  const location = useLocation();

  if (onItemClick) {
    return (
      <S.SearchRowWrapper onClick={() => onItemClick(channelId)}>{children}</S.SearchRowWrapper>
    );
  }

  return (
    <S.SearchRowWrapperLink
      to={`/channel/${channelId}`}
      state={isMobile ? { background: location } : null}
    >
      {children}
    </S.SearchRowWrapperLink>
  );
};

const getHighlightedText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  if (!highlight) return text;

  // make sure it does not include RegEx special characters. If includes, escape them
  const highlightSanitized = highlight.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');

  const parts = text.split(new RegExp(`(${highlightSanitized})`, 'gi'));
  return (
    <>
      {parts.map((part, i) => (
        <span
          className="no-translation"
          key={i}
          style={part.toLowerCase() === highlight.toLowerCase() ? { color: theme.persianBlue } : {}}
        >
          {part}
        </span>
      ))}
    </>
  );
};

type ChannelCardProps = {
  channelId: string;
  channel: Channel | undefined;
  searchStr: string;
  type: 'channel' | 'description';
  onItemClick?(channelId: string): void;
  onContentChange?: () => void;
};

const ChannelCard: FC<ChannelCardProps> = ({
  channelId,
  channel,
  searchStr,
  type,
  onItemClick,
  onContentChange
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const participants = channel?.participants;

  useEffect(() => {
    if (channel) {
      setIsLoading(false);
    }
  }, [channel]);

  // Если мы подгрузили все данные, сообщаем что контент изменился
  useEffect(() => {
    if (!isLoading && onContentChange) onContentChange();
  }, [isLoading]);

  // Если данных еще нету, то показываем скелетон
  if (isLoading || !channel) {
    return (
      <div style={{ padding: '13px 12px 0px' }}>
        <Skeleton
          loading
          active
          avatar
          title={false}
          paragraph={{
            rows: 1
          }}
        />
      </div>
    );
  }

  const { title, name, description, icon_url } = channel || {};

  return (
    <ChannelCardWrapper channelId={channelId} onItemClick={onItemClick}>
      <S.ChannelIconWrap>
        {icon_url ? (
          <S.ChannelIcon>
            <img src={`data:image/png;base64,${icon_url}`} alt="" />
          </S.ChannelIcon>
        ) : (
          <S.ChannelFakeIcon />
        )}
      </S.ChannelIconWrap>
      <div>
        <S.Name className="no-translation">{getHighlightedText(title, searchStr)}</S.Name>
        <S.Info>
          {type === 'channel' && name && `@${name}`}
          {type === 'description' && getHighlightedText(description || '', searchStr)}{' '}
          {type === 'channel' && participants && `, ${participants} подписчиков`}
        </S.Info>
      </div>
    </ChannelCardWrapper>
  );
};

export default ChannelCard;
