export const validation = (values: any) => {
  const errors: any = {};

  if (values.changePassword !== values.confirmPassword) {
    errors.confirmPassword = 'Пароли не совпадают';
  }

  if (values.changePassword && values.changePassword.length < 8) {
    errors.changePassword = 'Минимальная длина пароля 8 символов';
  }

  if (values.confirmPassword && values.confirmPassword.length < 8) {
    errors.confirmPassword = 'Минимальная длина пароля 8 символов';
  }

  if (values.changePassword !== values.confirmPassword) {
    errors.confirmPassword = 'Пароли не совпадают';
  }

  if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email)) {
    errors.email = 'Проверьте правильность email';
  }

  if (values.changePassword && !/[a-z]/.test(values.changePassword)) {
    errors.changePassword = 'Пароль должен содержать хотя бы один строчный символ';
  }

  if (!/[A-Z]/.test(values.changePassword)) {
    errors.changePassword = 'Пароль должен содержать хотя бы один заглавный символ';
  }

  return errors;
};
