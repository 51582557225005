import { theme } from 'theme/theme';

import { RootPaper, SkeletonProps } from './index';

export const AltMobileSkeleton = (props: SkeletonProps) => {
  const { id, getWidth, getHeight } = props;
  return (
    <RootPaper>
      <svg
        className="alt-mobile-skeleton"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="100%"
          height="100%"
          clipPath={`url(#a${id})`}
          style={{ fill: `url(#b${id})` }}
        />
        <defs>
          <linearGradient id={`b${id}`}>
            <stop stopColor={theme.alpineGoat}>
              <animate
                attributeName="offset"
                values="-1.5; -1.5; 1.5"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop stopColor="hsl(228deg 23.81% 95.88% / 35%)">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop stopColor={theme.alpineGoat}>
              <animate
                attributeName="offset"
                values="-0.5; -0.5; 2.5"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>

          <clipPath id={`a${id}`}>
            {/* top blocks */}
            <rect
              x={getWidth(16)}
              y={getHeight(16)}
              width={getWidth(211)}
              height={getHeight(17)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(16)}
              y={getHeight(41)}
              width={getWidth(35)}
              height={getHeight(17)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(16)}
              y={getHeight(63)}
              width={getWidth(107)}
              height={getHeight(12)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(133)}
              y={getHeight(45)}
              width={getWidth(8)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(133)}
              y={getHeight(63)}
              width={getWidth(42)}
              height={getHeight(12)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(192)}
              y={getHeight(45)}
              width={getWidth(8)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(192)}
              y={getHeight(63)}
              width={getWidth(30)}
              height={getHeight(12)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(239)}
              y={getHeight(45)}
              width={getWidth(14)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(239)}
              y={getHeight(63)}
              width={getWidth(37)}
              height={getHeight(12)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(293)}
              y={getHeight(45)}
              width={getWidth(16)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />

            <rect
              x={getWidth(293)}
              y={getHeight(63)}
              width={getWidth(33)}
              height={getHeight(12)}
              rx="4"
              fill={theme.alpineGoat}
            />
          </clipPath>
        </defs>
      </svg>
    </RootPaper>
  );
};
