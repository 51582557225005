import { ComponentProps, FC } from 'react';
import { URLs } from 'fsd/shared/config/URLs';
import { InfoBlock } from 'fsd/shared/ui/InfoBlock';

type Props = ComponentProps<typeof InfoBlock>;

const Info: FC<Props> = ({ ...restProps }) => {
  return (
    <InfoBlock {...restProps}>
      <ul>
        <li>Введите ник пользователя, которого хотите добавить в организацию без @</li>
        <li>У пользователя, которого вы хотите добавить, не должно быть организации</li>
        <li>
          В случае ошибки - свяжитесь с{' '}
          <a href={URLs.SUPPORT_BOT} target="_blank" rel="noreferrer">
            поддержкой
          </a>
        </li>
      </ul>
    </InfoBlock>
  );
};

export default memo(Info);
