import { Line } from 'react-chartjs-2';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale
} from 'chart.js';
import { withoutZeroData } from 'fsd/shared/lib/helpers/chart';
import { formatNumber } from 'fsd/shared/lib/helpers/tools';
import { DataType } from 'types/chart';

import { PersianLineWithShadow } from 'components/ChartWithZoom/controllers';
import { useGetChartData } from 'components/ChartWithZoom/hooks/useGetChartData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Filler,
  LineElement,
  LineController,
  BarElement,
  BarController,
  PersianLineWithShadow
);

interface Props {
  data: Array<DataType>;
  chartref?: any;
  min?: number;
  max?: number;
  wrapperHeight?: number;
  graphWidth?: number;
  animation?: boolean;
}

/**
 * Prepare the data by filtering out data points if the data length exceeds a certain threshold.
 *
 * @param {Array<DataType>} data - The array of data to be prepared.
 * @return {Array<DataType>} The prepared array of data.
 */
function prepare(data: Array<DataType>): Array<DataType> {
  if (!data) {
    return data;
  }

  const dataLength = data[0].data.length;
  const MAX_POINTS = 25;

  if (dataLength > MAX_POINTS) {
    const step = Math.ceil(dataLength / MAX_POINTS);

    // eslint-disable-next-line no-param-reassign
    data[0].data = data[0].data.filter((item, index) => {
      return index % step === 0;
    });
  }

  return data;
}

const CompactChart = ({
  data: inputData,
  min,
  max,
  animation,
  wrapperHeight,
  graphWidth,
  ...rest
}: Props) => {
  const data = useMemo(() => prepare(inputData), [inputData]);
  const { start, end, payload } = useGetChartData(data);

  const scales = useMemo(() => {
    return {
      x: {
        position: 'bottom',
        border: {
          display: false
        },
        grid: {
          zeroLineColor: 'transparent',
          drawBorder: false,
          drawOnChartArea: false,
          display: false,
          borderWidth: 0
        },
        min: start,
        max: end,
        type: 'time',
        ticks: {
          display: false
        },
        time: {
          displayFormats: {
            week: 'dd.MM',
            day: 'dd.MM',
            hour: 'HH:mm',
            minute: 'HH:mm',
            second: 'HH:mm:ss',
            millisecond: 'HH:mm:ss'
          }
        }
      },
      y: {
        border: {
          display: false
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          tickLength: 0
        },
        type: 'linear',
        position: 'left',
        min: min,
        max: max,
        grace: 10,
        ticks: {
          display: false,
          font: {
            size: 3
          },
          autoSkip: true,
          autoSkipPadding: 20,
          maxRotation: 0,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: (label: any) => {
            return formatNumber(label);
          }
        }
      }
    };
  }, [min, max, start, end]);

  const options = useMemo(() => {
    return {
      elements: {
        point: {
          radius: 0,
          hoverRadius: 0
        }
      },
      scales: scales,
      plugins: {
        tooltip: {
          enabled: false
        }
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      animation: animation,
      // FIXME : Посмотреть правильно ли тут используется resposive, т.к из-за того, что есть опечатка он по факту false
      resposive: true,
      maintainAspectRatio: false
    };
  }, [scales, animation]);

  const lineData = useMemo(() => withoutZeroData(payload), [payload]);
  // 24
  return (
    <div style={{ height: wrapperHeight || 30 }}>
      <Line
        ref={rest?.chartref}
        width={graphWidth || 64}
        // here is problem with TS becouse we can use different types of data in line chart
        // the chart type is defined in the dataset prop
        // we can use different types of data in the same chart, but ts is not happy with that
        data={lineData}
        // @ts-ignore
        options={options}
        {...rest}
      />
    </div>
  );
};

export default memo(CompactChart);
