import { useGetV1UserQuery, useLazyGetV1UserQuery } from 'api/api';
import { useGetOrganisationsQuery, useLazyGetOrganisationsQuery } from 'fsd/entities/strapi';

import getUserOrganisationId from './getUserOrganisationId';

export default () => {
  const { data: user, isLoading: isUserLoading } = useGetV1UserQuery();
  const organisationId = user && getUserOrganisationId(user, 'crm');
  const { data, isLoading: isOrgLoading } = useGetOrganisationsQuery(
    {
      id: organisationId!
    },
    {
      skip: !organisationId
    }
  );

  return {
    data: organisationId ? data : undefined,
    isLoading: isOrgLoading || isUserLoading
  };
};

export const useLazy = () => {
  const [getUser] = useLazyGetV1UserQuery();
  const [getOrganisation] = useLazyGetOrganisationsQuery();

  const get = useCallback(async () => {
    try {
      const user = await getUser(undefined, true).unwrap();
      const organisationId = user && getUserOrganisationId(user, 'crm');
      if (organisationId) {
        const organisation = await getOrganisation(
          {
            id: organisationId
          },
          true
        ).unwrap();

        return { data: organisation };
      }
    } catch (error) {
      console.error(error);
    }
    return { data: undefined };
  }, [getOrganisation, getUser]);

  return [get];
};
