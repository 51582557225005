import { ComponentProps } from 'react';
import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { CartBottomInfoWidget } from 'fsd/widgets/CartBottomInfo';
import { ContainersListWidget } from 'fsd/widgets/ContainersList';

import { Context, ContextProvider } from '../../lib/context';
import Header, { HeaderMobile } from '../Header';

import * as S from './styles';

type ContainerListWidgetProps = ComponentProps<typeof ContainersListWidget>;

const Content = memo(() => {
  const contextData = useContext(Context);
  const cartContainerId = useAppSelector(getCartContainerIdSelector);

  const listFilter = useCallback<Exclude<ContainerListWidgetProps['filter'], undefined>>(
    ({ container }) =>
      contextData?.search && container.title
        ? container.title?.toLowerCase().trim().includes(contextData.search.toLowerCase().trim())
        : true,
    [contextData?.search]
  );

  return (
    <>
      <HeaderMobile />
      <S.Wrapper>
        <Header />
        <S.Root $isHaveCart={Boolean(cartContainerId)}>
          <ContainersListWidget filter={listFilter} />
        </S.Root>
        <CartBottomInfoWidget page="carts" />
      </S.Wrapper>
    </>
  );
});

const Page = () => {
  return (
    <ContextProvider>
      <Content />
    </ContextProvider>
  );
};

export default memo(Page);
