import { useEffect, useState } from 'react';
import { useStickyState } from 'fsd/shared/lib/hooks/useStickyState';
import { Button } from 'fsd/shared/ui/Buttons';
import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import { initialValues } from '../../../../catalog';

import * as S from './styles';

const { Option } = Select;

const languagesUI = [
  {
    id: 1,
    title: 'English (US)',
    value: 'en'
  },
  {
    id: 2,
    title: 'Русский (RU)',
    value: 'ru'
  }
];

export const languagesChannels = [
  {
    id: -1,
    title: 'Не выбран',
    value: 'default'
  },
  {
    id: 1,
    title: 'Русский',
    value: 'ru'
  }
  // {
  //   id: 8,
  //   title: 'Украинский',
  //   value: 'uk'
  // }
];
const GeneralSettings = () => {
  const [displayedValueUI, setDisplayedValueUI] = useState(2);
  const [displayedValueChannels, setDisplayedValueChannels] = useState(1);

  const [formSettings, setFormSettings] = useStickyState(initialValues, 'formSettings');

  const currentLanguageUI = localStorage.getItem('ljs-lang');

  useEffect(() => {
    if (currentLanguageUI) {
      const newDisplayedValue = languagesUI.find((item) => {
        if (item.value === currentLanguageUI) {
          return item;
        }
      });
      if (!newDisplayedValue) return;

      setDisplayedValueUI(newDisplayedValue.id);
    }

    if (formSettings) {
      let newDisplayedValue = languagesChannels.find((item) => {
        if (item.id === formSettings.languages[0]) {
          return item;
        }
      });
      newDisplayedValue = newDisplayedValue || languagesChannels[0];

      setDisplayedValueChannels(newDisplayedValue.id);
    }
  }, []);

  function handleSelectUI(id: number) {
    setDisplayedValueUI(id);
  }

  function handleSelectChannel(id: number) {
    setDisplayedValueChannels(id);
  }

  function handleSubmit() {
    const newLanguageUI = languagesUI.find((item) => {
      if (item.id === displayedValueUI) {
        return item;
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // if (typeof Localize !== 'undefined') {
    //   Localize?.setLanguage(newLanguageUI?.value);
    // }

    const newLanguageChannels = languagesChannels.find((item) => {
      if (item.id === displayedValueChannels) {
        return item;
      }
    });
    if (!newLanguageChannels) {
      CustomToast({
        type: 'error',
        message: (
          <ToastMessage
            title="Ошибка сохранения"
            description="Причина: нет соединения с интернетом"
          />
        )
      });
      return;
    }

    setFormSettings({
      ...formSettings,
      languages: newLanguageChannels.id === -1 ? [] : [newLanguageChannels.id]
    });

    CustomToast({
      type: 'success',
      message: <ToastMessage title="Изменения сохранены" />
    });
  }

  return (
    <S.Paper>
      <S.TitleWrapper>
        <S.StyledIconSettings />
        <S.Title>Настройки</S.Title>
      </S.TitleWrapper>

      <S.LanguageWrapper>
        <S.LanguageTitle>Язык</S.LanguageTitle>

        {/* <S.LanguageSelectWrapper>
          <S.LanguageSelectLabel>Язык интерфейса</S.LanguageSelectLabel>

          <S.InputWrapper>
            <S.StyledSelect value={displayedValueUI} onSelect={handleSelectUI}>
              {languagesUI.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.title}
                  </Option>
                );
              })}
            </S.StyledSelect>
          </S.InputWrapper>
        </S.LanguageSelectWrapper> */}

        <S.LanguageSelectWrapper>
          <S.LanguageSelectLabel>Язык каналов по умолчанию</S.LanguageSelectLabel>

          <S.InputWrapper>
            <S.StyledSelect value={displayedValueChannels} onSelect={handleSelectChannel}>
              {languagesChannels.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.title}
                  </Option>
                );
              })}
            </S.StyledSelect>
          </S.InputWrapper>
        </S.LanguageSelectWrapper>
      </S.LanguageWrapper>

      <Button type="primary" size="large" block onClick={handleSubmit}>
        Сохранить
      </Button>
    </S.Paper>
  );
};

export default GeneralSettings;
