import { ChannelPostRead } from 'api/api';

const addHours = (date: Date, hours: number) => {
  return new Date(date.getTime() + hours * 60 * 60 * 1000);
};

export const useGetDateRangeFromPostType = (
  post: ChannelPostRead | undefined,
  now: Date,
  type: boolean
) => {
  if (!post) return { from: '', to: '' };

  const date = new Date(post?.posted_at);
  const dayOffset = type ? 2 : 7;
  const dateWithOffset = addHours(date, dayOffset * 24);

  const from = date.toISOString();
  const to = now < dateWithOffset ? now : dateWithOffset;

  return {
    from: from,
    to: to.toISOString()
  };
};
