import { FC } from 'react';
import { Table } from 'fsd/shared/ui/Table';
import isOnePurchasePublished from 'fsd/widgets/ContainersList/lib/isOnePurchasePublished';
import reducePurchasesSubscribers from 'fsd/widgets/ContainersList/lib/reducePurchasesSubscribers';

import { DefaultColumnCellProps } from '../../../types';
import NotPublishedPlaceholder from '../../NotPublishedPlaceholder';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ record }) => {
  const subscribers = useMemo(
    () => reducePurchasesSubscribers(record.purchases),
    [record.purchases]
  );
  const isPublished = useMemo(() => isOnePurchasePublished(record.purchases), [record.purchases]);

  if (!isPublished) return <NotPublishedPlaceholder />;
  return subscribers ? subscribers.toLocaleString('ru') : <Table.NoCellData />;
};

export default memo(Cell);
