import { ColumnComponentExporter } from '../../../types';

import Cell from './Cell';
import sorter from './sorter';
import Title from './Title';

export default {
  Title,
  Cell,
  sorter
} satisfies ColumnComponentExporter<typeof sorter>;
