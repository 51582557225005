import { FC } from 'react';
import { IconSpaceman } from 'fsd/shared/icons';

import * as S from './styles';

type Props = {
  type: string;
};

const NoDataWidget: FC<Props> = ({ type }) => {
  return (
    <S.NoDataTab>
      <S.NoDataContainer>
        <S.CentredItem>
          <div>
            <IconSpaceman color={theme.persianViolet} />
          </div>
        </S.CentredItem>
        <S.NoDataDescription>{`Вы пока не добавили ни одного ${type}`}</S.NoDataDescription>
      </S.NoDataContainer>
    </S.NoDataTab>
  );
};

export default NoDataWidget;
