import { useMemo } from 'react';
import * as iconsObject from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './Icons.styles';

const Icons = () => {
  const items = useMemo(() => {
    return Object.entries(iconsObject)
      .map(([key, val]) => {
        return {
          name: key,
          Component: val
        };
      })
      .sort((a, b) => (a < b ? 1 : 0));
  }, []);
  const sizes = [10, 20, 30, 40];
  return (
    <S.Root>
      {items.map(({ name, Component }) => (
        <S.Item key={name}>
          <S.Name>{name}</S.Name>
          <S.IconsList>
            {sizes.map((size) => (
              <S.IconItem>
                <Icon width={size} key={size} color={theme.persianBlue}>
                  <Component />
                </Icon>
              </S.IconItem>
            ))}
          </S.IconsList>
        </S.Item>
      ))}
    </S.Root>
  );
};

export default Icons;
