import React, { FC, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Popover } from 'antd';
import { useAppDispatch } from 'fsd/app/redux/hooks';
import { IconChevronArrowRight } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import { Icon } from 'fsd/shared/ui/Icon';
import { Collapse } from 'fsd/shared/ui/LegacyUIComponents';
import { closeAllButOne, closeAllTabs } from 'slices/channelTabs/ChannelTabsSlice';

import { FormatsType } from 'pages/catalog/components/Table/components/Row/components/Price/Action';
import { theme } from 'theme/theme';

import { catalogTabId } from '../../../../../../components/ChannelTabs/constants';
import { getTabId } from '../../../../../../components/ChannelTabs/utils/get-tab-id';
import { FormRef } from '../../index';
import ButtonContextMenu from '../ButtonContextMenu/ButtonContextMenu';
import FormAddPurchaseToExistContainer from '../FormAddPurchaseToExistContainer';
import FormNewContainer from '../FormNewContainer';

import * as S from '../../styles';

const { Panel } = Collapse;

type Props = {
  channelId: string[];
  setIsDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  format: FormatsType[number];
};

const MultiContextMenu: FC<Props> = ({
  channelId,
  setIsDropdownVisible,
  setIsOpen,
  isOpen,
  format
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id: channelIdUrlParam } = useParams();
  const formAddRef = useRef<FormRef>(null);
  const formNewRef = useRef<FormRef>(null);
  const [openPanels, setOpenPanels] = useState({
    panel1: false,
    panel2: false
  });
  const [form1IsValid, setForm1IsValid] = useState(false);
  const [form2IsValid, setForm2IsValid] = useState(false);

  const isSingleChannel = channelId.length === 1;

  function closeAllButOpen() {
    const tabId = channelIdUrlParam ? getTabId(channelIdUrlParam) : catalogTabId;

    if (tabId === catalogTabId) {
      closeAll();
    } else {
      dispatch(closeAllButOne(tabId));
    }
    setIsDropdownVisible(false);
  }

  function closeAll() {
    dispatch(closeAllTabs());
    navigate('/');
    setIsDropdownVisible(false);
  }

  const handleSubmit = () => {
    formAddRef.current?.handleSubmit();
    formNewRef.current?.handleSubmit();
  };

  const [activeKey, setActiveKey] = useState('');

  const handleChange = (keys: string[] | string) => {
    const key = keys[keys.length - 1];
    setActiveKey(key);
    setOpenPanels({
      panel1: key === '1',
      panel2: key === '2'
    });
  };

  return (
    <>
      <Popover
        overlayClassName="contextmenu-popover"
        autoAdjustOverflow
        placement="rightTop"
        showArrow={false}
        trigger="click"
        open={isOpen}
        onOpenChange={(e) => setIsOpen(e)}
        content={
          <Collapse onChange={handleChange} activeKey={activeKey} style={{ maxWidth: 410 }}>
            <Panel
              header={
                <S.PanelWrapper>
                  <S.PanelHeader>Добавить в существующую</S.PanelHeader>
                </S.PanelWrapper>
              }
              key="1"
            >
              <FormAddPurchaseToExistContainer
                setIsDropdownVisible={setIsDropdownVisible}
                channelId={channelId}
                setIsPopoverVisible={setIsOpen}
                ref={formAddRef}
                format={format}
                setFormIsValid={setForm1IsValid}
              />
            </Panel>
            <Panel header={<S.PanelHeader>Добавить в новую</S.PanelHeader>} key="2">
              <FormNewContainer
                ref={formNewRef}
                channelId={channelId}
                format={format}
                setFormIsValid={setForm2IsValid}
              />
            </Panel>
            {(openPanels.panel1 || openPanels.panel2) && (
              <div style={{ padding: '8px 18px 24px' }}>
                {openPanels.panel1 && (
                  <Button
                    type="primary"
                    size="middle"
                    block
                    htmlType="submit"
                    onClick={handleSubmit}
                    disabled={
                      !(openPanels.panel1 && form1IsValid) && !(openPanels.panel2 && form2IsValid)
                    }
                  >
                    Добавить
                  </Button>
                )}
              </div>
            )}
          </Collapse>
        }
      >
        <ButtonContextMenu>
          Добавить все каналы в закупку
          <Icon width={20} color={theme.majesticMount}>
            <IconChevronArrowRight />
          </Icon>
        </ButtonContextMenu>
      </Popover>

      <ButtonContextMenu disabled={isSingleChannel} onClick={closeAllButOpen}>
        Закрыть все неактивные вкладки
      </ButtonContextMenu>

      <ButtonContextMenu onClick={closeAll}>Закрыть все вкладки</ButtonContextMenu>
    </>
  );
};

export default MultiContextMenu;
