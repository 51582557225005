import React, { FC, Suspense, useState } from 'react';
import { ChannelPostRead, ChannelRead, ChannelWithExchangeInfo, HistoryStatistic } from 'api/api';
import { withLoading } from 'fsd/app/providers/withLoader';
import { IconChevronBottom } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';

import { PostChart } from './components/Chart';
import { PlainContent } from './components/Content/PlainContent';
import { PostFooter } from './components/Footer';
import { PostHeader } from './components/Header';

import * as S from './styles';

// const Content = React.lazy(() => import("./components/Content"));

// const LazyContentWithLoader = () => {
//   return (
//     <Suspense
//       fallback={
//         <S.LoaderWrapper>
//           <LoaderSpinner />
//         </S.LoaderWrapper>
//       }
//     >
//       <Content />
//     </Suspense>
//   );
// };

type PostContentProps = {
  type: Record<string, boolean>;
  channel: ChannelWithExchangeInfo | undefined;
  post: ChannelPostRead | undefined;
  views: HistoryStatistic;
  subscribes: HistoryStatistic;
  isAdvChart: boolean;
  compact?: boolean;
  withoutShadow?: boolean;
};

export const PostContent: FC<PostContentProps> = ({
  type,
  post,
  channel,
  views,
  subscribes,
  isAdvChart,
  compact,
  withoutShadow
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <S.Paper withoutShadow={withoutShadow}>
      <PostHeader type={type} channel={channel} compact={compact} post={post} />

      {compact ? (
        <>
          <S.CollapsedContent collapsed={collapsed}>
            <PlainContent content={post?.content} />
            {/* <LazyContentWithLoader /> */}
          </S.CollapsedContent>

          <S.CollapseButtonWrapper>
            <S.CollapseButton
              collapsed={collapsed}
              onClick={() => setCollapsed((prevState) => !prevState)}
            >
              <Icon width={16} color="">
                <IconChevronBottom />
              </Icon>
            </S.CollapseButton>
            <S.Divider />
          </S.CollapseButtonWrapper>
        </>
      ) : (
        <>
          {/* <LazyContentWithLoader /> */}
          <PlainContent content={post?.content} />
          <S.DividerBottom />
        </>
      )}

      {/* <PostChart
        compact={compact}
        isAdvChart={isAdvChart}
        post={post}
        views={views}
        subscribes={subscribes}
      />

      {post && <PostFooter post={post} />} */}
    </S.Paper>
  );
};

export const PostContentWithLoader = withLoading(
  PostContent,
  ['channel', 'post', 'views', 'subscribes'],
  '200px',
  30
);
