import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { ReactComponent as ClearIcon } from 'fsd/shared/icons/plus.svg';
import { isSafari } from 'fsd/shared/lib/helpers/constants/browserDetection';
import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const SearchBar = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 12px;
  margin: 0;
  margin-bottom: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @media ${MEDIA_DEVICE.tablet.from} {
    box-shadow:
      0px -4px 64px rgba(0, 0, 0, 0.07),
      0px 100px 80px rgba(179, 175, 214, 0.07);
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    justify-content: space-between;
    height: initial;

    background-color: transparent;
    padding: 0;
    margin: 0;
  }
`;

export const ChosenFilters = styled.div`
  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const SearchWrapper = styled.form`
  display: flex;
  gap: 24px;
  flex: 1;
  margin-left: 8px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-left: 0px;
  }
`;

export const SearchInput = styled.div`
  flex-grow: 1;
  margin-left: 6px;
  min-width: 120px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-left: 0px;
  }
`;

export const FiltersBtnWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.009em;
  color: ${({ theme }) => theme.blackRock};

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 100px;
  }
`;

export const FilterBtn = styled(Button)<{
  $hasFilters: boolean;
}>`
  @media ${MEDIA_DEVICE.mobileL.to} {
    button&.ant-btn.ant-btn-block {
      font-size: 14px;
      line-height: 16px;
      gap: 8px;
      border-radius: 100px;

      // in mobile version we shoud have white button
      ${({ $hasFilters, theme }) => {
        if (!$hasFilters) {
          return `
            background: ${theme.white} !important;
            color: ${theme.black} !important;
            border-color: ${theme.white} !important;
            box-shadow: none;
            font-weight: 400;

            svg {
              color: ${theme.persianBlue} !important;
            }
          `;
        }
      }};
    }
  }
`;

export const FilterCount = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${({ theme }) => theme.white};

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: ${({ theme }) => theme.persianBlue};
    text-align: center;
  }
`;

export const FilterCountWrapper = styled.div`
  position: relative;

  width: 18px;
  min-width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.white};
`;

export const TimeFilters = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }
`;

export const MobileFilters = styled.div`
  display: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: flex;
    gap: 8px;
    width: 100%;
    overflow: scroll;
    white-space: nowrap;
    align-items: flex-end;
  }
`;

export const MobileFilterLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.black};
  padding: 0 16px;
`;

export const MobileFilterItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MobileFilterItem = styled.span<{ active: boolean }>`
  border: none;
  padding: 5px 16px;
  background: ${({ active, theme }) => (active ? theme.blackGrey : theme.sweetFlower)};
  border-radius: 13px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ active, theme }) => (active ? theme.white : theme.black)};
  display: flex;
  justify-content: space-between;
  gap: 9px;

  ${({ active, theme }) =>
    active &&
    `
    path {
      fill: ${theme.bayFog};
    }
  `}
`;

export const MobileFilterClearBtn = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  width: 16px;
  height: 16px;
`;

export const FilterWrap = styled.div`
  padding-bottom: 32px;
`;

export const ClearButton = styled.button`
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 13px;
  background: ${({ theme }) => theme.sweetFlower};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledClearIcon = styled(ClearIcon)`
  width: 16px;
  height: 16px;
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
`;

export const TabsHeight = 88;
export const ContainerCatalogPadding = 36;

export const Wrapper = styled.div`
  // 88px высота вкладки, 36px padding контейнера сверху и снизу
  flex: 1;
  width: 100%;
  max-width: 2330px;
  margin: 0 auto;
`;
