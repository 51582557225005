import styled, { css } from 'styled-components';

export const Root = styled.span<{ $editing?: boolean }>`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  word-break: normal;
  outline-color: ${({ theme }) => theme.persianBlue};
  ${({ $editing }) =>
    !$editing &&
    css`
      text-overflow: ellipsis;
    `}
`;
