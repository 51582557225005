import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { IconDownload } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';
import styled from 'styled-components';

import { theme } from 'theme/theme';

interface IButton {
  isLoading: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const StyledButton = styled.button<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${theme.persianBlue};
  color: ${theme.maWhite};
  font-weight: 700;
  font-size: ${({ isLoading }) => (isLoading ? 20 : 14)}px;
  line-height: ${({ isLoading }) => (isLoading ? 20 : 14)}px;

  height: 48px;
  width: 48px;
  border-radius: 50%;
  opacity: 0.2;
  transition: all 0.2s ease-in-out;

  ${({ isLoading }) =>
    !isLoading &&
    `
      span {
        width: 0;
        transition: opacity 0.4s ease-in, scale 0.4s ease-in;
        display: flex;
        transform: scaleX(0);
        opacity: 0;
      }
      &:hover {
        width: 325px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 18px;
        gap: 12px;
        border-radius: 100px;

        opacity: 1;
        span {
          width: auto;
          opacity: 1;
          transform: scaleX(1);
        }
      }
  `}

  box-shadow: 0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
`;

export const LoadButton = ({ isLoading, onClick, children }: IButton) => {
  return (
    <>
      {isLoading && (
        <Tooltip title="Данные по каналу загружаются…" placement="topRight">
          <StyledButton isLoading={isLoading} onClick={onClick}>
            <Icon width={20} color={theme.white}>
              <LoadingOutlined />
            </Icon>
          </StyledButton>
        </Tooltip>
      )}

      {!isLoading && (
        <StyledButton isLoading={isLoading} onClick={onClick}>
          <span>{children}</span>
          <Icon width={20} color={theme.white}>
            <IconDownload />
          </Icon>
        </StyledButton>
      )}
    </>
  );
};
