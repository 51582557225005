import { FC } from 'react';
import { Field } from 'react-final-form';
import { Radio } from 'antd';

import * as S from '../../styles';

const IsVerifiedFilter: FC = () => {
  return (
    <Field
      name="is_verified"
      type="text"
      render={({ input, meta }) => (
        <S.SmallTabs>
          <Radio.Group id="is_verified" {...input}>
            <Radio.Button value="">Не важно</Radio.Button>
            <Radio.Button value>Верифицированный</Radio.Button>
          </Radio.Group>
          {meta.touched && meta.error && <S.Error>{meta.error}</S.Error>}
        </S.SmallTabs>
      )}
    />
  );
};

export default IsVerifiedFilter;
