import { FC } from 'react';
import Tag from 'fsd/shared/ui/Tag';

import getActivePurchases from '../../../lib/getActivePurchases';
import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ record }) => {
  const activePurchases = getActivePurchases(record.purchases);
  const count = activePurchases.length;
  return (
    <Tag size="medium" style={{ width: '100%' }} status={count ? theme.persianBlue : true}>
      Активных заявок <b>{count}</b>
    </Tag>
  );
};

export default memo(Cell);
