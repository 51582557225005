import { ColumnsType } from 'antd5/lib/table';

import ActivePurchasesCount from '../ui/columns/ActivePurchasesCount';
import Menu from '../ui/columns/Menu';
import PurchaseCount from '../ui/columns/PurchaseCount';
import ReducedCost from '../ui/columns/ReducedCost';
import ReducedCoverage from '../ui/columns/ReducedCoverage';
import ReducedCpmTotal from '../ui/columns/ReducedCpmTotal';
import ReducedSubscribers from '../ui/columns/ReducedSubscribers';
import Title from '../ui/columns/Title';

import useListData from './useListData';

export type ColumnData = Exclude<ReturnType<typeof useListData>['dataSource'], undefined>[0];

export default () => {
  const columns = useMemo<ColumnsType<ColumnData>>(() => {
    return [
      {
        key: 'title',
        title: <Title.Title />,
        render: (record: ColumnData) => <Title.Cell record={record} />
      },
      {
        key: 'reducedCost',
        align: 'center',
        title: <ReducedCost.Title />,
        render: (record: ColumnData) => <ReducedCost.Cell record={record} />,
        sorter: ReducedCost.sorter
      },
      {
        key: 'purchaseCount',
        align: 'center',
        title: <PurchaseCount.Title />,
        render: (record: ColumnData) => <PurchaseCount.Cell record={record} />,
        sorter: PurchaseCount.sorter
      },
      {
        key: 'reducedCoverage',
        align: 'center',
        title: <ReducedCoverage.Title />,
        render: (record: ColumnData) => <ReducedCoverage.Cell record={record} />,
        sorter: ReducedCoverage.sorter
      },
      {
        key: 'reducedCpmTotal',
        align: 'center',
        title: <ReducedCpmTotal.Title />,
        render: (record: ColumnData) => <ReducedCpmTotal.Cell record={record} />,
        sorter: ReducedCpmTotal.sorter
      },
      {
        key: 'reducedSubscribers',
        align: 'center',
        title: <ReducedSubscribers.Title />,
        render: (record: ColumnData) => <ReducedSubscribers.Cell record={record} />,
        sorter: ReducedSubscribers.sorter
      },
      {
        key: 'activePurchasesCount',
        align: 'center',
        fixed: 'right',
        title: <ActivePurchasesCount.Title />,
        render: (record: ColumnData) => <ActivePurchasesCount.Cell record={record} />,
        sorter: ActivePurchasesCount.sorter,
        width: 220
      },
      {
        key: 'menu',
        align: 'center',
        fixed: 'right',
        title: <Menu.Title />,
        render: (record: ColumnData) => <Menu.Cell record={record} />,
        width: 32
      }
    ];
  }, []);
  return columns;
};
