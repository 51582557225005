/* eslint-disable */
// @ts-nocheck
import { memo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

import { withoutZeroData } from 'fsd/shared/lib/helpers/chart';

import { EmptyDataText } from './components/EmptyDataText';
import { useGetChartData } from './hooks/useGetChartData';
import { PointClipNone, useGetChartOptions } from './hooks/useGetChartOptions';
import { DashedLinesPlugin } from './plugins/DashedLinesPlugin';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Filler,
  Tooltip,
  LineElement,
  LineController,
  BarElement,
  BarController
);

type DataType = {
  type: string;
  label: string;
  data: Array<{ x: number; y: number }>;
};

interface Props {
  data: Array<DataType>;
  onZoomChange?: (from: number, to: number, chart: any) => void;
  onClick?: (pointData) => void;
  chartref?: any;
  hasScaleLines?: boolean;
  withTime?: boolean;
  withDate?: boolean;
  timeUnit?: string;
  tickOptions?: Record<string, any>;
  isDashed?: boolean;
  mode?: 'nearest' | 'index' | 'point';
  suggestedMinX?: number;
  suggestedMaxX?: number;
  suggestedMaxY?: number;
  pointClipNone?: PointClipNone;
}

const filterOnePointData = (payload: Array<DataType>) => {
  payload.datasets = payload.datasets?.filter((item) => item.data.length > 1);
  return payload;
};

const Chart = ({
  data,
  onZoomChange,
  hasScaleLines,
  onClick,
  withTime,
  withDate,
  timeUnit,
  tickOptions,
  isDashed,
  mode,
  suggestedMaxY,
  suggestedMinX,
  suggestedMaxX,
  pointClipNone,
  ...rest
}: Props) => {
  const { start, end, payload, isEmptyData } = useGetChartData(data);

  const options = useGetChartOptions({
    start,
    end,
    onZoomChange,
    onClick,
    hasScaleLines,
    withTime,
    withDate,
    timeUnit,
    tickOptions,
    mode,
    suggestedMaxY,
    suggestedMinX,
    suggestedMaxX,
    pointClipNone
  });

  // Case when we have only one point on the chart
  const isDisableZoom = start === -Infinity && end === Infinity;

  return (
    <div style={{ position: 'relative' }}>
      <Line
        ref={rest?.chartref}
        width={400}
        data={withoutZeroData(filterOnePointData(payload))}
        options={options}
        plugins={[!isDisableZoom && zoomPlugin, isDashed && DashedLinesPlugin].filter(Boolean)}
        {...rest}
      />
      {isEmptyData && <EmptyDataText />}
    </div>
  );
};

const MemoizedChart = memo(Chart);

export { MemoizedChart as ChartJS };
