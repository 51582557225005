import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useVirtualizer } from '@tanstack/react-virtual';

import { PostTypes } from 'components/ChannelPosts/helpers';
import SinglePost from 'components/SinglePost';

import { usePostsList } from '../../hooks';
import { MentionsTabHeader, PostTabHeader } from '../Headers';

import * as S from './styles';

const PostTab = ({ type }: { type: string }) => {
  const params = useParams();
  const channelId = String(params.id);
  const types: PostTypes = type === 'regular' ? ['regular', 'repost'] : ['mention'];

  const {
    data: allRows,
    isLoading: isFetchingNextPage,
    loadNextPage: fetchNextPage,
    sortings,
    handlers
  } = usePostsList(channelId, types);

  const hasNextPage = true;

  const parentRef = React.useRef<any>();
  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 520,
    overscan: 2
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  React.useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= allRows.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, allRows.length, isFetchingNextPage, virtualItems]);

  return (
    <div>
      {type === 'mentions' ? (
        <MentionsTabHeader sortings={sortings} handlers={handlers} />
      ) : (
        <PostTabHeader sortings={sortings} handlers={handlers} />
      )}
      <S.Wrapper>
        <S.Root ref={parentRef}>
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative'
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const isLoaderRow = virtualRow.index > allRows.length - 1;
              const post = allRows[virtualRow.index];

              return (
                <div
                  key={virtualRow.index}
                  data-index={virtualRow.index}
                  ref={rowVirtualizer.measureElement}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualRow.start}px)`
                  }}
                >
                  {isLoaderRow ? (
                    hasNextPage ? (
                      'Loading more...'
                    ) : (
                      'Nothing more to load'
                    )
                  ) : (
                    <S.PostWrap>
                      <SinglePost
                        withoutShadow
                        channelId={channelId}
                        postId={post.post_id}
                        compact
                      />
                    </S.PostWrap>
                  )}
                </div>
              );
            })}
          </div>
        </S.Root>
      </S.Wrapper>
    </div>
  );
};

export default memo(PostTab);
