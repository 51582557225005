import { ComponentType, FC, PropsWithChildren } from 'react';
import ReactGA from 'react-ga4';
import { useGetV1UserQuery } from 'api/api';
import { isProd } from 'fsd/shared/lib/featureFlags';
import { InspectletJS } from 'fsd/shared/modules/inspectlet-js';

const GA_ID = 'G-XVNREB64Z4';
const INSPECTLET_WID = '882104886';

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useGetV1UserQuery(undefined, {
    skip: !isProd()
  });

  useEffect(() => {
    if (isProd()) {
      InspectletJS.install(INSPECTLET_WID);

      ReactGA.initialize(GA_ID, {
        gtagOptions: {
          user_id: user?.id
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProd()) {
      // @ts-ignore
      ReactGA.set({ user_id: user?.id });
      if (user?.id) {
        InspectletJS.insp(['identify', user.id]);
        InspectletJS.setMeta({
          email: user.email,
          user_id: user.id,
          tg_username: user.tg_username
        });

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        // eslint-disable-next-line no-undef
        dataLayer?.push({
          event: 'custom_event',
          event_category: 'registration',
          event_action: 'form_success',
          event_label: 'registration',
          event_value: null,
          event_context: null,
          event_content: 'cross_element',
          user_auth: '1',
          user_id: user.id
        });
      }
    }
  }, [user]);

  return children;
};

export const withAnalytics = (Component: ComponentType<{}>) => () => (
  <Provider>
    <Component />
  </Provider>
);
