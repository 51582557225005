import { calcPurchaseCPMPlan } from 'fsd/entities/purchase';

import useTableMetrics from '../../../lib/useTableMetrics';
import { ColumnComponentExporter } from '../../../types';

const sorter = (
  metrics?: ReturnType<typeof useTableMetrics>
): ColumnComponentExporter['sorter'] => ({
  compare: (a, b) => {
    if (!a.common_purchase_data || !b.common_purchase_data) return 0;

    const aResult =
      calcPurchaseCPMPlan(
        a,
        metrics?.reaches?.channels?.find((c) => c.channel_id === a.channel.info.id)
      ) ?? 0;
    const bResult =
      calcPurchaseCPMPlan(
        b,
        metrics?.reaches?.channels?.find((c) => c.channel_id === b.channel.info.id)
      ) ?? 0;
    return bResult - aResult;
  }
});

export default sorter;
