import { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconFilters } from 'fsd/shared/icons';
import styled from 'styled-components';

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 62px;
`;

const TabDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 220px;
`;

const TitleRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
`;

const TitleTypography = styled.div`
  color: ${({ theme }) => theme.blackRock};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChannelCatalogIconContainer = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.persianBlue};
`;

const Wrapper = styled.div`
  margin: 13px 16px;
`;

const ChannelCatalogTabLabel: FC = () => {
  return (
    <Wrapper>
      <div className="ts-tabs-tab-wrapper">
        <div className="ts-tabs-tab-heading">
          <TabWrapper>
            <ChannelCatalogIconContainer>
              <Icon component={IconFilters} />
            </ChannelCatalogIconContainer>

            <TabDescription className="usiteful__catalog-tab">
              <TitleRow>
                <TitleTypography>Каталог каналов</TitleTypography>
              </TitleRow>
            </TabDescription>
          </TabWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChannelCatalogTabLabel;
