import { ComponentType } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';

export const withErrorBoundary = (Component: ComponentType<{}>) => () => {
  return (
    <Sentry.ErrorBoundary>
      <Component />
    </Sentry.ErrorBoundary>
  );
};
