import { ChannelRead, useGetV2ChannelsByMetricTypeQuery } from 'api/api';
import { generateDateRangeByTimeWindow } from 'fsd/shared/lib/helpers/tools/dates';

export const useDetailedParticipants = (channel: ChannelRead | undefined, now: string) => {
  const isSkip = !channel || !channel?.id;

  const { data: today, isLoading: isLoadingToday } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channel?.id || ''],
      metricType: 'participantsDiffs',
      // "today" | "yesterday" | "week" | "month" | "all"
      dateRange: generateDateRangeByTimeWindow('today', now),
      historicalMetricsLoaded: channel?.historical_metrics_loaded || false,
      timeZone: 'Europe/Moscow'
    },
    { skip: isSkip }
  );

  const { data: yesterday, isLoading: isLoadingYesterday } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channel?.id || ''],
      metricType: 'participantsDiffs',
      dateRange: generateDateRangeByTimeWindow('yesterday', now),
      historicalMetricsLoaded: channel?.historical_metrics_loaded || false,
      timeZone: 'Europe/Moscow'
    },
    { skip: isSkip }
  );

  const { data: week, isLoading: isLoadingWeek } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channel?.id || ''],
      metricType: 'participantsDiffs',
      dateRange: generateDateRangeByTimeWindow('week', now),
      historicalMetricsLoaded: channel?.historical_metrics_loaded || false,
      timeZone: 'Europe/Moscow'
    },
    { skip: isSkip }
  );

  const { data: month, isLoading: isLoadingMonth } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channel?.id || ''],
      metricType: 'participantsDiffs',
      dateRange: generateDateRangeByTimeWindow('month', now),
      historicalMetricsLoaded: channel?.historical_metrics_loaded || false,
      timeZone: 'Europe/Moscow'
    },
    { skip: isSkip }
  );

  const participantsToday = today?.channels?.[0]?.history?.[0] || 0;
  const participantsYesterday = yesterday?.channels?.[0]?.history?.[0] || 0;
  const participantsWeek = week?.channels?.[0]?.history?.[0] || 0;
  const participantsMonth = month?.channels?.[0]?.history?.[0] || 0;

  return {
    participantsToday,
    participantsYesterday,
    participantsWeek,
    participantsMonth,
    isLoading: isLoadingToday || isLoadingYesterday || isLoadingWeek || isLoadingMonth
  };
};
