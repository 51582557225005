import { Radio } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Group = styled(Radio.Group)`
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 8px;
    flex-wrap: nowrap;
  }
`;

export const Button = styled(Radio.Button)`
  @media ${MEDIA_DEVICE.mobileL.to} {
    max-height: 26px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 16px;
    flex: 1;

    &:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: none;
    }
  }
`;
