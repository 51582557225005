import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { Icon } from '../../Icon';

const Root = styled.button`
  --icon-color: ${({ theme }) => theme.violetPowder};
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  border-radius: 4px;
  color: ${({ theme }) => theme.blackRock};
  background: transparent;
  transition: background 0.3s ease;
  font-size: 14px;
  font-weight: 600;
  line-height: 114%;
  &:hover {
    background: ${({ theme }) => theme.alpineGoat};
    --icon-color: ${({ theme }) => theme.bayFog};
  }
`;
const IconWrapper = styled(Icon)`
  color: var(--icon-color);
  transition: color 0.3s ease;
`;

type Props = {
  icon?: ReactNode;
} & HTMLAttributes<HTMLButtonElement>;

const Item: FC<PropsWithChildren<Props>> = ({ children, icon, ...restProps }) => {
  return (
    <Root {...restProps}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </Root>
  );
};

export default memo(Item);
