import { FC, ReactNode } from 'react';

import * as S from './Field.styles';

type Props = {
  label: ReactNode;
  value: ReactNode;
};

const Field: FC<Props> = ({ label, value }) => {
  return (
    <S.Root>
      <S.Label>{label}</S.Label>
      <S.Value>{value}</S.Value>
    </S.Root>
  );
};

export default memo(Field);
