import styled, { css } from 'styled-components';

import { Icon as _Icon } from '../Icon';

export type RootProps = {
  variant?: 'secondary' | 'outlined' | 'outlined-primary';
  size?: 'm';
};
export const Root = styled.div<RootProps>`
  display: flex;
  border-radius: 8px;
  ${({ theme, variant }) => {
    switch (variant) {
      case 'secondary':
        return css`
          color: ${theme.blackRock};
          background-color: ${theme.white};
        `;
      case 'outlined':
        return css`
          background-color: ${theme.white};
          color: ${theme.blackRock};
          border: 1px solid ${theme.persianBlue};
        `;
      case 'outlined-primary':
        return css`
          background-color: ${theme.selectRow};
          border: 1px solid ${theme.persianBlue};
        `;
      default:
        return css`
          background-color: ${theme.selectRow};
        `;
    }
  }}
  ${({ size }) =>
    size === 'm'
      ? css`
          --icon-gap: 10px;
          padding: 16px;
        `
      : css`
          --icon-gap: 4px;
          padding: 8px 10px;
        `}

  a {
    text-decoration: underline;
  }

  ul {
    padding: 0;
    padding-left: 18px;
    margin: 0;
  }
`;

export const Icon: typeof _Icon = styled(_Icon)`
  flex: none;
  margin-right: var(--icon-gap);
`;

export const Content = styled.div`
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  line-height: 114%;
  strong {
    color: ${({ theme }) => theme.persianBlue};
    font-size: 14px;
    font-weight: 600;
  }
`;
