import { FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';
import { formatNumber } from 'fsd/shared/lib/helpers/tools';

import useGetChannelCpm from '../../../Table/lib/useGetChannelCpm';
import EmtpyData from '../EmtpyData';

import * as S from './CPM.styles';

type TProps = {
  channel: ChannelWithExchangeInfo;
  selectedFormatId: number | undefined;
};

const Cpm: FC<TProps> = ({ channel, selectedFormatId }) => {
  const cpm = useGetChannelCpm({
    channelId: channel.info.id,
    activeFormatId: selectedFormatId,
    channelFormats: channel.exchange_info?.formats
  });

  if (cpm.isEmpty) {
    return <EmtpyData isLoading={cpm.isLoading} timeWindow={false} isHideChart />;
  }

  return <S.Text>{`${formatNumber(cpm.value, 1)}`.replace('.0', '')} ₽</S.Text>;
};

export default memo(Cpm);
