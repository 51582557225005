import { FC, PropsWithChildren, ReactNode } from 'react';
import { BalanceTypes } from 'fsd/entities/balance/model/types';
import { ModalSide } from 'fsd/shared/ui/Modal';

import { BalanceInfoWithData } from '../..';

import * as S from './styles';

type Props = {
  info?: ReactNode;
  open?: boolean;
  title?: string;
  balance?: BalanceTypes[];
  onOpenChange?: (open: boolean) => void;
};

const Modal: FC<PropsWithChildren<Props>> = ({
  open,
  balance = ['active', 'blocked'],
  onOpenChange,
  title = 'Недостаточно средств',
  info,
  children
}) => {
  const handleClose = useCallback(() => {
    onOpenChange?.(false);
  }, [onOpenChange]);
  return (
    <ModalSide
      open={open}
      destroyOnClose
      onClose={handleClose}
      onCancel={handleClose}
      title={title}
    >
      {Boolean(balance.length) && (
        <S.Balance>
          {balance.map((type) => (
            <BalanceInfoWithData type={type} key={type} />
          ))}
        </S.Balance>
      )}
      {info && (
        <S.Info size="m" variant="outlined-primary">
          {info}
        </S.Info>
      )}
      <S.Slot>{children}</S.Slot>
    </ModalSide>
  );
};

export default memo(Modal);
