import { Checkbox as AntdCheckbox, Collapse } from 'antd5';
import styled from 'styled-components';

import Checkbox from '../Checkbox';

export const Root = styled(Collapse)`
  border: 1px solid ${({ theme }) => theme.sweetFlower};
  border-radius: 6px;
  .ant-collapse-header {
    padding: 18px 16px;
    padding-right: 44px;
    .ant-collapse-item:not(:last-child) & {
      border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
    }
  }
  .ant-collapse-content-box {
    padding: 0 24px !important;
    background: ${({ theme }) => theme.alpineGoat};
  }
`;

export const GroupPanel = styled(Collapse.Panel)``;

export const Group = styled(AntdCheckbox.Group)`
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    margin: 0;
  }
`;

export const Item = styled(Checkbox)`
  padding: 10px 16px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.sweetFlower};
  }
`;
