import { DatePickerProps } from 'antd5';
import { Dayjs } from 'dayjs';
import { range } from 'remeda';

type DisabledTime = Exclude<DatePickerProps['disabledTime'], undefined>;

export default (minTime?: Dayjs) => {
  if (!minTime) return undefined;
  const createDisables = useCallback(
    (date: Dayjs): ReturnType<DisabledTime> => {
      const selected = {
        h: date.get('hours'),
        m: date.get('minutes'),
        s: date.get('seconds'),
        ms: date.get('milliseconds')
      };
      const hours = minTime.get('hours');
      const minutes = selected.h === hours && minTime.get('minutes');
      const seconds = selected.m === minutes && minTime.get('seconds');
      const milliseconds = selected.s === seconds && minTime.get('milliseconds');
      return {
        disabledHours: () => range(0, hours),
        disabledMinutes: () => (minutes ? range(0, minutes) : []),
        disabledSeconds: () => (seconds ? range(0, seconds) : []),
        disabledMilliseconds: () => (milliseconds ? range(0, milliseconds) : [])
      };
    },
    [minTime]
  );
  const disabledTime = useCallback<DisabledTime>(
    (date: Dayjs) => {
      if (!date.isSame(minTime, 'date')) return {};
      return createDisables(date);
    },
    [createDisables, minTime]
  );

  return disabledTime;
};
