import { FC, HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import { PopoverProps } from 'antd';
import { IconMoreMini } from 'fsd/shared/icons';

import { Button } from '../Buttons';

import ItemElement from './Item';
import List from './List';

import * as S from './styles';

type Item = {
  key?: string;
  label: ReactNode;
  icon?: ReactNode;
  props?: HTMLAttributes<HTMLButtonElement>;
};

type Props = PopoverProps & {
  items: Item[];
};

const CompactMenu: FC<Props> = ({
  trigger = 'click',
  items,
  placement = 'leftTop',
  ...restProps
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = useCallback((open: boolean) => setOpen(open), []);

  const handleClick = useCallback(
    (itemProps?: Item['props']): MouseEventHandler<HTMLButtonElement> => {
      return (event) => {
        setOpen(false);
        itemProps?.onClick?.(event);
      };
    },
    []
  );
  return (
    <S.Root
      trigger={trigger}
      placement={placement}
      destroyTooltipOnHide
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName="compact-menu"
      showArrow={false}
      align={{ offset: [10] }}
      {...restProps}
      content={
        <List>
          {items.map(({ key, icon, label, props }) => (
            <ItemElement {...props} key={key} icon={icon} onClick={handleClick(props)}>
              {label}
            </ItemElement>
          ))}
        </List>
      }
    >
      <Button square type="ghost" size="small" startIcon={<IconMoreMini />} />
    </S.Root>
  );
};

export default memo(CompactMenu);
