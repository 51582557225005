import { FC } from 'react';
import { ContainerExport } from 'fsd/entities/container';
import { ExportFieldKeys, ExportValue } from 'fsd/entities/container/types/export';
import { ModalSide } from 'fsd/shared/ui/Modal';

import ExcelAction from '../Actions/Excel';
import GoogleSheetsAction from '../Actions/GoogleSheets';

import * as S from './styles';

type Props = {
  containerId: string;
  open: boolean;
  onClose: () => void;
};

const Modal: FC<Props> = ({ open, onClose, containerId }) => {
  const [type, setType] = useState<ExportValue>('excel');
  const [fields, setFields] = useState<ExportFieldKeys>([]);
  return (
    <ModalSide
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title="Экспорт кампании"
      destroyOnClose
      footer={
        type === 'excel' ? (
          <ExcelAction containerId={containerId} fields={fields} />
        ) : (
          <GoogleSheetsAction containerId={containerId} fields={fields} />
        )
      }
    >
      <ContainerExport.Types value={type} onSelect={setType} />
      <S.Body>
        <S.BodyTitle>Выберите необходимые показатели для экспорта</S.BodyTitle>
        <S.BodyBlock>
          <ContainerExport.Fields value={fields} onChange={setFields} />
        </S.BodyBlock>
      </S.Body>
    </ModalSide>
  );
};

export default memo(Modal);
