/* eslint-disable no-unused-expressions */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Currency,
  Format,
  useLazyGetV1ExchangePurchasesContainersByContainerIdQuery,
  useLazyGetV1ExchangePurchasesContainersQuery,
  usePatchV1ExchangePurchaseUpdateMutation
} from 'api/api';
import { useGetUserChannelFormatsQuery } from 'fsd/entities/format';
import { AddChannelToNewPurchaseForm } from 'fsd/features/addChannelToPurchase';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import { FormatsType } from 'pages/catalog/components/Table/components/Row/components/Price/Action';

export type FormValues = {
  container_channel_id: string;
  title: string;
  budget: number;
  currency: Currency;
};

type Props = {
  channelId?: string[];
  onSubmit?(): void;
  setFormIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  showSubmitBtn?: boolean;
  isOpenTheSameTab?: boolean;
  format?: FormatsType[number];
};

const FormNewContainer = forwardRef((props: Props, ref: any) => {
  const { channelId, format } = props;

  const { data: formatsData = [] } = useGetUserChannelFormatsQuery(
    { formatsId: [format?.format_id!] },
    {
      skip: !format?.format_id
    }
  );

  const getFormatData = useCallback(
    ({ format_id: formatId }: Exclude<typeof format, undefined>): Format | undefined => {
      const formatData = formatsData.find((f) => f.format_id === formatId);
      if (!formatData) return;
      // eslint-disable-next-line consistent-return
      return {
        time_in_ad_hours: formatData.time_in_feed_minutes,
        time_in_top_hours: formatData.time_in_top_minutes
      };
    },
    [formatsData]
  );

  const [getContainersList] = useLazyGetV1ExchangePurchasesContainersQuery();
  const [getContainer] = useLazyGetV1ExchangePurchasesContainersByContainerIdQuery();
  const [updatePurchase] = usePatchV1ExchangePurchaseUpdateMutation();

  const navigate = useNavigate();

  const handleSuccess = useCallback(
    (containerId: string) => {
      if (!channelId) return;
      getContainer({
        containerId
      })
        .unwrap()
        .then((container) => {
          const purchase = container.find((purchase) => purchase.channel.info.id === channelId[0]);
          if (purchase && format) {
            const formatData = getFormatData(format);
            if (formatData) {
              updatePurchase({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                updatePurchase: {
                  ...purchase,
                  purchase_id: purchase.purchase_id,
                  currency: 'RUB',
                  format: formatData,
                  cost: format.priceNumber,
                  date: null,
                  cpm_amount: null,
                  cps_amount: null
                }
              })
                .unwrap()
                .then(() => {
                  navigate(`/campaigns/${containerId}`);
                  getContainersList({});
                  CustomToast({
                    type: 'success',
                    message: <ToastMessage title="Канал добавлен в новый список кампаний" />
                  });
                });
            }
          } else {
            navigate(`/campaigns/${containerId}`);
            getContainersList({});
            CustomToast({
              type: 'success',
              message: <ToastMessage title="Канал добавлен в новый список кампаний" />
            });
          }
        });
    },
    [channelId, format, getContainer, getContainersList, getFormatData, navigate, updatePurchase]
  );

  return <AddChannelToNewPurchaseForm onSuccess={handleSuccess} channelId={channelId?.[0]} />;
});

export default memo(FormNewContainer);
