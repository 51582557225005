import { FC, PropsWithChildren, ReactNode, RefObject } from 'react';
import { Empty } from 'antd';
import { TableProps } from 'antd5';
import { type AnyObject } from 'antd5/es/_util/type';
import { mergeDeep } from 'remeda';

import createColumn from '../../lib/createColumn';
import Body, { VirtualBody } from '../Body';
import { BodyCell, VirtualBodyCell } from '../BodyCell';
import Header from '../Header';
import HeaderCell from '../HeaderCell';
import Row, { VirtualRow } from '../Row';

import * as S from './styles';

type AdditionalProps<T = AnyObject> = {
  // bodyRowWrapper?: FC<PropsWithChildren<{ record: T }>>;
};

type Props = S.RootProps<unknown> & AdditionalProps<unknown>;

const Table: FC<Props> = ({
  children,
  components,
  columns,
  dataSource,
  rowKey,
  placeholder,
  loading,
  onRow: propsOnRow,
  virtual,
  ...restProps
}) => {
  const onRow = useCallback(
    (record: unknown) => ({ ...propsOnRow?.(record), record }),
    [propsOnRow]
  );

  const mergedComponents = useMemo(() => {
    return mergeDeep(
      {
        header: {
          wrapper: Header,
          cell: HeaderCell
        },
        body: {
          row: virtual ? VirtualRow : Row,
          wrapper: virtual ? VirtualBody : Body,
          cell: virtual ? VirtualBodyCell : BodyCell
        }
      } satisfies TableProps['components'],
      // @ts-ignore
      components ?? {}
    );
  }, [components, virtual]);

  const preparedColumns = columns?.map(createColumn);

  return (
    <S.Root
      {...restProps}
      columns={preparedColumns}
      dataSource={dataSource}
      rowKey={rowKey}
      virtual={virtual}
      components={mergedComponents}
      locale={{
        emptyText: <Empty description={loading ? 'Загрузка...' : 'Нет данных'} />
      }}
      onRow={onRow}
    >
      {children}
    </S.Root>
  );
};

export default memo(Table) as <T = AnyObject>(
  // eslint-disable-next-line no-unused-vars
  props: S.RootProps<T> & { ref?: RefObject<HTMLDivElement> } & AdditionalProps<T>
) => ReactNode;
