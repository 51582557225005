import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { GoToCatalogWithCart } from 'fsd/features/goToCatalogWithCart';
import { IconLineArrowLeft } from 'fsd/shared/icons';

const BackToCatalog = () => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  return (
    <GoToCatalogWithCart
      containerId={cartContainerId}
      buttonProps={{ startIcon: <IconLineArrowLeft />, type: 'outlined' }}
    >
      Назад к каталогу
    </GoToCatalogWithCart>
  );
};

export default BackToCatalog;
