import { FC } from 'react';

import Modal from '../../ui/Modal';

import Form from './Form';

type Props = {
  open?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
};

const ModalWithForm: FC<Props> = ({ open, onClose, onSuccess }) => {
  const handleSuccess = useCallback(() => {
    onSuccess?.();
  }, [onSuccess]);

  return (
    <Modal open={open} onCancel={onClose}>
      <Form onSuccess={handleSuccess} />
    </Modal>
  );
};

export default memo(ModalWithForm);
