import { IconMoneyEye } from 'fsd/shared/icons';
import { colNames } from 'fsd/shared/lib/helpers/constants/channelConstants';

import { TableDataContext } from '../../../lib/tableDataContext';
import SortableTitle from '../../SortableTitle';

const Title = () => {
  const tableDataContext = useContext(TableDataContext);
  return (
    tableDataContext && (
      <SortableTitle
        className="usiteful__column-cpm"
        tooltipText={
          <>
            <strong>Cost per Mille (CPM)</strong> <br />
            Средняя стоимость 1000 показов
          </>
        }
        icon={<IconMoneyEye />}
        text={colNames.cpm}
        name="cpm"
        form={tableDataContext.form}
        values={tableDataContext.formValues}
      />
    )
  );
};

export default Title;
