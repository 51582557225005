import useERRMetrics from './useERRMetrics';

export default (channelId: string) => {
  const { err } = useERRMetrics(channelId);
  const value = err?.history?.[0];
  return {
    value,
    isEmpty: typeof value !== 'number'
  };
};
