import styled from 'styled-components';

import { BaseCellContentStyles } from '../BaseCell.styles';

export const Content = styled.div`
  ${BaseCellContentStyles}
  font-size: 12px;
  font-weight: 400;
  line-height: 114%;
  color: ${({ theme }) => theme.blackRock};
  height: 32px;
  display: flex;
  align-items: center;
`;

export const Root = styled.th<{ $center?: boolean }>`
  z-index: isolated;
  border-radius: 0 !important;
  border-start-start-radius: 0 !important;
  padding: 0 !important;
  min-width: min-content;
  background: var(--header-background-color);
  border-bottom: 1px solid var(--table-border-color) !important;
  .ant-table-ping-right &.ant-table-cell-fix-right-first {
    &:before {
      opacity: 1;
    }
  }
  &&:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px !important;
    height: auto !important;
    transform: none !important;
    top: -1px !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: -1;
    opacity: 0;
    background: none !important;
    pointer-events: none;
    box-shadow: var(--table-right-shadow);
    border-left: 1px solid ${({ theme }) => theme.alpineGoat};
    border-top: 1px solid ${({ theme }) => theme.alpineGoat};
    border-top-left-radius: 20px;
    transition: opacity 0.3s ease !important;
  }
  &:after {
    display: none;
  }
  .ant-table-column-title {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    user-select: none;
  }
  .ant-table-column-sorters {
    justify-content: start;
    flex: none;
    gap: 4px;
  }
  .ant-table-column-title {
    flex: none;
  }
  ${Content} {
    justify-content: ${({ $center }) => ($center ? 'center' : 'start')};
  }
`;
