import { ComponentProps, FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { useLazyGetUserOrganisation } from 'fsd/entities/user';
import { CreateOrganisationModal } from 'fsd/features/createOrganisation';
import { IconLineArrowRight } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';

type Props = ComponentProps<typeof Button>;

const GoToCartButton: FC<PropsWithChildren<Props>> = ({ disabled, children, ...restProps }) => {
  const [getUserOrganisation] = useLazyGetUserOrganisation();
  const [createOrganisationOpen, setCreateOrganisationOpen] = useState(false);
  const cartContainerId = useAppSelector(getCartContainerIdSelector);

  const navigate = useNavigate();

  const goToCart = useCallback(
    () => navigate(`/campaigns/${cartContainerId}`),
    [cartContainerId, navigate]
  );

  const handleClick = useCallback(() => {
    getUserOrganisation().then((res) => {
      if (res?.data?.data) {
        goToCart();
      } else {
        setCreateOrganisationOpen(true);
      }
    });
  }, [getUserOrganisation, goToCart]);

  const handleCreateOrganisationClose = useCallback(() => {
    setCreateOrganisationOpen(false);
  }, []);

  const handleCreateOrganisationSuccess = useCallback(() => {
    goToCart();
  }, [goToCart]);

  const handleCreateOrganisationLater = useCallback(() => {
    goToCart();
  }, [goToCart]);

  return (
    <>
      <CreateOrganisationModal
        open={createOrganisationOpen}
        onClose={handleCreateOrganisationClose}
        onSuccess={handleCreateOrganisationSuccess}
        allowLater
        onLater={handleCreateOrganisationLater}
      />
      <Button
        endIcon={<IconLineArrowRight />}
        type="primary"
        {...restProps}
        onClick={handleClick}
        disabled={!cartContainerId || disabled}
      >
        {children || 'Перейти к оформлению'}
      </Button>
    </>
  );
};

export default memo(GoToCartButton);
