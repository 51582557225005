import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled, { css } from 'styled-components';

export const BlockContainer = styled.div<{ zoom?: boolean }>`
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 20px;
  padding: 0 16px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  grid-row: span 3;

  @media ${MEDIA_DEVICE.laptop.from} {
    padding: 0 16px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    padding: 0 24px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    border-radius: 14px;

    ${({ zoom }) =>
      zoom &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 1200;
      `};
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
`;

export const HeaderWrapper = styled.div`
  z-index: 4;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.white};
  padding-top: 16px;
  padding-bottom: 4px;

  @media ${MEDIA_DEVICE.laptop.from} {
    padding-top: 16px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    padding-top: 24px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding-bottom: 3px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  color: ${({ theme }) => theme.blackRock};
  display: flex;
  align-items: center;
  gap: 17px;
  padding-left: 2px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

export const IconWrap = styled.div`
  padding-left: 8px;
  display: flex;
  justify-content: center;
`;

export const SwitchWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .ant-switch {
    width: 29px;
    height: 16px;
  }

  .ant-switch-handle {
    width: 12px;
    height: 12px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 2px);
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 4px;
  }
`;

export const Label = styled.label`
  display: flex;
`;

export const RepostsLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.black};
  margin-right: 2px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-right: 4px;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    z-index: 1;
    max-height: calc(100vh - 500px);
  }
`;

export const IconWrapper = styled.div`
  padding-right: 1px;
  cursor: pointer;
  display: flex;

  .ant-picker-input {
    display: none;
  }
`;

export const BigIconRepost = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  color: ${({ theme }) => theme.persianBlue};

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

export const ErrorTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.maWhite};
`;

export const ColumnDiv = styled.div`
  background-color: ${({ theme }) => theme.alpineGoat};
  height: 32px;

  padding: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${MEDIA_DEVICE.mobileL.to} {
    height: 24px;
  }
`;

export const ColumnDiv1 = styled(ColumnDiv)`
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;

  justify-content: flex-start;
`;

export const ColumnDiv2 = styled(ColumnDiv)`
  padding-left: 21px;
  justify-content: flex-start;
`;

export const ColumnDiv3 = styled(ColumnDiv)`
  padding-left: 33px;
  justify-content: flex-start;

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding-left: 9px;
  }
`;

export const ColumnDiv4 = styled(ColumnDiv)`
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding-left: 0px;
  }
`;
