import { ReactComponent as ChannelsIcon } from 'fsd/shared/icons/channels.svg';
import { Input } from 'fsd/shared/ui/Input';
import styled from 'styled-components';

export const SubjectItem = styled.div<{ order: number; pinned: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  color: ${({ pinned, theme }) => (pinned ? theme.delftBlue : 'transparent')};
  order: ${({ order }) => order};

  label {
    display: flex;
    gap: 8px;
  }
`;

export const DropdownContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  max-height: calc(100svh - 168px);
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: #bcbec0 #f5f5f5;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SubjectsSearch = styled(Input)`
  margin-bottom: 8px;
`;

export const SearchIcon = styled(ChannelsIcon)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.bayFog};
`;
