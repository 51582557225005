import { FC, memo } from 'react';
import styled from 'styled-components';

import { AltMobileSkeleton } from './AltMobileSkeleton';
import { CardSkeleton } from './CardSkeleton';
import { DefaultSkeleton } from './DefaultSkeleton';
import { GraphSkeleton } from './GraphSkeleton';
import { MobileListSkeleton } from './MobileListSkeleton';
import { MobileSkeleton } from './MobileSkeleton';

export const RootPaper = styled.div`
  border-radius: 20px;
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px 100px 80px 0px rgba(179, 175, 214, 0.07),
    0px -4px 64px 0px rgba(0, 0, 0, 0.07);
  height: 100%;

  svg {
    height: 100%;
    width: 100%;
  }

  .mobile-skeleton {
    max-height: 108px;
  }

  .mobile-graph-skeleton {
    max-height: 230px;
    height: 230px;
  }

  .alt-mobile-skeleton {
    max-height: 91px;
  }

  .mobile-list-skeleton {
    max-height: 337px;
    height: 337px;
  }
`;

type Props = {
  type: 'card' | 'list' | 'mobile-list' | 'mobile' | 'alt-mobile' | 'graph';
  id?: number;
  modificator?: boolean;
  animation?: boolean;
};

export type SkeletonProps = {
  id: number;
  getWidth: (width: number) => string;
  getHeight: (height: number) => string;
};

const Skeleton: FC<Props> = ({ type, id = 1, modificator = false, animation = true }) => {
  const widthMap = {
    card: 732,
    list: 732,
    'mobile-list': 342,
    mobile: 342,
    'alt-mobile': 342,
    graph: 334
  };

  const heightMap = {
    card: 245,
    list: 777,
    mobile: 100,
    'alt-mobile': 88,
    graph: 210,
    'mobile-list': 337
  };

  const getWidth = (width: number) => {
    const oldViewbox = widthMap[type];
    const newWidth = (width / oldViewbox) * 100;
    return `${newWidth.toFixed(1)}%`;
  };

  const getHeight = (height: number) => {
    const oldViewbox = heightMap[type];
    const newHeight = (height / oldViewbox) * 100;
    return `${newHeight.toFixed(1)}%`;
  };

  if (type === 'card') {
    return (
      <CardSkeleton
        getWidth={getWidth}
        getHeight={getHeight}
        id={id}
        modificator={modificator}
        animation={animation}
      />
    );
  }

  if (type === 'mobile') {
    return <MobileSkeleton getWidth={getWidth} getHeight={getHeight} id={id} />;
  }

  if (type === 'alt-mobile') {
    return <AltMobileSkeleton getWidth={getWidth} getHeight={getHeight} id={id} />;
  }

  if (type === 'graph') {
    return <GraphSkeleton getWidth={getWidth} getHeight={getHeight} id={id} />;
  }

  if (type === 'mobile-list') {
    return <MobileListSkeleton getWidth={getWidth} getHeight={getHeight} id={id} />;
  }

  return <DefaultSkeleton />;
};

export default memo(Skeleton);
