import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const DateRow = styled.div<{ $translateY: number }>`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  line-height: normal;
  padding: 16px 0;
  will-change: transform;
  ${({ theme: { white, blackRock }, $translateY }) => `
    background: ${white};
    color: ${blackRock};
    transform: translateY(${$translateY}px) translateZ(0);
  `};

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 8px 0;

    font-size: 12px;
    font-weight: 700;
  }
`;

export const Date = styled.div<{ $isSelected: boolean }>`
  position: absolute;
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  transition:
    background-color 0.4s,
    color 0.4s; /* Добавляем переходы на цвет фона и цвет текста */

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 0 2px;
  }

  ${({ theme: { blackRock, persianBlue, white }, $isSelected }) =>
    $isSelected
      ? `
          background: ${persianBlue};
          color: ${white};
        `
      : `
          background: ${white};
          color: ${blackRock};
        `}
`;
