import { FC, HTMLAttributes, Ref } from 'react';

import createCustomRow from '../../lib/createCustomRow';

import EmptyRow from './EmptyRow';
import LoaderRow from './LoaderRow';

type Props = {
  customRow: ReturnType<typeof createCustomRow>;
  ref: Ref<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

const CustomRow: FC<Props> = ({ customRow, ...restProps }) => {
  if (['loader', 'loader-clear'].includes(customRow.type))
    return <LoaderRow customRow={customRow} {...restProps} />;
  if (['empty'].includes(customRow.type)) return <EmptyRow customRow={customRow} {...restProps} />;
  return null;
};

export default memo(CustomRow);
