import styled, { css } from 'styled-components';

export type RootProps = { $layout?: 'row' | 'column' };

export const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: ${({ $layout }) => $layout ?? 'column'};
  gap: 8px;
  ${({ $layout }) =>
    $layout !== 'row' &&
    css`
      width: 100%;
    `}
`;
