import { FC } from 'react';
import {
  api,
  ChannelExchangeFormats,
  ChannelWithExchangeInfo,
  GetV2UserChannelFormatsApiResponse,
  usePatchV1UserChannelMutation,
  UserChannelExchangeFormatV2,
  UserChannelsList
} from 'api/api';
import { parseFormatTime } from 'fsd/entities/format';
import { Checkbox } from 'fsd/shared/ui/Checkbox';
import cloneDeep from 'lodash.clonedeep';

import Cost from './Cost';

import * as S from './styles';

interface IProps {
  example: UserChannelExchangeFormatV2;
  format: ChannelExchangeFormats;
  channel: ChannelWithExchangeInfo;
  channelFormats: GetV2UserChannelFormatsApiResponse;
}

const Format: FC<IProps> = ({ example, format, channel, channelFormats }) => {
  const dispatch = useAppDispatch();
  const [updateChannel, { isLoading }] = usePatchV1UserChannelMutation();

  const updateFormat = useCallback(
    ({ isActive, price }: { isActive?: boolean; price?: number }) => {
      if (!channel.exchange_info?.formats) return;

      const isEmpty = !format.prices?.[0].price;
      let newChannels: UserChannelsList | undefined;

      const { undo } = dispatch(
        api.util.updateQueryData(`getV1UserChannels`, undefined, (data) => {
          const channelData = data.find(({ info }) => info.id === channel.info.id);
          if (channelData?.exchange_info?.formats) {
            const currentFormatData = channelData.exchange_info.formats.find(
              ({ format_id: formatId }) => formatId === format.format_id
            );

            if (currentFormatData) {
              if (isEmpty) {
                currentFormatData.is_active = true;
              }
              if (typeof isActive === 'boolean') {
                currentFormatData.is_active = isActive;
              }
              if (typeof price === 'number') {
                if (currentFormatData.prices?.[0]) {
                  currentFormatData.prices[0].price = price.toString();
                } else {
                  currentFormatData.prices = [
                    {
                      currency_id: 1,
                      price: price.toString()
                    }
                  ];
                }
              }
            }

            if (!channelData.exchange_info.formats.some(({ is_active: isActive }) => isActive)) {
              channelData.exchange_info.is_exchange_active = false;
            }
          }

          newChannels = cloneDeep(data);
        })
      );

      const newChannel = (newChannels as UserChannelsList | undefined)?.find(
        (c) => c.info.id === channel.info.id
      );

      if (!newChannel) {
        undo();
        return;
      }

      updateChannel(
        (() => {
          return {
            body: {
              channel_id: channel.info.id,
              exchange_info: {
                channel_id: channel.info.id,
                formats: newChannel.exchange_info?.formats,
                ...(typeof newChannel.exchange_info?.is_exchange_active === 'boolean'
                  ? { is_exchange_active: newChannel.exchange_info.is_exchange_active }
                  : undefined)
              }
            }
          };
        })()
      )
        .unwrap()
        .catch(() => undo());
    },
    [
      channel.exchange_info?.formats,
      channel.info.id,
      dispatch,
      format.format_id,
      format.prices,
      updateChannel
    ]
  );

  const handleCostChange = useCallback(
    (value: number) => {
      updateFormat({
        price: value
      });
    },
    [updateFormat]
  );

  const handleSelect = useCallback(() => {
    updateFormat({
      isActive: !format.is_active
    });
  }, [updateFormat, format.is_active]);

  const label = parseFormatTime(example).with.short;

  const price = useMemo(
    () => (format.prices?.[0].price ? Number(format.prices?.[0].price) : undefined),
    [format.prices]
  );

  return (
    <S.Format disabled={!price} active={format.is_active}>
      <S.CheckboxWrapper>
        <Checkbox
          onChange={handleSelect}
          disabled={!price || isLoading}
          checked={format.is_active}
        />
        <S.Label>{label}</S.Label>
      </S.CheckboxWrapper>
      <Cost
        value={price}
        label={label}
        disabled={isLoading}
        onChange={handleCostChange}
        channel={channel}
        format={format}
        channelFormats={channelFormats}
      />
    </S.Format>
  );
};

export default memo(Format);
