/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useGetV1ExchangePurchasesContainersQuery } from 'api/api';

import * as S from './Mini.styles';

type TabProps = PropsWithChildren & {
  containerId: string;
};

const MiniTab: FC<TabProps> = ({ containerId }) => {
  const navigate = useNavigate();

  const { data: container } = useGetV1ExchangePurchasesContainersQuery(
    {
      isActive: true
    },
    {
      selectFromResult: (data) => ({
        ...data,
        data: data.data?.find((c) => {
          return c.container_id === containerId;
        })
      })
    }
  );

  const { title = '' } = container || {};
  return (
    <S.Root
      className="usiteful__purchase__tab"
      onKeyDown={(e) => e.stopPropagation()}
      onClick={() => navigate(`/campaigns/${containerId}`)}
    >
      {title && title?.length >= 25 ? (
        <Tooltip title={title}>
          <S.Name className="no-translation">{title}</S.Name>
        </Tooltip>
      ) : (
        <S.Name className="no-translation">{title}</S.Name>
      )}
    </S.Root>
  );
};

export default memo(MiniTab);

export const MiniTabStyles = S;
