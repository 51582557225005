import { ComponentProps, FC } from 'react';

import { DefaultProps } from '../types';

import * as S from './styles';

type Props = DefaultProps<ComponentProps<(typeof S)['Root']>> & {
  postfix?: string;
};

const Number: FC<Props> = forwardRef(
  ({ value, postfix, placeholder, borderBottom, fontWeight, isError, ...restProps }, ref) => {
    return (
      <S.Root
        $fontWeight={fontWeight}
        $borderBottom={borderBottom}
        $isError={isError}
        controls={false}
        formatter={(value, info) => {
          if (info.userTyping) return value?.toString() ?? '';
          return value ? `${(+value)?.toLocaleString('ru')}${postfix ?? ''}` : '';
        }}
        value={value}
        placeholder={placeholder && `${placeholder}${postfix ?? ''}`}
        {...restProps}
      />
    );
  }
);

export default memo(Number);
