import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import QRCode from 'fsd/shared/ui/QRCode';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const QR = styled(QRCode)`
  width: 250px;
  max-width: 100%;
  margin-bottom: 24px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 110px;
  }
`;

export const DownloadWrapper = styled.div`
  width: 100%;
`;

export const Caption = styled.span`
  margin-top: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 133%;
  color: ${({ theme }) => theme.majesticMount};
`;
