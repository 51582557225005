import ChatMessage from 'fsd/shared/ui/ChatMessage';
import { ModalSide } from 'fsd/shared/ui/Modal';
import styled from 'styled-components';

export const Post = styled.div`
  flex: 1;

  overflow: auto;
`;

export const Modal = styled(ModalSide)`
  display: flex;
  flex-direction: column;
`;

export const ChatMessageWrapper = styled(ChatMessage.Wrapper)`
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;
