import Icon from '@ant-design/icons';
import { URLs } from 'fsd/shared/config/URLs';
import { ReactComponent as TgIcon } from 'fsd/shared/icons/telegram.svg';

import { TGBtn } from './styles';

const Button = () => {
  return (
    <TGBtn
      href={URLs.TG_LOGIN}
      target="_blank"
      size="large"
      type="primary"
      block
      icon={<Icon component={TgIcon} size={18} />}
    >
      Войти с помощью Telegram
    </TGBtn>
  );
};

export default memo(Button);
