/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from '../store';

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const createAppSelectorBase = <T extends (state: RootState) => unknown>(fn: T) => fn;
export const createAppSelector = createSelector.withTypes<RootState>();
