type Result = {
  version: string;
  payload: {
    media?: Array<{ s3_file_path: string; file_id: string; type: 'Photo' }>;
    message?: string;
    entities?: Array<{ type: string; offset: number; length: number }>;
  };
};

export default (markup: string): Result | undefined => {
  try {
    const data = JSON.parse(markup);
    const payload = JSON.parse(data.payload);
    const result: Result = { ...data, payload };
    return result;
  } catch (error) {
    return undefined;
  }
};
