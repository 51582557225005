import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

import { Icon } from '../../Icon';

import { BaseProps } from './types';

export const Button = styled(AntdButton)<BaseProps>`
  --border-color: ${({ borderColor, theme }) => borderColor || theme.sweetFlower};
  --border-color_hover: ${({ borderColor, theme }) => borderColor || theme.persianBlue};
  --border-color-current: var(--border-color);
  --color: ${({ color, theme }) => color || theme.blackRock};
  --color_hover: ${({ color, theme }) => color || theme.persianBlue};
  --color-current: var(--color);
  --background: none;
  --background-current: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  font-size: 14px;
  font-weight: 600;
  color: var(--color-current) !important;
  height: 32px;

  border: 2px solid;
  border-color: var(--border-color-current) !important;
  background: var(--background-current) !important;
  border-radius: 100px;

  &&[disabled] {
    --color-current: var(--color);
    --border-color-current: var(--border-color);
    opacity: 0.7;
  }
  &&:not([disabled]):hover {
    --border-color-current: var(--border-color_hover);
    --color-current: var(--color_hover);
  }
`;

export const IconWrapper = styled(Icon).attrs(({ color, theme }) => ({
  color: color || theme.persianBlue,
  width: 20
}))`
  display: flex;
  align-items: center;
  justify-content: center;
`;
