import {
  IconAccount,
  IconCartSmall,
  IconFilters,
  IconFinances,
  IconTelegramArrows
} from 'fsd/shared/icons';
import { SideBarItem } from 'fsd/shared/ui/SideBar/types';

const items: SideBarItem[] = [
  {
    key: 'catalog',
    icon: <IconFilters />,
    label: 'Каталог',
    to: '/'
  },
  {
    key: 'management',
    icon: <IconTelegramArrows />,
    label: 'Управление каналами',
    to: '/management'
  },
  {
    key: 'campaigns',
    icon: <IconCartSmall />,
    label: 'Кампании',
    to: '/campaigns'
  },
  {
    key: 'finances',
    icon: <IconFinances />,
    label: 'Управление финансами',
    to: '/finances'
  },
  {
    key: 'settings',
    icon: <IconAccount />,
    label: 'Аккаунт',
    to: '/settings'
  }
];

export default items;
