import { LoginViaTelegram } from 'fsd/features/loginViaTelegram';
import { LogoWithText } from 'fsd/shared/ui/Logo';

import * as S from './styles';

const Login = () => {
  return (
    <S.Root>
      <LogoWithText />
      <S.Title>Добро пожаловать в Tgmarket!</S.Title>
      <LoginViaTelegram />
      <S.DocsText>
        Продолжая регистрацию, вы соглашаетесь с&nbsp;
        <a target="_blank" href="https://tg.market/agreement-and-cookies" rel="noreferrer">
          правилами сайта
        </a>
        ,{' '}
        <a target="_blank" href="https://tg.market/personal-data" rel="noreferrer">
          политикой конфиденциальности
        </a>{' '}
        и&nbsp;
        <a target="_blank" href="https://tg.market/oferta" rel="noreferrer">
          договором-офертой
        </a>
      </S.DocsText>
    </S.Root>
  );
};

export default memo(Login);
