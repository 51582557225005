import styled from 'styled-components';

export const Root = styled.div``;

export const PostTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.blackRock};
  margin-bottom: 16px;
  display: block;
`;
