// NOTE: это функция для deep comparison by value.
// Не работает с объектами с кольцевыми ссылками.

export const isEqual = (arg1: any, arg2: any): boolean => {
  if (arg1 === null || arg2 === null) return arg1 === arg2;
  if (typeof arg1 !== typeof arg2) {
    return false;
  }
  if (typeof arg1 !== 'object' && typeof arg2 !== 'object') {
    return arg1 === arg2;
  }
  if (Array.isArray(arg1) && Array.isArray(arg2)) {
    if (arg1.length !== arg2.length) {
      return false;
    }
    for (let i = 0; i < arg1.length; i++) {
      if (!isEqual(arg1[i], arg2[i])) {
        return false;
      }
    }
    return true;
  }
  if (typeof arg1 === 'object' && typeof arg2 === 'object') {
    const keysArg1 = Object.keys(arg1);
    const keysArg2 = Object.keys(arg2);
    if (keysArg1.length !== keysArg2.length) {
      return false;
    }
    for (let j = 0; j < keysArg1.length; j++) {
      const key = keysArg1[j];
      if (!isEqual(arg1[key], arg2[key])) {
        return false;
      }
    }
    return true;
  }
  return false;
};
