import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ChannelIconWrap = styled.div`
  display: inline-block;
  vertical-align: top;

  > * {
    width: 100%;
  }

  width: 36px;
  height: 36px;
  min-width: 36px;

  margin-top: 0px;
`;

export const ChannelIcon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const ChannelFakeIcon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.black};
`;

export const SearchRowWrapper = styled.button`
  display: flex;
  padding: 13px 12px 5px;
  gap: 6px;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.alpineGoat};
  }
`;

export const SearchRowWrapperLink = styled(Link)`
  border: 1px solid pink;
  display: flex;
  padding: 13px 12px 5px;
  gap: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.alpineGoat};
  }
`;

export const Name = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.009em;
  color: ${({ theme }) => theme.blackRock};
  margin-bottom: 4px;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.majesticMount};
  margin-bottom: 0;
  max-width: 700px;
`;
