import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 114%;
  small {
    font-size: 12px;
    font-weight: inherit;
    color: ${({ theme }) => theme.bayFog};
  }
`;

export const Value = styled.strong`
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
`;
