import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    const formatA = a.format.time_in_ad_hours + a.format.time_in_top_hours;
    const formatB = b.format.time_in_ad_hours + b.format.time_in_top_hours;

    return formatB - formatA;
  }
};

export default sorter;
