import { ComponentType } from 'react';
import { Provider } from 'react-redux';
import { store } from 'fsd/app/redux/store';

export const withStore = (Component: ComponentType<{}>) => () => {
  return (
    <Provider store={store}>
      <Component />
    </Provider>
  );
};
