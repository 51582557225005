import { FC } from 'react';
import { Popover, PopoverProps } from 'antd5';

export const PopoverUI: FC<PopoverProps> = (props) => (
  <Popover
    {...props}
    overlayClassName="custom-popover"
    transitionName="ant-fade"
    motion={{
      motionName: 'ant-fade'
    }}
  />
);

export default PopoverUI;
