import { FC } from 'react';
import { Popover } from 'antd';
import { IconLinkHorizontal } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './styles';

type Props = {
  value?: string;
  formRender: FC<{ value?: string }>;
};

const InviteLink: FC<Props> = ({ value, formRender: FormRender }) => {
  return (
    <Popover
      trigger="click"
      placement="bottom"
      overlayStyle={{ zIndex: 1200 }}
      content={
        <S.Content>
          <S.Header>
            <S.Title>Пригласительная ссылка</S.Title>
            <S.Info>Гиперссылка, используемая в креативе</S.Info>
          </S.Header>
          <FormRender value={value} />
        </S.Content>
      }
    >
      <S.Slot>
        <Icon
          width={14}
          style={{ position: 'relative' }}
          color={value ? theme.americanGreen : theme.strawberryRed}
        >
          <IconLinkHorizontal />
        </Icon>
      </S.Slot>
    </Popover>
  );
};

export default memo(InviteLink);
