import { useState } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';
import { format } from 'date-fns';
import { createNow, parseISOWithoutTimeZone } from 'fsd/shared/lib/helpers/tools/dates';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';

import { isParticipantsDelta } from '../../utils/isParticipantsDelta';
import { DateRow } from '../DateRow/DateRow';
import { DeltaRow } from '../DeltaRow/DeltaRow';
import Row from '../Row';
import { SkeletonRow } from '../SkeletonRow/SkeletonRow';

type Props = {
  values: any[];
  virtualItems: VirtualItem[];
  isLoading: boolean;
  indexColorDate: number | null;
};

export const TableBody = ({ virtualItems, values, isLoading, indexColorDate }: Props) => {
  const [now] = useState(createNow());

  return (
    <tbody>
      {virtualItems.map((virtualRow: VirtualItem, index: number) => {
        const value = values[virtualRow.index];
        const translateY = virtualRow.start - index * virtualRow.size;
        const isDateRow = values[virtualRow.index].type === 'day';
        const isDateRowSelected =
          isDateRow && Boolean(indexColorDate && virtualRow.index === indexColorDate);

        if (isDateRow) {
          return (
            <DateRow
              key={virtualRow.key}
              date={value.dateNew}
              translateY={translateY}
              isSelected={isDateRowSelected}
            />
          );
        }

        const parsedDate = parseISOWithoutTimeZone(value.date);
        const formattedTime = format(parsedDate, 'H:mm');
        const formattedDate = format(parsedDate, 'd.MM.yyyy');

        return (
          <tr
            data-index={virtualRow.index}
            key={virtualRow.key}
            style={{
              willChange: 'transform',
              transform: `translateY(${translateY}px) translateZ(0px)`
            }}
          >
            {isParticipantsDelta(value) ? (
              <DeltaRow delta={value.delta} time={formattedTime} />
            ) : (
              <Row data={value} time={formattedTime} date={formattedDate} to={now} />
            )}
          </tr>
        );
      })}

      {isLoading && (
        <SkeletonRow $translateY={0}>
          <LoaderSpinner fontSize={30} />
        </SkeletonRow>
      )}
    </tbody>
  );
};
