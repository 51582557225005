import styled from 'styled-components';

export const LoadMore = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Accents/Persian blue */
  margin-left: 16px;
  margin-top: 14px;
  color: #4807ea;

  cursor: pointer;
`;
