import styled from 'styled-components';

export const Root = styled.div`
  --padding-x: 16px;
  padding-top: 16px;
  overflow: hidden;
  height: 370px;
  width: 375px;
  max-width: calc(100vw - 32px);
  background: #fff;
  border-radius: 8px;
  box-shadow:
    0px -4px 64px 0px rgba(0, 0, 0, 0.07),
    0px 100px 80px 0px rgba(179, 175, 214, 0.07);
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 var(--padding-x);
`;

export const Title = styled.h4``;

export const Body = styled.div`
  padding: 0 var(--padding-x);
  margin-top: 16px;
`;

export const Content = styled.div`
  padding: 16px 14px;
  border-radius: 14px;
  background: #fff;
  box-shadow:
    0px -4px 64px 0px rgba(0, 0, 0, 0.07),
    0px 100px 80px 0px rgba(179, 175, 214, 0.07);
  max-height: 260px;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  padding: 16px 24px;
  background: #fff;
  gap: 8px;
  box-shadow:
    0px -4px 64px 0px rgba(0, 0, 0, 0.07),
    0px 100px 80px 0px rgba(179, 175, 214, 0.07);
`;
