import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { Input } from 'fsd/shared/ui/Input';

import * as S from '../../styles';

const ReachFilter: FC = () => {
  function onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  }
  return (
    <S.InputWrap>
      <Field
        name="avg-daily-reaches.minItems"
        type="number"
        parse={(value) => (value.trim() === '' ? undefined : Number(value))}
        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.preventDefault()}
        render={({ input, meta }) => (
          <S.InputWrapper>
            <S.MobileLabel>От</S.MobileLabel>
            <Input
              size="small"
              prefix="От"
              inputMode="numeric"
              placeholder="339"
              fontWeight={600}
              onKeyPress={onKeyPress}
              onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.preventDefault();
                const input = e.target as HTMLInputElement; // явно указываем тип HTMLInputElement
                input.blur(); // снимаем фокус с поля ввода
              }}
              {...input}
            />
            {meta.touched && meta.error && <S.Error>{meta.error}</S.Error>}
          </S.InputWrapper>
        )}
      />
      <Field
        name="avg-daily-reaches.maxItems"
        type="number"
        parse={(value) => (value.trim() === '' ? undefined : Number(value))}
        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.preventDefault()}
        render={({ input, meta }) => (
          <S.InputWrapper>
            <S.MobileLabel>До</S.MobileLabel>
            <Input
              size="small"
              prefix="До"
              inputMode="numeric"
              placeholder="1 000"
              fontWeight={600}
              onKeyPress={onKeyPress}
              onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.preventDefault();
                const input = e.target as HTMLInputElement; // явно указываем тип HTMLInputElement
                input.blur(); // снимаем фокус с поля ввода
              }}
              {...input}
            />
            {meta.touched && meta.error && <S.Error>{meta.error}</S.Error>}
          </S.InputWrapper>
        )}
      />
    </S.InputWrap>
  );
};

export default ReachFilter;
