import { ChannelWithExchangeInfo } from 'api/api';
import { FormApi } from 'final-form';
import { FilterState } from 'types/channels/types';

import useMetrics from './useMetrics';

export type UseTableDataParams = Omit<
  Parameters<typeof useMetrics>[0],
  'historicalMetricsLoaded' | 'channelIds'
> & {
  channels: ChannelWithExchangeInfo[];
  form: FormApi<FilterState, Partial<FilterState>>;
  formValues: Partial<FilterState>;
};

export default ({ channels, timeWindow, formValues, form }: UseTableDataParams) => {
  const channelIds = useMemo(() => channels.map((item) => item.info.id), [channels]);

  const historicalMetricsLoaded = useMemo(
    () => channels.some((item) => item.info.historical_metrics_loaded),
    [channels]
  );

  const metrics = useMetrics({
    timeWindow,
    channelIds,
    historicalMetricsLoaded
  });

  return {
    ...metrics,
    timeWindow,
    channels,
    channelIds,
    historicalMetricsLoaded,
    formValues,
    form
  };
};
