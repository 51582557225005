import { FC, HTMLAttributes } from 'react';
import { QRCodeProps } from 'antd5';
import { IconLogoMobile } from 'fsd/shared/icons';

import * as S from './styles';

type Props = {
  value: string;
  errorLevel?: QRCodeProps['errorLevel'];
  qrProps?: Omit<QRCodeProps, 'icon' | 'errorLevel' | 'value' | 'bgColor' | 'type'>;
} & HTMLAttributes<HTMLDivElement>;

const QRCode: FC<Props> = ({ value, errorLevel = 'Q', qrProps, ...restProps }) => {
  return (
    <S.Root {...restProps}>
      <S.QR
        {...qrProps}
        bgColor={theme.alpineGoat}
        type="svg"
        color={theme.darkGray}
        errorLevel={errorLevel}
        value={value}
      />
      <S.IconWrapper>
        <IconLogoMobile />
      </S.IconWrapper>
    </S.Root>
  );
};

export default memo(QRCode);
