import { ComponentProps, FC } from 'react';
import { IconFolder } from 'fsd/shared/icons';

import * as S from './styles';

type Props = {
  count: number;
} & ComponentProps<typeof S.Root>;

const Counter: FC<Props> = ({ count, color = theme.persianBlue, width = 40, ...restProps }) => {
  return (
    <S.Root color={color} width={width} {...restProps}>
      <IconFolder />
      <S.Count>{count}</S.Count>
    </S.Root>
  );
};

export default Counter;
