import { FC, FocusEventHandler, FormEventHandler, KeyboardEventHandler } from 'react';
import { PurchasesListContainer, usePatchV1ExchangePurchaseContainerMutation } from 'api/api';

import * as S from './Inline.styles';

type Props = {
  container: PurchasesListContainer;
  maxWidth?: string | number;
};

const Inline: FC<Props> = ({ maxWidth, container }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updateContainer] = usePatchV1ExchangePurchaseContainerMutation();
  const ref = useRef<HTMLSpanElement>(null);

  const update = useCallback(() => {
    ref.current?.blur();
    if (ref.current?.textContent) {
      updateContainer({
        body: {
          container_id: container.container_id,
          title: ref.current.textContent,
          note: '',
          type: container.type
        }
      });
    } else if (ref.current && container.title) {
      ref.current.textContent = container.title;
    }
  }, [container.container_id, container.title, container.type, updateContainer]);

  const handleBlur = useCallback<FocusEventHandler<HTMLSpanElement>>(
    (e) => {
      e.currentTarget.scrollLeft = 0;
      setIsEditing(false);
      update();
    },
    [update]
  );

  const handleFocus = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLSpanElement>>(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        update();
      }
    },
    [update]
  );

  useEffect(() => {
    if (ref.current && container.title) {
      ref.current.textContent = container.title;
    }
  }, [container.title]);

  return (
    <S.Root
      aria-label="input"
      role="textbox"
      aria-placeholder="container title"
      tabIndex={0}
      contentEditable="plaintext-only"
      suppressContentEditableWarning
      ref={ref}
      onFocus={handleFocus}
      style={{ maxWidth }}
      $editing={isEditing}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    >
      {container.title ?? ''}
    </S.Root>
  );
};

export default memo(Inline);
