import { SortOrder } from 'antd5/lib/table/interface';
import { IconSortFlat } from 'fsd/shared/icons';
import styled from 'styled-components';

export const Icon = styled(IconSortFlat)<{ $sortOrder: SortOrder }>`
  --scale-y: ${({ $sortOrder }) => ($sortOrder === 'descend' ? -1 : 1)};
  transform: scaleY(var(--scale-y));
  transition: 0.2s ease-in-out;
  color: ${({ theme, $sortOrder }) => ($sortOrder ? theme.persianBlue : theme.violetPowder)};
`;
