import { FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';

import { Formats } from '../Formats';

import * as S from './styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

export const FormatAndCost: FC<IProps> = ({ channel }) => {
  return (
    <S.FormatWrapper>
      <S.FormatTitle>Формат и стоимость</S.FormatTitle>
      <Formats channel={channel} />
    </S.FormatWrapper>
  );
};
