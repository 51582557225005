export type CountryList = Record<
  string,
  {
    name: string;
  }
>;

export const COUNTRY_LIST: CountryList = {
  ru: {
    name: 'Россия'
  },
  ua: {
    name: 'Украина'
  },
  kz: {
    name: 'Казахстан'
  },
  us: {
    name: 'США'
  },
  by: {
    name: 'Беларусь'
  },
  am: {
    name: 'Армения'
  },
  kg: {
    name: 'Кыргызстан'
  },
  uz: {
    name: 'Узбекистан'
  }
};
