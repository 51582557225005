import { FC } from 'react';
import { Field } from 'react-final-form';
import { Radio } from 'antd';
import { CHANNEL_TYPE } from 'types/channels/types';

import * as S from '../../styles';

const ChannelTypeFilter: FC = () => {
  return (
    <Field
      name="is_public"
      type="text"
      render={({ input, meta }) => (
        <S.SmallTabs>
          <Radio.Group id="is_public" {...input}>
            <Radio.Button value="">Не важно</Radio.Button>
            <Radio.Button value={CHANNEL_TYPE.public.value}>
              {CHANNEL_TYPE.public.name}
            </Radio.Button>
            <Radio.Button value={CHANNEL_TYPE.private.value}>
              {CHANNEL_TYPE.private.name}
            </Radio.Button>
          </Radio.Group>
          {meta.touched && meta.error && <S.Error>{meta.error}</S.Error>}
        </S.SmallTabs>
      )}
    />
  );
};

export default ChannelTypeFilter;
