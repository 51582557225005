import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  width: 100%;
  flex: 1;
  padding: 20px;

  @media (max-height: 900px) {
    align-items: start;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 10px 0 20px;
    min-height: 0;
    align-items: start;
  }
`;

export const Wrapper = styled.div`
  padding: 40px;
  border-radius: 20px;
  background: #fff;
  min-height: min(calc(100vh - 100px), 600px);
  @media ${MEDIA_DEVICE.mobileL.to} {
    min-height: 0;
    padding: 30px;
    width: 100%;
  }
  @media ${MEDIA_DEVICE.mobile.to} {
    padding: 20px;
    border-radius: 10px;
  }
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  position: relative;
  z-index: 100;
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 22px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export const NavText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: ${({ theme }) => theme.gunPowder};
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  gap: 40px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    flex-direction: column;
    margin-top: 0;
  }

  @media ${MEDIA_DEVICE.mobile.to} {
    gap: 30px;
  }
`;

export const Body = styled.div`
  width: 400px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 100%;
  }
`;
