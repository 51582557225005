import { ErrorValues, FormValues } from './types';

export const validation = (values: FormValues) => {
  const errors: ErrorValues = {};

  if (Number(values.participants?.minItems) > Number(values.participants?.maxItems)) {
    errors.participants = { minItems: '', maxItems: '' };
    errors.participants.minItems = 'Максимальное значение должно быть больше минимального';
  }
  if (Number(values.views?.minItems) > Number(values.views?.maxItems)) {
    errors.views = { minItems: '', maxItems: '' };
    errors.views.minItems = 'Максимальное значение должно быть больше минимального';
  }
  if (Number(values.avg_daily_reaches?.minItems) > Number(values.avg_daily_reaches?.maxItems)) {
    errors.avg_daily_reaches = { minItems: '', maxItems: '' };
    errors.avg_daily_reaches.minItems = 'Максимальное значение должно быть больше минимального';
  }

  if (Number(values.mentions?.minItems) > Number(values.mentions?.maxItems)) {
    errors.mentions = { minItems: '', maxItems: '' };
    errors.mentions.minItems = 'Максимальное значение должно быть больше минимального';
  }
  if (Number(values.er?.minItems) > Number(values.er?.maxItems)) {
    errors.er = { minItems: '', maxItems: '' };
    errors.er.minItems = 'Максимальное значение должно быть больше минимального';
  }

  if (Number(values.cpm?.minItems) > Number(values.cpm?.maxItems)) {
    errors.cpm = { minItems: '', maxItems: '' };
    errors.cpm.minItems = 'Максимальное значение должно быть больше минимального';
  }

  return errors;
};
