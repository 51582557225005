import { PurchaseRead } from 'api/api';
import dayjs from 'dayjs';

import useUpdatePurchase from './useUpdatePurchase';

export default () => {
  const [updatePurchase, { isLoading }] = useUpdatePurchase();

  const update = useCallback(
    (purchase: PurchaseRead, date: Date) => {
      const dayjsDate = dayjs(date);
      const time = dayjsDate.get('minutes') + 60 * dayjsDate.get('hours');
      return updatePurchase(purchase, {
        time,
        date: dayjsDate.format('YYYY-MM-DD')
      });
    },
    [updatePurchase]
  );

  return [update, { isLoading }] as const;
};
