import { FC } from 'react';
import { useGetV1ExchangePurchaseByPurchaseIdCreativeQuery } from 'api/api';
import createS3Path from 'fsd/shared/lib/createS3Path';
import ChatMessage from 'fsd/shared/ui/ChatMessage';
import { IMedia } from 'fsd/shared/ui/ChatMessage/types';

import parseMarkup from '../../lib/parseMarkup';

type Props = {
  purchaseId: string;
};

const Content: FC<Props> = ({ purchaseId }) => {
  const { data: creative, isLoading } = useGetV1ExchangePurchaseByPurchaseIdCreativeQuery(
    {
      purchaseId
    },
    {
      refetchOnFocus: true
    }
  );

  const markupData = useMemo(() => {
    if (creative?.markup) {
      return parseMarkup(creative.markup);
    }
    return undefined;
  }, [creative?.markup]);

  const media = useMemo(() => {
    return (
      markupData?.payload?.media
        ?.filter((media) => media.type === 'Photo')
        .map(
          (media): IMedia => ({
            src: createS3Path(media.s3_file_path),
            type: 'image'
          })
        ) ?? []
    );
  }, [markupData?.payload?.media]);
  return (
    <ChatMessage.Wrapper type="tg">
      <ChatMessage.Item media={media}>
        {isLoading ? 'Пост загружается...' : creative?.text ?? 'Пост пустой'}
      </ChatMessage.Item>
    </ChatMessage.Wrapper>
  );
};

export default Content;
