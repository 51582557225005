import { useGetSiteInfo } from 'fsd/shared/lib/getSiteInfo';

export default () => {
  const { get, siteInfo, isFetching, isFound } = useGetSiteInfo();

  return {
    find: get,
    siteInfo,
    isFetching,
    isFound
  };
};
