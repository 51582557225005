import { FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';

import ParticipantsValue from '../../../../Row/components/Participants/ParticipantsValue';
import useParticipantsMetrics from '../../../lib/useParticipantsMetrics';

type Props = {
  channel: ChannelWithExchangeInfo;
};

const Value: FC<Props> = ({ channel }) => {
  const { tableData, ...participants } = useParticipantsMetrics(channel);
  return (
    tableData && (
      <ParticipantsValue
        participantsAll={participants.participantsAll}
        participantsDiff={participants.channelParticipantsDiffMetrics}
      />
    )
  );
};
export default memo(Value);
