import { useCallback, useSyncExternalStore } from 'react';

export default (offset = 0) => {
  const subscribe = useCallback((callback: () => void) => {
    window.addEventListener('scroll', callback);
    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, []);

  const getSnapshot = () => {
    return window.scrollY > offset;
  };

  const getServerSnapshot = () => {
    throw Error('window is a client-only element');
  };

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
};
