import { Card } from 'fsd/shared/ui/Card';
import styled from 'styled-components';

export const Root = styled(Card)`
  min-height: 168px;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  align-items: flex-start;
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  & > * {
    width: 100%;
  }
`;
