import { ComponentProps, FC } from 'react';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import Close from '../Close';

import * as S from './Modal.styles';

type Props = {
  title?: string;
} & ComponentProps<(typeof S)['Root']>;

const Modal: FC<Props> = ({ title, children, centered, ...restProps }) => {
  const isMobile = useIsMobile();
  return (
    <S.Root
      {...restProps}
      title={title && <S.Title>{title}</S.Title>}
      footer={null}
      centered={typeof centered === 'boolean' ? centered : isMobile}
      closeIcon={<Close />}
    >
      <S.Content>{children}</S.Content>
    </S.Root>
  );
};

export default memo(Modal);
