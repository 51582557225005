import styled from 'styled-components';

export const Format = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ disabled, active }) =>
    active ? theme.persianBlue : disabled ? theme.violetPowder : theme.blackRock};
  gap: 16px;
`;

export const Formats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
`;
