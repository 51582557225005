import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 16px;
  color: ${({ theme }) => theme.delftBlue};
  position: relative;
  top: 1px;
  right: 1px;
`;

export default LoadingIcon;
