import { Card } from 'fsd/shared/ui/Card';
import { InfoBlock } from 'fsd/shared/ui/InfoBlock';
import styled from 'styled-components';

export const FormWrapper = styled(Card)`
  a {
    color: ${({ theme }) => theme.blueRibbon};
  }
`;

export const Info = styled(InfoBlock)`
  margin-top: 16px;
`;

export const Content = styled.div`
  margin-top: 8px;
`;
