import { FC } from 'react';
import { PurchaseRead } from 'api/api';
import { Table as SharedTable } from 'fsd/shared/ui/Table';

import { TableContextProvider } from '../../lib/tableContext';
import useColumns from '../../lib/useColumns';
import useTableData from '../../lib/useTableData';

type Props = {
  containerId: string;
};

const Table: FC<Props> = ({ containerId }) => {
  const tableData = useTableData(containerId);
  const columns = useColumns({ tableData });
  const rowKey = useCallback((purchase: PurchaseRead) => purchase.purchase_id, []);
  const handleRow = useCallback((): { height: number } => ({ height: 90 }), []);
  return (
    <TableContextProvider value={tableData}>
      <SharedTable.Root
        scroll={{
          x: 1400
        }}
        rowKey={rowKey}
        columns={columns}
        dataSource={tableData.dataSource}
        pagination={false}
        onRow={handleRow}
        tableLayout="auto"
      />
    </TableContextProvider>
  );
};

export default memo(Table);
