import styled from 'styled-components';

export default {
  Content: styled.div`
    width: 100%;
  `,
  Title: styled.h2`
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 16px;
  `
};
