import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Id } from 'api/api';

export type BUTTON_STATE = 'loading' | 'hide';

type ChannelPoolingStack = {
  id: Id;
  title: string;
  time: string;
};

type stackState = {
  stack: ChannelPoolingStack[];
  buttonsState: Record<Id, BUTTON_STATE | undefined | null>;
};

const initialState: stackState = {
  stack: [],
  buttonsState: {}
};

export const channelPoolingStackSlice = createSlice({
  name: 'channelPoolingStack',
  initialState,
  reducers: {
    addChannelToStack: (state, action: PayloadAction<ChannelPoolingStack>) => {
      state.stack.push(action.payload);
    },
    removeChannelFromStack: (state, action: PayloadAction<Id>) => {
      state.stack = state.stack.filter((obj) => obj.id !== action.payload);
    },
    changeButtonState: (
      state,
      action: PayloadAction<{ id: string; state: BUTTON_STATE | null }>
    ) => {
      state.buttonsState[action.payload.id] = action.payload.state;
    }
  }
});

export const { addChannelToStack, removeChannelFromStack, changeButtonState } =
  channelPoolingStackSlice.actions;

export default channelPoolingStackSlice.reducer;
