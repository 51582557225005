import { FC, PropsWithChildren, ReactNode } from 'react';
import { useGetV2ChannelsByMetricTypeQuery } from 'api/api';
import dayjs from 'dayjs';
import { calcChannelCoverage } from 'fsd/entities/channel';
import { getPurchasesPrice } from 'fsd/entities/purchase';
import { cutNum } from 'fsd/shared/lib/helpers/tools';

import useGetCartPurchases from '../../lib/useGetCartPurchases';
import { getCartContainerIdSelector } from '../../model/selectors';

import Field from './Field';

import * as S from './styles';

const dateRange = {
  from: dayjs().add(-1, 'day').startOf('day').toISOString(),
  to: dayjs().add(-1, 'day').endOf('day').toISOString()
};

type Props = {
  close?: ReactNode;
  minCountToShow?: number;
};

const BottomInfo: FC<PropsWithChildren<Props>> = ({ children, close, minCountToShow = 0 }) => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  const { data: purchasesInCart = [] } = useGetCartPurchases();

  const channelIds = useMemo(
    () => purchasesInCart.map((purchase) => purchase.channel.info.id),
    [purchasesInCart]
  );

  const { data: avgDailyReaches } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: channelIds,
      metricType: 'avg-daily-reaches',
      dateRange,
      historicalMetricsLoaded: false,
      timeZone: 'Europe/Moscow'
    },
    {
      skip: !channelIds.length
    }
  );

  const count = purchasesInCart.length;

  const cost = useMemo(() => {
    return getPurchasesPrice(purchasesInCart);
  }, [purchasesInCart]);

  const coverage = useMemo(() => {
    if (!purchasesInCart.length) return 0;
    return (
      avgDailyReaches?.channels?.reduce((val, metrics) => val + calcChannelCoverage(metrics), 0) ??
      0
    );
  }, [avgDailyReaches?.channels, purchasesInCart.length]);

  return (
    cartContainerId &&
    count >= minCountToShow && (
      <S.Root>
        <S.Content>
          <S.Fields>
            <Field label="Каналов" value={count.toString()} />
            <Field
              label={
                <>
                  Охват<small>(общ)</small>
                </>
              }
              value={coverage ? `~${cutNum(coverage)}` : '-'}
            />
            <Field label="Общая сумма" value={`${cost.toLocaleString('ru')} ₽`} />
          </S.Fields>
          <S.Buttons>
            {children}
            {close && <S.CloseWrapper>{close}</S.CloseWrapper>}
          </S.Buttons>
        </S.Content>
      </S.Root>
    )
  );
};

export default memo(BottomInfo);
