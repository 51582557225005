import { ComponentProps, FC } from 'react';
import {
  Status,
  usePatchV1ExchangePurchaseDeleteMutation,
  usePatchV1ExchangePurchaseStatusMutation
} from 'api/api';
import { isCanRemovePurchase } from 'fsd/entities/purchase';
import { URLs } from 'fsd/shared/config/URLs';
import { IconAlertError, IconCross } from 'fsd/shared/icons';
import CompactMenu from 'fsd/shared/ui/CompactMenu';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  const [removePurchase] = usePatchV1ExchangePurchaseDeleteMutation();
  const [updateStatus] = usePatchV1ExchangePurchaseStatusMutation();

  const handleRemoveClick = useCallback(() => {
    removePurchase({
      purchaseIdList: {
        id_list: [purchase.purchase_id]
      }
    });
  }, [purchase.purchase_id, removePurchase]);

  const handleCancelClick = useCallback(() => {
    updateStatus({
      updatePurchaseStatus: {
        purchase_id: purchase.purchase_id,
        status: 'cancelled'
      }
    });
  }, [purchase.purchase_id, updateStatus]);

  const handleSupportClick = useCallback(() => {
    window.open(URLs.SUPPORT_BOT, '_blank');
  }, []);

  const items: ComponentProps<typeof CompactMenu>['items'] = useMemo(() => {
    const result = [
      {
        key: 'support',
        icon: <IconAlertError />,
        label: 'Написать в поддержку',
        props: {
          onClick: handleSupportClick
        }
      }
    ];

    if (purchase.status) {
      const isCanDelete = isCanRemovePurchase(purchase.status);
      if (isCanDelete && purchase.status === 'new') {
        result.unshift({
          key: 'removeChannel',
          icon: <IconCross />,
          label: 'Удалить канал',
          props: {
            onClick: handleRemoveClick
          }
        });
      } else if (isCanDelete) {
        result.unshift({
          key: 'remove',
          icon: <IconCross />,
          label: 'Удалить заявку',
          props: {
            onClick: handleRemoveClick
          }
        });
      } else if ((['post_requested', 'pending', 'planned'] as Status[]).includes(purchase.status)) {
        result.unshift({
          key: 'cancel',
          icon: <IconCross />,
          label: 'Отменить заявку',
          props: {
            onClick: handleCancelClick
          }
        });
      }
    }

    return result;
  }, [handleCancelClick, handleRemoveClick, handleSupportClick, purchase.status]);
  return <CompactMenu items={items} />;
};

export default memo(Cell);
