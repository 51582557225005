import reducePurchasesCpmTotal from '../../../lib/reducePurchasesCpmTotal';
import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    return reducePurchasesCpmTotal(b.purchases) - reducePurchasesCpmTotal(a.purchases);
  }
};

export default sorter;
