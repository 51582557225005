import { FC } from 'react';
import { useGetV1ExchangePurchasesContainersByContainerIdQuery } from 'api/api';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import Loader from 'fsd/shared/ui/Loader';

import Empty from '../Empty';
import MobileRows from '../MobileRows';
import Table from '../Table';

import * as S from './styles';

type Props = {
  containerId: string;
};

const List: FC<Props> = ({ containerId }) => {
  const isMobile = useIsMobile();
  const purchasesData = useGetV1ExchangePurchasesContainersByContainerIdQuery({ containerId });
  if (!purchasesData.data) {
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  }
  if (!purchasesData.data.length) {
    return (
      <S.EmptyWrapper>
        <Empty containerId={containerId} />
      </S.EmptyWrapper>
    );
  }
  return isMobile ? <MobileRows containerId={containerId} /> : <Table containerId={containerId} />;
};

export default memo(List);
