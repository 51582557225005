import { usePostV1ExchangePurchasesContainerNewCreateMutation } from 'api/api';
import { createContainerName, useAddChannelToContainer } from 'fsd/entities/container';
import { useFixFormatAndCostInPurchase } from 'fsd/entities/purchase';

import { getCartContainerIdSelector } from '../model/selectors';
import { changeCart } from '../model/slice';

type Data = {
  channelId: string;
  formatId: number;
};

export default () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const cartContainerId = useAppSelector(getCartContainerIdSelector);

  const [createPurchaseContainer] = usePostV1ExchangePurchasesContainerNewCreateMutation();
  const [fixPurchase] = useFixFormatAndCostInPurchase();
  const [addChannelToContainer] = useAddChannelToContainer();

  const add = async ({ channelId, formatId }: Data) => {
    setIsLoading(true);
    try {
      if (cartContainerId) {
        await addChannelToContainer({
          formatId,
          channelId,
          containerId: cartContainerId
        });
      } else {
        const createResult = await createPurchaseContainer({
          createPurchaseList: {
            container_channel_id: '',
            title: createContainerName(),
            purchase_channel_id_list: [channelId],
            budget: 0,
            currency: 'rub',
            source_link: '',
            container_type: 'tg_adv'
          }
        }).unwrap();

        const { purchaseId, containerId } = createResult[0];
        if (purchaseId && containerId) {
          dispatch(changeCart(containerId));
          await fixPurchase({
            channelId,
            formatId,
            purchaseId
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [
    add,
    {
      isLoading
    }
  ] as const;
};
