import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Card } from 'fsd/shared/ui/Card';
import styled from 'styled-components';

export const ContentWrapper = styled(Card)`
  padding: 16px 18px;
  box-shadow: 0px 4px 32px 0px rgba(0, 16, 61, 0.16);
  width: 328px;
  max-width: calc(100vw - 32px);
  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 100%;
    min-width: 0;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.blackRock};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 28px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-bottom: 18px;
  }
`;
