import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Paper = styled.div<{ withoutShadow?: boolean }>`
  padding: 16px;
  background: ${({ theme }) => theme.white};
  ${({ withoutShadow }) =>
    !withoutShadow &&
    `box-shadow: 0px -4px 64px rgba(0, 0, 0, 0.07),
  0px 100px 80px rgba(179, 175, 214, 0.07);`}
  border-radius: 14px;
`;

export const ChartWrapper = styled.div`
  position: relative;
  height: 204px;
  margin-bottom: 16px;
`;

export const CardHeaderContainer = styled.div<{
  compact?: boolean | undefined;
}>`
  display: flex;
  margin-bottom: ${({ compact }) => (compact ? '0' : '16px')};
`;

export const LogoContainer = styled.div`
  background: ${({ theme }) => theme.venetianNights};
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.0708636px;
  color: ${({ theme }) => theme.white};
  margin-right: 16px;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const ChannelFakeIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.black};
  margin-right: 16px;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ChannelTitle = styled.div`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  gap: 8px;
`;

export const ChannelName = styled.span``;

export const ChannelSubTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.bayFog};
`;

export const MinInfo = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const MinimalInfoRow = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  margin-top: 4px;
  justify-content: space-between;
`;

export const CardHeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const Date = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
`;

export const RightBlock = styled.div`
  text-align: right;
`;

export const IconsRow = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
`;

export const TimeWithLinkRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;

export const Time = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.bayFog};
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  padding-bottom: 16px;
  margin-bottom: 8px;
`;

export const TooltipSpanBold = styled.span`
  font-weight: 700;
`;

export const InfoRow2 = styled.div<{ compact: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  ${({ compact, theme }) =>
    !compact &&
    `
    border-top: 1px solid ${theme.alpineGoat};
    padding-top: 36px;
  `}
`;

export const InfoTitle = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.gunPowder};
`;

export const InfoValue = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
`;

export const InfoUpSubs = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.americanGreen};
`;

export const InfoMainCell = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const InfoCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Count = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
`;

export const PostContent = styled.div`
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 0;

  a {
    color: ${({ theme }) => theme.blueRibbon};
  }

  img {
    width: 100%;
    border-radius: 6px;
    margin-top: 8px;
  }
`;

export const DividerBottom = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
`;

export const InfoSmallText = styled.span`
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.londonGrey};
`;

export const CloseButtonWrapper = styled.div`
  width: 100%;
  padding: 24px;
  background: white;
  filter: drop-shadow(0px -4px 64px rgba(0, 0, 0, 0.07))
    drop-shadow(0px 100px 80px rgba(179, 175, 214, 0.07));

  @media ${MEDIA_DEVICE.mobileL.to} {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const CollapsedContent = styled.div<{ collapsed: boolean }>`
  max-height: ${({ collapsed }) => (collapsed ? '66px' : '1500px')};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

export const CollapseButton = styled.button<{ collapsed: boolean }>`
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.alpineGoat};
  border-radius: 12px;
  transform: scaleY(${({ collapsed }) => (collapsed ? 1 : -1)});
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CollapseButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 8px;
  position: relative;
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.alpineGoat};
  position: absolute;
  top: 18px;
  width: 100%;
`;

export const ErrorTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.maWhite};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
