import { FC } from 'react';
import { User } from 'api/api';
import { IconAccount } from 'fsd/shared/icons';

import * as S from './styles';

type Props = {
  user?: User;
};

const AccountInfo: FC<Props> = ({ user }) => {
  return (
    <S.Root>
      <S.IconWrapper>
        <IconAccount />
      </S.IconWrapper>

      <S.Content>
        <S.Title>Аккаунт</S.Title>
        <S.Username>{user?.tg_username ? `@${user.tg_username}` : null}</S.Username>
      </S.Content>
    </S.Root>
  );
};

export default memo(AccountInfo);
