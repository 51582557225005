import { HTMLAttributes, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { BaseCellContentStyles } from './BaseCell.styles';

export const BodyCellContent = styled.div`
  ${BaseCellContentStyles}
  display: flex;
  white-space: nowrap;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  small {
    font-size: 14px;
    font-weight: 400;
  }
  .ant-empty {
    margin: 0 auto;
  }
`;

const BodyCellBaseStyles = css`
  background: #fff;
  position: relative;
  padding: 0 !important;
  &&.ant-table-column-sort {
    background: #fff;
  }
  .ant-table-ping-right &.ant-table-cell-fix-right-first {
    &:before {
      opacity: 1;
    }
  }
  &.ant-table-cell-fix-right-first {
    &:before {
      .ant-table-row:last-child & {
        border-bottom-left-radius: 20px;
        border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
        bottom: -2px;
      }
    }
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    box-shadow: var(--table-right-shadow);
    border-left: 1px solid ${({ theme }) => theme.alpineGoat};
    transition: opacity 0.3s ease;
  }
  &:after {
    display: none;
  }
`;

const BodyCellStyled = styled.td<{ $center?: boolean }>`
  ${BodyCellBaseStyles}
  ${BodyCellContent} {
    ${({ $center }) =>
      $center &&
      css`
        justify-content: center;
      `}
  }
`;

const VirtualBodyCellStyled = styled.div<{ $center?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  ${BodyCellBaseStyles}
  ${BodyCellContent} {
    ${({ $center }) =>
      $center &&
      css`
        justify-content: center;
      `}
  }
`;

export const BodyCell = forwardRef<
  HTMLTableCellElement,
  PropsWithChildren<HTMLAttributes<HTMLTableCellElement>>
>(({ children, ...restProps }, ref) => {
  const isCenter = restProps?.style?.textAlign === 'center';
  return (
    <BodyCellStyled {...restProps} ref={ref} $center={isCenter}>
      <BodyCellContent>{children}</BodyCellContent>
    </BodyCellStyled>
  );
});

export const VirtualBodyCell = forwardRef<
  HTMLTableCellElement,
  PropsWithChildren<HTMLAttributes<HTMLTableCellElement>>
>(({ children, ...restProps }, ref) => {
  const isCenter = restProps?.style?.textAlign === 'center';
  return (
    <VirtualBodyCellStyled {...restProps} ref={ref} $center={isCenter}>
      <BodyCellContent>{children}</BodyCellContent>
    </VirtualBodyCellStyled>
  );
});
