/* eslint-disable @typescript-eslint/no-explicit-any */
function mergeAndSortArray(old_arr: any[], new_arr: any[], key: string): any[] {
  const merged_arr: any[] = [...old_arr, ...new_arr];
  const sorted_arr: any[] = merged_arr.sort((a, b) => {
    if (typeof a === 'object' && typeof b === 'object') {
      return a[key] - b[key];
    }
    return a - b;
  });
  return sorted_arr;
}

export { mergeAndSortArray };
