import { DateRange, TimeWindow } from 'api/api';

export function toISOStringWithoutTimeZone(date: Date) {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return newDate.toISOString().slice(0, -1);
}

export function toISOStringWithMSKTime(date: Date) {
  const hour = 60 * 60 * 1000;

  const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const mskOffset = 3 * hour;
  const newDate = new Date(date.getTime() - timeZoneOffset - mskOffset).toISOString();
  return newDate;
}

export function parseISOWithoutTimeZone(date: string) {
  const hour = 60 * 60 * 1000;

  const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const mskOffset = 3 * hour;
  const newDate = new Date(date);

  return new Date(newDate.getTime() + timeZoneOffset + mskOffset);
}

// TODO: change new Date() to createNewMSKDate()
export const createNewMSKDate = (): Date => {
  const newDate = parseISOWithoutTimeZone(new Date().toISOString());
  return newDate;
};

export const createNow = () => {
  const shortDelay = 5 * 60 * 1000;
  const newDate = toISOStringWithMSKTime(
    parseISOWithoutTimeZone(new Date(new Date().getTime() - shortDelay).toISOString())
  );
  return newDate;
};

export const generateDateRangeByTimeWindow = (
  timeWindow: TimeWindow,
  now: string,
  isReaches = false
): DateRange => {
  if (timeWindow === 'all') {
    return {
      to: now
    };
  }

  if (timeWindow === 'today') {
    const to = now;
    const from = new Date(to);
    from.setHours(0, 0, 0, 0);

    if (from.getTime() > new Date(now).getTime()) {
      from.setDate(from.getDate() - 1);
    }

    return {
      from: toISOStringWithMSKTime(from),
      to
    };
  }

  if (timeWindow === 'yesterday' && isReaches) {
    const to = new Date(now);
    // to.setDate(to.getDate() - );
    to.setHours(0, 0, 0, 0);

    const from = new Date(to);
    from.setDate(from.getDate() - 3);
    from.setHours(0, 0, 0, 0);

    return {
      from: toISOStringWithMSKTime(from),
      to: toISOStringWithMSKTime(to)
    };
  }

  if (timeWindow === 'yesterday') {
    const to = new Date(now);
    to.setHours(0, 0, 0, 0);

    const from = new Date(to);
    from.setDate(from.getDate() - 1);
    from.setHours(0, 0, 0, 0);

    return {
      from: toISOStringWithMSKTime(from),
      to: toISOStringWithMSKTime(to)
    };
  }

  if (timeWindow === 'week') {
    const to = new Date(now);
    to.setHours(0, 0, 0, 0);
    const from = new Date(to);
    from.setHours(0, 0, 0, 0);
    from.setDate(from.getDate() - 7);

    return {
      from: toISOStringWithMSKTime(from),
      to: toISOStringWithMSKTime(to)
    };
  }

  if (timeWindow === 'month') {
    const to = new Date(now);
    to.setHours(0, 0, 0, 0);
    const from = new Date(to);
    from.setHours(0, 0, 0, 0);
    from.setMonth(from.getMonth() - 1);

    return {
      from: toISOStringWithMSKTime(from),
      to: toISOStringWithMSKTime(to)
    };
  }

  return {
    to: now
  };
};
