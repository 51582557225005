import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const HeaderDecription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.black};
`;

export const ChannelSearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  right: 40px;
  top: 110px;
  z-index: 1;
`;

export const CentredItem = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  background: #ffffff;
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 20px;
  width: max-content;
  padding: 100px;
`;

export const NoDataDescription = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.009em;
  color: ${({ theme }) => theme.persianBlue};
  margin-top: 32px;
`;

export const NoDataTab = styled.div`
  height: calc(100vh - 25vh);
  justify-content: center;
  display: flex;
  align-items: center;
  @media ${MEDIA_DEVICE.mobileL.to} {
    height: auto;
  }
`;
