import { FC } from 'react';
import { Dropdown } from 'antd';
import {
  ChannelExchangeFormats,
  ChannelWithExchangeInfo,
  GetV2UserChannelFormatsApiResponse
} from 'api/api';

import EditForm from './EditForm/EditForm';

import * as S from './Cost.styles';

interface IProps {
  value?: number;
  disabled?: boolean;
  label?: string;
  onChange?: (value: number) => void;
  channel: ChannelWithExchangeInfo;
  format: ChannelExchangeFormats;
  channelFormats: GetV2UserChannelFormatsApiResponse;
}

const Cost: FC<IProps> = ({
  label,
  value,
  onChange,
  disabled,
  channel,
  format,
  channelFormats
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleChange = useCallback(
    (value: number) => {
      onChange?.(value);
      setOpenDropdown(false);
    },
    [onChange]
  );

  return (
    <Dropdown
      open={openDropdown}
      trigger={['click']}
      disabled={disabled}
      onOpenChange={setOpenDropdown}
      transitionName="ant-fade"
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={() => (
        <EditForm
          value={value}
          disabled={disabled}
          channel={channel}
          format={format}
          channelFormats={channelFormats}
          onChange={handleChange}
        />
      )}
    >
      <S.Value empty={!value}>
        {value ? <>{value.toLocaleString('ru')} ₽</> : <>+ добавить</>}
      </S.Value>
    </Dropdown>
  );
};

export default memo(Cost);
