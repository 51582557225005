import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Radio, RadioChangeEvent } from 'antd';
import { FormApi } from 'final-form';
import { IconSort } from 'fsd/shared/icons';
import { flattenObj } from 'fsd/shared/lib/helpers/tools';
import { Button } from 'fsd/shared/ui/Buttons';
import Modal from 'fsd/shared/ui/Modal';

import { FormValues } from '../../../pages/catalog/types';
import { SortType } from '../../../types/channels/types';

import * as S from './styles';

type Props = {
  form: FormApi<FormValues, Partial<FormValues>>;
  values: Partial<FormValues>;
};

const MobileSort: FC<Props> = ({ form, values }) => {
  const [value, setValue] = useState('participants');
  const [isVisible, setIsVisible] = useState(false);

  const [, setSearchParams] = useSearchParams();

  const toggleModal = () => setIsVisible(!isVisible);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const onClick = () => {
    const sort = {
      field: value,
      order: 'DESC'
    } as {
      field: SortType;
      order: 'ASC' | 'DESC';
    };

    form.change('sort', sort);
    form.submit();

    setSearchParams(
      flattenObj({
        ...values,
        sort
      })
    );

    toggleModal();
  };

  return (
    <>
      <IconSort onClick={toggleModal} />

      <Modal onCancel={toggleModal} open={isVisible}>
        <S.Title>Сортировать по</S.Title>

        <S.WrapperRadio>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="participants">Подписчики</Radio>
            <Radio value="participants_diff">Прирост</Radio>
          </Radio.Group>

          <Button type="primary" size="large" block onClick={onClick}>
            Применить
          </Button>
        </S.WrapperRadio>
      </Modal>
    </>
  );
};

export default MobileSort;
