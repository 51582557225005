import { FC } from 'react';

import ParticipantsCell from '../../../../Row/components/Participants';
import useParticipantsMetrics from '../../../lib/useParticipantsMetrics';
import { DefaultColumnCellProps } from '../type';

import * as S from '../../../../Row/styles';

type Props = DefaultColumnCellProps;

const Participants: FC<Props> = ({ channel }) => {
  const { tableData, ...participants } = useParticipantsMetrics(channel);
  return (
    <S.ChartCell className="usiteful__catalog__users-cell">
      {tableData && (
        <ParticipantsCell
          isLoading={!tableData.channelMetrics?.data || !tableData.participantsDiffMetrics?.data}
          graphData={participants.participantsGraphData}
          participants={participants.participants}
          participantsAll={participants.participantsAll}
          participantsDiff={participants.channelParticipantsDiffMetrics}
        />
      )}
    </S.ChartCell>
  );
};

export default memo(Participants);
