import { Link } from 'react-router-dom';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const TR = styled(Link)`
  display: table-row;

  background-color: ${({ theme }) => theme.white};
  border-collapse: separate;
  overflow: hidden;
  color: ${({ theme }) => theme.blackGrey};

  &:hover {
    color: ${({ theme }) => theme.blackGrey};
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 100%;
    display: block;
  }
`;

export const FixedCell = styled.td`
  position: sticky;
  left: 0;
  z-index: 2;
  background: #fff;

  display: table-cell;
  padding: 28px 0 0;
  vertical-align: top;

  :first-of-type {
    padding: 16px 0 16px 21px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 18px 0 16px;

    :first-of-type {
      max-width: 168px;
      width: 168px;
      padding: 18px 0 16px;
    }

    :nth-child(n + 3) {
      display: none;
    }
  }
`;

export const Cell = styled.td<{ isPriceCell?: boolean }>`
  display: table-cell;
  padding: ${({ isPriceCell }) => (isPriceCell ? '0' : '28px 0 0 0')};
  vertical-align: ${({ isPriceCell }) => (isPriceCell ? 'middle' : 'top')};
  position: relative;

  &:first-of-type {
    padding: 16px 0 16px 21px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 18px 0 16px;

    &:first-of-type {
      padding: 18px 0 16px;
      overflow: hidden;
    }

    &:nth-child(n + 3) {
      display: none;
    }
  }
`;

export const NameCellWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 6px;
  }
`;

export const ChannelName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.144px;
  white-space: nowrap;
  color: ${({ theme }) => theme.blackRock};
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${({ theme }) => theme.gunPowder};
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    max-width: 96px;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;
export const Rating = styled.span`
  padding: 2px 4px;
  background: ${({ theme }) => theme.sweetFlower};
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.black};

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const ChannelIconWrap = styled.div`
  display: inline-block;
  vertical-align: top;

  > * {
    width: 100%;
  }

  width: 42px;
  height: 42px;
  min-width: 42px;

  margin-top: 10px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    min-width: 36px;
    width: 36px;
    height: 36px;
    margin-top: 0;
  }
`;

export const ChannelInfoWrap = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 68px);
`;

export const ChannelActions = styled.div`
  display: flex;
  gap: 16px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const ChannelCategory = styled.p`
  display: inline-flex;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.bayFog};
  margin-bottom: 6px;
  white-space: nowrap;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-bottom: 0;
    font-size: 12px;
    display: block;

    max-width: 126px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media ${MEDIA_DEVICE.mobile.to} {
    width: 100%;
    max-width: none;
    white-space: normal;
  }
`;

export const ChartCell = styled.div`
  display: flex;

  justify-content: space-between;
  width: 141px;
`;

export const BarChartCell = styled.div`
  display: flex;

  gap: 16px;
  width: 182px;
`;

export const PriceCell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 169px;
`;

export const ChartCell2 = styled.div`
  display: flex;

  justify-content: space-between;
  width: 155px;
`;

export const TotalLabel = styled.small`
  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const ChannelNameWrapper = styled.div`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 14px;
    height: 14px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 4px;
    margin-bottom: 4px;
  }
`;

export const CellIcon = styled.div`
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 14px;
    height: 14px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const DataValue = styled.span<{ empty?: boolean }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  display: block;
  color: ${({ theme, empty }) => (empty ? theme.bayFog : theme.blackGrey)};

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-weight: 600;
    font-size: 12px;
  }

  .ant-skeleton-button-sm {
    height: 16px;
    line-height: 16px;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: block;
  }
`;

export const DataValueDiff = styled.span<{ status: boolean }>`
  color: ${({ status, theme }) => (status ? theme.americanGreen : theme.hotspot)};
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: block;
  white-space: nowrap;

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 4px;
  }
`;

export const ChannelIcon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const ChannelFakeIcon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.black};
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 7px;
  height: 38px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 77px;
    height: 46px;
  }
`;

export const ChannelActionPlaceholder = styled.div`
  width: 154px;
  height: 18px;
`;

export const ChannelActionWrapper = styled.div`
  display: inline-flex;
  width: fit-content;
`;
