import reducePurchasesSubscribers from '../../../lib/reducePurchasesSubscribers';
import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    return reducePurchasesSubscribers(b.purchases) - reducePurchasesSubscribers(a.purchases);
  }
};

export default sorter;
