import { Select } from 'antd';
import styled from 'styled-components';

export const Root: typeof Select = styled(Select)`
  &.ant-select-show-search {
    &:not(.ant-select-open) {
      .ant-select-selector {
        cursor: pointer;
      }
    }
  }
`;
