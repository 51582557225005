import { ComponentProps, FC } from 'react';
import {
  api,
  ChannelWithExchangeInfo,
  useGetV1UserChannelByChannelIdContactsQuery,
  usePatchV1UserChannelMutation
} from 'api/api';
import { IconArrowDown } from 'fsd/shared/icons';
import { Select } from 'fsd/shared/ui/Select';
import styled from 'styled-components';

import * as S from '../styles';

const SelectWithColoredItem: typeof Select = styled(Select)`
  .ant-select-selection-item {
    color: ${theme.blueRibbon};
  }
`;

interface IProps {
  channel: ChannelWithExchangeInfo;
}

const ContactSelect: FC<IProps> = ({ channel }) => {
  const dispatch = useAppDispatch();
  const { data, isFetching: isDataFetching } = useGetV1UserChannelByChannelIdContactsQuery({
    channelId: channel.info.id
  });
  const [updateChannel, { isLoading: isUpdateFetching }] = usePatchV1UserChannelMutation();

  const options: ComponentProps<typeof Select>['options'] = useMemo(() => {
    return data?.map((contact) => {
      return {
        value: contact.user_id,
        label: `@${contact.telegram_username}`
      };
    });
  }, [data]);

  const handleChange = useCallback(
    (value: string) => {
      const { undo } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const channelData = data.find((d) => d.info.id === channel.info.id);
          if (channelData?.exchange_info) {
            channelData.exchange_info.contact_id = value;
          }
        })
      );
      updateChannel({
        body: {
          channel_id: channel.info.id,
          exchange_info: {
            channel_id: channel.info.id,
            contact_id: value
          }
        }
      })
        .unwrap()
        .catch(() => undo());
    },
    [dispatch, updateChannel, channel.info.id]
  );
  return (
    <S.RowExpandedBlock>
      <S.ExpandedBlockHeader>
        <span>Контакт</span>
      </S.ExpandedBlockHeader>
      <div>
        <SelectWithColoredItem
          suffixIcon={<IconArrowDown />}
          placeholder="Не выбрано"
          disabled={isUpdateFetching}
          loading={isDataFetching}
          showSearch
          options={options}
          value={channel.exchange_info?.contact_id}
          onChange={handleChange}
        />
      </div>
    </S.RowExpandedBlock>
  );
};

export default ContactSelect;
