import { FC, memo, useRef } from 'react';
import { Chart } from 'chart.js';
import { CompactChart } from 'fsd/shared/ui/CompactChart';

import EmtpyLineChart from '../EmtpyLineChart';

import * as S from '../../styles';

type Props = {
  participants: any;
  graphData: any;
};

const ParticipantsChart: FC<Props> = ({ participants, graphData }) => {
  const chartRef = useRef<Chart>(null);

  return participants?.history?.length > 1 ? (
    <S.ChartWrapper>
      <CompactChart data={graphData} chartref={chartRef} animation={false} />
    </S.ChartWrapper>
  ) : (
    <S.ChartWrapper>
      <EmtpyLineChart />
    </S.ChartWrapper>
  );
};

export default memo(ParticipantsChart);
