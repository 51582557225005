import { FC, useCallback } from 'react';
import { Id5 } from 'api/api';
import { IconAdd } from 'fsd/shared/icons';
import { OutlinedButton } from 'fsd/shared/ui/Buttons/OutlinedButton';

import useSend from '../lib/useSend';

type Props = {
  purchaseId: Id5;
};

const Button: FC<Props> = ({ purchaseId }) => {
  const [send, { isLoading }] = useSend();

  const handleClick = useCallback(() => {
    send(purchaseId);
  }, [purchaseId, send]);

  return (
    <OutlinedButton block startIcon={<IconAdd />} onClick={handleClick} disabled={isLoading}>
      Креатив
    </OutlinedButton>
  );
};

export default Button;
