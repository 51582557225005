import { FC } from 'react';
import { Collapse, CollapseProps } from 'antd';
import { IconChevronBottom } from 'fsd/shared/icons';
import { theme } from 'theme/theme';
import { Icon } from 'fsd/shared/ui/Icon';
import styled from 'styled-components';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.white};
  border: none;
  box-shadow: 0px 4px 32px rgba(0, 16, 61, 0.16);
  border-radius: 8px;
  overflow: hidden;

  & > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse-header-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.blackRock};
  }
`;

const StyledIcon = styled(IconChevronBottom)<{ isActive: boolean }>`
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in-out;
`;

interface CollapseInterface extends FC<CollapseProps> {
  Panel: typeof Panel;
}

export const CollapseUI: CollapseInterface = (props) => {
  return (
    <StyledCollapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => {
        return (
          <Icon width={15} color={theme.majesticMount}>
            <StyledIcon isActive={isActive || false} />
          </Icon>
        );
      }}
      {...props}
    />
  );
};

CollapseUI.Panel = Panel;

export default memo(CollapseUI);
