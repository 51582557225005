import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { IconSpaceman } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';

import * as S from './styles';

type Props = {
  content?: ReactNode;
  link?: {
    href: string;
    content: ReactNode;
  };
};

const OrganisationFallback: FC<Props> = ({ content, link }) => {
  return (
    <S.Root>
      <IconSpaceman color={theme.persianBlue} />

      <S.TextWrapper>
        {content && <S.Title>{content}</S.Title>}
        {link && (
          <S.LinkText href={link.href} target="_blank">
            {link.content}
          </S.LinkText>
        )}
      </S.TextWrapper>

      <NavLink to="/settings/about_company?force=1" style={{ marginTop: 24, width: '100%' }}>
        <Button type="primary" size="large" block htmlType="submit">
          + Добавить организацию
        </Button>
      </NavLink>
    </S.Root>
  );
};

export default memo(OrganisationFallback);
