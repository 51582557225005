import { CSSProperties, FC, MouseEventHandler } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';
import { cutNum } from 'fsd/shared/lib/helpers/tools';

import { RowDataContext } from '../../lib/rowDataContext';
import useGetChannelCoverage from '../../lib/useGetChannelCoverage';
import useGetChannelCpm from '../../lib/useGetChannelCpm';
import useGetChannelErr from '../../lib/useGetChannelErr';
import useRowData from '../../lib/useRowData';
import Info from '../columns/Info';
import Participants from '../columns/Participants';
import { PriceForm } from '../columns/Price';

import * as S from './Row.styles';

type Props = {
  style?: CSSProperties;
  channel: ChannelWithExchangeInfo;
};

const Row: FC<Props> = memo(({ style, channel }) => {
  const rowDataContext = useContext(RowDataContext);
  const coverage = useGetChannelCoverage(channel.info.id);
  const err = useGetChannelErr(channel.info.id);
  const cpm = useGetChannelCpm({
    channelId: channel.info.id,
    activeFormatId: rowDataContext?.formatId,
    channelFormats: channel.exchange_info?.formats
  });

  return (
    <S.Wrapper style={style}>
      <S.Root>
        <S.Main>
          <S.InfoWrapper to={`/channel/${channel.info.id}`}>
            <Info.Cell channel={channel} />
          </S.InfoWrapper>
          <S.ChartWrapper>
            <Participants.Chart channel={channel} />
          </S.ChartWrapper>
          <S.Additional>
            <Participants.Value channel={channel} />
          </S.Additional>
        </S.Main>
        <S.Metrics>
          <S.MetricsTag>
            Охват(ср)<b>{cutNum(coverage)}</b>
          </S.MetricsTag>
          <S.MetricsTag>
            ERR<b>{err.isEmpty ? '-' : err.value}%</b>
          </S.MetricsTag>
          <S.MetricsTag>
            CPM<b>{cpm.isEmpty ? '-' : cutNum(cpm.value)}₽</b>
          </S.MetricsTag>
        </S.Metrics>
        <S.PriceWrapper>
          <PriceForm />
        </S.PriceWrapper>
      </S.Root>
    </S.Wrapper>
  );
});

const RowWithProviders: FC<{ channel: ChannelWithExchangeInfo; style?: CSSProperties }> = ({
  channel,
  style
}) => {
  const rowData = useRowData(channel);
  return (
    <RowDataContext.Provider value={rowData}>
      <Row style={style} channel={channel} />
    </RowDataContext.Provider>
  );
};

export default memo(RowWithProviders);
