import { useEffect } from 'react';

const TOKEN = 'ce0f1d0b818edb2e89c5e202641f670e';
const SRC = 'https://www.usetiful.com/dist/usetiful.js';
const SCRIPT_ID = 'usetifulScript';

/*
 * This hook sets usiteful script parameters and adds the script to the DOM
 * If on user data change script parameters need to be updated, this implementation needs changes
 */

export default function useUsitefulScript({
  userId,
  onSuccess,
  onError,
  segment = 'None',
  language = 'None',
  role = 'None',
  firstName = 'None'
} = {}) {
  const usitefulTags = {
    userId,
    segment,
    language,
    role,
    firstName
  };

  useEffect(() => {
    if (!userId) return;
    if (document.getElementById(SCRIPT_ID)) return;

    try {
      window.usetifulTags = usitefulTags;

      const head = document.getElementsByTagName('head')[0];
      const scriptElem = document.createElement('script');
      scriptElem.async = true;
      scriptElem.src = SRC;
      scriptElem.setAttribute('id', SCRIPT_ID);
      scriptElem.dataset.token = TOKEN;
      head.appendChild(scriptElem);
      window.USETIFUL?.reinitialize?.();

      onSuccess && Promise.resolve().then(() => onSuccess(scriptElem));
    } catch (e) {
      onError && Promise.resolve().then(() => onError(e));
    }
  }, [userId]);

  return null;
}
