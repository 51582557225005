import { ComponentProps } from 'react';

import { Input } from '../../Input';

import * as S from './styles';

type Props = ComponentProps<typeof S.PseudoInput> & { pseudo?: boolean };

const DateInput = forwardRef<HTMLInputElement, Props>(
  ({ value, placeholder, pseudo, ...restProps }, ref) => {
    return (
      <S.Root>
        {pseudo ? (
          <>
            <S.PseudoInput ref={ref} value={value} {...restProps} />
            {pseudo && <S.Pseudo>{value || placeholder}</S.Pseudo>}
          </>
        ) : (
          <Input ref={ref} value={value} placeholder={placeholder} {...restProps} />
        )}
      </S.Root>
    );
  }
);

export default memo(DateInput);
