import { ComponentProps, FC, MouseEventHandler } from 'react';
import { usePatchV1ExchangePurchaseContainersChangeStatusMutation } from 'api/api';
import { ExportContainerModal } from 'fsd/features/exportContainer';
import { URLs } from 'fsd/shared/config/URLs';
import { IconAlertError, IconCross, IconExport } from 'fsd/shared/icons';
import CompactMenu from 'fsd/shared/ui/CompactMenu';
import getActivePurchases from 'fsd/widgets/ContainersList/lib/getActivePurchases';
import { closeTab } from 'slices/purchaseTabs/PurchaseTabsSlice';
import styled from 'styled-components';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Root = styled.div``;

const Cell: FC<Props> = ({ record }) => {
  const [changeStatus] = usePatchV1ExchangePurchaseContainersChangeStatusMutation();
  const dispatch = useAppDispatch();
  const [exportOpen, setExportOpen] = useState(false);

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const isCanDelete = useMemo(
    () => !getActivePurchases(record.purchases).length,
    [record.purchases]
  );

  const handleExportClose = useCallback(() => {
    setExportOpen(false);
  }, []);

  const handleExportOpen = useCallback(() => {
    setExportOpen(true);
  }, []);

  const handleRemoveClick = useCallback(() => {
    if (!isCanDelete) return;
    changeStatus({
      changeActivityStatusForPurchaseLis: {
        container_id_list: [record.container.container_id],
        is_active: false
      }
    });
    dispatch(closeTab({ tabId: record.container.container_id }));
  }, [changeStatus, dispatch, isCanDelete, record.container.container_id]);

  const handleSupportClick = useCallback(() => {
    window.open(URLs.SUPPORT_BOT, '_blank');
  }, []);

  const items: ComponentProps<typeof CompactMenu>['items'] = useMemo(() => {
    const result = [
      {
        key: 'export',
        icon: <IconExport />,
        label: 'Экспорт',
        props: {
          onClick: handleExportOpen
        }
      },
      {
        key: 'support',
        icon: <IconAlertError />,
        label: 'Написать в поддержку',
        props: {
          onClick: handleSupportClick
        }
      }
    ];

    if (isCanDelete) {
      result.unshift({
        key: 'remove',
        icon: <IconCross />,
        label: 'Удалить кампанию',
        props: {
          onClick: handleRemoveClick
        }
      });
    }

    return result;
  }, [handleExportOpen, handleRemoveClick, handleSupportClick, isCanDelete]);
  return (
    <Root onClick={handleClick}>
      <ExportContainerModal
        open={exportOpen}
        onClose={handleExportClose}
        containerId={record.container.container_id}
      />
      <CompactMenu items={items} />
    </Root>
  );
};

export default memo(Cell);
