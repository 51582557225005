import { usePatchV1ExchangePurchaseStatusMutation } from 'api/api';
import { URLs } from 'fsd/shared/config/URLs';

export default () => {
  const [updatePurchaseStatus, { isLoading }] = usePatchV1ExchangePurchaseStatusMutation();
  const send = useCallback(
    (purchaseId: string) => {
      updatePurchaseStatus({
        updatePurchaseStatus: {
          purchase_id: purchaseId,
          status: 'post_requested'
        }
      })
        .unwrap()
        .then(() => {
          window.open(URLs.TELESPACE_ASSISTANT_BOT, '_blank');
        });
    },
    [updatePurchaseStatus]
  );

  return [send, { isLoading }] as const;
};
