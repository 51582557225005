import { ComponentType, FC, PropsWithChildren } from 'react';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';
import styled, { CSSProperties } from 'styled-components';

type LoaderWrapperProps = {
  padding?: CSSProperties['padding'];
};
export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  margin: auto 0;
  padding: ${({ padding }) => padding || '0px'};
  display: flex;
  justify-content: center;
  width: 100%;
`;

type AllowUndefined<T> = {
  [P in keyof T]: T[P] | undefined;
};

type SomeUndefined<T, A extends keyof T> = Omit<T, A> & AllowUndefined<Pick<T, A>>;

export const withLoading =
  <T, A extends keyof T>(
    Component: ComponentType<T>,
    loadingFields: readonly [A, ...A[]],
    padding?: CSSProperties['padding'],
    loaderSize?: number
  ): FC<SomeUndefined<T, A> & { isError?: boolean }> =>
  (props) => {
    const isLoading: boolean = Object.entries(props).some(
      ([k, v]) => loadingFields.includes(k as A) && typeof v === 'undefined'
    );
    // TODO неплохо бы завести нормальный err component
    if (props.isError)
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '200px'
          }}
        />
      );

    return isLoading ? (
      <LoaderWrapper padding={padding}>
        <LoaderSpinner fontSize={loaderSize} />
      </LoaderWrapper>
    ) : (
      <Component {...(props as PropsWithChildren<T>)} />
    );
  };
