import { FC, useState } from 'react';
import { createGlobalStyle } from 'styled-components';

import { FormatsType } from '../Table/components/Row/components/Price/Action';

import MultiContextMenu from './components/MultiContextMenu/MultiContextMenu';
import SingleContextMenu from './components/SingleContextMenu/SingleContextMenu';

import * as S from './styles';

const GS = createGlobalStyle`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 54px ;
  }
`;

type Props = {
  channelId: string[];
  setIsDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  actionType: 'single' | 'multi';
  format?: FormatsType[number];
  showPanel1?: boolean;
};

export type FormRef = {
  handleSubmit: () => void;
  isValid: boolean;
  isPristine?: boolean;
};

const TabsContextMenu: FC<Props> = ({
  channelId,
  setIsDropdownVisible,
  actionType,
  format,
  showPanel1 = true
}) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!format) return null;

  return (
    <S.TabsWrapper
      className="usiteful__catalog-tab-context-menu"
      onClick={(e) => e.stopPropagation()}
    >
      <GS />
      {actionType === 'single' ? (
        <SingleContextMenu
          setIsDropdownVisible={setIsDropdownVisible}
          channelId={channelId}
          setIsOpen={setIsOpen}
          format={format}
          showPanel1={showPanel1}
        />
      ) : (
        <MultiContextMenu
          setIsOpen={setIsOpen}
          channelId={channelId}
          isOpen={isOpen}
          format={format}
          setIsDropdownVisible={setIsDropdownVisible}
        />
      )}
    </S.TabsWrapper>
  );
};

export default TabsContextMenu;
