/* eslint-disable no-nested-ternary */
import { FC, memo, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { TimelineEventsPost, useGetV1ChannelsByChannelIdQuery } from 'api/api';
import { ChannelDescription } from 'fsd/entities/channel/ui/ChannelDescription';
import { IconPost, IconReposts, IconSpeaker, IconViews } from 'fsd/shared/icons';
import { cutNum } from 'fsd/shared/lib/helpers/tools';
import { createNow } from 'fsd/shared/lib/helpers/tools/dates';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import * as S from '../../styles';
import * as LS from './styles';

interface ChannelPost extends TimelineEventsPost {
  created_at: string;
  created_time: string;
}

type Props = {
  data: ChannelPost;
  time: string;
  date: string;
  to: string;
};

const Row: FC<Props> = ({ data, time, date, to }) => {
  const { channel_id, post_id, type } = data;
  const navigate = useNavigate();
  const location = useLocation();

  const [isActivePopover, setIsActivePopover] = useState(false);

  const isMobile = useIsMobile();

  const { data: channel } = useGetV1ChannelsByChannelIdQuery(
    {
      channelId: channel_id
    },
    {
      skip: !channel_id
    }
  );

  const handleClick = useCallback(() => {
    if (isMobile) {
      setIsActivePopover((prevState) => !prevState);
    } else {
      navigate(`/channel/${channel_id}`);
    }
  }, [channel_id]);

  const handleOnMouseEnter = useCallback(() => {
    if (isMobile) return;
    setIsActivePopover(true);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    if (isMobile) return;
    setIsActivePopover(false);
  }, []);

  const isRegular = type === 'regular';

  return (
    <>
      <LS.Hour className="usiteful__channel-stats__time">
        <S.ColumnDiv1>
          <Tooltip title={`${date} ${time}`}>{time}</Tooltip>
        </S.ColumnDiv1>
      </LS.Hour>

      <LS.Number>
        <S.ColumnDiv2 />
      </LS.Number>
      <LS.Event onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <S.ColumnDiv3>
          <LS.DescriptionWrapper>
            <LS.DescriptionContainer>
              {type !== 'regular' && (
                <LS.BigIcon>
                  {type === 'mention' && (
                    <Tooltip title="Упоминание">
                      <IconSpeaker className="usiteful__icon-speaker" />
                    </Tooltip>
                  )}

                  {type === 'repost' && (
                    <Tooltip title="Репост в другом канале">
                      <IconReposts className="usiteful__icon-reposts" />
                    </Tooltip>
                  )}
                </LS.BigIcon>
              )}

              {channel && (
                <LS.Description
                  className="usiteful__channel-stats__title"
                  onClick={handleClick}
                  isRegular={!!isRegular}
                >
                  {isActivePopover ? (
                    <ChannelDescription
                      showHeader
                      trigger={isMobile ? undefined : ['hover']}
                      channel={channel?.info}
                      exchangeInfo={channel?.exchange_info}
                      isRegular={isRegular}
                      to={to}
                    >
                      {isRegular ? 'Вышел пост' : channel?.info?.title}
                    </ChannelDescription>
                  ) : isRegular ? (
                    'Вышел пост'
                  ) : (
                    channel?.info?.title
                  )}
                </LS.Description>
              )}
            </LS.DescriptionContainer>
            {/* <S.DescriptionContainer className="usiteful__channel-stats__views">
            <S.Tooltip title="Просмотры поста за всё время">
              <S.BigIcon>
                <IconViews />
              </S.BigIcon>
            </S.Tooltip>
            <span>{cutNum(data.views || 0)}</span>
          </S.DescriptionContainer> */}
          </LS.DescriptionWrapper>
        </S.ColumnDiv3>
      </LS.Event>
      <LS.Post>
        <S.ColumnDiv4>
          {/* <LS.PostLink
          className="usiteful__post-link-1"
          to={`/channel/${channel_id}/post/${post_id}`}
          state={{ background: location }}
        >
          <LS.TooltipLeft title="Посмотреть пост">
            <LS.BigIconPost>
              <IconPost />
            </LS.BigIconPost>
          </LS.TooltipLeft>
        </LS.PostLink> */}
        </S.ColumnDiv4>
      </LS.Post>
    </>
  );
};

export default memo(Row);
