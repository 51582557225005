import { FC } from 'react';
import { Table } from 'fsd/shared/ui/Table';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ record }) => {
  if (typeof record.container.common_purchase_data?.cost === 'undefined') {
    return <Table.NoCellData />;
  }
  return `${record.container.common_purchase_data.cost.toLocaleString('ru')} ₽`;
};

export default memo(Cell);
