import MediaQuery from './MediaQuery';

export type MediaQueryBreakpointType = 'height' | 'width';
export type MediaQueryBreakpointSize = {
  from: number;
  to: number;
};

const createTypeString = (type: MediaQueryBreakpointType, prefix: 'min' | 'max') =>
  `${prefix}-${type}`;

class MediaQueryBreakpoint extends MediaQuery {
  size;

  from;

  to;

  constructor(type: MediaQueryBreakpointType, size: MediaQueryBreakpointSize) {
    super(createTypeString(type, 'max'), `${size.to}px`);

    this.size = size;
    this.from = this.createMediaQuery(createTypeString(type, 'min'), `${this.size.from + 1}px`);
    this.to = this.mediaQuery;
  }
}

export default MediaQueryBreakpoint;
