import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import useAnimateConfig from 'rc-tabs/lib/hooks/useAnimateConfig';
import type { EditableConfig, Tab } from 'rc-tabs/lib/interface';
import TabContext from 'rc-tabs/lib/TabContext';
import TabPanelList from 'rc-tabs/lib/TabPanelList';
import useMergedState from 'rc-util/lib/hooks/useMergedState';

import TabBar from './TabBar/TabBar';

let uuid = 0;

export type TabsProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'children'> & {
  items: Tab[];
  activeKey?: string;
  onChange: (activeKey: string) => void;
  onRemove: (key: string) => void;
  CatalogTab: FC;
  Tab: FC<any>;
  catalogTabId: string;
  getChannelId(tabId: string): string;
  minTabWidth: number;
};

function Tabs(
  {
    items,
    activeKey,
    onChange,
    onRemove,
    CatalogTab,
    Tab,
    catalogTabId,
    getChannelId,
    minTabWidth,
    ...restProps
  }: TabsProps,
  ref: React.Ref<HTMLDivElement>
) {
  const prefixCls = 'ant-tabs';
  const tabPosition = 'top';
  const tabs = React.useMemo(
    () => (items || []).filter((item) => item && typeof item === 'object' && 'key' in item),
    [items]
  );
  const mergedAnimated = useAnimateConfig();
  const [mergedActiveKey, setMergedActiveKey] = useMergedState<string>(() => tabs[0]?.key, {
    value: activeKey
  });
  const [activeIndex, setActiveIndex] = useState(() =>
    tabs.findIndex((tab) => tab.key === mergedActiveKey)
  );
  const [mergedId, setMergedId] = useMergedState('', {
    value: ''
  });

  useEffect(() => {
    let newActiveIndex = tabs.findIndex((tab) => tab.key === mergedActiveKey);
    if (newActiveIndex === -1) {
      newActiveIndex = Math.max(0, Math.min(activeIndex, tabs.length - 1));
      setMergedActiveKey(tabs[newActiveIndex]?.key);
    }
    setActiveIndex(newActiveIndex);
  }, [tabs.map((tab) => tab.key).join('_'), mergedActiveKey, activeIndex]);

  useEffect(() => {
    setMergedId(`rc-tabs-${process.env.NODE_ENV === 'test' ? 'test' : uuid}`);
    uuid += 1;
  }, []);

  const onInternalTabClick = (key: string) => {
    const isActiveChanged = key !== mergedActiveKey;
    setMergedActiveKey(key);

    if (isActiveChanged) {
      onChange(key);
    }
  };

  const onInternalEdit: EditableConfig['onEdit'] = (type, { key }) => {
    if (type === 'remove' && typeof key === 'string') {
      onRemove(key);
    }
  };

  const [visibleEnd, setVisibleEnd] = useState<number>(tabs.length - 1);

  const onVisibleEndChange = (visibleEnd: number) => {
    setVisibleEnd(visibleEnd);
  };

  const visibleTabs = tabs.slice(0, visibleEnd);
  const hiddenTabs = tabs.slice(visibleEnd);

  if (activeIndex >= visibleEnd) {
    const extraTab = visibleTabs.pop();
    const activeTab = tabs[activeIndex];

    hiddenTabs.splice(activeIndex - visibleEnd, 1);

    if (extraTab) {
      hiddenTabs.unshift(extraTab);
    }

    visibleTabs.push(activeTab);
  }

  const tabBarProps = {
    activeIndex,
    activeKey: mergedActiveKey,
    editable: {
      onEdit: onInternalEdit,
      showAdd: false
    },
    hiddenTabs,
    id: mergedId,
    onTabClick: onInternalTabClick,
    onVisibleEndChange,
    prefixCls,
    tabs,
    visibleEnd,
    visibleTabs,
    catalogTabId,
    minTabWidth
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TabContext.Provider value={{ tabs, prefixCls }}>
      <div
        ref={ref}
        className={classNames(prefixCls, `${prefixCls}-${tabPosition}`, `${prefixCls}-editable`)}
        {...restProps}
      >
        <TabBar {...tabBarProps}>
          {(node) => {
            const { key } = node;

            if (typeof key !== 'string') {
              return <></>;
            }

            return React.cloneElement(node, node.props, [
              node.key === catalogTabId ? (
                <CatalogTab key={key} />
              ) : (
                <Tab
                  key={key}
                  channelId={getChannelId(key)}
                  containerId={getChannelId(key)}
                  isActive={activeKey === key}
                  isCollapsed={false}
                />
              ),
              ...node.props.children.slice(1)
            ]);
          }}
        </TabBar>
        <TabPanelList
          destroyInactiveTabPane
          id={mergedId}
          activeKey={mergedActiveKey}
          animated={mergedAnimated}
          tabPosition={tabPosition}
        />
      </div>
    </TabContext.Provider>
  );
}

const ForwardTabs = forwardRef(Tabs);
if (import.meta.env.DEV /* process.env.NODE_ENV !== "production" */) {
  ForwardTabs.displayName = 'Tabs';
}

export default memo(ForwardTabs);
