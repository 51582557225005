import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 16px 10px 32px;
  margin-bottom: 14px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.persianBlue};

  @media ${MEDIA_DEVICE.desktopL.from} {
    margin: 0 auto 14px;
  }
`;

export const Text = styled.div`
  /* Text Bold */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1em;
  color: ${({ theme }) => theme.persianBlue};
`;
