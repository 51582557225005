import { FC } from 'react';
import type { TabsProps } from 'antd';
import { IconTelegramArrows } from 'fsd/shared/icons';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { Icon } from 'fsd/shared/ui/Icon';
import { LayoutContent } from 'fsd/shared/ui/Layout';
import { HeaderMobileWidget } from 'fsd/widgets/Header';

import { theme } from 'theme/theme';

import Channels from './components/content/Channels';
import { useChannelTableLogic } from './components/content/Channels/hooks';
import { ChannelsContext } from './components/content/Channels/Provider';

import * as S from './styles';

const ContentManager: FC = () => {
  const isMobile = useIsMobile();
  const channelContextValue = useChannelTableLogic();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Каналы`,
      children: <Channels />
    }
  ];

  return (
    <ChannelsContext.Provider value={channelContextValue}>
      <HeaderMobileWidget>
        <S.StyledSearchWithAddChannel />
      </HeaderMobileWidget>
      <LayoutContent>
        <S.PageContainer>
          <>
            {!isMobile && (
              <S.PageHeaderContainer>
                <Icon width={20} color={theme.persianBlue}>
                  <IconTelegramArrows />
                </Icon>
                <S.PageHeader>Управление каналами</S.PageHeader>
              </S.PageHeaderContainer>
            )}

            <S.ControlsContainer>
              <S.StyledTabs items={items} />
            </S.ControlsContainer>
          </>
        </S.PageContainer>
      </LayoutContent>
    </ChannelsContext.Provider>
  );
};

export default memo(ContentManager);
