import { Link } from 'react-router-dom';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const CardHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const CardContentContainer = styled.div`
  position: relative;
  bottom: 8px;
`;

export const LogoContainer = styled.div`
  background: ${({ theme }) => theme.venetianNights};
  border-radius: 50%;
  width: 64px;
  min-width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.white};
  margin-right: 16px;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const ChannelFakeIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.black};
  margin-right: 16px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ChannelTitle = styled.a`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  gap: 8px;
  color: inherit;
`;

export const ChannelName = styled.span``;

export const ChannelSubTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.bayFog};
`;

export const IconWrapper = styled.span`
  margin-right: 26px;
  cursor: pointer;
`;

export const BlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
`;

export const BlockHeader = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const BlockHeaderCount = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.blackRock};
  letter-spacing: 0.2px;
`;

export const BlockHeaderDesc = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.gunPowder};
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
  }

  svg {
    width: 12px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  //justify-content: space-between;
  justify-content: start;
  gap: 40px;
`;

export const InfoTitle = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.gunPowder};
`;

export const InfoValue = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.blackRock};
`;

export const InfoCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const RootInfo = styled.div`
  text-align: left;

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 0 24px 0 16px;
  }
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 14px;
  margin-bottom: 8px;
  padding: 16px;
`;

export const Info = styled.div`
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 14px;
  padding: 16px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.blackRock};
  padding: 8px 0;
  border-top: 1px solid ${({ theme }) => theme.alpineGoat};

  &:hover {
    color: inherit;
  }
`;

export const ChannelDescText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.blackRock};
  word-break: break-word;
  margin-bottom: 0;
  white-space: pre-wrap;
  max-width: 100%;
`;
