import styled from 'styled-components';

export const HeaderContentWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderContentBlock = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
