import { HistoryStatistic2 } from 'api/api';

export default (viewsData?: HistoryStatistic2) => {
  const length = viewsData?.history?.length;

  if (!length) return 0;

  const viewsNumber = viewsData?.history[length - 1] || 0;

  return viewsNumber;
};
