import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Time = styled.td`
  //padding-left: 17px;
  padding: 0;
`;

export const Number = styled.td`
  //padding: 0 5px;
  padding: 0;
`;

export const Event = styled.td`
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.blackRock};

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const IconWrap = styled.div`
  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.persianBlue};
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    svg {
      width: 12px;
      height: 12px;
      color: ${({ theme }) => theme.persianBlue};
    }
  }
`;
