import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IconFinances, IconLineArrowRight } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import Tag from 'fsd/shared/ui/Tag';
import isOnePurchasePublished from 'fsd/widgets/ContainersList/lib/isOnePurchasePublished';

import { ColumnData } from '../../../lib/useColumns';
import ActivePurchasesCount from '../../columns/ActivePurchasesCount';
import Menu from '../../columns/Menu';
import PurchaseCount from '../../columns/PurchaseCount';
import ReducedCost from '../../columns/ReducedCost';
import ReducedCoverage from '../../columns/ReducedCoverage';
import ReducedCpmTotal from '../../columns/ReducedCpmTotal';
import ReducedSubscribers from '../../columns/ReducedSubscribers';

import * as S from './styles';

type Props = {
  record: ColumnData;
};

const Row: FC<Props> = ({ record }) => {
  const isPublished = useMemo(() => isOnePurchasePublished(record.purchases), [record.purchases]);
  return (
    <S.Root>
      <S.Header>
        <S.Title>{record.container.title}</S.Title>
        <Menu.Cell record={record} />
      </S.Header>
      <S.TagList>
        <Tag>
          CPM<b>-</b>
        </Tag>
        <Tag>
          Каналов
          <b>
            <PurchaseCount.Cell record={record} />
          </b>
        </Tag>
      </S.TagList>
      <Tag size="medium" startIcon={<IconFinances />}>
        Общая сумма
        <b>
          <ReducedCost.Cell record={record} />
        </b>
      </Tag>
      <ActivePurchasesCount.Cell record={record} />
      {isPublished && (
        <S.TagList>
          <Tag>
            CPM итог
            <b>
              <ReducedCpmTotal.Cell record={record} />
            </b>
          </Tag>
          <Tag>
            Охват
            <b>
              <ReducedCoverage.Cell record={record} />
            </b>
          </Tag>
          <Tag>
            Новых пдп
            <b>
              <ReducedSubscribers.Cell record={record} />
            </b>
          </Tag>
        </S.TagList>
      )}
      <NavLink to={`/campaigns/${record.container.container_id}`}>
        <Button type="primary" size="small" block endIcon={<IconLineArrowRight />}>
          Перейти в кампанию
        </Button>
      </NavLink>
    </S.Root>
  );
};

export default memo(Row);
