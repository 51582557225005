import { useState } from 'react';
import { FilterState, MinMaxType } from 'types/channels/types';

import { CatalogProps } from '../../..';

const calcFilterCount = (values: FilterState) => {
  const {
    views,
    participants,
    mentions,
    er,
    languages,
    is_verified,
    is_public,
    cpm,
    countries,
    prices,
    format_ids,
    avg_daily_reaches
  } = values;

  let count = 0;

  // isPublic can be false or true as a filter
  if (is_public !== undefined) {
    count += 1;
  }

  // only one value can be selected
  if (is_verified) {
    count += 1;
  }

  // if we have language filter, count it. It can be an array
  if (languages && languages?.length > 0) {
    count += 1;
  }

  if (countries && countries.length > 0) {
    count += 1;
  }

  if (format_ids && format_ids.length > 0) {
    count += 1;
  }

  const checkMinMax = (value: MinMaxType) => {
    if (value?.minItems || value?.maxItems) {
      return true;
    }
    return false;
  };

  if (checkMinMax(participants)) {
    count += 1;
  }

  if (checkMinMax(views)) {
    count += 1;
  }

  if (checkMinMax(mentions)) {
    count += 1;
  }

  if (checkMinMax(er)) {
    count += 1;
  }

  if (checkMinMax(cpm)) {
    count += 1;
  }

  if (checkMinMax(prices)) {
    count += 1;
  }

  if (checkMinMax(avg_daily_reaches)) {
    count += 1;
  }

  return count;
};

export const useFiltersCount = ({ catalogProps }: CatalogProps) => {
  const [filtersCount, setFiltersCount] = useState(0);
  const state = catalogProps?.form?.getState();
  const { dirtySinceLastSubmit, values } = state;

  if (!dirtySinceLastSubmit) {
    const newCount = calcFilterCount(values);
    if (filtersCount !== newCount) {
      setFiltersCount(newCount);
    }
  }

  return {
    filtersCount
  };
};
