import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    if (!a.common_purchase_data || !b.common_purchase_data) return 0;

    const aSubs = a.common_purchase_data.subscribers ?? 0;
    const bSubs = b.common_purchase_data.subscribers ?? 0;

    return bSubs - aSubs;
  }
};

export default sorter;
