import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    if (
      !a.common_purchase_data ||
      !b.common_purchase_data ||
      !a.common_purchase_data.coverage ||
      !b.common_purchase_data.coverage
    )
      return 0;
    return b.common_purchase_data.coverage - a.common_purchase_data.coverage;
  }
};

export default sorter;
