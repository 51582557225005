import { FC } from 'react';
import { CartBottomInfo } from 'fsd/entities/cart';
import { CloseCart } from 'fsd/features/closeCart';
import { GoToCart } from 'fsd/features/goToCart';

import BackToCatalog from './BackToCatalog';

type Props = {
  page: 'catalog' | 'cart' | 'carts';
};

const CartBottomInfoPage: FC<Props> = ({ page }) => {
  return (
    <CartBottomInfo minCountToShow={page === 'catalog' ? 1 : 0} close={<CloseCart />}>
      {page === 'catalog' ? <GoToCart /> : <BackToCatalog />}
    </CartBottomInfo>
  );
};

export default memo(CartBottomInfoPage);
