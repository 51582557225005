import React, { FC } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<Props>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  padding: 12px 16px;
  border-radius: 4px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme, disabled }) => (disabled ? theme.violetPowder : theme.blackRock)};
`;

type Props = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
};

const ButtonContextMenu: FC<Props> = (props) => {
  return <StyledButton {...props} />;
};

export default ButtonContextMenu;
