import { FC } from 'react';
import { Modal, ModalProps } from 'antd5';
import styled from 'styled-components';

type Props = Omit<ModalProps, 'style' | 'height' | 'width' | 'modalRender'>;

const Root = styled(Modal)`
  & > div {
    height: 100%;
    pointer-events: auto;
  }
`;

const FullPage: FC<Props> = ({ children, ...restProps }) => {
  return (
    <Root
      modalRender={() => children}
      width="100%"
      height="100%"
      style={{ margin: 0, padding: 0, top: 0, maxWidth: '100%', height: '100%' }}
      mask={false}
      maskClosable={false}
      {...restProps}
    />
  );
};

export default memo(FullPage);
