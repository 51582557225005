import styled from 'styled-components';

export const Root = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const LoaderWrapper = styled.li`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
