import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import ClearIcon from './ClearIcon';

type Props = HTMLAttributes<HTMLButtonElement> & {
  size?: number;
};

const Root = styled.button<{ size: number }>`
  --size: ${({ size }) => size};
  width: var(--size);
  height: var(--size);
  border: none;
  background: none;
  flex: none;
`;

const ClearButton: FC<Props> = ({ size = 20, ...restProps }) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <Root size={size} {...restProps}>
      <ClearIcon size={size} />
    </Root>
  );
};

export default memo(ClearButton);
