import { FC, ReactNode } from 'react';
import { ModalProps } from 'antd';

import * as S from './Modal.styles';
import { AlternativeCloseModalIcon, CloseModalIcon } from './Modal.styles';

type Props = {
  topPosition?: number;
  alternativeCloseIcon?: ReactNode;
};

export const ModalUI: FC<ModalProps & Props> = ({
  closable = false,
  topPosition,
  footer = null,
  alternativeCloseIcon,
  ...props
}) => (
  <S.Root
    closeIcon={
      // eslint-disable-next-line no-nested-ternary
      closable ? (
        alternativeCloseIcon ? (
          <AlternativeCloseModalIcon $topPosition={topPosition} />
        ) : (
          <CloseModalIcon $topPosition={topPosition} />
        )
      ) : null
    }
    closable={closable}
    footer={footer}
    centered
    {...props}
  />
);

export default memo(ModalUI);
