import { FC } from 'react';
import { IconExport } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';

import Modal from '../Modal';

type Props = {
  containerId: string;
  mini?: boolean;
};

const ExportButton: FC<Props> = ({ containerId, mini }) => {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);
  return (
    <>
      <Modal open={open} onClose={handleClose} containerId={containerId} />
      <Button
        onClick={handleClick}
        startIcon={<IconExport />}
        square={mini}
        type="primary"
        size="middle"
      >
        {!mini && 'Экспорт'}
      </Button>
    </>
  );
};

export default ExportButton;
