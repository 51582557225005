import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DateFilterState = Record<string, number | null>;

const initialState: DateFilterState = {};

export const dateFilterSlice = createSlice({
  name: 'dateFilter',
  initialState,
  reducers: {
    setDateFilter: (state, action: PayloadAction<{ id: string; timestamp: number | null }>) => {
      state[action.payload.id] = action.payload.timestamp;
    }
  }
});

export const { setDateFilter } = dateFilterSlice.actions;

export default dateFilterSlice.reducer;
