import { ComponentProps, FC } from 'react';

import ComponentWrapper from './ComponentWrapper';

export default function flagComponent<T extends FC<any>>(flag: boolean, Component: T): T {
  if (flag)
    return ((props: ComponentProps<typeof Component>) => (
      <ComponentWrapper>
        <Component {...props} />
      </ComponentWrapper>
    )) as T;
  return (() => null) as unknown as T;
}
