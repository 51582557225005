import { ComponentProps, ReactNode } from 'react';
import { Tooltip } from 'antd';
import {
  api,
  ChannelWithExchangeInfo,
  usePatchV1UserChannelMutation,
  usePostV1UserChannelVerificationByChannelIdMutation
} from 'api/api';
import { URLs } from 'fsd/shared/config/URLs';
import { Button } from 'fsd/shared/ui/Buttons';

interface IScenario {
  key: number;
  // eslint-disable-next-line no-unused-vars
  conditionFn: (data: { channel: ChannelWithExchangeInfo }) => boolean;
  // eslint-disable-next-line no-unused-vars
  action?: (data: {
    updateChannel: ReturnType<typeof usePatchV1UserChannelMutation>[0];
    sendVerification: ReturnType<typeof usePostV1UserChannelVerificationByChannelIdMutation>[0];
    dispatch: ReturnType<typeof useAppDispatch>;
    channel: ChannelWithExchangeInfo;
  }) => void;
  button?: ComponentProps<typeof Button>;
  tooltip?: ComponentProps<typeof Tooltip>;
}

const validationChannelDetails = (channel: ChannelWithExchangeInfo) => {
  return (
    Boolean(channel.info?.language) &&
    Boolean(channel.info?.categories?.length) &&
    Boolean(channel.info?.country) &&
    Boolean(channel.exchange_info?.formats?.some(({ is_active: isActive }) => isActive)) &&
    Boolean(channel.exchange_info?.contact_id)
  );
};

const invalidTooltipTitle: ReactNode = (
  <>
    <p>Необходимо заполнить все поля</p>
    <ul style={{ paddingLeft: 20 }}>
      <li>Указать хотя бы 1 формат</li>
      <li>Выбрать контакт для приема заявок</li>
      <li>Указать тематику канала для поиска</li>
      <li>Выбрать язык канала и страну</li>
    </ul>
  </>
);

const notVerifiedTooltipTitle: ReactNode = (
  <p>
    Для повторой отправки на модерацию, обратитесь{' '}
    <a href={URLs.SUPPORT_BOT} target="_blank" rel="noreferrer">
      в поддержку
    </a>
  </p>
);

const scenarios: IScenario[] = [
  {
    key: 1,
    conditionFn({ channel }) {
      return (
        !channel.exchange_info?.is_exchange_active &&
        channel.exchange_info?.verification_status === 'not_started' &&
        !validationChannelDetails(channel)
      );
    },
    button: {
      disabled: true
    },
    tooltip: {
      title: invalidTooltipTitle
    }
  },
  {
    key: 2,
    conditionFn({ channel }) {
      return (
        !channel.exchange_info?.is_exchange_active &&
        channel.exchange_info?.verification_status === 'not_started' &&
        validationChannelDetails(channel)
      );
    },
    action({ sendVerification, updateChannel, dispatch, channel }) {
      const { undo: undoVerification } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const channelData = data.find((d) => d.info.id === channel.info.id);
          if (channelData?.exchange_info) {
            channelData.exchange_info.verification_status = 'in_progress';
          }
        })
      );
      const { undo: undoExchangeActive } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const channelData = data.find((d) => d.info.id === channel.info.id);
          if (channelData?.exchange_info) {
            channelData.exchange_info.is_exchange_active = true;
          }
        })
      );
      sendVerification({
        channelId: channel.info.id
      })
        .unwrap()
        .then(() => {
          updateChannel({
            body: {
              channel_id: channel.info.id,
              exchange_info: {
                channel_id: channel.info.id,
                is_exchange_active: true
              }
            }
          })
            .unwrap()
            .catch(undoExchangeActive);
        })
        .catch(undoVerification);
    }
  },
  {
    key: 3,
    conditionFn({ channel }) {
      return channel.exchange_info?.verification_status === 'not_verified';
    },
    button: {
      disabled: true,
      children: 'Канал не прошел модерацию',
      type: 'default'
    },
    tooltip: {
      title: notVerifiedTooltipTitle
    }
  },
  {
    key: 4,
    conditionFn({ channel }) {
      if (!channel.exchange_info?.verification_status) return false;
      return (
        channel.exchange_info.is_exchange_active &&
        !['not_started', 'not_verified'].includes(channel.exchange_info.verification_status)
      );
    },
    action({ updateChannel, dispatch, channel }) {
      const { undo: undoExchangeActive } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const channelData = data.find((d) => d.info.id === channel.info.id);
          if (channelData?.exchange_info) {
            channelData.exchange_info.is_exchange_active = false;
          }
        })
      );

      updateChannel({
        body: {
          channel_id: channel.info.id,
          exchange_info: {
            channel_id: channel.info.id,
            is_exchange_active: false
          }
        }
      })
        .unwrap()
        .catch(undoExchangeActive);
    },
    button: {
      children: 'Отключить прием заявок',
      type: 'default'
    }
  },
  {
    key: 5,
    conditionFn({ channel }) {
      if (!channel.exchange_info?.verification_status) return false;
      return (
        !channel.exchange_info.is_exchange_active &&
        !['not_started', 'not_verified'].includes(channel.exchange_info.verification_status) &&
        !validationChannelDetails(channel)
      );
    },
    tooltip: {
      title: invalidTooltipTitle
    },
    button: {
      disabled: true,
      children: 'Включить прием заявок'
    }
  },
  {
    key: 6,
    conditionFn({ channel }) {
      if (!channel.exchange_info?.verification_status) return false;
      return (
        !channel.exchange_info.is_exchange_active &&
        !['not_started', 'not_verified'].includes(channel.exchange_info.verification_status) &&
        validationChannelDetails(channel)
      );
    },
    action({ updateChannel, dispatch, channel }) {
      const { undo: undoExchangeActive } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const channelData = data.find((d) => d.info.id === channel.info.id);
          if (channelData?.exchange_info) {
            channelData.exchange_info.is_exchange_active = true;
          }
        })
      );

      updateChannel({
        body: {
          channel_id: channel.info.id,
          exchange_info: {
            channel_id: channel.info.id,
            is_exchange_active: true
          }
        }
      })
        .unwrap()
        .catch(undoExchangeActive);
    },
    button: {
      children: 'Включить прием заявок'
    }
  }
];

export default scenarios;
