import { FC } from 'react';
import { useGetV1ChannelsByChannelIdPostsAndPostIdQuery } from 'api/api';
import ChatMessage from 'fsd/shared/ui/ChatMessage';

type Props = {
  channelId: string;
  postId: number;
};

const Content: FC<Props> = ({ channelId, postId }) => {
  const { data: post, isFetching } = useGetV1ChannelsByChannelIdPostsAndPostIdQuery({
    channelId,
    postId
  });
  return (
    <ChatMessage.Wrapper type="base">
      <ChatMessage.Item>
        {isFetching ? 'Пост загружается...' : post?.content ?? 'Пост пустой'}
      </ChatMessage.Item>
    </ChatMessage.Wrapper>
  );
};

export default Content;
