export const cutNum = (num: number, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' K' },
    { value: 1e6, symbol: ' M' },
    { value: 1e9, symbol: ' B' }
  ];

  let isNegative = false;

  if (num < 0) {
    num = Math.abs(num);
    isNegative = true;
  }

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });
  return item
    ? isNegative
      ? -(num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};
