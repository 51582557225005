import { ComponentProps, FC } from 'react';
import { ChannelInfo } from 'fsd/entities/channel';
import { FormatSelect } from 'fsd/entities/format';
import { PurchaseRow } from 'fsd/entities/purchase';
import { useUpdatePurchaseFormatSelectLogic } from 'fsd/features/updatePurchaseFormat';

import { ColumnData } from '../../../lib/useColumns';
import Coverage from '../../columns/Coverage';
import Cpm from '../../columns/Cpm';
import CpmTotal from '../../columns/CpmTotal';
import Creative from '../../columns/Creative';
import DateWithTime from '../../columns/DateWithTime';
import Menu from '../../columns/Menu';
import Status from '../../columns/Status';
import Subscribers from '../../columns/Subscribers';

type Props = {
  purchase: ColumnData;
};

type RowProps = ComponentProps<typeof PurchaseRow.Row>;

const Row: FC<Props> = ({ purchase }) => {
  const formatSelectLogic = useUpdatePurchaseFormatSelectLogic(purchase);
  const formats = useMemo(
    () => formatSelectLogic.formats.map((format) => ({ ...format, format_id: format._id })),
    [formatSelectLogic.formats]
  );

  const mainTags = useMemo<RowProps['tags']>(() => {
    return [
      {
        key: 'cpm',
        content: (
          <>
            <Cpm.Title />{' '}
            <b>
              <Cpm.Cell purchase={purchase} />
            </b>
          </>
        )
      }
    ];
  }, [purchase]);

  const tags = useMemo<RowProps['tags']>(() => {
    if (!purchase.published_at) return [];
    return [
      {
        key: 'coverage',
        content: (
          <>
            <Coverage.Title />{' '}
            <b>
              <Coverage.Cell purchase={purchase} />
            </b>
          </>
        )
      },
      {
        key: 'cpmTotal',
        content: (
          <>
            <CpmTotal.Title />{' '}
            <b>
              <CpmTotal.Cell purchase={purchase} />
            </b>
          </>
        )
      },
      {
        key: 'subscribers',
        content: (
          <>
            <Subscribers.Title />{' '}
            <b>
              <Subscribers.Cell purchase={purchase} />
            </b>
          </>
        )
      }
    ];
  }, [purchase]);
  return (
    <PurchaseRow.Row
      info={
        purchase.channel && (
          <ChannelInfo size="m" withTgLing={false} channelInfo={purchase.channel.info} />
        )
      }
      mainTags={mainTags}
      buttons={<Menu.Cell purchase={purchase} />}
      tags={tags}
      date={<DateWithTime.Cell purchase={purchase} block />}
      post={<Creative.Cell purchase={purchase} />}
      format={
        <FormatSelect
          formats={formats}
          value={formatSelectLogic.formatId}
          onSelect={formatSelectLogic.handleSelect}
          disabled={formatSelectLogic.isDisabled}
        />
      }
    >
      <Status.Cell purchase={purchase} />
    </PurchaseRow.Row>
  );
};

export default memo(Row);
