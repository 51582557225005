import { ComponentProps } from 'react';
import { IconFinances } from 'fsd/shared/icons';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { LayoutContent } from 'fsd/shared/ui/Layout';
import { HeaderMobileWidget, HeaderTitleWidget, HeaderWidget } from 'fsd/widgets/Header';

import FinancesContent from './FinancesContent';

import * as S from './Finances.styles';

const Finances = () => {
  const isMobile = useIsMobile();
  const tabs: ComponentProps<(typeof S)['Tabs']>['items'] = [
    {
      key: useId(),
      label: 'Финансы',
      children: <FinancesContent />
    }
  ];

  return (
    <>
      <HeaderMobileWidget>
        <HeaderTitleWidget icon={<IconFinances />}>Управление финансами</HeaderTitleWidget>
      </HeaderMobileWidget>
      <LayoutContent>
        <S.Root>
          {!isMobile && (
            <S.HeaderWrapper>
              <HeaderWidget title="Управление финансами" icon={<IconFinances />} />
            </S.HeaderWrapper>
          )}
          <S.Tabs items={tabs} />
        </S.Root>
      </LayoutContent>
    </>
  );
};

export default memo(Finances);
