import { FC, RefObject } from 'react';
import { Tooltip } from 'antd';
import { ChannelRead, Id } from 'api/api';
import { Chart } from 'chart.js';
import { IconUsers } from 'fsd/shared/icons';
import { checkIsDataExsist, cutNum, getColorByCount } from 'fsd/shared/lib/helpers/tools';
import { EmptyLine } from 'fsd/shared/ui/EmptyLine';
import Skeleton from 'fsd/shared/ui/Skeleton';

import { ChartJS } from 'components/ChartWithZoom';
import { EmptyDataText } from 'components/ChartWithZoom/components/EmptyDataText';
import { theme } from 'theme/theme';

import { useAuth } from '../../../../fsd/app/providers/Auth';

import { useParticipantsBlockLogic } from './hooks/useParticipantsBlockLogic';

import * as LS from '../styles';

export type Props = {
  channelId: Id;
  now: string;
  channel: ChannelRead | undefined;
  isLoadingChannel: boolean;
  compact?: boolean;
  chartRef: RefObject<Chart>;
};

const ParticipantsBlock: FC<Props> = (props) => {
  const { isAuth } = useAuth();

  const {
    isLoadingParticipants,
    isLoadingChannel,
    participantsStatistic,
    participantsAll,
    participantsToday,
    participantsYesterday,
    participantsWeek,
    participantsMonth,
    handleDateClick,
    chartRef,
    graphData,
    handleZoomChange,
    handleChartClick,
    isEmptyDataOnZoomChange
  } = useParticipantsBlockLogic(props);

  if (isLoadingParticipants || isLoadingChannel || !isAuth) {
    return <Skeleton type="card" id={1} animation={isAuth} />;
  }

  return (
    <LS.BlockContainer pointClip className="usiteful__channel-page__subscribers-widget">
      <LS.MetaInfoContainer>
        <Tooltip
          overlayClassName="tooltip-none-max-width"
          placement="topLeft"
          title="Текущее количество подписчиков"
        >
          <LS.TotalInfo>
            <LS.Count color={theme.blackRock}>
              {checkIsDataExsist(participantsStatistic) && participantsAll ? (
                new Intl.NumberFormat().format(participantsAll)
              ) : (
                <EmptyLine />
              )}
            </LS.Count>
            <LS.HeaderDesciption>
              <LS.IconWrapper>
                <LS.BigIcon>
                  <IconUsers />
                </LS.BigIcon>
              </LS.IconWrapper>
              <LS.CountDesciptionLabel>Подписчики</LS.CountDesciptionLabel>
            </LS.HeaderDesciption>
          </LS.TotalInfo>
        </Tooltip>
        <LS.DetailedInfo>
          <LS.Pointer
            onClick={() => {
              handleDateClick('today');
            }}
          >
            <LS.Count color={getColorByCount(participantsToday)}>
              {participantsToday ? cutNum(participantsToday) : <EmptyLine />}
            </LS.Count>
            <LS.CountDesciption>Сегодня</LS.CountDesciption>
          </LS.Pointer>
          <LS.Pointer
            onClick={() => {
              handleDateClick('yesterday');
            }}
          >
            <LS.Count color={getColorByCount(participantsYesterday)}>
              {participantsYesterday ? cutNum(participantsYesterday) : <EmptyLine />}
            </LS.Count>
            <LS.CountDesciption>Вчера</LS.CountDesciption>
          </LS.Pointer>
          <LS.Pointer
            onClick={() => {
              handleDateClick('week');
            }}
          >
            <LS.Count color={getColorByCount(participantsWeek)}>
              {participantsWeek ? cutNum(participantsWeek) : <EmptyLine />}
            </LS.Count>
            <LS.CountDesciption>Неделя</LS.CountDesciption>
          </LS.Pointer>
          <LS.Pointer
            onClick={() => {
              handleDateClick('month');
            }}
          >
            <LS.Count color={getColorByCount(participantsMonth)}>
              {participantsMonth ? cutNum(participantsMonth) : <EmptyLine />}
            </LS.Count>
            <LS.CountDesciption>Месяц</LS.CountDesciption>
          </LS.Pointer>
        </LS.DetailedInfo>
      </LS.MetaInfoContainer>

      <LS.ChartContainer>
        <LS.Title>
          <LS.ChartFilter>
            <LS.BigIconTableParamsBlue>
              <IconUsers />
            </LS.BigIconTableParamsBlue>
            <LS.ChartFilterLabel>Подписчики</LS.ChartFilterLabel>
          </LS.ChartFilter>
        </LS.Title>

        <LS.ChartWrapper>
          <ChartJS
            chartref={chartRef}
            data={graphData}
            onZoomChange={handleZoomChange}
            onClick={handleChartClick}
            isDashed
          />
          {isEmptyDataOnZoomChange && <EmptyDataText />}
        </LS.ChartWrapper>
      </LS.ChartContainer>
    </LS.BlockContainer>
  );
};

export default memo(ParticipantsBlock);
