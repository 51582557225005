import { useRef, useState } from 'react';
import { Popover, Tooltip } from 'antd';
import { ChannelRead, Id, useGetV2ChannelsByMetricTypeQuery } from 'api/api';
import { Chart } from 'chart.js';
import { IconCalendar, IconSpeaker } from 'fsd/shared/icons';
import { checkIsDataExsist, cutNum } from 'fsd/shared/lib/helpers/tools';
import { toISOStringWithMSKTime } from 'fsd/shared/lib/helpers/tools/dates';
import { theme } from 'theme/theme';
import { EmptyLine } from 'fsd/shared/ui/EmptyLine';
import { Icon } from 'fsd/shared/ui/Icon';
import Skeleton from 'fsd/shared/ui/Skeleton';

import { ChartJS } from 'components/ChartWithZoom';
import { generateDates, ListViewCalendar } from 'components/ListViewCalendar';

import { useGetV1MentionsData } from './hooks';

import * as LS from '../styles';

export type Props = {
  channelId: Id;
  now: string;
  channel: ChannelRead | undefined;
  isLoadingChannel: boolean;
};

const generateDefaultDate = (now: string) => {
  const date = new Date(now);
  const year = date.getFullYear();
  const month = date.getMonth();
  return `${year}-${month + 1}`;
};

const MentionsBlock = (props: Props) => {
  const { isAuth } = useAuth();

  const {
    isLoadingChannel,
    isLoadingMentions,
    mentionsStatistic,
    mentionsHistoryEmtpy,
    mentionsAll,
    mentionsToday,
    mentionsYesterday,
    mentionsWeek,
    mentionsMonth,
    setIsPickerOpen,
    isPickerOpen,
    selectedDate,
    setSelectedDate,
    graphData,
    channel,
    now
  } = useMentionsBlockLogic(props);
  if (isLoadingChannel || isLoadingMentions || !isAuth) {
    return <Skeleton type="card" id={3} modificator animation={isAuth} />;
  }

  return (
    <LS.BlockContainer className="usiteful__channel-page__mentions-widget">
      <LS.MetaInfoContainer>
        <Tooltip title="Упоминания канала за всё время">
          <LS.TotalInfo>
            <LS.Count color={theme.blackRock}>
              {checkIsDataExsist(mentionsStatistic) && !mentionsHistoryEmtpy ? (
                new Intl.NumberFormat().format(mentionsAll)
              ) : (
                <EmptyLine />
              )}
            </LS.Count>
            <LS.HeaderDesciption>
              <LS.BigIcon>
                <IconSpeaker />
              </LS.BigIcon>
              <LS.CountDesciptionLabel>Упоминания канала</LS.CountDesciptionLabel>
            </LS.HeaderDesciption>
          </LS.TotalInfo>
        </Tooltip>
        <div>
          <LS.DetailedInfo>
            <div>
              <LS.Count color={theme.persianBlue}>
                {checkIsDataExsist(mentionsStatistic, 'today') ? (
                  cutNum(mentionsToday)
                ) : (
                  <EmptyLine />
                )}
              </LS.Count>
              <LS.CountDesciption>Сегодня</LS.CountDesciption>
            </div>
            <div>
              <LS.Count color={theme.persianBlue}>
                {checkIsDataExsist(mentionsStatistic, 'yesterday') ? (
                  cutNum(mentionsYesterday)
                ) : (
                  <EmptyLine />
                )}
              </LS.Count>
              <LS.CountDesciption>Вчера</LS.CountDesciption>
            </div>
            <div>
              <LS.Count color={theme.persianBlue}>
                {checkIsDataExsist(mentionsStatistic, 'week') ? (
                  cutNum(mentionsWeek)
                ) : (
                  <EmptyLine />
                )}
              </LS.Count>
              <LS.CountDesciption>Неделя</LS.CountDesciption>
            </div>
            <div>
              <LS.Count color={theme.persianBlue}>
                {checkIsDataExsist(mentionsStatistic, 'month') ? (
                  cutNum(mentionsMonth)
                ) : (
                  <EmptyLine />
                )}
              </LS.Count>
              <LS.CountDesciption>Месяц</LS.CountDesciption>
            </div>
          </LS.DetailedInfo>
        </div>
      </LS.MetaInfoContainer>
      <LS.ChartContainerWithNotifications>
        <LS.TitleWithCalendar>
          <LS.ChartToggle>
            <LS.ChartFilter>
              <Icon width={16} color={theme.persianBlue}>
                <IconSpeaker />
              </Icon>
              <LS.ChartFilterLabel>Количество упоминаний</LS.ChartFilterLabel>
            </LS.ChartFilter>
          </LS.ChartToggle>

          <LS.CalendarContainer>
            <Tooltip title="Выбрать месяц" zIndex={1}>
              <LS.BigIconCalendar>
                <IconCalendar
                  onClick={() => {
                    setIsPickerOpen((prev) => !prev);
                  }}
                />
              </LS.BigIconCalendar>
            </Tooltip>

            <Popover
              zIndex={1080}
              overlayClassName="contextmenu-popover"
              autoAdjustOverflow
              placement="rightTop"
              showArrow={false}
              trigger="click"
              open={isPickerOpen}
              onOpenChange={(e) => {
                setIsPickerOpen(!isPickerOpen);
              }}
              content={
                <ListViewCalendar
                  onChange={(dateString: string) => {
                    setSelectedDate(dateString);
                    setIsPickerOpen(false);
                  }}
                  value={selectedDate}
                  dates={generateDates(channel?.created_at, now)}
                />
              }
            />
          </LS.CalendarContainer>
        </LS.TitleWithCalendar>

        <LS.ChartWrapper>
          <ChartJS data={graphData} hasScaleLines />
        </LS.ChartWrapper>
      </LS.ChartContainerWithNotifications>
    </LS.BlockContainer>
  );
};

export const useMentionsBlockLogic = ({ channelId, now, channel, isLoadingChannel }: Props) => {
  const { isAuth } = useAuth();
  const chartRef = useRef<Chart>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(generateDefaultDate(now));

  const { data: channelMetrics, isLoading: isLoadingMentions } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channelId],
      metricType: 'mentions',
      dateRange: {
        to: now
      },
      historicalMetricsLoaded: !!channel?.historical_metrics_loaded,
      timeZone: 'Europe/Moscow'
    },
    { refetchOnReconnect: true, skip: isLoadingChannel || !isAuth }
  );

  const {
    mentionsStatistic,
    mentionsToday,
    mentionsYesterday,
    mentionsWeek,
    mentionsMonth,
    mentionsAll,
    graphData,
    mentionsHistoryEmtpy
  } = useGetV1MentionsData(channelMetrics?.channels?.[0]);

  const filteredData = graphData?.[0]?.data?.filter((item: { x: number; y: number }) => {
    const shift = 3 * 60 * 60 * 1000;
    const date = new Date(new Date(item.x).getTime() + shift);
    const isoDate = toISOStringWithMSKTime(date);
    const [year, month] = isoDate.split('-');
    const [selectedYear, selectedMonth] = selectedDate.split('-');
    const preparedSelectedMonth = selectedMonth.length === 1 ? `0${selectedMonth}` : selectedMonth;

    return year === selectedYear && month === preparedSelectedMonth;
  });

  const TARGET_NUMBERS = 30;
  const diff = TARGET_NUMBERS - filteredData.length;
  const day = 24 * 60 * 60 * 1000;
  const fillArray = new Array(diff).fill(0).map((_, index) => {
    return {
      x: filteredData[filteredData.length - 1]?.x + day * (index + 1),
      y: 0
    };
  });
  const filledData = filteredData.concat(fillArray);

  graphData[0].data = filledData;

  return {
    isLoadingChannel,
    isLoadingMentions,
    mentionsStatistic,
    mentionsHistoryEmtpy,
    mentionsAll,
    mentionsToday,
    mentionsYesterday,
    mentionsWeek,
    mentionsMonth,
    setIsPickerOpen,
    isPickerOpen,
    selectedDate,
    setSelectedDate,
    graphData,
    channel,
    now,
    chartRef
  };
};

export default MentionsBlock;
