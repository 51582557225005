import { MediaQueryBreakpointSize } from '../modules/media-queries/lib/MediaQueryBreakpoint';

type MediaDeviceNames =
  | 'mobileS'
  | 'mobile'
  | 'mobileL'
  | 'tablet'
  | 'laptopS'
  | 'laptop'
  | 'laptopL'
  | 'desktopS'
  | 'desktop'
  | 'desktopL';

export const MEDIA_DEVICE_BREAKPOINTS: Record<MediaDeviceNames, MediaQueryBreakpointSize> = {
  mobileS: {
    from: 320,
    to: 375
  },
  mobile: {
    from: 375,
    to: 540
  },
  mobileL: {
    from: 540,
    to: 768
  },
  tablet: {
    from: 768,
    to: 1024
  },
  laptopS: {
    from: 1024,
    to: 1280
  },
  laptop: {
    from: 1280,
    to: 1440
  },
  laptopL: {
    from: 1440,
    to: 1600
  },
  desktopS: {
    from: 1600,
    to: 1920
  },
  desktop: {
    from: 1920,
    to: 2560
  },
  desktopL: {
    from: 2560,
    to: 2560 * 2
  }
};
