import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledSideNav = styled.nav<{ isAuth: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isAuth }) => (isAuth ? '35px' : '107px')} 6px 26px;
  gap: 45px;
  position: fixed;
  width: 64px;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${({ theme }) => theme.white};
  z-index: 10;

  box-shadow:
    rgba(0, 0, 0, 0.07) 0px -4px 64px,
    rgba(179, 175, 214, 0.07) 0px 100px 80px,
    rgba(179, 175, 214, 0.05) 0px 41.7776px 33.4221px,
    rgba(179, 175, 214, 0.043) 0px 22.3363px 17.869px,
    rgba(179, 175, 214, 0.035) 0px 12.5216px 10.0172px,
    rgba(179, 175, 214, 0.027) 0px 6.6501px 5.32008px,
    rgba(179, 175, 214, 0.02) 0px 2.76726px 2.21381px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const Beta = styled.div`
  width: 100%;
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: ${({ theme }) => theme.alpineGoat};

  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;

  svg {
    width: 24px;
  }

  a {
    color: ${({ theme }) => theme.violetPowder};

    &:hover {
      color: ${({ theme }) => theme.persianBlue} !important;
    }
  }
`;

export const StyledTextBlock = styled.div`
  padding: 10px 10px 14px 10px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const SettingContainer = styled.div`
  width: 256px;
`;

export const Account = styled.div`
  color: ${({ theme }) => theme.violetPowder};

  &:hover {
    color: ${({ theme }) => theme.persianBlue} !important;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin: 0 6px 4px 2px;
`;

export const SettingsButtonsWrapper = styled.div`
  padding: 0 4px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SettingsButton = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  gap: 10px;

  background: ${({ theme }) => theme.alpineGoat};
  padding: 10px 14px;

  border-radius: 4px;

  cursor: pointer;
`;

export const ButtonText = styled.div`
  color: ${({ theme }) => theme.blackRock};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`;

export const IconSettingsStyles = styled.div`
  width: 20px;
  height: 20px;

  color: ${({ theme }) => theme.violetPowder};

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;
