import { ComponentProps, FC } from 'react';

import { CustomRow } from '../../../lib/createCustomRow';

import * as S from './styles';

type Props = ComponentProps<typeof S.Root> & { customRow: CustomRow<any> };

const LoaderRow: FC<Props> = ({ customRow, ...restProps }) => {
  return <S.Root {...restProps} />;
};

export default memo(LoaderRow);
