/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-rest-params */

import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Button } from 'fsd/shared/ui/Buttons';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';
import styled from 'styled-components';

export const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    // @ts-ignore: Unreachable code error
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      // eslint-disable-next-line no-undef, no-return-assign
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: -12px;

  @media ${MEDIA_DEVICE.tablet.from} {
    margin-top: -18px;
    button {
      margin-bottom: 2px;
    }
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 12px;
  }
`;

export const throttledTimeoutMessage = throttle(() => {
  CustomToast({
    type: 'error',
    options: {
      autoClose: false
    },
    message: (
      <ToastMessage
        title="Что-то пошло не так"
        description={
          <StyledRow>
            <span>Попробуйте перезагрузить страницу.</span>
            <Button
              size="small"
              variant="outlined"
              // eslint-disable-next-line no-undef
              onClick={() => window.location.reload()}
            >
              Перезагрузить страницу
            </Button>
          </StyledRow>
        }
        withoutClose
      />
    )
  });
}, 1000);
