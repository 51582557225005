import { FC } from 'react';
import { changeCart } from 'fsd/entities/cart';
import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { CartBottomInfoWidget } from 'fsd/widgets/CartBottomInfo';
import { PurchaseContainerListWidget } from 'fsd/widgets/PurchaseContainerList';

import { ContainerPageProvider } from '../../lib/pageContext';
import usePageData from '../../lib/usePageData';
import Header, { HeaderMobile } from '../Header';

import * as S from './styles';

type Props = {
  containerId: string;
};

const Page: FC<Props> = ({ containerId }) => {
  const dispatch = useAppDispatch();

  const cartContainerId = useAppSelector(getCartContainerIdSelector);

  const pageData = usePageData({ containerId });

  useEffect(() => {
    if (cartContainerId) {
      dispatch(changeCart(containerId));
    }
  }, [cartContainerId, containerId, dispatch]);
  return (
    <ContainerPageProvider value={pageData}>
      <HeaderMobile />
      <S.Wrapper>
        <Header />
        <S.Root $isHaveCart={Boolean(cartContainerId)}>
          <PurchaseContainerListWidget containerId={containerId} />
        </S.Root>
      </S.Wrapper>
      <CartBottomInfoWidget page="cart" />
    </ContainerPageProvider>
  );
};

export default memo(Page);
