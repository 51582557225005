import { memo, useState } from 'react';
import { Dropdown } from 'antd';
import { Id, useGetV1ChannelsByChannelIdQuery } from 'api/api';
import { useAppSelector } from 'fsd/app/redux/hooks';
import { useChannelCategories } from 'fsd/entities/channel/lib/useChannelCategories';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';
import styled from 'styled-components';

import ChannelTabLabel from 'components/ChannelTabs/ChannelTabLabel';

import TabsContextMenu from '../../components/TabsContextMenu';

import * as S from './styles';

type Props = {
  channelId: Id;
  isActive?: boolean;
  isCollapsed?: boolean;
};

const StyledWrap = styled.div`
  margin: 13px 16px;
`;

const ChannelTab = ({ channelId, isActive = false, isCollapsed = false }: Props) => {
  const { data: channel } = useGetV1ChannelsByChannelIdQuery({ channelId });
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const channelIds = useAppSelector((state) => state.channelTabs.channelIds);
  const commaSeparatedCategoryNames = useChannelCategories(channel?.info?.categories) ?? '';

  if (!channel || !channel.info) {
    return (
      <S.LoaderWrapper>
        <LoaderSpinner />
      </S.LoaderWrapper>
    );
  }

  const { title, name, icon_url: iconUrl, is_verified: isVerified = false } = channel.info;

  if (isCollapsed) {
    return (
      <>
        {title} | {commaSeparatedCategoryNames}
      </>
    );
  }

  return (
    <Dropdown
      trigger={['contextMenu']}
      open={isDropdownVisible}
      onOpenChange={() => setIsDropdownVisible((prevState) => !prevState)}
      overlay={
        <TabsContextMenu
          setIsDropdownVisible={setIsDropdownVisible}
          channelId={channelIds}
          actionType="multi"
        />
      }
    >
      <div className="usiteful__catalog__channel-tab" style={{ width: '100%' }}>
        <StyledWrap>
          <ChannelTabLabel
            channel={channel.info}
            exchange_info={channel.exchange_info}
            title={title}
            name={name}
            category={commaSeparatedCategoryNames}
            iconUrl={iconUrl}
            isVerified={isVerified}
            isActive={isActive}
          />
        </StyledWrap>
      </div>
    </Dropdown>
  );
};

export default memo(ChannelTab);
