import { FC } from 'react';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { ChannelWithExchangeInfo } from 'api/api';
import { useInfinitier } from 'fsd/shared/modules/infinitier';
import Loader from 'fsd/shared/ui/Loader';

import { MobileRow } from '.';

import * as S from './List.styles';

type Props = {
  hasMore: boolean;
  offsetLoadMore?: number;
  loadMore: Parameters<typeof useInfinitier>[0]['loadMore'];
  channels: ChannelWithExchangeInfo[];
};

const List: FC<Props> = ({ channels, offsetLoadMore, loadMore, hasMore }) => {
  const rowVirtualizer = useWindowVirtualizer({
    count: channels.length,
    estimateSize: () => 200,
    overscan: 20
  });

  useInfinitier({
    windowScroll: true,
    hasMore,
    loadMore,
    offsetLoadMore
  });

  return (
    <S.Root
      style={{
        position: 'relative',
        height: `${rowVirtualizer.getTotalSize() + 86}px`
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        const channel = channels[virtualRow.index];
        return (
          <MobileRow
            channel={channel}
            key={channel.info.id}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`
            }}
          />
        );
      })}
      {hasMore && (
        <S.LoaderWrapper
          style={{
            position: 'absolute',
            bottom: 0
          }}
        >
          <Loader width={10 / 2} height={70 / 2} />
        </S.LoaderWrapper>
      )}
    </S.Root>
  );
};

export default memo(List);
