import { FC } from 'react';
import { calcPurchaseCPMTotal } from 'fsd/entities/purchase';
import { formatNumber } from 'fsd/shared/lib/helpers/tools';
import { Table } from 'fsd/shared/ui/Table';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  const result = calcPurchaseCPMTotal(purchase);
  return typeof result !== 'undefined' ? (
    <>{formatNumber(result, 1).toString().replace('.0', '')} ₽</>
  ) : (
    <Table.NoCellData />
  );
};

export default memo(Cell);
