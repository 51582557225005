import { IconSortFlat } from 'fsd/shared/icons';
import { theme } from 'theme/theme';
import styled from 'styled-components';

import { Sortings } from '../../hooks';

export const StyledSortIcon = styled(IconSortFlat)<{ sorted: Sortings }>`
  transform: scaleY(${({ sorted }) => (sorted === 'asc' ? -1 : 1)});
  transition: 0.2s ease-in-out;
  color: ${({ sorted }) => (sorted === null ? theme.majesticMount : theme.persianBlue)};
`;

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;
