import styled from 'styled-components';

import { Icon } from '../Icon';

export const Root = styled.div<{ $size?: 'small' | 'medium' | 'large' }>`
  --height: ${({ $size }) => {
    switch ($size) {
      case 'medium':
        return '36px';
      default:
        return '28px';
    }
  }};
  --gap: ${({ $size }) => {
    switch ($size) {
      case 'medium':
        return '10px';
      default:
        return '4px';
    }
  }};
  --border-radius: 100px;
  --padding-x: ${({ $size }) => {
    switch ($size) {
      case 'medium':
        return '16px';
      default:
        return '10px';
    }
  }};
  --border-color: ${({ theme }) => theme.violetPowder};
  --color: ${({ theme }) => theme.blackRock};
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--height);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  color: var(--color);
  font-size: 12px;
  font-weight: 400;
  line-height: 114%;
  white-space: nowrap;
  gap: var(--gap);
  padding: 0 var(--padding-x);
  b {
    font-weight: 700;
  }
`;

export const Status = styled.div<{ $color?: string }>`
  width: 6px;
  height: 6px;
  background: ${({ theme, $color }) => $color || theme.londonGrey};
  flex: none;
  border-radius: 10px;
`;

export const IconWrapper = styled(Icon).attrs(({ theme, color }) => ({
  color: color || theme.persianBlue,
  width: 14
}))``;
