import sign from 'jwt-encode';

import { SortingsProps } from '../hooks';

type PostType = 'regular' | 'mention' | 'repost';
export type PostTypes = Array<PostType>;
enum FieldType {
  date = 'date',
  views = 'views',
  shares = 'shares'
}
type PayloadType = {
  type: PostTypes;
  field: FieldType[];
  order: 'ASC' | 'DESC';
  pagination: {
    limit: number;
    offset: number;
  };
};

const LIMIT = 10;

export const getNavigationBySortings = (
  sortings: SortingsProps,
  types: PostTypes,
  page: number
) => {
  const order = Object.values(sortings).find((item) => item === 'asc' || item === 'desc');
  const payload: PayloadType = {
    type: types,
    field: [
      ...(sortings.date ? [FieldType.date] : []),
      ...(sortings.views ? [FieldType.views] : []),
      ...(sortings.forwards ? [FieldType.shares] : [])
    ],
    order: order === 'asc' ? 'ASC' : 'DESC',
    pagination: {
      limit: LIMIT,
      offset: page * LIMIT
    }
  };

  const JWT_PAYLOAD = sign(payload, import.meta.env.VITE_REACT_APP_JWT_SECRET || '');

  return JWT_PAYLOAD;
};
