import { IconLogoMobile, IconMobileOrientation } from 'fsd/shared/icons';

import * as S from './Fallback.styles';

const Fallback = () => {
  return (
    <S.Root>
      <S.LogoWrapper width={36} color={theme.sweetFlower}>
        <IconLogoMobile />
      </S.LogoWrapper>
      <S.Content>
        <S.IconWrapper width={84}>
          <IconMobileOrientation />
        </S.IconWrapper>
        <S.Text>
          Мобильная страница находится в разработке. Необходимо вернуть телефон в вертикальное
          положение, или открыть страницу на компьютере или планшете
        </S.Text>
      </S.Content>
    </S.Root>
  );
};

export default Fallback;
