// import { useGetV1ExchangeCurrencyQuery } from "../api/api";
import { IconRuble, IconToncoin, IconToncoinSign, IconUSD } from '../../icons';

export const currencyList = {
  RUB: {
    name: 'rub',
    title: 'Российский рубль',
    icon: <IconRuble />,
    sign: '₽'
  },
  USD: {
    name: 'usd',
    title: 'Доллар США',
    icon: <IconUSD />,
    sign: '$'
  },
  TON: {
    name: 'ton',
    title: 'Toncoin',
    icon: <IconToncoin />,
    sign: <IconToncoinSign />
  }
};

type CurrencyItem = {
  name: string;
  title: string;
  icon: JSX.Element;
};

export const useCurrencyList = (): CurrencyItem[] => {
  // const { data: _currency } = useGetV1ExchangeCurrencyQuery();

  // TS-1592 - left only RUB currency
  const currencies = {
    currency_list: ['RUB']
  };

  const result: CurrencyItem[] = [];

  currencies?.currency_list?.forEach((currency) => {
    const currencyKey = currency.toUpperCase() as keyof typeof currencyList;
    result.push(currencyList[currencyKey]);
  });

  return result;
};
