import { FC, memo, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import useOrientationLocker from '../lib/useOrientationLocker';
import { IOptions } from '../types';

type Props = IOptions;

const Provider: FC<PropsWithChildren<Props>> = ({ fallback, enabled, orientation, children }) => {
  const orientationLocker = useOrientationLocker();
  return (
    <>
      {children}
      {enabled &&
        orientation === orientationLocker.orientation &&
        createPortal(fallback, document.body)}
    </>
  );
};

export default memo(Provider);
