import { ComponentProps, FC, ReactNode } from 'react';
import { type TableProps } from 'antd5';
import { useInfinitier } from 'fsd/shared/modules/infinitier';

import Table from './Table';

type RenderProps = Pick<Partial<ComponentProps<typeof Table>>, 'scroll' | 'onScroll'>;

type RenderFn = (data: { props: RenderProps }) => ReactNode;

type Props = {
  render: RenderFn;
  hasMore: boolean;
  maxScrollHeight?: number;
  minScrollHeight?: number;
  offsetScrollHeight?: number;
  offsetLoadMore?: number;
  scroll?: TableProps<any>['scroll'];
  loadMore: Parameters<typeof useInfinitier>[0]['loadMore'];
};

const InfiniteProvider: FC<Props> = ({
  render,
  loadMore,
  hasMore,
  scroll,
  maxScrollHeight,
  minScrollHeight,
  offsetScrollHeight,
  offsetLoadMore
}) => {
  const { handleScroll: _handleScroll } = useInfinitier({
    hasMore,
    loadMore,
    offsetLoadMore
  });

  const handleScroll = useCallback(
    (e: any) => {
      _handleScroll(e);
    },
    [_handleScroll]
  );

  const props: RenderProps = {
    onScroll: handleScroll,
    scroll: {
      scrollToFirstRowOnChange: false,
      y: window.innerHeight - (offsetScrollHeight || 380),
      x: 1400,
      ...scroll
    }
  };

  // eslint-disable-next-line no-undef
  return <>{render({ props })}</>;
};

export default InfiniteProvider;
