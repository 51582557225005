import { Popover } from 'antd';
import { useGetV1UserChannelsQuery, useGetV1UserQuery } from 'api/api';
import { IconAccount, IconSettings } from 'fsd/shared/icons';
import { theme } from 'theme/theme';

import * as S from '../../style';

const UserSettings = () => {
  const { data: user } = useGetV1UserQuery();
  const { data: userChannels } = useGetV1UserChannelsQuery();
  const { username, tg_username } = user || {};

  const channelsNumber = userChannels ? userChannels.length : 0;

  // word declension method
  function declineChannels(number: number) {
    const cases = [2, 0, 1, 1, 1, 2];

    return `${number} ${
      ['канал', 'канала', 'каналов'][
        number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
      ]
    }`;
  }

  return (
    <Popover
      placement="rightTop"
      overlayClassName="settings-popover"
      showArrow={false}
      content={
        <S.SettingContainer>
          <S.StyledTextBlock className="usiteful__nav-account">
            <span
              style={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              {username}
            </span>
            <span
              style={{
                marginTop: 6,
                fontSize: '14px',
                lineHeight: '16px',
                color: theme.bayFog
              }}
            >
              {tg_username ? `@${tg_username}, ${declineChannels(channelsNumber)}` : null}
            </span>
          </S.StyledTextBlock>

          <S.Divider />

          <S.SettingsButtonsWrapper>
            <S.SettingsButton to="/settings">
              <S.IconSettingsStyles>
                <IconSettings />
              </S.IconSettingsStyles>
              <S.ButtonText>Настройки</S.ButtonText>
            </S.SettingsButton>
          </S.SettingsButtonsWrapper>
        </S.SettingContainer>
      }
    >
      <S.Account>
        <IconAccount className="usiteful__nav-account-icon" />
      </S.Account>
    </Popover>
  );
};

export default UserSettings;
