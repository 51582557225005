import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

import Tabs from 'components/Tabs';

export const StyledCatalogTabs = styled(Tabs)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .ant-tabs-top {
    > .ant-tabs-nav {
      margin: 0;
    }
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-nav-list {
    display: flex;
    overflow: hidden;
    background-color: #f2f3f7;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: unset;
    visibility: unset;
    background-color: #f2f3f7;
  }

  .ant-tabs-tab {
    position: relative;
    background-color: #f2f3f7;
    max-width: 289px;
    min-width: ${({ minTabWidth }) => minTabWidth}px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    flex: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 75px;
      height: 60px;
      background: linear-gradient(270deg, #f2f3f7 25%, transparent 75%);
      pointer-events: none;
    }

    &:nth-last-of-type(2) {
      &::after {
        content: '';
        position: absolute;
        top: 10px;
        right: 0;
        height: 68px;
        width: 100%;
        pointer-events: none;
        border-right: 1px solid #d9d9d9;
      }
    }

    &:hover {
      .ant-tabs-tab-remove {
        visibility: visible;
        z-index: 1;
      }
    }

    + .ant-tabs-tab {
      width: 289px;
      margin: 0px;

      &::after {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 68px;
        border-left: 1px solid #d9d9d9;
        pointer-events: none;
      }
    }

    &.ant-tabs-tab-active {
      width: 100%;
      border-radius: 20px 20px 0 0;
      background-color: #ffffff;
      border-color: #ffffff;
      margin: 0;
      padding: 0;
      cursor: default;
      min-width: 289px;

      &:first-of-type {
        min-width: 200px;
        width: initial;
      }

      &::before {
        display: none;
      }

      &::after {
        border-left-color: transparent;
      }

      + .ant-tabs-tab {
        border-left-color: transparent;
        margin-left: 0;

        &::after {
          border-left-color: transparent;
        }
      }

      &:nth-last-of-type(2) {
        &::after {
          border-right: transparent;
        }
      }

      .ant-tabs-tab-remove {
        top: 9px;
        right: 12px;
        padding: 4px;
        background-color: #fff;
      }
    }

    .anticon {
      margin-right: 0;
    }
  }

  .ant-tabs-tab-wrapper {
    max-width: 100%;
  }

  .ant-tabs-tab-heading {
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-tabs-tab-remove {
    position: absolute;
    margin: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    visibility: hidden;
    top: 9px;
    right: 12px;
    padding: 4px;

    @media ${MEDIA_DEVICE.laptop.to} {
      visibility: visible;
    }
  }

  .ant-tabs-content-holder {
    padding: 36px 40px;
    padding-bottom: 0;
    background-color: #ffffff;
    height: 100%;

    //min-width: 1216px; // Размер рабочей области каталога каналов

    @media ${MEDIA_DEVICE.laptopL.to} {
      padding: 24px;
      padding-bottom: 0;
    }
  }
`;
