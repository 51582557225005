import { checkIsDataExsist } from 'fsd/shared/lib/helpers/tools';
import { theme } from 'theme/theme';
import Skeleton from 'fsd/shared/ui/Skeleton';

import {
  Props as MentionsProps,
  useMentionsBlockLogic
} from 'pages/channel/components/MentionsBlock';
import { Props as ParticipantsBlockProps } from 'pages/channel/components/ParticipantsBlock';
import { useParticipantsBlockLogic } from 'pages/channel/components/ParticipantsBlock/hooks/useParticipantsBlockLogic';
import { Props as ViewsBlockProps, useViewsBlockLogic } from 'pages/channel/components/ViewsBlock';
import GraphBlockParticipiants from 'pages/channelMobile/components/GraphBlockParticipiants';
import GraphBlockViews from 'pages/channelMobile/components/GraphBlockViews';

import { useReachBlockLogic } from '../../../channel/components/ReachBlock';
import { GraphBlockMentions } from '../GraphBlockMentions';
import GraphBlockReaches from '../GraphBlockReaches';
import InfoBlock from '../InfoBlock/InfoBlock';

import * as S from '../../styles';

export const MobileParticipantsBlock = (props: ParticipantsBlockProps) => {
  const { compact } = props;
  const {
    isLoadingParticipants,
    isLoadingChannel,
    participantsStatistic,
    participantsAll,
    participantsToday,
    participantsYesterday,
    participantsWeek,
    participantsMonth,
    // handleDateClick,
    chartRef,
    graphData,
    handleZoomChange,
    handleChartClick
    // isEmptyDataOnZoomChange,
  } = useParticipantsBlockLogic(props);

  if (isLoadingParticipants || isLoadingChannel) {
    return (
      <>
        <div style={{ marginBottom: 13 }}>
          <Skeleton type={compact ? 'alt-mobile' : 'mobile'} id={1} />
        </div>
        {/* Skeleton for graph */}
        {compact && (
          <div style={{ marginBottom: 13 }}>
            <Skeleton type="graph" id={5} />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <S.Wrapper style={{ marginBottom: 13 }}>
        <InfoBlock
          title="participants"
          icon="participants"
          totalCount={participantsAll}
          er={false}
          today={participantsToday}
          yesterday={participantsYesterday}
          week={participantsWeek}
          month={participantsMonth}
          numbersColor={theme.americanGreen}
          minusColor={theme.hotspot}
          compact={compact}
          graphData={graphData}
          chartRef={chartRef}
          showTotalCount={!!(checkIsDataExsist(participantsStatistic) && participantsAll)}
        />
      </S.Wrapper>
      {compact && (
        <GraphBlockParticipiants
          chartRef={chartRef}
          graphData={graphData}
          handleChartClick={handleChartClick}
          handleZoomChange={handleZoomChange}
        />
      )}
    </div>
  );
};

export const MobileViewsBlock = (props: ViewsBlockProps) => {
  const { compact } = props;
  const {
    isLoadingViews,
    isLoadingParticipants,
    isLoadingChannel,
    viewsStatistic,
    viewsAll,
    viewsToday,
    viewsYesterday,
    viewsWeek,
    viewsMonth,
    erd,
    erm,
    // handleDateClick,
    isShowAll,
    isShowViews,
    toggleLine,
    chartRef,
    graphData,
    handleChartClick,
    // isEmptyDataOnZoomChange,
    handleZoomChange
  } = useViewsBlockLogic(props);

  if (isLoadingViews || isLoadingParticipants || isLoadingChannel) {
    return (
      <>
        <div style={{ marginBottom: 13 }}>
          <Skeleton type={compact ? 'alt-mobile' : 'mobile'} id={1} />
        </div>
        {/* Skeleton for graph */}
        {compact && (
          <div style={{ marginBottom: 13 }}>
            <Skeleton type="graph" id={5} />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <S.Wrapper style={{ marginBottom: 13 }}>
        <InfoBlock
          title="views"
          icon="views"
          totalCount={viewsAll}
          er={{
            erd,
            erm
          }}
          today={viewsToday}
          yesterday={viewsYesterday}
          week={viewsWeek}
          month={viewsMonth}
          numbersColor={theme.persianBlue}
          compact={props.compact}
          graphData={graphData}
          chartRef={chartRef}
          showTotalCount={!!(checkIsDataExsist(viewsStatistic) && viewsAll)}
        />
      </S.Wrapper>

      {props.compact && (
        <GraphBlockViews
          chartRef={chartRef}
          graphData={graphData}
          handleChartClick={handleChartClick}
          handleZoomChange={handleZoomChange}
          isShowAll={isShowAll}
          isShowViews={isShowViews}
          toggleLine={toggleLine}
        />
      )}
    </div>
  );
};

export const MobileReachesBlock = (props: ViewsBlockProps) => {
  const { compact } = props;
  const {
    isLoadingReach,
    isLoadingParticipants,
    isLoadingChannel,
    viewsAll,
    viewsYesterday,
    viewsWeek,
    viewsMonth,
    isShowAll,
    isShowViews,
    toggleLine,
    chartRef,
    graphData,
    handleChartClick,
    handleZoomChange,
    viewsStatistic,
    viewsIsHistoryEmtpy
  } = useReachBlockLogic(props);

  if (isLoadingReach || isLoadingParticipants || isLoadingChannel) {
    return (
      <>
        <div style={{ marginBottom: 13 }}>
          <Skeleton type={compact ? 'alt-mobile' : 'mobile'} id={1} />
        </div>
        {/* Skeleton for graph */}
        {compact && (
          <div style={{ marginBottom: 13 }}>
            <Skeleton type="graph" id={5} />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <S.Wrapper style={{ marginBottom: 13 }}>
        <InfoBlock
          hideToday
          title="reaches"
          icon="reaches"
          totalCount={viewsAll}
          er={false}
          yesterday={viewsYesterday}
          week={viewsWeek}
          month={viewsMonth}
          numbersColor={theme.persianBlue}
          compact={props.compact}
          graphData={graphData}
          chartRef={chartRef}
          showTotalCount={!!(checkIsDataExsist(viewsStatistic) && !viewsIsHistoryEmtpy)}
        />
      </S.Wrapper>

      {props.compact && (
        <GraphBlockReaches
          chartRef={chartRef}
          graphData={graphData}
          handleChartClick={handleChartClick}
          handleZoomChange={handleZoomChange}
          isShowAll={isShowAll}
          isShowViews={isShowViews}
          toggleLine={toggleLine}
        />
      )}
    </div>
  );
};

export const MobileMentionsBlock = (
  props: MentionsProps & {
    isCompact?: boolean;
    hasGraph?: boolean;
    title?: 'mentions' | 'detailedMentions';
  }
) => {
  const { hasGraph, isCompact, title } = props;
  const {
    isLoadingChannel,
    isLoadingMentions,
    mentionsStatistic,
    mentionsAll,
    mentionsToday,
    mentionsYesterday,
    mentionsWeek,
    mentionsMonth,
    // setIsPickerOpen,
    // isPickerOpen,
    // selectedDate,
    // setSelectedDate,
    graphData,
    chartRef,
    mentionsHistoryEmtpy
    // channel,
    // now,
  } = useMentionsBlockLogic(props);

  if (isLoadingMentions || isLoadingChannel) {
    return (
      <>
        <div style={{ marginBottom: 13 }}>
          <Skeleton type={isCompact ? 'alt-mobile' : 'mobile'} id={1} />
        </div>
        {/* Skeleton for graph */}
        {isCompact && (
          <div style={{ marginBottom: 13 }}>
            <Skeleton type="graph" id={5} />
          </div>
        )}
      </>
    );
  }

  graphData[0].barThickness = hasGraph ? 5 : 2;

  return (
    <>
      <div>
        <S.Wrapper style={{ marginBottom: 13 }}>
          <InfoBlock
            title={title || 'mentions'}
            icon="speaker"
            totalCount={mentionsAll}
            er={false}
            today={mentionsToday}
            yesterday={mentionsYesterday}
            week={mentionsWeek}
            month={mentionsMonth}
            compact={isCompact}
            numbersColor={theme.persianBlue}
            graphData={graphData}
            chartRef={chartRef}
            showTotalCount={!!(checkIsDataExsist(mentionsStatistic) && !mentionsHistoryEmtpy)}
          />
        </S.Wrapper>
      </div>
      {hasGraph && <GraphBlockMentions graphData={graphData} />}
    </>
  );
};
