import { FC } from 'react';
import { BalancePaymentSbpResponse } from 'api/api';
import QRCode from 'fsd/shared/ui/QRCode';
import styled from 'styled-components';

type Props = {
  data: BalancePaymentSbpResponse;
};

const Root = styled.div`
  position: relative;
  width: 255px;
  max-width: 100%;
`;

const Link = styled.a`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const QR: FC<Props> = ({ data }) => {
  return (
    <Root>
      <Link href={data.payload} target="_blank" />
      <QRCode value={data.imageUrl} />
    </Root>
  );
};

export default memo(QR);
