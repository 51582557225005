import { format } from 'date-fns';
import { dateFormat } from 'fsd/shared/lib/helpers/constants/dateFormats';
import { createNewMSKDate } from 'fsd/shared/lib/helpers/tools/dates';
import { Statistic } from 'types/stat';

type Type = 'sum' | 'avg';

export const getDaysValuesByStatistic = (statistic: Statistic[], type: Type) => {
  const todayDate = format(createNewMSKDate(), dateFormat);
  const yesterdayDate = format(
    createNewMSKDate().setDate(createNewMSKDate().getDate() - 1),
    dateFormat
  );

  // one day more without today
  const weekDate = createNewMSKDate().setDate(createNewMSKDate().getDate() - 8);
  const monthDate = createNewMSKDate().setDate(createNewMSKDate().getDate() - 30);

  const todayValue = statistic.find((item) => item.date === todayDate);
  const yesterdayValue = statistic.find((item) => item.date === yesterdayDate);

  const today = todayValue?.value || 0;
  const yesterday = yesterdayValue?.value || 0;
  const week = calcPeriodValue(statistic, weekDate, type);
  const month = calcPeriodValue(statistic, monthDate, type);

  let all = (statistic.length && statistic[statistic.length - 1].value) || 0;

  if (type === 'sum') {
    all = statistic.reduce((acc, item) => acc + item.value, 0);
  }

  return { today, yesterday, week, month, all };
};

const calcPeriodValue = (statistic: Statistic[], date: number, type: Type): number => {
  const period = statistic.filter((item) => {
    const itemDate = parseDate(item.date);
    const periodDate = new Date(date);
    return itemDate >= periodDate;
  });

  if (period.length && type === 'sum') {
    return period.reduce((acc, item) => acc + item.value, 0);
  }

  if (period.length && type === 'avg') {
    return period.reduce((acc, item) => acc + item.value, 0) / period.length;
  }

  return 0;
};

export function parseDate(dateString: string) {
  const parts = dateString.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-based
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
}
