import { FC, memo } from 'react';

import ErrValue from '../../../../Row/components/Err/ErrValue';
import { DefaultColumnCellProps } from '../type';

type Props = DefaultColumnCellProps;

const Value: FC<Props> = ({ channel }) => {
  return <ErrValue channelId={channel.info.id} />;
};

export default memo(Value);
