import useGetCartPurchases from './useGetCartPurchases';

export default () => {
  const { data: purchases } = useGetCartPurchases();

  const check = useCallback(
    (channelId: string) => {
      if (!purchases?.length) return undefined;
      return purchases?.find((purchase) => purchase.channel?.info?.id === channelId);
    },
    [purchases]
  );

  return [check, { purchases }] as const;
};
