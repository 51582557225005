import { Form } from 'antd';
import { FindChannelOrExternalService } from 'fsd/entities/channel';
import styled from 'styled-components';

export const Root = styled(Form)`
  display: flex;
  flex-direction: column;
  background: #fff;
  gap: 26px;
  padding: 10px;
`;

export const Input = styled(FindChannelOrExternalService)`
  width: 100%;
`;
