import * as LS from './styles';

type Props = {
  translateY: number;
  isSelected: boolean;
  date: string;
};

export const DateRow = ({ date, isSelected, translateY }: Props) => {
  return (
    <LS.DateRow $translateY={translateY}>
      <LS.Date $isSelected={isSelected}>{date}</LS.Date>
    </LS.DateRow>
  );
};
