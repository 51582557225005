import { ComponentProps, FC } from 'react';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';

import { CustomRow } from '../../../lib/createCustomRow';

import * as S from './styles';

type Props = ComponentProps<typeof S.Root> & { customRow: CustomRow<any> };

const LoaderRow: FC<Props> = ({ customRow, ...restProps }) => {
  return (
    <S.Root {...restProps}>
      {customRow.type === 'loader' && <LoaderSpinner fontSize={24} color={theme.violetPowder} />}
    </S.Root>
  );
};

export default memo(LoaderRow);
