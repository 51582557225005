import { ComponentProps, FC, PropsWithChildren, ReactNode } from 'react';

import Title from '../Title';

import * as S from './styles';

type Props = {
  title: ReactNode;
  titleSize?: 'medium';
  caption?: ReactNode;
  icon?: ComponentProps<typeof Title>['icon'];
};

const Header: FC<PropsWithChildren<Props>> = ({ title, caption, icon, children, titleSize }) => {
  return (
    <S.Root>
      <S.Info>
        {caption && <S.Caption>{caption}</S.Caption>}
        <Title size={titleSize} icon={icon}>
          {title}
        </Title>
      </S.Info>
      {children && <S.Body>{children}</S.Body>}
    </S.Root>
  );
};

export default memo(Header);
