import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { getOrientationType, getOrientationTypeByWindow } from './getOrientationType';

export default () => {
  const isNewFeature = typeof window.screen?.orientation !== 'undefined';
  const [type, setType] = useState(
    isNewFeature ? getOrientationType() : getOrientationTypeByWindow()
  );

  const handleOrientationChange = useDebouncedCallback(
    useCallback(
      () => setType(isNewFeature ? getOrientationType() : getOrientationTypeByWindow()),
      [isNewFeature]
    ),
    isNewFeature ? 0 : 50
  );

  useEffect(() => {
    if (isNewFeature) {
      window.screen?.orientation.addEventListener('change', handleOrientationChange);
      return () =>
        window.screen?.orientation.removeEventListener('change', handleOrientationChange);
    }

    window.addEventListener('resize', handleOrientationChange);
    return () => window.removeEventListener('resize', handleOrientationChange);
  });

  return type;
};
