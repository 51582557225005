import { withServiceWorkerUpdater } from '@3m1/service-worker-updater';
// eslint-disable-next-line import/no-extraneous-dependencies
import compose from 'compose-function';

import { withAnalytics } from './Analytics';
import { withAuth } from './Auth';
import { withDate } from './Date';
import { withErrorBoundary } from './errorBoundary';
import { withOrientationLocker } from './OrientationLocker';
import { withSentryUsers } from './sentryUsers';
import { withBrowserRouter } from './with-browser-router';
import { withCart } from './with-cart';
import { withStore } from './with-store';
import { withTheme } from './with-theme';

export const withProviders = compose(
  withErrorBoundary,
  withStore,
  withBrowserRouter,
  withAuth,
  withAnalytics,
  withSentryUsers,
  withDate,
  withCart,
  withTheme,
  withServiceWorkerUpdater,
  withOrientationLocker
);
