import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.white};
  padding: 26px 20px 24px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
`;

export const CloseWrapper = styled.button`
  margin-left: auto;
`;

export const Nav = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Body = styled.div`
  flex: 1;
  margin-top: 50px;
`;

export const Footer = styled.div`
  flex: none;
  margin-top: auto;
`;
