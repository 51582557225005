import { PropsWithChildren } from 'react';

import BodyPlaceholder from '../BodyPlaceholder';

import * as S from './styles';

type Props = {
  placeholder?: boolean;
};

const Body = forwardRef<HTMLTableSectionElement, PropsWithChildren<Props>>(
  ({ children, placeholder, ...restProps }, outerRef) => {
    return (
      <S.Root {...restProps} ref={outerRef}>
        {placeholder ? <BodyPlaceholder /> : children}
      </S.Root>
    );
  }
);

export const VirtualBody = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    ({ children, placeholder, ...restProps }, outerRef) => {
      return (
        <S.RootVirtual {...restProps} ref={outerRef}>
          {placeholder ? <BodyPlaceholder /> : children}
        </S.RootVirtual>
      );
    }
  )
);

export default memo(Body);
