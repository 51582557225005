import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

import { theme } from 'theme/theme';

const StyledLine = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: ${theme.bayFog};

  @media ${MEDIA_DEVICE.laptopL.from} {
    line-height: 24px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const EmptyLine = () => {
  return <StyledLine>—</StyledLine>;
};
