import { FC, PropsWithChildren, ReactNode } from 'react';

import * as S from './styles';

type Props = {
  title?: ReactNode;
  labels?: {
    title?: string;
    value: ReactNode;
  }[];
};

const Content: FC<PropsWithChildren<Props>> = ({ children, title, labels }) => {
  return (
    <S.Root>
      <S.Title>{title}</S.Title>
      {labels?.length && (
        <S.LabelWrapper>
          {labels.map((label) => (
            <S.Label key={label.title}>
              {label.title && <S.LabelTitle>{label.title}</S.LabelTitle>}
              <S.LabelValue>{label.value}</S.LabelValue>
            </S.Label>
          ))}
        </S.LabelWrapper>
      )}
      <S.Slot>{children}</S.Slot>
    </S.Root>
  );
};

export default memo(Content);
