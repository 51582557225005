import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
  max-width: 1600px;
  @media ${MEDIA_DEVICE.laptopS.to} {
    grid-template-columns: 1fr;
  }
`;
