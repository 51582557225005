import { FC } from 'react';
import { Divider, DividerProps } from 'antd';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px;
  color: ${({ theme }) => theme.sweetFlower} !important;
`;

export const DividerUI: FC<DividerProps> = (props) => <StyledDivider {...props} />;

export default DividerUI;
