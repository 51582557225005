import React, { ComponentType, createContext, ReactNode, useContext, useState } from 'react';
import { createNow } from 'fsd/shared/lib/helpers/tools/dates';

import { AuthProvider } from './Auth';

interface DateContextProps {
  children: ReactNode;
}

interface DateContextValue {
  now: string;
}

export const DateContext = createContext<DateContextValue | undefined>(undefined);

export const DateProvider: React.FC<DateContextProps> = ({ children }) => {
  const [now] = useState(createNow());

  return <DateContext.Provider value={{ now: now }}>{children}</DateContext.Provider>;
};

export const useDate = (): DateContextValue => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useDate must be used within an DateProvider');
  }
  return context;
};

export const withDate = (Component: ComponentType<{}>) => () => {
  return (
    <DateProvider>
      <Component />
    </DateProvider>
  );
};
