import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

import { theme } from 'theme/theme';

export const LoadersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
  min-width: 0;
`;

export const TableRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Row = styled.div`
  display: block;
  background-color: ${theme.white};
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  padding: 24px 32px;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  max-width: 1680px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  box-shadow:
    0px -4px 64px 0px rgba(0, 0, 0, 0.07),
    0px 100px 80px 0px rgba(179, 175, 214, 0.07);

  @media ${MEDIA_DEVICE.laptopS.to} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'info formats' 'contacts language';
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    grid-template-areas: 'info info' 'formats formats' 'contacts language';
    padding: 20px;
  }
  @media ${MEDIA_DEVICE.mobileS.to} {
    grid-template-columns: 1fr;
    grid-template-areas: 'info' 'formats' 'contacts' 'language';
  }
`;

export const ChannelIconWrap = styled.div`
  user-select: none;
  display: inline-block;
  vertical-align: top;

  > * {
    width: 100%;
  }

  width: 24px;
  height: 24px;
  min-width: 24px;

  margin-top: 0px;
  margin-right: 8px;
`;

export const ChannelFakeIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.black};
  margin-right: 8px;
`;

export const ChannelIcon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const ChannelInfo = styled.div`
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  text-overflow: ellipsis;
  max-width: 190px;
  @media ${MEDIA_DEVICE.laptopS.to} {
    max-width: none;
  }
`;
export const ChannelTitle = styled.span`
  color: ${theme.blackRock};
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
`;

export const SwitchWrapper = styled.div<{ disabled?: boolean }>`
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ disabled }) => (disabled ? theme.violetPowder : 'inherit')};
`;

export const SwitchLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
`;

export const ActiveSwitchInfoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;
`;

export const ActiveSwitchInfoTitleText = styled.span`
  flex: 1;
  margin-right: 36px;
  white-space: normal;
`;

export const ActiveSwitchInfoTitleButton = styled(Button)`
  flex: none;
  span {
    font-weight: 600;
  }
`;

export const FormatTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.blackRock};
  margin-bottom: 16px;
`;

export const FormatCheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormatWrapper = styled.div`
  grid-area: formats;
  margin-bottom: 8px;
  min-width: 163px;
  flex: none;
  @media ${MEDIA_DEVICE.laptopS.to} {
    min-width: 0;
  }
`;

export const InfoWrapper = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: none;
`;

export const ContactWrapper = styled.div`
  grid-area: contacts;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  min-width: 250px;
  @media ${MEDIA_DEVICE.laptop.from} {
    max-width: 280px;
  }
  @media ${MEDIA_DEVICE.laptopS.to} {
    min-width: 0;
  }
`;

export const LanguageWrapper = styled.div`
  grid-area: language;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  min-width: 200px;
  @media ${MEDIA_DEVICE.laptop.from} {
    max-width: 220px;
  }
  @media ${MEDIA_DEVICE.laptopS.to} {
    min-width: 0;
  }
`;

export const ContactLabel = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.blackRock};
  margin-bottom: 8px;
`;

export const SendButtonWrapper = styled.div`
  @media ${MEDIA_DEVICE.laptopS.to} {
    width: 100%;
  }
`;
