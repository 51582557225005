import { FC, ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormApi } from 'final-form';
import { flattenObj } from 'fsd/shared/lib/helpers/tools';
import Popover from 'fsd/shared/ui/LegacyUIComponents/Popover';
import { SortType } from 'types/channels/types';

import { FormValues } from 'pages/catalog/types';

import * as S from '../../../../styles';

type Props = {
  condition?: boolean;
  icon: ReactElement;
  name: SortType;
  text: string;
  tooltipText?: string | ReactElement;
  form: FormApi<FormValues, Partial<FormValues>>;
  values: Partial<FormValues>;
  className?: string;
  sortable?: boolean;
};

const SortableTitle: FC<Props> = ({
  condition = true,
  icon,
  name,
  text,
  tooltipText,
  form,
  values,
  className,
  sortable = true
}) => {
  if (!condition) {
    return null;
  }

  const { sort } = values;
  const sortedDESC = sort?.order === 'DESC';
  const isActive = sort?.field === name;

  const [, setSearchParams] = useSearchParams();

  const onClick = () => {
    if (!sortable) {
      return;
    }
    const sort = {
      field: name,
      order: isActive ? (sortedDESC ? 'ASC' : 'DESC') : 'DESC'
    } as {
      field: SortType;
      order: 'ASC' | 'DESC';
    };

    form?.change('pagination', {
      offset: 0,
      limit: 20
    });
    form?.submit();

    form.change('sort', sort);
    form.submit();

    setSearchParams(
      flattenObj({
        ...values,
        sort
      })
    );
  };

  return (
    <S.Cell className={className}>
      <Popover placement="top" content={tooltipText}>
        <S.SortButton onClick={onClick}>
          <S.ThIcon>{icon}</S.ThIcon>
          {text}
          {sortable && (
            <S.StyledArrowDown $sortedDesc={isActive ? sortedDESC : true} $active={isActive} />
          )}
        </S.SortButton>
      </Popover>
    </S.Cell>
  );
};

export default memo(SortableTitle);
