import React, { FC, useRef, useState } from 'react';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { Button } from 'fsd/shared/ui/Buttons';
import { Collapse } from 'fsd/shared/ui/LegacyUIComponents';

import { FormatsType } from 'pages/catalog/components/Table/components/Row/components/Price/Action';

import { FormRef } from '../../index';
import FormAddPurchaseToExistContainer from '../FormAddPurchaseToExistContainer';
import FormNewContainer from '../FormNewContainer';

import * as S from '../../styles';

const { Panel } = Collapse;

type Props = {
  channelId: string[];
  setIsDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  format: FormatsType[number];
  showPanel1?: boolean;
};

const SingleContextMenu: FC<Props> = ({
  channelId,
  setIsDropdownVisible,
  setIsOpen,
  format,
  showPanel1 = true
}) => {
  const isMobile = useIsMobile();
  const formAddRef = useRef<FormRef>(null);
  const formNewRef = useRef<FormRef>(null);
  const [openPanels, setOpenPanels] = useState({
    panel1: false,
    panel2: !showPanel1
  });
  const [form1IsValid, setForm1IsValid] = useState(false);
  const [form2IsValid, setForm2IsValid] = useState(false);
  const handleSubmit = () => {
    formAddRef.current?.handleSubmit();
    formNewRef.current?.handleSubmit();
  };

  const [activeKey, setActiveKey] = useState(showPanel1 ? '' : '2');

  const handleChange = (keys: string[] | string) => {
    const key = keys[keys.length - 1];
    setActiveKey(key);
    setOpenPanels({
      panel1: key === '1',
      panel2: key === '2'
    });
  };

  return (
    <Collapse
      onChange={handleChange}
      style={{ maxWidth: isMobile ? 'none' : 410 }}
      activeKey={activeKey}
    >
      {showPanel1 && (
        <Panel
          header={
            <S.PanelWrapper>
              <S.PanelHeader>Добавить в существующую кампанию</S.PanelHeader>
            </S.PanelWrapper>
          }
          key="1"
        >
          <FormAddPurchaseToExistContainer
            ref={formAddRef}
            setIsDropdownVisible={setIsDropdownVisible}
            channelId={channelId}
            format={format}
            setIsPopoverVisible={setIsOpen}
            setFormIsValid={setForm1IsValid}
          />
        </Panel>
      )}
      <Panel header={<S.PanelHeader>Добавить в новую кампанию</S.PanelHeader>} key="2">
        <FormNewContainer
          ref={formNewRef}
          channelId={channelId}
          format={format}
          setFormIsValid={setForm2IsValid}
        />
      </Panel>

      {(openPanels.panel1 || openPanels.panel2) && (
        <div style={{ padding: '8px 18px 24px' }}>
          {openPanels.panel1 && (
            <Button
              type="primary"
              size="middle"
              block
              htmlType="submit"
              onClick={handleSubmit}
              disabled={
                !(openPanels.panel1 && form1IsValid) && !(openPanels.panel2 && form2IsValid)
              }
            >
              Добавить
            </Button>
          )}
        </div>
      )}
    </Collapse>
  );
};

export default memo(SingleContextMenu);
