import { FC, ReactNode } from 'react';
import { ExportValue } from 'fsd/entities/container/types/export';
import { IconExcel, IconGoogleSheets } from 'fsd/shared/icons';

import * as S from './styles';

type Props = {
  value?: ExportValue;
  onSelect?: (value: ExportValue) => void;
};

const items: { value: ExportValue; label: ReactNode; icon: ReactNode }[] = [
  {
    value: 'excel',
    label: '.xksx',
    icon: <IconExcel />
  },
  {
    value: 'google-sheets',
    label: 'Таблицы',
    icon: <IconGoogleSheets />
  }
];

const Types: FC<Props> = ({ value, onSelect }) => {
  const handleClick = useCallback(
    (value: ExportValue) => {
      return () => onSelect?.(value);
    },
    [onSelect]
  );
  return (
    <S.Root>
      {items.map((item) => (
        <S.Item
          onClick={handleClick(item.value)}
          key={item.value}
          startIcon={item.icon}
          type={item.value === value ? 'primary' : 'outlined'}
        >
          {item.label}
        </S.Item>
      ))}
    </S.Root>
  );
};

export default memo(Types);
