import { ComponentProps } from 'react';
import { useGetUserBalance } from 'fsd/entities/balance';
import { RechargeBalance } from 'fsd/features/rechargeBalance';
import { WithdrawalBalance } from 'fsd/features/withdrawalBalance';

import Card from './Card';

import * as S from './List.styles';

const List = () => {
  const balance = useGetUserBalance();

  const firstItems: ComponentProps<typeof Card>['items'] = [
    {
      key: useId(),
      value: balance.active,
      type: 'active'
    },
    {
      key: useId(),
      value: balance.blocked,
      type: 'blocked'
    }
  ];

  const secondItems: ComponentProps<typeof Card>['items'] = [
    {
      key: useId(),
      value: balance.profit,
      type: 'profit'
    },
    {
      key: useId(),
      value: balance.withdrawal,
      type: 'withdrawal'
    }
  ];

  return (
    <S.Root>
      <Card items={firstItems} button={<RechargeBalance />} />
      <Card items={secondItems} button={<WithdrawalBalance />} />
    </S.Root>
  );
};

export default List;
