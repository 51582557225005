import { usePostV1ExchangePurchaseByPurchaseIdCreativeSendMutation } from 'api/api';
import { URLs } from 'fsd/shared/config/URLs';

export default () => {
  const [sendCreative, { isLoading }] = usePostV1ExchangePurchaseByPurchaseIdCreativeSendMutation();

  const send = useCallback(
    (purchaseId: string) => {
      sendCreative({
        purchaseId
      }).then(() => {
        window.open(URLs.TELESPACE_ASSISTANT_BOT, '_blank');
      });
    },
    [sendCreative]
  );

  const isDisabled = isLoading;

  return { send, isDisabled };
};
