import { ComponentProps, FC } from 'react';
import {
  api,
  ChannelWithExchangeInfo,
  useGetV1CategoriesQuery,
  usePatchV1UserChannelMutation
} from 'api/api';
import { IconArrowDown } from 'fsd/shared/icons';
import { Select } from 'fsd/shared/ui/Select';

import * as S from '../styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

const CategorySelect: FC<IProps> = ({ channel }) => {
  const dispatch = useAppDispatch();
  const { data, isFetching: isDataFetching } = useGetV1CategoriesQuery();
  const [updateChannel, { isLoading: isUpdateFetching }] = usePatchV1UserChannelMutation();

  const options: ComponentProps<typeof Select>['options'] = useMemo(() => {
    return data?.map((category) => {
      return {
        value: category.id,
        label: category.code_name
      };
    });
  }, [data]);

  const handleChange = useCallback(
    (value: number) => {
      const { undo } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const dataIndex = data.findIndex((d) => d.info.id === channel.info.id);
          data[dataIndex].info.categories = [value];
        })
      );
      updateChannel({
        body: {
          channel_id: channel.info.id,
          properties: {
            category_id: value
          }
        }
      })
        .unwrap()
        .catch(() => undo());
    },
    [dispatch, updateChannel, channel.info.id]
  );

  return (
    <S.RowExpandedBlock>
      <S.ExpandedBlockHeader>
        <span>Тематика</span>
      </S.ExpandedBlockHeader>
      <div>
        <Select
          optionFilterProp="label"
          suffixIcon={<IconArrowDown />}
          options={options}
          placeholder="Не выбрано"
          value={channel.info.categories?.[0]}
          disabled={isUpdateFetching}
          loading={isDataFetching}
          showSearch
          onChange={handleChange}
        />
      </div>
    </S.RowExpandedBlock>
  );
};

export default CategorySelect;
