import { FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';

import ParticipantsChart from '../../../../Row/components/Participants/ParticipantsChart';
import useParticipantsMetrics from '../../../lib/useParticipantsMetrics';

type Props = {
  channel: ChannelWithExchangeInfo;
};

const Chart: FC<Props> = ({ channel }) => {
  const { tableData, ...participants } = useParticipantsMetrics(channel);
  return (
    tableData && (
      <ParticipantsChart
        graphData={participants.participantsGraphData}
        participants={participants.participants}
      />
    )
  );
};
export default memo(Chart);
