import { theme } from 'theme/theme';

import { RootPaper } from './index';

export const DefaultSkeleton = () => {
  return (
    <RootPaper>
      <svg preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" clipPath="url(#a4)" style={{ fill: 'url(#b4)' }} />
        <defs>
          <linearGradient id="b4">
            <stop stopColor={theme.alpineGoat}>
              <animate
                attributeName="offset"
                values="-1.5; -1.5; 1.5"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop stopColor="hsl(228deg 23.81% 95.88% / 35%)">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop stopColor={theme.alpineGoat}>
              <animate
                attributeName="offset"
                values="-0.5; -0.5; 2.5"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
          <clipPath id="a4">
            <rect width="23.2%" height="2.4%" x="3.3%" y="3.5%" fill={theme.alpineGoat} rx="4" />
            <rect width="2.6%" height="2.4%" x="28.7%" y="3.5%" fill={theme.alpineGoat} rx="4" />
            <rect width="19.7%" height="2.3%" x="77.0%" y="3.5%" fill={theme.alpineGoat} rx="4" />
            <rect width="93.4%" height="4.1%" x="3.3%" y="7.6%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.1%" x="3.3%" y="12.3%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.1%" x="3.3%" y="16.9%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.1%" x="3.3%" y="21.5%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="30.4%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="35.2%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="39.9%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="44.7%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="49.5%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="54.2%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="59.0%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="63.8%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="68.5%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="73.3%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="78.0%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="82.8%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="87.6%" fill={theme.alpineGoat} rx="6" />
            <rect width="93.4%" height="4.2%" x="3.3%" y="92.3%" fill={theme.alpineGoat} rx="6" />
            <rect width="8.1%" height="2.2%" x="3.3%" y="26.9%" fill={theme.alpineGoat} rx="4" />
          </clipPath>
        </defs>
      </svg>
    </RootPaper>
  );
};
