import { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import styled, { css } from 'styled-components';

const underlinedCss = css`
  // little rendering fix
  margin-right: -1px;

  .ant-tabs-ink-bar {
    height: 5px !important;
    border-radius: 5px 5px 0px 0px !important;
    color: ${({ theme }) => theme.persianBlue} !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    padding: 12px 0 18px;

    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.blackRock};
    }

    &:not(.ant-tabs-tab-active) {
      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.bayFog};
      }
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const StyledTabs = styled(Tabs)<Props>`
  ${({ underlined }) => underlined && underlinedCss}
`;

type Props = TabsProps & {
  underlined?: number;
};

export const TabsUI: FC<Props> = ({ underlined, ...props }) => (
  <StyledTabs underlined={underlined} moreIcon={null} {...props} />
);

export default TabsUI;
