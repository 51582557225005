import { FC } from 'react';
import { IconBeta, IconLogoWithText } from 'fsd/shared/icons';

import * as S from './WithText.styles';

type Props = {
  width?: number;
};

const WithText: FC<Props> = ({ width = 116 }) => {
  return (
    <S.Root>
      <IconLogoWithText width={width} height="auto" />
      <IconBeta width={24} />
    </S.Root>
  );
};

export default memo(WithText);
