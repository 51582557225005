/* eslint-disable @typescript-eslint/no-explicit-any */
import { api as generatedAPI } from 'api/api';

type generatedAPIType = typeof generatedAPI;

export const generateInvalidateTagsByAPI = (generatedAPI: generatedAPIType) => {
  // find all endpoints with a mutation type
  const endpointsWithMutation = Object.values(generatedAPI.endpoints).filter(
    (endpoint: any) => endpoint?.useMutation
  );
  // find all endpoints with a query type
  const getEndpoints = Object.values(generatedAPI.endpoints).filter(
    (endpoint: any) => endpoint?.useQuery
  );

  // contain all tags that are used by a mutation
  const mutationTags = new Set<string>();

  // add tags to the mutationTags set
  endpointsWithMutation.forEach((endpoint: any) => {
    const { tag: tagName } = getTagFromName(endpoint.name);

    mutationTags.add(tagName);
    endpoint.invalidatesTags = [tagName];
  });

  // add providedTags to the query endpoints
  getEndpoints.forEach((endpoint: any) => {
    const { tag: tagName } = getTagFromName(endpoint.name);

    if (mutationTags.has(tagName)) {
      endpoint.providesTags = [tagName];
    }
  });

  function getTagFromName(str: string) {
    const words = str.split(/(?=[A-Z])/);

    // handle the case where the word is "Purchase" or "Purchases"
    if (words[2] === 'Purchase') {
      words[2] = 'Purchases';
    }

    const nextWords = words.slice(1, 3);
    return {
      tag: nextWords.join('')
    };
  }

  return {
    tagTypes: [...mutationTags],
    endpoints: generatedAPI.endpoints
  };
};
