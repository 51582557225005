import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Direction } from 'api/api';
import { Chart } from 'chart.js';
import { format } from 'date-fns';
import {
  parseISOWithoutTimeZone,
  toISOStringWithMSKTime
} from 'fsd/shared/lib/helpers/tools/dates';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

export const useTableScroll = (
  values: any[],
  isRepost: boolean,
  chartRef: RefObject<Chart>,
  isLoading: boolean,
  hasMoreUp: boolean,
  hasMoreDown: boolean,
  channelId: string,
  loadMore: (direction: Direction) => void
) => {
  const sortedValues = useMemo(() => {
    const withIsRepostFilter = !isRepost
      ? values.filter(
          (item) => item.type !== 'repost' && item.type !== 'mention' && item.type !== 'regular'
        )
      : values.filter(
          // не отображаем "Вышел пост"
          (item) => item.type !== 'regular'
        );
    return withIsRepostFilter;
  }, [values, isRepost]);

  const parentRef = React.useRef<HTMLDivElement>(null);

  const count = sortedValues.length;
  const isMobile = useIsMobile();
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => (isMobile ? 28 : 36), []),
    overscan: 5
  });

  const virtualItems = virtualizer.getVirtualItems();
  const [dataFirst, setDataFirst] = useState<any>();
  const [isUpScan, setIsUpScan] = useState(true);

  const [indexColorDate, setIndexColorDate] = useState<number | null>(0);
  const [isHoverTable, setIsHoverTable] = useState(false);

  // LoadMore hook
  useEffect(() => {
    const firstItem = virtualizer.getVirtualItems()[0];
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem || !firstItem) {
      return;
    }

    if (firstItem.index === 0 && !isLoading && hasMoreUp && isUpScan) {
      setDataFirst(sortedValues[firstItem.index]);
      setIsUpScan(false);
      loadMore('up');
    }

    if (lastItem.index >= count - 1 && hasMoreDown && !isLoading) {
      loadMore('down');
    }
  }, [virtualizer.getVirtualItems()]);

  // Tooltip in graphics show
  useEffect(() => {
    const virtualItems = virtualizer.getVirtualItems();

    const firstIndex = virtualItems.length > 24 ? 24 : virtualItems.length;
    const firstItem = virtualItems[virtualItems.length - firstIndex];
    if (!firstItem) return;
    if (sortedValues[firstItem.index].dateNew) return;

    const dateFirstItem = format(
      parseISOWithoutTimeZone(sortedValues[firstItem.index].date),
      'd.MM.yyyy'
    );

    if (chartRef?.current) {
      const { current } = chartRef;

      // Задаем точку которая указывает в никуда, потому что мы на навели на график
      if (!isHoverTable && !isMobile) {
        current.setActiveElements([]);

        current.tooltip?.setActiveElements([], { x: -1, y: -1 });
        current.update();
        return;
      }

      if (current?.isZoomedOrPanned && current.isZoomedOrPanned() && isUpScan) {
        const min = new Date(current.scales.x.min);
        const max = new Date(current.scales.x.max);
        const dateFirstItemDate = new Date(sortedValues[firstItem.index].date);

        if (min > dateFirstItemDate || dateFirstItemDate > max) current.resetZoom();
      }

      if (current.data.datasets.length > 0) {
        const dataShowItemIndex = current.data.datasets[0].data.findIndex((item) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (item.x) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (
              format(
                parseISOWithoutTimeZone(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  toISOStringWithMSKTime(new Date(item.x))
                ),
                'd.MM.yyyy'
              ) == dateFirstItem
            );
          }
        });

        if (dataShowItemIndex < 0) {
          current.setActiveElements([]);

          current.tooltip?.setActiveElements([], { x: -1, y: -1 });
          current.update();
          return;
        }
        const dataShowItem = current.data.datasets[0].data[dataShowItemIndex];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const xCoordOrDate = dataShowItem.x;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const yCoordOrDate = dataShowItem.y;

        // Задаем новую точку тултипа, в зависимости от даты

        current.setActiveElements([{ datasetIndex: 0, index: dataShowItemIndex }]);

        current.tooltip?.setActiveElements([{ datasetIndex: 0, index: dataShowItemIndex }], {
          x: xCoordOrDate,
          y: yCoordOrDate
        });
      }

      current.update();
    }
  }, [virtualizer.getVirtualItems(), isHoverTable]);

  useEffect(() => {
    if (dataFirst === undefined) {
      return;
    }

    // эта штука завтра после обсуждения, надеюсь будет работать более корректно
    const newFirstItem = sortedValues.findIndex((item) => item.date === dataFirst.date);
    if (newFirstItem === undefined) {
      return;
    }

    virtualizer.scrollToIndex(newFirstItem - 2, { align: 'start' });

    setIndexColorDate(newFirstItem - 1);
    setTimeout(() => {
      setIndexColorDate(0);
    }, 3000);

    setDataFirst(undefined);
    setIsUpScan(true);
  }, [sortedValues]);

  return {
    parentRef,
    virtualizer,
    sortedValues,
    virtualItems,
    count,
    setIsHoverTable,
    indexColorDate
  };
};
