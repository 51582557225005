import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconSettings } from 'fsd/shared/icons';
import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import styled from 'styled-components';

import Layout from '../../Layout';

export const Paper = styled(Layout.Content)`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px 10px;

  flex-direction: column;

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 22px 10px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;
`;

export const StyledIconSettings = styled(IconSettings)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.persianBlue};
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: 0.113px;
`;

export const LanguageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const LanguageTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`;

export const LanguageSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const LanguageSelectLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  .ant-select-dropdown-menu {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const ErrorTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.maWhite};
`;
