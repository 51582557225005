import { useGetV1UserQuery } from 'api/api';
import { FinalizeReg } from 'fsd/features/finalizeReg';
import { LogoWithText } from 'fsd/shared/ui/Logo';

import * as S from './styles';

const Welcome = () => {
  const { data: user } = useGetV1UserQuery();
  const [open, setOpen] = useState(Boolean(user && !user.role));

  const handleSuccess = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    setOpen(Boolean(user && !user.role));
  }, [user]);
  return (
    <S.Root
      open={open}
      closable={false}
      maskClosable={false}
      background={theme.white}
      centered
      width={570}
      paddingSize="l"
    >
      <S.Content>
        <LogoWithText />
        <S.Title>Добро пожаловать в Tgmarket!</S.Title>
        <S.Body>
          <FinalizeReg onSuccess={handleSuccess} />
        </S.Body>
      </S.Content>
    </S.Root>
  );
};

export default memo(Welcome);
