import { IconCrossLine } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';

import Modal from '../Modal';

const CloseCartButton = () => {
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Modal onClose={handleClose} open={open} />
      <Button
        type="ghost"
        square
        size="small"
        startIcon={<IconCrossLine color={theme.bayFog} />}
        onClick={handleClick}
      />
    </>
  );
};

export default memo(CloseCartButton);
