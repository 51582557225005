import { FC } from 'react';
import { Switch } from 'antd';
import {
  IconCalendar,
  IconForward,
  IconMegafon,
  IconPositiveGrowth,
  IconViews
} from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import { theme } from 'theme/theme';

import { HandlersProps, SortingsProps } from '../../hooks';
import SortHeader from '../SortHeader';

import * as S from '../PostTab/styles';

type HeadersProps = {
  sortings: SortingsProps;
  handlers: HandlersProps;
};

export const MentionsTabHeader = ({ sortings, handlers }: HeadersProps) => {
  return (
    <S.FiltersWrapper>
      <S.Filters>
        <SortHeader
          iconSettings={{
            icon: <IconCalendar />
          }}
          sorted={sortings.date}
          handleClick={handlers.date}
        />
        <SortHeader
          iconSettings={{
            icon: <IconViews />
          }}
          sorted={sortings.views}
          handleClick={handlers.views}
        />
        <SortHeader
          iconSettings={{
            icon: <IconPositiveGrowth />,
            iconColor: theme.americanGreen
          }}
          sorted={sortings.growth}
          handleClick={handlers.growth}
        />
      </S.Filters>
    </S.FiltersWrapper>
  );
};

export const PostTabHeader = ({ sortings, handlers }: HeadersProps) => {
  return (
    <S.Filters>
      <SortHeader
        iconSettings={{
          icon: <IconCalendar />
        }}
        sorted={sortings.date}
        handleClick={handlers.date}
      />
      <SortHeader
        iconSettings={{
          icon: <IconViews />
        }}
        sorted={sortings.views}
        handleClick={handlers.views}
      />
      <SortHeader
        iconSettings={{
          icon: <IconForward />
        }}
        sorted={sortings.forwards}
        handleClick={handlers.forwards}
      />

      <S.FilterSwitch>
        <Icon width={16} color={theme.persianBlue}>
          <IconMegafon />
        </Icon>
        <S.FilterText>Только рекламные</S.FilterText>
        <Switch checked={sortings.onlyAds} onChange={handlers.ads} />
      </S.FilterSwitch>
    </S.Filters>
  );
};
