import { useNavigate, useParams } from 'react-router-dom';

import { useChannelPageLogic } from '../channel/ChannelPage';
import { MobileMentionsBlock } from '../channelMobile/components/DataBlocks';
import { Header } from '../channelMobile/components/Header';

import * as S from '../channelMobile/styles';

const ViewsMobile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const channelId = String(params.id);
  const { channel, isLoadingChannel, nowTimestamp } = useChannelPageLogic(channelId);

  const handleCancel = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }, [navigate]);

  return (
    <S.ChannelMobileModal
      open
      mask={false}
      maskClosable={false}
      onCancel={handleCancel}
      closable
      className="channel-modal hide-overflow"
    >
      <S.ChannelMobileModalBody>
        <S.CloseModalIcon onClick={handleCancel} />
        <Header channel={channel?.info} channelId={channelId} navigate={navigate} />

        <MobileMentionsBlock
          channel={channel?.info}
          isLoadingChannel={isLoadingChannel}
          channelId={channelId}
          now={nowTimestamp}
          isCompact
          hasGraph
          title="detailedMentions"
        />
      </S.ChannelMobileModalBody>
    </S.ChannelMobileModal>
  );
};

export default memo(ViewsMobile);
