import { ComponentProps, FC } from 'react';
import { IconVerified } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

type Props = Omit<ComponentProps<typeof Icon>, 'width' | 'color'> & { size?: number };

const ChannelVerified: FC<Props> = ({ size = 14, ...restProps }) => {
  return (
    <Icon width={size} {...restProps}>
      <IconVerified />
    </Icon>
  );
};

export default memo(ChannelVerified);
