import { User } from 'api/api';

export default (user: User, type?: 'crm') => {
  switch (type) {
    case 'crm':
      return user?.organisations?.[0]?.organisation_crm_id;
    default:
      return user?.organisations?.[0]?.organisation_id;
  }
};
