/* eslint-disable no-unused-vars */
import { ComponentProps, FC } from 'react';
import {
  BalanceInvoiceInfo,
  BalanceRecharge,
  useCreateBalanceRecharge
} from 'fsd/entities/balance';
import { RechargeTarget } from 'fsd/entities/balance/types';
import SBPInvoiceInfo from 'fsd/entities/balance/ui/SBPInvoiceInfo';
import { useGetUserOrganisation } from 'fsd/entities/user';
import { IconLineArrowLeft } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';

type Props = {
  onSuccess?: () => void;
};

type FormProps = ComponentProps<typeof BalanceRecharge.Form>;

const Form: FC<Props> = ({ onSuccess }) => {
  const { data: userOrganisation } = useGetUserOrganisation();
  const [step, setStep] = useState<'createInvoice' | 'invoiceCreated'>('createInvoice');
  const [target, setTarget] = useState<RechargeTarget>('invoice');
  const [createRecharge, { invoiceData, SBPData }] = useCreateBalanceRecharge();

  const handleFinish = useCallback<FormProps['onFinish']>(
    (data) => {
      setTarget(data.target as RechargeTarget);
      if (userOrganisation?.data?.attributes) {
        createRecharge({
          target: data.target as RechargeTarget,
          amount: data.amount,
          organisation: userOrganisation.data.attributes
        }).then(() => {
          setStep('invoiceCreated');
        });
      }
    },
    [createRecharge, userOrganisation?.data?.attributes]
  );
  const presets: FormProps['presets'] = [50000, 200000].map((val) => ({
    label: `${val.toLocaleString('ru')} ₽`,
    value: val.toString()
  }));

  const Info = useCallback(() => {
    if (target === 'invoice') {
      return <BalanceInvoiceInfo invoice={invoiceData} />;
    }
    if (target === 'sbp' && SBPData.data) {
      return <SBPInvoiceInfo data={SBPData.data} />;
    }
    return null;
  }, [SBPData.data, invoiceData, target]);

  const back = useCallback(() => {
    setStep('createInvoice');
  }, []);

  // eslint-disable-next-line no-nested-ternary
  return step === 'createInvoice' ? (
    <BalanceRecharge.Form
      onFinish={handleFinish}
      button="Сгенерировать счет на оплату"
      loading={invoiceData.isLoading || SBPData.isLoading ? { button: 'Генерируем' } : undefined}
      defaultValue="100000"
      presets={presets}
    />
  ) : (
    <>
      <Info />
      <Button
        style={{ marginTop: 24 }}
        onClick={back}
        block
        size="large"
        type="outlined"
        startIcon={<IconLineArrowLeft />}
      >
        Назад
      </Button>
    </>
  );
};

export default memo(Form);
