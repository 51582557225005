import { FC } from 'react';
import useRemoveChannelFromCart from 'fsd/entities/cart/lib/useRemoveChannelFromCart';
import { isCanRemovePurchase } from 'fsd/entities/purchase';
import { GoToCart } from 'fsd/features/goToCart';
import { IconCart, IconClose } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './styles';

type Props = {
  channelId: string;
};

const Remove: FC<Props> = ({ channelId }) => {
  const [remove, { isDisabled, isLoading, getPurchase }] = useRemoveChannelFromCart();
  const purchase = getPurchase(channelId);
  const isCanRemove = purchase?.status && isCanRemovePurchase(purchase.status);

  const handleClick = useCallback(() => {
    remove(channelId);
  }, [channelId, remove]);

  return (
    <S.Root>
      <GoToCart
        type="positive"
        size="small"
        block
        noEvents
        startIcon={<IconCart />}
        endIcon={null}
        disabled={isDisabled || isLoading}
      >
        Добавлен
      </GoToCart>
      {isCanRemove && (
        <Button
          type="glass"
          square
          size="small"
          disabled={isDisabled || isLoading}
          onClick={handleClick}
          icon={
            <Icon width={18} color="currentColor">
              <IconClose />
            </Icon>
          }
        />
      )}
    </S.Root>
  );
};

export default memo(Remove);
