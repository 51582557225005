import { FC } from 'react';
import { Avatar } from 'antd';

type Props = {
  title: string;
  iconUrl?: string;
};

export const ChannelIcon: FC<Props> = ({ title, iconUrl }) => {
  if (iconUrl) {
    return <Avatar size={42} src={`data:image/png;base64,${iconUrl}`} alt={title} />;
  }

  return (
    <Avatar size={42} alt={title}>
      {title[0]}
    </Avatar>
  );
};
