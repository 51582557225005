import { FC } from 'react';
import { ButtonProps } from 'antd5';
import { IconExport } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';

type Props = {
  loading?: boolean;
  onClick?: ButtonProps['onClick'];
};

const ExportButton: FC<Props> = ({ loading, onClick }) => {
  if (loading) {
    return (
      <Button
        noEvents
        size="large"
        type="primary-glass"
        startIcon={<LoaderSpinner color="currentColor" />}
      >
        Генерируем Таблицу...
      </Button>
    );
  }

  return (
    <Button size="large" type="primary" startIcon={<IconExport />} onClick={onClick}>
      Экспортировать
    </Button>
  );
};

export default memo(ExportButton);
