import {
  DEFAULT_DATES,
  getCurrentDate,
  IListViewCalendar,
  isSelectedItem,
  MONTH_MAP
} from './helpers';

import { Heading, List, ListItem, ListViewCalendarContainer } from './styles';

export const generateDates = (from: string | undefined, to: string) => {
  if (!from) {
    return undefined;
  }

  const getMonth = (date: Date) => date.getMonth();
  const fromMonth = getMonth(new Date(from)) + 1;
  const toMonth = getMonth(new Date(to)) + 1;

  const fromYear = new Date(from).getFullYear();
  const toYear = new Date(to).getFullYear();

  const result = [];

  for (let year = fromYear; year <= toYear; year++) {
    const avaliableMonths = [];

    if (year === fromYear) {
      for (let month = fromMonth; month <= 12; month++) {
        avaliableMonths.push(month);
      }
    } else if (year === toYear) {
      for (let month = 1; month <= toMonth; month++) {
        avaliableMonths.push(month);
      }
    } else {
      for (let month = 1; month <= 12; month++) {
        avaliableMonths.push(month);
      }
    }

    result.push({
      year,
      avaliableMonths
    });
  }

  return result.reverse();
};

export const ListViewCalendar = ({
  onChange,
  value,
  showOneMonth,
  dates = DEFAULT_DATES
}: IListViewCalendar) => {
  const DATES = showOneMonth ? getCurrentDate() : dates;

  return (
    <ListViewCalendarContainer>
      {DATES.map((item) => (
        <>
          <Heading role="listBox">{item.year}</Heading>
          <List>
            {[...item.avaliableMonths].reverse().map((month) => (
              <ListItem
                selected={showOneMonth || isSelectedItem(value, item.year, month)}
                onClick={() => {
                  onChange?.(`${item.year}-${month}`);
                }}
              >
                {MONTH_MAP[month - 1]}
              </ListItem>
            ))}
          </List>
        </>
      ))}
    </ListViewCalendarContainer>
  );
};
