import { useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { useNavigate } from 'react-router';
import { flagComponent, isDev } from 'fsd/shared/lib/featureFlags';
import { Button } from 'fsd/shared/ui/Buttons';
import { Input } from 'fsd/shared/ui/Input';

import * as S from './Form.styles';

const Form = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      loginLink: ''
    }
  });

  const handleFinish = useCallback<Parameters<typeof handleSubmit>[0]>(
    (data) => {
      if (data.loginLink) {
        const token = new URL(data.loginLink).searchParams.get('token');
        if (token) {
          navigate(`/login/tg?token=${token}`);
        }
      }
    },
    [navigate]
  );
  return (
    <S.Root onFinish={handleSubmit(handleFinish)}>
      <FormItem control={control} name="loginLink">
        <Input type="url" placeholder="Ссылка с логином" />
      </FormItem>
      <Button htmlType="submit" type="primary">
        Войти
      </Button>
    </S.Root>
  );
};

export default flagComponent(isDev(), memo(Form));
