import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'fsd/app/redux/hooks';
import { ContainerPage } from 'fsd/pages/container';
import { ContainersPage } from 'fsd/pages/containers';
import { IconClose } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';
import { closeTab, openTab } from 'slices/purchaseTabs/PurchaseTabsSlice';

import Tabs, { TabsProps } from 'components/Tabs';

import { getChannelId } from './utils/get-channel-id';
import { getTabId } from './utils/get-tab-id';
import { catalogTabId } from './constants';
import PurchaseListTab from './PurchaseListTab';
import { PurchaseMiniTab, PurchaseMiniTabStyles } from './Tab';

import * as S from './styles';

const MIN_TAB_WIDTH = 113;

export const PurchaseTabs: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { containerId: channelIdUrlParam } = useParams();

  const { purchaseIds, activeTabId } = useAppSelector((state) => state.purchaseTabs);

  useEffect(() => {
    const tabId = channelIdUrlParam ? getTabId(channelIdUrlParam) : catalogTabId;

    dispatch(openTab({ tabId }));
  }, [channelIdUrlParam, dispatch]);

  const items: TabsProps['items'] = [
    {
      label: (
        <PurchaseMiniTabStyles.Root>
          <PurchaseMiniTabStyles.Name>Мои кампании</PurchaseMiniTabStyles.Name>
        </PurchaseMiniTabStyles.Root>
      ),
      key: catalogTabId,
      closable: false,
      children: <ContainersPage />
    },
    ...purchaseIds.map((containerId) => ({
      label: <PurchaseMiniTab containerId={containerId} />,
      key: getTabId(containerId),
      closable: true,
      closeIcon: (
        <Icon width={13} color="">
          <IconClose />
        </Icon>
      ),
      children: <ContainerPage containerId={containerId} />
    }))
  ];

  const handleOnChange = (tabId: string) => {
    if (tabId === catalogTabId) {
      navigate('/campaigns');
      return;
    }

    const channelId = getChannelId(tabId);
    navigate(`/campaigns/${channelId}`);
  };

  const handleOnRemove = (tabId: string) => {
    if (tabId === catalogTabId) {
      return;
    }

    if (activeTabId === tabId) {
      const activeChannelId = getChannelId(activeTabId);
      const activeChannelIndex = purchaseIds.findIndex(
        (channelId) => channelId === activeChannelId
      );

      if (activeChannelIndex === -1 || purchaseIds.length === 1) {
        navigate('/campaigns');
      } else {
        const nextChannelIndex =
          activeChannelIndex === purchaseIds.length - 1
            ? activeChannelIndex - 1
            : activeChannelIndex + 1;
        const nextChannelId = purchaseIds[nextChannelIndex];

        navigate(`/campaigns/${nextChannelId}`);
      }
    }

    dispatch(closeTab({ tabId }));
  };

  return (
    <S.StyledCatalogTabs $minTabWidth={MIN_TAB_WIDTH}>
      <Tabs
        onRemove={handleOnRemove}
        onChange={handleOnChange}
        activeKey={activeTabId}
        items={items}
        CatalogTab={PurchaseListTab}
        Tab={PurchaseMiniTab}
        catalogTabId={catalogTabId}
        getChannelId={getChannelId}
        minTabWidth={MIN_TAB_WIDTH}
      />
    </S.StyledCatalogTabs>
  );
};
