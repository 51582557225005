import { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { useGetV1UserQuery, usePatchV1UserMutation } from 'api/api';
import { Button } from 'fsd/shared/ui/Buttons';
import { Input } from 'fsd/shared/ui/Input';
import { LayoutContent } from 'fsd/shared/ui/Layout';

import pkg from '../../../package.json';

import { Error, Field as StyledField, Label, Title } from './commonStyledComponents';
import { validation } from './validation';

import * as S from './styles';

const AccountPage: FC = () => {
  const { data: user } = useGetV1UserQuery();
  const [updateUser] = usePatchV1UserMutation();

  const { username, email } = user || {};

  const initialValues = {
    username,
    email,
    changePassword: '',
    confirmPassword: ''
  };

  return (
    <Form
      onSubmit={updateUser}
      initialValues={initialValues}
      validate={validation}
      render={({ handleSubmit, pristine }) => (
        <LayoutContent>
          <form onSubmit={handleSubmit}>
            <S.Centered>
              <S.Paper>
                <S.Limiter>
                  <S.MainTitleBlock>
                    <S.StyledAccountIcon />
                    <S.MainTitle>Аккаунт</S.MainTitle>
                  </S.MainTitleBlock>

                  <S.Block>
                    <Title>Основная информация</Title>
                    <StyledField>
                      <Label htmlFor="username">Имя</Label>
                      <Field
                        name="username"
                        placeholder="Иванов Иван иванович"
                        render={({ input, meta }) => (
                          <S.InputWrapper>
                            <Input fontWeight={600} {...input} />
                            {meta.touched && meta.error && <Error>{meta.error}</Error>}
                          </S.InputWrapper>
                        )}
                      />
                    </StyledField>
                    <StyledField>
                      <Label htmlFor="email">Почта</Label>

                      <Field
                        name="email"
                        placeholder="ivanow@mail.ru"
                        render={({ input, meta }) => (
                          <S.InputWrapper>
                            <Input fontWeight={600} {...input} />
                            {meta.touched && meta.error && <Error>{meta.error}</Error>}
                          </S.InputWrapper>
                        )}
                      />
                    </StyledField>
                  </S.Block>
                  {/*
                <S.Block>
                  <Title>Изменение пароля</Title>
                  <StyledField>
                    <Label htmlFor="changePassword">Новый пароль</Label>
                    <Field
                      name="changePassword"
                      type="password"
                      placeholder="Не менее 8 символов"
                      render={({ input, meta }) => (
                        <S.InputWrapper>
                          <Input {...input} />
                          {meta.touched && meta.error && (
                            <Error>{meta.error}</Error>
                          )}
                        </S.InputWrapper>
                      )}
                    />
                  </StyledField>
                  <StyledField>
                    <Label htmlFor="confirmPassword">Подтвердите пароль</Label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      placeholder="Не менее 8 символов"
                      render={({ input, meta }) => (
                        <S.InputWrapper>
                          <Input {...input} />
                          {meta.touched && meta.error && (
                            <Error>{meta.error}</Error>
                          )}
                        </S.InputWrapper>
                      )}
                    />
                  </StyledField>
                </S.Block>
                */}

                  <StyledField>
                    <Button disabled={pristine} size="large" type="primary" block htmlType="submit">
                      Сохранить
                    </Button>
                  </StyledField>
                </S.Limiter>
                <p>V{pkg.version}</p>
              </S.Paper>
            </S.Centered>
          </form>
        </LayoutContent>
      )}
    />
  );
};

export default memo(AccountPage);
