import { FC } from 'react';
import { Control } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { SelectProps } from 'antd';
import { OrganisationType } from 'api/api';
import dayjs from 'dayjs';
import { DatePicker } from 'fsd/shared/ui/Date';
import { Input } from 'fsd/shared/ui/Input';
import { Select } from 'fsd/shared/ui/LegacyUIComponents';

import * as S from './styles';

type Props = {
  control: Control<any>;
  organisationTypes: OrganisationType[];
  organisationType?: number;
  disabled?: boolean;
};

const contragentOptions: SelectProps['options'] = [
  {
    value: 1,
    label: 'Рекламораспространитель (Админ канала)'
  },
  {
    value: 2,
    label: 'Рекламодатель (Организация или менеджер закупки)'
  },
  {
    value: 3,
    label: 'Оба'
  }
];

const Fields: FC<Props> = ({ control, organisationTypes, disabled, organisationType }) => {
  const organisationTypeOptions = useMemo<SelectProps['options']>(() => {
    return (
      organisationTypes?.map((type) => ({
        value: type.id,
        label: type.name
      })) ?? []
    );
  }, [organisationTypes]);

  return (
    <S.Root>
      <FormItem
        control={control}
        name="contragentType"
        label="Тип Контрагента
"
      >
        <Select
          getPopupContainer={(elem) => elem}
          options={contragentOptions}
          placeholder="Тип Контрагента
"
        />
      </FormItem>
      <FormItem control={control} name="organisation_type" label="ОПФ">
        <Select
          getPopupContainer={(elem) => elem}
          options={organisationTypeOptions}
          placeholder="ОПФ"
        />
      </FormItem>
      {organisationType === 1 && (
        <FormItem control={control} name="kpp" label="КПП">
          <Input placeholder="165438406" />
        </FormItem>
      )}
      {[1, 2].includes(organisationType ?? 0) && (
        <FormItem control={control} name="ogrn" label="ОГРН">
          <Input placeholder="319774609961743" />
        </FormItem>
      )}
      <FormItem control={control} name="inn" label="ИНН">
        <Input placeholder="12345678901" />
      </FormItem>
      {organisationType === 3 && (
        <FormItem control={control} name="owner_date_of_birth" label="Дата рождения">
          <DatePicker
            inputReadOnly
            disabled={disabled}
            maxDate={dayjs().set('year', dayjs().year() - 18)}
            style={{ width: '100%' }}
            placeholder="Дата"
          />
        </FormItem>
      )}
      <FormItem control={control} name="owner_full_name" label="ФИО Генерального директора">
        <Input placeholder="Иванов Иван Иванович" />
      </FormItem>
      <FormItem
        control={control}
        name="organisation_name"
        label={organisationType === 3 ? 'Название' : 'Юдиридическое название огранизации'}
      >
        <Input placeholder={organisationType === 3 ? 'Иванов' : 'ИП Иванов'} />
      </FormItem>
    </S.Root>
  );
};

export default memo(Fields);
