type Payload = {
  datasets: Array<DataType>;
};

type DataType = {
  type: string;
  label: string;
  data: Array<{ x: number; y: number }>;
};

export const withoutZeroData = (payload: Payload): any => {
  // run through all data and replace 0 with last non-zero value
  payload.datasets.map((item) => {
    if (item.type === 'line' && item.data.length > 0) {
      item.data.map((point, index) => {
        if (point.y === 0) {
          item.data[index].y = item.data[index - 1]?.y || 0;
        }
      });
    }
    return item;
  });
  return payload;
};
