import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

type OptionProps = { $active: boolean };

export const Option = styled(Button).attrs<OptionProps>(({ $active }) => ({
  size: 'large',
  type: $active ? 'primary' : 'default'
}))<OptionProps>`
  flex: 1;
  min-width: min-content;
`;
