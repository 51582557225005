import { FC, memo, Suspense } from 'react';

import EmtpyData from '../EmtpyData';

import ParticipantsChart from './ParticipantsChart';
import ParticipantsValue from './ParticipantsValue';

import * as S from '../../styles';

type Props = {
  isLoading: boolean;
  participants: any;
  participantsDiff?: number;
  participantsAll: number;
  graphData: any;
};

const Participants: FC<Props> = ({
  isLoading,
  participants,
  participantsDiff,
  participantsAll,
  graphData
}) => {
  if (isLoading || !participants || !participants.history || !participants.history.length) {
    return <EmtpyData isLoading={isLoading} timeWindow="Всего" />;
  }
  return (
    <>
      <div>
        <ParticipantsValue participantsDiff={participantsDiff} participantsAll={participantsAll} />
        <S.TotalLabel>Всего</S.TotalLabel>
      </div>
      <Suspense fallback="load">
        <ParticipantsChart graphData={graphData} participants={participants} />
      </Suspense>
    </>
  );
};

export default memo(Participants);
