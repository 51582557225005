import { FC, PropsWithChildren } from 'react';

import Button from '../Button';
import Input from '../Input';
import { ContextProps, PickerContext } from '../lib/context';
import useDisabledTime from '../lib/useDisabledTime';
import Panel from '../Panel';
import { PickerProps } from '../types';

import * as S from './styles';

type Props = PickerProps;

const Picker: FC<PropsWithChildren<Props>> = ({
  children,
  format,
  okButton,
  okDisabled,
  minTime,
  okButtonRender,
  placeholder,
  ...restProps
}) => {
  const disabledTime = useDisabledTime(minTime);
  const contextValue = useMemo(
    (): ContextProps => ({ format, okButton, okDisabled }),
    [format, okButton, okDisabled]
  );
  return (
    <PickerContext.Provider value={contextValue}>
      <S.Root
        {...restProps}
        components={{
          input: Input,
          button: okButtonRender ?? Button
        }}
        allowClear={false}
        showNow={false}
        suffixIcon={null}
        transitionName="ant-fade"
        placement="bottomRight"
        format={format}
        inputReadOnly
        disabledTime={disabledTime}
        placeholder={placeholder || 'Время'}
        // eslint-disable-next-line react/no-unstable-nested-components
        panelRender={(originalPanel) => <Panel>{originalPanel}</Panel>}
      />
    </PickerContext.Provider>
  );
};

export default memo(Picker);
