import styled from 'styled-components';

export const Root = styled.div`
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
  color: ${({ theme }) => theme.blackRock};

  a {
    color: ${({ theme }) => theme.blueRibbon};
  }
`;
