import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const SwitchWrapper = styled.div<{ disabled?: boolean }>`
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ disabled, theme }) => (disabled ? theme.violetPowder : 'inherit')};
`;

export const SwitchLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
`;

export const Root = styled(Button)``;
