import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
`;

export const DocsText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.londonGrey};
  max-width: 330px;
  margin: 0 auto;

  a {
    color: ${({ theme }) => theme.blueRibbon};
    text-decoration: none;
    border-bottom: 1px ${({ theme }) => theme.blueRibbon} solid;
  }
`;
