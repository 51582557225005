import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconMegafon, IconPosts } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';
import { Tabs } from 'fsd/shared/ui/LegacyUIComponents';
import Modal from 'fsd/shared/ui/Modal';

import { theme } from 'theme/theme';

import PostTab from './components/PostTab';

import './styles.less';

import * as S from './styles';

const ChannelPosts: FC = () => {
  const navigate = useNavigate();

  const items = [
    {
      label: (
        <S.TabItem>
          <Icon color={theme.persianBlue} width={20}>
            <IconPosts />
          </Icon>
          <span>Посты</span>
        </S.TabItem>
      ),
      key: 'posts',
      children: <PostTab type="regular" />
    },
    {
      label: (
        <S.TabItem>
          <Icon color={theme.persianBlue} width={20}>
            <IconMegafon />
          </Icon>
          <span>Упоминания</span>
        </S.TabItem>
      ),
      key: 'mentions',
      children: <PostTab type="mentions" />
    }
  ];

  return (
    <Modal
      className="posts-modal"
      open
      closable
      onCancel={() => navigate(-1)}
      topPosition={25}
      alternativeCloseIcon
    >
      <Tabs underlined={1} items={items} centered />
    </Modal>
  );
};

export default ChannelPosts;
