import { Table, TableProps } from 'antd5';
import { type AnyObject } from 'antd5/es/_util/type';
import styled from 'styled-components';

export type RootProps<T = AnyObject> = TableProps<T> & { placeholder?: boolean };

export const Root = styled(Table)<RootProps<unknown>>`
  --table-col-space: 16px;
  --table-border-color: ${({ theme }) => theme.sweetFlower};
  --table-right-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.05);
  background: none;
  box-shadow: none !important;
  position: relative;

  .ant-table-container {
    border-radius: 16px;
    border: 1px solid var(--table-border-color);
    overflow: hidden;
  }

  table {
    border-spacing: 0 !important;
    border-color: none;
  }
  .ant-table {
    position: relative;
    z-index: 0;
    background: none;
  }
  .ant-table-body,
  .ant-table {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ant-table-header:not(.ant-table-sticky-holder) {
    position: relative;
    z-index: 2;
  }
  .ant-pagination {
    position: relative;
    z-index: 1;
  }
  .ant-spin.ant-spin-spinning {
    width: 100%;
  }
  .ant-table-tbody-virtual-scrollbar {
    display: none;
  }
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static !important;
  margin: 0 !important;
`;
