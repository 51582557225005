import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import * as S from './styles';

type Props = HTMLAttributes<HTMLTableRowElement> & { virtual?: boolean };

const Row = forwardRef<HTMLTableRowElement, PropsWithChildren<Props>>(
  ({ children, ...restProps }, ref) => {
    return (
      <S.Root {...restProps} ref={ref}>
        {children}
      </S.Root>
    );
  }
);

export const VirtualRow = memo(
  forwardRef<HTMLTableRowElement, PropsWithChildren<Props>>(
    ({ children, className, ...restProps }, ref) => {
      return (
        <S.RootVirtual className={className} ref={ref}>
          <S.RootVirtualContent {...restProps}>{children}</S.RootVirtualContent>
        </S.RootVirtual>
      );
    }
  )
);

export default memo(Row);
