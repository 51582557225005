import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from 'antd';
import { URLs } from 'fsd/shared/config/URLs';
import {
  IconCartSmall,
  IconFilters,
  IconFinances,
  IconLogo,
  IconSignOut,
  IconSupport,
  IconTelegramArrows
} from 'fsd/shared/icons';
import { isDev } from 'fsd/shared/lib/featureFlags';
import { createUsitefulClassName } from 'fsd/shared/modules/usiteful';
import { Icon } from 'fsd/shared/ui/Icon';
import Divider from 'fsd/shared/ui/LegacyUIComponents/Divider';
import { PopoverUI } from 'fsd/shared/ui/LegacyUIComponents/Popover';

import { theme } from 'theme/theme';

import { useAuth } from '../../fsd/app/providers/Auth';

import UserSettings from './components/UserSettings';
import * as S from './style';

const centeringStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px'
};

const activeStyle = {
  color: theme.persianBlue,
  ...centeringStyles
};

const inactiveStyle = {
  color: theme.violetPowder,
  ...centeringStyles
};

const SideNav: FC = () => {
  const { isAuth } = useAuth();

  return (
    <S.StyledSideNav isAuth={isAuth}>
      <NavLink to="/" style={activeStyle}>
        <Icon width={30} color="">
          <IconLogo className="usiteful__icon-logo" />
          <S.Beta>beta</S.Beta>
        </Icon>
      </NavLink>

      <S.Nav>
        {isAuth && (
          <>
            <PopoverUI placement="right" content="Каналы">
              <NavLink
                className={createUsitefulClassName('nav-channels')}
                to="/"
                style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
              >
                <IconFilters />
              </NavLink>
            </PopoverUI>

            <PopoverUI placement="right" content="Управление каналами">
              <NavLink
                className={createUsitefulClassName('nav-management')}
                to="/management"
                style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
              >
                <IconTelegramArrows />
              </NavLink>
            </PopoverUI>

            <PopoverUI placement="right" content="Кампании">
              <NavLink
                className={createUsitefulClassName('nav-campaigns')}
                to="/campaigns"
                style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
              >
                <IconCartSmall />
              </NavLink>
            </PopoverUI>

            <PopoverUI placement="right" content="Управление финансами">
              <NavLink
                className={createUsitefulClassName('nav-finances')}
                to="/finances"
                style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
              >
                <IconFinances />
              </NavLink>
            </PopoverUI>

            <Divider />

            <UserSettings />

            <Popover placement="right" content="Написать в поддержку">
              <NavLink style={{ marginTop: 'auto' }} to={URLs.SUPPORT_BOT} target="_blank">
                <Icon width={20} color="">
                  <IconSupport className="usiteful__icon-support" />
                </Icon>
              </NavLink>
            </Popover>
            <Popover placement="right" content="Выйти">
              <NavLink to="/signout">
                <Icon width={20} color="">
                  <IconSignOut className="usiteful__icon-signout" />
                </Icon>
              </NavLink>
            </Popover>
          </>
        )}
      </S.Nav>
    </S.StyledSideNav>
  );
};

export default SideNav;
