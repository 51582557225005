import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { ChannelPostRead, ChannelWithExchangeInfo } from 'api/api';
import { format } from 'date-fns';
import { createBase64ChannelIcon } from 'fsd/entities/channel';
import { useChannelCategories } from 'fsd/entities/channel/lib/useChannelCategories';
import {
  IconCollection,
  IconMegafon,
  IconReposts,
  IconSpeaker,
  IconTelegramWithColors,
  IconUsers,
  IconVerified,
  IconViews
} from 'fsd/shared/icons';
import { formatNumber } from 'fsd/shared/lib/helpers/tools';
import { Icon, IconAbsolute } from 'fsd/shared/ui/Icon';

import { theme } from 'theme/theme';

import * as S from '../../styles';

type MinProps = {
  channel: ChannelWithExchangeInfo | undefined;
  post: ChannelPostRead | undefined;
};
const MinimalHeader: FC<MinProps> = ({ channel, post }) => {
  return (
    <>
      <S.CardHeaderContainer compact>
        <S.CardHeaderRow>
          <S.DescriptionContainer>
            <S.ChannelTitle>
              <S.ChannelName className="no-translation">{channel?.info.title}</S.ChannelName>
              {channel?.exchange_info?.verification_status === 'verified' && <IconVerified />}
            </S.ChannelTitle>

            <S.MinimalInfoRow>
              {post?.posted_at && (
                <>
                  <S.MinInfo>
                    <IconAbsolute width={14} color={theme.persianBlue}>
                      <IconMegafon />
                    </IconAbsolute>
                    <S.Date>{format(new Date(post?.posted_at), 'd.MM.yy')}</S.Date>
                    <S.Time>{format(new Date(post?.posted_at), 'HH:mm')}</S.Time>
                  </S.MinInfo>

                  <div>
                    <Link
                      target="_blank"
                      to={`https://t.me/c/${post?.channel_id}/${post?.post_id}`}
                    >
                      <IconAbsolute width={14} color={theme.persianBlue}>
                        <IconTelegramWithColors />
                      </IconAbsolute>
                    </Link>
                  </div>
                </>
              )}
            </S.MinimalInfoRow>
          </S.DescriptionContainer>
        </S.CardHeaderRow>
      </S.CardHeaderContainer>

      <S.InfoRow />
    </>
  );
};

type Props = {
  type: Record<string, boolean>;
  channel: ChannelWithExchangeInfo | undefined;
  post: ChannelPostRead | undefined;
  compact?: boolean;
};
export const PostHeader: FC<Props> = ({ type, channel, post }) => {
  const categories = useChannelCategories(channel?.info.categories);

  // const isShowFullHeader = type.is_mention || post?.type !== "regular";
  // const isShowMinimalHeader = type.is_advertising;
  const isShowFullHeader = false;
  const isShowMinimalHeader = true;

  if (isShowMinimalHeader) {
    return <MinimalHeader channel={channel} post={post} />;
  }

  return (
    <>
      <S.CardHeaderContainer compact={!isShowFullHeader}>
        {channel?.info.icon_url ? (
          <S.LogoContainer>
            <img src={createBase64ChannelIcon(channel.info.icon_url)} alt="" />
          </S.LogoContainer>
        ) : (
          <S.ChannelFakeIcon />
        )}

        <S.CardHeaderRow>
          <S.DescriptionContainer>
            <S.ChannelTitle>
              <S.ChannelName className="no-translation">{channel?.info.title}</S.ChannelName>
              {channel?.exchange_info?.verification_status === 'verified' && <IconVerified />}
            </S.ChannelTitle>
            <S.ChannelSubTitle>{categories}</S.ChannelSubTitle>
          </S.DescriptionContainer>

          {post?.posted_at && (
            <S.RightBlock>
              <S.IconsRow>
                {type.is_collection && (
                  <Icon width={14} color={theme.persianBlue}>
                    <IconCollection />
                  </Icon>
                )}

                {type.is_repost && (
                  <Icon width={14} color={theme.persianBlue}>
                    <IconReposts />
                  </Icon>
                )}

                {type.is_mention && (
                  <Icon width={14} color={theme.persianBlue}>
                    <IconSpeaker />
                  </Icon>
                )}

                <S.Date>{format(new Date(post?.posted_at), 'd.MM.yy')}</S.Date>
              </S.IconsRow>

              <S.TimeWithLinkRow>
                <Link target="_blank" to={`https://t.me/c/${post?.channel_id}/${post?.post_id}`}>
                  <Icon width={14} color={theme.persianBlue}>
                    <IconTelegramWithColors />
                  </Icon>
                </Link>

                <S.Time>{format(new Date(post?.posted_at), 'HH:mm')}</S.Time>
              </S.TimeWithLinkRow>
            </S.RightBlock>
          )}
        </S.CardHeaderRow>
      </S.CardHeaderContainer>

      <S.InfoRow>
        {isShowFullHeader && (
          <>
            <Tooltip
              placement="topLeft"
              title={
                <span>
                  Количество подписчиков <S.TooltipSpanBold>на дату публикации</S.TooltipSpanBold>
                </span>
              }
            >
              <S.InfoCell>
                <Icon width={16} color={theme.persianBlue}>
                  <IconUsers />
                </Icon>
                <S.InfoValue>
                  {channel?.info.participants ? formatNumber(channel.info.participants) : '—'}
                </S.InfoValue>
              </S.InfoCell>
            </Tooltip>

            <Tooltip
              placement="topLeft"
              title={
                <span>Охват поста за всё время его существования, включая просмотры репостов </span>
              }
            >
              <S.InfoCell>
                <Icon width={16} color={theme.persianBlue}>
                  <IconViews />
                </Icon>
                <S.InfoValue>
                  {channel?.info.views ? formatNumber(channel.info.views) : '—'}
                </S.InfoValue>
              </S.InfoCell>
            </Tooltip>

            <Tooltip
              placement="topLeft"
              title={
                <span>
                  <S.TooltipSpanBold>Engagement Rate (Daily)</S.TooltipSpanBold>
                  <br />
                  Отношение среднего количества просмотров на пост к среднему числу подписчиков за
                  24 часа
                </span>
              }
            >
              <S.InfoCell>
                <S.InfoTitle>ERD</S.InfoTitle>
                <S.InfoValue>
                  {channel?.info.ERD ? `${Math.round(channel.info.ERM)}%` : '—'}
                </S.InfoValue>
              </S.InfoCell>
            </Tooltip>

            <Tooltip
              placement="topLeft"
              title={
                <span>
                  <S.TooltipSpanBold>Engagement Rate (Monthly)</S.TooltipSpanBold>
                  <br />
                  Отношение среднего количества просмотров на пост к числу подписчиков за 30 дней
                </span>
              }
            >
              <S.InfoCell>
                <S.InfoTitle>ERM</S.InfoTitle>
                <S.InfoValue>
                  {channel?.info.ERM ? `${Math.round(channel.info.ERM)}%` : '—'}
                </S.InfoValue>
              </S.InfoCell>
            </Tooltip>
          </>
        )}
      </S.InfoRow>
    </>
  );
};
