/* eslint-disable no-nested-ternary */
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconAdd } from 'fsd/shared/icons';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';
import { createUsitefulClassName } from 'fsd/shared/modules/usiteful';
import { Button } from 'fsd/shared/ui/Buttons';
import { Icon } from 'fsd/shared/ui/Icon';

import { ChannelsContext } from '../../Provider';
import ChannelModal from '../ChannelModal';

import * as S from './styles';

const AddChannel = () => {
  const isMobileL = useIsMobile();
  const isMobile = useMediaQuery(MEDIA_DEVICE.mobile.to);
  const contextData = useContext(ChannelsContext);
  return (
    contextData && (
      <S.Root
        trigger={['click']}
        open={contextData.isChannelModalOpen}
        placement="bottom"
        destroyPopupOnHide
        onOpenChange={contextData.setIsChannelModalOpen}
        // eslint-disable-next-line react/no-unstable-nested-components
        dropdownRender={() => (
          <S.Container
            className={createUsitefulClassName('content-manager', 'add-channel-cascader')}
          >
            <S.Header>Добавление канала</S.Header>
            <ChannelModal setIsModalOpen={contextData.setIsChannelModalOpen} />
          </S.Container>
        )}
      >
        <span className="usiteful__purchase__add-channel-button">
          <Button type="primary" size="middle" onClick={contextData.toggleCascader}>
            <Icon width={18} color="" inline>
              <IconAdd />
            </Icon>
            {isMobileL ? (isMobile ? null : 'Добавить') : 'Добавить канал'}
          </Button>
        </span>
      </S.Root>
    )
  );
};

export default memo(AddChannel);
