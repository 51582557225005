import { createSlice, isAnyOf, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { api, PurchasesListContainer } from 'api/api';
import { closeAllButOne, closeAllTabs, closeTab } from 'slices/purchaseTabs/PurchaseTabsSlice';

type CartState = {
  containerId?: PurchasesListContainer['container_id'];
};

const initialState: CartState = {
  containerId: undefined
};

const cardSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    changeCart(state, action: PayloadAction<CartState['containerId']>) {
      state.containerId = action.payload;
    },
    closeCart(state) {
      state.containerId = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(closeTab, (state, action) => {
        if (state.containerId && action.payload.tabId.replace('tab-', '') === state.containerId) {
          state.containerId = undefined;
        }
      })
      .addCase(closeAllTabs, (state) => {
        if (state.containerId) {
          state.containerId = undefined;
        }
      })
      .addCase(closeAllButOne, (state) => {
        if (state.containerId) {
          state.containerId = undefined;
        }
      });
    // .addMatcher(
    //   isAnyOf(api.endpoints.patchV1ExchangePurchaseContainersChangeStatus.matchFulfilled),
    //   (state, action) => {
    //     if (!state.containerId) return;
    //     const args = action?.meta?.arg?.originalArgs?.changeActivityStatusForPurchaseLis;
    //     if (args) {
    //       const containers = args.container_id_list;
    //       if (!args.is_active && containers.includes(state.containerId)) {
    //         state.containerId = undefined;
    //       }
    //     }
    //   }
    // );
  }
});

export const cartMiddleware: Middleware =
  ({ getState }) =>
  (next) => {
    return (action) => {
      const result = next(action);
      localStorage.setItem('cartState', JSON.stringify(getState().cart));
      return result;
    };
  };

export const { changeCart, closeCart } = cardSlice.actions;
export const cartSliceReducer = cardSlice.reducer;

export const reHydrateCart = (): CartState => {
  const localStorageData = localStorage.getItem('cartState');
  if (localStorageData) {
    return JSON.parse(localStorageData);
  }
  return initialState;
};
