import { FC } from 'react';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ record }) => {
  return record.container.title ?? '';
};

export default memo(Cell);
