import { FC, PropsWithChildren } from 'react';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconLogoMobile } from 'fsd/shared/icons';
import useWindowScrolled from 'fsd/shared/lib/useWindowScrolled';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';
import { Icon } from 'fsd/shared/ui/Icon';
import { SideBarMobileWidget } from 'fsd/widgets/SideBarMobile';

import * as S from './styles';

const Mobile: FC<PropsWithChildren> = ({ children }) => {
  const windowScrolled = useWindowScrolled();
  const isMobile = useMediaQuery(MEDIA_DEVICE.mobileL.to);
  return (
    isMobile && (
      <S.Root scrolled={windowScrolled}>
        <S.Content>
          <S.LogoWrapper to="/">
            <Icon width={36} color={theme.sweetFlower}>
              <IconLogoMobile />
            </Icon>
          </S.LogoWrapper>
          {children && <S.Slot>{children}</S.Slot>}
          <SideBarMobileWidget />
        </S.Content>
      </S.Root>
    )
  );
};

export default memo(Mobile);
