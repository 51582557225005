import styled from 'styled-components';

export const SkeletonRow = styled.div<{ $translateY: number }>`
  align-items: center;
  display: flex;
  height: 40px;
  justifycontent: center;
  minwidth: 100%;
  position: absolute;
  will-change: transform;
  ${({ $translateY }) => `
    transform: translateY(${$translateY}px) translateZ(0);
  `}
`;
