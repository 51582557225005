import { boolean, object, z } from 'zod';

import { createBaseSchema } from './baseSchema';

export default createBaseSchema((schema) =>
  schema.merge(
    object({
      acceptOffer: boolean({
        required_error: 'Поле обязательно'
      }).refine((val) => val === true, 'Поле обязательно')
    })
  )
);
