import { FormValues } from '.';

type Error = {
  container_id_list?: string;
};

export const validation = (values: FormValues) => {
  const errors: Partial<Error> = {};

  if (values.container_id_list?.length === 0) {
    errors.container_id_list = 'Необходимо выбрать хотя бы 1 список кампаний';
  }

  return errors;
};
