import { FormItem as _FormItem } from 'react-hook-form-antd';
import { Form as _Form } from 'antd';
import { IconArrowDown as _IconArrowDown } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import { Input } from 'fsd/shared/ui/Input';
import styled from 'styled-components';

export const Root: typeof _Form = styled(_Form)`
  font-size: 14px;
  box-shadow: 0px 4px 32px rgba(0, 16, 61, 0.16);
  background-color: white;
  border-radius: 8px;
  padding: 12px 16px 15px;
  width: 232px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Label = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.blackRock};
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

export const InputLabel = styled.span`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: ${theme.blackRock};
  flex: none;
`;

export const NumberInput = styled(Input)`
  max-width: 100%;
  max-height: 36px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
`;

export const SubmitButton = styled(Button)`
  font-weight: 600;
  background-color: ${({ theme }) => theme.persianBlue};
  max-width: 100%;
`;

export const FormItem: typeof _FormItem = styled(_FormItem)`
  width: 131px;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const IconArrowDown = styled(_IconArrowDown)`
  color: ${theme.blackRock};
`;
