import { FC, memo } from 'react';

import { Err as ErrCell } from '../../../../Row/components/Err';
import useERRMetrics from '../../../lib/useERRMetrics';
import { DefaultColumnCellProps } from '../type';

import * as S from '../../../../Row/styles';

type Props = DefaultColumnCellProps;

const Err: FC<Props> = ({ channel }) => {
  const { tableData, err } = useERRMetrics(channel.info.id);

  return (
    <S.ChartCell className="usiteful__catalog__err-cell">
      {tableData && (
        <ErrCell
          channelId={channel.info.id}
          errMetrics={err}
          isLoading={!tableData.errMetrics?.data}
        />
      )}
    </S.ChartCell>
  );
};

export default memo(Err);
