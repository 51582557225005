import { ComponentProps, CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import * as S from './styles';

export type FormatSelectType = {
  format_id: number;
  format: string;
  price: string;
  currency: number;
  priceNumber: number;
};

type Props = {
  value?: number;
  formats: FormatSelectType[];
  pricePlaceholder?: ReactNode;
  style?: CSSProperties;
} & ComponentProps<typeof S.Root>;

const Select: FC<Props> = ({
  isColumn = false,
  value,
  formats,
  style,
  pricePlaceholder = '— ₽',
  ...restProps
}) => {
  const isMobile = useIsMobile();

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <S.Root
      {...restProps}
      isColumn={isColumn}
      value={value}
      onClick={handleClick}
      style={style}
      placement={isMobile ? 'topLeft' : undefined}
      dropdownStyle={{
        fontSize: '14px',
        borderRadius: '8px',
        padding: '4px 4px 4px'
      }}
    >
      {formats.map((item) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <S.Root.Option value={item.format_id} key={item.format_id}>
            <S.OptionContent>
              <S.Format>{item.format}</S.Format>
              <S.Price disabled={!item.price}>
                {item.price ? `${item.priceNumber.toLocaleString('ru')} ₽` : pricePlaceholder}
              </S.Price>
            </S.OptionContent>
          </S.Root.Option>
        );
      })}
    </S.Root>
  );
};

export default memo(Select);
