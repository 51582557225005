import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    return (
      (b.container.common_purchase_data?.cost ?? 0) - (a.container.common_purchase_data?.cost ?? 0)
    );
  }
};

export default sorter;
