import { FC } from 'react';
import { Link } from 'react-router-dom';
import { closeCart } from 'fsd/entities/cart';
import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { useGetContainerById } from 'fsd/entities/container';
import { Button } from 'fsd/shared/ui/Buttons';
import { BetaModal } from 'fsd/shared/ui/Modal';

import * as S from './styles';

type Props = {
  open?: boolean;
  onClose?: () => void;
};

const Modal: FC<Props> = ({ open, onClose }) => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  const { data: container } = useGetContainerById(cartContainerId);
  const dispatch = useAppDispatch();
  const handleCloseClick = useCallback(() => {
    dispatch(closeCart());
  }, [dispatch]);

  return (
    <BetaModal
      background={theme.white}
      paddingSize="m"
      width={530}
      open={open}
      destroyOnClose
      onCancel={onClose}
    >
      <S.Content>
        <S.Title>Закрыть {container?.title ?? 'корзину'} ?</S.Title>
        <S.Description>
          Вы всегда сможете вернуться к ее редактированию в разделе{' '}
          <Link to="/campaigns">мои кампании</Link>
        </S.Description>
        <S.Buttons>
          <Button size="large" type="secondary" onClick={handleCloseClick}>
            Закрыть
          </Button>
          <S.FullButton size="large" type="primary" onClick={onClose}>
            Продолжить работу
          </S.FullButton>
        </S.Buttons>
      </S.Content>
    </BetaModal>
  );
};

export default memo(Modal);
