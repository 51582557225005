import { ColumnsType } from 'antd5/lib/table';
import { ChannelWithExchangeInfo } from 'api/api';
import { CustomRow } from 'fsd/shared/ui/Table/lib/createCustomRow';

import AvgDailyReaches from '../ui/columns/AvgDailyReaches';
import Cpm from '../ui/columns/Cpm';
import Err from '../ui/columns/Err';
import Info from '../ui/columns/Info';
import Participants from '../ui/columns/Participants';
import ParticipantsDiff from '../ui/columns/ParticipantsDiff';
import Price from '../ui/columns/Price';

export type ColumnData = ChannelWithExchangeInfo | CustomRow<{ key: string }>;

export default () => {
  const columns = useMemo(() => {
    const result: ColumnsType<ColumnData> = [
      {
        render: (channel: ChannelWithExchangeInfo) => <Info.Cell channel={channel} />,
        title: <Info.Title />,
        width: 300
      },
      {
        render: (channel: ChannelWithExchangeInfo) => <Participants.Cell channel={channel} />,
        title: <Participants.Title />
      },
      {
        render: (channel: ChannelWithExchangeInfo) => <ParticipantsDiff.Cell channel={channel} />,
        title: <ParticipantsDiff.Title />
      },
      {
        render: (channel: ChannelWithExchangeInfo) => <AvgDailyReaches.Cell channel={channel} />,
        title: <AvgDailyReaches.Title />
      },
      {
        render: (channel: ChannelWithExchangeInfo) => <Err.Cell channel={channel} />,
        title: <Err.Title />
      },
      {
        render: (channel: ChannelWithExchangeInfo) => <Cpm.Cell channel={channel} />,
        title: <Cpm.Title />
      },
      {
        render: () => <Price.Cell />,
        title: <Price.Title />,
        fixed: 'right',
        align: 'center',
        width: 250
      }
    ];

    return result;
  }, []);
  return columns;
};
