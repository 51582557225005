import { FC } from 'react';
import { ChannelInfo } from 'fsd/entities/channel';
import styled from 'styled-components';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Info = styled(ChannelInfo)`
  width: 180px;
`;

const Cell: FC<Props> = ({ purchase }) => {
  return <Info channelInfo={purchase.channel.info} />;
};

export default memo(Cell);
