import { ReactNode } from 'react';
import { DatePickerProps } from 'antd5';

export type ContextProps = {
  format?: DatePickerProps['format'];
  okButton?: ReactNode;
  okDisabled?: boolean;
};

export const PickerContext = createContext<ContextProps | null>(null);
