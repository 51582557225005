/* eslint-disable no-nested-ternary */
import { ComponentProps, FC } from 'react';
import { IconInfoCircle, IconInternet } from 'fsd/shared/icons';
import { FallbackImgProps } from 'fsd/shared/ui/FallbackImg';
import { Icon } from 'fsd/shared/ui/Icon';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';

import * as S from './styles';

type Props = {
  icon?: string;
  size?: 's' | 'm';
  circle?: boolean;
  internet?: boolean;
  error?: boolean;
  loading?: boolean;
} & Omit<ComponentProps<typeof S.Root>, 'isActive'>;

const isFallbackFn: FallbackImgProps['isFallbackFn'] = ({ event }) =>
  event.currentTarget.naturalWidth < 20;

// eslint-disable-next-line consistent-return
const Image: FC<Pick<Props, 'icon' | 'internet'>> = ({ icon, internet }) => {
  if (internet) {
    if (icon)
      return (
        <S.Image
          src={icon}
          alt="channel favicon"
          isFallbackFn={isFallbackFn}
          fallback={
            <Icon width={16} color={theme.white}>
              <IconInternet />
            </Icon>
          }
        />
      );
    return (
      <Icon width={16} color={theme.white}>
        <IconInternet />
      </Icon>
    );
  }
  if (icon) return <S.Image src={icon} alt="channel icon" />;
  return null;
};

const ChannelIcon: FC<Props> = ({ icon, loading, internet, error, size, circle, ...restProps }) => {
  const isActive = internet || loading;
  return (
    <S.Root {...restProps} isActive={isActive} size={size} circle={circle} isError={error}>
      {error ? (
        <Icon width={size === 's' ? 10 : 16} color={theme.white}>
          <IconInfoCircle />
        </Icon>
      ) : loading ? (
        <LoaderSpinner color={theme.white} fontSize={size === 's' ? 10 : 16} />
      ) : (
        <Image icon={icon} internet={internet} />
      )}
    </S.Root>
  );
};

export default memo(ChannelIcon);
