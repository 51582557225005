import {
  useLazyGetV1ExchangePurchaseByPurchaseIdQuery,
  usePatchV1ExchangePurchaseUpdateMutation,
  usePostV1ExchangePurchaseContainersCreateMutation
} from 'api/api';

type Args = {
  purchaseId: string;
  containerId: string;
};

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatePurchase] = usePatchV1ExchangePurchaseUpdateMutation();
  const [getPurchase] = useLazyGetV1ExchangePurchaseByPurchaseIdQuery();
  const [addPurchaseToContainers] = usePostV1ExchangePurchaseContainersCreateMutation();

  const repeat = useCallback(
    async ({ purchaseId, containerId }: Args) => {
      setIsLoading(true);
      try {
        const purchase = await getPurchase({
          purchaseId
        }).unwrap();

        const [addResult] = await addPurchaseToContainers({
          createPurchase: {
            container_id_list: [containerId],
            purchase_channel_id: purchase.channel.info.id
          }
        }).unwrap();

        if (addResult.purchaseId) {
          await updatePurchase({
            // @ts-ignore
            updatePurchase: {
              purchase_id: addResult.purchaseId,
              currency: 'RUB',
              format: purchase.format,
              cost: purchase.common_purchase_data?.cost ?? 0
            }
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [addPurchaseToContainers, getPurchase, updatePurchase]
  );

  return [repeat, { isLoading }] as const;
};
