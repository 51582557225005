import { FC, PropsWithChildren } from 'react';
import { useGetV1ExchangePurchasesContainersQuery } from 'api/api';

import { getCartContainerIdSelector } from '../selectors';
import { closeCart } from '../slice';

const useCartContextLogic = () => {
  //   const cartContainerId = useAppSelector(getCartContainerIdSelector);
  //   const dispatch = useAppDispatch();
  //   const { data: containers } = useGetV1ExchangePurchasesContainersQuery({
  //     isActive: true
  //   });
  // //   useEffect(() => {
  // //     if (containers && containers.findIndex((c) => c.container_id === cartContainerId) === -1) {
  // //       dispatch(closeCart());
  // //     }
  // //   }, [cartContainerId, containers, dispatch]);
};

const Context = createContext<ReturnType<typeof useCartContextLogic> | null>(null);

export const Provider: FC<PropsWithChildren> = ({ children }) => {
  const contextLogic = useCartContextLogic();
  return <Context.Provider value={contextLogic}>{children}</Context.Provider>;
};
