import getActivePurchases from '../../../lib/getActivePurchases';
import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    return getActivePurchases(b.purchases).length - getActivePurchases(a.purchases).length;
  }
};

export default sorter;
