import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api, useLazyGetV1ChannelsByChannelIdDownloadStatusQuery } from 'api/api';
import { useAppDispatch, useAppSelector } from 'fsd/app/redux/hooks';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';
import {
  changeButtonState,
  removeChannelFromStack
} from 'slices/channelPoolingStack/ChannelPoolingStackSlice';
import { setDateFilter } from 'slices/dateFilter/DateFilterSlice';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.white};
`;

const RESPONSE_STATUSES = {
  in_progress: 'in_progress',
  received: 'received',
  not_found: 'not_found',
  fail: 'fail',
  ready: 'ready'
};

export const useTopLevelChannelPooling = () => {
  const dispatch = useAppDispatch();
  const { stack } = useAppSelector((state) => state.channelPoolingStack);

  const [loadHistoricalMetrics] = useLazyGetV1ChannelsByChannelIdDownloadStatusQuery();

  const refreshChannelHooksData = (id: string, time: string) => {
    // refresh channel data
    dispatch(
      api.util.updateQueryData(
        'getV1ChannelsByChannelId',
        {
          channelId: id
        },
        (draft) => {
          draft.info.historical_metrics_loaded = true;
        }
      )
    );
    dispatch(
      api.endpoints.getV2ChannelsByMetricType.initiate(
        {
          ids: [id],
          metricType: 'views',
          dateRange: {
            to: time
          },
          historicalMetricsLoaded: true
        },
        { forceRefetch: true }
      )
    );
    dispatch(
      api.endpoints.getV2ChannelsByMetricType.initiate(
        {
          ids: [id],
          metricType: 'participants',
          dateRange: {
            to: time
          },
          historicalMetricsLoaded: true
        },
        { forceRefetch: true }
      )
    );
    dispatch(
      api.endpoints.getV2ChannelsByMetricType.initiate(
        {
          ids: [id],
          metricType: 'mentions',
          dateRange: {
            to: time
          },
          historicalMetricsLoaded: true
        },
        { forceRefetch: true }
      )
    );

    // reset dateFilter and refetch statistics widget data
    dispatch(
      setDateFilter({
        id,
        timestamp: null
      })
    );
    dispatch(
      api.endpoints.getV2ChannelsByChannelIdTimelineEvents.initiate(
        {
          channelId: id,
          lastDate: time,
          limit: 200,
          withReposts: true,
          historicalMetricsLoaded: true,
          direction: 'down'
        },
        { subscribe: false, forceRefetch: true }
      )
    );
  };
  const startPooling = ({ id, title, time }: { id: string; title: string; time: string }) => {
    const interval = setInterval(() => {
      loadHistoricalMetrics({ channelId: id })
        .unwrap()
        .then((data) => {
          // if status === in_progress or received we continue polling, nothing to do

          // if status === not_found hide btn
          if (data === RESPONSE_STATUSES.not_found) {
            CustomToast({
              type: 'warn',
              message: (
                <ToastMessage
                  title="Нет данных по каналу"
                  description={`Дополнительных данных по каналу ${title} не найдено`}
                />
              )
            });

            dispatch(
              changeButtonState({
                id,
                state: 'hide'
              })
            );

            clearInterval(interval);
          }

          // if status === fail just show btn and error message
          if (data === RESPONSE_STATUSES.fail) {
            CustomToast({
              type: 'error',
              message: (
                <ToastMessage title="Ошибка загрузки данных" description="Попробуйте позднее" />
              )
            });

            dispatch(
              changeButtonState({
                id,
                state: null
              })
            );

            clearInterval(interval);
          }

          // if status === ready we reload channel data and show success message
          if (data === RESPONSE_STATUSES.ready) {
            CustomToast({
              type: 'success',
              message: (
                <ToastMessage
                  title="Данные загружены 🥳"
                  description={
                    <span>
                      {`Данные по каналу ${title} доступны на `}
                      <StyledLink to={`/channel/${id}`}>странице канала</StyledLink>
                    </span>
                  }
                />
              )
            });

            dispatch(
              changeButtonState({
                id,
                state: 'hide'
              })
            );

            clearInterval(interval);

            refreshChannelHooksData(id, time);
          }
        })
        .catch(() => {
          CustomToast({
            type: 'error',
            message: (
              <ToastMessage title="Ошибка загрузки данных" description="Попробуйте позднее" />
            )
          });

          dispatch(
            changeButtonState({
              id,
              state: null
            })
          );

          clearInterval(interval);
        });
    }, 3000);
  };

  useEffect(() => {
    if (stack.length) {
      startPooling(stack[0]);
      dispatch(removeChannelFromStack(stack[0].id));
    }
  }, [stack]);

  return {};
};
