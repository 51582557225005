import { RefObject, useMemo, useState } from 'react';
import { ChannelRead } from 'api/api';
import { Chart } from 'chart.js';
import { useAppDispatch, useAppSelector } from 'fsd/app/redux/hooks';
import { toISOStringWithMSKTime } from 'fsd/shared/lib/helpers/tools/dates';
import { setDateFilter } from 'slices/dateFilter/DateFilterSlice';

import { generateDates } from 'components/ListViewCalendar';

export const useDateFilterController = (
  now: string,
  channelId: string,
  channel: ChannelRead | undefined,
  chartRef: RefObject<Chart>
) => {
  const dispatch = useAppDispatch();
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const setZoomGraph = (timestamp: number) => {
    if (chartRef.current === null) return;
    const { current } = chartRef;

    // Получаем текущую дату
    const now = new Date(timestamp);

    // Устанавливаем максимальную дату - текущий день текущего месяца
    const max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    // Создаем дату на месяц раньше
    const minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);

    current.zoomScale('x', { min: minDate.getTime(), max: max.getTime() }, 'default');
    current.update();
  };

  const onDateChange = (dateString: string) => {
    const [year, month] = dateString.split('-');
    const lastDateInMonth = new Date(parseInt(year), parseInt(month), 1).getTime() - 1;

    setZoomGraph(lastDateInMonth);
    dispatch(
      setDateFilter({
        id: channelId,
        timestamp: lastDateInMonth
      })
    );
    setIsPickerOpen(false);
  };

  const timestamps = useAppSelector((state) => state.dateFilter);
  const timestamp = timestamps?.[channelId];

  const isTimestampLessThanNow = timestamp && timestamp < new Date(now).getTime();

  const filterDate =
    timestamp && isTimestampLessThanNow ? toISOStringWithMSKTime(new Date(timestamp)) : now;

  const selectedDate = `${new Date(filterDate).getFullYear()}-${
    new Date(filterDate).getMonth() + 1
  }`;

  const calendarDates = useMemo(() => {
    /* return channel?.historical_metrics_loaded
      ? generateDates(channel?.created_at, channel?.deleted_at || now)
      : [
          {
            year: new Date(now).getFullYear(),
            avaliableMonths: [new Date(now).getMonth() + 1],
          },
        ]; */
    return [
      {
        year: new Date(now).getFullYear(),
        avaliableMonths: [new Date(now).getMonth(), new Date(now).getMonth() + 1]
      }
    ];
  }, [channel, now]);

  return {
    isPickerOpen,
    setIsPickerOpen,
    onDateChange,
    filterDate,
    selectedDate,
    dates: calendarDates
  };
};
