import { ComponentProps, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChannelWithExchangeInfo, useGetV1UserQuery, useLazyGetV1ChannelsFindQuery } from 'api/api';
import { ChannelLocalFormatSelect } from 'fsd/entities/channel';
import { useSendColdLeadMutation } from 'fsd/entities/channel/api';
import { FormatSelectType } from 'fsd/entities/format/ui/Select';
import { getCurrencyIcon } from 'fsd/shared/lib/helpers/tools';
import { Button } from 'fsd/shared/ui/Buttons';
import { Input, InputNumber } from 'fsd/shared/ui/Input';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import schema from '../model/schema';

import * as S from './Form.styles';

type Props = {
  onSuccess?: () => void;
  channel: ChannelWithExchangeInfo;
};

const Form: FC<Props> = ({ onSuccess, channel }) => {
  const [sendColdLead, { isLoading }] = useSendColdLeadMutation();
  const [findChannels] = useLazyGetV1ChannelsFindQuery();
  const [format, setFormat] = useState<FormatSelectType | undefined>();
  const { data: user, isLoading: isUserLoading } = useGetV1UserQuery();
  const {
    control,
    handleSubmit,
    formState: { isSubmitted, isValid }
  } = useForm({
    defaultValues: {
      targetChannelLink: '',
      price: undefined as number | undefined
    },
    resolver: zodResolver(schema),
    disabled: isLoading
  });

  const handleFinish = useCallback<Parameters<typeof handleSubmit>[0]>(
    (data) => {
      findChannels({
        keywords: data.targetChannelLink ?? ''
      })
        .unwrap()
        .then((res) => {
          sendColdLead({
            cold_channel_id: Number(channel.info.id),
            cold_channel_link: channel.info.link ?? '',
            target_channel_id: res.channels?.length ? Number(res.channels?.[0]) : 0,
            target_channel_link: data.targetChannelLink,
            format: format?.format,
            price: data.price,
            user_nickname: user?.tg_username ?? ''
          })
            .unwrap()
            .then(() => {
              CustomToast({
                message: <ToastMessage title="Заявка отправлена" />,
                type: 'success'
              });
              onSuccess?.();
            })
            .catch(() => {
              CustomToast({
                message: <ToastMessage title="Не удалось отправить заявку" />,
                type: 'error'
              });
            });
        });
    },
    [
      channel.info.id,
      channel.info.link,
      findChannels,
      format?.format,
      onSuccess,
      sendColdLead,
      user?.tg_username
    ]
  );

  const handleFormatSelect: ComponentProps<typeof ChannelLocalFormatSelect>['onSelect'] = (
    id,
    format
  ) => {
    setFormat(format);
  };

  return (
    <S.Root onFinish={handleSubmit(handleFinish)} disabled={isLoading || isUserLoading}>
      <ChannelLocalFormatSelect
        channelLink={channel.info.link ?? ''}
        isColumn
        pricePlaceholder={null}
        value={format?.format_id}
        onSelect={handleFormatSelect}
      />
      <FormItem control={control} name="price">
        <InputNumber
          size="middle"
          placeholder="Цена"
          postfix={` ${getCurrencyIcon().toString()}`}
          min={0}
        />
      </FormItem>
      <FormItem control={control} name="targetChannelLink">
        <Input placeholder="Ссылка на ваш канал или сайт" size="large" />
      </FormItem>
      <Button
        htmlType="submit"
        size="middle"
        type="primary"
        disabled={isLoading || isUserLoading || (isSubmitted && !isValid)}
      >
        Отправить
      </Button>
    </S.Root>
  );
};

export default memo(Form);
