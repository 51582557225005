import { Info as SharedInfo } from 'fsd/shared/ui/Info';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  max-width: calc(100vw - 32px);
  padding: 10px 0;
  gap: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = styled(SharedInfo).attrs(({ theme }) => ({
  width: 16,
  color: theme.violetPowder
}))`
  margin-left: auto;
`;

export const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.blackRock};
`;

export const Slot = styled.div`
  cursor: pointer;
`;
