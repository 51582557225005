import { FC } from 'react';
import { Spin } from 'antd5';
import { useGetContainerById } from 'fsd/entities/container';
import useExportGoogleSheets from 'fsd/features/exportContainer/lib/useExportGoogleSheets';
import useIsFieldsEdited from 'fsd/features/exportContainer/lib/useIsFieldsEdited';
import { IconAlertSuccess, IconSynchronization } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import { LinkField } from 'fsd/shared/ui/LinkField';

import ExportButton from '../../ExportButton';
import { BaseProps } from '../types';

type Props = BaseProps;

const GoogleSheetsAction: FC<Props> = ({ fields, containerId }) => {
  const { isEdited, update } = useIsFieldsEdited(fields);
  const { data: container } = useGetContainerById(containerId);
  const [exportGoogleSheets, { isLoading, data, isSynchronized }] = useExportGoogleSheets();

  const url = container?.report_link ?? data?.url;

  const handleExportClick = useCallback(() => {
    exportGoogleSheets({ fields, containerId }).then(() => {
      update(fields);
    });
  }, [containerId, exportGoogleSheets, fields, update]);

  if (url) {
    return (
      <>
        <LinkField url={url} />
        {isSynchronized && !isEdited ? (
          <Button type="positive" size="large" startIcon={<IconAlertSuccess />} noEvents>
            Синхронизировано
          </Button>
        ) : (
          <Button
            type="primary"
            size="large"
            startIcon={!isLoading && <IconSynchronization />}
            onClick={handleExportClick}
            loading={isLoading}
          >
            {isLoading ? 'Обновляем таблицу' : 'Обновить таблицу'}
          </Button>
        )}
      </>
    );
  }
  return <ExportButton onClick={handleExportClick} loading={isLoading} />;
};

export default GoogleSheetsAction;
