import { IconEye } from 'fsd/shared/icons';
import Tag from 'fsd/shared/ui/Tag';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

export const TagPost = styled(Tag).attrs({
  startIcon: <IconEye />
})`
  flex: none;
`;

export const TagStatus = styled(Tag)`
  flex: 1;
`;
