import { useGetV1CategoriesQuery } from '../../../../api/api';

export const useChannelCategories = (channelCategories?: number[]) => {
  const { data: categories } = useGetV1CategoriesQuery();

  const result = useMemo(
    () => channelCategories?.map((cat) => categories?.find((catt) => catt.id === cat)?.code_name),
    [categories, channelCategories]
  );

  if (!channelCategories) {
    return 'Без тематики';
  }

  return result?.join(', ');
};
