import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: ${({ theme }) => theme.alpineGoat};
  padding: 10px 16px;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
`;

export const Root = styled.header<{ scrolled?: boolean }>`
  position: sticky;
  z-index: 400;
  top: 0;
  width: 100%;
  padding-bottom: 6px;
  ${({ scrolled }) =>
    scrolled &&
    css`
      ${Content} {
        box-shadow: 0px 10px 10px #34333d18;
      }
    `}
`;

export const LogoWrapper = styled(NavLink)`
  flex: none;
`;

export const Slot = styled.div`
  flex: 1;
`;
