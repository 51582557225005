import { FC, PropsWithChildren } from 'react';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

import SideNav from '../../../../components/SideNav';
import { useAuth } from '../../../app/providers/Auth';

const ContentWrapper = styled.div<{ isAuth: boolean }>`
  margin-left: ${({ isAuth }) => (isAuth ? '64px' : 0)};
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-left: 0;
    margin-top: 0;
  }
`;

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { isAuth } = useAuth();

  return (
    <Container>
      {isAuth && <SideNav />}
      <ContentWrapper isAuth={isAuth}>{children}</ContentWrapper>
    </Container>
  );
};

export default Layout;
