import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { cutNum } from 'fsd/shared/lib/helpers/tools';

import EmtpyLineChart from '../EmtpyLineChart';

import * as S from '../../styles';

type Props = {
  isLoading: boolean;
  timeWindow: string | boolean;
  number?: number;
  isHideChart?: boolean;
};
const EmtpyData: FC<Props> = ({ isLoading, timeWindow, number, isHideChart }) => {
  return (
    <>
      <div>
        <S.DataWrapper>
          <S.DataValue empty>
            {isLoading ? (
              <Skeleton.Button active shape="default" size="small" block />
            ) : number ? (
              <S.DataValueDiff status={number > 0}>
                {number > 0 && '+'} {cutNum(number)}
              </S.DataValueDiff>
            ) : (
              '—'
            )}
          </S.DataValue>
        </S.DataWrapper>

        <S.Mobile>
          <S.DataValueDiff status={false}>—</S.DataValueDiff>
        </S.Mobile>
        {timeWindow !== false && <small>{timeWindow}</small>}
      </div>

      {!isHideChart && (
        <S.ChartWrapper>
          <EmtpyLineChart />
        </S.ChartWrapper>
      )}
    </>
  );
};

export default memo(EmtpyData);
