/* eslint-disable react/no-unstable-nested-components */
import {
  FC,
  memo,
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownProps } from 'antd5';
import { ChannelExchangeInfo, ChannelRead, useGetV2ChannelsByMetricTypeQuery } from 'api/api';
import { useChannelCategories } from 'fsd/entities/channel/lib/useChannelCategories';
import { ChannelActions } from 'fsd/entities/channel/ui/ChannelActions';
import { IconUsers, IconVerified, IconViews } from 'fsd/shared/icons';
import {
  calcErd,
  calcErm,
  combineArrays,
  cutNum,
  getDatesArray
} from 'fsd/shared/lib/helpers/tools';
import { createNow } from 'fsd/shared/lib/helpers/tools/dates';
import { replaceLinksInText } from 'fsd/shared/lib/helpers/tools/replaceLinksInText';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { Icon } from 'fsd/shared/ui/Icon';

import { theme } from 'theme/theme';

import createBase64ChannelIcon from '../../lib/createBase64ChannelIcon';

import * as S from './styles';

type Props = {
  channel: ChannelRead;
  exchangeInfo?: ChannelExchangeInfo;
  trigger?: DropdownProps['trigger'];
  to?: string;
  showHeader: boolean;
  isRegular?: boolean;
};

const ChannelDescription: FC<PropsWithChildren & Props> = ({
  channel,
  exchangeInfo,
  trigger,
  showHeader,
  children,
  isRegular,
  to
}) => {
  const [now] = useState(createNow());
  const popupContainerRef = useRef<HTMLDivElement>(null);

  const { data: participantsMetrics } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channel.id],
      metricType: 'participants',
      dateRange: {
        to: to || now
      },
      historicalMetricsLoaded: channel.historical_metrics_loaded,
      timeZone: 'Europe/Moscow'
    },
    {
      skip: !showHeader
    }
  );

  const { data: viewsMetrics } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channel.id],
      metricType: 'views',
      dateRange: {
        to: to || now
      },
      historicalMetricsLoaded: channel.historical_metrics_loaded,
      timeZone: 'Europe/Moscow'
    },
    {
      skip: !showHeader
    }
  );

  const isMobile = useIsMobile();

  const participants = participantsMetrics?.channels?.[0];
  const views = viewsMetrics?.channels?.[0];

  // @TODO
  // WTF IS THIS! WE SHOUD refactor this shit

  const participantsAll = useMemo(
    () => (participants?.history ? participants.history[participants.history.length - 1] : 0),
    [participants]
  );

  const viewsAll = useMemo(
    () => (views?.history ? views?.history[views.history.length - 1] : 0),
    [views]
  );

  const participantsDatesArr = useMemo(
    () => (participants ? getDatesArray(participants.from, participants.to) : []),
    [participants]
  );
  const participantsStatistic = useMemo(
    () => combineArrays(participantsDatesArr, participants?.history || []),
    [participantsDatesArr, participants]
  );

  const viewsDatesArr = useMemo(() => (views ? getDatesArray(views.from, views.to) : []), [views]);
  const viewsStatistic = useMemo(
    () => combineArrays(viewsDatesArr, views?.history || []),
    [viewsDatesArr, views]
  );

  const erd = useMemo(
    () => calcErd(viewsStatistic, participantsStatistic),
    [viewsStatistic, participantsStatistic]
  );
  const erm = useMemo(
    () => calcErm(viewsStatistic, participantsStatistic),
    [viewsStatistic, participantsStatistic]
  );

  const categories = useChannelCategories(channel?.categories);

  const [showInfo, setShowInfo] = useState(!showHeader);
  const [open, setOpen] = useState(false);

  const modifiedStr = useMemo(() => replaceLinksInText(channel.description), [channel.description]);

  const handleOpenChange = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (trigger === undefined) {
      setOpen(true);
    }
  }, [trigger]);

  const handleContentClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <>
      <Dropdown
        overlayClassName={`description-popover ${isMobile && 'mobile-popover'}`}
        placement="bottomLeft"
        transitionName="ant-fade"
        autoAdjustOverflow={isMobile}
        trigger={trigger}
        open={open}
        destroyPopupOnHide
        onOpenChange={handleOpenChange}
        dropdownRender={() => (
          <S.RootInfo onClick={handleContentClick}>
            {showHeader && (
              <S.Header>
                <S.CardHeaderContainer>
                  {channel?.icon_url ? (
                    <S.LogoContainer>
                      <img src={createBase64ChannelIcon(channel.icon_url)} alt="" />
                    </S.LogoContainer>
                  ) : (
                    <S.ChannelFakeIcon />
                  )}
                  <S.DescriptionContainer>
                    <S.ChannelTitle
                      className="usiteful__channel-info__title"
                      href={`/channel/${channel.id}`}
                    >
                      <S.ChannelName className="no-translation">{channel?.title}</S.ChannelName>
                      {channel?.is_verified && <IconVerified />}
                    </S.ChannelTitle>
                    <S.ChannelSubTitle>{categories}</S.ChannelSubTitle>
                    <ChannelActions
                      onInfoClick={(e) => {
                        setShowInfo((prevState) => !prevState);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      channel={channel}
                      exchangeInfo={exchangeInfo}
                    />
                  </S.DescriptionContainer>
                </S.CardHeaderContainer>

                <S.InfoRow>
                  <S.InfoCell className="usiteful__channel-info__users">
                    <Icon width={16} color={theme.persianBlue}>
                      <IconUsers />
                    </Icon>
                    <S.InfoValue className="usiteful__channel-info-participants">
                      {participants?.history ? cutNum(participantsAll) : '—'}
                    </S.InfoValue>
                  </S.InfoCell>
                  <S.InfoCell>
                    <Icon width={16} color={theme.persianBlue}>
                      <IconViews />
                    </Icon>
                    <S.InfoValue className="usiteful__channel-info-views">
                      {views?.history ? cutNum(viewsAll) : '—'}
                    </S.InfoValue>
                  </S.InfoCell>
                  {/* <S.InfoCell>
              <S.InfoTitle>ERD</S.InfoTitle>
              <S.InfoValue className="usiteful__channel-info-erd">
                {erd}
              </S.InfoValue>
            </S.InfoCell>
            <S.InfoCell>
              <S.InfoTitle>ERM</S.InfoTitle>
              <S.InfoValue className="usiteful__channel-info-erm">
                {erm}
              </S.InfoValue>
            </S.InfoCell> */}
                </S.InfoRow>
              </S.Header>
            )}

            {showInfo && (
              <S.Info>
                <span>Информация о канале</span>
                <S.ChannelDescText className="no-translation">{modifiedStr}</S.ChannelDescText>

                {/* <S.StyledLink
            to={`/channel/${channelId}/names-history`}
            state={{ background: location }}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            <Icon width={14} color={theme.persianBlue}>
              <IconHistory />
            </Icon>
            <span>История названий</span>
          </S.StyledLink>
          <S.StyledLink
            to={`/channel/${channelId}/description-history`}
            state={{ background: location }}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            <Icon width={14} color={theme.persianBlue}>
              <IconHistory />
            </Icon>
            <span>История описаний</span>
          </S.StyledLink>
          <S.StyledLink
            to={`/channel/${channelId}/link-history`}
            state={{ background: location }}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            <Icon width={14} color={theme.persianBlue}>
              <IconHistory />
            </Icon>
            <span>История ссылок</span>
          </S.StyledLink>
        */}
              </S.Info>
            )}
          </S.RootInfo>
        )}
      >
        {children}
      </Dropdown>
      <div ref={popupContainerRef} />
    </>
  );
};

export default memo(ChannelDescription);
