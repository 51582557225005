import { useCallback, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import { ChannelRead, Id, useGetV2ChannelsByMetricTypeQuery } from 'api/api';
import { Chart } from 'chart.js';
import { useAppDispatch } from 'fsd/app/redux/hooks';
import { IconReach, IconUsers, IconViews } from 'fsd/shared/icons';
import {
  checkIsDataExsist,
  cutNum,
  fetchAPI,
  mergeAndSortArray
} from 'fsd/shared/lib/helpers/tools';
import { toISOStringWithMSKTime } from 'fsd/shared/lib/helpers/tools/dates';
import { useChartDateControl } from 'fsd/shared/lib/hooks/useChartDateControl';
import {
  getGraphDataByParticipantsAndViews,
  useViewsAndParticipantsStatistic
} from 'fsd/shared/lib/hooks/useViewsAndParticipantsStatistic';
import { EmptyLine } from 'fsd/shared/ui/EmptyLine';
import Skeleton from 'fsd/shared/ui/Skeleton';

import { ChartJS } from 'components/ChartWithZoom';
import { theme } from 'theme/theme';

import { EmptyDataText } from '../../../../components/ChartWithZoom/components/EmptyDataText';

import * as LS from '../styles';

export type Props = {
  channelId: Id;
  now: string;
  channel: ChannelRead | undefined;
  isLoadingChannel: boolean;
  compact?: boolean;
};

const ReachBlock = (props: Props) => {
  const { isAuth } = useAuth();

  const {
    isLoadingReach,
    isLoadingParticipants,
    isLoadingChannel,
    viewsStatistic,
    viewsAll,
    viewsYesterday,
    viewsWeek,
    viewsMonth,
    viewsIsHistoryEmtpy,
    handleDateClick,
    isShowAll,
    isShowViews,
    toggleLine,
    chartRef,
    graphData,
    handleChartClick,
    isEmptyDataOnZoomChange,
    handleZoomChange
  } = useReachBlockLogic(props);

  if (isLoadingReach || isLoadingParticipants || isLoadingChannel || !isAuth) {
    return <Skeleton type="card" id={2} modificator animation={isAuth} />;
  }

  return (
    <LS.BlockContainer className="usiteful__channel-page__views-widget">
      <LS.MetaInfoContainer>
        <LS.TotalInfoWithERM>
          <Tooltip title="Средний охват поста – среднее арифметическое количество просмотров опубликованных постов за всё время, включая просмотры репостов">
            <LS.Count color={theme.blackRock}>
              {checkIsDataExsist(viewsStatistic) && !viewsIsHistoryEmtpy ? (
                new Intl.NumberFormat().format(viewsAll)
              ) : (
                <EmptyLine />
              )}
            </LS.Count>
            <LS.HeaderDesciption>
              <LS.BigIcon>
                <IconReach />
              </LS.BigIcon>
              <LS.CountDesciptionLabel>Средний охват</LS.CountDesciptionLabel>
            </LS.HeaderDesciption>
          </Tooltip>
        </LS.TotalInfoWithERM>
        <div>
          <LS.DetailedInfo>
            <LS.Pointer
              onClick={() => {
                handleDateClick('yesterday');
              }}
            >
              <LS.Count color={theme.blackRock}>
                {checkIsDataExsist(viewsStatistic, 'yesterday') ? (
                  cutNum(viewsYesterday)
                ) : (
                  <EmptyLine />
                )}
              </LS.Count>
              <LS.CountDesciption>Вчера</LS.CountDesciption>
            </LS.Pointer>

            {/* Empty space */}
            <div />

            <LS.Pointer
              onClick={() => {
                handleDateClick('week');
              }}
            >
              <LS.Count color={theme.blackRock}>
                {checkIsDataExsist(viewsStatistic, 'week') ? cutNum(viewsWeek) : <EmptyLine />}
              </LS.Count>
              <LS.CountDesciption>Неделя</LS.CountDesciption>
            </LS.Pointer>
            <LS.Pointer
              onClick={() => {
                handleDateClick('month');
              }}
            >
              <LS.Count color={theme.blackRock}>
                {checkIsDataExsist(viewsStatistic, 'month') ? cutNum(viewsMonth) : <EmptyLine />}
              </LS.Count>
              <LS.CountDesciption>Месяц</LS.CountDesciption>
            </LS.Pointer>
          </LS.DetailedInfo>
        </div>
      </LS.MetaInfoContainer>
      <LS.ChartContainer>
        <LS.Title>
          <LS.InteractiveChartFilter
            isDisabledToggle={!isShowAll && isShowViews}
            onClick={() => toggleLine('views')}
            isActive={isShowViews}
          >
            <LS.BigIconTableParams>
              <IconViews />
            </LS.BigIconTableParams>
            <LS.ChartFilterLabel>Средний охват поста</LS.ChartFilterLabel>
          </LS.InteractiveChartFilter>
          <LS.InteractiveChartFilter
            isDisabledToggle={isShowAll && !isShowViews}
            onClick={() => toggleLine('all')}
            isActive={isShowAll}
          >
            <LS.BigIconTableParamsBlue>
              <IconUsers />
            </LS.BigIconTableParamsBlue>
            <LS.ChartFilterLabel>Подписчики</LS.ChartFilterLabel>
          </LS.InteractiveChartFilter>
        </LS.Title>

        <LS.ChartWrapper>
          <ChartJS
            chartref={chartRef}
            data={graphData}
            onZoomChange={handleZoomChange}
            onClick={handleChartClick}
            isDashed
          />

          {isEmptyDataOnZoomChange && <EmptyDataText />}
        </LS.ChartWrapper>
      </LS.ChartContainer>
    </LS.BlockContainer>
  );
};

export const useReachBlockLogic = ({ channelId, now, channel, isLoadingChannel }: Props) => {
  const { isAuth } = useAuth();
  const dispatch = useAppDispatch();
  const chartRef = useRef<Chart>(null);
  const [isShowAll, setIsShowAll] = useState(false);
  const [isShowViews, setIsShowViews] = useState(true);

  const { data: views, isLoading: isLoadingReach } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: [channelId],
      metricType: 'reaches',
      dateRange: {
        to: now
      },
      historicalMetricsLoaded: !!channel?.historical_metrics_loaded,
      timeZone: 'Europe/Moscow'
    },
    { refetchOnReconnect: true, skip: isLoadingChannel || !isAuth }
  );

  const { data: participants, isLoading: isLoadingParticipants } =
    useGetV2ChannelsByMetricTypeQuery(
      {
        ids: [channelId],
        metricType: 'participants',
        dateRange: {
          to: now
        },
        historicalMetricsLoaded: !!channel?.historical_metrics_loaded,
        timeZone: 'Europe/Moscow'
      },
      { refetchOnReconnect: true, skip: isLoadingChannel || !isAuth }
    );

  const [isEmptyDataOnZoomChange, setIsEmptyDataOnZoomChange] = useState(false);

  const {
    graphData,
    viewsYesterday,
    viewsWeek,
    viewsMonth,
    viewsAll,
    viewsStatistic,
    viewsIsHistoryEmtpy
  } = useViewsAndParticipantsStatistic(views?.channels?.[0], participants?.channels?.[0]);

  const toggleLine = useCallback(
    (type: string): void => {
      const chart = chartRef?.current;

      if (type === 'all') {
        const isTryDisableBoth = !isShowViews && isShowAll;

        if (!isTryDisableBoth) {
          setIsShowAll(!isShowAll);
          chart?.setDatasetVisibility(0, !isShowAll);
          chart?.update();
        }
      }

      if (type === 'views') {
        const isTryDisableBoth = !isShowAll && isShowViews;

        if (!isTryDisableBoth) {
          setIsShowViews(!isShowViews);
          chart?.setDatasetVisibility(1, !isShowViews);
          chart?.update();
        }
      }
    },
    [isShowAll, isShowViews]
  );

  const handleZoomChange = useCallback(
    async (from: number, to: number, chart: any) => {
      const day = 24 * 60 * 60 * 1000;
      const diffence = to - from;

      if (diffence < day) {
        const newFrom = toISOStringWithMSKTime(new Date(from));
        const newTo =
          toISOStringWithMSKTime(new Date(to)) > now ? now : toISOStringWithMSKTime(new Date(to));

        const result = await fetchAPI.all([
          `/v2/channels/reaches?ids=${channelId}&from=${newFrom}&to=${newTo}&historical_metrics_loaded=${!!channel?.historical_metrics_loaded}&time_zone=Europe%2FMoscow`,
          `/v2/channels/participants?ids=${channelId}&from=${newFrom}&to=${newTo}&historical_metrics_loaded=${!!channel?.historical_metrics_loaded}&time_zone=Europe%2FMoscow`
        ]);

        if (!result) {
          return;
        }

        const newData = getGraphDataByParticipantsAndViews(
          result[0]?.channels?.[0],
          result[1]?.channels?.[0]
        );

        newData.graphData.forEach((item, index) => {
          const mergedData = mergeAndSortArray(graphData[index].data, item.data, 'x');
          if (chart) {
            // eslint-disable-next-line no-param-reassign
            chart.data.datasets[index].data = mergedData;
          }
        });

        chart.stop();
        chart.update('none');

        const IsEmpty = chart.data.datasets[0].data.findIndex((item: any) => {
          return item.x > from && item.x < to;
        });

        setIsEmptyDataOnZoomChange(IsEmpty === -1);
      } else {
        setIsEmptyDataOnZoomChange(false);
      }
    },
    [now, channelId, channel?.historical_metrics_loaded, graphData]
  );

  const { handleDateClick, handleChartClick } = useChartDateControl(
    chartRef,
    handleZoomChange,
    now,
    'views',
    dispatch,
    channelId
  );
  return {
    isLoadingReach,
    isLoadingParticipants,
    isLoadingChannel,
    viewsStatistic,
    viewsAll,
    viewsYesterday,
    viewsWeek,
    viewsMonth,
    viewsIsHistoryEmtpy,
    handleDateClick,
    isShowAll,
    isShowViews,
    toggleLine,
    chartRef,
    graphData,
    handleChartClick,
    isEmptyDataOnZoomChange,
    handleZoomChange
  };
};

export default memo(ReachBlock);
