import { TableDataContext } from './tableDataContext';

export default (channelId: string) => {
  const tableDataContext = useContext(TableDataContext);

  const cpm = useMemo(
    () =>
      tableDataContext?.avgDailyReachesMetrics?.data?.channels?.find(
        (item) => item.channel_id === channelId
      ),
    [tableDataContext?.avgDailyReachesMetrics?.data?.channels, channelId]
  );

  return {
    tableData: tableDataContext,
    cpm
  };
};
