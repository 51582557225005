import { ComponentProps } from 'react';
import { useLazyGetUserOrganisation } from 'fsd/entities/user';
import { CreateOrganisationModal } from 'fsd/features/createOrganisation';
import { IconArrowSquire } from 'fsd/shared/icons';
import { Button as SharedButton } from 'fsd/shared/ui/Buttons';

import { WithdrawalBalanceForm } from '../Form';

import Modal from './Modal';

const Button = (props: ComponentProps<typeof SharedButton>) => {
  const [openModal, setOpenModal] = useState(false);
  const [getUserOrganisation] = useLazyGetUserOrganisation();
  const [createOrganisationOpen, setCreateOrganisationOpen] = useState(false);

  const handleSuccess = () => {
    setOpenModal(false);
  };

  const handleClick = useCallback(() => {
    getUserOrganisation().then((res) => {
      if (res?.data?.data) {
        setOpenModal(true);
      } else {
        setCreateOrganisationOpen(true);
      }
    });
  }, [getUserOrganisation]);

  const handleCreateOrganisationClose = useCallback(() => {
    setCreateOrganisationOpen(false);
  }, []);

  const handleCreateOrganisationSuccess = useCallback(() => {
    setCreateOrganisationOpen(false);
    setOpenModal(true);
  }, []);

  return (
    <>
      <CreateOrganisationModal
        open={createOrganisationOpen}
        onClose={handleCreateOrganisationClose}
        onSuccess={handleCreateOrganisationSuccess}
      />
      <Modal open={openModal} onCancel={() => setOpenModal(false)}>
        <WithdrawalBalanceForm onSuccess={handleSuccess} />
      </Modal>
      <SharedButton
        {...props}
        onClick={handleClick}
        type="outlined"
        size="middle"
        endIcon={<IconArrowSquire />}
      >
        Вывести
      </SharedButton>
    </>
  );
};

export default Button;
