import { ComponentProps, FC, useEffect } from 'react';
import { ChannelsListResponse, TimeWindow } from 'api/api';
import { FormApi } from 'final-form';
import { IconFilters, IconSearch } from 'fsd/shared/icons';
import { isEqual } from 'fsd/shared/lib/helpers/tools';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { useStickyState } from 'fsd/shared/lib/hooks/useStickyState';
import { Icon } from 'fsd/shared/ui/Icon';
import Loader from 'fsd/shared/ui/Loader';

import MobileSort from 'components/TableSettings/MobileSort';
import { FormValues } from 'pages/catalog/types';
import { theme } from 'theme/theme';

import { CatalogTable } from './components/Table';

import * as S from './styles';

type Props = {
  form: FormApi<FormValues, Partial<FormValues>>;
  values: Partial<FormValues>;
  data?: ChannelsListResponse;
  onLoadMore: ComponentProps<typeof CatalogTable>['onLoadMore'];
  hasMore?: ComponentProps<typeof CatalogTable>['hasMore'];
};

export const initialTableCols = {
  channelName: true,
  users: true,
  usersGrowth: true,
  // views: false,
  avg_daily_reaches: true,
  er: false,
  price: true,
  mentions: false,
  cpm: true,
  err: true
};

export const initialMobileTableCols = {
  channelName: true,
  users: true,
  usersGrowth: false,
  views: false,
  er: false,
  mentions: false
};

const ChannelsTable: FC<Props> = ({ form, values, data, onLoadMore, hasMore = false }) => {
  const { channels } = data || {};

  const [tableCols, setTableCols] = useStickyState(initialTableCols, 'channelsTableCols');

  // if localStorage is stale
  useEffect(() => {
    // TODO: extract to a hook
    if (!isEqual(Object.keys(initialTableCols).sort(), Object.keys(tableCols).sort())) {
      setTableCols(initialTableCols);
    }
  }, [setTableCols, tableCols]);

  const isMobile = useIsMobile();
  return (
    <S.TableWrapper className="usiteful__tabele-channels">
      <S.TitleWrapper className="usiteful__table-title">
        <S.PageTitle className="usiteful__table-title-text">
          <Icon width={isMobile ? 24 : 20} color={theme.persianBlue}>
            {isMobile ? <IconSearch /> : <IconFilters />}
          </Icon>
          <span>Каталог каналов</span>
        </S.PageTitle>

        <S.MobileTableActions className="usiteful__table-actions">
          <MobileSort values={values} form={form} />
        </S.MobileTableActions>
      </S.TitleWrapper>

      {/* <Pagination upOnSafari form={form} values={values} total={total} /> */}
      {channels ? (
        <CatalogTable
          channels={channels}
          form={form}
          values={values}
          timeWindow={values.date_range as TimeWindow}
          onLoadMore={onLoadMore}
          hasMore={hasMore}
        />
      ) : (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      )}

      {/* <S.FooterPagination>
        <Pagination form={form} values={values} total={total} />
      </S.FooterPagination> */}
    </S.TableWrapper>
  );
};

export default memo(ChannelsTable);
