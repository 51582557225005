import { FC, ReactNode } from 'react';
import { IconAttach, IconCopy } from 'fsd/shared/icons';
import { copyURL } from 'fsd/shared/lib/helpers/tools';

import { Icon } from '../../Icon';

import * as S from './styles';

type LinkFieldProps = {
  icon?: ReactNode;
  iconColor?: string;
  url: string;
};

const LinkField: FC<LinkFieldProps> = ({
  icon = <IconAttach />,
  iconColor = theme.persianBlue,
  url
}) => {
  return (
    <S.LinkContainer
      onClick={(e) => {
        copyURL(e, url);
      }}
    >
      {icon && (
        <Icon width={18} color={iconColor}>
          {icon}
        </Icon>
      )}
      <S.LinkText>{url}</S.LinkText>
      <Icon width={18} color={theme.persianBlue}>
        <IconCopy />
      </Icon>
    </S.LinkContainer>
  );
};

export default LinkField;
