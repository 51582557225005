import React, { ReactElement, ReactNode, Ref, useEffect, useRef } from 'react';

type ClickHandler = () => void;

function useOutsideAlerter<T extends HTMLElement>(
  ref: React.RefObject<T>,
  onClickInside?: ClickHandler,
  onClickOutside?: ClickHandler,
  exclude?: React.RefObject<HTMLElement>[]
): void {
  useEffect(() => {
    function handleClick(event: MouseEvent): void {
      const inExcluded = exclude?.some((excl) => {
        if (excl?.current?.contains?.(event.target as Node)) return true;

        return false;
      });

      if (inExcluded) return;

      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside && onClickOutside();
      } else {
        onClickInside && onClickInside();
      }
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, onClickInside, onClickOutside]);
}

/*
 * HOC Component that alerts if you click outside/inside of it
 * exclude will exclude passed elements from triggering onClickInside/onClickOutside callbacks
 */
const OutsideClickAlerter = ({
  onClickOutside,
  onClickInside,
  exclude,
  children
}: {
  onClickOutside: ClickHandler;
  onClickInside?: ClickHandler;
  exclude?: React.RefObject<HTMLElement>[];
  children: ReactNode;
}): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, onClickInside, onClickOutside, exclude);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickAlerter;
