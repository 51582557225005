import { ChannelIcon } from 'fsd/entities/channel';
import styled, { css } from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
`;

export const Icon = styled(ChannelIcon).attrs({
  size: 's',
  circle: true
})`
  margin-right: 8px;
`;

export const NameCss = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  height: 1.12em;
  width: 100%;
`;

export const Name = styled.h3`
  ${NameCss}
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
