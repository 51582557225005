import { FormEvent } from 'react';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

export default async (e?: FormEvent, link?: string, text?: string) => {
  e?.stopPropagation();
  try {
    await navigator.clipboard.writeText(link || window.location.href);
    CustomToast({
      type: 'success',
      message: <ToastMessage title={text || 'Текст скопирован'} />
    });
  } catch (err) {
    console.error('Не удалось скопировать: ', err);
    CustomToast({
      type: 'error',
      message: <ToastMessage title="Не удалось скопировать" />
    });
  }
};
