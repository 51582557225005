import { ComponentProps, FC, PropsWithChildren } from 'react';

import * as S from './styles';

type Props = ComponentProps<typeof S.Root>;

const HeaderCell: FC<PropsWithChildren<Props>> = ({ children, ...restProps }) => {
  const isCenter = restProps?.style?.textAlign === 'center';
  return (
    <S.Root {...restProps} $center={isCenter}>
      <S.Content>{children}</S.Content>
    </S.Root>
  );
};

export default memo(HeaderCell);
