import { ColumnComponentExporter } from '../type';

import Cell from './CellBeta';
import Title from './Title';

export default {
  Title,
  Cell
} satisfies ColumnComponentExporter;

export { default as PriceForm } from './Form';
