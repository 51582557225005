import { FC } from 'react';
import { Table } from 'fsd/shared/ui/Table';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  const result = purchase.common_purchase_data?.subscribers;
  return typeof result !== 'undefined' ? result.toLocaleString('ru') : <Table.NoCellData />;
};

export default memo(Cell);
