import { FC } from 'react';
import Icon from '@ant-design/icons';
import { Channel, ChannelExchangeInfo } from 'api/api';
import { ChannelActions } from 'fsd/entities/channel/ui/ChannelActions';
import { IconVerified } from 'fsd/shared/icons';
import { Popover } from 'fsd/shared/ui/LegacyUIComponents';
import styled from 'styled-components';

import { ChannelIcon } from '../ChannelIcon/ChannelIcon';

type Props = {
  channel: Channel;
  exchange_info?: ChannelExchangeInfo;
  title: string;
  name: string;
  category: string;
  iconUrl?: string;
  isVerified: boolean;
  isActive: boolean;
};

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TabDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 220px;
`;

const TitleRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  white-space: nowrap;
`;

const TitleTypography = styled.div<{ isVerifed: boolean }>`
  color: ${({ theme }) => theme.blackRock};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => (props.isVerifed ? '167px' : '190px')};
`;

const SubTitleTypography = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.bayFog};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChannelTabLabel: FC<Props> = (props) => {
  const { channel, exchange_info, iconUrl, title, category, isVerified, isActive } = props;

  const renderTitle = () => {
    return (
      <TitleRow>
        <TitleTypography isVerifed={isVerified} className="no-translation">
          {title}
        </TitleTypography>

        {isVerified && <Icon component={IconVerified} />}
      </TitleRow>
    );
  };

  const renderChannelIcon = () => {
    return (
      <div>
        <ChannelIcon title={title} iconUrl={iconUrl} />
      </div>
    );
  };

  const renderChildren = () => {
    return (
      <div className="ts-tabs-tab-wrapper">
        <div className="ts-tabs-tab-heading">
          <TabWrapper>
            {isActive ? (
              <Popover content={`${title} | ${category}`} placement="bottom">
                {renderChannelIcon()}
              </Popover>
            ) : (
              renderChannelIcon()
            )}
            <TabDescription>
              {isActive ? (
                <Popover content={`${title} | ${category}`} placement="bottom">
                  {renderTitle()}
                </Popover>
              ) : (
                renderTitle()
              )}
              <div className="usiteful__catalog-tab-subtitle">
                <SubTitleTypography>{category}</SubTitleTypography>
              </div>

              {isActive && channel && (
                <ChannelActions channel={channel} exchangeInfo={exchange_info} />
              )}
            </TabDescription>
          </TabWrapper>
        </div>
      </div>
    );
  };

  if (!isActive) {
    return (
      <Popover content={`${title} | ${category}`} placement="bottom">
        {renderChildren()}
      </Popover>
    );
  }

  return renderChildren();
};

export default memo(ChannelTabLabel);
