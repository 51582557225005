import { usePostV1ExchangeByContainerIdReportExcelMutation } from 'api/api';
import stringsToBooleanObject from 'fsd/shared/lib/stringsToBooleanObject';

import { BaseExportData } from './types';

export default () => {
  const [exportMutation, { isLoading }] = usePostV1ExchangeByContainerIdReportExcelMutation();

  const exportExcel = useCallback(
    (data: BaseExportData) => {
      exportMutation({
        body: {
          fields: stringsToBooleanObject(data.fields)
        },
        containerId: data.containerId
      });
    },
    [exportMutation]
  );

  return [exportExcel, { isLoading }] as const;
};
