import CustomToast from 'fsd/shared/ui/Toast/customToast';

import SuccessToastMessage from '../ui/SuccessToastMessage';

export default () => {
  CustomToast({
    message: <SuccessToastMessage />,
    type: 'success'
  });
};
