import { FC, HTMLAttributes } from 'react';
import { ChannelRead } from 'api/api';

import createBase64ChannelIcon from '../../lib/createBase64ChannelIcon';
import { useChannelCategories } from '../../lib/useChannelCategories';
import ChannelIcon from '../ChannelIcon';
import ChannelVerified from '../ChannelVerified';

import * as S from './styles';

type Props = {
  channelInfo: ChannelRead;
  withTgLing?: boolean;
  size?: 'm';
} & HTMLAttributes<HTMLDivElement>;

const ChannelInfo: FC<Props> = ({ channelInfo, size, withTgLing = true, ...restProps }) => {
  const categories = useChannelCategories(channelInfo.categories);

  return (
    <S.Root {...restProps}>
      <ChannelIcon icon={createBase64ChannelIcon(channelInfo.icon_url)} size={size || 's'} circle />
      <S.Info>
        <S.Header>
          <S.Title>{channelInfo.title}</S.Title>
          {channelInfo.is_verified && <ChannelVerified />}
        </S.Header>
        <S.Category>{categories}</S.Category>
        {channelInfo.name && withTgLing && (
          <S.Link
            className="usiteful__purchase__item__channel-link"
            target="_blank"
            rel="nofollow noopener"
            href={`https://t.me/${channelInfo.name}`}
          >
            @{channelInfo.name}
          </S.Link>
        )}
      </S.Info>
    </S.Root>
  );
};

export default memo(ChannelInfo);
