import { ChangeEventHandler, ComponentProps, FC } from 'react';
import { IconSearch } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';
import { Input } from 'fsd/shared/ui/Input';
import { useDebouncedCallback } from 'use-debounce';

import { Context } from '../../lib/context';

type Props = ComponentProps<typeof Input>;

const Search: FC<Props> = (props) => {
  const pageData = useContext(Context);
  const [value, setValue] = useState(pageData?.search ?? '');

  const search = useDebouncedCallback((value: string) => pageData?.setSearch(value), 100);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    search(value);
  }, [search, value]);

  return (
    <Input
      prefix={
        <Icon>
          <IconSearch />
        </Icon>
      }
      placeholder="Название кампании"
      {...props}
      onChange={handleChange}
      value={value}
    />
  );
};

export default memo(Search);
