import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Icon } from 'fsd/shared/ui/Icon';
import { Title } from 'fsd/shared/ui/Title';
import styled, { css } from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex: 1;
  gap: 8px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 4px;
  }
`;

export const Text = styled(Title)<{ $size?: 'medium' }>`
  margin-bottom: 0;
  line-height: 114%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex: 1;
  ${({ $size }) =>
    $size === 'medium' &&
    css`
      font-size: 20px;
    `}
  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 24px - 4px);
  }
`;

export const IconWrapper = styled(Icon)`
  flex: none;
`;
