import { ComponentProps, FC, HTMLAttributes } from 'react';
import { useNavigate } from 'react-router';
import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { createTableLoaderRow, Table as TableShared } from 'fsd/shared/ui/Table';

import { TableDataContext } from './lib/tableDataContext';
import useColumns, { ColumnData } from './lib/useColumns';
import useTableData, { UseTableDataParams } from './lib/useTableData';
import BodyRow from './ui/BodyRow';
import { MobileRowList } from './ui/MobileRow';

type Props = {
  channels: UseTableDataParams['channels'];
  timeWindow: UseTableDataParams['timeWindow'];
  form: UseTableDataParams['form'];
  values: UseTableDataParams['formValues'];
  onLoadMore: ComponentProps<typeof TableShared.InfiniteProvider>['loadMore'];
  hasMore: boolean;
};

const Table: FC<Props> = ({ channels, timeWindow, form, values, onLoadMore, hasMore }) => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  const isMobile = useIsMobile();
  const columns = useColumns();
  const tableData = useTableData({ channels, timeWindow, form, formValues: values });
  const navigate = useNavigate();

  const dataSource: ColumnData[] = useMemo(
    () => [...channels, ...createTableLoaderRow(hasMore)],
    [channels, hasMore]
  );

  const handleRowClick = useCallback(
    (channel: ColumnData) => {
      if ('info' in channel) {
        navigate(`/channel/${channel.info.id}`);
      }
    },
    [navigate]
  );

  const onRow = useCallback(
    (channel: ColumnData): HTMLAttributes<HTMLElement> & { channel: ColumnData } => {
      return {
        onClick(e) {
          e.stopPropagation();
          handleRowClick(channel);
        },
        channel
      };
    },
    [handleRowClick]
  );

  return (
    <TableDataContext.Provider value={tableData}>
      {isMobile ? (
        <MobileRowList
          hasMore={hasMore}
          channels={channels}
          loadMore={onLoadMore}
          offsetLoadMore={800}
        />
      ) : (
        <TableShared.InfiniteProvider
          hasMore={hasMore}
          loadMore={onLoadMore}
          offsetScrollHeight={330 + (cartContainerId ? 80 : 0)}
          offsetLoadMore={800}
          render={({ props }) => {
            return (
              <TableShared.Root
                {...props}
                rowKey={(channel: ColumnData) =>
                  'info' in channel ? channel.info.id : channel.key
                }
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                virtual
                tableLayout="auto"
                components={{
                  body: {
                    row: BodyRow
                  }
                }}
                onRow={onRow}
              />
            );
          }}
        />
      )}
    </TableDataContext.Provider>
  );
};

export default memo(Table);
