import styled from 'styled-components';

export const Root = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #fff;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
  line-height: 114%;
`;

export const TagList = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  & > * {
    flex: 1;
    min-width: min-content;
  }
`;
