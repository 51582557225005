import { ChannelExchangeFormats } from 'api/api';
import calcCPMPlan from 'fsd/shared/lib/analytics/calcCPMPlan';

import useCPMMetrics from './useCPMMetrics';

type Args = {
  channelId: string;
  activeFormatId?: number;
  channelFormats?: ChannelExchangeFormats[];
};

export default ({ channelId, activeFormatId, channelFormats }: Args) => {
  const { cpm } = useCPMMetrics(channelId);
  const isLoading = !cpm;
  const isEmpty =
    isLoading || cpm.history.length === 0 || !activeFormatId || !channelFormats?.length;

  const format = useMemo(
    () => channelFormats?.find((format) => format.format_id === activeFormatId),
    [activeFormatId, channelFormats]
  );

  const cpmValue = cpm && cpm.history[cpm.history.length - 1];
  const price = Number(format?.prices?.[0]?.price ?? 0);
  const result = price !== undefined && cpmValue ? calcCPMPlan(price, cpmValue) : 0;

  return {
    isLoading,
    value: result,
    price,
    cpmValue,
    isEmpty
  };
};
