import styled from 'styled-components';

export const Root = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 5px;
  min-width: 558px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 8px;
  }

  li {
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: ${({ theme }) => theme.white} !important;
  }
`;

export const ContextHotkeyText = styled.div`
  border-radius: 4.6px;
  background-color: ${({ theme }) => theme.alpineGoat};
  padding: 5px 4px;
  box-shadow: 0px 1px 0px #e9e9e9;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
`;

export const ContextMenuItem = styled.div`
  padding: 5px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  &:active {
    li {
      background-color: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.alpineGoat};

    div {
      background-color: ${({ theme }) => theme.white};
    }
  }
`;

export const ContextMenuHotkeyText = styled.span`
  margin-left: 41px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const MacosIcon = styled.span`
  font-size: 14px;
  font-weight: 700;
`;
