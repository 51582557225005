export default (src: string) => {
  return new Promise<Event>((resolve, reject) => {
    const img = document.createElement('img');
    img.src = src;
    img.onload = (e) => {
      resolve(e);
      img.remove();
    };
    img.onerror = () => {
      reject();
      img.remove();
    };
  });
};
