import { ComponentType } from 'react';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd5';
import RU from 'antd5/locale/ru_RU';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyle from 'theme/globalStyles';
import muiTheme from 'theme/muiTheme';
import { theme } from 'theme/theme';

import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

const StyledToastContainer = styled(ToastContainer)`
  width: auto;
  max-width: min(800px, calc(100vw - 2rem));
  width: 100%;
  z-index: 9999;
  .Toastify__toast {
    background-color: ${({ theme }) => theme.persianBlue};
    color: ${({ theme }) => theme.white};
    padding: 16px;
    min-height: auto;
    width: 100%;
    &-container {
      min-width: 650px;
    }
  }

  .Toastify__toast-body {
    padding: 0px;
    padding-right: 12px;
    margin: 0;
  }

  .Toastify__toast-icon {
    display: none;
    align-self: flex-start;
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.strawberryRed};
    color: ${({ theme }) => theme.white};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.americanGreen};
  }
  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.mangoTango};
  }

  svg {
    fill: ${({ theme }) => theme.white};
  }
`;

export const withTheme = (Component: ComponentType<{}>) => () => {
  return (
    <ConfigProvider locale={RU}>
      <ThemeProvider theme={theme}>
        <MUIThemeProvider theme={muiTheme}>
          <GlobalStyle theme={theme} />
          <StyledToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={3}
          />
          <Component />
        </MUIThemeProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};
