import styled, { css } from 'styled-components';

const BaseRootStyles = css`
  background: none;
  .ant-table-row {
    &:not(:last-child) {
      .ant-table-cell {
        border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
      }
    }
  }
`;

export const Root = styled.tbody`
  ${BaseRootStyles}
`;

export const RootVirtual = styled.div`
  ${BaseRootStyles}
`;
