import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const Root = styled.div<{ isAboveAll?: boolean }>`
  ${({ isAboveAll }) =>
    isAboveAll &&
    `
    position: absolute;
    z-index: 2;
    left: 0;
  `}
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.persianBlue};
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 26px;

  span {
    margin-left: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: ${({ theme }) => theme.white};
  }
`;

export const NotifBtn = styled(Button)`
  background: white;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.009em;
  padding: 6px 20px;
  gap: 12px;
  height: 32px;

  color: #0f0f13;
`;
