import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Paper = styled.div`
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 20px;
  padding: 0 4px 16px;
  height: fit-content;
  max-height: 100vh;
  width: 100%;
  max-width: calc(100vw - 32px);

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Scrollblock = styled.div`
  width: 100%;
  border-radius: 20px;
  max-height: 330px;
  overflow: auto;
`;

export const Title = styled.p`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.black};
  padding: 16px 14px;
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  margin-bottom: 0;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 22px 22px 1px;
`;

export const NoDataDescription = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.majesticMount};
`;

export const FindedChannels = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* White Theme / Gray - 5 */

  color: #7e7e8f;
  margin-left: 8px;
`;

export const IconNotFound = styled.span`
  font-size: 18px;
  line-height: 18px;
`;
