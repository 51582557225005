import { Login } from 'fsd/widgets/Login';

import * as S from './styles';

const LoginPage = () => {
  return (
    <S.Root>
      <Login />
    </S.Root>
  );
};

export default memo(LoginPage);
