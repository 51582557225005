export type CustomRowTypes = 'loader' | 'loader-clear' | 'empty';

export type CustomRow<T extends object> = {
  type: CustomRowTypes;
} & T;

type Data = {
  key: string;
};

export default <T extends Data>(type: CustomRowTypes, data: T): CustomRow<T> => ({ ...data, type });
