import { calcChannelCoverage } from 'fsd/entities/channel';

import { TableDataContext } from './tableDataContext';

export default (channelId: string) => {
  const { avgDailyReachesMetrics } = useContext(TableDataContext) || {};
  const views = useMemo(
    () => avgDailyReachesMetrics?.data?.channels?.find((item) => item.channel_id === channelId),
    [avgDailyReachesMetrics?.data?.channels, channelId]
  );

  const value = useMemo(() => calcChannelCoverage(views), [views]);

  return value;
};
