import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const StyledCatalogTabs = styled.div<{ $minTabWidth: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  .ant-tabs {
    flex: 1;
  }
  .ant-tabs-nav {
    @media ${MEDIA_DEVICE.mobileL.to} {
      display: none;
    }
  }
  .ant-tabs-top {
    > .ant-tabs-nav {
      margin: 0;
    }
  }

  .ant-tabs-nav-more {
    padding: 0 !important;
  }

  .ant-tabs-nav-list {
    display: flex;
    overflow: hidden;
    height: 48px;
    background-color: ${({ theme }) => theme.alpineGoat};
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: unset;
    visibility: unset;
    background-color: ${({ theme }) => theme.alpineGoat};
  }

  .ant-tabs-tab {
    height: min-content;
    position: relative;
    background-color: ${({ theme }) => theme.alpineGoat};
    max-width: 289px;
    min-width: ${({ $minTabWidth }) => $minTabWidth}px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    flex: 1;

    &:first-of-type {
      min-width: 200px;
      justify-content: center;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 75px;
      height: 40px;
      background: linear-gradient(270deg, ${({ theme }) => theme.alpineGoat} 25%, transparent 75%);
      pointer-events: none;
    }

    &:hover {
      .ant-tabs-tab-remove {
        visibility: visible;
        z-index: 1;
      }
    }

    + .ant-tabs-tab {
      &::before {
        border-left: 1px solid #d9d9d9;
        display: block;
        content: '';
        height: calc(100% - 20px);
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    &:nth-last-of-type(2) {
      &::after {
        display: block;
        content: '';
        height: calc(100% - 20px);
        margin-top: 10px;
        margin-bottom: 10px;
        border-right: 1px solid #d9d9d9;
      }
    }

    &.ant-tabs-tab-active {
      width: 100%;
      border-radius: 20px 20px 0 0;
      background-color: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.white};
      margin: 0;
      padding: 0;
      cursor: default;
      min-width: 289px;
      justify-content: center;

      &:first-of-type {
        min-width: 200px;
        width: initial;
      }

      &::before {
        display: none;
        border-left-color: transparent;
      }

      &::after {
        display: none;
      }

      + .ant-tabs-tab {
        &::before {
          display: none;
          border-left-color: transparent;
        }
      }

      .ant-tabs-tab-remove {
        top: 9px;
        right: 12px;
        padding: 4px;
        background-color: ${({ theme }) => theme.white};
      }
    }

    .anticon {
      margin-right: 0;
    }
  }

  .ant-tabs-tab-wrapper {
    max-width: 100%;
  }

  .ant-tabs-tab-heading {
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-tabs-tab-remove {
    position: absolute;
    margin: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    visibility: hidden;
    top: 8px;
    right: 8px;
  }

  .ant-tabs-content-holder {
    padding: 36px 40px;
    background-color: ${({ theme }) => theme.white};
    min-height: calc(100vh - 88px);
    @media ${MEDIA_DEVICE.mobileL.to} {
      min-height: 0;
      padding: 0;
      background: none;
    }
  }
`;
