import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: ${({ theme }) => theme.white};
  border-radius: 100px;
  gap: 10px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;

  @media ${MEDIA_DEVICE.mobileL.to} {
    max-width: max-content;
    padding: 5px 16px;
  }
`;
