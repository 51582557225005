import { ComponentProps, FC } from 'react';

import * as S from './Tabs.styles';

type Props = ComponentProps<(typeof S)['Root']> & ComponentProps<(typeof S)['Tabs']>;

const Tabs: FC<Props> = ({ style, className, ...restProps }) => {
  return (
    <S.Root style={style} className={className}>
      <S.Tabs {...restProps} />
    </S.Root>
  );
};

export default Tabs;
