/* eslint-disable no-unused-vars */
import { FC, MouseEventHandler } from 'react';
import { useAddOrCreateCart } from 'fsd/entities/cart';
import { Button } from 'fsd/shared/ui/Buttons';

type Props = {
  channelId: string;
  formatId?: number;
};

const Add: FC<Props> = ({ channelId, formatId }) => {
  const [addOrCreate, { isLoading }] = useAddOrCreateCart();
  const isDisabled = !channelId || !formatId;

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (isDisabled) return;
      addOrCreate({ channelId, formatId });
    },
    [addOrCreate, channelId, formatId, isDisabled]
  );
  return (
    <Button
      size="small"
      type="primary"
      block
      onClick={handleClick}
      disabled={isDisabled || isLoading}
      loading={isLoading}
    >
      Добавить
    </Button>
  );
};

export default memo(Add);
