import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  @media ${MEDIA_DEVICE.mobileL.to} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }
`;
