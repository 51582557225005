import { ComponentProps, FC } from 'react';
import { ToastMessage } from 'fsd/shared/ui/Toast';

type Props = Partial<ComponentProps<typeof ToastMessage>>;

const SuccessToastMessage: FC<Props> = ({ title, ...restProps }) => {
  return <ToastMessage title={title ?? 'Оплата отправлена админу'} closable {...restProps} />;
};

export default memo(SuccessToastMessage);
