import { ColumnComponentExporter } from '../type';

import Cell from './Cell';
import Chart from './Chart';
import Title from './Title';
import Value from './Value';

export default {
  Title,
  Cell,
  Chart,
  Value
} satisfies ColumnComponentExporter & { Chart: typeof Chart; Value: typeof Value };
