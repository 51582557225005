import styled from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: 5px;
  background-color: ${({ theme }) => theme.alpineGoat};
  padding: 0px 14px;
  height: 36px;

  cursor: pointer;
`;

export const LinkText = styled.span`
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.persianBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
