import styled from 'styled-components';

const Root = styled.span`
  font-size: 12px;
  font-weight: 400;
  white-space: normal;
  max-width: 100px;
  color: ${({ theme }) => theme.bayFog};
`;

const NotPublishedPlaceholder = () => <Root>появится после публикации</Root>;

export default NotPublishedPlaceholder;
