import { ChannelWithExchangeInfo } from 'api/api';
import { useStatistic } from 'fsd/shared/lib/hooks/useStatistic';

import { TableDataContext } from './tableDataContext';

export default (channel: ChannelWithExchangeInfo) => {
  const tableDataContext = useContext(TableDataContext);

  const channelMetricsDiff = useMemo(
    () =>
      tableDataContext?.participantsDiffMetrics?.data?.channels?.find(
        (item) => item.channel_id === channel.info.id
      ),
    [channel.info.id, tableDataContext?.participantsDiffMetrics?.data?.channels]
  );

  const channelParticipantsDiffMetrics = channelMetricsDiff?.history?.[0];

  const participants = useMemo(
    () =>
      tableDataContext?.channelMetrics?.data?.channels?.find(
        (item) => item.channel_id === channel.info.id
      ),
    [tableDataContext?.channelMetrics?.data?.channels, channel.info.id]
  );

  const { graphData: participantsGraphData, participantsAll } = useStatistic(
    participants,
    'PersianLineWithShadow'
  );

  return {
    tableData: tableDataContext,
    channelMetricsDiff,
    channelParticipantsDiffMetrics,
    participants,
    participantsGraphData,
    participantsAll
  };
};
