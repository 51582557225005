import { FC, useState } from 'react';
import {
  useLazyGetV1UserChannelFormatsQuery,
  usePatchV1UserChannelMutation,
  usePostV1UserChannelMutation
} from 'api/api';
import { URLs } from 'fsd/shared/config/URLs';
import { IconQuestion, IconTelegram } from 'fsd/shared/icons';
import { copyURL } from 'fsd/shared/lib/helpers/tools';
import { Button } from 'fsd/shared/ui/Buttons';
import { Icon } from 'fsd/shared/ui/Icon';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import ChannelSearch from 'components/ChannelSearch';
import { theme } from 'theme/theme';

import assistantScreenshot from '../assets/assistantScreenshot.png';
import assistantScreenshot_us from '../assets/assistantScreenshot_us.png';

import * as S from '../styles';

type Props = {
  setIsModalOpen: (state: boolean) => void;
};

const ChannelModal: FC<Props> = ({ setIsModalOpen }) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [bindChannelToUser] = usePostV1UserChannelMutation();
  const [formatsQuery] = useLazyGetV1UserChannelFormatsQuery();
  const [updateChannel] = usePatchV1UserChannelMutation();

  const currentLanguageUI = localStorage.getItem('ljs-lang');

  const handleChannelClick = useCallback(
    (channel: string) => {
      bindChannelToUser({
        body: {
          channel_id: channel
        }
      })
        .unwrap()
        .then((res) => {
          // Сразу заносим форматы в канал
          formatsQuery({
            formatsId: [1, 2, 3]
          })
            .unwrap()
            .then((formats) => {
              updateChannel({
                body: {
                  channel_id: channel,
                  exchange_info: {
                    channel_id: channel,
                    formats: formats.map((format) => {
                      return {
                        format_id: format.format_id,
                        is_active: false,
                        prices: []
                      };
                    })
                  }
                }
              })
                .unwrap()
                .then(() => {
                  CustomToast({
                    type: 'success',
                    message: <ToastMessage title="Ваш канал добавлен в Tgmarket!" />,
                    options: {
                      autoClose: 2000
                    }
                  });
                  setIsModalOpen(false);
                })
                .catch(() => {
                  CustomToast({
                    type: 'error',
                    message: <ToastMessage title="Не удалось добавить ваш канал в Tgmarket" />,
                    options: {
                      autoClose: 2000
                    }
                  });
                });
            });
        })
        .catch((err) => {
          const apiMessage = (err?.data as string) ?? '';

          let message = 'Что-то пошло не так. Попробуйте чуть позже';

          if (apiMessage.includes('check admin privileges of user')) {
            message = `Для подключения канала добавьте нашего бота в админы канала. В случае ошибки свяжитесь с поддержкой `;
          }

          if (apiMessage.includes('user is not admin')) {
            message =
              'Вы не можете добавить канал сюда, не являясь админом канала. В случае ошибки свяжитесь со службой поддержки';
          }

          CustomToast({
            type: 'error',
            message: <ToastMessage title={message} />,
            options: {
              autoClose: 2000
            }
          });
          setIsError(true);
        });
    },
    [bindChannelToUser, formatsQuery, setIsModalOpen, updateChannel]
  );

  return (
    <>
      <S.ModalBlock>
        <S.ModalBlockHeader>
          <div>Подключение бота</div>
          <S.StyledCascader
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={() => (
              <S.HelperBlock>
                <S.ModalHeader>Как должно быть в вашем канале</S.ModalHeader>
                <S.ImageContainer>
                  <S.StyledImage
                    src={currentLanguageUI === 'ru' ? assistantScreenshot : assistantScreenshot_us}
                    alt="assistantScreenshot"
                    language={currentLanguageUI}
                  />
                </S.ImageContainer>
              </S.HelperBlock>
            )}
          >
            <S.IconWrapper>
              <Icon width={16} color={theme.white}>
                <IconQuestion />
              </Icon>
            </S.IconWrapper>
          </S.StyledCascader>
        </S.ModalBlockHeader>

        <S.ModalBlockDescription>
          Для авторизации канала необходимо добавить бота{' '}
          <a href={URLs.TELESPACE_ASSISTANT_BOT} target="_blank" rel="noreferrer">
            Tgmarket Assistant
          </a>{' '}
          в администраторы канала <S.BoldText>только с правами на добавление участников</S.BoldText>
          . Это необходимо для подтверждения владения каналом.{' '}
          <S.BoldText>Никаких дополнительных прав не нужно</S.BoldText> <br />
        </S.ModalBlockDescription>
        <S.ModalBlockDescription>
          <S.BoldText>ВАЖНО:</S.BoldText> ООО “Телеспейс” не имеет возможности стать владельцами
          канала. Это запрещено самим мессенджером Telegram
        </S.ModalBlockDescription>
        <Button
          href="https://t.me/telespace_assistant_bot"
          target="_blank"
          type="primary"
          size="middle"
          block
        >
          <Icon width={20} color={theme.white}>
            <IconTelegram />
          </Icon>
          Перейти в Telegram
        </Button>
      </S.ModalBlock>

      <S.ModalBlock>
        <S.ModalBlockHeader>Поиск канала</S.ModalBlockHeader>
        <S.ModalBlockDescription>
          После добавления вставьте ник канала или ссылку на канал в поле поиска и выберите канал из
          списка
        </S.ModalBlockDescription>

        <ChannelSearch onChannelClick={handleChannelClick} showAsPopup={false} showAsInline />
      </S.ModalBlock>
    </>
  );
};

export default memo(ChannelModal);
