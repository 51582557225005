import { ComponentProps, FC } from 'react';

import Input from '../Input';

type Props = ComponentProps<typeof Input>;

const Form: FC<Props> = (restProps) => {
  return <Input {...restProps} />;
};

export default memo(Form);
