import { type ComponentProps } from 'react';
import { type CheckboxGroup } from 'fsd/shared/ui/Checkbox';

const items: ComponentProps<typeof CheckboxGroup>['items'] = [
  {
    key: 'channelInfo',
    label: 'Информация о канале',
    values: [
      {
        label: 'Название канала',
        value: 'channel_name'
      },
      {
        label: 'Ссылка на канал',
        value: 'channel_link'
      }
    ]
  },
  {
    key: 'channelStats',
    label: 'Статистика канала в Tgmarket',
    values: [
      {
        label: 'Количество подписчиков',
        value: 'channel_participants'
      },
      {
        label: (
          <>
            Прирост <small>(за вчера)</small>
          </>
        ),
        value: 'channel_participants_diff'
      },
      {
        label: (
          <>
            Средний охват <small>(за вчера)</small>
          </>
        ),
        value: 'channel_avg_reaches'
      },
      {
        label: (
          <>
            ERR <small>(за вчера)</small>
          </>
        ),
        value: 'channel_err'
      },
      {
        label: 'CPM',
        value: 'cpm'
      },
      {
        label: 'Цена|CPP',
        value: 'cpp'
      }
    ]
  },
  {
    key: 'containerParams',
    label: 'Параметры кампании',
    values: [
      {
        label: 'Дата и время публикации',
        value: 'date'
      },
      {
        label: 'Формат',
        value: 'format'
      },
      {
        label: 'Стоимость размещения',
        value: 'price'
      },
      {
        label: 'Статус заявки',
        value: 'status'
      },
      {
        label: 'Пригласительная ссылка',
        value: 'invite_link'
      },
      {
        label: 'Общая стоимость кампании',
        value: 'total_cpp'
      }
    ]
  },
  {
    key: 'totals',
    label: 'Итоговые показатели',
    values: [
      {
        label: 'Ссылка на пост',
        value: 'post_link'
      },
      {
        label: 'Количество просмотров',
        value: 'post_reaches'
      },
      {
        label: 'Новых подписчиков',
        value: 'new_participants'
      },
      {
        label: 'ERR поста',
        value: 'post_err'
      },
      {
        label: 'CPM итог',
        value: 'total_cpm'
      },
      {
        label: 'CPS итог',
        value: 'total_cps'
      }
    ]
  }
];

export default items;
