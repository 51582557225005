import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Tabs as _Tabs } from 'fsd/shared/ui/Tabs';
import styled from 'styled-components';

export const Root = styled.div`
  padding-top: var(--page-container-padding-y);
  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 0;
    padding-bottom: 20px;
  }
`;

export const HeaderWrapper = styled.div`
  padding: 0 var(--page-container-padding-x);
`;

export const Tabs: typeof _Tabs = styled(_Tabs)`
  margin-top: 40px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-top: 0;
  }
  .ant-tabs-nav {
    @media ${MEDIA_DEVICE.mobileL.to} {
      display: none;
    }
  }
`;
