import React from 'react';
import { Channel, Id, usePostV1ChannelsHistoricalMetricsMutation } from 'api/api';
import { useAppDispatch, useAppSelector } from 'fsd/app/redux/hooks';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';
import {
  addChannelToStack,
  changeButtonState
} from 'slices/channelPoolingStack/ChannelPoolingStackSlice';

export const useLoadHistoricalMetricsController = (
  channel: Channel | undefined,
  channelId: Id,
  now: string
) => {
  const dispatch = useAppDispatch();
  const [loadHistoricalMetrics] = usePostV1ChannelsHistoricalMetricsMutation();
  const { buttonsState } = useAppSelector((state) => state.channelPoolingStack);
  const buttonState = buttonsState?.[channelId];

  const handleButtonClick = async () => {
    if (buttonState === 'loading') {
      return;
    }
    dispatch(
      changeButtonState({
        id: channelId,
        state: 'loading'
      })
    );
    CustomToast({
      type: 'info',
      message: (
        <ToastMessage
          title="Данные по каналу загружаются"
          description="Вы можете продолжать работу. Мы сообщим вам о завершении загрузки"
        />
      )
    });
    await loadHistoricalMetrics({
      body: {
        channel_id: channelId
      }
    });
    dispatch(
      addChannelToStack({
        id: channelId,
        title: channel?.title || '',
        time: now
      })
    );
  };

  return {
    isShowButton: channel?.historical_metrics_loaded === false && buttonState !== 'hide',
    isButtonLoading: buttonState === 'loading',
    handleButtonClick,
    historical_metrics_loaded: channel?.historical_metrics_loaded
  };
};
