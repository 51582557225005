import { FC, MouseEventHandler, PropsWithChildren } from 'react';

import * as S from './styles';

const Panel: FC<PropsWithChildren> = ({ children }) => {
  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => e.stopPropagation(),
    []
  );
  return <S.Root onClick={handleClick}>{children}</S.Root>;
};

export default Panel;
