import { Modal } from 'antd5';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconClose } from 'fsd/shared/icons';
import { MergeComponentWithProps } from 'fsd/shared/types/MergeComponentWithProps';
import styled from 'styled-components';

type RootProps = {
  background?: string;
  paddingSize?: 'l' | 'm';
};

type RootPaddingMapResult = {
  desktop: [number, number];
  mobile: [number, number];
};

const rootPaddingMap = new Map<RootProps['paddingSize'], RootPaddingMapResult>([
  [
    'l',
    {
      desktop: [60, 40],
      mobile: [24, 26]
    }
  ],
  [
    'm',
    {
      desktop: [40, 40],
      mobile: [20, 20]
    }
  ]
]);

export const Root: MergeComponentWithProps<typeof Modal, RootProps> = styled(Modal)<RootProps>`
  --paddingY: ${({ paddingSize }) => `${rootPaddingMap.get(paddingSize)?.desktop[0] ?? 24}px`};
  --paddingX: ${({ paddingSize }) => `${rootPaddingMap.get(paddingSize)?.desktop[1] ?? 16}px`};

  @media ${MEDIA_DEVICE.mobileL.to} {
    --paddingY: ${({ paddingSize }) => `${rootPaddingMap.get(paddingSize)?.mobile[0] ?? 24}px`};
    --paddingX: ${({ paddingSize }) => `${rootPaddingMap.get(paddingSize)?.mobile[1] ?? 16}px`};
    padding-bottom: 0;
    vertical-align: bottom !important;
    margin: 0 auto;
  }
  .ant-modal-content {
    padding: var(--paddingY) 0;
    border-radius: 20px;
    background-color: ${({ theme, background }) => background ?? theme.alpineGoat};
    @media ${MEDIA_DEVICE.mobileL.to} {
      border-radius: 20px 20px 0 0;
    }
  }
  .ant-modal-body {
    padding: 0 var(--paddingX);
  }
  .ant-modal-close {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25px;
    right: 16px;
    &-x {
      width: 100%;
      height: 100%;
      line-height: 100%;
    }
  }
  .ant-modal-header {
    padding: 0 var(--paddingX);
    margin-bottom: 16px;
    background: none;
    line-height: 100%;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: ${({ theme }) => theme.blackGrey};
`;

export const Content = styled.div``;

export const CloseButton = styled(IconClose)``;
