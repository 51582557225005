import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconSortFlat } from 'fsd/shared/icons';
import { isSafari } from 'fsd/shared/lib/helpers/constants/browserDetection';
import styled, { css } from 'styled-components';

export const TableWrap = styled.div`
  position: relative;
  clear: both;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  box-shadow:
    0px 100px 80px rgb(195 194 209 / 7%),
    0px 41.7776px 33.4221px rgb(195 194 209 / 5%),
    0px 22.3363px 17.869px rgb(195 194 209 / 4%),
    0px 12.5216px 10.0172px rgb(195 194 209 / 4%),
    0px 6.6501px 5.32008px rgb(195 194 209 / 3%),
    0px 2.76726px 2.21381px rgb(195 194 209 / 2%);

  @media ${MEDIA_DEVICE.mobileL.to} {
    box-shadow: none;
  }
`;

export const Table = styled.table`
  position: relative;
  clear: both;
  max-width: 100%;

  width: 100%;
  table-layout: fixed;
  border-spacing: 0px 8px;
  border-collapse: separate;
  min-width: 1168px;

  ${isSafari &&
  `
    background-color: rgba(242, 243, 247, 0.6);
    transform: translate3d(0, -8px, 0);
    margin-bottom: -16px;
  `}

  a {
    border-radius: 6px;
  }

  td:first-child {
    border-radius: 6px 0px 0px 6px;
  }

  td:last-child,
  th:last-child {
    border-radius: 0 6px 6px 0;
  }

  th:first-child {
    border-radius: 3px 0px 0px 3px;
  }
  th:last-child {
    border-radius: 0 3px 3px 0;
  }

  overflow: auto;

  @media ${MEDIA_DEVICE.mobileL.to} {
    min-width: auto;
    border-spacing: 0px 1px;
    table-layout: auto;

    td:first-child {
      border-radius: 0;
    }

    td:last-child,
    th:last-child {
      border-radius: 0;
    }

    th:first-child {
      border-radius: 0;
    }
    th:last-child {
      border-radius: 0;
    }
  }
`;

export const TableCaption = styled.div`
  display: table-caption;
  caption-side: bottom;
`;

export const Thead = styled.thead``;

export const TheadTR = styled.tr`
  box-shadow:
    0px 100px 80px rgba(195, 194, 209, 0.07),
    0px 41.7776px 33.4221px rgba(195, 194, 209, 0.0503198),
    0px 22.3363px 17.869px rgba(195, 194, 209, 0.0417275),
    0px 12.5216px 10.0172px rgba(195, 194, 209, 0.035),
    0px 6.6501px 5.32008px rgba(195, 194, 209, 0.0282725),
    0px 2.76726px 2.21381px rgba(195, 194, 209, 0.0196802);
`;

export const StaticTH = styled.th`
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.white};

  display: table-cell;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.londonGrey};
  background-color: ${({ theme }) => theme.white};
  padding: 0 0;
  height: 42px;

  &:first-of-type {
    width: 21.16%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    padding-left: 21px;
  }

  &:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0 16px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }

  vertical-align: middle;
`;

export const TH = styled.th`
  display: table-cell;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.londonGrey};
  background-color: ${({ theme }) => theme.white};
  padding: 0 0;
  height: 42px;

  &:last-of-type {
    width: 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0;
    transform: translateX(-16px);
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }

  vertical-align: middle;
`;

export const Cell = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.londonGrey};
  padding: 0 0;
`;

export const TableWrapper = styled.div`
  margin: 0;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin: 16px -16px 0px -16px;
    background-color: ${({ theme }) => theme.white};
    padding: 0 16px 16px;
    border-radius: 20px;
  }
`;

export const FooterPagination = styled.div`
  position: relative;
  top: 48px;
`;

export const PageTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.blackGrey};
  letter-spacing: 0.3px;
`;

export const TitleWrapper = styled.div`
  justify-content: space-between;

  padding-top: 24px;
  padding-bottom: 8px;

  display: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: flex;
  }
`;
export const MobileTableActions = styled.div`
  display: none;
  align-items: center;
  gap: 9px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: flex;
  }
`;

export const DesktopTableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  justify-content: flex-end;
`;

export const SortButton = styled.button`
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ThIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const StyledArrowDown = styled(IconSortFlat)<{
  $sortedDesc: boolean;
  $active: boolean;
}>`
  transform: scaleY(${({ $sortedDesc }) => ($sortedDesc ? 1 : -1)});
  transition: 0.2s ease-in-out;
  color: ${({ theme, $active }) => ($active ? theme.persianBlue : theme.violetPowder)};
`;

export const LoaderRow = styled.div``;

export const LoaderWrapper = styled.div`
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
`;
