import { FC } from 'react';
import { Table } from 'fsd/shared/ui/Table';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  const { coverage } = purchase?.common_purchase_data || {};
  return typeof coverage !== 'undefined' ? coverage.toLocaleString('ru') : <Table.NoCellData />;
};

export default memo(Cell);
