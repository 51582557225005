import { createContext, FC, PropsWithChildren } from 'react';

import useListData, { UseListDataOptions } from './useListData';

export const ListContext = createContext<ReturnType<typeof useListData> | null>(null);

export const ListContextProvider: FC<PropsWithChildren<UseListDataOptions>> = ({
  children,
  filter
}) => {
  const listData = useListData({ filter });
  return <ListContext.Provider value={listData}>{children}</ListContext.Provider>;
};
