import { HTMLAttributes, MouseEventHandler, PropsWithChildren } from 'react';
import { Dropdown } from 'antd5';
import { ChannelWithExchangeInfo } from 'api/api';
import { Table } from 'fsd/shared/ui/Table';
import styled from 'styled-components';

import ContextMenu from '../../Row/components/ContextMenu';
import { useLocationController } from '../../Row/hooks';
import { RowDataContext } from '../lib/rowDataContext';
import useRowData from '../lib/useRowData';

type Props = {
  channel: ChannelWithExchangeInfo;
} & HTMLAttributes<HTMLTableRowElement>;

const StyledRow = styled(Table.VirtualRow)`
  cursor: pointer;
`;

const BodyChannelRow = memo(
  forwardRef<HTMLTableRowElement, PropsWithChildren<Props>>(
    ({ children, channel, onClick, ...restProps }, ref) => {
      const rowData = useRowData(channel);

      const handleClick: MouseEventHandler<HTMLTableRowElement> = useCallback(
        (e) => {
          onClick?.(e);
        },
        [onClick]
      );

      const {
        openActiveBrowserTab,
        openActiveServiceTab,
        openNotActiveServiceTab,
        openActiveBrowserWindow,
        handleNavigation
      } = useLocationController({
        // @ts-ignore
        onRowClick: () => handleClick(new Event('click')),
        channel: channel.info
      });

      return (
        <RowDataContext.Provider value={rowData}>
          <Dropdown
            trigger={['contextMenu']}
            destroyPopupOnHide
            overlayClassName="context-dropdown-menu"
            menu={{
              items: ContextMenu({
                channel: channel.info,
                openActiveBrowserTab,
                openActiveServiceTab,
                openNotActiveServiceTab,
                openActiveBrowserWindow
              })
            }}
          >
            <StyledRow {...restProps} onClick={handleNavigation} ref={ref}>
              {children}
            </StyledRow>
          </Dropdown>
        </RowDataContext.Provider>
      );
    }
  )
);

const BodyRow = forwardRef<HTMLTableRowElement, PropsWithChildren<Props>>(
  ({ children, channel, ...restProps }, ref) => {
    return 'info' in channel ? (
      <BodyChannelRow ref={ref} channel={channel} {...restProps}>
        {children}
      </BodyChannelRow>
    ) : (
      <Table.CustomRow ref={ref} customRow={channel} {...restProps} />
    );
  }
);

export default memo(BodyRow);
