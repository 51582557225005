import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Id } from 'api/api';

import { catalogTabId } from 'pages/purchaseList/components/PurchaseTabs/constants';
import { getChannelId } from 'pages/purchaseList/components/PurchaseTabs/utils/get-channel-id';

type PurchaseTabsState = {
  initialized: boolean;
  activeTabId: string;
  visibleEnd: number;
  purchaseIds: Id[];
};

const initialState: PurchaseTabsState = {
  initialized: false,
  activeTabId: catalogTabId,
  visibleEnd: 0,
  purchaseIds: []
};

export const channelTabsSlice = createSlice({
  name: 'purchaseTabs',
  initialState,
  reducers: {
    initialize: (state, action: PayloadAction<string[]>) => {
      const purchaseIds = action.payload;

      state.purchaseIds = purchaseIds;
      state.initialized = true;
    },

    openTab: (state, action: PayloadAction<{ tabId: string; openInBackground?: boolean }>) => {
      const { tabId, openInBackground } = action.payload;

      if (tabId === catalogTabId) {
        state.activeTabId = tabId;
        return;
      }

      const channelId = getChannelId(tabId);

      if (!state.purchaseIds.includes(channelId)) {
        if (state.activeTabId === catalogTabId) {
          state.purchaseIds.unshift(channelId);
        } else {
          const activeChannelId = getChannelId(state.activeTabId);
          const activeChannelIndex = state.purchaseIds.findIndex(
            (channelId) => channelId === activeChannelId
          );

          state.purchaseIds.splice(activeChannelIndex, 0, channelId);
        }
      }

      if (!openInBackground) {
        state.activeTabId = tabId;
      }
    },

    closeTab: (state, action: PayloadAction<{ tabId: string }>) => {
      const { tabId } = action.payload;
      const channelId = getChannelId(tabId);

      state.purchaseIds = state.purchaseIds.filter((id) => id !== channelId);
    },

    closeAllTabs: (state) => {
      state.purchaseIds = [];
    },

    closeAllButOne: (state, action: PayloadAction<string>) => {
      const tabId = action.payload;
      const channelId = getChannelId(tabId);

      state.purchaseIds = [channelId];
    },

    setVisibleEnd: (state, action: PayloadAction<number>) => {
      state.visibleEnd = action.payload;
    }
  }
});

export const { openTab, initialize, closeTab, closeAllTabs, closeAllButOne, setVisibleEnd } =
  channelTabsSlice.actions;

export default channelTabsSlice.reducer;
