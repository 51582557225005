import { BaseExportData } from './types';

export default (fields: BaseExportData['fields']) => {
  const [initialSortedFields, setInitialSortedFields] = useState(fields.sort());
  const sortedFields = useMemo(() => fields.sort(), [fields]);
  const isEdited = useMemo(() => {
    return initialSortedFields.join() !== sortedFields.join();
  }, [sortedFields, initialSortedFields]);

  const update = useCallback((fields: BaseExportData['fields']) => {
    setInitialSortedFields(fields.sort());
  }, []);

  return { isEdited, update };
};
