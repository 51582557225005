import { useNavigate, useParams } from 'react-router-dom';

import { AddToPurchaseForm } from 'pages/channel/components/add-to-purchase';

import { useAuth } from '../../fsd/app/providers/Auth';
import { useChannelPageLogic } from '../channel/ChannelPage';

import { AuthModals } from './components/AuthModals';
import { MobileParticipantsBlock, MobileReachesBlock } from './components/DataBlocks';
import { Header } from './components/Header';

import * as S from './styles';

const MobileChannelPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const channelId = String(params.id);

  const { isAuth } = useAuth();

  const { channel, isLoadingChannel, nowTimestamp, chartRef } = useChannelPageLogic(channelId);

  const handleCancel = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }, [navigate]);

  return (
    <S.ChannelMobileModal
      open
      mask={false}
      maskClosable={false}
      onCancel={handleCancel}
      closable
      className="channel-modal no-borders hide-overflow"
    >
      <S.ChannelMobileModalBody>
        <S.CloseModalIcon onClick={handleCancel} />
        {!isAuth && <AuthModals />}
        {channel && <Header channel={channel} channelId={channelId} navigate={navigate} />}
        <MobileParticipantsBlock
          channel={channel?.info}
          isLoadingChannel={isLoadingChannel}
          channelId={channelId}
          now={nowTimestamp}
          chartRef={chartRef}
        />
        <MobileReachesBlock
          channel={channel?.info}
          isLoadingChannel={isLoadingChannel}
          channelId={channelId}
          now={nowTimestamp}
        />
        {/* <MobileMentionsBlock
        channel={channel?.info}
        isLoadingChannel={isLoadingChannel}
        channelId={channelId}
        now={nowTimestamp}
      /> */}
        {channel && (
          <S.ChannelMobilePriceWrapper>
            <AddToPurchaseForm channel={channel} layout="column" />
          </S.ChannelMobilePriceWrapper>
        )}
      </S.ChannelMobileModalBody>
    </S.ChannelMobileModal>
  );
};

export default memo(MobileChannelPage);
