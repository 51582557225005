import { FC, MouseEventHandler } from 'react';
import { Channel, ChannelExchangeInfo } from 'api/api';
import { ChannelDescription } from 'fsd/entities/channel/ui/ChannelDescription';
import { IconCopy, IconInfo } from 'fsd/shared/icons';
import { copyURL } from 'fsd/shared/lib/helpers/tools';
import Popover from 'fsd/shared/ui/LegacyUIComponents/Popover';

import * as S from './styles';
import { IconStyles } from './styles';

type Props = {
  channel: Channel;
  exchangeInfo?: ChannelExchangeInfo;
  onInfoClick?: MouseEventHandler<HTMLButtonElement>;
};

const ChannelActions: FC<Props> = ({ channel, onInfoClick, exchangeInfo }) => {
  const popupContainerRef = useRef<HTMLDivElement>(null);
  if (!channel) return null;

  return (
    <S.Root
      className="usiteful__catalog-tab-actions channel-actions"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {/* {exchange_info?.is_exchange_active && (
        <Popover placement="bottom" content="Добавить в список кампании">
          <Dropdown
            trigger={["click"]}
            overlay={
              <TabsContextMenu
                setIsDropdownVisible={setIsDropdownVisible}
                channelId={[channel.id]}
                actionType={"single"}
              />
            }
            open={isDropdownVisible}
            onOpenChange={() => setIsDropdownVisible((prevState) => !prevState)}
          >
            <button className="usiteful__catalog-tab-icon-add">
              <IconStyles>
                <IconCartAdd />
              </IconStyles>
            </button>
          </Dropdown>
        </Popover>
      )} */}
      {onInfoClick ? (
        <Popover
          placement="bottom"
          destroyTooltipOnHide
          content="Информация о канале"
          getPopupContainer={() => popupContainerRef.current ?? document.body}
        >
          <button
            aria-label="info"
            type="button"
            className="usiteful__catalog-tab-icon-info"
            onClick={onInfoClick}
          >
            <IconStyles>
              <IconInfo />
            </IconStyles>
          </button>
        </Popover>
      ) : (
        <ChannelDescription
          showHeader={false}
          trigger={['click']}
          channel={channel}
          exchangeInfo={exchangeInfo}
        >
          <button aria-label="info" type="button" className="usiteful__catalog-tab-icon-info">
            <IconStyles>
              <IconInfo />
            </IconStyles>
          </button>
        </ChannelDescription>
      )}
      {channel.name && (
        <Popover placement="bottom" destroyTooltipOnHide content="Скопировать ссылку на канал">
          <button
            aria-label="copy"
            className="usiteful__catalog-tab-icon-copy"
            onClick={(e) => {
              e.preventDefault();
              copyURL(e, `https://t.me/${channel.name}`, 'Ссылка на канал скопирована');
            }}
            type="button"
          >
            <IconStyles>
              <IconCopy />
            </IconStyles>
          </button>
        </Popover>
      )}

      {/* Контакты отсутствуют в релизе 1.0.0 */}
      {/* <Popover placement="bottom" content="Контакты">
        <Link
          to={`/channel/${channel.id}/contacts`}
          state={{ background: location }}
        >
          <Icon width={18} color={theme.persianBlue}>
            <IconContacts />
          </Icon>
        </Link>
      </Popover> */}

      {/* <Popover placement="bottom" content="Посты">
        <Link
          to={`/channel/${channelId}/posts`}
          state={{ background: location }}
        >
          <Icon width={18} color={theme.persianBlue}>
            <IconPosts />
          </Icon>
        </Link>
      </Popover> */}
      <div ref={popupContainerRef} />
    </S.Root>
  );
};

export default memo(ChannelActions);
