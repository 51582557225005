import { DatePicker } from 'antd5';
import styled from 'styled-components';

export const Root: typeof DatePicker = styled(DatePicker)`
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  color: inherit !important;
  .ant-picker-dropdown {
    position: static;
  }
  .ant-picker-panel-container {
    box-shadow: none;
  }
  .ant-picker-input {
    display: none;
  }
`;
