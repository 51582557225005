import { useGetV1UserQuery } from 'api/api';
import { getUserOrganisationId } from 'fsd/entities/user';
import { AddUserToTeam } from 'fsd/features/addUserToTeam';

import Layout from 'pages/settings/components/Layout';
import OrganisationFallback from 'pages/settings/components/OrganisationFallback';

const Content = () => {
  const { data: user } = useGetV1UserQuery();
  const orgId = user && getUserOrganisationId(user, 'crm');
  return (
    <Layout.Content>
      <Layout.Title>Добавление в команду</Layout.Title>
      {orgId ? (
        <AddUserToTeam />
      ) : (
        <OrganisationFallback
          content="Добавьте организацию, чтобы создать команду
для совместной работы"
        />
      )}
    </Layout.Content>
  );
};

export default Content;
