import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'fsd/app/redux/hooks';

import { initialize } from '../../../../slices/channelTabs/ChannelTabsSlice';
import { initialize as initializePurchase } from '../../../../slices/purchaseTabs/PurchaseTabsSlice';

export const useSyncStateAndSearchParams = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const [searchParams, setSeachParams] = useSearchParams();
  const { channelIds, initialized } = useAppSelector((state) => state.channelTabs);
  const { purchaseIds, initialized: purchaseInitialized } = useAppSelector(
    (state) => state.purchaseTabs
  );

  // Хук заполняет channelIds Redux, если в searcParams они есть, а в channelIds нету
  useEffect(() => {
    const channelTabs = searchParams.get('channelTab');
    const purchaseTabs = searchParams.get('purchaseTab');

    if (!initialized && channelTabs) {
      const channelTabValue = JSON.parse(channelTabs);
      dispatch(initialize([...channelTabValue]));
    }

    if (!purchaseInitialized && purchaseTabs) {
      const purchaseTabValue = JSON.parse(purchaseTabs);
      dispatch(initializePurchase([...purchaseTabValue]));
    }
  }, [searchParams]);

  const syncSearchParamsByReduxState = (
    key: string,
    searchParams: URLSearchParams,
    ids: string[],
    initialize: any,
    initialized: boolean
  ) => {
    const tabs = searchParams.get(key);
    let tabsArray = [];
    if (tabs) {
      tabsArray = JSON.parse(tabs);
    }

    // При клике на табы, они добавляются в redux channelIds, но никак не дают флаг инициализации, даже если объекты есть)
    if (!initialized && ids.length > 0) {
      dispatch(initialize(ids));
    }

    const shouldUpdateSearchParams = initialized && (!tabs || ids.length !== tabsArray.length);

    if (shouldUpdateSearchParams) {
      setSeachParams(
        (prevSearchParams) => {
          const params = new URLSearchParams(prevSearchParams); // Создаем новый объект URLSearchParams на основе предыдущих параметров поиска
          params.set(key, JSON.stringify(ids)); // Устанавливаем значение параметра 'channelTab' в виде строки JSON
          return `?${params.toString()}`; // Преобразуем обновленные параметры поиска обратно в строку
        },
        {
          replace: true, // Чтобы не усложнять концепцию navigate -1, перезаписываем текущее значение
          state: location.state // Наследуем background из предыдущего location, иначе сломаем location т.к мы его перезаписываем
        }
      );
    }
  };

  // Хук заполняет searchParams из channelIds Redux, если в channelIds есть объекты, а в searchParam их нету
  useEffect(() => {
    syncSearchParamsByReduxState('channelTab', searchParams, channelIds, initialize, initialized);
  }, [searchParams, initialized, channelIds]);
  useEffect(() => {
    syncSearchParamsByReduxState(
      'purchaseTab',
      searchParams,
      purchaseIds,
      initializePurchase,
      purchaseInitialized
    );
  }, [searchParams, purchaseInitialized, purchaseIds]);
};
