import { ComponentProps, FC } from 'react';
import { FormatSelect } from 'fsd/entities/format';

import getFormatFromLocalFile from '../../lib/getFormatFromLocalFile';

type Props = {
  channelLink: string;
  value?: number;
  // eslint-disable-next-line no-unused-vars
  onSelect?: (id: number, format?: ComponentProps<typeof FormatSelect>['formats'][0]) => void;
} & Omit<
  ComponentProps<typeof FormatSelect>,
  'formats' | 'value' | 'onSelect' | 'pricePlaceholder'
>;

const defaultFormatNames = ['1/24', '1/нед', '1/мес'];

const defaultFormats: ComponentProps<typeof FormatSelect>['formats'] = defaultFormatNames.map(
  (name, index) => ({
    currency: 1,
    format: name,
    format_id: index + 1,
    price: '',
    priceNumber: 0
  })
);

const LocalFormatSelect: FC<Props> = ({ channelLink, onSelect, value, ...restProps }) => {
  const localData = useDeferredValue(getFormatFromLocalFile(channelLink));

  const formats: ComponentProps<typeof FormatSelect>['formats'] = useMemo(
    () =>
      localData
        ? [
            {
              currency: 1,
              format: localData?.name ?? '',
              format_id: 1,
              price: localData?.price ? localData.price.toString() : '',
              priceNumber: localData?.price ?? 0
            }
          ]
        : defaultFormats,
    [localData]
  );

  const handleSelect = useCallback(
    (id: number) => {
      onSelect?.(
        id,
        formats.find((f) => f.format_id === id)
      );
    },
    [formats, onSelect]
  );

  useEffect(() => {
    handleSelect(value ?? 1);
  }, [handleSelect, value]);

  return <FormatSelect formats={formats} value={value} {...restProps} onSelect={handleSelect} />;
};

export default memo(LocalFormatSelect);
