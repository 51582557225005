/* eslint-disable no-nested-ternary */
import { FC, ReactNode } from 'react';

import { FindItem } from '../../../lib/find/useFind';

import * as S from './Label.styles';

type Props = Pick<FindItem, 'icon' | 'type'> & {
  name: ReactNode;
};
const Label: FC<Props> = ({ name, icon, type }) => {
  return (
    <S.Root>
      <S.Icon icon={icon} internet={type === 'external_adv'} />
      {typeof name === 'string' ? <S.Name>{name}</S.Name> : name}
    </S.Root>
  );
};

export default memo(Label);
