import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IconHouse, IconInfo } from 'fsd/shared/icons';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { Icon } from 'fsd/shared/ui/Icon';
import { LayoutContent } from 'fsd/shared/ui/Layout';
import { HeaderMobileWidget } from 'fsd/widgets/Header';

import { useGetV1UserQuery } from '../../api/api';

import AccountInfo from './components/AccountInfo';
import { SettingsTeamNavLink } from './components/pages/team';
import SettingsNavLink from './components/SettingsNavLink';

import * as S from './styles';

const SettingsPage = () => {
  const isMobile = useIsMobile();
  const { data: user } = useGetV1UserQuery();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/settings') {
      navigate('general');
    }
  }, [location, navigate]);

  return (
    <>
      <HeaderMobileWidget>
        <AccountInfo user={user} />
      </HeaderMobileWidget>
      <LayoutContent>
        <S.Centered>
          <S.Wrapper>
            {!isMobile && <AccountInfo user={user} />}
            <S.WrapperContent>
              <S.SideBar>
                <S.Nav>
                  <SettingsNavLink to="general">
                    <Icon width={24} color="">
                      <IconInfo />
                    </Icon>

                    <S.NavText>Общие</S.NavText>
                  </SettingsNavLink>

                  <SettingsNavLink to="about_company">
                    <Icon width={24} color="">
                      <IconHouse />
                    </Icon>

                    <S.NavText>Организация</S.NavText>
                  </SettingsNavLink>
                  <SettingsTeamNavLink />
                </S.Nav>
              </S.SideBar>

              <S.Body>
                <Outlet />
              </S.Body>
            </S.WrapperContent>
          </S.Wrapper>
        </S.Centered>
      </LayoutContent>
    </>
  );
};

export default memo(SettingsPage);
