import { BalanceMultipleCardListWidget } from 'fsd/widgets/BalanceMultipleCard';

import * as S from './FinancesContent.styles';

const FinancesContent = () => {
  return (
    <S.Root>
      <BalanceMultipleCardListWidget />
    </S.Root>
  );
};

export default memo(FinancesContent);
