import { Link } from 'react-router-dom';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  background: #ffffff;
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 20px;
  padding: 60px 85px;
  margin: auto;
  text-align: center;
  max-width: 590px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 20px;
  }
`;

export const Img = styled.div`
  margin-bottom: 24px;
  width: 220px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 180px;
    height: 190px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #4807ea;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #2e2e3a;
  text-align: center;
  margin-bottom: 16px;
`;

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.blueRibbon};

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  border-bottom: 1px solid ${({ theme }) => theme.blueRibbon};
  cursor: pointer;
`;
