import { FC } from 'react';
import { cutNum } from 'fsd/shared/lib/helpers/tools';
import { Table } from 'fsd/shared/ui/Table';
import isOnePurchasePublished from 'fsd/widgets/ContainersList/lib/isOnePurchasePublished';
import reducePurchasesCpmTotal from 'fsd/widgets/ContainersList/lib/reducePurchasesCpmTotal';

import { DefaultColumnCellProps } from '../../../types';
import NotPublishedPlaceholder from '../../NotPublishedPlaceholder';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ record }) => {
  const cpmTotal = useMemo(() => reducePurchasesCpmTotal(record.purchases), [record.purchases]);
  const isPublished = useMemo(() => isOnePurchasePublished(record.purchases), [record.purchases]);

  if (!isPublished) return <NotPublishedPlaceholder />;
  return cpmTotal ? `${cutNum(cpmTotal)} ₽` : <Table.NoCellData />;
};

export default memo(Cell);
