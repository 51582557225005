import React, { FC, memo, useEffect, useMemo } from 'react';
import {
  HistoryStatistic,
  useGetV1ChannelsByChannelIdPostsAndPostIdQuery,
  useGetV1ChannelsByChannelIdPostsAndPostIdTypeQuery,
  useGetV1ChannelsByChannelIdQuery
} from 'api/api';

import { ToastMessage } from '../../fsd/shared/ui/Toast';
import CustomToast from '../../fsd/shared/ui/Toast/customToast';

import { useGetDateRangeFromPostType } from './hooks';
import { PostContentWithLoader } from './PostContent';

type Props = {
  channelId: string;
  postId: number;
  compact?: boolean;
  withoutShadow?: boolean;
};

// @TODO remove after testing
declare global {
  interface Window {
    custom_post_type: any;
    custom_compact_type: any;
  }
}

const SinglePost: FC<Props> = ({ compact, channelId, postId, withoutShadow }) => {
  const {
    data: channel,
    error: errorChannel,
    isError: isErrChannel
  } = useGetV1ChannelsByChannelIdQuery({ channelId });
  const {
    data: post,
    error: errorPost,
    isError: isErrPost
  } = useGetV1ChannelsByChannelIdPostsAndPostIdQuery({
    channelId,
    // todo: fix
    postId
  });

  const type = window.custom_post_type || {
    is_repost: false,
    is_mention: false,
    is_advertising: false,
    is_collection: false
  }; // Затычка чтобы понять рекламный пост или нет, как это появится в инфе о посте заменить

  const now = useMemo(() => new Date(), []);
  const isAdvChart = true;
  const { from, to } = useGetDateRangeFromPostType(post, now, isAdvChart);

  const {
    data: subscribes = generateRandomGraphData(isAdvChart),
    error: errorSubscribes,
    isError: isErrSubscribes
  } = useGetV1ChannelsByChannelIdPostsAndPostIdTypeQuery(
    {
      postId: post?.post_id || 0,
      channelId: post?.channel_id || '',
      type: 'subscribes',
      dateRange: {
        from,
        to
      },
      timeZone: 'UTC'
    },
    {
      skip: !post || !post.post_id || !post.channel_id
    }
  );

  const {
    data: views = generateRandomGraphData(isAdvChart, true),
    error: errorViews,
    isError: isErrViews
  } = useGetV1ChannelsByChannelIdPostsAndPostIdTypeQuery(
    {
      postId: post?.post_id || 0,
      channelId: post?.channel_id || '',
      type: 'views',
      dateRange: {
        from,
        to
      },
      timeZone: 'UTC'
    },
    {
      skip: !post || !post.post_id || !post.channel_id
    }
  );

  // @TODO remove after getting data from API
  const isError = !(!isErrPost && !isErrChannel);

  useEffect(() => {
    if (isErrPost) errorHandler(errorPost);
    // if (isErrViews) errorHandler(errorViews);
    if (isErrChannel) errorHandler(errorChannel);
    // if (isErrSubscribes) errorHandler(errorSubscribes);
  }, [isErrPost, isErrChannel, isErrViews, isErrSubscribes]);

  function errorHandler(e: any) {
    if (e.status == 'FETCH_ERROR') {
      CustomToast({
        type: 'error',
        message: (
          <ToastMessage
            title="Ошибка"
            description={'Причина: нет соединения с\n' + '          интернетом'}
          />
        )
      });
    } else {
      CustomToast({
        type: 'error',
        message: (
          <ToastMessage
            title="Ошибка"
            description={`Код ошибки:
          ${e.originalStatus}`}
          />
        )
      });
    }
  }

  return (
    <PostContentWithLoader
      type={type}
      channel={channel}
      compact={window.custom_compact_type || compact}
      withoutShadow={withoutShadow}
      post={post}
      views={views}
      subscribes={subscribes}
      isAdvChart={isAdvChart}
      isError={isError}
    />
  );
};

function generateRandomGraphData(isAdvChart: boolean, isViews?: boolean): HistoryStatistic {
  const generateRandomNumberFromRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  if (!isAdvChart) {
    return {
      from: '2023-09-10T00:00:00.000Z',
      to: '2023-09-16T00:00:00.000Z',
      accuracy: 'day',
      history: Array.from({ length: 7 }, () => {
        return generateRandomNumberFromRange(2800, 3000);
      })
    };
  }
  return {
    from: '2023-09-10T13:00:00.000Z',
    to: '2023-09-11T13:00:00.000Z',
    accuracy: 'hour',
    history: Array.from({ length: generateRandomNumberFromRange(49, 49) }, () => {
      return isViews
        ? generateRandomNumberFromRange(20, 120)
        : generateRandomNumberFromRange(180, 190);
    })
  };
}

export default memo(SinglePost);
