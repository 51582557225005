import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconLogoMobile } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const MobileLogo = styled(IconLogoMobile)`
  display: none;
  color: ${({ theme }) => theme.sweetFlower};
  @media ${MEDIA_DEVICE.mobileL.to} {
    display: block;
  }
`;

export const Header = styled.div`
  position: absolute;
  height: 68px;
  left: 0;
  right: 0;
  top: calc(-68px - 52px);
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px 100px 80px rgba(182, 187, 245, 0.07),
    0px 41.7776px 33.4221px rgba(182, 187, 245, 0.0503198),
    0px 22.3363px 17.869px rgba(182, 187, 245, 0.0417275),
    0px 12.5216px 10.0172px rgba(182, 187, 245, 0.035),
    0px 6.6501px 5.32008px rgba(182, 187, 245, 0.0282725),
    0px 2.76726px 2.21381px rgba(182, 187, 245, 0.0196802);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;

  @media ${MEDIA_DEVICE.mobileL.to} {
    box-shadow: none;
    padding: 0px 16px;
    background-color: ${({ theme }) => theme.icyGray};
    //position: static;
  }
`;

export const RightBlock = styled.div`
  display: flex;
`;

export const Search = styled.div`
  display: none;
  flex-grow: 1;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: inline-block;
    margin-left: 16px;
  }
`;

export const AuthBlock = styled.div`
  display: flex;
`;

export const SignInBtn = styled(Button)`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.009em;
  padding: 4px 20px;
  height: 36px;
`;

export const SignUpBtn = styled(Button)`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.009em;
  padding: 4px 21px;
  height: 36px;
`;

export const ShowStatisticBlock = styled.div`
  position: absolute;
  top: -52px;
  left: 0;
  background: ${({ theme }) => theme.persianBlue};
  height: 52px;
  width: 100%;
  border-radius: 5px;
  padding: 10px 26px;

  justify-content: center;
  align-items: center;
`;

export const TextStatistic = styled.div`
  color: ${({ theme }) => theme.white};
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
