import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider } from 'antd';
import {
  ChannelExchangeFormats,
  ChannelWithExchangeInfo,
  GetV2UserChannelFormatsApiResponse,
  usePostV1UserChannelUpdateFormatMutation
} from 'api/api';
import minutesToName from 'fsd/shared/lib/minutesToName';
import { Select } from 'fsd/shared/ui/Select';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import CostCommission from '../CostCommission';

import schema from './schema';

import * as S from './EditForm.styles';

interface IProps {
  value?: number;
  disabled?: boolean;
  channel: ChannelWithExchangeInfo;
  format: ChannelExchangeFormats;
  channelFormats: GetV2UserChannelFormatsApiResponse;
  onChange: (value: number) => void;
}

type FormatType = {
  amount: string | undefined;
  timeInTop: number;
  timeInFeed: number;
};

const timeInTopOptions = [
  { value: 10, label: minutesToName(10)?.short },
  { value: 30, label: minutesToName(30)?.short },
  { value: 60, label: minutesToName(60)?.short },
  { value: 120, label: minutesToName(120)?.short }
];

const timeInFeedOptions = [
  { value: 1440, label: minutesToName(1440)?.short },
  { value: 2880, label: minutesToName(2880)?.short },
  { value: 4320, label: minutesToName(4320)?.short },
  { value: 10080, label: minutesToName(10080)?.long },
  { value: 43200, label: minutesToName(43200)?.long },
  { value: -1, label: minutesToName(-1)?.short }
];

export const EditForm: FC<IProps> = ({
  value,
  disabled,
  channel,
  format,
  channelFormats,
  onChange
}) => {
  const [cost, setCost] = useState(value);
  const [updateFormat] = usePostV1UserChannelUpdateFormatMutation();

  const currentFormat = useMemo(
    () => channelFormats.find(({ format_id }) => format_id === format.format_id),
    [channelFormats, format.format_id]
  );

  const { handleSubmit, control } = useForm({
    defaultValues: {
      amount: cost?.toString(),
      timeInTop: currentFormat?.time_in_top_minutes || 0,
      timeInFeed: currentFormat?.time_in_feed_minutes || 0
    },
    resolver: zodResolver(schema)
  });

  const handleFinish = (data: FormatType) => {
    const timeInFeedOption = minutesToName(
      timeInFeedOptions.find((format) => format.value === data.timeInFeed)!.value
    )?.long;
    const timeInTopOption = minutesToName(
      timeInTopOptions.find((format) => format.value === data.timeInTop)!.value
    )?.long;

    const isFormatAdded = channelFormats
      .filter(({ format_id }) => format_id !== format.format_id)
      .some(
        (format) =>
          format.time_in_feed_minutes === data.timeInFeed &&
          format.time_in_top_minutes === data.timeInTop
      );

    if (isFormatAdded) {
      CustomToast({
        message: <ToastMessage title="Данный формат уже добавлен" />,
        type: 'error'
      });

      return;
    }

    if (timeInFeedOption && timeInTopOption && cost) {
      updateFormat({
        body: {
          channel_id: channel.info.id,
          format_id: format.format_id,
          time_in_feed_name: timeInFeedOption,
          time_in_feed_minutes: data.timeInFeed,
          time_in_top_name: timeInTopOption,
          time_in_top_minutes: data.timeInTop,
          prices: [
            {
              currency_id: 1,
              price: cost?.toString()
            }
          ]
        }
      })
        .unwrap()
        .then(() => {
          onChange(cost);
          CustomToast({
            message: <ToastMessage title="Данные сохранены" />,
            type: 'success'
          });
        })
        .catch(() => {
          CustomToast({
            message: <ToastMessage title="Не удалось сохранить данные" />,
            type: 'error'
          });
        });
    }
  };

  return (
    <S.Root onFinish={handleSubmit(handleFinish)}>
      <S.Label>Добавить формат</S.Label>
      <S.FormSection>
        <Divider style={{ margin: 0 }} />
        <S.InputRow>
          <S.InputLabel>В топе</S.InputLabel>
          <S.FormItem control={control} name="timeInTop">
            <Select options={timeInTopOptions} suffixIcon={<S.IconArrowDown />} />
          </S.FormItem>
        </S.InputRow>

        <S.InputRow>
          <S.InputLabel>В ленте</S.InputLabel>
          <S.FormItem control={control} name="timeInFeed">
            <Select options={timeInFeedOptions} suffixIcon={<S.IconArrowDown />} />
          </S.FormItem>
        </S.InputRow>
      </S.FormSection>

      <S.Label>Стоимость формата</S.Label>

      <S.FormSection>
        <Divider style={{ margin: 0 }} />
        <FormItem control={control} name="amount">
          <S.InputRow>
            <S.NumberInput
              borderBottom
              size="small"
              type="number"
              disabled={disabled}
              value={cost}
              onChange={({ target }) => setCost(Number(target.value))}
            />
          </S.InputRow>
        </FormItem>

        <Divider style={{ margin: 0 }} />
        <CostCommission cost={cost ?? 0} />
      </S.FormSection>

      <S.SubmitButton
        type="primary"
        block
        size="small"
        htmlType="submit"
        disabled={!Number.isFinite(cost) || disabled}
      >
        {value ? 'Сохранить' : 'Добавить'}
      </S.SubmitButton>
    </S.Root>
  );
};

export default memo(EditForm);
