import createPDFFromHTML from 'fsd/shared/lib/createPDFFromHTML';

import createInvoiceHTML, { CreateInvoiceHTMLArg } from './createInvoiceHTML';

type Data = CreateInvoiceHTMLArg;

export default (data: Data) => {
  const pdf = createPDFFromHTML(createInvoiceHTML(data));
  return pdf;
};
