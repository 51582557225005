import { ComponentProps, FC } from 'react';
import { Button, ButtonVariant } from 'fsd/shared/ui/Buttons';
import { ToastMessage } from 'fsd/shared/ui/Toast';

type Props = Omit<ComponentProps<typeof ToastMessage>, 'title' | 'closable'> & {
  onRechargeCall?: () => void;
};

const InvalidToast: FC<Props> = ({ closeToast, onRechargeCall, ...restProps }) => {
  const handleClick = useCallback(() => {
    closeToast?.();
    onRechargeCall?.();
  }, [closeToast, onRechargeCall]);

  return (
    <ToastMessage
      {...restProps}
      closeToast={closeToast}
      closable
      title="У вас недостаточно средств для оплаты размещения"
      buttons={
        <Button
          type="primary"
          variant={ButtonVariant.WHITE}
          endIcon={null}
          size="small"
          onClick={handleClick}
        >
          Пополнить баланс
        </Button>
      }
    />
  );
};

export default memo(InvalidToast);
