import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconCart } from 'fsd/shared/icons';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';
import { HeaderMobileWidget, HeaderTitleWidget } from 'fsd/widgets/Header';

import Search from '../Search';

const Header = () => {
  const isMobile = useMediaQuery(MEDIA_DEVICE.mobileL.to);
  return !isMobile ? (
    <Search />
  ) : (
    <HeaderTitleWidget icon={<IconCart />}>Мои кампании</HeaderTitleWidget>
  );
};

export default Header;

export const HeaderMobile = () => {
  return (
    <HeaderMobileWidget>
      <Search styleType="white" />
    </HeaderMobileWidget>
  );
};
