/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Tooltip } from 'antd';
import {
  useGetV1ChannelsByChannelIdQuery,
  useGetV1ExchangePurchasesContainersByContainerIdQuery,
  useGetV1ExchangePurchasesContainersQuery,
  useLazyGetV1ChannelsFindQuery,
  usePostV1ExchangePurchaseContainersCreateMutation
} from 'api/api';
import { useChannelCategories } from 'fsd/entities/channel/lib/useChannelCategories';
import { UpdateChannelInContainer } from 'fsd/features/updateChannelInContainer';
import { IconArrowDown } from 'fsd/shared/icons';
import { getSyncSiteInfo } from 'fsd/shared/lib/getSiteInfo';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './styles';

type TabProps = PropsWithChildren & {
  containerId: string;
  isCollapsed?: boolean;
};

const Base: FC<TabProps> = ({ containerId, isCollapsed = false }) => {
  const navigate = useNavigate();
  const [isAutoFindChannelLoading, setIsAutoFindChannelLoading] = useState(false);
  const [isAutoFindChannelError, setIsAutoFindChannelError] = useState(false);
  const [isEditDropdownOpen, setIsEditDropdownOpen] = useState(false);

  const [updateContainer] = usePostV1ExchangePurchaseContainersCreateMutation();
  const [findChannels] = useLazyGetV1ChannelsFindQuery();

  const { data: container } = useGetV1ExchangePurchasesContainersQuery(
    {
      isActive: true
    },
    {
      selectFromResult: (data) => ({
        ...data,
        data: data.data?.find((c) => {
          return c.container_id === containerId;
        })
      })
    }
  );

  const { channel, title = '', source_link: sourceLink = '', type = 'tg_adv' } = container || {};
  const containerSiteInfo = useMemo(
    () => (sourceLink && type === 'external_adv' ? getSyncSiteInfo(sourceLink) : null),
    [sourceLink, type]
  );

  // TODO: В дальнейшем можно избавиться от этих данных, если расширить данные в channel(поля которых не хватает: is_verified)
  const { data: channelData } = useGetV1ChannelsByChannelIdQuery(
    { channelId: channel && channel.id ? channel?.id : '0' },
    { skip: !channel?.id }
  );

  const categories = useChannelCategories(channel?.categories);

  const { data: purchases = [] } = useGetV1ExchangePurchasesContainersByContainerIdQuery(
    { containerId },
    { skip: !containerId }
  );

  const isChannelEditable = useMemo(() => {
    return false;
    return (
      isAutoFindChannelError ||
      container?.type === 'external_adv' ||
      purchases.every(
        (item) =>
          !Object.hasOwn(item, 'post_id') &&
          !Object.hasOwn(item, 'time') &&
          !Object.hasOwn(item.common_purchase_data || {}, 'date')
      )
    );
  }, [isAutoFindChannelError, container?.type, purchases]);

  const { containerId: activeContainerId } = useParams();

  const isActive = activeContainerId === containerId;

  const handleLabelClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (isActive) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [isActive]
  );

  const handleOpenChange = useCallback((open: boolean) => setIsEditDropdownOpen(open), []);

  useEffect(() => {
    if (type === 'tg_adv' && !channel && sourceLink && container) {
      setIsAutoFindChannelLoading(true);
      setIsAutoFindChannelError(false);
      findChannels({
        keywords: sourceLink
      })
        .unwrap()
        .then((data) => {
          if (data.channels?.length === 1) {
            updateContainer({
              createPurchase: {
                container_id_list: [container.container_id],
                purchase_channel_id: data.channels[0]
              }
            })
              .unwrap()
              .finally(() => setIsAutoFindChannelLoading(false))
              .catch(() => setIsAutoFindChannelError(true));
          } else {
            setIsAutoFindChannelLoading(false);
          }
        })
        .catch(() => setIsAutoFindChannelError(true))
        .finally(() => setIsAutoFindChannelLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceLink, container?.container_id, findChannels, updateContainer]);

  const handleFormClick = useCallback<MouseEventHandler<HTMLFormElement>>((e) => {
    e.stopPropagation();
  }, []);

  const labelTitle = useMemo((): string => {
    switch (type) {
      case 'external_adv': {
        return containerSiteInfo?.name ?? '';
      }
      default:
        return channelData?.info?.title ?? '';
    }
  }, [type, channelData?.info?.title, containerSiteInfo?.name]);

  const labelIcon = useMemo((): string | undefined => {
    switch (type) {
      case 'external_adv': {
        return containerSiteInfo?.favicon;
      }
      default:
        return channelData?.info?.icon_url && `data:image/png;base64,${channel?.icon}`;
    }
  }, [channel?.icon, channelData?.info?.icon_url, containerSiteInfo?.favicon, type]);

  if (isCollapsed) {
    return (
      <>
        {title} | {labelTitle}
      </>
    );
  }

  return (
    <S.StyledTab
      className="usiteful__purchase__tab"
      onKeyDown={(e) => e.stopPropagation()}
      onClick={() => navigate(`/campaigns/${containerId}`)}
      editableChannel={isChannelEditable}
    >
      {title && title?.length >= 25 ? (
        <Tooltip title={title}>
          <S.ContainerName className="no-translation">{title}</S.ContainerName>
        </Tooltip>
      ) : (
        <S.ContainerName className="no-translation">{title}</S.ContainerName>
      )}
      <Dropdown
        trigger={['click']}
        disabled={!isChannelEditable || !isActive}
        open={isEditDropdownOpen}
        destroyPopupOnHide
        placement="bottom"
        onOpenChange={handleOpenChange}
        overlayStyle={{ maxWidth: 200 }}
        dropdownRender={() => (
          <UpdateChannelInContainer containerId={containerId} onClick={handleFormClick} />
        )}
      >
        <S.Label
          title={labelTitle}
          icon={labelIcon}
          loading={isAutoFindChannelLoading && { title: 'Добавляем канал...' }}
          error={
            isAutoFindChannelError && {
              title: 'Канал не найден',
              caption: 'Проверьте ссылку на канал'
            }
          }
          verified={channelData?.info?.is_verified}
          caption={channel ? categories : sourceLink}
          internet={type === 'external_adv'}
          onClick={handleLabelClick}
          endIcon={
            isChannelEditable && (
              <Icon width={24} rotateAngle={isEditDropdownOpen ? 180 : 0}>
                <IconArrowDown />
              </Icon>
            )
          }
        />
      </Dropdown>
    </S.StyledTab>
  );
};

export default memo(Base);
