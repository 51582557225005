import { useGetV1ExchangePurchasesContainersQuery } from 'api/api';

export default (containerId?: string) => {
  const data = useGetV1ExchangePurchasesContainersQuery(
    { isActive: true },
    {
      selectFromResult: (data) => ({ data: data.data?.find((c) => c.container_id === containerId) })
    }
  );
  return data;
};
