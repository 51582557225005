import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

import Header from '../Header';

import * as S from './styles';

type Props = {
  title?: ReactNode;
  footer?: ReactNode;
  onClose: MouseEventHandler<HTMLButtonElement>;
};

const Content = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, title, footer, onClose }, ref) => {
    const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
      (e) => e.stopPropagation(),
      []
    );
    return (
      <S.Root onClick={handleClick} ref={ref}>
        <Header onClose={onClose}>{title}</Header>
        <S.Body>{children}</S.Body>
        {footer && <S.Footer>{footer}</S.Footer>}
      </S.Root>
    );
  }
);

export default Content;
