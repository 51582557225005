import { getCookie } from 'fsd/shared/lib/helpers/tools';

const URL = import.meta.env.VITE_REACT_APP_API_URL;

const fetchAPI = {
  get: async (path: string) => {
    try {
      const token = getCookie('token');
      const response = await fetch(`${URL}${path}`, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` })
        }
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  all: async (paths: string[]) => {
    try {
      const token = getCookie('token');
      const requests = paths.map((path) =>
        fetch(`${URL}${path}`, {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` })
          }
        })
      );
      const responses = await Promise.all(requests);
      const data = await Promise.all(responses.map((response) => response.json()));
      return data;
    } catch (error) {
      console.error(error);
    }
  }
};

export { fetchAPI };
