import { IconGrowth } from 'fsd/shared/icons';
import { colNames } from 'fsd/shared/lib/helpers/constants/channelConstants';

import { TableDataContext } from '../../../lib/tableDataContext';
import SortableTitle from '../../SortableTitle';

const Title = () => {
  const tableDataContext = useContext(TableDataContext);
  return (
    tableDataContext && (
      <SortableTitle
        className="usiteful__column-participants-diff"
        tooltipText="Разница между числом подписчиков на конец и на начало периода"
        icon={<IconGrowth />}
        text={colNames.usersGrowth}
        name="participants_diff"
        form={tableDataContext.form}
        values={tableDataContext.formValues}
      />
    )
  );
};

export default memo(Title);
