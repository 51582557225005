import styled from 'styled-components';

// TODO : Надо будет такие вещи вынести в отдельное место в shared
const Root = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 16px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
`;

export default Root;
