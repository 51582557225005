import { cutNum, getColorByCount } from 'fsd/shared/lib/helpers/tools';

import * as S from '../../styles';
import * as LS from './styles';

export const DeltaRow = ({ delta, time }: { delta: number; time: string }) => {
  const preDelta = cutNum(delta);
  const count = delta > 0 ? `+${preDelta}` : preDelta;

  return (
    <>
      <LS.TDHours>
        <S.ColumnDiv1>
          <LS.Hour>{time}</LS.Hour>
        </S.ColumnDiv1>
      </LS.TDHours>
      {/* Here is need more space */}
      <LS.TDpeoples>
        <S.ColumnDiv2>
          <LS.Count color={getColorByCount(delta)}>{count}</LS.Count>
        </S.ColumnDiv2>
      </LS.TDpeoples>
      <LS.TDposts>
        <S.ColumnDiv3 />
      </LS.TDposts>
      <td style={{ padding: 0, width: 50 }}>
        <S.ColumnDiv4 />
      </td>
    </>
  );
};
