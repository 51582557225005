import { ComponentProps, FC, PropsWithChildren, type SyntheticEvent, useCallback } from 'react';
import { AnyObject, Form } from 'react-final-form';
import { ChannelsListResponse, useLazyChannelsQuery } from 'api/api';
import { FormApi } from 'final-form';
import { useDate } from 'fsd/app/providers/Date';
import { isDev } from 'fsd/shared/lib/featureFlags';
import { fixData } from 'fsd/shared/lib/helpers/tools';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { useStickyState } from 'fsd/shared/lib/hooks/useStickyState';
import mergeArray from 'fsd/shared/lib/mergeArray';
import sign from 'jwt-encode';
import { DATE_RANGE, FilterState } from 'types/channels/types';
import { useDebouncedCallback } from 'use-debounce';

import CatalogPage from 'pages/catalog/CatalogPage';
import { FormValues } from 'pages/catalog/types';
import { validation } from 'pages/catalog/validation';

import ChannelsTable from './components/Table';
import { CHANNELS_LIMIT, MOBILE_CHANNELS_LIMIT } from './config';

export type CatalogProps = {
  catalogProps: {
    onLoadMore?: ComponentProps<typeof ChannelsTable>['onLoadMore'];
    hasMore?: boolean;
    handleSubmit: (
      // eslint-disable-next-line no-unused-vars
      event?:
        | Partial<Pick<SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>>
        | undefined
    ) => Promise<AnyObject | undefined> | undefined;
    form: FormApi<FilterState, Partial<FilterState>>;
    values: FormValues;
    isFetching: boolean;
    data: ChannelsListResponse | undefined;
  };
};

export const initialValues: Partial<FilterState> = {
  date_range: DATE_RANGE.yesterday.value,
  is_exchange_active: true,
  is_top_tgmarket: true,
  sort: {
    field: 'participants',
    order: 'DESC'
  },
  pagination: {
    offset: 0,
    limit: 20
  },
  languages: []
};

const Catalog: FC<PropsWithChildren> = () => {
  const { now } = useDate();
  const isMobile = useIsMobile();
  const [getChannels, { isFetching, data: channelsData }] = useLazyChannelsQuery();
  const [formSettings, setFormSettings] = useStickyState(initialValues, 'formSettings', true);
  const [data, setData] = useState<typeof channelsData>({
    total: 0,
    channels: undefined
  });

  const [page, setPage] = useState(1);
  const [hasChannels, setHasChannels] = useState(true);

  const handleSubmit = useDebouncedCallback(
    useCallback(
      (values: FormValues) => {
        setPage(1);
        setHasChannels(true);
        setFormSettings(values);
        getChannels({
          filter: sign(fixData({ ...values }, now), import.meta.env.VITE_REACT_APP_JWT_SECRET || '')
        })
          .unwrap()
          .then((res) => setData(res));
      },
      [getChannels, now, setFormSettings]
    ),
    150
  );

  const handleLoadMore = useCallback<(end: () => void, values: FormValues) => Promise<unknown>>(
    (end, values) => {
      const limit = isMobile ? MOBILE_CHANNELS_LIMIT : CHANNELS_LIMIT;
      const pagination: typeof values.pagination = {
        limit: limit,
        offset: limit * page
      };

      return getChannels({
        filter: sign(
          fixData({ ...values, pagination }, now),
          import.meta.env.VITE_REACT_APP_JWT_SECRET || ''
        )
      })
        .unwrap()
        .then((res) => {
          if (res.channels?.length) {
            setData((cur) => {
              return {
                total: data?.total ?? 0,
                channels: mergeArray(
                  cur?.channels ?? [],
                  res?.channels ?? [],
                  (data) => data.info.id
                )
              };
            });
            setPage((cur) => cur + 1);
          } else {
            setHasChannels(false);
          }
        })
        .finally(() => end());
    },
    [data?.total, getChannels, isMobile, now, page]
  );

  useEffect(() => {
    if (isDev()) {
      console.log(`LOADED CHANNELS: ${data?.channels?.length} \n TOTAL: ${data?.total}`);
    }
  }, [data]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={formSettings}
      validate={validation}
      render={({ handleSubmit: formSubmit, form, values }) => {
        return (
          <CatalogPage
            catalogProps={{
              handleSubmit: formSubmit,
              onLoadMore: (end) => handleLoadMore(end, values),
              form,
              values,
              isFetching: isFetching,
              data,
              hasMore: (data?.channels?.length ?? 0) < (data?.total ?? 1) && hasChannels
            }}
          />
        );
      }}
    />
  );
};

export default memo(Catalog);
