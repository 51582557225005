import { LoadingOutlined } from '@ant-design/icons';
import { Cascader } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { ReactComponent as ChannelsIcon } from 'fsd/shared/icons/channels.svg';
import { ReactComponent as CloseIcon } from 'fsd/shared/icons/close.svg';
import { Input } from 'fsd/shared/ui/Input';
import styled from 'styled-components';

import SearchWithAddChannel from './components/SearchWithAddChannel';

export const TabContainer = styled.div`
  position: relative;
`;

export const RowContainer = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 6px;
  min-height: 80px;
  padding: 24px 24px;
  margin-bottom: 7px;
`;

export const RowInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 73px;
  // justify-content: space-between;
`;

export const RowInfoItem = styled.div`
  :last-of-type {
    justify-content: end;
  }
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
`;

export const RowInfoDesc = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
`;

export const RowExpandedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

export const RowExpandedBlock = styled.div`
  padding-right: 0px;
`;

export const ExpandedBlockHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.blackRock};
  margin-bottom: 10px;
`;

export const DescWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  min-width: 304px;
  margin-top: 24px;
`;

export const DescContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FormatDesc = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.persianBlue};
`;

export const CostDescContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 14px;
`;

export const CostDesc = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.009em;
  color: ${({ theme }) => theme.persianBlue};
`;

export const CurrencyDesc = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.009em;
  color: ${({ theme }) => theme.gunPowder};
`;

export const DaysContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 24px;
`;

export const DayDesc = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.009em;
  color: ${({ theme }) => theme.gunPowder};
  margin-top: 6px;
`;

export const TimeWrapper = styled.div`
  min-width: 202px;
  margin-top: 8px;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

export const IconAddWrapper = styled.div`
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  cursor: pointer;
  padding: 4px;
`;

export const InputSearch = styled(Input)`
  width: 25vw;

  .ant-input-prefix {
    margin-right: 9px;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 100%;
    display: inline-flex;
  }
`;

export const SearchIcon = styled(ChannelsIcon)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.bayFog};
`;

export const LoadingIcon = styled(LoadingOutlined)`
  font-size: 16px;
  color: ${({ theme }) => theme.delftBlue};
  position: relative;
  top: 1px;
  right: 1px;
`;

export const CloseModalIcon = styled(CloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const ModalBlock = styled.div`
  :first-of-type {
    margin-top: 16px;
  }
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px 100px 80px rgba(185, 187, 197, 0.07),
    0px 41.7776px 33.4221px rgba(185, 187, 197, 0.0503198),
    0px 22.3363px 17.869px rgba(185, 187, 197, 0.0417275),
    0px 12.5216px 10.0172px rgba(185, 187, 197, 0.035),
    0px 6.6501px 5.32008px rgba(185, 187, 197, 0.0282725),
    0px 2.76726px 2.21381px rgba(185, 187, 197, 0.0196802);
  border-radius: 14px;
  margin-top: 16px;
  padding: 16px;
`;

export const ModalTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.blackRock};
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const ModalBlockHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.blackGrey};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img<{ language?: string | null }>`
  ${({ language }) =>
    language === 'en' &&
    `
    height: 408px;
  `};
  width: 268px;
  border-radius: 20px;
  box-shadow:
    0px 100px 80px rgba(185, 187, 197, 0.07),
    0px 41.7776px 33.4221px rgba(185, 187, 197, 0.0503198),
    0px 22.3363px 17.869px rgba(185, 187, 197, 0.0417275),
    0px 12.5216px 10.0172px rgba(185, 187, 197, 0.035),
    0px 6.6501px 5.32008px rgba(185, 187, 197, 0.0282725),
    0px 2.76726px 2.21381px rgba(185, 187, 197, 0.0196802);
`;

export const HelperBlock = styled.div`
  padding: 16px;
`;

export const ModalBlockDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
  margin-bottom: 16px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const BoldText = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
`;

export const BotLink = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.blueRibbon};
  cursor: pointer;
`;

export const AddInput = styled(Input)`
  background-color: ${({ theme }) => theme.alpineGoat};

  .ant-input {
    background-color: ${({ theme }) => theme.alpineGoat};
  }

  .ant-input-prefix {
    margin-right: 9px;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: inline-flex;
    background-color: ${({ theme }) => theme.alpineGoat};

    .ant-input {
      background-color: ${({ theme }) => theme.alpineGoat};
    }
  }
`;

export const ErrorText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.hotspot};
  position: absolute;
  bottom: 16px;
  right: 32px;
`;

export const ThematicsInput = styled(Input)`
  width: 297px;
  background: ${({ theme }) => theme.alpineGoat} !important;
  border-color: ${({ theme }) => theme.alpineGoat} !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock} !important;
  cursor: default !important;
`;

export const AdministratorInput = styled(Input)`
  width: 297px;
  background: ${({ theme }) => theme.alpineGoat} !important;
  border-color: ${({ theme }) => theme.alpineGoat} !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.blueRibbon} !important;
  cursor: pointer !important;
`;

export const ChannelSearchWithAddChannel = styled(SearchWithAddChannel)`
  position: absolute;
  right: 0;
  top: -60px;
  z-index: 1;
`;

export const StyledCascader = styled(Cascader)``;

export const CascaderContainer = styled.div`
  padding: 16px;
  width: 375px;
  max-width: 100vw;
`;

export const ModalHeader = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.blackRock};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const YellowCircle = styled.div`
  position: absolute;
  width: 40vw;
  height: 50vh;
  bottom: 0;
  background: radial-gradient(
    67.22% 50% at 50% 50%,
    rgba(255, 161, 51, 0.15) 0%,
    rgba(245, 228, 255, 0) 70%
  );
  opacity: 0.9;
  z-index: -1;
  border-radius: 999999px;
`;
