import { FC } from 'react';

import Button from '../Button';
import { ContextProps, PickerContext } from '../lib/context';
import useDisabledTime from '../lib/useDisabledTime';
import Panel from '../Panel';
import { PickerProps } from '../types';

import * as S from './Inline.styles';

type Props = PickerProps;

const Inline: FC<Props> = ({
  format,
  okButton,
  minTime,
  okButtonRender,
  okDisabled,
  ...restProps
}) => {
  const disabledTime = useDisabledTime(minTime);
  const contextValue = useMemo(
    (): ContextProps => ({ format, okButton, okDisabled }),
    [format, okButton, okDisabled]
  );
  return (
    <PickerContext.Provider value={contextValue}>
      <S.Root
        open
        suffixIcon={null}
        allowClear={false}
        showNow={false}
        inputReadOnly
        // eslint-disable-next-line react/no-unstable-nested-components
        panelRender={(content) => <Panel>{content}</Panel>}
        getPopupContainer={(elem) => elem}
        components={{
          input: () => null,
          button: okButtonRender ?? Button
        }}
        disabledTime={disabledTime}
        format={format}
        {...restProps}
      />
    </PickerContext.Provider>
  );
};

export default memo(Inline);
