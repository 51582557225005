import { usePostV1UserBalancePaymentSbpMutation } from 'api/api';

type Params = {
  organisationId: number;
  amount: string;
};

export default () => {
  const [createSBP, data] = usePostV1UserBalancePaymentSbpMutation();
  const create = useCallback(
    ({ organisationId, amount }: Params) => {
      return createSBP({
        postBalancePaymentSbpRequest: {
          organisation_id: organisationId,
          sum: amount
        }
      });
    },
    [createSBP]
  );
  return [create, data] as const;
};
