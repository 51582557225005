import { eachDayOfInterval, eachHourOfInterval, format, parseISO } from 'date-fns';
import { dateFormat } from 'fsd/shared/lib/helpers/constants/dateFormats';

import { parseISOWithoutTimeZone } from './dates';

export const getDatesArray = (
  dateFrom: string,
  dateTo: string,
  accuracy: 'day' | 'hour' = 'day',
  timestamp?: boolean
): string[] => {
  if (!dateFrom || !dateTo) return [];

  let datesArr = null;

  if (accuracy === 'hour') {
    datesArr = eachHourOfInterval({
      start: parseISOWithoutTimeZone(dateFrom),
      end: parseISOWithoutTimeZone(dateTo)
    });
  } else {
    datesArr = eachDayOfInterval({
      start: parseISOWithoutTimeZone(dateFrom),
      end: parseISOWithoutTimeZone(dateTo)
    });
  }

  const dates = datesArr.map((date) => {
    return timestamp ? `${date.getTime()}` : format(date, dateFormat);
  });

  return dates;
};
