import { FC } from 'react';
import { SortOrder } from 'antd5/lib/table/interface';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './styles';

type Props = {
  sortOrder: SortOrder;
};

const SortIcon: FC<Props> = ({ sortOrder }) => {
  return (
    <Icon width={14}>
      <S.Icon $sortOrder={sortOrder} />
    </Icon>
  );
};

export default memo(SortIcon);
