/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
import { Id, TimeWindow } from 'api/api';
import dayjs from 'dayjs';
import { useChannelMetricsWithCache } from 'fsd/entities/channel';
import { ChanelMetricsWithCacheResult } from 'fsd/entities/channel/api/useMetricsWithCache';
import { generateDateRangeByTimeWindow } from 'fsd/shared/lib/helpers/tools/dates';

type Params = {
  channelIds: Id[];
  historicalMetricsLoaded: boolean;
  timeWindow: TimeWindow;
};

const yesterdayDayRange = {
  from: dayjs().add(-1, 'day').startOf('day').toISOString(),
  to: dayjs().add(-1, 'day').endOf('day').toISOString()
};

export default ({ channelIds, historicalMetricsLoaded, timeWindow }: Params) => {
  const { now } = useDate();

  const [dateRange, dateRangeWithReaches] = useMemo(
    () => [
      generateDateRangeByTimeWindow(timeWindow, now),
      generateDateRangeByTimeWindow(timeWindow, now, true)
    ],
    [timeWindow, now]
  );

  const channelMetricsPromise = useChannelMetricsWithCache({
    ids: channelIds,
    metricType: 'participants',
    dateRange: {
      to: now
    },
    historicalMetricsLoaded,
    timeZone: 'Europe/Moscow'
  });

  const participantsDiffMetricsPromise = useChannelMetricsWithCache({
    ids: channelIds,
    metricType: 'participantsDiffs',
    dateRange,
    historicalMetricsLoaded,
    timeZone: 'Europe/Moscow'
  });

  const reachesMetricsPromise = useChannelMetricsWithCache({
    ids: channelIds,
    // FIXME : update openapi docs
    // @ts-ignore
    metricType: 'reaches',
    dateRange: dateRangeWithReaches,
    historicalMetricsLoaded,
    timeZone: 'Europe/Moscow'
  });

  const avgDailyReachesMetricsPromise = useChannelMetricsWithCache({
    ids: channelIds,
    // FIXME : update openapi docs
    // @ts-ignore
    metricType: 'avg-daily-reaches',
    dateRange: timeWindow === 'yesterday' ? yesterdayDayRange : dateRangeWithReaches,
    historicalMetricsLoaded,
    timeZone: 'Europe/Moscow'
  });

  const errMetricsPromise = useChannelMetricsWithCache({
    ids: channelIds,
    // FIXME : update openapi docs
    // @ts-ignore
    metricType: 'avg-daily-err',
    dateRange,
    historicalMetricsLoaded,
    timeZone: 'Europe/Moscow'
  });

  const [metrics, setMetrics] = useState<
    Partial<
      Record<
        | 'channelMetrics'
        | 'participantsDiffMetrics'
        | 'avgDailyReachesMetrics'
        | 'reachesMetrics'
        | 'errMetrics',
        ChanelMetricsWithCacheResult
      >
    >
  >({});

  useEffect(() => {
    Promise.all([
      channelMetricsPromise(),
      participantsDiffMetricsPromise(),
      avgDailyReachesMetricsPromise(),
      reachesMetricsPromise(),
      errMetricsPromise()
    ]).then(
      ([
        channelMetrics,
        participantsDiffMetrics,
        avgDailyReachesMetrics,
        reachesMetrics,
        errMetrics
      ]) => {
        setMetrics({
          channelMetrics,
          participantsDiffMetrics,
          avgDailyReachesMetrics,
          reachesMetrics,
          errMetrics
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelIds, dateRange, dateRangeWithReaches, historicalMetricsLoaded, now, timeWindow]);

  const metricsResultForEmptyIds = {
    ...metrics,
    dateRange,
    dateRangeWithReaches
  };

  useEffect(() => {
    Object.values(metrics).forEach(({ resetCache }) => resetCache());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeWindow]);

  return metricsResultForEmptyIds;
};
