import { ComponentProps, FC } from 'react';

import AddChannel from '../AddChannel';

import * as S from './styles';

type Props = ComponentProps<typeof S.Root>;

const SearchWithAddChannel: FC<Props> = (props) => {
  return (
    <S.Root {...props}>
      <S.Search />
      <AddChannel />
    </S.Root>
  );
};

export default memo(SearchWithAddChannel);
