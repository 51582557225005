import { ComponentType } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { useGetV1UserQuery } from 'api/api';

export const withSentryUsers = (Component: ComponentType<{}>) => () => {
  const { data: user } = useGetV1UserQuery();

  useEffect(() => {
    Sentry.setUser(
      user
        ? {
            email: user.email,
            id: user.id,
            username: `${user.username}/@${user.tg_username}`,
            tg_username: user.tg_username
          }
        : null
    );
  }, [user]);

  return <Component />;
};
