import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ChannelTimelineEvents,
  Direction,
  MergedChannelTimelineEventsRead,
  useLazyGetV2ChannelsByChannelIdTimelineEventsQuery
} from 'api/api';
import { format } from 'date-fns';
import {
  parseISOWithoutTimeZone,
  toISOStringWithMSKTime
} from 'fsd/shared/lib/helpers/tools/dates';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import { useDateFilterController } from './useDateFilterController';

const LIMIT = 200;

export const useGetInfinityScrollData = (
  channelId: string,
  filterDate: string,
  historical_metrics_loaded: boolean
) => {
  const [isWidgetLoading, setIsWidgetLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreUp, setHasMoreUp] = useState(true);
  const [hasMoreDown, setHasMoreDown] = useState(true);
  const [loadMoreData] = useLazyGetV2ChannelsByChannelIdTimelineEventsQuery();
  const [results, setResults] = useState<any[]>([]);

  const loadMore = useCallback(
    async (direction: Direction) => {
      if (isLoading) return;
      setIsLoading(true);
      const lastDateInResults =
        direction === 'up' && results.length > 0
          ? results[0]?.date
          : results[results.length - 1]?.date || filterDate || toISOStringWithMSKTime(new Date());

      // load and merge more data
      loadMoreData({
        channelId,
        lastDate: lastDateInResults,
        limit: LIMIT,
        withReposts: true,
        historicalMetricsLoaded: historical_metrics_loaded,
        direction
      })
        .unwrap()
        .catch((e: any) => {
          if (e.status === 'FETCH_ERROR') {
            CustomToast({
              type: 'error',
              message: (
                <ToastMessage
                  title="Ошибка загрузки"
                  description={
                    'Сервер не отвечает.\n' +
                    '              Проверьте соединение с интернетом или попробуйте позднее'
                  }
                />
              ),
              options: {
                toastId: 'channel-statistics-error'
              }
            });
          } else {
            CustomToast({
              type: 'error',
              message: (
                <ToastMessage
                  title="Ошибка загрузки"
                  description={`Причина: код ошибки ${e.status}`}
                />
              ),
              options: {
                toastId: 'channel-statistics-error'
              }
            });
          }
          if (direction === 'up') setHasMoreUp(false);
          else setHasMoreDown(false);

          setIsLoading(false);
        })
        .then((data: void | MergedChannelTimelineEventsRead | null) => {
          const sorted = Array.isArray(data) ? data : [];
          let newArray = [];
          if (direction === 'up') newArray = [...sorted, ...results];
          else newArray = [...results, ...sorted];

          for (let i = 0; i < newArray.length - 1; i++) {
            if (newArray[i]?.dateNew || i === 0) continue;

            const currentItemDate =
              newArray[i]?.date && format(parseISOWithoutTimeZone(newArray[i].date), 'd.MM.yyyy');

            const prevItemDate =
              newArray[i - 1]?.date &&
              format(parseISOWithoutTimeZone(newArray[i - 1].date), 'd.MM.yyyy');

            if (!currentItemDate || !prevItemDate) continue;

            if (currentItemDate !== prevItemDate) {
              const newDayElement = {
                dateNew: currentItemDate,
                type: 'day'
              };
              newArray.splice(i, 0, newDayElement);
            }
          }

          setResults(newArray);
          setIsLoading(false);
          if (!data || !Array.isArray(data) || !data.length) {
            if (direction === 'up') setHasMoreUp(false);
            else setHasMoreDown(false);
          }
        });
    },
    [results, isLoading]
  );

  useEffect(() => {
    // load initial data and reload if filterDate changed
    (async () => {
      setIsWidgetLoading(true);
      setHasMoreUp(true);

      loadMoreData({
        channelId,
        lastDate: filterDate || '',
        limit: LIMIT,
        withReposts: true,
        historicalMetricsLoaded: historical_metrics_loaded,
        direction: 'down'
      })
        .unwrap()
        .catch((e: any) => {
          if (e.status === 'FETCH_ERROR') {
            CustomToast({
              type: 'error',
              message: (
                <ToastMessage
                  title="Ошибка загрузки"
                  description={`Сервер не
                отвечает. Проверьте соединение с интернетом или попробуйте
                позднее`}
                />
              ),
              options: {
                toastId: 'channel-statistics-error'
              }
            });
          } else {
            CustomToast({
              type: 'error',
              message: (
                <ToastMessage
                  title="Ошибка загрузки"
                  description={`Причина: код ошибки ${e.status}`}
                />
              ),
              options: {
                toastId: 'channel-statistics-error'
              }
            });
          }

          return null;
        })
        .then((data: MergedChannelTimelineEventsRead | null) => {
          const sorted = Array.isArray(data) ? data : [];
          setResults(sorted);
          setIsWidgetLoading(false);
          if (!data || !Array.isArray(data) || !data.length) {
            setHasMoreDown(false);
          }
        });
    })();
  }, [filterDate]);

  return {
    values: results,
    isFirstLoading: isWidgetLoading,

    loadMore,
    hasMoreUp,
    hasMoreDown,
    isLoading,
    setIsLoading
  };
};

export { useDateFilterController };
