import { FC } from 'react';
import { ChannelWithExchangeInfoRead } from 'api/api';

import Form from './Form';

import * as S from './AddToPurchase.styles';

type Props = {
  channel: ChannelWithExchangeInfoRead;
};

const AddToPurchase: FC<Props> = ({ channel }) => {
  return (
    <S.Root>
      <S.Text>Купить рекламу в канале</S.Text>
      <Form channel={channel} />
    </S.Root>
  );
};

export default memo(AddToPurchase);
