import { format } from 'date-fns';
import { dateFormat } from 'fsd/shared/lib/helpers/constants/dateFormats';

export const getAllDaysInMonth = (year: number, month: number): string[] => {
  const date = new Date(year, month, 1);

  const dates = [];

  while (date.getMonth() === month) {
    dates.push(format(new Date(date), dateFormat));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};
