import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Filters = styled.div`
  box-shadow:
    rgba(0, 0, 0, 0.09) 0px -4px 64px,
    rgba(179, 175, 214, 0.7) 0px 100px 80px;
  border-radius: 14px;
  background: ${({ theme }) => theme.white};
  padding: 8px 10px;
  margin-bottom: 13px;
  position: relative;
  display: flex;
  gap: 14px;
`;

export const FilterSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const FilterText = styled.span`
  font-size: 12px;
  line-height: 16px;
`;

export const Root = styled.div`
  height: calc(100vh - 145px);
  padding: 0 16px;
  width: 100%;
  overflow: auto;
  background: transparent;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    height: calc(100vh - 145px);
  }
`;

export const Wrapper = styled.div`
  margin: 0 -16px 1px;
`;

export const PostWrap = styled.div`
  margin-bottom: 13px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const MentionsFilters = styled.div`
  box-shadow:
    rgba(0, 0, 0, 0.09) 0px -4px 64px,
    rgba(179, 175, 214, 0.7) 0px 100px 80px;
  border-radius: 14px;
  background: ${({ theme }) => theme.white};
  padding: 8px 24px;
  margin-bottom: 13px;
  position: relative;
  display: flex;
  gap: 24px;
`;

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;
