import { AddOrRemoveChannelFromCart } from 'fsd/features/addOrRemoveChannelFromCart';

import { RowDataContext } from '../../../lib/rowDataContext';

const Content = () => {
  const rowData = useContext(RowDataContext);

  const handleSelect = useCallback(
    (formatId?: number) => {
      rowData?.setFormatId(formatId);
    },
    [rowData]
  );
  return (
    rowData && (
      <AddOrRemoveChannelFromCart
        channel={rowData.channel}
        onSelect={handleSelect}
        value={rowData.formatId}
      />
    )
  );
};

export default memo(Content);
