import { Select } from 'antd';
import { ChannelTab } from 'fsd/entities/channel';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled, { css } from 'styled-components';

export const ContainerName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  overflow: hidden;
  flex: none;
  text-overflow: ellipsis;
  max-width: 235px;
  white-space: nowrap;
  padding-bottom: 9px;
`;

export const StyledTab = styled.div<{ editableChannel?: boolean }>`
  width: 289px;
  height: 88px;
  border-radius: 20px 20px 0px 0px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: ${({ editableChannel }) => {
    return editableChannel ? '16px 10px 7px 13px' : '16px 10px 10px 13px';
  }};

  color: ${({ theme }) => theme.blackRock};

  ${ContainerName} {
    padding-bottom: 4px;
  }

  ${({ editableChannel }) =>
    editableChannel &&
    css`
      .ant-select-selector {
        height: 45px !important;
        padding: 0 5px !important;
      }

      .ant-select-selection-item > div {
        height: 45px;
      }
    `}
`;

export const Label = styled(ChannelTab.Label)`
  cursor: pointer;
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const DropdownContent = styled.div`
  /* padding: 24px; */
  display: flex;
  flex-direction: column;

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 0;
    background: transparent;
  }
`;

export const SubjectItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  color: ${({ active, theme }) => (active ? theme.delftBlue : 'transparent')};
  order: ${({ active }) => (active ? 0 : 1)};

  label {
    display: flex;
    gap: 8px;
  }
`;

export const CurrencyOption = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};

  &:hover {
    color: ${({ theme }) => theme.persianBlue};
  }
`;

export const CurrencyWrapper = styled.div`
  padding: 4px;
`;

export const CurrencySelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
  }
`;

export const BudgetRow = styled.div`
  display: flex;
  gap: 16px;
`;

export const SubmitWrapper = styled.div`
  padding: 18px;
  background-color: white;
  border: none;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  margin-right: 12px;
`;
