import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Label = styled.label`
  margin-bottom: 4px;
  line-height: 16px;
  display: block;

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 12px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.3px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
  }
`;

export const Field = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-bottom: 16px;
  }
`;

export const Error = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.hotspot};
  position: absolute;
  top: 39px;
  right: 0;
`;
