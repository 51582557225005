import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconLogoMobile } from 'fsd/shared/icons';
import styled from 'styled-components';

import { LogoWithText } from '.';

const LogoLink = styled(NavLink)`
  margin-left: 17px;
`;
const MobileLogo = styled(IconLogoMobile)`
  display: none;
  color: ${({ theme }) => theme.sweetFlower};
  @media ${MEDIA_DEVICE.mobileL.to} {
    display: block;
  }
`;

const Logo: FC = () => {
  return (
    <LogoLink to="/">
      <LogoWithText />
      <MobileLogo />
    </LogoLink>
  );
};

export default Logo;
