import { FunctionComponent } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import styled from 'styled-components';

import { BaseStyles } from '../styles';

type Props = {
  $borderBottom?: boolean;
  $fontWeight?: number;
  $isError?: boolean;
};

export const Root: FunctionComponent<InputNumberProps & Props> = styled(InputNumber)<Props>`
  width: 100%;
  ${BaseStyles};
`;
