import { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconDownload } from 'fsd/shared/icons';
import { Button } from 'fsd/shared/ui/Buttons';
import { OutlinedButton } from 'fsd/shared/ui/Buttons/OutlinedButton';

import createInvoiceQRData from '../../lib/createInvoiceQRData';
import { CreateInvoiceResult } from '../../lib/useCreateInvoice';

import * as S from './styles';

type Props = {
  invoice: CreateInvoiceResult;
};

const InvoiceInfo: FC<Props> = ({ invoice }) => {
  // const isMobile = useMediaQuery(MEDIA_DEVICE.mobileL.to);
  const handleDownloadClick = useCallback(() => {
    invoice.pdf?.download(`Tgmarket Payment Invoice ${dayjs().format(`DD-MM-YYYY`)}`);
  }, [invoice.pdf]);

  // const qrValue = useMemo(() => {
  //   if (invoice?.organisation?.inn) {
  //     return createInvoiceQRData({
  //       sumRub: Number(invoice.amount),
  //       payerINN: invoice.organisation.inn
  //     });
  //   }
  //   return '';
  // }, [invoice.amount, invoice.organisation.inn]);

  return (
    <S.Root>
      {/* {qrValue && <S.QR errorLevel="M" value={qrValue} />} */}
      <S.DownloadWrapper>
        <Button
          startIcon={<IconDownload />}
          type="primary"
          block
          size="large"
          onClick={handleDownloadClick}
        >
          Скачать PDF
        </Button>
      </S.DownloadWrapper>
      {/* <S.Caption>В процессе оплаты счёта вы можете продолжить работу</S.Caption> */}
    </S.Root>
  );
};

export default memo(InvoiceInfo);
