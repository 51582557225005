import { BetaModal } from 'fsd/shared/ui/Modal';
import styled from 'styled-components';

export const Root = styled(BetaModal)``;

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
`;

export const Body = styled.div`
  width: 100%;
`;
