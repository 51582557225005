import { FC } from 'react';

import { BaseProps } from './types';

import * as S from './OutlinedButton.styles';

type Props = BaseProps;

const OutlinedButton: FC<Props> = ({
  children,
  color,
  startIcon,
  startIconColor,
  endIcon,
  endIconColor,
  ...restProps
}) => {
  return (
    <S.Button color={color} {...restProps}>
      {startIcon && <S.IconWrapper color={startIconColor}>{startIcon}</S.IconWrapper>}
      {children}
      {endIcon && <S.IconWrapper color={endIconColor}>{endIcon}</S.IconWrapper>}
    </S.Button>
  );
};

export default memo(OutlinedButton);
