import { MediaQueryBreakpoint } from '../modules/media-queries';

import { MEDIA_DEVICE_BREAKPOINTS } from './mediaDeviceBreakpoints';

export const MEDIA_DEVICE = Object.fromEntries(
  Object.entries(MEDIA_DEVICE_BREAKPOINTS).map(([key, value]) => [
    key,
    new MediaQueryBreakpoint('width', value)
  ])
) as Record<keyof typeof MEDIA_DEVICE_BREAKPOINTS, MediaQueryBreakpoint>;
