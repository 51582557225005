import FallbackImg from 'fsd/shared/ui/FallbackImg';
import styled from 'styled-components';

export const Root = styled.div<{
  isActive?: boolean;
  isError?: boolean;
  size?: 's' | 'm';
  circle?: boolean;
}>`
  --size: ${({ size }) => (size === 's' ? '24px' : '36px')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border-radius: ${({ circle }) => (circle ? '50%' : '5px')};
  background: ${({ theme, isActive, isError }) =>
    // eslint-disable-next-line no-nested-ternary
    isError ? theme.strawberryRed : isActive ? theme.persianBlue : theme.blackRock};
  flex: none;
  overflow: hidden;
`;

export const Image = styled(FallbackImg)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: ${({ theme }) => theme.alpineGoat};
`;
