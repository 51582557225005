import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'fsd/app/redux/hooks';
import { logout } from 'fsd/features/logout';

const SignOutPage: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return <Navigate to="/" replace />;
};

export default SignOutPage;
