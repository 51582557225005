import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  margin-top: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 114%;
  a {
    color: ${({ theme }) => theme.blueRibbon};
  }
`;

export const Footer = styled.div`
  margin-top: 16px;
`;
