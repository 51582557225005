import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { InfoBlock } from 'fsd/shared/ui/InfoBlock';
import styled from 'styled-components';

export const Balance = styled.div`
  display: flex;
  align-items: center;
  gap: 24px 16px;
  justify-content: space-between;
  flex-wrap: wrap;
  & > * {
    flex: 1;
    min-width: min-content;
    white-space: nowrap;
  }
`;

export const Info = styled(InfoBlock)`
  margin-top: 32px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-top: 24px;
  }
`;

export const Slot = styled.div`
  border-top: 2px solid ${({ theme }) => theme.alpineGoat};
  margin-top: 32px;
  padding-top: 32px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-top: 16px;
    padding-top: 24px;
  }
`;
