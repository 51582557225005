import { FC } from 'react';
import { Button } from 'fsd/shared/ui/Buttons';

import useSend from '../../lib/useSend';

type Props = {
  purchaseId: string;
};

const SendButton: FC<Props> = ({ purchaseId }) => {
  const { send, isDisabled } = useSend();

  const handleClick = useCallback(() => {
    send(purchaseId);
  }, [send, purchaseId]);

  return (
    <Button type="primary" block size="large" disabled={isDisabled} onClick={handleClick}>
      Посмотреть в Telegram
    </Button>
  );
};

export default memo(SendButton);
