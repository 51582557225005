import { IconViews } from 'fsd/shared/icons';
import { colNames } from 'fsd/shared/lib/helpers/constants/channelConstants';
import { Icon } from 'fsd/shared/ui/Icon';

import { TableDataContext } from '../../../lib/tableDataContext';
import SortableTitle from '../../SortableTitle';

const Title = () => {
  const tableDataContext = useContext(TableDataContext);
  return (
    tableDataContext && (
      <SortableTitle
        className="usiteful__column-err"
        tooltipText={
          <>
            <strong>Engagement Rate by Reach за 24 часа</strong> <br />
            Отношение среднего охвата поста к среднему числу подписчиков за 24 часа после
            публикации, выраженное в процентах
          </>
        }
        icon={
          <Icon width={12} color={theme.persianBlue}>
            <IconViews />
          </Icon>
        }
        text={colNames.err}
        name="avg-daily-err"
        form={tableDataContext.form}
        values={tableDataContext.formValues}
      />
    )
  );
};

export default Title;
