import { FC } from 'react';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';
import Loader from 'fsd/shared/ui/Loader';

import { ListContext, ListContextProvider } from '../../lib/listContext';
import { UseListDataOptions } from '../../lib/useListData';
import Empty from '../Empty';
import MobileRows from '../MobileRows';
import Table from '../Table';

import * as S from './styles';

const List = memo(() => {
  const { containers } = useContext(ListContext) || {};
  const isMobile = useMediaQuery(MEDIA_DEVICE.mobileL.to);
  if (!containers?.data) {
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  }
  if (!containers.data.length) {
    return (
      <S.EmptyWrapper>
        <Empty />
      </S.EmptyWrapper>
    );
  }
  return isMobile ? <MobileRows /> : <Table />;
});

type ProviderProps = Pick<UseListDataOptions, 'filter'>;

const ListWithProvider: FC<ProviderProps> = ({ filter }) => {
  return (
    <ListContextProvider filter={filter}>
      <List />
    </ListContextProvider>
  );
};

export default memo(ListWithProvider);
