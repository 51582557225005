import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Id } from 'api/api';

import { catalogTabId } from 'components/ChannelTabs/constants';
import { getChannelId } from 'components/ChannelTabs/utils/get-channel-id';

type ChannelTabsState = {
  initialized: boolean;
  activeTabId: string;
  visibleEnd: number;
  channelIds: Id[];
};

const initialState: ChannelTabsState = {
  initialized: false,
  activeTabId: catalogTabId,
  visibleEnd: 0,
  channelIds: []
};

export const channelTabsSlice = createSlice({
  name: 'channelTabs',
  initialState,
  reducers: {
    initialize: (state, action: PayloadAction<string[]>) => {
      const channelIds = action.payload;

      state.channelIds = channelIds;
      state.initialized = true;
    },

    openTab: (state, action: PayloadAction<{ tabId: string; openInBackground?: boolean }>) => {
      const { tabId, openInBackground } = action.payload;

      if (tabId === catalogTabId) {
        state.activeTabId = tabId;
        return;
      }

      const channelId = getChannelId(tabId);

      if (!state.channelIds.includes(channelId)) {
        if (state.activeTabId === catalogTabId) {
          state.channelIds.unshift(channelId);
        } else {
          const activeChannelId = getChannelId(state.activeTabId);
          const activeChannelIndex = state.channelIds.findIndex(
            (channelId) => channelId === activeChannelId
          );

          state.channelIds.splice(activeChannelIndex, 0, channelId);
        }
      }

      if (!openInBackground) {
        state.activeTabId = tabId;
      }
    },

    closeTab: (state, action: PayloadAction<{ tabId: string }>) => {
      const { tabId } = action.payload;
      const channelId = getChannelId(tabId);

      state.channelIds = state.channelIds.filter((id) => id !== channelId);
    },

    closeAllTabs: (state) => {
      state.channelIds = [];
    },

    closeAllButOne: (state, action: PayloadAction<string>) => {
      const tabId = action.payload;
      const channelId = getChannelId(tabId);

      state.channelIds = [channelId];
    },

    setVisibleEnd: (state, action: PayloadAction<number>) => {
      state.visibleEnd = action.payload;
    }
  }
});

export const { openTab, initialize, closeTab, closeAllTabs, closeAllButOne, setVisibleEnd } =
  channelTabsSlice.actions;

export default channelTabsSlice.reducer;
