import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  .ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
      background: ${({ theme }) => theme.persianBlue} !important;
      color: ${({ theme }) => theme.white}!important;
    }
  }
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: ${({ theme }) => theme.persianBlue} !important;
      color: ${({ theme }) => theme.white}!important;
    }
  }
  .ant-picker-cell-today {
    .ant-picker-cell-inner {
      &:before {
        border-color: ${({ theme }) => theme.persianBlue} !important;
      }
    }
  }
  .ant-picker-ranges {
    display: flex;
    align-items: center;
    gap: 4px;
    & > * {
      padding-block: 0;
      margin: 0;
      flex: 1;
    }
  }

  @media ${MEDIA_DEVICE.mobile.to} {
    .ant-picker-date-panel {
      width: 230px !important;
    }
    .ant-picker-body {
      padding: 8px 10px !important;
      width: 100% !important;
      .ant-picker-content {
        width: 100% !important;
        th,
        td {
          width: 20px !important;
          min-width: 20px !important;
        }
      }
    }
    .ant-picker-time-panel-column {
      width: 40px !important;
    }
    .ant-picker-time-panel-cell-inner {
      width: 100% !important;
      padding: 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
`;
