import { FC } from 'react';

import useGetChannelErr from '../../../Table/lib/useGetChannelErr';

import * as S from './Err.styles';

type Props = {
  channelId: string;
};

const ErrValue: FC<Props> = ({ channelId }) => {
  const { value, isEmpty } = useGetChannelErr(channelId);
  return <S.Text>{isEmpty ? '–' : value ?? 0} %</S.Text>;
};

export default ErrValue;
