import reducePurchasesCoverage from '../../../lib/reducePurchasesCoverage';
import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    return reducePurchasesCoverage(b.purchases) - reducePurchasesCoverage(a.purchases);
  }
};

export default sorter;
