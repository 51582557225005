import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Icon } from 'fsd/shared/ui/Icon';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  gap: 10px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 8px;
    align-items: center;
  }
`;

export const IconWrapper = styled(Icon).attrs({
  width: 32,
  color: theme.persianBlue
})``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 4px;
  }
`;

export const Username = styled.small`
  font-size: 14px;
  font-weight: 400;
  line-height: 114%;
  color: ${({ theme }) => theme.bayFog};
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  color: ${({ theme }) => theme.blackRock};
  @media ${MEDIA_DEVICE.mobileL.to} {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
  }
`;
