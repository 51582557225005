export const isMobileDevice = () => {
  return /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
    navigator.userAgent
  );
};

export const useIsMobileDevice = () => {
  const [_isMobileDevice, setIsMobileDevice] = useState(isMobileDevice());

  return _isMobileDevice;
};
