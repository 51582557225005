import { FC, memo } from 'react';
import { HistoryStatisticsForListOfChannels, TimeWindow } from 'api/api';
import { calcChannelCoverage } from 'fsd/entities/channel';
import { cutNum } from 'fsd/shared/lib/helpers/tools';
import { DATE_RANGE } from 'types/channels/types';

import useGetChannelCoverage from '../../../Table/lib/useGetChannelCoverage';
import EmtpyData from '../EmtpyData';

import * as S from '../../styles';

type Props = {
  channelId: string;
  timeWindow: TimeWindow;
  isLoading: boolean;
};

const Reach: FC<Props> = ({ channelId, timeWindow, isLoading }) => {
  const viewsNumber = useGetChannelCoverage(channelId);

  if (isLoading || !viewsNumber) {
    return (
      <EmtpyData
        isLoading={isLoading}
        timeWindow={timeWindow === 'all' ? 'Всего' : DATE_RANGE[timeWindow].name}
        isHideChart={timeWindow === 'yesterday'}
      />
    );
  }

  return (
    <div>
      <S.DataValue>{cutNum(viewsNumber)}</S.DataValue>
      <small>{timeWindow === 'all' ? 'Всего' : DATE_RANGE[timeWindow].name}</small>
    </div>
  );
};

export default memo(Reach);
