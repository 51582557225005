import { InputProps, InputRef, Spin } from 'antd';
import { IconCross } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import { theme } from 'theme/theme';

import LoadingIcon from '../LoadingIcon';
import { DefaultProps } from '../types';
import Unclickable from '../Unclickable';

import * as S from './styles';

type Props = DefaultProps<InputProps>;

export const InputUI = forwardRef<InputRef, Props>(
  (
    {
      borderBottom,
      fontWeight,
      isError,
      allowClear,
      isLoading,
      onFocusCustom,
      styleType,
      ...restProps
    },
    ref
  ) => {
    let showedIcon = null;

    if (isLoading) {
      showedIcon = (
        <Unclickable>
          <Spin indicator={<LoadingIcon spin />} />
        </Unclickable>
      );
    } else if (allowClear) {
      showedIcon = (
        <Icon width={18} color={theme.violetPowder}>
          <IconCross />
        </Icon>
      );
    }

    return (
      <S.Root
        $fontWeight={fontWeight}
        $borderBottom={borderBottom}
        $isError={isError}
        $styleType={styleType}
        {...restProps}
        onFocus={(e) => {
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            { passive: false }
          );

          if (onFocusCustom) onFocusCustom(e);
        }}
        ref={ref}
        allowClear={
          showedIcon
            ? {
                clearIcon: showedIcon
              }
            : false
        }
      />
    );
  }
);

export default InputUI;

export { LoadingIcon };
