import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const ChannelContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: column;
  gap: 24px;
  padding-bottom: 20px;

  @media ${MEDIA_DEVICE.desktopL.from} {
    margin: 0 auto;
  }

  @media ${MEDIA_DEVICE.laptopS.to} {
    grid-template-rows: auto auto;
    grid-template-areas: 'participants reach' 'statistic statistic';
  }

  @media ${MEDIA_DEVICE.tablet.to} {
    grid-template-columns: 1fr;
    grid-template-areas: 'participants' 'reach' 'statistic';
  }
`;

export const ButtonContainer = styled.div`
  height: auto !important;
  position: fixed;
  z-index: 1080;
  bottom: 32px;
  right: 32px;
`;

export const ParticipantsWrapper = styled.div`
  @media ${MEDIA_DEVICE.laptopS.to} {
    grid-row: auto;
    grid-area: participants;
  }
`;

export const ReachWrapper = styled.div`
  @media ${MEDIA_DEVICE.laptopS.to} {
    grid-row: auto;
    grid-area: reach;
  }
`;

export const StatisticWrapper = styled.div`
  grid-row: span 3;
  @media ${MEDIA_DEVICE.laptopS.to} {
    grid-row: auto;
    grid-area: statistic;
  }
`;
