import dayjs from 'dayjs';
import RU from 'dayjs/locale/ru';
import { convert as convertNumberToWordsRu } from 'number-to-words-ru';

import invoiceHTML, { EInvoiceHTMLFields } from '../config/invoiceHTML';

export type CreateInvoiceHTMLArg = {
  amount: string;
  org: {
    id: string;
    createdAt: string | Date;
    type: string;
    name: string;
    inn: string;
    kpp: string;
  };
};

const createOrgInfo = ({ type, name, inn, kpp }: CreateInvoiceHTMLArg['org']) => {
  let result = `${type} «${name}»`;
  if (inn.length > 4) {
    result += `, ИНН ${inn}`;
  }
  if (kpp.length > 4) {
    result += `, КПП ${kpp}`;
  }
  return result;
};

const createFieldRegExp = (field: EInvoiceHTMLFields) => new RegExp(`{{${field}}}`, 'g');

export default ({ amount, org }: CreateInvoiceHTMLArg): string => {
  const amountString = Number(amount).toLocaleString('ru', {
    minimumFractionDigits: 2
  });

  const amountWords = convertNumberToWordsRu(Number(amount), {
    currency: 'rub'
  });

  const date = dayjs().locale(RU).format('DD MMMM YYYY г.');
  const number = `${org.id}080${Date.now()}А`;
  const deadLine = dayjs().add(3, 'day').format('DD.MM.YYYY');
  const orgInfo = createOrgInfo(org);
  const createOrgDate = dayjs(org.createdAt).format('DD MMMM YYYY');

  return invoiceHTML
    .replace(createFieldRegExp(EInvoiceHTMLFields.AMOUNT), amountString)
    .replace(createFieldRegExp(EInvoiceHTMLFields.AMOUNT_WORDS), amountWords)
    .replace(createFieldRegExp(EInvoiceHTMLFields.NUMBER), number)
    .replace(createFieldRegExp(EInvoiceHTMLFields.DATE), date)
    .replace(createFieldRegExp(EInvoiceHTMLFields.DEAD_LINE), deadLine)
    .replace(createFieldRegExp(EInvoiceHTMLFields.ORG_INFO), orgInfo)
    .replace(createFieldRegExp(EInvoiceHTMLFields.CREATE_ORG_DATE), createOrgDate);
};
