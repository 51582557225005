import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px var(--padding-x);
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
`;
