import { URLs } from 'fsd/shared/config/URLs';
import { IconSignOut, IconSupport } from 'fsd/shared/icons';
import { SideBarItem } from 'fsd/shared/ui/SideBar/types';

const footerItems: SideBarItem[] = [
  {
    key: 'support',
    icon: <IconSupport />,
    label: 'Написать в поддержку',
    to: URLs.SUPPORT_BOT,
    target: '_blank'
  },
  {
    key: 'signout',
    icon: <IconSignOut />,
    label: 'Выйти',
    to: '/signout'
  }
];

export default footerItems;
