import { PurchaseRead, useGetV2ChannelsByMetricTypeQuery } from 'api/api';
import dayjs from 'dayjs';

const dateRange = {
  from: dayjs().add(-1, 'day').startOf('day').toISOString(),
  to: dayjs().add(-1, 'day').endOf('day').toISOString()
};

export default (purchases: PurchaseRead[]) => {
  const { data: avgDailyReaches } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: purchases.map((purchase) => purchase.channel.info.id),
      metricType: 'avg-daily-reaches',
      historicalMetricsLoaded: false,
      timeZone: 'Europe/Moscow',
      dateRange
    },
    {
      skip: !purchases.length
    }
  );

  const { data: reaches } = useGetV2ChannelsByMetricTypeQuery(
    {
      ids: purchases.map((purchase) => purchase.channel.info.id),
      metricType: 'reaches',
      historicalMetricsLoaded: false,
      timeZone: 'Europe/Moscow',
      dateRange: {
        to: dateRange.to
      }
    },
    {
      skip: !purchases.length
    }
  );

  return {
    avgDailyReaches,
    reaches
  };
};
