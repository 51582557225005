import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Hour = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.majesticMount};

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
`;

export const Tooltip = styled.div`
  position: relative;
  will-change: transform;
  transfrom: translate3d(0, 0, 0);

  &:hover::after,
  &:hover::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
  }

  &:hover::after {
    content: attr(title);
    z-index: 10730;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    background: white;
    color: black;
    padding: 6px 8px;
    border-radius: 8px;
    white-space: nowrap;
    box-shadow:
      0px 38px 188px rgba(0, 0, 0, 0.09),
      0px 15.8755px 78.5419px rgba(0, 0, 0, 0.0646969),
      0px 8.4878px 41.9923px rgba(0, 0, 0, 0.0536497),
      0px 4.75819px 23.5405px rgba(0, 0, 0, 0.045),
      0px 2.52704px 12.5022px rgba(0, 0, 0, 0.0363503),
      0px 1.05156px 5.20244px rgba(0, 0, 0, 0.0253031);
  }
`;

export const Count = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.color};

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
`;

export const TDHours = styled.td`
  padding: 0px;

  min-width: 50px;
  width: 50px;
  max-width: 50px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 40px;
    max-width: 40px;
  }
`;

export const TDpeoples = styled.td`
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  padding: 0px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    min-width: 70px;
    width: 70px;
    max-width: 70px;
  }
`;

export const TDposts = styled.td`
  padding: 0;
`;
