import createScript from './createScript';
import insp from './insp';

let installed = false;

export default (wid: string) => {
  if (installed) return;
  // @ts-ignore
  window.__insp = window.__insp || [];

  insp(['wid', wid]);

  const init = () => {
    const insp = createScript(wid);

    const x = document.getElementsByTagName('script')[0];
    x.parentNode?.insertBefore(insp, x);
  };

  // eslint-disable-next-line no-unused-expressions
  document.readyState !== 'complete'
    ? window.addEventListener('load', init, false)
    : setTimeout(() => init(), 0);
  installed = true;
};
