import { FC, useMemo } from 'react';

import * as S from './styles';

type Props = {
  channelName: string;
  channelId?: string;
  width?: number;
};

const FakeIcon: FC<Props> = ({ channelName, channelId, width = 24 }) => {
  const splittedName = channelName.split(' ');
  const firsWord = splittedName[0];
  const lastWord = splittedName[splittedName.length - 1];

  const colorArr = ['#0062FF', '#DB3031', '#FF5CAA', '#FE7E07', '#A162F7', '#E80FE9', '#FF7A2F'];

  const colorItem = useMemo(() => {
    if (channelId) {
      const numericId = parseInt(channelId, 10);
      if (!Number.isNaN(numericId)) {
        return colorArr[numericId % colorArr.length];
      }
    }
    // Если channelId нет или он не числовой, выбираем случайный цвет
    return colorArr[Math.floor(Math.random() * colorArr.length)];
  }, [channelId, colorArr]);

  const initials =
    splittedName.length >= 2
      ? firsWord.toUpperCase()[0] + lastWord.toUpperCase()[0]
      : firsWord.toUpperCase()[0] + firsWord.toUpperCase()[1];
  return (
    <S.FakeIcon color={colorItem} width={width}>
      {initials}
    </S.FakeIcon>
  );
};

export default FakeIcon;
