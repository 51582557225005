import { TableDataContext } from './tableDataContext';

export default (channelId: string) => {
  const tableDataContext = useContext(TableDataContext);

  const err = useMemo(
    () =>
      tableDataContext?.errMetrics?.data?.channels?.find((item) => item.channel_id === channelId),
    [tableDataContext?.errMetrics?.data?.channels, channelId]
  );

  return {
    tableData: tableDataContext,
    err
  };
};
