export const blockNames = {
  participants: 'Подписчики',
  views: 'Просмотры',
  reaches: 'Средний охват',
  placements: 'Размещения в данном канале',
  mentions: 'Упоминания канала',
  detailedMentions: 'Упоминания (в среднем за день)'
};

export const colNames = {
  channelName: 'Название канала',
  users: 'Подписчики',
  usersGrowth: 'Прирост',
  views: 'Просмотры на пост',
  avg_daily_reaches: 'Средний охват',
  er: 'ERRa за сутки',
  price: 'Цена | CPP',
  mentions: 'Количество упоминаний',
  cpm: 'CPM',
  err: 'ERR'
};

export const channelFilters = {
  date_range: 'Период',
  participants: 'Подписчики',
  er: 'ER',
  mentions: 'Упоминания',
  views: 'Просмотры',
  is_public: 'Тип канала',
  is_verified: 'Верификация'
};
