import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  GetClientArg,
  GetClientResponse,
  GetOrganisationResponse,
  GetOrganisationsArg
} from './types';

export const strapiApi = createApi({
  reducerPath: 'strapi',
  tagTypes: ['StrapiClient', 'StrapiBalance', 'StrapiOrganisation'],
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_REACT_APP_STRAPI_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${import.meta.env.VITE_REACT_APP_STRAPI_READ_TOKEN}`);
      return headers;
    }
  }),
  endpoints: (build) => ({
    getClient: build.query<GetClientResponse, GetClientArg>({
      query: ({ id }) => `clients?filters[clientID][$eq]=${id}`,
      providesTags: ['StrapiClient', 'StrapiBalance']
    }),
    getOrganisations: build.query<GetOrganisationResponse, GetOrganisationsArg>({
      query: ({ id }) => `organisations/${id}`,
      providesTags: ['StrapiOrganisation', 'StrapiBalance']
    })
  })
});

export const {
  useGetClientQuery,
  useLazyGetClientQuery,
  useGetOrganisationsQuery,
  useLazyGetOrganisationsQuery
} = strapiApi;
