import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

// import { onServiceWorkerUpdate } from "@3m1/service-worker-updater";
// import { worker } from "../mocks/browser";
import App from './App';
import { initSentry } from './sentry';

// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import '../../theme/index.less';
import 'react-toastify/dist/ReactToastify.css';

import '@ungap/has-own';

declare global {
  interface Window {
    ignoredWarnings: Set<string>;
  }
}

// import "./helpers/tools/ignoreConsoleWarns";

// worker.start();

initSentry();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// serviceWorkerRegistration.register({
//   onUpdate: onServiceWorkerUpdate,
// });
