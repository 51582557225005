import { number, object } from 'zod';

export default ({ max }: { max: number }) =>
  object({
    amount: number({
      required_error: 'Введите сумму',
      invalid_type_error: 'Введите сумму'
    })
      .max(max, `Сумма вывода превышает доступные средства`)
      .positive()
      .transform((value) => (value ? value.toString() : undefined))
  });
