import type { ComponentProps, FC, PropsWithChildren, ReactElement } from 'react';
import { BetaModal as Modal } from 'fsd/shared/ui/Modal';

import * as S from './styles';

type Props = ComponentProps<typeof Modal> & {
  title: string;
  description: string | ReactElement;
  info: string;
};

const ManageModal: FC<PropsWithChildren<Props>> = ({
  title,
  description,
  info,
  children,
  ...restProps
}) => {
  return (
    <Modal {...restProps} title={title} width={375} closable destroyOnClose>
      <S.FormWrapper size="s">
        <p>{description}</p>
        <S.Content>{children}</S.Content>
      </S.FormWrapper>
      <S.Info variant="outlined" size="m">
        {info}
      </S.Info>
    </Modal>
  );
};

export default memo(ManageModal);
