import { ReactElement } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { type ToastProps } from 'react-toastify/dist/types';
import styled from 'styled-components';

import { IconCancel } from '../../icons';

import * as S from './ToastMessage.styles';

export const ToastTitle = styled.div<{ description?: string | JSX.Element }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

export const ToastDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  a {
    color: ${(props) => props.theme.white};
    text-decoration: underline;
  }
`;

export const FooterBlock = styled.div`
  margin-top: 8px;
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
`;

export const ToastTimeText = styled.div`
  color: #fff;

  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 200% */
  letter-spacing: -0.09px;
`;

export const CloseToast = styled.div`
  color: ${({ theme }) => theme.white};
  opacity: 1;
  flex: none;
  align-self: center;
  width: 20px;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${({ theme }) => theme.white};
    width: 20px;
    height: 20px;
  }
`;

export const IconBlock = styled.div`
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.white};
  }
`;

// timer is number in milisecons
export const ToastMessage = ({
  title,
  description,
  buttons,
  closable,
  closeToast,
  withoutClose,
  toastProps
}: {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  buttons?: ReactElement;
  closable?: boolean;
  closeToast?: () => void;
  withoutClose?: boolean;
  toastProps?: ToastProps;
}) => {
  return (
    <S.Root>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '12px'
        }}
      >
        <IconBlock>{toastProps?.iconOut}</IconBlock>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <ToastTitle description={description}>{title}</ToastTitle>

          <div
            style={{
              display: 'flex',
              justifySelf: 'flex-end',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            {toastProps?.autoClose && !description && buttons && (
              <CountdownCircleTimer
                isPlaying
                duration={toastProps?.autoClose / 1000}
                colors={['#fff', '#fff', '#fff', '#fff']}
                trailColor="#00"
                size={21}
                strokeWidth={2}
                colorsTime={[7, 5, 2, 0]}
              >
                {({ remainingTime }) => <ToastTimeText>{remainingTime}</ToastTimeText>}
              </CountdownCircleTimer>
            )}
            {!description && <S.ButtonsWrapper>{buttons}</S.ButtonsWrapper>}
            {closable && !withoutClose && (
              <CloseToast onClick={() => closeToast?.()}>
                <IconCancel />
              </CloseToast>
            )}
          </div>
        </div>
      </div>
      {description && (
        <FooterBlock>
          <ToastDescription>{description}</ToastDescription>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            {toastProps?.autoClose && buttons && (
              <CountdownCircleTimer
                isPlaying
                duration={toastProps?.autoClose / 1000}
                colors={['#fff', '#fff', '#fff', '#fff']}
                trailColor="#00"
                size={21}
                strokeWidth={2}
                colorsTime={[7, 5, 2, 0]}
              >
                {({ remainingTime }) => <ToastTimeText>{remainingTime}</ToastTimeText>}
              </CountdownCircleTimer>
            )}
            {buttons && <S.ButtonsWrapper>{buttons}</S.ButtonsWrapper>}
          </div>
        </FooterBlock>
      )}
    </S.Root>
  );
};
