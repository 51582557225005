import { useGetV1ExchangePurchasesContainersByContainerIdQuery } from 'api/api';

import { ColumnData } from './useColumns';
import useTableMetrics from './useTableMetrics';

export default (containerId: string) => {
  const purchasesData = useGetV1ExchangePurchasesContainersByContainerIdQuery(
    { containerId },
    {
      refetchOnFocus: true
    }
  );
  const dataSource: ColumnData[] = purchasesData.data ?? [];
  const metrics = useTableMetrics(purchasesData.data ?? []);

  return { purchasesData, dataSource, metrics, containerId };
};
