import { useGetV1UserQuery } from 'api/api';
import { useGetOrganisationsQuery } from 'fsd/entities/strapi';
import { getUserOrganisationId } from 'fsd/entities/user';

import { BalanceTypes } from '../model/types';

export default (): Record<BalanceTypes, number> => {
  const { data: user } = useGetV1UserQuery();
  const organisationId = user && getUserOrganisationId(user, 'crm');
  const { data: client } = useGetOrganisationsQuery(
    {
      id: organisationId!
    },
    {
      skip: !organisationId,
      refetchOnFocus: true
    }
  );

  return {
    active: client?.data?.attributes?.activeBalance ?? 0,
    blocked: client?.data?.attributes?.blockedBalance ?? 0,
    profit: client?.data?.attributes?.profitBalance ?? 0,
    withdrawal: client?.data?.attributes?.withdrawlBalance ?? 0
  };
};
