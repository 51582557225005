import { IconMegafon, IconReach, IconSpeaker, IconUsers, IconViews } from 'fsd/shared/icons';

export const getIcon = (iconType: string) => {
  switch (iconType) {
    case 'speaker':
      return <IconSpeaker />;
    case 'megafon':
      return <IconMegafon />;
    case 'participants':
      return <IconUsers />;
    case 'views':
      return <IconViews />;
    case 'reaches':
      return <IconReach />;
  }
};
