import { FC } from 'react';
import { PayForPurchase } from 'fsd/features/payForPurchase';
import { RepeatPurchase } from 'fsd/features/repeatPurchase';
import { SendPurchase } from 'fsd/features/sendPurchase';
import { IconAlertSuccess, IconCrossLine, IconSent } from 'fsd/shared/icons';

import { TableContext } from '../../../lib/tableContext';
import { DefaultColumnCellProps } from '../../../types';

import * as S from './Cell.styles';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  const tableData = useContext(TableContext);
  if (!tableData) return null;

  if (purchase.status === 'pending')
    return (
      <S.Root>
        <S.TagsWrapper>
          <S.TagStatus startIcon={<IconSent />}>заявка отправлена</S.TagStatus>
        </S.TagsWrapper>
      </S.Root>
    );

  if (purchase.status === 'cancelled')
    return (
      <S.Root>
        <S.TagsWrapper>
          <S.TagStatus startIcon={<IconCrossLine />} startIconColor={theme.strawberryRed}>
            отменена вами
          </S.TagStatus>
        </S.TagsWrapper>
      </S.Root>
    );

  if (purchase.status === 'declined' || purchase.status === 'broken')
    return (
      <S.Root>
        <S.TagsWrapper>
          <S.TagStatus startIcon={<IconCrossLine />} startIconColor={theme.strawberryRed}>
            заявка отклонена
          </S.TagStatus>
        </S.TagsWrapper>
        <RepeatPurchase purchaseId={purchase.purchase_id} containerId={tableData.containerId} />
      </S.Root>
    );

  if (purchase.status === 'planned')
    return (
      <S.Root>
        <S.TagsWrapper>
          <S.TagStatus startIcon={<IconAlertSuccess />}>пост запланирован</S.TagStatus>
        </S.TagsWrapper>
      </S.Root>
    );

  if (purchase.status === 'published')
    return (
      <S.Root>
        <S.TagsWrapper>
          <S.TagStatus startIcon={<IconAlertSuccess />}>пост опубликован</S.TagStatus>
        </S.TagsWrapper>
        {!purchase.is_paid && <PayForPurchase purchase={purchase} />}
      </S.Root>
    );

  // @ts-ignore
  if (purchase.status === 'unpublished')
    return (
      <S.Root>
        <S.TagsWrapper>
          <S.TagStatus startIcon={<IconAlertSuccess />}>пост не опубликован вовремя</S.TagStatus>
        </S.TagsWrapper>
      </S.Root>
    );

  if (purchase.status === 'deleted')
    return (
      <S.Root>
        <S.TagsWrapper>
          <S.TagStatus startIcon={<IconAlertSuccess />}>заявка завершена</S.TagStatus>
        </S.TagsWrapper>
        {!purchase.is_paid && <PayForPurchase purchase={purchase} />}
      </S.Root>
    );

  return (
    <S.Root>
      <SendPurchase purchase={purchase} containerId={tableData.containerId} />
    </S.Root>
  );
};

export default memo(Cell);
