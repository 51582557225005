import { FC } from 'react';
import { Field } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';
import { Radio } from 'antd';
import { FormApi } from 'final-form';
import { flattenObj } from 'fsd/shared/lib/helpers/tools';
import { DATE_RANGE, FilterState } from 'types/channels/types';

import { FormValues } from '../../types';

import * as S from './styles';

type Props = {
  form?: FormApi<FormValues, Partial<FormValues>>;
  values?: FilterState;
};

export const PeriodFilter: FC<Props> = ({ form, values }) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <Field
      name="date_range"
      type="text"
      render={({ input }) => (
        <S.Group
          id="date_range"
          className="usiteful__period-filter"
          {...input}
          onChange={(e) => {
            setSearchParams(
              flattenObj({
                ...values,
                pagination: {
                  offset: 0,
                  limit: 20
                },
                date_range: e.target.value
              })
            );
            form?.change('pagination', {
              offset: 0,
              limit: 20
            });
            form?.submit();

            input.onChange(e);
            form?.submit();
          }}
        >
          <S.Button className="usiteful__period-yesterday" value={DATE_RANGE.yesterday.value}>
            {DATE_RANGE.yesterday.name}
          </S.Button>
          <S.Button className="usiteful__period-week" value={DATE_RANGE.week.value}>
            {DATE_RANGE.week.name}
          </S.Button>
          <S.Button className="usiteful__period-month" value={DATE_RANGE.month.value}>
            {DATE_RANGE.month.name}
          </S.Button>
          {/* <Radio.Button value={DATE_RANGE.all.value}>
            {DATE_RANGE.all.name}
          </Radio.Button> */}
        </S.Group>
      )}
    />
  );
};

export default PeriodFilter;
