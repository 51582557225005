/* eslint-disable jsx-a11y/alt-text */
import { FC, ImgHTMLAttributes, ReactEventHandler, ReactNode, SyntheticEvent } from 'react';

type IsFallbackFnData = { event: SyntheticEvent<HTMLImageElement, Event> };
// eslint-disable-next-line no-unused-vars
type IsFallbackFn = (data: IsFallbackFnData) => boolean;

type Props = {
  fallback?: ReactNode;
  onError?: () => void;
  isFallbackFn?: IsFallbackFn;
} & ImgHTMLAttributes<HTMLImageElement>;

const FallbackImg: FC<Props> = ({
  fallback,
  onError,
  isFallbackFn: _isFallbackFn,
  ...restProps
}) => {
  const [forceFallback, setForceFallback] = useState(false);
  const [isError, setIsError] = useState(false);

  const isFallbackFn = useCallback(
    (data: IsFallbackFnData) => {
      if (_isFallbackFn) {
        setForceFallback(_isFallbackFn(data));
      } else {
        setForceFallback(false);
      }
    },
    [_isFallbackFn]
  );

  const handleLoad = useCallback<ReactEventHandler<HTMLImageElement>>(
    (event) => {
      if (isFallbackFn) {
        setForceFallback(true);
        isFallbackFn?.({ event });
      } else {
        setForceFallback(false);
      }
    },
    [isFallbackFn]
  );

  const handleError = useCallback<ReactEventHandler<HTMLImageElement>>(
    (event) => {
      isFallbackFn?.({ event });
      setIsError(true);
      onError?.();
    },
    [onError, isFallbackFn]
  );

  return isError || forceFallback ? (
    fallback || <img {...restProps} onError={handleError} onLoad={handleLoad} />
  ) : (
    <img {...restProps} onError={handleError} onLoad={handleLoad} />
  );
};

export default memo(FallbackImg);
export type FallbackImgProps = Props;
