import { ComponentProps, FC } from 'react';
import { IconEye } from 'fsd/shared/icons';
import { OutlinedButton } from 'fsd/shared/ui/Buttons/OutlinedButton';

import ViewModal from '.';

type Props = Pick<ComponentProps<typeof ViewModal>, 'actions' | 'channelId' | 'postId'>;

const PreviewModalOpen: FC<Props> = ({ channelId, postId, actions }) => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <ViewModal
        open={open}
        onClose={handleClose}
        channelId={channelId}
        postId={postId}
        actions={actions}
      />
      <OutlinedButton block startIcon={<IconEye />} onClick={handleClick}>
        Пост
      </OutlinedButton>
    </>
  );
};

export default PreviewModalOpen;
