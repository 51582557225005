import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { ReactComponent as ArrowDownIcon } from 'fsd/shared/icons/arrow-down.svg';
import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const MobileSubjectsButton = styled.button`
  display: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    border: none;
    gap: 8px;
    align-items: center;
    display: flex;
    background-color: ${({ theme }) => theme.white};
    padding: 9px 16px;
    color: ${({ theme }) => theme.blackRock};
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 18px;
  }
`;

export const SubjectsButtonText = styled.span``;

export const SubjectsButton = styled.button`
  border: none;
  background-color: transparent;
  gap: 8px;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
  padding: 0 0 0 12px;
  display: flex;
  cursor: pointer;
  height: 22px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const DropdownArrow = styled(ArrowDownIcon)`
  width: 18px;
  height: 18px;
`;

export const FilterCount = styled.span`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: ${({ theme }) => theme.bethlehemRed};
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  top: -7px;
  left: 11px;
`;

export const FilterCountWrapper = styled.div`
  position: relative;
`;

export const WrapperIconSubj = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

export const RelativeForm = styled.form`
  position: relative;
`;

export const AbsoluteSafeButton = styled(Button)`
  bottom: env(safe-area-inset-bottom);
  position: absolute;
`;
