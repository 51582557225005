import { FC, PropsWithChildren, ReactNode } from 'react';

import * as S from './styles';

type TagData = {
  key: string;
  content?: ReactNode;
};

type Props = {
  info?: ReactNode;
  buttons?: ReactNode;
  mainTags?: TagData[];
  tags?: TagData[];
  date?: ReactNode;
  post?: ReactNode;
  format?: ReactNode;
};

const Row: FC<PropsWithChildren<Props>> = ({
  info,
  buttons,
  mainTags,
  tags,
  children,
  date,
  post,
  format
}) => {
  return (
    <S.Root>
      <S.Main>
        {info && <S.InfoWrapper>{info}</S.InfoWrapper>}
        {!!mainTags?.length && (
          <S.MainTagList>
            {mainTags.map((tag) => (
              <S.TagItem key={tag.key}>{tag.content}</S.TagItem>
            ))}
          </S.MainTagList>
        )}
        {buttons && <S.MainButtons>{buttons}</S.MainButtons>}
      </S.Main>
      {date && <S.FieldWrapper>{date}</S.FieldWrapper>}
      {post && <S.FieldWrapper>{post}</S.FieldWrapper>}
      {!!tags?.length && (
        <S.BaseTagList>
          {tags.map((tag) => (
            <S.TagItem key={tag.key}>{tag.content}</S.TagItem>
          ))}
        </S.BaseTagList>
      )}
      {format && <S.FormatWrapper>{format}</S.FormatWrapper>}
      {children && <S.Footer>{children}</S.Footer>}
    </S.Root>
  );
};

export default memo(Row);
