import React, { useRef } from 'react';
import { Chart } from 'chart.js';
import { CompactChart } from 'fsd/shared/ui/CompactChart';

import { theme } from 'theme/theme';

import * as S from '../../styles';

const fakeData = [
  {
    x: +new Date(),
    y: 900
  },
  {
    x: +new Date() + 1000,
    y: 1200
  },
  {
    x: +new Date() + 2000,
    y: 1500
  },
  {
    x: +new Date() + 3000,
    y: 1600
  },
  {
    x: +new Date() + 4000,
    y: 3500
  },
  {
    x: +new Date() + 5000,
    y: 3100
  },
  {
    x: +new Date() + 6000,
    y: 3300
  },
  {
    x: +new Date() + 7000,
    y: 3500
  },
  {
    x: +new Date() + 8000,
    y: 3800
  },
  {
    x: +new Date() + 9000,
    y: 4000
  },
  {
    x: +new Date() + 10000,
    y: 4000
  },
  {
    x: +new Date() + 11000,
    y: 3800
  },
  {
    x: +new Date() + 12000,
    y: 3500
  },
  {
    x: +new Date() + 13000,
    y: 3200
  },
  {
    x: +new Date() + 14000,
    y: 3200
  },
  {
    x: +new Date() + 15000,
    y: 3500
  },
  {
    x: +new Date() + 16000,
    y: 5000
  },
  {
    x: +new Date() + 17000,
    y: 4600
  },
  {
    x: +new Date() + 18000,
    y: 6000
  },
  {
    x: +new Date() + 19000,
    y: 5800
  },
  {
    x: +new Date() + 20000,
    y: 5500
  },
  {
    x: +new Date() + 21000,
    y: 4000
  },
  {
    x: +new Date() + 22000,
    y: 4600
  },
  {
    x: +new Date() + 23000,
    y: 6000
  },
  {
    x: +new Date() + 24000,
    y: 5800
  },
  {
    x: +new Date() + 25000,
    y: 5500
  },
  {
    x: +new Date() + 26000,
    y: 4000
  }
];

const EmtpyLineChart = () => {
  const chartRef = useRef<Chart>(null);

  const graphData = [
    {
      type: 'line',
      label: 'NoData',
      lineTension: 0.2,
      borderWidth: 1,
      barThickness: 2,
      borderColor: theme.violetPowder,
      data: fakeData
    }
  ];

  return (
    <S.ChartWrapper>
      <CompactChart data={graphData} chartref={chartRef} animation={false} />
    </S.ChartWrapper>
  );
};

export default memo(EmtpyLineChart);
