import insp from './lib/insp';
import install from './lib/install';
import setMeta from './lib/setMeta';

export class InspectletJS {
  static install = install;

  static insp = insp;

  static setMeta = setMeta;
}
