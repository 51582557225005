import { FC } from 'react';
import { ChannelsFindResult } from 'api/api';

import SearchListWrapper from './components/SearchListWrapper';

import * as S from './styles';

type Props = {
  searchStr: string;
  requestStr: string;
  data?: ChannelsFindResult;
  loading?: boolean;
  onItemClick(channelId: string): void;
  showSearchByDescription?: boolean;
  minChars?: number;
};

const SearchResult: FC<Props> = ({
  data,
  loading,
  searchStr,
  requestStr,
  onItemClick,
  showSearchByDescription = false,
  minChars = 3
}) => {
  if (loading || !searchStr.length) {
    return null;
  }

  if (searchStr.length < minChars) {
    return (
      <S.Paper>
        <S.NoDataContainer>
          <S.IconNotFound>🕵️‍♂️</S.IconNotFound>
          <S.NoDataDescription>Введите хотя бы 3 символа чтобы начать поиск</S.NoDataDescription>
        </S.NoDataContainer>
      </S.Paper>
    );
  }

  if (requestStr?.trim() !== searchStr.trim()) return null;

  const isLink = searchStr.startsWith('https://t.me') || searchStr.startsWith('t.me');
  const channelName = isLink ? searchStr.replace(/^(https?:\/\/)?(t\.me\/)?/i, '') : null;
  const goodLink = channelName && channelName !== 't.me';

  if ((!data?.channels?.length && !data?.descriptions?.length) || (isLink && !goodLink)) {
    return (
      <S.Paper>
        <S.NoDataContainer>
          <S.IconNotFound>🕵️‍♂️</S.IconNotFound>
          <S.NoDataDescription>
            {isLink
              ? `️По этой ссылке в нашей базе нет каналов, мы продолжим его поиск в Telegram. Нам понадобится несколько минут...`
              : `Нет результатов по запросу "${searchStr}"`}
          </S.NoDataDescription>
        </S.NoDataContainer>
      </S.Paper>
    );
  }

  return (
    <S.Paper>
      <S.Title>
        Каналы
        <S.FindedChannels>найдено {data.channels?.length || 0}</S.FindedChannels>
      </S.Title>
      {data.channels?.length && (
        <SearchListWrapper
          channels={data.channels}
          searchStr={searchStr}
          onItemClick={onItemClick}
          type="channel"
          initiallyDisplayedNumber={8}
        />
      )}
      {showSearchByDescription && data.descriptions?.length && (
        <>
          <S.Title>
            Описание
            <S.FindedChannels>найдено {data.descriptions?.length}</S.FindedChannels>
          </S.Title>
          {data.descriptions?.length && (
            <SearchListWrapper
              channels={data?.descriptions}
              searchStr={searchStr}
              onItemClick={onItemClick}
              type="description"
              initiallyDisplayedNumber={2}
            />
          )}
        </>
      )}
    </S.Paper>
  );
};

export default SearchResult;
