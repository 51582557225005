import { useGetV1UserQuery } from 'api/api';
import { getUserOrganisationId } from 'fsd/entities/user';
import { CreateOrganisation } from 'fsd/features/createOrganisation';
import { EditOrganisation } from 'fsd/features/editOrganisation';

const Form = () => {
  const { data: user, isLoading } = useGetV1UserQuery();

  const organisationId = user && getUserOrganisationId(user, 'crm');
  if (isLoading) return null;
  return organisationId ? <EditOrganisation /> : <CreateOrganisation />;
};

export default memo(Form);
