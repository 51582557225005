import {
  ChannelExchangeFormatPrice,
  ChannelExchangeFormats,
  UserChannelExchangeFormatV2
} from 'api/api';
import { parseFormatTime } from 'fsd/entities/format';

type Result = {
  format: string;
  format_id: number;
  top: number;
  feed: number;
  price: string;
  priceNumber: number;
  currency: number;
};

export default (
  formats: ChannelExchangeFormats[] | undefined,
  formatsInfo: UserChannelExchangeFormatV2[] | undefined
) => {
  if (!formats || !formatsInfo) {
    return [];
  }

  const result: Result[] = [];

  formats.forEach((format: ChannelExchangeFormats) => {
    format?.prices?.forEach((price: ChannelExchangeFormatPrice) => {
      if (format.is_active) {
        const currentFormatInfo = formatsInfo.find((f) => f.format_id === format.format_id);
        if (currentFormatInfo) {
          const formatName = parseFormatTime(currentFormatInfo)?.with.short ?? '';
          result.push({
            format: formatName,
            top: currentFormatInfo.time_in_top_minutes,
            feed: currentFormatInfo.time_in_feed_minutes,
            format_id: format.format_id,
            price: `${price.price}`,
            priceNumber: Number(price.price),
            currency: price.currency_id
          });
        }
      }
    });
  });

  return result;
};
