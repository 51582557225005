import {
  api,
  GetV2ChannelsByMetricTypeApiArg,
  HistoryStatistic2,
  HistoryStatisticsForListOfChannels
} from 'api/api';

export type ChanelMetricsWithCacheResult = {
  data: HistoryStatisticsForListOfChannels;
  resetCache: () => void;
};

export default (queryArgs: GetV2ChannelsByMetricTypeApiArg) => {
  const cache = useRef(new Map());
  const dispatch = useAppDispatch();

  const cachedIds = Array.from(cache.current.keys());

  const newIds = useMemo(
    () => queryArgs.ids.filter((id) => !cachedIds.includes(id)),
    [cachedIds, queryArgs.ids]
  );

  const resetCache = useCallback(() => cache.current.clear(), []);

  return useCallback(
    async (): Promise<ChanelMetricsWithCacheResult> => {
      if (newIds.length) {
        const result = await dispatch(
          api.endpoints.getV2ChannelsByMetricType.initiate({ ...queryArgs, ids: newIds })
        ).unwrap();
        result?.channels?.forEach((channel) => {
          cache.current.set(channel.channel_id, channel);
        });
        const allData = (() => {
          const newChannels = result?.channels ?? [];
          return { channels: [...newChannels, ...cache.current.values()] };
        })();
        return {
          data: allData,
          resetCache
        };
      }
      return {
        data: { channels: [...cache.current.values()] as unknown as HistoryStatistic2[] },
        resetCache
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetCache, newIds]
  );
};
