import { FC, PropsWithChildren } from 'react';
import { ModalProps } from 'antd';
import { BalanceManageModal } from 'fsd/entities/balance';

type Props = {
  open?: boolean;
} & ModalProps;

const Modal: FC<PropsWithChildren<Props>> = ({ open, children, ...restProps }) => {
  return (
    <BalanceManageModal
      {...restProps}
      title="Пополнение баланса"
      description={<>Для пополнения баланса укажите сумму и оплатите счет</>}
      info="После поступления средств на счёт они отобразятся в вашем кабинете в разделе Управления финансами"
      open={open}
    >
      {children}
    </BalanceManageModal>
  );
};

export default memo(Modal);
