import {
  Format,
  useLazyGetV1ChannelsByChannelIdQuery,
  useLazyGetV2UserChannelFormatsQuery,
  usePatchV1ExchangePurchaseUpdateMutation,
  UserChannelExchangeFormatV2
} from 'api/api';
import parseChannelFormatsWithFormats from 'fsd/entities/channel/lib/parseChannelFormatsWithFormats';

type Data = {
  channelId: string;
  formatId: number;
  purchaseId: string;
};

const getFormatData = (format: UserChannelExchangeFormatV2): Format | undefined => {
  return {
    time_in_ad_hours: format.time_in_feed_minutes,
    time_in_top_hours: format.time_in_top_minutes
  };
};

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatePurchase] = usePatchV1ExchangePurchaseUpdateMutation();
  const [getFormat] = useLazyGetV2UserChannelFormatsQuery();
  const [getChannel] = useLazyGetV1ChannelsByChannelIdQuery();

  const fix = useCallback(
    async ({ channelId, formatId, purchaseId }: Data) => {
      setIsLoading(true);
      try {
        const channelData = await getChannel({ channelId }).unwrap();
        const [format] = await getFormat({ formatsId: [formatId] }).unwrap();

        const channelFormat = parseChannelFormatsWithFormats(channelData.exchange_info?.formats, [
          format
        ])[0];
        const formatData = getFormatData(format);

        if (formatData) {
          await updatePurchase({
            // @ts-ignore
            updatePurchase: {
              purchase_id: purchaseId,
              currency: 'RUB',
              format: formatData,
              cost: channelFormat.priceNumber
            }
          }).unwrap();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [getChannel, getFormat, updatePurchase]
  );

  return [fix, { isLoading }] as const;
};
