import { linkRegex } from 'fsd/shared/lib/isLink';
import { number, object, string } from 'zod';

export default object({
  targetChannelLink: string().regex(linkRegex, 'Укажите ссылку на канал'),
  price: number({
    required_error: 'Введите сумму',
    invalid_type_error: 'Введите сумму'
  }).positive()
});
