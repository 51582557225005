import { ChangeEventHandler, CSSProperties, FC } from 'react';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';

import { ChannelsContext } from '../Provider';

import * as S from '../styles';

type Props = {
  style?: CSSProperties;
  className?: string;
};

const ChannelsSearch: FC<Props> = ({ style, className }) => {
  const context = useContext(ChannelsContext);
  const isMobile = useMediaQuery(MEDIA_DEVICE.mobileL.to);
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      context?.setSearchStr(e.target.value);
    },
    [context]
  );

  return (
    context && (
      <S.InputSearch
        prefix={<S.SearchIcon />}
        allowClear
        style={style}
        name="keywords"
        type="search"
        styleType={isMobile ? 'white' : undefined}
        placeholder="Поиск"
        autoComplete="off"
        value={context.searchStr}
        onChange={handleChange}
        className={`usiteful__purchase__search-channel-input ${className}`}
      />
    )
  );
};

export default memo(ChannelsSearch);
