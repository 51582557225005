import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  ...theme,
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: '#000',
          borderRadius: 32,
          border: 'none',
          backgroundColor: '#FFF',
          fontSize: 12,
          fontWeight: 400,
          '&.Mui-selected': {
            ':hover': {
              backgroundColor: theme.persianBlue
            }
          }
        },
        today: {
          color: theme.persianBlue,
          borderColor: theme.persianBlue,
          ':not(.Mui-selected)': {
            borderColor: theme.persianBlue,
            border: '1px solid'
          }
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {}
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: 14,
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: '1.25px'
        }
      }
    },
    MuiClock: {
      styleOverrides: {
        pin: {
          backgroundColor: theme.persianBlue
        }
      }
    },
    MuiClockPointer: {
      styleOverrides: {
        root: {
          backgroundColor: theme.persianBlue
        },
        thumb: {
          borderColor: theme.persianBlue,
          border: `12px solid ${theme.persianBlue}`,
          top: '-18px',
          left: 'calc(50% - 14px)'
        }
      }
    },
    MuiClockNumber: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: theme.white,
            backgroundColor: theme.persianBlue
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          maxHeight: 32,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: 16,
          background: theme.alpineGoat
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: 5,
          border: `1px solid ${theme.alpineGoat}`
        }
      }
    }
  }
});

export default muiTheme;
