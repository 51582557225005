import { Modal } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { ReactComponent as AlternativeCloseIcon } from 'fsd/shared/icons/alternative-close.svg';
import { ReactComponent as CloseIcon } from 'fsd/shared/icons/close.svg';
import styled from 'styled-components';

export const Root = styled(Modal)`
  @media ${MEDIA_DEVICE.mobileL.to} {
    bottom: 0;
    top: initial;
    left: 0;
    right: 0;
    position: absolute;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const CloseModalIcon = styled(CloseIcon)<{ $topPosition?: number }>`
  position: absolute;
  top: ${({ $topPosition }) => $topPosition || 27}px;
  right: 20px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 20px;
    height: 20px;
  }
`;

export const AlternativeCloseModalIcon = styled(AlternativeCloseIcon)<{
  $topPosition?: number;
}>`
  position: absolute;
  top: ${({ $topPosition }) => $topPosition || 27}px;
  right: 20px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 20px;
    height: 20px;
  }
`;
