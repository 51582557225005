import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  text-align: center;
`;

export const LabelWrapper = styled.ul`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 10px 0;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 32px;
`;

export const Label = styled.li`
  text-align: center;
  padding: 0 10px;
  width: 100%;
  white-space: nowrap;
  &:not(:first-child) {
    @media ${MEDIA_DEVICE.mobileL.from} {
      border-left: 2px solid ${({ theme }) => theme.alpineGoat};
    }
  }
`;

export const LabelTitle = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 114%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LabelValue = styled.strong`
  display: block;
  font-size: 28px;
  font-weight: 700;
  line-height: 123%;
  margin-top: 8px;
`;

export const Slot = styled.div`
  margin-top: 24px;
`;
