import { ComponentProps, FC } from 'react';
import { useLazyGetUserOrganisation } from 'fsd/entities/user';
import { CreateOrganisationModal } from 'fsd/features/createOrganisation';
import { IconWalletFill } from 'fsd/shared/icons';
import { Button as SharedButton } from 'fsd/shared/ui/Buttons';

import { RechargeBalanceModalWithForm } from '../Form';

type Props = ComponentProps<typeof SharedButton> & {
  onSuccess?: () => void;
};

const Button: FC<Props> = ({ children, onSuccess, ...restProps }) => {
  const [openModal, setOpenModal] = useState(false);
  const [getUserOrganisation] = useLazyGetUserOrganisation();
  const [createOrganisationOpen, setCreateOrganisationOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleClick = useCallback(() => {
    getUserOrganisation().then((res) => {
      if (res?.data?.data) {
        setOpenModal(true);
      } else {
        setCreateOrganisationOpen(true);
      }
    });
  }, [getUserOrganisation]);

  const handleCreateOrganisationClose = useCallback(() => {
    setCreateOrganisationOpen(false);
  }, []);

  const handleCreateOrganisationSuccess = useCallback(() => {
    setCreateOrganisationOpen(false);
    setOpenModal(true);
  }, []);
  return (
    <>
      <CreateOrganisationModal
        open={createOrganisationOpen}
        onClose={handleCreateOrganisationClose}
        onSuccess={handleCreateOrganisationSuccess}
      />
      <RechargeBalanceModalWithForm open={openModal} onClose={handleClose} />
      <SharedButton
        type="primary"
        size="middle"
        onClick={handleClick}
        endIcon={<IconWalletFill />}
        {...restProps}
      >
        {children || 'Пополнить'}
      </SharedButton>
    </>
  );
};

export default memo(Button);
