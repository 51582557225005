import { createContext, FC, PropsWithChildren } from 'react';

import useFind from './useFind';

type ContextValue = ReturnType<typeof useFind>;

export const FindContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  value: ContextValue;
};

export const FindContextProvider: FC<PropsWithChildren<Props>> = ({ children, value }) => {
  return <FindContext.Provider value={value}>{children}</FindContext.Provider>;
};
