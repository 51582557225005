import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 32px;
  margin-top: 25px;
  @media ${MEDIA_DEVICE.mobile.to} {
    gap: 24px;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 114%;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 12px 24px;
  flex-wrap: wrap;
  @media ${MEDIA_DEVICE.mobile.to} {
    flex-direction: column;
  }
  & > * {
    @media ${MEDIA_DEVICE.mobile.to} {
      width: 100%;
      flex: none;
    }
  }
`;

export const FullButton = styled(Button)`
  flex: 1;
  @media ${MEDIA_DEVICE.mobile.to} {
    width: 100%;
    flex: none;
  }
`;
