import { PurchasesListContainer } from 'api/api';
import { useGetContainerById } from 'fsd/entities/container';

export type ContainerPageData = {
  container?: PurchasesListContainer;
};

type ContainerPageDataOptions = {
  containerId: string;
};

export default ({ containerId }: ContainerPageDataOptions) => {
  const { data: container } = useGetContainerById(containerId);

  return {
    container
  };
};
