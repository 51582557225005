import { FC } from 'react';
import { SetPurchaseDateWithTime } from 'fsd/features/setPurchaseDateWithTime';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps & { block?: boolean };

const Cell: FC<Props> = ({ purchase, block }) => {
  return <SetPurchaseDateWithTime purchase={purchase} block={block} />;
};

export default memo(Cell);
