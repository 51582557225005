import { FC } from 'react';
import { ModalFullPage } from 'fsd/shared/ui/Modal';

import { SideBarProps } from '../../types';

import Button from './Button';
import Menu from './Menu';

type Props = SideBarProps;

const Mobile: FC<Props> = ({ ...restProps }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <ModalFullPage open={modalOpen} destroyOnClose onCancel={handleCancel}>
        <Menu {...restProps} onClose={handleCancel} />
      </ModalFullPage>
      <Button onClick={handleButtonClick} />
    </>
  );
};

export default memo(Mobile);
