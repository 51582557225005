/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chart } from 'chart.js';
import { format, parse, toDate } from 'date-fns';
import { formatNumber } from 'fsd/shared/lib/helpers/tools';
import { theme } from 'theme/theme';
import { useDebouncedCallback } from 'use-debounce';

import 'chartjs-adapter-date-fns';

enum ZoomMode {
  X = 'x',
  Y = 'y',
  XY = 'xy'
}

export type PointClipNone = {
  isEnabled: boolean;
  paddingRight: number;
};

interface IOptions {
  start: number;
  end: number;
  onZoomChange?: (from: number, to: number, chart: any) => void;
  hasScaleLines?: boolean;
  onClick?: any;
  withTime?: boolean;
  withDate?: boolean;
  timeUnit?: string;
  tickOptions?: Record<string, any>;
  mode?: 'nearest' | 'index' | 'point';
  suggestedMaxY?: number;
  suggestedMinX?: number;
  suggestedMaxX?: number;
  pointClipNone?: PointClipNone;
}

const ViewsIcon = new Image(12, 12);
ViewsIcon.src = '/icons/views.svg';

const ParticipantsIcon = new Image(12, 12);
ParticipantsIcon.src = '/icons/users.svg';

const MentionsIcon = new Image(12, 12);
MentionsIcon.src = '/icons/at.svg';

type ImagesType = {
  [key: string]: HTMLImageElement;
};
const images: ImagesType = {
  Views: ViewsIcon,
  Participants: ParticipantsIcon,
  Mentions: MentionsIcon
};

export const useGetChartOptions = (props: IOptions) => {
  const {
    onZoomChange,
    start,
    end,
    hasScaleLines,
    onClick,
    withTime,
    withDate,
    timeUnit,
    tickOptions,
    mode,
    suggestedMaxY,
    suggestedMinX,
    suggestedMaxX,
    pointClipNone
  } = props;

  let isZooming = false;

  const handleDebouncedResize = onZoomChange
    ? useDebouncedCallback(
        ({ chart }: { chart: Chart }) => {
          const { min, max } = chart.scales.x;
          if (chart.options.interaction) {
            chart.options.interaction.intersect = false;
          }
          chart.update();
          if (chart.options.interaction) {
            chart.options.interaction.intersect = false;
          }
          chart.update();
          onZoomChange(min, max, chart);
          isZooming = false;
        },
        150,
        {}
      )
    : null;

  const handleThrottledZoomStart = ({ chart, event }: { chart: Chart; event: MouseEvent }) => {
    if (!isZooming) {
      // Если событие не связано с zoom, то выходим из функции
      if (event.type === 'mousedown') return;
      // Для того, чтобы график не реагировал на любое положение курсора и не показывал тултипы
      // После завершения зума true меняем на false
      if (chart.options.interaction) {
        chart.options.interaction.intersect = true;
      }
      chart.update();
    }
    isZooming = true;
  };

  const scales = {
    x: {
      position: 'bottom',
      grid: {
        zeroLineColor: 'transparent',
        drawBorder: false,
        drawOnChartArea: false,
        display: false,
        borderWidth: 0
      },
      min: suggestedMinX || start,
      max: suggestedMaxX || end,
      type: 'time',
      ticks: {
        font: {
          size: 10
        },
        letterSpacing: 0.5,
        maxRotation: 0,

        // for showcase
        // backdropColor: "#fff000",
        // backdropPadding: 0,
        // showLabelBackdrop: true,

        // Can canfigurate ticks
        align: 'start',
        autoSkipPadding: 8,
        maxTicksLimit: 8,
        ...tickOptions
      },
      time: {
        ...(timeUnit && { unit: timeUnit }),
        displayFormats: {
          quarter: 'MMM yyyy',
          month: 'MMM yyyy',
          week: 'dd.MM.yy',
          day: 'dd.MM',
          hour: 'HH:mm',
          second: 'HH:mm:ss'
          // do not define millisecond, because it will break zoom x axis
          // it's bug of library
          // millisecond: "HH:mm:ss",
        }
      }
    },
    y: {
      border: {
        display: false
      },
      suggestedMax: suggestedMaxY,
      grid: {
        display: !!hasScaleLines,
        drawOnChartArea: true,
        tickLength: 4
      },
      type: 'linear',
      position: 'left',
      ticks: {
        font: {
          size: 8
        },
        autoSkip: true,
        autoSkipPadding: 20,
        maxRotation: 0,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (label: any, index: number, dataArray: any[]) => {
          const lastValue = `${formatNumber(dataArray[dataArray.length - 1]?.value, 1)}`.replace(
            '.0',
            ''
          );
          const firstValue = `${formatNumber(dataArray[0]?.value, 1)}`.replace('.0', '');
          const currentValue = `${formatNumber(label, 1)}`.replace('.0', '');

          if (firstValue === lastValue) {
            return `${formatNumber(label, 3)}`;
          }
          return currentValue;
        }
      }
    }
  };

  const zoomOptions = {
    limits: {
      x: {
        min: 'original',
        max: 'original',
        minRange: 60 * 1000 * 60 * 72
      }
    },
    pan: {
      enabled: true,
      mode: ZoomMode.X,
      modifierKey: 'shift',
      onPanComplete: handleDebouncedResize
    },
    zoom: {
      drag: {
        enabled: true,
        mode: ZoomMode.X
      },
      wheel: {
        enabled: true,
        speed: 0.03
      },
      pinch: {
        enabled: true
      },
      mode: ZoomMode.X,
      onZoomComplete: handleDebouncedResize,
      onZoomStart: handleThrottledZoomStart
    }
  };

  const clickHandler = onClick
    ? (event: Event, elements: any[], chart: any) => {
        const point = elements[0];
        if (!point) return;

        const dataset = chart.data.datasets;
        const pointData = dataset[point.datasetIndex].data[point.index];

        onClick(pointData);
      }
    : null;

  const options: any = {
    onClick: clickHandler,
    clip: pointClipNone?.isEnabled ? false : 0,
    layout: {
      padding: {
        right: pointClipNone?.isEnabled ? pointClipNone.paddingRight : 0
      }
    },
    elements: {
      point: {
        pointStyle: 'circle',
        radius: 0,
        hoverRadius: 5,
        hoverBorderColor: theme.violetPoint,
        hoverBorderWidth: 2,
        hoverBackgroundColor: theme.white
      }
    },
    scales: scales,
    plugins: {
      zoom: onZoomChange ? zoomOptions : null,
      tooltip: {
        mode: 'nearest',
        axis: 'x',
        usePointStyle: true,
        backgroundColor: 'rgba(249, 246, 255, 0.9)',
        padding: 8,
        caretSize: 0,
        // !important do not set border width to 1, it will break tooltip
        // because of we draw shadow on border in chart controller
        borderWidth: 0.9,
        borderColor: '#ddd',
        titleColor: '#2e2e3a',
        titleFont: {
          size: 10,
          family: 'Inter, sans-serif',
          weight: 'normal'
        },
        bodyFont: {
          size: 10,
          family: 'Inter, sans-serif',
          weight: 700
        },
        bodyColor: 'rgba(0, 0, 0, 0.85)',
        // tooltip customizations
        callbacks: {
          title: (context: any) => {
            const title = context[0].label;

            const parsedDate = parse(title, 'MMM dd, yyyy, hh:mm:ss a', new Date());
            const jsDate = toDate(parsedDate);

            const formattedDate = format(jsDate, 'dd.MM.yyyy');
            const formattedTime = format(jsDate, 'HH:mm');

            let result = formattedDate;

            // show with time and date
            if (!withTime && !withDate) {
              if (formattedTime !== '00:00') {
                return (result += ` ${formattedTime}`);
              }
            }
            // show only with time
            if (withTime) {
              return (result += ` ${formattedTime}`);
            }
            // show only with date
            if (withDate) {
              return result;
            }

            return result;
          },
          label: (context: any) => {
            const value = context.formattedValue;
            return `${value}`;
          },
          labelPointStyle: (context: any) => {
            const key = context.dataset.label;
            return {
              pointStyle: images[key] || 'circle'
            };
          }
        }
      }
    },
    interaction: {
      mode: mode || 'nearest',
      intersect: false
    },
    transitions: {
      zoom: {
        animation: {
          duration: 100
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return options;
};
