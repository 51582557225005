import { FC } from 'react';
import Icon from '@ant-design/icons';
import { DevLoginByLink } from 'fsd/features/devLoginByLink';
import { URLs } from 'fsd/shared/config/URLs';
import { ReactComponent as TgIcon } from 'fsd/shared/icons/telegram.svg';

import * as S from './styles';

const SignInPage: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '24px'
      }}
    >
      <S.Title>Вход в Tgmarket</S.Title>
      <DevLoginByLink />
      <S.TGBtn
        href={URLs.TG_LOGIN}
        target="_blank"
        type="primary"
        block
        icon={<Icon component={TgIcon} size={18} />}
      >
        Войти с помощью Telegram
      </S.TGBtn>

      <S.DocsText>
        Продолжая регистрацию, вы соглашаетесь с&nbsp;
        <a target="_blank" href="https://tg.market/agreement-and-cookies" rel="noreferrer">
          правилами сайта
        </a>
        ,{' '}
        <a target="_blank" href="https://tg.market/personal-data" rel="noreferrer">
          политикой конфиденциальности
        </a>{' '}
        и&nbsp;
        <a target="_blank" href="https://tg.market/oferta" rel="noreferrer">
          договором-офертой
        </a>
      </S.DocsText>
    </div>
  );
};

export default memo(SignInPage);
