import React, { FC, FormEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { Dropdown, Popover } from 'antd';
import { useLazyGetV1ChannelsFindQuery } from 'api/api';

import OutsideClickAlerter from './components/OutsideClickWrapper';
import SearchResult from './components/SearchResult';

import * as S from './styles';

const MIN_CHARS = 3;
const REQUEST_DELAY = 700;

interface ChannelSearchProps {
  onChannelClick: (channelId: string) => void;
  showSearchByDescription?: boolean;
  placeholder?: string;
  showAsPopup?: boolean;
  showAsInline?: boolean;
  className?: string;
}

const ChannelSearch: FC<ChannelSearchProps> = ({
  onChannelClick,
  showSearchByDescription = true,
  placeholder = 'Название канала, ссылка или описание',
  showAsPopup = true,
  showAsInline = false,
  className
}) => {
  const [resultsVisible, setResultsVisible] = useState(false);
  const [popUpHidden, setPopUpHidden] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [lastRequestedSearchStr, setLastRequestedSearchStr] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const searchInputRef = useRef(null);
  const [findChannels, channelsData] = useLazyGetV1ChannelsFindQuery();
  const { data: channelsSearchResult, isFetching } = channelsData;

  useEffect(() => {
    if (isFetching) {
      return setShowLoader(true);
    }
    if (searchStr?.trim()?.length >= MIN_CHARS && searchStr.trim() !== lastRequestedSearchStr) {
      return setShowLoader(true);
    }
    setShowLoader(false);
  }, [isFetching, searchStr, lastRequestedSearchStr]);

  useEffect(() => {
    if (!searchStr || searchStr.trim().length < MIN_CHARS) return;

    setPopUpHidden(true);
    setResultsVisible(false); // hide popup after new input

    const timeoutId = setTimeout(
      async () => {
        setPopUpHidden(false);
        await findChannels({ keywords: searchStr.trim() });
        setLastRequestedSearchStr(searchStr.trim());
      },
      searchStr.trim().length === MIN_CHARS ? 0 : REQUEST_DELAY
    ); // on searchStr.trim().length === MIN_CHARS we warm up backend cache so delay is not needed

    return () => clearTimeout(timeoutId);
  }, [searchStr.trim()]);

  useEffect(() => {
    searchStr.trim().length && !popUpHidden && setResultsVisible(true);
  }, [channelsData, popUpHidden, searchStr.trim()]);

  const wrapInClickListener = (elem: ReactNode) => (
    <OutsideClickAlerter
      onClickInside={() => setResultsVisible(true)}
      onClickOutside={() => setResultsVisible(false)}
      exclude={[searchInputRef]}
    >
      {elem}
    </OutsideClickAlerter>
  );

  const handleEnterPress = () =>
    searchStr.trim() &&
    searchStr.trim().length >= MIN_CHARS &&
    findChannels({ keywords: searchStr.trim() });

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchStr(value);
  };

  const searchInputComponent = (
    <S.SearchInput
      className={`usiteful__channels-search ${className}`}
      prefix={<S.SearchIcon />}
      name="keywords"
      type="search"
      styleType="white"
      placeholder={placeholder}
      allowClear
      onFocus={() => setResultsVisible(true)}
      isLoading={showLoader}
      value={searchStr}
      onChange={handleChange}
      autoComplete="off"
      onKeyPress={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleEnterPress();
        }
      }}
    />
  );

  const searchResultComponent = (
    <SearchResult
      searchStr={searchStr}
      requestStr={lastRequestedSearchStr}
      data={channelsSearchResult}
      onItemClick={onChannelClick}
      loading={isFetching}
      showSearchByDescription={showSearchByDescription}
      minChars={MIN_CHARS}
    />
  );

  if (showAsPopup)
    return (
      <Dropdown
        overlayClassName="search-popup"
        placement="bottomLeft"
        destroyPopupOnHide
        open={resultsVisible && !popUpHidden}
        dropdownRender={() => wrapInClickListener(searchResultComponent)}
      >
        <S.InputWrapper ref={searchInputRef}>{searchInputComponent}</S.InputWrapper>
      </Dropdown>
    );

  if (showAsInline) {
    return (
      <>
        <S.InputWrapper ref={searchInputRef}>{searchInputComponent}</S.InputWrapper>
        <S.InlineSearchResultWrapper>{searchResultComponent}</S.InlineSearchResultWrapper>
      </>
    );
  }

  return (
    <>
      <S.InputBoundariesWrapper>{searchInputComponent}</S.InputBoundariesWrapper>
      {searchResultComponent}
    </>
  );
};

export default memo(ChannelSearch);
