import { ComponentProps } from 'react';
import ReactInputMask from 'react-input-mask';
import { InputProps, InputRef } from 'antd';

import { DefaultProps } from '../types';
import { Input } from '..';

type Props = DefaultProps<InputProps & ComponentProps<typeof ReactInputMask>>;

export const Masked = forwardRef<InputRef, Props>(({ ...restProps }, ref) => {
  return (
    <ReactInputMask {...restProps}>
      {
        // @ts-ignore
        (inputProps: Props) => <Input ref={ref} {...inputProps} />
      }
    </ReactInputMask>
  );
});

export default memo(Masked);
