import { FC, PropsWithChildren, ReactNode } from 'react';
import { Dropdown } from 'antd';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import * as S from './DropdownWrapper.styles';

type Props = {
  content: ReactNode;
  title?: string;
  open?: boolean;
  onOpenChange?: () => void;
};

const DropdownWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  content,
  title,
  onOpenChange,
  open
}) => {
  const isMobile = useIsMobile();
  return (
    <Dropdown
      trigger={['click']}
      placement={isMobile ? 'top' : undefined}
      overlayClassName="mobile-add-campany-dropdown"
      destroyPopupOnHide
      overlayStyle={
        isMobile
          ? {
              minWidth: 0,
              width: 'calc(100vw - 32px)',
              marginLeft: 24
            }
          : undefined
      }
      // @ts-ignore
      overlay={
        <S.ContentWrapper>
          {title && <S.Title>{title}</S.Title>}
          {content}
        </S.ContentWrapper>
      }
      open={open}
      onOpenChange={onOpenChange}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownWrapper;
