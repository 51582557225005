import { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { IconArrowDown } from 'fsd/shared/icons';
import styled, { css } from 'styled-components';

import { Icon } from '../../Icon';

const { Option } = Select;

interface SelectInterface extends FC<SelectProps> {
  Option: typeof Option;
}

const IconWrapper = styled(Icon)<{ opened: boolean }>`
  transform-origin: center center;
  transition: transform 0.3s ease;
  ${({ opened }) =>
    opened &&
    css`
      transform: rotateX(180deg);
    `}
`;

export const SelectUI: SelectInterface = (props) => {
  const [opened, setOpened] = useState(false);

  const handleOnDropdownVisibleChange = useCallback((open: boolean) => {
    setOpened(open);
  }, []);

  return (
    <Select
      {...props}
      onDropdownVisibleChange={handleOnDropdownVisibleChange}
      suffixIcon={
        <IconWrapper width={20} color={theme.blackRock} opened={opened}>
          <IconArrowDown />
        </IconWrapper>
      }
    />
  );
};

SelectUI.Option = Option;

export default memo(SelectUI);
