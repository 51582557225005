import { useSearchParams } from 'react-router-dom';
import { useGetV1UserQuery } from 'api/api';
import { getUserOrganisationId } from 'fsd/entities/user';
import { OrganisationFormWidget } from 'fsd/widgets/OrganisationForm';

import OrganisationFallback from '../../OrganisationFallback';

import * as S from './styles';

const AboutCompany = () => {
  const { data: user, isLoading } = useGetV1UserQuery();
  const [searchParams] = useSearchParams();
  const isHaveOrganisation = Boolean(user && getUserOrganisationId(user, 'crm'));

  const showPreview = useMemo(
    () => !searchParams.get('force') && !isHaveOrganisation,
    [isHaveOrganisation, searchParams]
  );

  return (
    <S.Paper>
      <S.AboutWrapper>
        <S.TitleWrapper>
          <S.Title>Информация об организации</S.Title>
        </S.TitleWrapper>
      </S.AboutWrapper>
      {!isLoading &&
        (showPreview ? (
          <OrganisationFallback
            content="Добавьте информацию об организации для подключения автоматической маркировки рекламы"
            link={{
              href: 'https://tg.market/ord',
              content: 'Как работает автоматическая маркировка рекламы в Tgmarket?'
            }}
          />
        ) : (
          <OrganisationFormWidget />
        ))}
    </S.Paper>
  );
};

export default memo(AboutCompany);
