import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const TableContainer = styled.div<{ ref?: any }>`
  height: 694px;
  overflow: auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-anchor: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media ${MEDIA_DEVICE.laptopS.to} {
    height: 400px;
  }
  @media ${MEDIA_DEVICE.mobileL.to} {
    height: calc(100vh - 60px);
  }
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.blackRock};
  width: 100%;
  position: absolute;
  border: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const Time = styled.td`
  padding-left: 17px;
`;

export const Number = styled.td`
  padding: 0 5px;
`;

export const Body = styled.tbody``;
