import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  gap: 16px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 24px;
  }
`;

export const IconStyles = styled.div`
  width: 18px;
  height: 18px;
  
  color: ${({ theme }) => theme.persianBlue}}

  &:hover {
    color: ${({ theme }) => theme.primaryBtnHover};
  }
  
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
    `;
