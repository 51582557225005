import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: min-content;
  pointer-events: all;
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.blackRock};
  margin: 10px;
  flex: none;
  position: relative;
  overflow: hidden;
  padding-top: 30px;
`;
const HideButton = styled.button`
  pointer-events: all;
  border: none;
  background: ${({ theme }) => theme.blackRock};
  color: #fff;
  padding: 2px;
  text-align: center;
  font-weight: 500;
  width: 100%;
  min-width: min-content;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease;
  &:hover {
    background: ${({ theme }) => theme.strawberryRed};
  }
`;
const Body = styled.div`
  padding: 10px;
`;

const ComponentWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [hidden, setHidden] = useState(false);

  const handleHideClick = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.preventDefault();
    e.stopPropagation();
    setHidden(true);
  }, []);

  return (
    !hidden && (
      <Root>
        <HideButton onClick={handleHideClick}>Скрыть</HideButton>
        <Body>{children}</Body>
      </Root>
    )
  );
};

export default ComponentWrapper;
