import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { Popover } from 'antd';

import SignInPage from '../../../signIn';
import SignUpPage from '../../../signUp';

import * as S from './styles';

export const AuthModals = () => {
  return (
    <>
      <S.Header>
        <Link to="/">
          <S.MobileLogo />
        </Link>

        <S.RightBlock>
          <S.AuthBlock>
            <Popover
              overlayClassName="singUp-popover"
              placement="bottom"
              trigger="click"
              showArrow={false}
              content={<SignInPage />}
            >
              <S.SignInBtn className="usiteful__login-btn" type="primary">
                Вход
              </S.SignInBtn>
            </Popover>

            {/* <Popover
              overlayClassName={"singUp-popover"}
              placement={"bottomRight"}
              trigger={"click"}
              showArrow={false}
              content={<SignUpPage />}
            >
              <S.SignUpBtn className="usiteful__signup-btn" type="primary">
                Регистрация
              </S.SignUpBtn>
            </Popover> */}
          </S.AuthBlock>
        </S.RightBlock>
      </S.Header>
      <S.ShowStatisticBlock>
        <S.TextStatistic>Для просмотра всей статистики необходимо авторизоваться</S.TextStatistic>
      </S.ShowStatisticBlock>
    </>
  );
};
