import { ChannelsListResponse } from 'api/api';
import { FormApi } from 'final-form';
import { IconFilters } from 'fsd/shared/icons';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { createUsitefulClassName } from 'fsd/shared/modules/usiteful';
import { FilterState } from 'types/channels/types';

import ChannelSearch from 'components/ChannelSearch';

import { CatalogProps } from '../..';
import Subjects from '../Subjects';

import * as S from '../Catalog/styles';

type TSearchBar = {
  catalogProps: CatalogProps['catalogProps'];
  filtersCount: number;
  data: ChannelsListResponse | undefined;
  form: FormApi<FilterState, Partial<FilterState>>;
  values: FilterState;
  setFiltersVisible: (value: boolean) => void;
  handleChannelSearchItemClick: (channelId: string) => void;
};

const SearchBar = (props: TSearchBar) => {
  const {
    catalogProps,
    filtersCount,
    data,
    form,
    values,
    setFiltersVisible,
    handleChannelSearchItemClick
  } = props;

  const isMobile = useIsMobile();

  return (
    <S.SearchBar className={createUsitefulClassName('catalog', 'search-bar')}>
      <Subjects
        handleSubmit={catalogProps.handleSubmit}
        total={data?.total}
        form={form}
        values={values}
      />

      <S.FiltersBtnWrapper
        className="usiteful__catalog__filters-button"
        onClick={() => setFiltersVisible(true)}
      >
        <S.FilterBtn
          type="primary"
          $hasFilters={Boolean(filtersCount)}
          size="middle"
          block
          htmlType="submit"
        >
          <S.FilterCountWrapper>
            <IconFilters />
          </S.FilterCountWrapper>
          <span>Фильтры</span>
          {Boolean(filtersCount) && (
            <S.FilterCount>
              <span>{filtersCount}</span>
            </S.FilterCount>
          )}
        </S.FilterBtn>
      </S.FiltersBtnWrapper>

      {!isMobile && (
        <S.SearchWrapper onSubmit={catalogProps.handleSubmit}>
          <S.SearchInput>
            <ChannelSearch
              onChannelClick={handleChannelSearchItemClick}
              className={createUsitefulClassName('catalog', 'search-bar', 'channel-search')}
            />
          </S.SearchInput>
        </S.SearchWrapper>
      )}
    </S.SearchBar>
  );
};

export default memo(SearchBar);
