import createSrc from './createSrc';

export default (wid: string) => {
  const insp = document.createElement('script');
  insp.type = 'text/javascript';
  insp.async = true;
  insp.id = 'inspsync';
  insp.src = createSrc(wid);
  return insp;
};
