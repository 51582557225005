import { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd5';
import styled from 'styled-components';

interface LoadingIconProps {
  fontSize?: number;
  color?: string;
}
const StyledIcon = styled(LoadingOutlined)<{ font: number; color?: string }>`
  font-size: ${({ font }) => `${font}px`};
  color: ${({ theme, color }) => color ?? theme.violetPowder};
`;

const LoadingIcon: FC<LoadingIconProps> = ({ fontSize, color }) => {
  return <StyledIcon font={fontSize || 16} color={color} />;
};

const LoaderSpinner: FC<LoadingIconProps> = ({ fontSize, color }) => {
  return <Spin indicator={<LoadingIcon fontSize={fontSize} color={color} />} />;
};

export default memo(LoaderSpinner);
