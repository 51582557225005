import { FC, PropsWithChildren, ReactNode } from 'react';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';

import * as S from './styles';

type Props = {
  size?: 'medium';
  icon?: ReactNode;
};

const Title: FC<PropsWithChildren<Props>> = ({ children, size, icon }) => {
  const isMobile = useMediaQuery(MEDIA_DEVICE.mobileL.to);
  return (
    <S.Root>
      {icon && (
        <S.IconWrapper width={isMobile ? 20 : 24} color={theme.persianBlue}>
          {icon}
        </S.IconWrapper>
      )}
      <S.Text $size={size} level={2}>
        {children}
      </S.Text>
    </S.Root>
  );
};

export default memo(Title);
