import { ComponentProps, FC, MouseEventHandler, ReactNode } from 'react';

import * as S from './Label.styles';

type BooleanText =
  | {
      title?: string;
      caption?: string;
    }
  | boolean;

type Props = {
  loading?: BooleanText;
  error?: BooleanText;
  endIcon?: ReactNode;
  title: string;
  caption?: string;
  verified?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
} & Pick<ComponentProps<typeof S.Icon>, 'icon' | 'internet'> &
  ComponentProps<typeof S.Root>;

const getBooleanText = (key: keyof Exclude<BooleanText, boolean>, text?: BooleanText) => {
  if (text && typeof text === 'object') return text[key];
  return '';
};

const Label: FC<Props> = ({
  title,
  caption,
  verified,
  endIcon,
  loading,
  icon,
  internet,
  error,
  onClick,
  ...restProps
}) => {
  const titleText = useMemo(
    () => getBooleanText('title', error) || getBooleanText('title', loading) || title,
    [error, loading, title]
  );
  const captionText = useMemo(
    () => getBooleanText('caption', error) || getBooleanText('caption', loading) || caption,
    [caption, error, loading]
  );
  return (
    <S.Root onClick={onClick} {...restProps}>
      <S.Icon icon={icon} loading={!!loading} error={!!error} internet={internet} />
      <S.Content>
        <S.InfoWrapper>
          <S.Header>
            <S.Title>{titleText}</S.Title>
            {verified && <S.Verified />}
          </S.Header>
          {captionText && <S.Caption>{captionText}</S.Caption>}
        </S.InfoWrapper>
        {endIcon && <S.EndIconWrapper>{endIcon}</S.EndIconWrapper>}
      </S.Content>
    </S.Root>
  );
};

export default memo(Label);
