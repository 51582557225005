import { ReactComponent as CloseIcon } from 'fsd/shared/icons/close.svg';
import { ReactComponent as Collapse } from 'fsd/shared/icons/collapse.svg';
import { theme } from 'theme/theme';
import Modal, { ModalFullPage } from 'fsd/shared/ui/Modal';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 10px 16px 16px;
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 14px;
  margin-bottom: 9px;
`;

export const ChartWrapper = styled(Wrapper)`
  padding: 16px;
  height: 230px;
  margin-bottom: 13px;
`;

export const CloseModalIcon = styled(CloseIcon)`
  position: absolute;
  top: 27px;
  right: 20px;
`;

export const CollapseIcon = styled(Collapse)`
  position: absolute;
  top: 25px;
  right: 20px;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const CardContentContainer = styled.div`
  position: relative;
  bottom: 8px;
`;

export const LogoContainer = styled.div`
  background: ${({ theme }) => theme.venetianNights};
  border-radius: 50%;
  width: 64px;
  min-width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.white};
  margin-right: 16px;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const ChannelFakeIcon = styled.div`
  background: ${({ theme }) => theme.black};
  border-radius: 50%;
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-right: 16px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ChannelTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  gap: 8px;
`;

export const ChannelName = styled.span``;

export const ChannelSubTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  'data-disabled'?: boolean;
}

export const IconWrapper = styled.span<IconProps>`
  margin-right: 24px;
  cursor: pointer;

  width: 18px;
  height: 18px;
  svg {
    color: ${(props) => (props['data-disabled'] ? theme.violetPowder : theme.persianBlue)};
    transition: color 0.3s ease; // добавьте переход для плавного изменения цвета
  }
`;

export const NameAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const BlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ScrollableTable = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  padding: 24px 24px 16px 24px;
  border-radius: 20px;
`;

export const WrapperPlacements = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  padding: 24px 24px 16px 24px;
  border-radius: 20px;

  grid-row: span 3;
`;

export const WrapperPlacements2Rows = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  padding: 24px 24px 16px 24px;
  border-radius: 20px;

  grid-row: span 2;
`;

export const WidgetContainer = styled.div`
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 20px;
  padding: 24px 24px 16px 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

export const InfoContainer = styled.div<{ compact?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: ${({ compact }) => (compact ? '0' : '16px')};
`;

export const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BlockHeaderCount = styled.div<{ compact?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: ${({ compact }) => (compact ? '0' : '5px')};
  color: ${({ theme }) => theme.blackRock};
  letter-spacing: 0.2px;
`;

export const BlockHeaderStyle = styled.div<{ compact?: boolean }>`
  display: ${({ compact }) => (compact ? 'flex' : 'normal')};
  gap: ${({ compact }) => (compact ? '8px' : '0px')};
`;

export const BlockHeaderDesc = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.gunPowder};
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
    margin-left: 4px;
  }

  svg {
    fill: ${({ theme }) => theme.persianBlue};
    width: 12px;
    height: 12px;
  }
`;

export const ErWrapper = styled.div`
  display: flex;
  width: 43%;
  justify-content: space-between;
`;

export const BlockDescription = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ErContainer = styled.div<{ compact?: boolean; $end?: boolean }>`
  flex-direction: ${({ compact }) => (compact ? 'row-reverse' : 'column')};
  justify-content: ${({ compact, $end }) => (compact && $end ? 'flex-end' : 'flex-start')};
  align-items: ${({ compact }) => (compact ? 'center' : 'flex-start')};
  display: flex;
  gap: ${({ compact }) => (compact ? '5px' : '0')};
`;

export const ErCount = styled.span<{ compact?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: ${({ compact }) => (compact ? '0' : '5px')};
  color: ${({ theme }) => theme.blackRock};
`;

export const ErDescription = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.gunPowder};
`;

export const CountContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Count = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.color};
  margin-bottom: 4px;
`;

export const CountDescription = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.gunPowder};

  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ToggleScreenBtn = styled.button`
  border: none;
  background: transparent;
  position: relative;
  right: 5px;
  bottom: 3px;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  color: ${({ theme }) => theme.blackRock};
`;

export const GridContainer = styled.div<{ compact?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(4, auto);
  column-gap: 13px;
  row-gap: ${({ compact }) => (compact ? '8px' : '10px')};

  margin-bottom: 1px;
`;

export const ChannelMobileModal = styled(ModalFullPage)`
  background: #fff;
`;

export const ChannelMobileModalBody = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  & > * {
    flex: none;
  }
`;

export const ChannelMobilePriceWrapper = styled.div`
  margin-top: auto;
`;
