import { FC } from 'react';
import { ModalSide } from 'fsd/shared/ui/Modal';

import Form from '../Form';

type Props = {
  open?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  allowLater?: boolean;
  onLater?: () => void;
};

const Modal: FC<Props> = ({ open, onClose, onSuccess, allowLater, onLater }) => {
  return (
    <ModalSide destroyOnClose open={open} title="Добавление организации" onClose={onClose}>
      <Form onSuccess={onSuccess} allowLater={allowLater} onLater={onLater} />
    </ModalSide>
  );
};

export default memo(Modal);
