import { ComponentProps, FC, PropsWithChildren } from 'react';

import * as S from './Title.styles';

interface IProps extends ComponentProps<(typeof S)['H1']> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

const levelComponentMap = new Map<IProps['level'], (typeof S)[keyof typeof S]>([
  [1, S.H1],
  [2, S.H2],
  [3, S.H3],
  [4, S.H4],
  [5, S.H5],
  [6, S.H6]
]);

const Title: FC<PropsWithChildren<IProps>> = ({ level, children, ...restProps }) => {
  const RootComponent = useMemo(() => levelComponentMap.get(level) ?? S.H2, [level]);

  return <RootComponent {...restProps}>{children}</RootComponent>;
};

export default Title;
