import { FC } from 'react';
import { Select } from 'antd';
import {
  api,
  ChannelWithExchangeInfo,
  useGetV1LanguagesQuery,
  usePatchV1UserChannelMutation
} from 'api/api';
import getCountryFlag from 'country-flag-icons/unicode';
import { IconArrowDown } from 'fsd/shared/icons';
import getLanguageData from 'fsd/shared/lib/getLanguageData';

import * as S from '../styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

const LanguageSelect: FC<IProps> = ({ channel }) => {
  const { data, isFetching: isDataFetching } = useGetV1LanguagesQuery();
  const dispatch = useAppDispatch();
  const [updateChannel, { isLoading: isUpdateFetching }] = usePatchV1UserChannelMutation();

  const options = useMemo(() => {
    return (
      data?.map((lang) => {
        const langData = getLanguageData(lang.code_name);
        return {
          value: lang.id,
          label: `${getCountryFlag(langData.countryCode)} ${langData.name}`
        };
      }) || []
    );
  }, [data]);

  const handleChange = useCallback(
    (value: number) => {
      const { undo } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const dataIndex = data.findIndex((d) => d.info.id === channel.info.id);
          data[dataIndex].info.language = value;
        })
      );
      updateChannel({
        body: {
          channel_id: channel.info.id,
          properties: {
            language_id: value
          }
        }
      })
        .unwrap()
        .catch(() => undo());
    },
    [dispatch, updateChannel, channel.info.id]
  );

  return (
    <S.RowExpandedBlock>
      <S.ExpandedBlockHeader>
        <span>Язык</span>
      </S.ExpandedBlockHeader>
      <div>
        <Select
          suffixIcon={<IconArrowDown />}
          placeholder="Не выбрано"
          options={options}
          loading={isDataFetching}
          disabled={isUpdateFetching}
          value={channel.info.language}
          onChange={handleChange}
        />
      </div>
    </S.RowExpandedBlock>
  );
};

export default LanguageSelect;
