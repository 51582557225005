import { isLocal } from 'fsd/shared/lib/featureFlags';
import Cookies from 'js-cookie';

export default (token: string) => {
  Cookies.set('token', encodeURIComponent(token), {
    path: '/',
    secure: !isLocal(),
    sameSite: 'Lax',
    expires: 60 * 60 * 24 * 365
  });
};
