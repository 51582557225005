import { FC } from 'react';
import { IconUsers } from 'fsd/shared/icons';
import { cutNum } from 'fsd/shared/lib/helpers/tools';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import * as S from '../../styles';
import * as Styles from './ParticipantsValue.styles';

type Props = {
  participantsDiff?: number;
  participantsAll: number;
};

const ParticipantsValue: FC<Props> = ({ participantsAll, participantsDiff }) => {
  const isMobile = useIsMobile();

  return (
    <Styles.Root>
      <S.DataWrapper>
        {isMobile && <IconUsers width={14} height={14} />}
        <S.DataValue>{cutNum(participantsAll)}</S.DataValue>
      </S.DataWrapper>
      <S.Mobile>
        <S.DataValue empty>
          {typeof participantsDiff !== 'undefined' ? (
            <S.DataValueDiff status={participantsDiff > 0}>
              {participantsDiff > 0 && '+'}
              {cutNum(participantsDiff)}
            </S.DataValueDiff>
          ) : (
            '—'
          )}
        </S.DataValue>
      </S.Mobile>
    </Styles.Root>
  );
};

export default memo(ParticipantsValue);
