import React, {
  ComponentType,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router';
import { removeAuthToken } from 'fsd/entities/auth';

import { useLazyGetV1UserQuery } from '../../../api/api';

interface AuthContextProps {
  children: ReactNode;
}

interface AuthContextValue {
  isAuth: boolean;
  setAuth: (auth: boolean) => void;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [isAuth, setAuth] = useState<boolean>(document.cookie.includes('token'));
  const navigate = useNavigate();
  const [getUser] = useLazyGetV1UserQuery();

  useEffect(() => {
    if (!document.cookie.includes('token')) {
      setAuth(false);
      return;
    }

    getUser()
      .unwrap()
      .then((data) => {
        setAuth(Boolean(data?.id));
      })
      .catch(() => {
        getUser()
          .unwrap()
          .catch(() => {
            setAuth(false);
            navigate('/');
            removeAuthToken();
          });
      });
  }, [getUser, navigate]);

  return <AuthContext.Provider value={{ isAuth, setAuth }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const withAuth = (Component: ComponentType<{}>) => () => {
  return (
    <AuthProvider>
      <Component />
    </AuthProvider>
  );
};
