/* eslint-disable no-unused-vars */
import { FC } from 'react';
import { ChannelExchangeFormats, UserChannelExchangeFormatV2 } from 'api/api';
import { FormatSelect, useGetUserChannelFormatsQuery } from 'fsd/entities/format';

import { sortChannelFormatsByPrice } from '../..';
import parseChannelFormatsWithFormats from '../../lib/parseChannelFormatsWithFormats';

type Props = {
  channelFormats: ChannelExchangeFormats[];
  forceValue?: (formats: UserChannelExchangeFormatV2[]) => number | undefined;
  value?: number;
  disabled?: boolean;
  onSelect?: (formatId?: number) => void;
};

const ChannelFormatSelect: FC<Props> = ({
  disabled,
  channelFormats,
  value,
  onSelect,
  forceValue
}) => {
  const formatsId = useMemo(() => channelFormats.map((f) => f.format_id), [channelFormats]);
  const { data: formatsInfo = [] } = useGetUserChannelFormatsQuery(
    { formatsId },
    {
      skip: !formatsId.length
    }
  );

  const forceValueResult = forceValue?.(formatsInfo);

  const prepareFormats = useMemo(
    () =>
      formatsInfo.length
        ? parseChannelFormatsWithFormats(sortChannelFormatsByPrice(channelFormats), formatsInfo)
        : [],
    [channelFormats, formatsInfo]
  );

  const [formats, setFormats] =
    useState<ReturnType<typeof parseChannelFormatsWithFormats>>(prepareFormats);

  useEffect(() => {
    setFormats(prepareFormats);
  }, [prepareFormats]);

  return (
    <FormatSelect
      isColumn
      disabled={disabled}
      formats={formats}
      value={forceValueResult || value}
      onSelect={onSelect}
    />
  );
};

export default memo(ChannelFormatSelect);
