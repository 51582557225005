import styled from 'styled-components';

export const Root = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  list-style: none;
  padding: 40px;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.white};
  padding: 10px;
  border-radius: 14px;
`;

export const Name = styled.h3`
  font-size: 16px;
  font-weight: 700;
`;

export const IconsList = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const IconItem = styled.div`
  padding: 10px;
  border-radius: 6px;
  background: ${({ theme }) => theme.alpineGoat};
  display: flex;
  align-items: center;
  justify-content: center;
`;
