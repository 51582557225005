export type LanguageList = Record<
  string,
  {
    name: string;
    countryCode: string;
  }
>;

export const LANGUAGE_LIST: LanguageList = {
  ru: {
    name: 'Русский',
    countryCode: 'ru'
  },
  ua: {
    name: 'Украинский',
    countryCode: 'ua'
  },
  kz: {
    name: 'Казахский',
    countryCode: 'kz'
  },
  en: {
    name: 'Английский',
    countryCode: 'usa'
  },
  be: {
    name: 'Белорусский',
    countryCode: 'by'
  }
};
