import { usePatchV1ExchangePurchaseDeleteMutation } from 'api/api';

import useGetCartPurchases from './useGetCartPurchases';

export default () => {
  const { data: purchases } = useGetCartPurchases();
  const [removePurchase, { isLoading }] = usePatchV1ExchangePurchaseDeleteMutation();

  const getPurchase = useCallback(
    (channelId: string) => {
      return purchases?.find((p) => p.channel?.info?.id === channelId);
    },
    [purchases]
  );

  const isDisabled = !purchases?.length;

  const remove = useCallback(
    async (channelId: string) => {
      if (isDisabled) return;
      const purchase = getPurchase(channelId);
      if (purchase) {
        try {
          await removePurchase({
            purchaseIdList: {
              id_list: [purchase.purchase_id]
            }
          }).unwrap();
        } catch (error) {
          console.error(error);
        }
      }
    },
    [getPurchase, isDisabled, removePurchase]
  );

  return [
    remove,
    {
      isDisabled,
      isLoading,
      getPurchase
    }
  ] as const;
};
