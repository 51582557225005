type Result = {
  short: string;
  long: string;
};

const minutesMap = new Map<number, Result>([
  [
    10,
    {
      long: '10 минут',
      short: '10 мин'
    }
  ],
  [
    30,
    {
      long: '30 минут',
      short: '30 мин'
    }
  ],
  [
    60,
    {
      long: '1 час',
      short: '1 ч'
    }
  ],
  [
    120,
    {
      long: '2 часа',
      short: '2 ч'
    }
  ],
  [
    1440,
    {
      long: '24 часа',
      short: '24 ч'
    }
  ],
  [
    2880,
    {
      long: '48 часов',
      short: '48 ч'
    }
  ],
  [
    4320,
    {
      long: '72 часа',
      short: '72 ч'
    }
  ],
  [
    10080,
    {
      long: 'Неделя',
      short: 'нед'
    }
  ],
  [
    43200,
    {
      long: 'Месяц',
      short: 'мес'
    }
  ],
  [
    -1,
    {
      long: '∞',
      short: '∞'
    }
  ]
]);

export default (minutes: number) => minutesMap.get(minutes);
