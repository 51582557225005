import { FC, memo } from 'react';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import NoDataWidget from '../../NoData';

import { ContentManagementTable } from './components/Table';
import { ChannelsContext } from './Provider';

import * as S from './styles';

type Props = {};

const Channels: FC<Props> = () => {
  const isMobile = useIsMobile();
  const context = useContext(ChannelsContext);

  return (
    <>
      {/* Header */}
      {!isMobile && <S.ChannelSearchWithAddChannel />}
      {/*  Table */}
      <S.TabContainer>
        {(context && context.isLoadingChannels) || context?.channels?.length ? (
          <ContentManagementTable searchStr={context.searchStr} channels={context.channels} />
        ) : (
          <NoDataWidget type="канала" />
        )}
        <S.YellowCircle />
      </S.TabContainer>
    </>
  );
};

export default memo(Channels);
