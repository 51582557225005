import { FC } from 'react';

import * as S from './Loader.styles';

type Props = {
  height?: number;
  width?: number;
};

const Loader: FC<Props> = ({ height = 70, width = 10 }) => {
  return (
    <S.Root $height={height} $width={width}>
      <div className="bar bar1" />
      <div className="bar bar2" />
      <div className="bar bar3" />
      <div className="bar bar4" />
      <div className="bar bar5" />
      <div className="bar bar6" />
      <div className="bar bar7" />
      <div className="bar bar8" />
    </S.Root>
  );
};

export default memo(Loader);
