import { Tabs } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

import SearchWithAddChannel from './components/content/Channels/components/SearchWithAddChannel';

export const PageContainer = styled.div`
  --page-container-padding: 40px;
  padding: var(--page-container-padding);
  padding-bottom: 0;
  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 0;
    padding-bottom: 20px;
  }
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`;

export const PageHeader = styled.span`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: ${({ theme }) => theme.blackRock};
`;

export const ControlsContainer = styled.div`
  margin-top: 40px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    margin: 0;
  }
`;

export const ChannelImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const ChannelInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  width: 194px;
`;

export const ChannelTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.blackRock};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledTabs: typeof Tabs = styled(Tabs)`
  .ant-tabs-tab {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
  }

  .ant-tabs-nav {
    @media ${MEDIA_DEVICE.mobileL.to} {
      display: none;
    }
  }

  .ant-tabs-nav::before {
    border-color: ${({ theme }) => theme.sweetFlower};
    width: calc(100% + var(--page-container-padding) * 2);
    transform: translateX(calc(var(--page-container-padding) * -1));
  }
  .ant-tabs-tab-active {
    font-weight: 700;
  }
`;

export const StyledSearchWithAddChannel = styled(SearchWithAddChannel)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
`;
