import { Checkbox } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { ReactComponent as FiltersIcon } from 'fsd/shared/icons/filters.svg';
import { Button } from 'fsd/shared/ui/Buttons';
import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import DividerUI from 'fsd/shared/ui/LegacyUIComponents/Divider';
import styled from 'styled-components';

export const PageTitle = styled.h2`
  color: ${({ theme }) => theme.blackRock};
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    letter-spacing: 0.04px;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;

    justify-content: center;
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const StyledFilterIcon = styled(FiltersIcon)`
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.persianBlue};

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const InputWrap = styled.div`
  display: flex;
  gap: 23px;
`;

export const Row = styled.div`
  display: flex;
  gap: 60px;
  margin-bottom: 30px;

  > div {
    position: relative;
    width: 100%;
    color: ${({ theme }) => theme.blackRock};

    &:focus-within {
      color: ${({ theme }) => theme.persianBlue};
    }

    @media ${MEDIA_DEVICE.mobileL.to} {
      padding-bottom: 15px;

      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: -16px;
        min-width: 364px;
        width: 100vw;
        border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
      }
    }
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    flex-wrap: wrap;
    gap: 18px;
    margin-bottom: 15px;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  svg {
    width: 18px;
    height: 18px;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 9px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.white};

  margin: 32px -32px 0px;
  padding: 17px 32px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07),
    0px 41.7776px 33.4221px rgba(179, 175, 214, 0.0503198),
    0px 22.3363px 17.869px rgba(179, 175, 214, 0.0417275),
    0px 12.5216px 10.0172px rgba(179, 175, 214, 0.035),
    0px 6.6501px 5.32008px rgba(179, 175, 214, 0.0282725),
    0px 2.76726px 2.21381px rgba(179, 175, 214, 0.0196802);

  @media ${MEDIA_DEVICE.mobileL.to} {
    border-radius: 0px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    margin: 0;
    padding: 24px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const SmallTabs = styled.div`
  position: relative;
  box-sizing: border-box;

  .ant-radio-button-wrapper {
    border: 1px solid transparent;
    padding: 6px 14px;
    font-size: 12px;
    line-height: 16px;
    height: 28px;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    border: 1px solid #c6cbd9;
  }
`;

export const SmallTabsCheckbox = styled.div`
  position: relative;
  box-sizing: border-box;

  .ant-checkbox-group {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    border: 1px solid #c6cbd9;
  }
`;

export const ResetBtn = styled(Button)`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.009em;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.persianBlue};
  position: relative;
  left: -15px;

  &:not([disabled]):hover {
    text-decoration-line: underline;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const SubmitBtn = styled(Button)<{
  isLoadingorDataChanged: boolean;
  widthButton: number;
}>`
  width: ${({ isLoadingorDataChanged, widthButton }) =>
    isLoadingorDataChanged ? `${widthButton}px` : 'auto'};
  @media ${MEDIA_DEVICE.mobileL.to} {
    height: 48px;
    width: 100%;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

export const Error = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.hotspot};
  position: absolute;
  top: 35px;
  left: 0;
  width: calc(100vw - 48px);
  max-width: 343px;
`;

export const MobileSearchResults = styled.div`
  display: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }
`;

export const MobileClearBtn = styled(Button)`
  color: ${({ theme }) => theme.persianBlue};
  position: relative;
  right: -15px;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  :focus {
    color: ${({ theme }) => theme.persianBlue};
  }
`;

export const Chosen = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
`;

export const MobileLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackGrey};
  margin-bottom: 4px;
  display: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: block;
  }
`;

export const Divider = styled(DividerUI)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px;
  color: ${({ theme }) => theme.bayFog} !important;
  margin: 30px 0 32px;
  border: 1px solid ${({ theme }) => theme.alpineGoat};

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const Form = styled.form`
  @media ${MEDIA_DEVICE.mobileL.to} {
    padding-bottom: 218px;
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-dropdown-menu {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const CheckButton = styled(Checkbox)`
  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox {
    display: none; // скрываем стандартный чекбокс
  }

  .ant-checkbox + span {
    padding-left: 0;
    padding-right: 0;
  }

  .checkbox-button {
    display: flex;
    background: ${({ theme }) => theme.white};
    border-radius: 100px;
    padding: 6px 14px;
    color: ${({ theme }) => theme.blackRock};
    border: 1px solid ${({ theme }) => theme.alpineGoat};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
  }

  &.ant-checkbox-wrapper-checked .checkbox-button {
    background: ${({ theme }) => theme.persianBlue};
    border: 1px solid ${({ theme }) => theme.persianBlue};
    color: ${({ theme }) => theme.white};
  }

  .ant-checkbox-input:checked + .checkbox-button {
    background: ${({ theme }) => theme.persianBlue};
  }

  .ant-checkbox-input:checked:hover + .checkbox-button {
    background: ${({ theme }) => theme.persianBlue};
  }
`;
