import { theme } from 'theme/theme';

export const DashedLinesPlugin = {
  afterDraw: (chart: any) => {
    if (chart.tooltip?._active?.length) {
      const { x } = chart._active[0].element;
      const { y } = chart._active[0].element;
      const { ctx } = chart;

      // Для вертикальной линии
      drawDashedLine(ctx, x, chart.scales.y.top, x, chart.scales.y.bottom, theme.lilacHint);

      // Для горизонтальной линии
      drawDashedLine(ctx, chart.scales.x.left, y, chart.scales.x.right, y, theme.lilacHint);
    }
  }
};

// Вспомогательная функция для рисования пунктирных линий
function drawDashedLine(
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  color: string
) {
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(startX, startY);
  ctx.lineTo(endX, endY);
  ctx.lineWidth = 1;
  ctx.strokeStyle = color;
  ctx.setLineDash([5, 5]);
  ctx.stroke();
  ctx.restore();
}
