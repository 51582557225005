import { NavLink } from 'react-router-dom';
import { Popover as _Popover } from 'antd5';
import { Icon as _Icon } from 'fsd/shared/ui/Icon';
import styled from 'styled-components';

export const Popover = styled(_Popover)``;

export const Link = styled(NavLink)`
  display: flex;
  color: ${({ theme }) => theme.bayFog};
  font-weight: 500;
  &.active {
    color: ${({ theme }) => theme.persianBlue};
    font-weight: 700;
  }
`;

export const Icon = styled(_Icon).attrs({ width: 24 })`
  margin-right: 16px;
`;

export const Text = styled.h5`
  color: ${({ theme }) => theme.blackRock};
  font-size: 16px;
  font-weight: inherit;
`;
