import { useState } from 'react';
import { useLazyGetV1ChannelsByChannelIdPostsQuery } from 'api/api';

import { getNavigationBySortings, PostTypes } from '../helpers';

export type Sortings = 'desc' | 'asc' | null;

export type SortingsProps = {
  date: Sortings;
  views: Sortings;
  growth: Sortings;
  forwards: Sortings;
  onlyAds: boolean;
};

export type HandlersProps = {
  date: () => void;
  views: () => void;
  growth: () => void;
  forwards: () => void;
  ads: () => void;
};

export const usePostsSortings = (): {
  sortings: SortingsProps;
  handlers: HandlersProps;
} => {
  const [sortByDate, setSortByDate] = useState<Sortings>(null);
  const [sortByViews, setSortByViews] = useState<Sortings>(null);
  const [sortByForwards, setSortByForwards] = useState<Sortings>(null);
  const [sortByGrowth, setSortByGrowth] = useState<Sortings>(null);
  const [onlyAds, setOnlyAds] = useState<boolean>(false);

  const changeSorting = (sorting: Sortings, setSorting: (value: Sortings) => void) => {
    if (sorting === null) {
      setSorting('desc');
    }
    if (sorting === 'desc') {
      setSorting('asc');
    }
    if (sorting === 'asc') {
      setSorting(null);
    }
  };

  const handleChangeDateSort = () => {
    changeSorting(sortByDate, setSortByDate);
  };

  const handleChangeViewsSort = () => {
    changeSorting(sortByViews, setSortByViews);
  };

  const handleChangeForwardsSort = () => {
    changeSorting(sortByForwards, setSortByForwards);
  };

  const handleChangeGrowthSort = () => {
    changeSorting(sortByGrowth, setSortByGrowth);
  };

  const handleChangeOnlyAds = () => {
    setOnlyAds(!onlyAds);
  };

  const handlers = {
    date: handleChangeDateSort,
    views: handleChangeViewsSort,
    growth: handleChangeGrowthSort,
    forwards: handleChangeForwardsSort,
    ads: handleChangeOnlyAds
  };

  const sortings = {
    date: sortByDate,
    views: sortByViews,
    growth: sortByGrowth,
    forwards: sortByForwards,
    onlyAds: onlyAds
  };

  return {
    sortings,
    handlers
  };
};

export const usePostsList = (channelId: string, type: PostTypes) => {
  const [posts, setPosts] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const { sortings, handlers } = usePostsSortings();
  const [loadPosts] = useLazyGetV1ChannelsByChannelIdPostsQuery();

  const loadNextPage = async () => {
    if (hasNextPage && !isLoading) {
      const navigation = getNavigationBySortings(sortings, type, page);

      setIsLoading(true);
      loadPosts({
        channelId,
        navigation
      })
        .unwrap()
        .then((res) => {
          setPosts([...posts, ...res]);
        })
        .catch((err) => {
          setHasNextPage(false);
        })
        .finally(() => {
          setIsLoading(false);
          setPage(page + 1);
        });
    }
  };

  return {
    data: posts,
    sortings,
    handlers,
    isLoading,
    hasNextPage,
    loadNextPage
  };
};
