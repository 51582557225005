import { memo } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';
import { createUsitefulClassName } from 'fsd/shared/modules/usiteful';

import { ExchangeActiveWithVerification } from '../ExchangeActiveWithVerification';

import { ChannelInfo } from './ChannelInfo';
import { ContactColumn } from './ContactColumn';
import { FormatAndCost } from './FormatAndCost';
import { LanguageColumn } from './LanguageColumn';

import * as S from './styles';

export const ContentTableRow = memo(({ channel }: { channel: ChannelWithExchangeInfo }) => {
  return (
    <S.Row className={createUsitefulClassName('content-manager', 'channel-row')}>
      <S.InfoWrapper>
        <ChannelInfo channel={channel} />
        <S.SendButtonWrapper>
          <ExchangeActiveWithVerification channel={channel} />
        </S.SendButtonWrapper>
      </S.InfoWrapper>

      <FormatAndCost channel={channel} />

      <ContactColumn channel={channel} />

      <LanguageColumn channel={channel} />
    </S.Row>
  );
});
