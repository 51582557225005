import { Form } from 'antd';
import { Button as SharedButton } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

import _Info from '../Info';

export const Root: typeof Form = styled(Form)``;

export const Info = styled(_Info)`
  margin-top: 16px;
`;

export const Button = styled(SharedButton)`
  margin-top: 8px;
`;
