import { FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';
import { getFormatsByChannel } from 'fsd/entities/channel';
import { useGetUserChannelFormatsQuery } from 'fsd/entities/format';

import Format from './Format';

import * as S from './styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

const Formats: FC<IProps> = ({ channel }) => {
  const formatsId: number[] = useMemo(
    () => getFormatsByChannel(channel).map((format) => format.format_id),
    [channel]
  );
  const { data: formatsExamples = [] } = useGetUserChannelFormatsQuery(
    { formatsId },
    {
      skip: !formatsId.length
    }
  );

  return (
    <S.Formats>
      {channel.exchange_info?.formats &&
        [...formatsExamples]
          .sort(
            (a, b) =>
              a.time_in_top_minutes +
              a.time_in_feed_minutes -
              (b.time_in_top_minutes + b.time_in_feed_minutes)
          )
          .map((format) => {
            const formatWithStatus = channel.exchange_info!.formats?.find(
              ({ format_id }) => format_id === format.format_id
            );
            return (
              formatWithStatus &&
              formatsExamples && (
                <Format
                  key={formatWithStatus.format_id}
                  example={format}
                  format={formatWithStatus}
                  channel={channel}
                  channelFormats={formatsExamples}
                />
              )
            );
          })}
    </S.Formats>
  );
};

export default Formats;
