import { FC } from 'react';
import { Navigate, redirectDocument, Route, Routes, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { ChannelPost } from 'fsd/entities/channel/ui/ChannelPost';
import { IconsPage } from 'fsd/pages/dev/icons';
import { PlaygroundPage } from 'fsd/pages/dev/playground';
import { FinancesPage } from 'fsd/pages/finances';
import { LoginPage as NewLoginPage } from 'fsd/pages/login';
import { isDev } from 'fsd/shared/lib/featureFlags';
import { useSyncStateAndSearchParams } from 'fsd/shared/lib/hooks/useSyncStateAndSearchParams';
import styled from 'styled-components';

import ChannelPosts from 'components/ChannelPosts';
import AccountPage from 'pages/account';
import Catalog from 'pages/catalog';
import Channel from 'pages/channel';
import ContentManager from 'pages/contentManager';
import LoginPage from 'pages/login';
import PurchaseList from 'pages/purchaseList';
import SettingsPage from 'pages/settings';
import SignOutPage from 'pages/signOut';

import MentionsMobile from '../../pages/mentionsMobile';
import ParticipantsMobile from '../../pages/participantsMobile';
import ReachesMobile from '../../pages/reachesMobile';
import AboutCompanyPage from '../../pages/settings/components/pages/aboutCompany';
import GeneralSettings from '../../pages/settings/components/pages/general';
import { SettingsTeamPage } from '../../pages/settings/components/pages/team';
import ViewsMobile from '../../pages/viewsMobile';

import { useAuth } from './providers/Auth';

const RoutesComponent: FC = () => {
  const { isAuth } = useAuth();
  const location = useLocation();

  useSyncStateAndSearchParams();

  useEffect(() => {
    document.body.style.setProperty(
      '--page-background-color',
      ['/management', '/finances'].includes(location.pathname) ? '#fff' : theme.alpineGoat
    );
  }, [location]);

  // useEffect(() => {
  //   if (isProd() && isAuth === false) {
  //     window.location.href = 'https://telespace.market';
  //   }
  // }, [isAuth]);

  return (
    <Routes location={location}>
      <Route path="/login/tg" element={<LoginPage />} />
      {!isAuth && <Route path="*" element={<NewLoginPage />} />}

      {isDev() && (
        <>
          <Route path="/dev/icons" element={<IconsPage />} />
          <Route path="/dev/playground" element={<PlaygroundPage />} />
        </>
      )}

      {isAuth && (
        <>
          <Route path="/" element={<Catalog />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/signout" element={<SignOutPage />} />
          <Route path="/settings" element={<SettingsPage />}>
            <Route path="general" element={<GeneralSettings />} />
            <Route path="about_company" element={<AboutCompanyPage />} />
            <Route path="team" element={<SettingsTeamPage />} />
          </Route>
          <Route path="/channel/:id" element={<Channel />} />
          <Route path="/channel/:id/posts" element={<ChannelPosts />} />
          <Route path="/channel/:id/post/:post_id" element={<ChannelPost />} />
          <Route path="/finances" element={<FinancesPage />} />
          <Route path="/management" element={<ContentManager />} />
          <Route path="/campaigns" element={<PurchaseList />} />
          <Route path="/campaigns/:containerId" element={<PurchaseList />} />
          <Route
            path="/campaigns/:containerId/channelId/:id/post/:post_id"
            element={<ChannelPost />}
          />
          <Route path="/channel/:id/participants" element={<ParticipantsMobile />} />
          <Route path="/channel/:id/reaches" element={<ReachesMobile />} />
          <Route path="/channel/:id/views" element={<ViewsMobile />} />
          <Route path="/channel/:id/mentions" element={<MentionsMobile />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
    </Routes>
  );
};

export default Sentry.withSentryReactRouterV6Routing(RoutesComponent);
