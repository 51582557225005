import styled from 'styled-components';

export const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  color: ${({ theme }) => theme.blackRock};
`;

export const WrapperRadio = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;

  .ant-radio-group {
    display: flex;
    flex-direction: column;

    gap: 24px;
  }

  .ant-radio-input:checked + .ant-radio-inner {
    border-width: 4px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    display: none;
  }
`;
