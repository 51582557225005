import styled, { css } from 'styled-components';

export const Card = styled.div<{ size?: 's' }>`
  box-shadow:
    0px 100px 80px 0px #b3afd612,
    0px -4px 64px 0px #00000012;
  background: ${theme.white};
  padding: ${({ size }) => (size === 's' ? `16px` : `32px 24px`)};
  border-radius: ${({ size }) => (size === 's' ? `14px` : `12px`)};
  ${({ size }) =>
    size === 's'
      ? css`
          padding: 16px;
          border-radius: 14px;
          font-size: 12px;
        `
      : css`
          padding: 32px 24px;
          border-radius: 12px;
          font-size: 14px;
        `}
`;
