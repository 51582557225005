import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import styled, { css } from 'styled-components';

import { theme } from 'theme/theme';

export const StyledSelect = styled(Select)`
  width: 100px;
  overflow: hidden;
  .ant-select-selector {
    background: transparent !important;
    border-color: none !important;
    border: none !important;
    padding-left: 0 !important;
  }

  .ant-select-selector > .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.black};
  }

  .ant-select-selection-item {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.009em;
    color: ${({ theme }) => theme.black};
    padding-bottom: 12px;
  }

  .ant-select-selector {
    align-items: center;
    gap: 8px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selection-item {
        padding-right: 0 !important;
      }
    `}
`;

export const OptionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Format = styled.div`
  font-size: 14px;
  color: ${theme.blackRock};
`;
export const Price = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #4807ea;
`;
