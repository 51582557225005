import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Button } from 'fsd/shared/ui/Buttons';
import styled from 'styled-components';

export const TGBtn = styled(Button)`
  background-color: ${({ theme }) => theme.cerulean};
  border-color: ${({ theme }) => theme.cerulean};
  height: 48px;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  min-width: 352px;
`;

export const DocsText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.108px;
  color: ${({ theme }) => theme.londonGrey};
  max-width: 330px;
  margin: 0 auto;

  a {
    color: ${({ theme }) => theme.blueRibbon};
    text-decoration: none;
    border-bottom: 1px ${({ theme }) => theme.blueRibbon} solid;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  line-height: 34.602px;

  color: ${({ theme }) => theme.blackRock};
  text-align: center;
  margin-bottom: 8px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-bottom: 21px;
    font-size: 24px;
    line-height: 30px;
  }
`;

export const Field = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const TGError = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.hotspot};
  position: absolute;
  top: 194px;
  right: 60px;
`;

export const InSystemDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  span {
    color: ${({ theme }) => theme.gunPowder};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.126px;
  }
`;
