import { FC } from 'react';

import Reach from '../../../../Row/components/Reach';
import { TableDataContext } from '../../../lib/tableDataContext';
import { DefaultColumnCellProps } from '../type';

import * as S from '../../../../Row/styles';

type Props = DefaultColumnCellProps;

const AvgDailyReaches: FC<Props> = ({ channel }) => {
  const tableData = useContext(TableDataContext);

  return (
    <S.ChartCell className="usiteful__catalog__views-cell">
      {tableData && (
        <Reach
          channelId={channel.info.id}
          timeWindow={tableData.timeWindow}
          isLoading={!tableData.avgDailyReachesMetrics?.data}
        />
      )}
    </S.ChartCell>
  );
};

export default memo(AvgDailyReaches);
