import { FC, PropsWithChildren } from 'react';

import * as S from './styles';

type WrapperProps = {
  type?: 'base' | 'tg';
};

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({ type = 'base', children }) => {
  return <S.Root $type={type}>{children}</S.Root>;
};

export { Wrapper };
