import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.white};
  border-radius: 16px;
`;

export const Album = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  align-items: stretch;

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  overflow: hidden;

  & > * {
    flex: 1;
    min-width: calc(100% / 3);
  }
`;

export const TextContainer = styled.div`
  padding: 10px;
`;

export const MessageTail = styled.img`
  position: absolute;
  left: -4px;
  bottom: 0;
`;
