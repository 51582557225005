import { FC } from 'react';
import { theme } from 'theme/theme';
import { Icon } from 'fsd/shared/ui/Icon';

import { Sortings } from '../../hooks';

import * as S from './styles';

type Props = {
  iconSettings: {
    icon: JSX.Element;
    iconColor?: string;
  };
  handleClick: () => void;
  sorted: Sortings;
  title?: string;
};

const SortHeader: FC<Props> = ({ iconSettings, title, handleClick, sorted }) => {
  return (
    <S.FilterButton onClick={handleClick}>
      {title}
      <Icon width={16} color={iconSettings.iconColor || theme.persianBlue}>
        {iconSettings.icon}
      </Icon>

      <Icon width={16} color={theme.persianBlue}>
        <S.StyledSortIcon sorted={sorted} />
      </Icon>
    </S.FilterButton>
  );
};

export default SortHeader;
