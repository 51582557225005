import { FC } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';

import CategorySelect from '../CategorySelect';
import ContactSelect from '../ContactSelect';

import * as S from './styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

export const ContactColumn: FC<IProps> = ({ channel }) => {
  return (
    <S.ContactWrapper>
      <div>
        <ContactSelect channel={channel} />
      </div>
      <div>
        <CategorySelect channel={channel} />
      </div>
    </S.ContactWrapper>
  );
};
