import { useCallback } from 'react';
import { Chart } from 'chart.js';
import { format } from 'date-fns';
import { setDateFilter } from 'slices/dateFilter/DateFilterSlice';

export const useChartDateControl = (
  chartRef: React.RefObject<Chart>,
  handleZoomChange: (from: number, to: number, chart: any) => Promise<void>,
  now: string,
  graphType: string,
  dispatch: any,
  channelId: string
) => {
  const today = new Date(now).getTime();
  const hour = 60 * 60 * 1000;
  const day = 24 * hour;

  const handleDateClick = (type: string) => {
    const chart = chartRef?.current;
    if (!chart) return;

    let min = null;
    let max = null;

    if (type === 'today') {
      min = today - day;
      max = today - 1;
    }

    if (type === 'yesterday') {
      min = today - day * 2;
      max = today - day - 1;
    }

    if (type === 'week') {
      min = today - day * 8;
      max = today;
    }

    if (type === 'month') {
      min = today - day * 30;
      max = today;
    }

    if (min && max) {
      chart.zoomScale('x', { min, max }, 'default');
      chart.update();
      handleZoomChange(min, max, chart);
      dispatch(
        setDateFilter({
          id: channelId,
          timestamp: max
        })
      );
    }
  };

  const handleChartClick = useCallback(
    (dataPoint: { x: number; y: number } | undefined) => {
      if (!dataPoint) return;
      let timestamp = dataPoint.x;
      const time = format(dataPoint.x, 'HH:mm');

      if (time === '00:00') {
        timestamp += day;
      } else {
        timestamp += hour;
      }

      if (timestamp > today) {
        timestamp = today;
      }

      dispatch(
        setDateFilter({
          id: channelId,
          timestamp: timestamp
        })
      );
    },
    [dispatch, channelId]
  );

  return {
    handleChartClick,
    handleDateClick
  };
};
