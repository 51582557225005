import { Modal } from 'antd5';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled(Modal)`
  & > div {
    height: 100%;
    pointer-events: auto;
  }
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  height: 100%;
  margin: 0 0 0 auto !important;
  @media ${MEDIA_DEVICE.mobile.to} {
    max-width: none !important;
    margin: auto 0 0 0 !important;
    border-radius: 20px 20px 0 0;
    overflow: auto;
    width: 100% !important;
    vertical-align: bottom !important;
    top: auto;
    height: auto;
    max-height: calc(100vh - 40px);
  }
`;
