import { isProd } from '../lib/featureFlags';
import getAppDomain from '../lib/getAppDomain';

const TELESPACE_ASSISTANT_BOT = import.meta.env.VITE_REACT_APP_TG_BOT_URL.replace(
  '?start=start',
  ''
);

export const URLs = {
  TG_LOGIN: `${import.meta.env.VITE_REACT_APP_API_URL}/v1/signup/tg?domain=${getAppDomain()}`,
  OFFERS: `https://tg.market/contract`,
  TELESPACE_ASSISTANT_BOT: import.meta.env.VITE_REACT_APP_TG_BOT_URL.replace('?start=start', ''),
  TELESPACE_ASSISTANT_MANAGER: `https://t.me/tsorgsup`,
  TELESPACE_ASSISTANT_FEEDBACK: `${TELESPACE_ASSISTANT_BOT}?start=feedback`,
  SUPPORT_BOT: `https://t.me/tgmarket_support_bot?start=start`
};
