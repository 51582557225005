import { usePostV1ExchangePurchaseContainersCreateMutation } from 'api/api';
import { useFixFormatAndCostInPurchase } from 'fsd/entities/purchase';

type Data = {
  containerId: string;
  channelId: string;
  formatId: number;
};

export default () => {
  const [addPurchaseToContainers, { isLoading: isAdding }] =
    usePostV1ExchangePurchaseContainersCreateMutation();
  const [fixPurchase, { isLoading: isFixing }] = useFixFormatAndCostInPurchase();

  const add = useCallback(
    async ({ containerId, channelId, formatId }: Data) => {
      try {
        const res = await addPurchaseToContainers({
          createPurchase: {
            container_id_list: [containerId],
            purchase_channel_id: channelId
          }
        }).unwrap();

        const { purchaseId } = res[0];

        if (purchaseId) {
          await fixPurchase({
            channelId,
            purchaseId,
            formatId
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [addPurchaseToContainers, fixPurchase]
  );

  return [
    add,
    {
      isLoading: isAdding || isFixing
    }
  ] as const;
};
