import { FC, HTMLAttributes } from 'react';

import { Button as SharedButton } from '../../Buttons';
import { PickerContext } from '../lib/context';

const Button: FC<HTMLAttributes<HTMLButtonElement>> = ({ ...restProps }) => {
  const contextData = useContext(PickerContext);
  return (
    <SharedButton
      {...restProps}
      block
      type="primary"
      size="small"
      disabled={contextData?.okDisabled}
    >
      {contextData?.okButton ?? 'OK'}
    </SharedButton>
  );
};

export default Button;
