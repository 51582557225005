import { IconTeamAdd } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import SettingsNavLink from 'pages/settings/components/SettingsNavLink';

import * as S from '../../../../styles';

const NavItem = () => {
  return (
    <SettingsNavLink to="team">
      <Icon width={24} color="">
        <IconTeamAdd />
      </Icon>

      <S.NavText>Команда</S.NavText>
    </SettingsNavLink>
  );
};

export default NavItem;
