import { FC, PropsWithChildren } from 'react';
import { type CheckboxProps } from 'antd5';

import * as S from './Checkbox.styles';

type Props = {
  error?: boolean;
  split?: boolean;
  labelSize?: S.RootProps['$labelSize'];
} & CheckboxProps;

const Checkbox: FC<PropsWithChildren<Props>> = ({
  children,
  split,
  error,
  labelSize,
  ...restProps
}) => {
  return (
    <S.Root $isError={error} $labelSize={labelSize} $isSplit={split} {...restProps}>
      {children}
    </S.Root>
  );
};

export default Checkbox;
