import { FC } from 'react';
import { cutNum } from 'fsd/shared/lib/helpers/tools';
import { Table } from 'fsd/shared/ui/Table';
import isOnePurchasePublished from 'fsd/widgets/ContainersList/lib/isOnePurchasePublished';
import reducePurchasesCoverage from 'fsd/widgets/ContainersList/lib/reducePurchasesCoverage';

import { DefaultColumnCellProps } from '../../../types';
import NotPublishedPlaceholder from '../../NotPublishedPlaceholder';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ record }) => {
  const isPublished = useMemo(() => isOnePurchasePublished(record.purchases), [record.purchases]);
  const coverage = useMemo(() => reducePurchasesCoverage(record.purchases), [record.purchases]);

  if (!isPublished) return <NotPublishedPlaceholder />;

  return coverage ? cutNum(coverage) : <Table.NoCellData />;
};

export default memo(Cell);
