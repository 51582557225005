import { calcPurchaseCPMTotal } from 'fsd/entities/purchase';

import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    if (!a.common_purchase_data || !b.common_purchase_data) return 0;

    const aCpmAmount = calcPurchaseCPMTotal(a) ?? 0;
    const bCpmAmount = calcPurchaseCPMTotal(b) ?? 0;

    return bCpmAmount - aCpmAmount;
  }
};

export default sorter;
