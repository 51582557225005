import * as S from './NotFound.styles';

const NotFound = () => {
  return (
    <S.Root>
      <S.IconWrapper>🕵️‍♂️</S.IconWrapper>
      <S.Text>
        Поиск не дал результата.
        <br />
        Попробуйте поиск по ссылке
      </S.Text>
    </S.Root>
  );
};

export default memo(NotFound);
