import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 8px;
  left: 0px;
  right: 0px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
`;

export const Content = styled.div`
  position: relative;
  border-radius: 12px;
  padding: 14px 16px;
  border: 1px solid ${({ theme }) => theme.indivioletSunset};
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  @media ${MEDIA_DEVICE.mobileL.to} {
    border-radius: 20px;
    padding: 14px 24px;
  }
`;
