import { Icon } from 'fsd/shared/ui/Icon';
import { Info } from 'fsd/shared/ui/Info';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
  line-height: 114.29%;
`;

export const CaptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
  line-height: 100%;
  color: ${({ theme }) => theme.gray4};
  font-size: 9px;
  font-weight: 400;
  letter-spacing: 5%;
  line-height: 122.22%;
`;

export const CaptionInfo = styled(Info)`
  margin-right: 2px;
  flex: none;
`;

export const Value = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 114.29%;
  flex: none;
`;
