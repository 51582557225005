import { Dropdown } from 'antd';
import styled from 'styled-components';

export const Root = styled(Dropdown)``;

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 4px 32px #00103d29;
  padding: 16px;
  border-radius: 8px;
  width: 375px;
  max-width: 100vw;
`;

export const Header = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.blackRock};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;
