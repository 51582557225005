import styled from 'styled-components';

import ChannelsSearch from '../ChannelsSearch';

export const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Search = styled(ChannelsSearch)`
  width: 100%;
`;
