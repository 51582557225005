import { FC } from 'react';
import { BalancePaymentSbpResponse } from 'api/api';

import QR from './QR';

import * as S from './styles';

type Props = {
  data: BalancePaymentSbpResponse;
};

const SBPInvoiceInfo: FC<Props> = ({ data }) => {
  return (
    <S.Root>
      <QR data={data} />
    </S.Root>
  );
};

export default memo(SBPInvoiceInfo);
