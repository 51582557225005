import { NavigateFunction } from 'react-router';
import { ChannelWithExchangeInfoRead } from 'api/api';
import { useChannelCategories } from 'fsd/entities/channel/lib/useChannelCategories';
import { ChannelActions } from 'fsd/entities/channel/ui/ChannelActions';
import { IconVerified } from 'fsd/shared/icons';

import * as S from '../../styles';

type Props = {
  channel: ChannelWithExchangeInfoRead;
  channelId: string;
  navigate: NavigateFunction;
};

export const Header = memo(({ channel, channelId, navigate }: Props) => {
  const categories = useChannelCategories(channel?.info?.categories);
  if (!channel) return null;
  return (
    <S.CardHeaderContainer>
      {channel.info?.icon_url ? (
        <S.LogoContainer>
          <img src={`data:image/png;base64,${channel.info.icon_url}`} alt="channelLogo" />
        </S.LogoContainer>
      ) : (
        <S.ChannelFakeIcon />
      )}
      <S.DescriptionContainer>
        <S.ChannelTitle>
          <S.ChannelName className="no-translation">{channel.info?.title}</S.ChannelName>
          {channel.info?.is_verified && <IconVerified />}
        </S.ChannelTitle>
        <S.ChannelSubTitle>
          <span>{categories}</span>
        </S.ChannelSubTitle>
        <div>
          {/* Cart btn */}
          {/* channel && (
            <Dropdown
              trigger={["click"]}
              overlayStyle={{ maxWidth: "100%" }}
              overlay={
                <TabsContextMenu
                  setIsDropdownVisible={setIsDropdownVisible}
                  channelId={[channel.id]}
                  actionType={"single"}
                />
              }
              open={isDropdownVisible}
              onOpenChange={() =>
                setIsDropdownVisible((prevState) => !prevState)
              }
            >
              <button className="usiteful__catalog-tab-icon-add">
                <S.IconWrapper data-disabled={!isAuth}>
                  <IconCartAdd />
                </S.IconWrapper>
              </button>
            </Dropdown>
          ) */}

          {/* Info btn */}
          <ChannelActions channel={channel.info} exchangeInfo={channel.exchange_info} />
        </div>
      </S.DescriptionContainer>
    </S.CardHeaderContainer>
  );
});
