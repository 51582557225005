import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const Text = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.144px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 12px;
    text-align: right;
  }
`;

export const Desc = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.286%;
`;
