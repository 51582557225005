import styled from 'styled-components';

export const List = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 16px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
`;
