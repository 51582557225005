import { format } from 'date-fns';
import { dateFormat } from 'fsd/shared/lib/helpers/constants/dateFormats';

import { getAllDaysInMonth } from './datesCalculator';

type Args = {
  date: string;
  value: number;
}[];

export const calcErd = (viewsMonth: Args, participantsMonth: Args): string => {
  let result = '—';

  const date = format(new Date(), dateFormat);
  const viewsToday = viewsMonth.filter((item) => item.date === date);
  const participantsToday = participantsMonth.filter((item) => item.date === date);

  if (viewsToday.length < 1 || (viewsToday.length >= 1 && participantsToday.length < 1)) {
    return result;
  }
  if (viewsToday.length >= 1 && viewsToday[0].value === 1) {
    result = '100%';
  } else {
    result = `${((viewsToday[0].value / participantsToday[0].value) * 100).toFixed(1)}%`;
  }
  return result;
};

export const calcErm = (viewsMonth: Args, participantsMonth: Args): string => {
  let result = '—';

  const currentDay = new Date();
  const currentMonth = currentDay.getMonth();
  const currentYear = currentDay.getFullYear();
  const monthDatesArr = getAllDaysInMonth(currentYear, currentMonth);

  const viewsInMonth = viewsMonth.filter((item) => {
    return monthDatesArr.includes(item.date);
  });
  const averageViews =
    viewsInMonth.reduce((acc, current) => {
      return acc + current.value;
    }, 0) / viewsInMonth.length;

  const participantsInMonth = participantsMonth.filter((item) => {
    return monthDatesArr.includes(item.date);
  });
  const averageParticipants =
    participantsInMonth.reduce((acc, current) => {
      return acc + current.value;
    }, 0) / viewsInMonth.length;

  if (viewsInMonth.length < 1 || participantsInMonth.length < 1) {
    return result;
  }
  if (averageViews === 1 || averageViews < averageParticipants) {
    result = '100%';
  } else {
    result = `${((averageViews / averageParticipants) * 100).toFixed(1)}%`;
  }

  return result;
};
