import { FC } from 'react';

import { DefaultColumnCellProps } from '../../../types';

type Props = DefaultColumnCellProps;

const Cell: FC<Props> = ({ purchase }) => {
  return `${(purchase.common_purchase_data?.cost ?? 0).toLocaleString('ru')} ₽`;
};

export default memo(Cell);
