import { OrientationTypes } from '../types';

export const getOrientationTypeBySize = (width: number, height: number): OrientationTypes => {
  if (width > height) return 'landscape';
  return 'portrait';
};

export const getOrientationType = () =>
  window.screen.orientation.type.split('-')[0] as OrientationTypes;

export const getOrientationTypeByWindow = () =>
  getOrientationTypeBySize(window.innerWidth, window.innerHeight);
