import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 16px;
  }
`;
