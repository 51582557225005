import { RefObject, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type Options = {
  getScrollContainer?: (ref: RefObject<HTMLElement | null>) => HTMLElement | undefined | null;
  hasMore: boolean;
  offsetLoadMore?: number;
  windowScroll?: boolean;
  loadMore: (end: () => void) => Promise<unknown>;
};

const useInfinitier = ({
  getScrollContainer,
  hasMore,
  loadMore,
  offsetLoadMore,
  windowScroll
}: Options) => {
  const ref = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const callLoad = useCallback(() => {
    setIsLoading(true);
    loadMore(() => setIsLoading(false));
  }, [loadMore]);

  // eslint-disable-next-line no-undef
  const scrollHandler = useDebouncedCallback<EventListener>(
    useCallback(
      (e) => {
        if (!hasMore || isLoading) return;
        let different = 0;
        if (windowScroll) {
          different = document.documentElement.scrollHeight - (window.scrollY + window.innerHeight);
        } else {
          const scrollContainer = e.target as HTMLElement;
          different =
            scrollContainer.scrollHeight -
            (scrollContainer.scrollTop + scrollContainer.clientHeight);
        }
        if (different < (offsetLoadMore ?? 100)) {
          callLoad();
        }
      },
      [callLoad, hasMore, isLoading, offsetLoadMore, windowScroll]
    ),
    100
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const scrollContainer = windowScroll ? window : getScrollContainer?.(ref) ?? ref.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', scrollHandler);
      return () => scrollContainer?.removeEventListener('scroll', scrollHandler);
    }
  }, [getScrollContainer, scrollHandler, windowScroll]);

  return { ref, isLoading, handleScroll: scrollHandler };
};

export { useInfinitier };
