export type MergeArrayData = object | number | string;

export type MergeArrayKey<T extends MergeArrayData> =
  | (T extends object ? keyof T : undefined)
  | ((item: T) => unknown);

const mergeArray = <T extends MergeArrayData>(a: Array<T>, b: Array<T>, key: MergeArrayKey<T>) => {
  const result = a ? [...a] : [];
  const add = b ? [...b] : [];
  if (!result.length) return add;
  if (!add?.length) return result;

  if (key) {
    if (typeof key === 'function') {
      result.push(
        ...add.filter((bItem) => result.findIndex((aItem) => key(aItem) === key(bItem)) === -1)
      );
    } else {
      result.push(
        ...add.filter((bItem) => result.findIndex((aItem) => aItem[key] === bItem[key]) === -1)
      );
    }
  } else {
    result.push(...add);
  }
  return result;
};

export default mergeArray;
