import { FC, MouseEventHandler } from 'react';
import { createOrganisationSchema } from 'fsd/entities/organisation';
import { flagComponent, isDev } from 'fsd/shared/lib/featureFlags';
import { Button } from 'fsd/shared/ui/Buttons';
import { convert as numberToWordsRu } from 'number-to-words-ru';
import { randomInteger } from 'remeda';
import { z } from 'zod';

type Props = {
  onFinish: (data: Partial<z.infer<typeof createOrganisationSchema>>) => void;
};

const DevRandom: FC<Props> = ({ onFinish }) => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const type = Number(e.currentTarget.getAttribute('data-value')) as 1 | 2;
      onFinish({
        contragentType: 1,
        inn: type === 1 ? '5650745930' : '897207869310',
        ogrn: type === 1 ? '7046530639401' : '404039415953022',
        kpp: '838144892',
        organisation_name: numberToWordsRu(randomInteger(10, 21)),
        owner_full_name: numberToWordsRu(randomInteger(30, 41), {
          showNumberParts: {
            fractional: false
          }
        }),
        organisation_type: type
      });
    },
    [onFinish]
  );

  return (
    <>
      <Button block size="small" data-value={1} onClick={handleClick}>
        Этакая организация ООО
      </Button>
      <Button block size="small" data-value={2} onClick={handleClick}>
        Этакая организация ИП
      </Button>
    </>
  );
};

export default flagComponent(isDev(), memo(DevRandom));
