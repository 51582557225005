import {
  ChannelsRead,
  useLazyGetV1ChannelsFindQuery,
  useLazyGetV1ChannelsListQuery
} from 'api/api';

import { FIND } from '../../config/find';

export default () => {
  const [channels, setChannels] = useState<ChannelsRead>([]);
  const [getChannels] = useLazyGetV1ChannelsFindQuery();
  const [isFetching, setIsFetching] = useState(false);

  const [getChannelList, channelsListData] = useLazyGetV1ChannelsListQuery();

  const find = useCallback(
    (link: string) =>
      new Promise<ChannelsRead>((resolve) => {
        if (link.length >= FIND.MIN_LINK_OR_NAME_LENGTH) {
          setIsFetching(true);
          getChannels({
            keywords: link.trim()
          })
            .then((res) => {
              if (res?.data?.channels?.length) {
                getChannelList({
                  ids: res?.data?.channels ?? []
                })
                  .then((res) => {
                    resolve(res.data?.channels ?? []);
                    setChannels(res.data?.channels ?? []);
                  })
                  .catch(() => {
                    resolve([]);
                    setChannels([]);
                  })
                  .finally(() => setIsFetching(false));
              } else {
                resolve([]);
                setChannels([]);
                setIsFetching(false);
              }
            })
            .catch(() => {
              resolve([]);
              setChannels([]);
              setIsFetching(false);
            });
        } else {
          resolve([]);
          setChannels([]);
        }
      }),
    [getChannelList, getChannels]
  );

  return {
    find,
    channels,
    isLoading: channelsListData.isLoading,
    isFetching
  };
};
