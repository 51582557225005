import { FormEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Cascader } from 'antd';
import { FormApi } from 'final-form';
import { IconSubjects } from 'fsd/shared/icons';
import { flattenObj } from 'fsd/shared/lib/helpers/tools';
import { Button } from 'fsd/shared/ui/Buttons';
import Modal from 'fsd/shared/ui/Modal';

import SubjectList from 'components/SubjectList';
import { FormValues } from 'pages/catalog/types';

import * as S from './styles';
import { WrapperIconSubj } from './styles';

type SubjectsProps = {
  handleSubmit(event?: FormEvent<HTMLFormElement>): void;
  total?: number;
  form: FormApi<FormValues, Partial<FormValues>>;
  values: Partial<FormValues>;
};

function Subjects({ handleSubmit, total, form, values }: SubjectsProps) {
  const [subjectsMobileVisible, setSubjectsMobileVisible] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const handleSubjectsSubmit = (e: FormEvent<HTMLFormElement>) => {
    setSubjectsMobileVisible(false);
    handleSubmit(e);
  };

  const handleCheckboxClick = (nextSelectedCategories: number[]) => {
    setSearchParams(
      flattenObj({
        ...values,
        categories: nextSelectedCategories
      })
    );

    form.change('categories', nextSelectedCategories);
    form.submit();
  };

  const filterCount = values.categories?.length;

  return (
    <>
      <Cascader
        placement="bottomLeft"
        dropdownRender={() => (
          <SubjectList
            initiallySelectedCategories={values?.categories}
            onCheckedCategoriesChange={handleCheckboxClick}
          />
        )}
      >
        <S.SubjectsButton className="usiteful__catalog-subjects-button">
          <S.FilterCountWrapper>
            <WrapperIconSubj>
              <IconSubjects />
            </WrapperIconSubj>

            {Boolean(filterCount) && <S.FilterCount>{filterCount}</S.FilterCount>}
          </S.FilterCountWrapper>
          <span>Тематика</span>
          <S.DropdownArrow />
        </S.SubjectsButton>
      </Cascader>

      <S.MobileSubjectsButton onClick={() => setSubjectsMobileVisible(true)}>
        <S.FilterCountWrapper>
          <WrapperIconSubj>
            <IconSubjects />
          </WrapperIconSubj>

          {Boolean(filterCount) && <S.FilterCount>{filterCount}</S.FilterCount>}
        </S.FilterCountWrapper>
        <S.SubjectsButtonText>Тематика</S.SubjectsButtonText>
      </S.MobileSubjectsButton>

      <Modal
        className="mobile-subjects-modal"
        open={subjectsMobileVisible}
        onCancel={() => setSubjectsMobileVisible(false)}
        closable={false}
      >
        <S.RelativeForm onSubmit={handleSubjectsSubmit}>
          {subjectsMobileVisible && (
            <SubjectList
              initiallySelectedCategories={values?.categories}
              onCheckedCategoriesChange={handleCheckboxClick}
            />
          )}
          {total ? (
            <S.AbsoluteSafeButton type="primary" size="large" block htmlType="submit">
              Показать {total} каналов
            </S.AbsoluteSafeButton>
          ) : (
            <Button type="primary" size="large" block disabled>
              Нет результатов
            </Button>
          )}
        </S.RelativeForm>
      </Modal>
    </>
  );
}

export default Subjects;
