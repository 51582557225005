import React from 'react';
import { ChannelRead, ChannelWithExchangeInfo } from 'api/api';
import FakeIcon from 'fsd/shared/ui/FakeIcon';

import * as S from './styles';

export const ChannelInfo = ({ channel }: { channel: ChannelWithExchangeInfo }) => {
  return (
    <S.ChannelInfo>
      <S.ChannelIconWrap>
        {channel.info.icon_url ? (
          <S.ChannelIcon>
            <img src={`data:image/png;base64,${channel.info.icon_url}`} alt="" />
          </S.ChannelIcon>
        ) : (
          <FakeIcon channelName={channel.info.title} channelId={channel.info.id} />
        )}
      </S.ChannelIconWrap>
      <S.ChannelTitle>{channel.info.title}</S.ChannelTitle>
    </S.ChannelInfo>
  );
};
