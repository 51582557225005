import { InputProps } from 'antd';
import { css } from 'styled-components';

export const BaseStyles = css<{
  $borderBottom?: boolean;
  $fontWeight?: number;
  $isError?: boolean;
  size?: InputProps['size'];
  $styleType?: 'white';
}>`
  border: 1px solid ${({ theme }) => theme.alpineGoat};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.alpineGoat};
  padding: 6px 13px;
  outline: none;

  ${({ $fontWeight }) =>
    $fontWeight &&
    css`
      font-weight: ${$fontWeight};

      input {
        font-weight: ${$fontWeight};
      }
    `}

  ${({ theme, $styleType }) => {
    if ($styleType === 'white') {
      return css`
        background-color: ${theme.white};

        .ant-input {
          background-color: ${theme.white};
        }
      `;
    }
    return css`
      .ant-input {
        background-color: ${theme.alpineGoat};
      }
    `;
  }}

  &:focus {
    outline: 4px solid rgba(72, 7, 234, 0.3);
  }

  &::placeholder {
    color: ${({ theme }) => theme.bayFog};
  }

  ${(props) =>
    props.size === 'large' &&
    css`
      padding: 10px 13px;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: 4px 12px;
    `}

${({ theme, $borderBottom }) =>
    $borderBottom &&
    css`
      border-bottom: 2px solid ${theme.bayFog};
      border-radius: 0;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: ${theme.alpineGoat};
        border-bottom: 2px solid ${theme.persianBlue};
      }

      &:hover {
        outline: none;
        box-shadow: none;
        border-color: ${theme.alpineGoat};
        border-bottom: 2px solid ${theme.persianBlue};
      }
    `}
  
  ${({ theme, $isError }) =>
    $isError &&
    css`
      border: 1px solid ${theme.hotspot};
    `}

  input {
    padding: 0;
  }

  input::placeholder {
    color: ${({ theme }) => theme.bayFog};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background-color: ${theme.alpineGoat};
    border: 1px solid ${theme.alpineGoat};
  }
`;
