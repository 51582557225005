import dayjs from 'dayjs';

import { ColumnComponentExporter } from '../../../types';

const sorter: ColumnComponentExporter['sorter'] = {
  compare: (a, b) => {
    const dateA = a.common_purchase_data?.date
      ? dayjs(a.common_purchase_data.date).minute(a.time ?? 0)
      : 0;
    const dateB = b.common_purchase_data?.date
      ? dayjs(b.common_purchase_data.date).minute(b.time ?? 0)
      : 0;

    return +dateB - +dateA;
  }
};

export default sorter;
