import { useGetV1ExchangePurchasesContainersByContainerIdQuery } from 'api/api';

import { getCartContainerIdSelector } from '../model/selectors';

export default () => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  const { data } = useGetV1ExchangePurchasesContainersByContainerIdQuery(
    {
      containerId: cartContainerId!
    },
    {
      skip: !cartContainerId
    }
  );

  return { data: cartContainerId ? data : [] };
};
