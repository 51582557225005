/* eslint-disable */
// @ts-nocheck

import { theme } from 'theme/theme';
import { DataType } from '../../../types/chart';

const getDefaultSettingsByType = (type) => {
  if (type === 'bar') {
    return {
      backgroundColor: theme.persianBlue,
      barThickness: 9,
      borderRadius: 3,
      borderSkipped: false
    };
  }
  if (type === 'PersianLineWithShadow') {
    return {
      borderWidth: 2,
      backgroundColor: 'transparent',
      borderColor: theme.baingani,
      fill: true,
      tension: 0.1
    };
  }

  return {
    borderWidth: 1,
    backgroundColor: 'transparent',
    borderColor: theme.blueRibbon,
    fill: true,
    tension: 0.1
  };
};

export const useGetChartData = (data: DataType[]) => {
  const filteredData = useMemo(
    () =>
      data.map((item) => {
        const { data: itemData } = item;

        return {
          ...item,
          data: itemData.filter((item) => item.x && item.x > 0)
        };
      }),
    [data]
  );

  const { start, end } = useMemo(
    () =>
      filteredData.reduce(
        (acc, item) => {
          const { data: itemData } = item;

          const itemStart = itemData[0]?.x;
          const itemEnd = itemData[itemData.length - 1]?.x;

          if (itemStart < acc.start) {
            acc.start = itemStart;
          }

          if (itemEnd > acc.end) {
            acc.end = itemEnd;
          }

          return acc;
        },
        { start: Infinity, end: -Infinity }
      ),
    [filteredData]
  );

  let payload = useMemo(() => {
    return {
      datasets: filteredData.map((item) => ({
        ...getDefaultSettingsByType(item.type),
        ...item
      }))
    };
  }, [filteredData, getDefaultSettingsByType]);

  const isInsufficientData = data[0].data.length < 2;

  if (isInsufficientData) {
    payload.datasets[0].data = [];
  }

  const isEmptyFilteredData = useMemo(
    () =>
      filteredData.every((item) => {
        return !item.data || !item.data.length;
      }),
    [filteredData]
  );

  const isEmptyData =
    !filteredData || !filteredData.length || isInsufficientData || isEmptyFilteredData;

  return {
    start: isInsufficientData ? -Infinity : start,
    end: isInsufficientData ? Infinity : end,
    payload,
    isEmptyData
  };
};
