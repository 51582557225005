import { ReactNode } from 'react';
import { SelectProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';

import * as S from './styles';

type SelectType = <
  ValueType = any,
  OptionType extends DefaultOptionType | BaseOptionType = DefaultOptionType
>(
  props: SelectProps<ValueType, OptionType>
) => ReactNode;

const Select: SelectType = ({ children, ...restProps }) => {
  return <S.Root {...restProps}>{children}</S.Root>;
};

export default memo(Select);
