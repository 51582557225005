import { FC, ReactElement } from 'react';
import { theme } from 'theme/theme';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './Notification.styles';

type Props = {
  icon?: ReactElement;
  text: string;
  btn?: {
    text: string;
    onClick: () => void;
  };
  isAboveAll?: boolean;
};

const Notification: FC<Props> = ({ icon, text, btn, isAboveAll }) => {
  return (
    <S.Root isAboveAll={isAboveAll}>
      <S.TextContainer>
        {icon && (
          <Icon width={18} color={theme.white}>
            {icon}
          </Icon>
        )}
        <span>{text}</span>
      </S.TextContainer>
      {btn && (
        <S.NotifBtn type="primary" size="middle" onClick={btn.onClick}>
          {btn.text}
        </S.NotifBtn>
      )}
    </S.Root>
  );
};

export default Notification;
