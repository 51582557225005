import { FC } from 'react';
import formatPrice from 'fsd/shared/lib/formatPrice';

type Props = {
  value: number;
  currency?: string;
};

const Price: FC<Props> = ({ value, currency = '₽' }) => {
  return (
    <>
      {formatPrice(value)} {currency}
    </>
  );
};

export default Price;
