import styled from 'styled-components';

import ChannelIcon from '../ChannelIcon';
import ChannelVerified from '../ChannelVerified';

export const Root = styled.div`
  border-radius: 10px;
  padding: 5px;
  background: ${({ theme }) => theme.alpineGoat};
  display: flex;
  flex-wrap: nowrap;
`;

export const Icon = styled(ChannelIcon)`
  margin-right: 12px;
`;

export const Content = styled.div`
  display: flex;
  width: calc(100% - 36px - 12px);
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 24px - 4px);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

export const Title = styled.h3`
  margin: 0;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 114%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.blackRock};
`;

export const Verified = styled(ChannelVerified)`
  margin-left: 6px;
`;

export const Caption = styled.small`
  margin: 0;
  line-height: 114%;
  font-size: 12px;
  color: ${({ theme }) => theme.bayFog};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EndIconWrapper = styled.div`
  margin-left: auto;
  align-self: center;
  color: ${({ theme }) => theme.violetPowder};
  width: 24px;
`;
