import styled from 'styled-components';

export const Root = styled.div<{ accent?: boolean; success?: boolean }>`
  --icon-color: ${({ accent, success }) => {
    if (success) return theme.americanGreen;
    if (accent) return theme.orange;
    return theme.persianBlue;
  }};
  --icon-wrapper-background-color: ${({ accent, success }) => {
    if (success) return theme.americanGreenLight;
    if (accent) return theme.orangeLight;
    return theme.violetLight;
  }};
  display: flex;
  align-items: center;
  color: ${({ accent }) => (accent ? theme.orange : theme.blackRock)};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: var(--icon-wrapper-background-color);
  border-radius: 9px;
  margin-right: 14px;
  flex: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.h4`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 100%;
  color: inherit;
`;

export const Value = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  line-height: 100%;
  color: inherit;
`;
