import { ColumnsType } from 'antd5/lib/table';
import { PurchaseRead } from 'api/api';

import Cost from '../ui/columns/Cost';
import Coverage from '../ui/columns/Coverage';
import Cpm from '../ui/columns/Cpm';
import CpmTotal from '../ui/columns/CpmTotal';
import Creative from '../ui/columns/Creative';
import DateWithTime from '../ui/columns/DateWithTime';
import Format from '../ui/columns/Format';
import Info from '../ui/columns/Info';
import Menu from '../ui/columns/Menu';
import Status from '../ui/columns/Status';
import Subscribers from '../ui/columns/Subscribers';

import useTableData from './useTableData';

export type ColumnData = PurchaseRead;

type Args = {
  tableData: ReturnType<typeof useTableData>;
};

export default ({ tableData }: Args) => {
  const columns = useMemo((): ColumnsType<ColumnData> => {
    return [
      {
        key: 'info',
        title: <Info.Title />,
        render: (purchase: ColumnData) => <Info.Cell purchase={purchase} />
      },
      {
        key: 'format',
        title: <Format.Title />,
        render: (purchase: ColumnData) => <Format.Cell purchase={purchase} />,
        sorter: Format.sorter
      },
      {
        key: 'cost',
        title: <Cost.Title />,
        render: (purchase: ColumnData) => <Cost.Cell purchase={purchase} />,
        sorter: Cost.sorter
      },
      {
        key: 'cpm',
        title: <Cpm.Title />,
        render: (purchase: ColumnData) => <Cpm.Cell purchase={purchase} />,
        sorter: Cpm.sorter(tableData?.metrics)
      },
      {
        key: 'dateWithTime',
        title: <DateWithTime.Title />,
        align: 'center',
        render: (purchase: ColumnData) => <DateWithTime.Cell purchase={purchase} />,
        sorter: DateWithTime.sorter
      },
      {
        key: 'creative',
        title: <Creative.Title />,
        align: 'center',
        render: (purchase: ColumnData) => <Creative.Cell purchase={purchase} />,
        width: 120
      },
      {
        key: 'coverage',
        title: <Coverage.Title />,
        render: (purchase: ColumnData) => <Coverage.Cell purchase={purchase} />,
        sorter: Coverage.sorter
      },
      {
        key: 'cpmTotal',
        title: <CpmTotal.Title />,
        render: (purchase: ColumnData) => <CpmTotal.Cell purchase={purchase} />,
        sorter: CpmTotal.sorter
      },
      {
        key: 'subscribers',
        title: <Subscribers.Title />,
        render: (purchase: ColumnData) => <Subscribers.Cell purchase={purchase} />,
        sorter: Subscribers.sorter
      },
      {
        key: 'status',
        title: <Status.Title />,
        fixed: 'right',
        align: 'center',
        render: (purchase: ColumnData) => <Status.Cell purchase={purchase} />,
        width: 160
      },
      {
        key: 'menu',
        title: <Menu.Title />,
        align: 'center',
        fixed: 'right',
        render: (purchase: ColumnData) => <Menu.Cell purchase={purchase} />,
        width: 32
      }
    ];
  }, [tableData?.metrics]);
  return columns;
};
