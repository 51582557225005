import styled from 'styled-components';

import { NameCss } from './Label.styles';

export default styled.input`
  ${NameCss}
  border: none;
  padding: 0;
  color: inherit;
  background: none;
  outline: none;
`;
