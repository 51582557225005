import React, { memo } from 'react';
import { Skeleton } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { UserChannelsListRead } from 'api/api';

import NoDataWidget from '../../../../NoData';

import { ContentTableRow } from './Row';

import * as S from './styles';
import { TableRoot } from './styles';

type Props = {
  searchStr: string;
  channels?: UserChannelsListRead;
};

const sxLoader = {
  bgcolor: theme.alpineGoat,
  maxWidth: '1680px'
};

export const ContentManagementTable = memo(({ searchStr, channels }: Props) => {
  if (!channels?.length) {
    return (
      <S.LoadersWrapper>
        <Skeleton animation="wave" variant="rounded" width="100%" height={186} sx={sxLoader} />
        <Skeleton animation="wave" variant="rounded" width="100%" height={186} sx={sxLoader} />
        <Skeleton animation="wave" variant="rounded" width="100%" height={186} sx={sxLoader} />
        <Skeleton animation="wave" variant="rounded" width="100%" height={186} sx={sxLoader} />
        <Skeleton animation="wave" variant="rounded" width="100%" height={186} sx={sxLoader} />
      </S.LoadersWrapper>
    );
  }

  const filteredData = channels.filter((channel) => {
    return channel.info.title.toLowerCase().includes(searchStr.toLowerCase());
  });

  if (!filteredData?.length)
    return (
      <S.TableWrapper>
        <NoDataWidget type="канала" />
      </S.TableWrapper>
    );

  return (
    <S.TableWrapper>
      <S.TableRoot>
        {channels?.map((channel) => <ContentTableRow key={channel.info.id} channel={channel} />)}
      </S.TableRoot>
    </S.TableWrapper>
  );
});
