import { theme } from 'theme/theme';

export const getColorByCount = (count: number, isNotification?: boolean): string => {
  let color = '';
  if (count > 0) {
    color = theme.americanGreen;
  } else if (count < 0) {
    color = theme.strawberryRed;
  } else if (count === 0 && isNotification) {
    color = theme.persianBlue;
  } else if (count === 0) {
    color = theme.blackRock;
  }
  return color;
};
