import { ComponentProps, FC } from 'react';
import { ChannelWithExchangeInfoRead } from 'api/api';
import { getFirstActiveChannelFormat } from 'fsd/entities/channel';
import { AddOrRemoveChannelFromCart } from 'fsd/features/addOrRemoveChannelFromCart';
import { SendColdLead } from 'fsd/features/sendColdLead';
import styled, { css } from 'styled-components';

type Props = {
  channel: ChannelWithExchangeInfoRead;
  layout?: ComponentProps<typeof AddOrRemoveChannelFromCart>['layout'];
};

export const ColdLeadWrapper = styled.div<{ $layout: Props['layout'] }>`
  ${({ $layout }) =>
    $layout === 'row' &&
    css`
      max-width: 200px;
    `}
`;

const Form: FC<Props> = ({ channel, layout = 'row' }) => {
  const defaultActiveFormat = useMemo(
    () => getFirstActiveChannelFormat(channel?.exchange_info?.formats ?? [])?.format_id,
    [channel?.exchange_info?.formats]
  );

  const isActiveExchange = Boolean(channel.exchange_info);

  const [formatId, setFormatId] = useState<number | undefined>(defaultActiveFormat);

  const handleSelect = useCallback((format?: number) => {
    setFormatId(format);
  }, []);

  useEffect(() => {
    setFormatId(defaultActiveFormat);
  }, [defaultActiveFormat]);
  return isActiveExchange ? (
    <AddOrRemoveChannelFromCart
      channel={channel}
      layout={layout}
      onSelect={handleSelect}
      value={formatId}
    />
  ) : (
    <ColdLeadWrapper $layout={layout}>
      <SendColdLead channel={channel} />
    </ColdLeadWrapper>
  );
};

export default memo(Form);
