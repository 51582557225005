import { theme } from 'theme/theme';

import { RootPaper, SkeletonProps } from './index';

type Props = SkeletonProps & {
  modificator?: boolean;
  animation?: boolean;
};

export const CardSkeleton = (props: Props) => {
  const { id, getWidth, getHeight, modificator, animation } = props;
  return (
    <RootPaper>
      <svg preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="100%"
          height="100%"
          clipPath={`url(#a${id})`}
          style={{ fill: `url(#b${id})` }}
        />
        <defs>
          <linearGradient id={`b${id}`}>
            <stop stopColor={theme.alpineGoat}>
              {animation && (
                <animate
                  attributeName="offset"
                  values="-1.5; -1.5; 1.5"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              )}
            </stop>
            <stop stopColor="hsl(228deg 23.81% 95.88% / 35%)">
              {animation && (
                <animate
                  attributeName="offset"
                  values="-1; -1; 2"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              )}
            </stop>
            <stop stopColor={theme.alpineGoat}>
              {animation && (
                <animate
                  attributeName="offset"
                  values="-0.5; -0.5; 2.5"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              )}
            </stop>
          </linearGradient>

          <clipPath id={`a${id}`}>
            <rect
              x={getWidth(24)}
              y={getHeight(28.5)}
              width={modificator ? getWidth(50) : getWidth(95)}
              height={getHeight(16.1)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(24)}
              y={getHeight(56.6)}
              width={getWidth(115)}
              height={getHeight(18.1)}
              rx="4"
              fill={theme.alpineGoat}
            />
            {modificator && (
              <>
                <rect
                  x={getWidth(150)}
                  y={getHeight(28.5)}
                  width={getWidth(95)}
                  height={getHeight(16.1)}
                  rx="4"
                  fill={theme.alpineGoat}
                />
                <rect
                  x={getWidth(150)}
                  y={getHeight(56.6)}
                  width={getWidth(115)}
                  height={getHeight(18.1)}
                  rx="4"
                  fill={theme.alpineGoat}
                />
              </>
            )}
            <rect
              x={getWidth(24)}
              y={getHeight(103.7)}
              width={getWidth(65)}
              height={getHeight(19.1)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(24)}
              y={getHeight(176.9)}
              width={getWidth(65)}
              height={getHeight(19.1)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(163)}
              y={getHeight(103.7)}
              width={getWidth(65)}
              height={getHeight(19.1)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(163)}
              y={getHeight(176.9)}
              width={getWidth(65)}
              height={getHeight(19.1)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(24)}
              y={getHeight(130.8)}
              width={getWidth(65)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(24)}
              y={getHeight(204)}
              width={getWidth(65)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(163)}
              y={getHeight(130.8)}
              width={getWidth(65)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(163)}
              y={getHeight(204)}
              width={getWidth(65)}
              height={getHeight(15)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <circle cx={getWidth(284)} cy={getHeight(41.5)} fill={theme.alpineGoat} r="8" />
            <circle cx={getWidth(382)} cy={getHeight(41.5)} fill={theme.alpineGoat} r="8" />
            {!modificator && (
              <circle cx={getWidth(446)} cy={getHeight(41.5)} fill={theme.alpineGoat} r="8" />
            )}
            <rect
              x={getWidth(296)}
              y={getHeight(36.5)}
              width={getWidth(70)}
              height={getHeight(11)}
              rx="4"
              fill={theme.alpineGoat}
            />
            <rect
              x={getWidth(394)}
              y={getHeight(36.5)}
              width={getWidth(38)}
              height={getHeight(11)}
              rx="4"
              fill={theme.alpineGoat}
            />
            {!modificator && (
              <rect
                x={getWidth(458)}
                y={getHeight(36.5)}
                width={getWidth(50)}
                height={getHeight(11)}
                rx="4"
                fill={theme.alpineGoat}
              />
            )}
            <rect
              x={getWidth(275)}
              y={getHeight(56.6)}
              width={getWidth(433)}
              height={getHeight(167.5)}
              rx="4"
              fill={theme.alpineGoat}
            />
          </clipPath>
        </defs>
      </svg>
    </RootPaper>
  );
};
