import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Checkbox } from 'antd';
import {
  Format,
  Id6,
  useGetV1ExchangePurchasesContainersQuery,
  useLazyGetV1ExchangePurchasesContainersByContainerIdQuery,
  usePatchV1ExchangePurchaseUpdateMutation,
  usePostV1ExchangePurchaseContainersCreateMutation,
  usePostV1ExchangePurchasesContainersCreateMutation
} from 'api/api';
import { FormApi } from 'final-form';
import { useGetUserChannelFormatsQuery } from 'fsd/entities/format';
import { IconPin } from 'fsd/shared/icons';
import { useStickyState } from 'fsd/shared/lib/hooks/useStickyState';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';
import styled from 'styled-components';

import { FormatsType } from 'pages/catalog/components/Table/components/Row/components/Price/Action';

import { validation } from './validation';

import * as S from '../../styles';
import * as LS from './styles';

export type FormValues = {
  purchase_channel_id: string | string[];
  container_id_list: Id6[];
};

type Props = {
  channelId: string[];
  setIsDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setFormIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  format: FormatsType[number];
};

const IconPinWithPointer = styled(IconPin)`
  cursor: pointer;
`;

const FormAddPurchaseToExistContainer = forwardRef((props: Props, ref: any) => {
  const { channelId, setIsDropdownVisible, setIsPopoverVisible, setFormIsValid, format } = props;
  const [pinnedItems, setPinnedItems] = useStickyState([], 'pinnedContainers');
  const [formApi, setFormApi] = useState<FormApi<any> | null>(null);

  const { data: formatsData = [] } = useGetUserChannelFormatsQuery(
    { formatsId: [format.format_id] },
    {
      skip: !format.format_id
    }
  );

  const getFormatData = useCallback((): Format | undefined => {
    const formatData = formatsData.find((f) => f.format_id === format.format_id);
    if (!formatData) return;
    // eslint-disable-next-line consistent-return
    return {
      time_in_ad_hours: formatData.time_in_feed_minutes,
      time_in_top_hours: formatData.time_in_top_minutes
    };
  }, [format.format_id, formatsData]);

  const { data: containers, isLoading } = useGetV1ExchangePurchasesContainersQuery({});

  const [addPurchaseToExistContainers] = usePostV1ExchangePurchaseContainersCreateMutation();

  const [addManyPurchasesToExistContainers] = usePostV1ExchangePurchasesContainersCreateMutation();

  const [getContainer] = useLazyGetV1ExchangePurchasesContainersByContainerIdQuery();

  const [updatePurchase] = usePatchV1ExchangePurchaseUpdateMutation();

  const isSingleChannel = channelId.length === 1;

  // Подписываемся на изменения в форме
  useEffect(() => {
    if (formApi) {
      formApi.subscribe(
        ({ valid, pristine }) => {
          if (setFormIsValid) setFormIsValid(valid && !pristine);
        },
        { valid: true, pristine: true }
      );
    }
  }, [formApi]);

  // Передаем handleSubmit в ref
  useImperativeHandle(ref, () => ({
    handleSubmit: () => formApi?.submit()
  }));

  const handleSubmit = useCallback(
    (values: { container_id_list: Id6[]; [key: string]: any }) => {
      if (isSingleChannel) {
        addPurchaseToExistContainers({
          createPurchase: {
            ...values,
            container_id_list: values.container_id_list as string[],
            purchase_channel_id: channelId[0]
          }
        })
          .unwrap()
          .then((data) => {
            values.container_id_list.forEach((containerId) => {
              getContainer({
                containerId: containerId as string
              })
                .unwrap()
                .then((container) => {
                  const purchase = container.find(
                    (purchase) => purchase.channel.info.id === channelId[0]
                  );
                  if (purchase) {
                    const formatData = getFormatData();
                    if (formatData) {
                      updatePurchase({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        updatePurchase: {
                          ...purchase,
                          purchase_id: purchase.purchase_id,
                          currency: 'RUB',
                          format: formatData,
                          cost: format.priceNumber,
                          date: null,
                          cpm_amount: null,
                          cps_amount: null
                        }
                      });
                    }
                  }
                });
            });
            CustomToast({
              type: 'success',
              message: <ToastMessage title="Канал добавлен в списки кампаний" />
            });
            setIsDropdownVisible((prevState) => !prevState);
            setIsPopoverVisible((prevState) => !prevState);
          })
          .catch((err) => {
            CustomToast({
              type: 'error',
              message: <ToastMessage title="Ошибка добавления канала в списки кампаний" />
            });
            throw err;
          });
      } else {
        addManyPurchasesToExistContainers({
          createPurchaseList: {
            ...values,
            container_id_list: values.container_id_list as string[],
            purchase_channel_id_list: channelId
          }
        })
          .unwrap()
          .then((data) => {
            CustomToast({
              type: 'success',
              message: <ToastMessage title="Каналы добавлены в списки кампаний" />
            });
            setIsDropdownVisible((prevState) => !prevState);
            setIsPopoverVisible((prevState) => !prevState);
          })
          .catch((err) => {
            CustomToast({
              type: 'error',
              message: <ToastMessage title="Ошибка добавления каналов в списки кампаний" />
            });
            throw err;
          });
      }
    },
    [
      addManyPurchasesToExistContainers,
      addPurchaseToExistContainers,
      channelId,
      format.priceNumber,
      getContainer,
      getFormatData,
      isSingleChannel,
      setIsDropdownVisible,
      setIsPopoverVisible,
      updatePurchase
    ]
  );

  if (isLoading) {
    return (
      <LS.LoaderWrapper>
        <LoaderSpinner fontSize={30} />
      </LS.LoaderWrapper>
    );
  }
  const reverseContainers = containers?.toReversed();

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      validate={validation}
      render={({ form }) => {
        useEffect(() => {
          setFormApi(form);
        }, []);

        return (
          <form>
            <S.DropdownContent>
              <S.MyPurchaseText>Мои кампании</S.MyPurchaseText>
              {(!containers || containers.length === 0) && (
                <S.NoValueBlock>
                  <S.NoValueText>У вас нет пока ни одной кампании</S.NoValueText>
                  <S.NoValueDescription>
                    После создания они будут отображаться здесь
                  </S.NoValueDescription>
                </S.NoValueBlock>
              )}
              {reverseContainers?.map((item) => (
                <S.SubjectItem
                  className="no-translation"
                  active={pinnedItems.includes(item.container_id)}
                  key={item.container_id}
                >
                  <Field
                    name="container_id_list"
                    type="checkbox"
                    value={item.container_id}
                    render={({ input }) => <Checkbox {...input}>{item.title}</Checkbox>}
                  />

                  <IconPinWithPointer
                    onClick={() => {
                      setPinnedItems((prev: string[]) => {
                        if (prev.includes(item.container_id)) {
                          return prev.filter((pinned) => pinned !== item.container_id);
                        }
                        return [...prev, item.container_id];
                      });
                    }}
                  />
                </S.SubjectItem>
              ))}
            </S.DropdownContent>
          </form>
        );
      }}
    />
  );
});

export default FormAddPurchaseToExistContainer;
