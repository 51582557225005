/* eslint-disable */
// @ts-nocheck

import { LineController } from "chart.js";

class PersianLineWithShadow extends LineController {
  draw() {
    super.draw(arguments);
    const ctx = this.chart.ctx;
    let _stroke = ctx.stroke;
    ctx.stroke = function () {
      ctx.save();
      ctx.shadowColor = theme.baingani;
      ctx.shadowBlur = 6;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 2;

      _stroke.apply(this, arguments);
      ctx.restore();
    };
  }
}

PersianLineWithShadow.id = "PersianLineWithShadow";
PersianLineWithShadow.defaults = LineController.defaults;

export { PersianLineWithShadow };
