import { FC } from 'react';
import { IconRuble } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';
import { Price } from 'fsd/shared/ui/Price';

import { BalanceTypes } from '../../model/types';

import * as S from './styles';

const labelMap = new Map<BalanceTypes, string>([
  ['active', 'Баланс'],
  ['blocked', 'Резерв для оплаты'],
  ['profit', 'Доход от продаж'],
  ['withdrawal', 'В процессе вывода']
]);

type Props = {
  value: number;
  type: BalanceTypes;
};

const Info: FC<Props> = ({ value, type }) => {
  const accent = ['blocked', 'withdrawal'].includes(type);
  const success = type === 'profit';

  return (
    <S.Root accent={accent} success={success}>
      <S.IconWrapper>
        <Icon color="var(--icon-color)" width={20}>
          <IconRuble />
        </Icon>
      </S.IconWrapper>
      <S.Content>
        <S.Label>{labelMap.get(type)}</S.Label>
        <S.Value>
          <Price value={value} />
        </S.Value>
      </S.Content>
    </S.Root>
  );
};

export default memo(Info);
export { default as InfoWithData } from './WithData';
