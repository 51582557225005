import { FC } from 'react';
import { PurchaseRow } from 'fsd/entities/purchase';

import { TableContext } from '../../lib/tableContext';
import useTableData from '../../lib/useTableData';

import Row from './Row';

type Props = {
  containerId: string;
};

const MobileRows: FC<Props> = ({ containerId }) => {
  const tableData = useTableData(containerId);
  return (
    <TableContext.Provider value={tableData}>
      <PurchaseRow.List>
        {tableData.dataSource.map((data) => (
          <Row key={data.purchase_id} purchase={data} />
        ))}
      </PurchaseRow.List>
    </TableContext.Provider>
  );
};

export default memo(MobileRows);
