import { ComponentType } from 'react';
import { BrowserRouter } from 'react-router-dom';

export const withBrowserRouter = (Component: ComponentType<{}>) => () => {
  return (
    <BrowserRouter>
      <Component />
    </BrowserRouter>
  );
};
