import { FC, PropsWithChildren } from 'react';
import { PurchaseRead, useGetV1ExchangePurchaseByPurchaseIdCreativeQuery } from 'api/api';
import { PostViewModal } from 'fsd/entities/post';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconEye } from 'fsd/shared/icons';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';
import { Button } from 'fsd/shared/ui/Buttons';
import { InfoBlock } from 'fsd/shared/ui/InfoBlock';

import InviteLink from '../InviteLink';

import * as S from './styles';

type Props = {
  purchaseId: string;
  channelId?: string;
  inviteLink?: string;
  inviteLinkForm: FC<{ inviteLink?: string; purchaseId: string }>;
};

const Preview: FC<PropsWithChildren<Props>> = ({
  purchaseId,
  inviteLink,
  channelId,
  children,
  inviteLinkForm: InviteLinkForm
}) => {
  const { data: creative, isLoading } = useGetV1ExchangePurchaseByPurchaseIdCreativeQuery({
    purchaseId
  });

  return (
    <S.Root>
      <S.Header>
        <S.Title>Пост прикрепленный к заявке</S.Title>
        <InviteLink
          value={inviteLink}
          // eslint-disable-next-line react/no-unstable-nested-components
          formRender={({ value }) => <InviteLinkForm inviteLink={value} purchaseId={purchaseId} />}
        />
      </S.Header>
      <S.Body>
        <S.Content>{isLoading ? 'Пост загружается...' : creative?.text ?? 'Пост пустой'}</S.Content>
      </S.Body>
      <S.Footer>
        <InfoBlock>
          Это превью поста. Здесь нет картинок, но пост будет опубликован в том виде, в котором мы
          его получили.{' '}
        </InfoBlock>
        {children}
      </S.Footer>
    </S.Root>
  );
};

export default memo(Preview);
