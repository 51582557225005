import { useEffect, useState } from 'react';

export const useStickyState = (defaultValue: unknown, key: string, useSessionStorage?: boolean) => {
  const storage = useSessionStorage ? sessionStorage : localStorage;

  const [value, setValue] = useState(() => {
    const stickyValue = storage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });

  useEffect(() => {
    storage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};
