import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { LayoutContent } from 'fsd/shared/ui/Layout';
import styled, { css } from 'styled-components';

export const Root = styled.div<{ $isHaveCart?: boolean }>`
  margin-top: 16px;
  ${({ $isHaveCart }) =>
    $isHaveCart &&
    css`
      padding-bottom: 80px;
    `}
  @media ${MEDIA_DEVICE.mobileL.to} {
    padding-bottom: ${({ $isHaveCart }) => ($isHaveCart ? '120px' : '20px')};
    margin: 16px -16px 0 -16px;
  }
`;

export const Wrapper = styled(LayoutContent)``;
