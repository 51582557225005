import { FC, MouseEventHandler } from 'react';
import { usePatchV1ExchangePurchaseContainerMutation } from 'api/api';
import {
  FindChannelOrExternalServiceProvider,
  useChannelOrExternalService
} from 'fsd/entities/channel';
import normalizeLink from 'fsd/shared/lib/normalizeLink';
import { Button } from 'fsd/shared/ui/Buttons';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import * as S from './Form.styles';

type Props = {
  containerId: string;
  onSuccess?: () => void;
  onClick?: MouseEventHandler<HTMLFormElement>;
};

const Form: FC<Props> = ({ onSuccess, containerId, onClick }) => {
  const channelOrExternalService = useChannelOrExternalService();
  const [updateContainer, { isLoading }] = usePatchV1ExchangePurchaseContainerMutation();

  const handleFinish = useCallback(() => {
    const { selectedItem } = channelOrExternalService;
    if (selectedItem) {
      updateContainer({
        body: {
          container_id: containerId,
          type: selectedItem.type,
          note: '',
          source_link: selectedItem.link ? normalizeLink(selectedItem.link) : ''
        }
      })
        .unwrap()
        .then(() => {
          CustomToast({
            type: 'success',
            message: <ToastMessage title="Контейнер успешно изменён" />
          });
          onSuccess?.();
        })
        .catch(() => {
          CustomToast({
            type: 'error',
            message: <ToastMessage title="При изменении контейнера произошла ошибка" />
          });
        });
    }
  }, [channelOrExternalService, containerId, onSuccess, updateContainer]);

  return (
    <FindChannelOrExternalServiceProvider value={channelOrExternalService}>
      <S.Root onFinish={handleFinish} onClick={onClick}>
        <S.Input />
        <Button
          disabled={
            !channelOrExternalService.selectedItem || channelOrExternalService.isEdited || isLoading
          }
          htmlType="submit"
          type="primary"
          size="middle"
          block
        >
          Обновить
        </Button>
      </S.Root>
    </FindChannelOrExternalServiceProvider>
  );
};

export default memo(Form);
