export enum EInvoiceHTMLFields {
  AMOUNT = 'amount',
  AMOUNT_WORDS = 'amountWords',
  DATE = 'date',
  NUMBER = 'number',
  DEAD_LINE = 'deadLine',
  ORG_INFO = 'orgInfo',
  CREATE_ORG_DATE = 'createOrgDate'
}

const generalTable = `<table border style="border-collapse:collapse" cellspacing="0">
    <tbody>
      <tr>
        <td colspan="2" rowspan="2">
          АО"Райффайзенбанк"</br>
          Банк получателя</td>
        <td>БИК</td>
        <td>044030723</td>
      </tr>
      <tr>
        <td>Сч. №</td>
        <td>30101810100000000723</td>
      </tr>
      <tr>
        <td>ИНН 7841099216</td>
        <td>КПП 784101001</td>
        <td rowspan="2">Сч. №</td>
        <td rowspan="2">40702810903000089977</td>
      </tr>
      <tr>
        <td colspan="2">Общество с ограниченной ответственностью "ТЕЛЕСПЭЙС"<br/>
          Получатель</td>
      </tr>
    </tbody>
  </table>`;

const customerTable = `<table border style="border-collapse:collapse" cellspacing="0">
    <tbody>
    <tr>
      <td>№</td>
      <td>Товары (работы, услуги)</td>
      <td>Кол-во</td>
      <td>Ед.</td>
      <td>Цена</td>
      <td>Сумма</td>
    </tr>
    <tr>
      <td>1</td>
      <td>Размещение рекламно-информационных материалов на платформе Telegram</td>
      <td>1

      </td>
      <td>Услуга</td>
      <td>{{${EInvoiceHTMLFields.AMOUNT}}}</td>
      <td>{{${EInvoiceHTMLFields.AMOUNT}}}</td>
    </tr>
  </tbody>
</table/>`;

export default `${generalTable}
<h2 style="padding-top: 13pt;padding-left: 8pt;text-indent: 0pt;text-align: left; margin: 0px">Счет на оплату № {{${EInvoiceHTMLFields.NUMBER}}} от {{${EInvoiceHTMLFields.DATE}}}</h2>
<hr />
<div style="padding-top: 0pt;padding-left: 7pt;text-indent: 0pt;text-align: left;">Поставщик (Исполнитель): <i><b style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Общество с ограниченной ответственностью «Телеспэйс»
      Limited Liability Company “Telespace” LLC “ Telespace”, ИНН 7841099216, 191186, г.Санкт-Петербург вн.тер.г. Муниципальный округ Дворцовый округ, наб. Канала Грибоедова д.6/2, литера А, помещ.25-Н, офис 418

    </b></i></div>
<p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Покупатель (Заказчик): <b style="padding-left: 7pt;text-indent: 0pt;text-align: left;">{{${EInvoiceHTMLFields.ORG_INFO}}}</b>
<p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Основание: Договор оферта от {{${EInvoiceHTMLFields.CREATE_ORG_DATE}}}</p>
${customerTable}
<h5 style="text-align: right;">Итого: {{${EInvoiceHTMLFields.AMOUNT}}}</h5>
<h5 style="padding-left: 15pt;text-align: right;">Без налога (НДС)</h5>
<h5 style="padding-left: 15pt;text-align: right;">Всего к оплате: {{${EInvoiceHTMLFields.AMOUNT}}}</h5>
<p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Всего наименований 1, на сумму {{${EInvoiceHTMLFields.AMOUNT}}} руб.</p>
<b style="padding-left: 7pt;text-indent: 0pt;text-align: left;">{{${EInvoiceHTMLFields.AMOUNT_WORDS}}}</b>
<p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Оплатить не позднее {{${EInvoiceHTMLFields.DEAD_LINE}}}</p>
<p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Оплата данного счета означает согласие Заказчика с условиями оказания услуг:</p>
<ol>
  <li>
    Исполнитель обязуется оказать Заказчику услуги, а Заказчик обязуется их принять и оплатить.
  </li>
  <li>
    Сведения об оказываемых услугах содержатся в настоящем счете.
  </li>
  <li>
    Оплата услуг осуществляется Заказчиком путем безналичного перевода денежных средств на расчетный счет Исполнителя с обязательным указанием в платежном поручении реквизитов настоящего счета.
  </li>
  <li>
    Заказчик обязуется оплатить услуги в размере 100% в течение 3 рабочих дней.
  </li>
</ol>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<hr />
<p style="padding-left: 7pt; width: 100%">Директор ООО "Телеспейс" Ю.С. Евтихеева</p>
  <hr />
  `;
