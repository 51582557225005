import { NavLink } from 'react-router-dom';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

const SettingsNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.violetPowder};

  &.active {
    color: ${({ theme }) => theme.persianBlue};
  }

  @media ${MEDIA_DEVICE.mobile.to} {
    gap: 10px;
  }
`;

export default memo(SettingsNavLink);
