import {
  PatchV1ExchangePurchaseUpdateApiArg,
  PurchaseRead,
  usePatchV1ExchangePurchaseUpdateMutation
} from 'api/api';
import { mergeDeep } from 'remeda';

type UpdateData = PatchV1ExchangePurchaseUpdateApiArg['updatePurchase'];

export default () => {
  const [updatePurchase, { isLoading }] = usePatchV1ExchangePurchaseUpdateMutation();

  const update = useCallback(
    (purchase: Omit<PurchaseRead, 'channel'>, updateData: Partial<UpdateData>) => {
      return updatePurchase({
        updatePurchase: mergeDeep<UpdateData, Partial<UpdateData>>(
          {
            purchase_id: purchase.purchase_id,
            currency: purchase.currency,
            post_id: purchase.post_id || null,
            time: purchase.time || null,
            note: purchase.note || null,
            is_paid: purchase.is_paid || false,
            date: purchase.common_purchase_data?.date || null,
            cpm_amount: purchase.common_purchase_data?.cpm_amount || null,
            cps_amount: purchase.common_purchase_data?.cps_amount || null,
            cost: purchase.common_purchase_data?.cost || null,
            format: purchase.format ?? null,
            invite_link: purchase.invite_link ?? null
          },
          updateData
        ) as UpdateData
      });
    },
    [updatePurchase]
  );

  return [update, { isLoading }] as const;
};
