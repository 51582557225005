import { ComponentProps, FC, MouseEventHandler } from 'react';
import { ChannelWithExchangeInfo } from 'api/api';
import { ButtonSmall } from 'fsd/shared/ui/Buttons';

import { SendColdLeadForm } from '../Form';

import DropdownWrapper from './DropdownWrapper';

type Props = ComponentProps<typeof ButtonSmall> & {
  channel: ChannelWithExchangeInfo;
};

const Button: FC<Props> = ({ channel, ...restProps }) => {
  const [open, setOpen] = useState(false);
  const handleButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.stopPropagation();
  }, []);

  return (
    <DropdownWrapper
      title="Отправить заявку"
      open={open}
      onOpenChange={() => setOpen((val) => !val)}
      content={<SendColdLeadForm channel={channel} onSuccess={() => setOpen(false)} />}
    >
      <ButtonSmall type="primary" size="small" block {...restProps} onClick={handleButtonClick}>
        Отправить заявку
      </ButtonSmall>
    </DropdownWrapper>
  );
};

export default memo(Button);
