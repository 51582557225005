import { format } from 'date-fns';
import { dateFormat } from 'fsd/shared/lib/helpers/constants/dateFormats';

export const formatDate = (
  data: {
    date: string;
    value: number;
  }[]
) => {
  return data?.map((item: { date: string; value: number }) => {
    return {
      date: format(new Date(+item?.date), dateFormat),
      value: item?.value
    };
  });
};
