import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, type ChannelsListResponse, type ChannelWithExchangeInfo } from 'api/api';

interface IChannelState {
  items: ChannelWithExchangeInfo[];
  total: number;
}

const initialState: IChannelState = {
  items: [],
  total: 0
};

export const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    loadMore(state, action: PayloadAction<ChannelsListResponse>) {
      state.total = action.payload.total;
      state.items = action.payload.channels || [];
    }
  },
  extraReducers(builder) {
    builder.addMatcher(api.endpoints.getV1ChannelsList.matchFulfilled, (state, action) => {
      // state.items = action.payload.channels?.map((c) => ({
      //     info: c,
      // }));
    });
  }
});

export const { loadMore } = channelSlice.actions;

export default channelSlice.reducer;
