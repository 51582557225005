import { FC, memo } from 'react';
import { LayoutContent } from 'fsd/shared/ui/Layout';
import Modal from 'fsd/shared/ui/Modal';
import { CartBottomInfoWidget } from 'fsd/widgets/CartBottomInfo';
import { HeaderMobileWidget } from 'fsd/widgets/Header';

import ChannelSearch from 'components/ChannelSearch';

import { CatalogProps } from '../..';
import Filters from '../Filters';
import PeriodFilter from '../PeriodFilter';
import SearchBar from '../SearchBar';
import ChannelsTable from '../Table';

import { useCatalogLogic } from './hooks/useCatalogLogic';

import * as S from './styles';
import TopFilter from '../TopFilter/TopFilter';

const Catalog: FC<CatalogProps> = ({ catalogProps }) => {
  const {
    isFiltersModalVisible,
    setFiltersVisible,
    handleSubmit,
    resetFilters,
    handleChannelSearchItemClick,
    isAuth,
    form,
    values,
    data,
    isFetching,
    filtersCount
  } = useCatalogLogic({ catalogProps });

  return (
    <>
      <HeaderMobileWidget>
        <ChannelSearch
          onChannelClick={handleChannelSearchItemClick}
          placeholder="Название канала"
        />
      </HeaderMobileWidget>
      <LayoutContent>
        <S.Wrapper>
          <S.SearchBarWrapper>
            <SearchBar
              catalogProps={catalogProps}
              filtersCount={filtersCount}
              data={data}
              form={form}
              values={values}
              setFiltersVisible={setFiltersVisible}
              handleChannelSearchItemClick={handleChannelSearchItemClick}
            />
          </S.SearchBarWrapper>

          <S.TimeFilters>
            <PeriodFilter form={form} values={values} />
            <TopFilter form={form} values={values} />
          </S.TimeFilters>

          <Modal
            className="filters-modal"
            open={isFiltersModalVisible}
            onCancel={() => setFiltersVisible(false)}
            width={810}
            closable
            topPosition={0}
          >
            <Filters
              handleSubmit={handleSubmit}
              resetFilters={resetFilters}
              form={form}
              values={values}
              filtersCount={filtersCount}
            />
          </Modal>
          {catalogProps.onLoadMore && (
            <ChannelsTable
              form={form}
              values={values}
              data={data}
              onLoadMore={catalogProps.onLoadMore}
              hasMore={catalogProps.hasMore}
            />
          )}
        </S.Wrapper>
      </LayoutContent>
      <CartBottomInfoWidget page="catalog" />
    </>
  );
};

export default memo(Catalog);
