import { AutoComplete as AntdAutoComplete } from 'antd';
import { Icon } from 'fsd/shared/ui/Icon';
import { Input as SharedInput, InputClearButton } from 'fsd/shared/ui/Input';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';
import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
`;
export const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -5px;
  transform: translateY(100%);
  text-align: right;
  color: ${({ theme }) => theme.hotspot};
  font-weight: 400;
  font-size: 12px;
`;
export const AutoComplete = styled(AntdAutoComplete)``;
export const Input = styled(SharedInput)`
  height: 48px;
  input {
    color: ${({ isError, theme }) => isError && theme.hotspot};
  }
`;
export const IconLoader = styled(LoaderSpinner);

export const LabelInField = styled.div`
  display: flex;
  align-items: center;
  padding-left: 13px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const FieldIcon = styled(Icon)`
  flex: none;
  margin-right: 8px;
`;

export const RightWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  flex: none;
`;

export const FieldDivider = styled.div`
  flex: none;
  height: 32px;
  width: 1px;
  display: block;
  background: ${({ theme }) => theme.sweetFlower};
`;

export const FieldButton = styled.button`
  display: block;
  flex: none;
  color: ${({ theme }) => theme.persianBlue};
  font-weight: 400;
  font-size: 14px;
  height: 100%;
  padding: 0 8px;
  &:hover {
    color: ${({ theme }) => theme.primaryBtnHover};
  }
`;

export const InputIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
`;

export const Clear = styled(InputClearButton)`
  margin-right: 8px;
`;
