export const combineArrays = (
  dateArr: string[],
  valuesArr: number[]
): { date: string; value: number }[] => {
  const combinedArray = dateArr.map((date, index) => {
    return { date, value: valuesArr[index] || 0 };
  });
  return combinedArray;
};

export const combineArraysValues = (
  dateArr: string[],
  valuesArr: number[]
): { date: string; value: number }[] => {
  const combinedArray = valuesArr.map((value, index) => {
    return { date: dateArr[index], value: value };
  });

  return combinedArray;
};
