import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: calc(100% - 24px - 8px);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: 6px;
`;

export const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
  line-height: 114%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Category = styled.small`
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.bayFog};
`;

export const Link = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.blueRibbon};
`;
