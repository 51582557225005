import { useMemo } from 'react';
import { HistoryStatistic, HistoryStatistic2 } from 'api/api';
import { combineArrays, formatDate, getDatesArray } from 'fsd/shared/lib/helpers/tools';
import { theme } from 'theme/theme';

type DataType = 'line' | 'PersianLineWithShadow';

export const getGraphDataByParticipants = (
  payload: HistoryStatistic2 | HistoryStatistic | undefined,
  type?: DataType
) => {
  const participants = payload || {
    from: '',
    to: '',
    accuracy: 'day',
    history: []
  };

  const participantsDatesArr = getDatesArray(
    participants.from,
    participants.to,
    participants.accuracy,
    true
  );
  const participantsStatistic = combineArrays(participantsDatesArr, participants?.history || []);

  const graphData = [
    {
      type: 'line',
      label: 'Participants',
      borderWidth: type === 'PersianLineWithShadow' ? 2 : 1,
      lineTension: 0.2,
      borderColor: theme.baingani,
      data: participantsStatistic.map((item) => {
        return {
          x: +item.date,
          y: item.value
        };
      })
    }
  ];

  const participantsDiffPerAll = [
    {
      type: 'line',
      label: 'ParticipantsDiffPerAll',
      borderWidth: type === 'PersianLineWithShadow' ? 2 : 1,
      lineTension: 0.2,
      borderColor: theme.baingani,
      data: participantsStatistic.map((item, index: number) => {
        return {
          x: +item.date,
          y: item.value - participantsStatistic[(index || 1) - 1].value
        };
      })
    }
  ];

  return {
    graphData,
    participantsStatistic: formatDate(participantsStatistic),
    participantsDiffPerAll
  };
};

export const useStatistic = (
  payload: HistoryStatistic2 | HistoryStatistic | undefined,
  type?: DataType
) => {
  const participants = useMemo(
    (): HistoryStatistic2 | HistoryStatistic =>
      payload || {
        from: '',
        to: '',
        accuracy: 'day',
        history: []
      },
    [payload]
  );

  const { graphData, participantsStatistic, participantsDiffPerAll } = useMemo(
    () => getGraphDataByParticipants(participants, type),
    [participants, type]
  );

  const history = participants?.history || [];
  const all = (history.length && history[history.length - 1]) || 0;

  return {
    participantsStatistic,
    participantsAll: all,
    graphData,
    participantsDiffPerAll
  };
};
