/* eslint-disable no-nested-ternary */
import React from 'react';
import { Id, toast, ToastContent, ToastOptions } from 'react-toastify';
import { IconAlertCircle, IconAlertError, IconAlertSuccess } from 'fsd/shared/icons';

interface CustomToastProps {
  type: 'success' | 'info' | 'error' | 'warn';
  message: ToastContent<unknown>;
  options?: Partial<ToastOptions> | undefined;
}

export const CustomToast: React.FC<CustomToastProps> = ({ type, message, options }) => {
  const toastType = {
    success: toast.success,
    info: toast.info,
    error: toast.error,
    warn: toast.warn
  }[type];

  return (
    <div>
      {toastType(message, {
        ...options,
        icon:
          type === 'success' ? (
            <IconAlertSuccess />
          ) : type === 'info' ? (
            <IconAlertCircle />
          ) : type === 'error' || type === 'warn' ? (
            <IconAlertError color="white" />
          ) : null,
        closeButton: false
      })}
    </div>
  );
};

export default CustomToast;
