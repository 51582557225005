import { useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetV1UserQuery, usePostV1UserOrganisationAddMemberMutation } from 'api/api';
import { getUserOrganisationId } from 'fsd/entities/user';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { Input } from 'fsd/shared/ui/Input';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import schema from '../../model/schema';

import * as S from './styles';

const Form = () => {
  const { data: user } = useGetV1UserQuery();
  const isMobile = useIsMobile();

  const [addMember, { isLoading }] = usePostV1UserOrganisationAddMemberMutation();

  const organizationId = user && getUserOrganisationId(user);
  const disabled = isLoading || !organizationId;

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset
  } = useForm({
    defaultValues: {
      newMember: ''
    },
    resolver: zodResolver(schema),
    disabled
  });

  const handleFinish = useCallback<Parameters<typeof handleSubmit>[0]>(
    (values) => {
      if (!organizationId) return;
      addMember({
        body: {
          OrganisationID: organizationId,
          newMember: values.newMember
        }
      })
        .unwrap()
        .then(() => {
          CustomToast({
            type: 'success',
            message: (
              <ToastMessage title={`Пользователь ${values.newMember} добавлен в организацию`} />
            ),
            options: {
              autoClose: 2500
            }
          });
          reset();
        })
        .catch((err) => {
          const apiMessage = (err?.data as string) ?? '';

          let message =
            'Нам не удалось добавить пользователя в организацию, свяжитесь с нашей службой поддержки';

          if (apiMessage.includes('user is not owner of company')) {
            message = 'Вы не являетесь владельцем организации';
          } else if (apiMessage.includes('member should be registered in telespace')) {
            message = 'Пользователь не зарегистрирован в Tgmarket';
          } else if (apiMessage.includes('user already in company')) {
            message =
              'Вы не можете добавить данного пользователя, так как он уже добавлен в другую команду';
          }

          CustomToast({
            type: 'error',
            message: <ToastMessage title={message} />,
            options: {
              autoClose: 2500
            }
          });
        });
    },
    [addMember, organizationId, reset]
  );

  return (
    <S.Root onFinish={handleSubmit(handleFinish)} disabled={disabled}>
      <FormItem control={control} name="newMember">
        <Input placeholder="Введите ник пользователя без @" size="middle" />
      </FormItem>
      <S.Info />
      <S.Button type="primary" block htmlType="submit" disabled={disabled || !isDirty || !isValid}>
        {isMobile ? 'Добавить в организацию' : 'Добавить пользователя в организацию'}
      </S.Button>
    </S.Root>
  );
};

export default Form;
