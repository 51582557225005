import getLinkType from 'fsd/shared/lib/getLinkType';

export type LinkType = 'tg_adv' | 'link-tg_adv' | 'external_adv';

export default () => {
  const [link, setLink] = useState('');
  const [type, setType] = useState<LinkType>('tg_adv');

  const getType = useCallback((link: string) => {
    return `${getLinkType(link)}_adv` as typeof type;
  }, []);

  const set = useCallback((link: string) => {
    setLink(link);
    setType(`${getLinkType(link)}_adv`);
  }, []);

  const reset = useCallback(() => {
    set('');
  }, [set]);

  return {
    set,
    link,
    type,
    reset,
    getType
  };
};
