import { CSSProperties, FC, PropsWithChildren } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { IconInfoCircle } from 'fsd/shared/icons';

import { Icon } from '../Icon';

type Props = {
  width?: number;
  color?: string;
  placement?: TooltipProps['placement'];
  className?: string;
  style?: CSSProperties;
};

const Info: FC<PropsWithChildren<Props>> = ({
  width = 10,
  color,
  placement = 'top',
  children,
  className,
  style
}) => {
  return (
    <Tooltip
      placement={placement}
      title={children}
      getTooltipContainer={(elem) => elem}
      className={className}
      style={style}
    >
      <Icon width={width} color={color}>
        <IconInfoCircle />
      </Icon>
    </Tooltip>
  );
};

export default Info;
