import { FC } from 'react';
import useExportExcel from 'fsd/features/exportContainer/lib/useExportExcel';

import ExportButton from '../../ExportButton';
import { BaseProps } from '../types';

type Props = BaseProps;

const ExcelAction: FC<Props> = ({ fields, containerId }) => {
  const [exportExcel, { isLoading }] = useExportExcel();

  const handleClick = useCallback(() => {
    exportExcel({ fields, containerId });
  }, [containerId, exportExcel, fields]);

  return <ExportButton onClick={handleClick} loading={isLoading} />;
};

export default ExcelAction;
