import Tag from 'fsd/shared/ui/Tag';
import styled from 'styled-components';

export const Root = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InfoWrapper = styled.div`
  margin-right: auto;
  max-width: 50%;
`;

export const TagItem = styled(Tag)`
  flex: 1;
  min-width: min-content;
`;

export const TagList = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const MainTagList = styled(TagList)``;

export const MainButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const FieldWrapper = styled.div``;

export const BaseTagList = styled(TagList)``;

export const FormatWrapper = styled.div``;

export const Footer = styled.div``;
