import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { createGlobalStyle } from 'styled-components';

import { ThemeType } from './theme';

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  :root {
    --page-background-color: ${({ theme }) => theme.alpineGoat};
    --page-container-padding-x: 24px;
    --page-container-padding-y: 40px;
    --white: #fff;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  #root {
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .compact-menu {
    .ant-popover-inner-content {
      border-radius: 0;
      padding: 0;
      background: none;
    }
    .ant-popover-inner, .ant-popover-content {
      background: none;
      border-radius: 0px;
      box-shadow: none;
    }
  }

  .ant-modal {
    color: inherit;
    pointer-events: auto!important;
    @media ${MEDIA_DEVICE.mobileL.to} {
      margin-bottom: 0!important;
    }
  }

  .ant-form-item-explain-error { 
    color: ${theme.hotspot};
    font-size: 12px;
  }

  body {
    margin: 0;
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.blackRock};
    font-family: "Inter", sans-serif;
    background: var(--page-background-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  svg:not(:root){
    overflow: visible;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* добавляем кастомный цвет фона скролла */
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /* добавляем кастомный цвет для скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: #BCBEBF;
    border-radius: 4px;
  }

  /* переопределяем размер всплывающего окна ant-design */
  .ant-popover-inner-content {
    width: 100%;
  }
  
`;

export default GlobalStyle;
