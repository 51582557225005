import styled from 'styled-components';

export const Root = styled.div<{ $height: number; $width: number }>`
  flex: none;
  .bar {
    width: ${({ $width }) => $width}px;
    height: ${({ $height }) => $height}px;
    background: ${({ theme }) => theme.white};
    margin-right: 1px;
    display: inline-block;
    transform-origin: bottom center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    animation: loader 1.2s linear infinite;
  }
  .bar1 {
    animation-delay: 0.1s;
  }
  .bar2 {
    animation-delay: 0.2s;
  }
  .bar3 {
    animation-delay: 0.3s;
  }
  .bar4 {
    animation-delay: 0.4s;
  }
  .bar5 {
    animation-delay: 0.5s;
  }
  .bar6 {
    animation-delay: 0.6s;
  }
  .bar7 {
    animation-delay: 0.7s;
  }
  .bar8 {
    animation-delay: 0.8s;
  }

  @keyframes loader {
    0% {
      transform: scaleY(0.1);
    }
    50% {
      transform: scaleY(1);
      background: ${({ theme }) => theme.indivioletSunset};
    }
    100% {
      transform: scaleY(0.1);
      background: transparent;
    }
  }
`;
