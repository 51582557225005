import { ComponentProps, FC, MouseEventHandler, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { changeCart } from 'fsd/entities/cart';
import { IconAdd } from 'fsd/shared/icons';
import { Button as SharedButton } from 'fsd/shared/ui/Buttons';

type Props = {
  containerId?: string;
  buttonProps?: ComponentProps<typeof SharedButton>;
  mini?: boolean;
};

const Button: FC<PropsWithChildren<Props>> = ({ children, mini, containerId, buttonProps }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = useCallback<MouseEventHandler<HTMLLinkElement>>(
    (e) => {
      e.preventDefault();
      if (containerId) {
        dispatch(changeCart(containerId));
        navigate('/');
      }
    },
    [containerId, dispatch, navigate]
  );

  return (
    <SharedButton
      type="primary"
      startIcon={<IconAdd />}
      {...buttonProps}
      href="/"
      square={mini}
      onClick={handleClick}
      disabled={!containerId}
    >
      {!mini && (children || 'Добавить канал')}
    </SharedButton>
  );
};

export default Button;
