import { Form } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Button } from 'fsd/shared/ui/Buttons';
import { InfoBlock } from 'fsd/shared/ui/InfoBlock';
import styled from 'styled-components';

export const Root: typeof Form = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Footer = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 8px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 16px;
  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 8px;
  }
`;

export const Submit: typeof Button = styled(Button).attrs({
  type: 'primary',
  size: 'large',
  htmlType: 'submit'
})`
  flex: 1;
`;

export const Later: typeof Button = styled(Button).attrs({
  type: 'outlined',
  size: 'large',
  htmlType: 'button'
})`
  flex: 1;
`;

export const Info = styled(InfoBlock).attrs({ variant: 'outlined-primary', size: 'm' })``;
