import { FC, PropsWithChildren } from 'react';

import { SideBarItem } from '../../types';

import * as S from './styles';

type Props = SideBarItem;

const Item: FC<PropsWithChildren<Props>> = ({ label, target, icon, to, className }) => {
  return (
    <S.Link to={to} target={target} className={className}>
      {icon && <S.Icon color="currentColor">{icon}</S.Icon>}
      <S.Text>{label}</S.Text>
    </S.Link>
  );
};

export default memo(Item);
