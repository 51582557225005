import { Switch as SwitchAndt, SwitchProps } from 'antd';

const Switch = ({ checked, ...restProps }: SwitchProps) => {
  return (
    <SwitchAndt
      style={{ backgroundColor: checked ? theme.persianBlue : undefined }}
      checked={checked}
      {...restProps}
    />
  );
};

export default Switch;
