import { RechargeTarget } from '../types';

import useCreateInvoice, { CreateInvoiceArg } from './useCreateInvoice';
import useCreateSBPInvoice from './useCreateSBPInvoice';

type Params = {
  target: RechargeTarget;
  amount: string;
  organisation: CreateInvoiceArg['organisation'];
};

export default () => {
  const [createInvoice, invoiceData] = useCreateInvoice();
  const [createSBPInvoice, SBPData] = useCreateSBPInvoice();
  const create = useCallback(
    ({ target, amount, organisation }: Params) => {
      if (target === 'invoice') {
        return createInvoice({ amount, organisation });
      }
      return createSBPInvoice({ amount, organisationId: Number(organisation.organisationID) });
    },
    [createInvoice, createSBPInvoice]
  );
  return [create, { invoiceData, SBPData }] as const;
};
