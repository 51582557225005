import { FC } from 'react';
import { HistoryStatistic2 } from 'api/api';
import { isDev } from 'fsd/shared/lib/featureFlags';

import EmtpyData from '../EmtpyData';

import ErrValue from './ErrValue';

import * as S from './Err.styles';

type TProps = {
  channelId: string;
  errMetrics: HistoryStatistic2 | undefined;
  isLoading: boolean;
};

const Err: FC<TProps> = ({ errMetrics, channelId, isLoading }) => {
  if (isLoading || !errMetrics || !errMetrics.history.length) {
    return <EmtpyData isLoading={isLoading} timeWindow={isDev() && 'Всего'} isHideChart />;
  }

  return (
    <S.Root>
      <ErrValue channelId={channelId} />
      {isDev() && <S.Desc>Всего</S.Desc>}
    </S.Root>
  );
};

export default memo(Err);
