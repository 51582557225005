import { Checkbox } from 'antd5';
import { MergeComponentWithProps } from 'fsd/shared/types/MergeComponentWithProps';
import styled, { css } from 'styled-components';

export type RootProps = {
  $isError?: boolean;
  $isSplit?: boolean;
  $labelSize?: 'small';
};

const getLabelSize = (labelSize: RootProps['$labelSize']) => {
  switch (labelSize) {
    case 'small':
      return css`
        font-size: 12px;
        font-weight: 400;
      `;
    default:
      return css`
        font-size: 14px;
        font-weight: 600;
      `;
  }
};

export const Root: MergeComponentWithProps<typeof Checkbox, RootProps> = styled(Checkbox)<{
  $isError?: boolean;
  $isSplit?: boolean;
  $labelSize?: RootProps['$labelSize'];
}>`
  display: flex !important;
  align-items: flex-start !important;
  line-height: 16px;
  ${({ $isSplit }) =>
    $isSplit &&
    css`
      pointer-events: none;
    `}
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 133.33%;
    color: ${({ theme }) => theme.gunPowder};
    padding-right: 0;
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        color: ${({ theme }) => theme.persianBlue};
      }
    }
  }
  .ant-checkbox {
    display: block;
    align-self: flex-start;
    margin-top: -1px;
    ${({ $isSplit }) =>
      $isSplit &&
      css`
        pointer-events: auto;
      `}
    &::after {
      display: none;
    }
    & + span {
      ${({ $labelSize }) => getLabelSize($labelSize)}
      color: ${({ theme }) => theme.blackRock};
      line-height: 142%;
      padding-left: 8px;
      small {
        font-size: inherit;
        font-weight: inherit;
        color: ${({ theme }) => theme.bayFog};
      }
    }
  }
  &:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.persianBlue};
  }
  .ant-checkbox-inner {
    border-radius: 2px;
    outline: none;
    ${({ $isError, theme }) =>
      $isError &&
      css`
        border-color: ${theme.hotspot}!important;
      `}
  }
`;
