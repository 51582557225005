import { FC, MouseEventHandler, PropsWithChildren } from 'react';

import Close from '../../Close';

import * as S from './styles';

type Props = {
  onClose: MouseEventHandler<HTMLButtonElement>;
};

const Header: FC<PropsWithChildren<Props>> = ({ children, onClose }) => {
  return (
    <S.Root>
      <S.Title>{children}</S.Title>
      <Close onClick={onClose} />
    </S.Root>
  );
};

export default Header;
