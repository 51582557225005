import { FC, RefObject } from 'react';
import { Direction, Id } from 'api/api';
import { Chart } from 'chart.js';

import { useScrollHeaderSticky } from '../../hooks/useScrollHeaderSticky';
import { useTableScroll } from '../../hooks/useTableScroll';
import { TableBody } from '../TableBody/TableBody';
import { TableHeader } from '../TableHeader/TableHeader';

import * as LS from './styles';

type Props = {
  values: any[];
  startDay?: string;
  channelId: Id;

  hasMoreUp: boolean;
  hasMoreDown: boolean;
  isLoading: boolean;
  isRepost: boolean;
  loadMore: (direction: Direction) => void;
  setIsLoading: (value: boolean) => void;
  chartRef: RefObject<Chart>;
};

const Table: FC<Props> = ({
  values,
  channelId,
  hasMoreUp,
  hasMoreDown,
  isLoading,
  isRepost,
  loadMore,
  chartRef
}) => {
  const { parentRef, virtualizer, sortedValues, virtualItems, setIsHoverTable, indexColorDate } =
    useTableScroll(
      values,
      isRepost,
      chartRef,
      isLoading,
      hasMoreUp,
      hasMoreDown,
      channelId,
      loadMore
    );
  const tableHeight = virtualizer.getTotalSize();

  const { tableRef } = useScrollHeaderSticky(parentRef, tableHeight, virtualItems);

  return (
    <LS.TableContainer
      ref={parentRef}
      onMouseEnter={() => setIsHoverTable(true)}
      onMouseLeave={() => setIsHoverTable(false)}
    >
      <div style={{ height: `${tableHeight}px` /* , background: "red" */ }}>
        <LS.Table className="virtual-table" ref={tableRef}>
          <TableHeader />
          <TableBody
            values={sortedValues}
            virtualItems={virtualItems}
            indexColorDate={indexColorDate}
            isLoading={isLoading}
          />
        </LS.Table>
      </div>
    </LS.TableContainer>
  );
};

export default Table;
