import { Link, NavLink } from 'react-router-dom';
import Tag from 'fsd/shared/ui/Tag';
import styled from 'styled-components';

export const Wrapper = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  }
`;

export const Root = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 21px;
  padding-bottom: 16px;
`;

export const InfoWrapper = styled(NavLink)`
  grid-area: info;
  &:hover {
    color: inherit;
  }
`;

export const ChartWrapper = styled.div`
  grid-area: chart;
`;

export const Additional = styled.div`
  grid-area: additional;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-end;
`;

export const Main = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 50% 1fr auto;
  grid-template-areas: 'info chart additional';
  position: relative;
`;

export const MetricsTag = styled(Tag)`
  flex: 1;
  min-width: min-content;
`;

export const Metrics = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const PriceWrapper = styled.div``;
