import { FC, MouseEventHandler, ReactNode } from 'react';
import { CheckboxProps, CollapseProps } from 'antd5';
import { CheckboxChangeEvent } from 'antd5/lib/checkbox';
import { IconArrowDown } from 'fsd/shared/icons';

import { Icon } from '../../Icon';
import Checkbox from '../Checkbox';

import * as S from './styles';

type Value = { label: ReactNode } & CheckboxProps;

type GroupItem = {
  key: string;
  label: ReactNode;
  values: Value[];
};

type Props = {
  items: GroupItem[];
  value: Array<CheckboxProps['value']>;
  onChange: (value: Props['value']) => void;
};

const Group: FC<Props> = ({ items, value, onChange }) => {
  const handleAllChange = useCallback(
    (item: GroupItem) => {
      const values = item.values.map((v) => v.value);
      return (e: CheckboxChangeEvent) => {
        let newValue = [...value];
        newValue = newValue.filter((v) => !values.includes(v));
        if (e.target.checked) {
          newValue.push(...values);
        }
        onChange?.(Array.from(new Set(newValue)));
      };
    },
    [onChange, value]
  );

  const handleGroupChange = useCallback(
    (item: GroupItem) => {
      const values = item.values.map((v) => v.value);
      return (checkedValue: unknown[]) => {
        let newValue = [...value];
        newValue = newValue.filter((v) => !values.includes(v));
        onChange?.([...checkedValue, ...newValue]);
      };
    },
    [onChange, value]
  );

  const isAllChecked = useCallback(
    (item: GroupItem) => {
      return item.values.map((v) => v.value).every((val) => value.includes(val));
    },
    [value]
  );

  const handleLabelClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation();
  }, []);

  const groupItems = useMemo<CollapseProps['items']>(() => {
    return items.map((item) => ({
      key: item.key,
      label: (
        <Checkbox
          value={item.key}
          split
          onClick={handleLabelClick}
          checked={isAllChecked(item)}
          onChange={handleAllChange(item)}
        >
          {item.label}
        </Checkbox>
      ),
      children: (
        <S.Group value={value} onChange={handleGroupChange(item)}>
          {item.values.map(({ label, ...itemValue }) => (
            <S.Item value={itemValue.value} key={itemValue.value}>
              {label}
            </S.Item>
          ))}
        </S.Group>
      )
    }));
  }, [handleAllChange, handleGroupChange, handleLabelClick, isAllChecked, items, value]);

  return (
    <S.Root
      // eslint-disable-next-line react/no-unstable-nested-components
      expandIcon={() => (
        <Icon color={theme.blackRock} width={18}>
          <IconArrowDown />
        </Icon>
      )}
      ghost
      items={groupItems}
      expandIconPosition="end"
    />
  );
};

export default memo(Group);
