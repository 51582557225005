import { createContext, FC, PropsWithChildren } from 'react';

const useContextData = () => {
  const [search, setSearch] = useState('');
  return {
    search,
    setSearch
  };
};

export const Context = createContext<ReturnType<typeof useContextData> | null>(null);

export const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const contextData = useContextData();
  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};
