import { ColumnType } from 'antd5/lib/table';

import SortIcon from '../ui/SortIcon';

const createColumn = <T extends ColumnType<any>>(column: T): T => {
  return {
    ...column,
    sortIcon: (props) => <SortIcon {...props} />,
    showSorterTooltip: false
  };
};

export default createColumn;
