import styled, { css } from 'styled-components';

const RootCss = css`
  background: #fff;
  min-height: 42px;
  box-sizing: border-box;
`;

export const Root = styled.tr`
  ${RootCss}
`;

export const RootVirtual = styled.div`
  display: flex;
`;

export const RootVirtualContent = styled.div`
  display: flex;
  ${RootCss}
`;
