import React, { RefObject } from 'react';
import { Chart } from 'chart.js';
import { IconUsers, IconViews } from 'fsd/shared/icons';

import { ChartJS } from 'components/ChartWithZoom';

import * as S from './styles';

type PropsChartViewsMobile = {
  chartRef: RefObject<Chart>;
  handleChartClick?: (dataPoint: { x: number; y: number } | undefined) => void;
  handleZoomChange: (from: number, to: number, chart: any) => Promise<void>;
  graphData: (
    | {
        type: string;
        label: string;
        borderColor: string;
        data: { x: number; y: number }[];
      }
    | {
        type: string;
        label: string;
        data: { x: number; y: number }[];
        borderColor?: undefined;
      }
  )[];
  isShowAll: boolean;
  isShowViews: boolean;
  toggleLine: (type: string) => void;
};

const GraphBlockReaches = (props: PropsChartViewsMobile) => {
  return (
    <S.Wrapper>
      <S.Title>
        <S.InteractiveChartFilter
          isDisabledToggle={props.isShowAll && !props.isShowViews}
          onClick={() => props.toggleLine('all')}
          isActive={props.isShowAll}
        >
          <S.BigIconTableParamsBlue>
            <IconUsers />
          </S.BigIconTableParamsBlue>
          <S.ChartFilterLabel>Подписчики</S.ChartFilterLabel>
        </S.InteractiveChartFilter>
        <S.InteractiveChartFilter
          isDisabledToggle={!props.isShowAll && props.isShowViews}
          onClick={() => props.toggleLine('views')}
          isActive={props.isShowViews}
        >
          <S.BigIconTableParams>
            <IconViews />
          </S.BigIconTableParams>
          <S.ChartFilterLabel>Средний охват</S.ChartFilterLabel>
        </S.InteractiveChartFilter>
      </S.Title>
      <div>
        <ChartJS
          chartref={props.chartRef}
          data={props.graphData}
          onZoomChange={props.handleZoomChange}
          isDashed
        />
      </div>
    </S.Wrapper>
  );
};

export default GraphBlockReaches;
