import styled from 'styled-components';

import { BigIcon } from 'pages/channel/components/styles';

export const GraphWrapper = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.white};
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px -4px 64px,
    rgba(179, 175, 214, 0.07) 0px 100px 80px;
  border-radius: 14px;
  margin-bottom: 13px;
`;

export const Wrapper = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.white};
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px -4px 64px,
    rgba(179, 175, 214, 0.07) 0px 100px 80px;
  border-radius: 14px;
  margin-bottom: 13px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: 9px;
`;

export const InteractiveChartFilter = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const BigIconTableParams = styled(BigIcon)`
  color: ${({ theme }) => theme.blueRibbon};
  width: 12px;
  height: 12px;
`;

export const BigIconTableParamsBlue = styled(BigIconTableParams)`
  color: ${({ theme }) => theme.persianBlue};
`;

export const ChartFilterLabel = styled.span`
  margin-left: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
`;
