import { FC } from 'react';
import { GoToCatalogWithCart } from 'fsd/features/goToCatalogWithCart';
import { IconSpaceman } from 'fsd/shared/icons';

import * as S from './styles';

type Props = {
  containerId: string;
};

const EmptyList: FC<Props> = ({ containerId }) => {
  return (
    <S.Root>
      <S.Img>
        <IconSpaceman color={theme.persianViolet} />
      </S.Img>
      <S.Title>Вы пока не добавили ни один канал</S.Title>
      <S.Text>
        После добавления <br />
        они будут отображаться здесь
      </S.Text>

      <GoToCatalogWithCart containerId={containerId}>Перейти в каталог</GoToCatalogWithCart>
    </S.Root>
  );
};

export default memo(EmptyList);
