import { FC, HTMLAttributes } from 'react';
import { IconClose } from 'fsd/shared/icons';

import { Icon } from '../../Icon';

import * as S from './styles';

type Props = HTMLAttributes<HTMLButtonElement>;

const Close: FC<Props> = (props) => {
  return (
    <S.Root {...props}>
      <Icon width={16} color="currentColor">
        <IconClose />
      </Icon>
    </S.Root>
  );
};

export default memo(Close);
