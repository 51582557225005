import { FC } from 'react';
import { PurchaseRead } from 'api/api';
import dayjs, { Dayjs } from 'dayjs';
import { useUpdatePurchaseDateAndTime } from 'fsd/entities/purchase';
import { DatePickerInline } from 'fsd/shared/ui/Date';
import { BetaModal } from 'fsd/shared/ui/Modal';

type Props = {
  purchase: PurchaseRead;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
};

const DateModal: FC<Props> = ({ purchase, open, onOpenChange, onSuccess }) => {
  const [updateDateAndTime, { isLoading }] = useUpdatePurchaseDateAndTime();
  const handleCancel = useCallback(() => {
    onOpenChange(!open);
  }, [onOpenChange, open]);

  const handleOk = useCallback(
    (date?: Dayjs) => {
      if (date) {
        updateDateAndTime(purchase, date.toDate())
          .unwrap()
          .then(() => {
            onOpenChange(false);
            onSuccess?.();
          });
      }
    },
    [onOpenChange, onSuccess, purchase, updateDateAndTime]
  );

  return (
    <BetaModal
      open={open}
      onCancel={handleCancel}
      width="min-content"
      destroyOnClose
      title="Установите дату и время размещения"
    >
      <DatePickerInline
        showTime
        okDisabled={isLoading}
        format="YYYY-MM-DD-HH:mm"
        minDate={dayjs()}
        minTime={dayjs()}
        minuteStep={5}
        onOk={handleOk}
        okButton="Установить дату и отправить"
      />
    </BetaModal>
  );
};

export default memo(DateModal);
