import getPriceDataFromLocalFile from './getPriceDataFromLocalFile';

type Result = {
  price?: number;
  name: string;
};

// eslint-disable-next-line consistent-return
export default (channelLink: string): Result | undefined => {
  const priceData = getPriceDataFromLocalFile(channelLink);

  if (priceData) {
    return {
      price: priceData?.rub_price,
      name: priceData?.format ?? ''
    };
  }
};
