import { ComponentType } from 'react';
import { CartProvider } from 'fsd/entities/cart';

export const withCart = (Component: ComponentType<{}>) => () => {
  return (
    <CartProvider>
      <Component />
    </CartProvider>
  );
};
