import { FC, memo, useRef } from 'react';
import { DateRange, TimeWindow } from 'api/api';
import { Chart } from 'chart.js';
import { cutNum } from 'fsd/shared/lib/helpers/tools';
import { useStatistic } from 'fsd/shared/lib/hooks/useStatistic';
import { CompactChart } from 'fsd/shared/ui/CompactChart';
import { DATE_RANGE } from 'types/channels/types';

import { TableDataContext } from '../../../Table/lib/tableDataContext';
import EmtpyData from '../EmtpyData';

import * as S from '../../styles';

type Props = {
  channelId: string;
  timeWindow: TimeWindow;
  participantsDiff?: number;
  isLoading: boolean;
  channelIds: string[];
  dateRange: DateRange;
  historicalMetricsLoaded: boolean;
};

const ParticipantsDiff: FC<Props> = ({
  channelId,
  channelIds,
  dateRange,
  historicalMetricsLoaded,
  timeWindow,
  participantsDiff,
  isLoading
}) => {
  const tableDataContext = useContext(TableDataContext);

  const participants = useMemo(
    () =>
      tableDataContext?.channelMetrics?.data?.channels?.find(
        (item) => item.channel_id === channelId
      ),
    [channelId, tableDataContext?.channelMetrics?.data?.channels]
  );

  const { participantsDiffPerAll } = useStatistic(participants, 'PersianLineWithShadow');

  const chartRef = useRef<Chart>(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const min = useMemo(
    () =>
      participantsDiffPerAll[0]?.data?.reduce((min, current) => {
        return current.y < min.y ? current : min;
      }, participantsDiffPerAll[0]?.data[0]),
    [participantsDiffPerAll]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const max = useMemo(
    () =>
      participantsDiffPerAll[0]?.data?.reduce((max, current) => {
        return current.y > max.y ? current : max;
      }, participantsDiffPerAll[0]?.data[0]),
    [participantsDiffPerAll]
  );

  if (
    isLoading ||
    !participants ||
    !participants.history ||
    !participants.history.length ||
    participants.history.length === 1
  ) {
    return (
      <EmtpyData
        number={participantsDiff}
        isLoading={isLoading}
        timeWindow={timeWindow === 'all' ? DATE_RANGE.month.name : DATE_RANGE[timeWindow].name}
      />
    );
  }

  return (
    <>
      {participantsDiff ? (
        <div>
          <S.DataValueDiff status={participantsDiff > 0}>
            {participantsDiff > 0 && '+'} {cutNum(participantsDiff)}
          </S.DataValueDiff>

          <small>
            {timeWindow === 'all' ? DATE_RANGE.month.name : DATE_RANGE[timeWindow].name}
          </small>
        </div>
      ) : (
        <S.DataValue empty>—</S.DataValue>
      )}

      {participants.history.length > 1 && (
        <S.ChartWrapper>
          <CompactChart
            data={participantsDiffPerAll}
            chartref={chartRef}
            min={min.y * 1.6}
            max={max.y * 1.6}
            animation={false}
          />
        </S.ChartWrapper>
      )}
    </>
  );
};

export default memo(ParticipantsDiff);
