import { useCallback, useSyncExternalStore } from 'react';

import { MediaQueryString } from './MediaQuery';

export default (query: MediaQueryString) => {
  if (typeof window.matchMedia === 'undefined') {
    throw Error('matchMedia not supported by browser!');
  }

  const subscribe = useCallback(
    (callback: () => void) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener('change', callback);
      return () => {
        matchMedia.removeEventListener('change', callback);
      };
    },
    [query]
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };

  const getServerSnapshot = () => {
    throw Error('useMediaQuery is a client-only hook');
  };

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
};
