import { FC } from 'react';
import { IconClose } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import { SideBarProps } from '../../../types';
import Item from '../../Item';

import * as S from './styles';

type Props = SideBarProps & { onClose?: () => void };

const Menu: FC<Props> = ({ items, footer, onClose, logo }) => {
  return (
    <S.Root>
      <S.Header>
        {logo}
        <S.CloseWrapper onClick={onClose}>
          <Icon width={18} color={theme.blackRock}>
            <IconClose />
          </Icon>
        </S.CloseWrapper>
      </S.Header>
      <S.Body>
        {items?.length && (
          <S.Nav>
            {items.map(({ key, ...item }) => (
              <Item key={key} {...item} />
            ))}
          </S.Nav>
        )}
      </S.Body>
      {footer?.items.length && (
        <S.Footer>
          <S.Nav>
            {footer.items.map(({ key, ...item }) => (
              <Item key={key} {...item} />
            ))}
          </S.Nav>
        </S.Footer>
      )}
    </S.Root>
  );
};

export default memo(Menu);
