import { FC, PropsWithChildren } from 'react';
import { ModalProps } from 'antd';
import { BalanceManageModal } from 'fsd/entities/balance';
import { URLs } from 'fsd/shared/config/URLs';

type Props = {
  open: boolean;
} & ModalProps;

const Modal: FC<PropsWithChildren<Props>> = ({ open, children, ...restProps }) => {
  return (
    <BalanceManageModal
      {...restProps}
      title="Вывод средств"
      description={
        <>
          Для вывода средств укажите сумму и отправьте заявку. В течение часа менеджер свяжется с
          вами через{' '}
          <a href={URLs.TELESPACE_ASSISTANT_BOT} target="_blank" rel="noreferrer">
            Tgmarket Assistant
          </a>
        </>
      }
      info="Средства появятся у вас на счету после согласования вывода с менеджером Tgmarket"
      open={open}
    >
      {children}
    </BalanceManageModal>
  );
};

export default Modal;
