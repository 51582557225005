import { ComponentProps, FC } from 'react';
import { Select } from 'antd';
import {
  api,
  ChannelWithExchangeInfo,
  useGetV1CountriesQuery,
  usePatchV1UserChannelMutation
} from 'api/api';
import getCountryFlag from 'country-flag-icons/unicode';
import { IconArrowDown } from 'fsd/shared/icons';
import getCountryData from 'fsd/shared/lib/getCountryData';

import * as S from '../styles';

interface IProps {
  channel: ChannelWithExchangeInfo;
}

const CountrySelect: FC<IProps> = ({ channel }) => {
  const dispatch = useAppDispatch();
  const { data, isFetching: isDataFetching } = useGetV1CountriesQuery();
  const [updateChannel, { isLoading: isUpdateFetching }] = usePatchV1UserChannelMutation();

  const options: ComponentProps<typeof Select>['options'] = useMemo(() => {
    return (
      data?.map((country) => {
        const countryData = getCountryData(country.code_name);
        return {
          value: country.id,
          label: `${getCountryFlag(country.code_name)} ${countryData.name}`
        };
      }) || []
    );
  }, [data]);

  const handleChange = useCallback(
    (value: number) => {
      const { undo } = dispatch(
        api.util.updateQueryData('getV1UserChannels', undefined, (data) => {
          const dataIndex = data.findIndex((d) => d.info.id === channel.info.id);
          data[dataIndex].info.country = value;
        })
      );
      updateChannel({
        body: {
          channel_id: channel.info.id,
          properties: {
            country_id: value
          }
        }
      })
        .unwrap()
        .catch(() => undo());
    },
    [updateChannel, channel.info.id]
  );

  return (
    <S.RowExpandedBlock>
      <S.ExpandedBlockHeader>
        <span>Страна</span>
      </S.ExpandedBlockHeader>
      <div>
        <Select
          suffixIcon={<IconArrowDown />}
          placeholder="Не выбрано"
          value={channel.info.country}
          loading={isDataFetching}
          disabled={isUpdateFetching}
          options={options}
          onChange={handleChange}
        />
      </div>
    </S.RowExpandedBlock>
  );
};

export default CountrySelect;
