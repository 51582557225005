import { useCallback, useState } from 'react';
import { useGetV2ChannelsByMetricTypeQuery } from 'api/api';
import { useAppDispatch } from 'fsd/app/redux/hooks';
import { fetchAPI, mergeAndSortArray } from 'fsd/shared/lib/helpers/tools';
import { toISOStringWithMSKTime } from 'fsd/shared/lib/helpers/tools/dates';
import { useChartDateControl } from 'fsd/shared/lib/hooks/useChartDateControl';
import { getGraphDataByParticipants, useStatistic } from 'fsd/shared/lib/hooks/useStatistic';

import { Props } from '../index';

import { useDetailedParticipants } from './useDetailedParticipants';

export const useParticipantsBlockLogic = ({
  channelId,
  now,
  channel,
  chartRef,
  isLoadingChannel
}: Props) => {
  const { isAuth } = useAuth();
  const dispatch = useAppDispatch();

  const {
    participantsToday,
    participantsYesterday,
    participantsWeek,
    participantsMonth,
    isLoading: isLoadingParticipantsDetailed
  } = useDetailedParticipants(channel, now);

  const { data: channelMetrics, isLoading: isLoadingParticipants } =
    useGetV2ChannelsByMetricTypeQuery(
      {
        ids: [channelId],
        metricType: 'participants',
        dateRange: {
          to: now
        },
        historicalMetricsLoaded: !!channel?.historical_metrics_loaded,
        timeZone: 'Europe/Moscow'
      },
      { refetchOnReconnect: true, skip: isLoadingChannel || !isAuth }
    );

  const [isEmptyDataOnZoomChange, setIsEmptyDataOnZoomChange] = useState(false);

  const { graphData, participantsStatistic, participantsAll } = useStatistic(
    channelMetrics?.channels?.[0]
  );

  const handleZoomChange = useCallback(
    async (from: number, to: number, chart: any) => {
      const day = 24 * 60 * 60 * 1000;
      const diffence = to - from;

      if (diffence < day) {
        const newFrom = toISOStringWithMSKTime(new Date(from));
        const newTo =
          toISOStringWithMSKTime(new Date(to)) > now ? now : toISOStringWithMSKTime(new Date(to));

        const result = await fetchAPI.get(
          `/v2/channels/participants?ids=${channelId}&from=${newFrom}&to=${newTo}&historical_metrics_loaded=${!!channel?.historical_metrics_loaded}&time_zone=Europe%2FMoscow`
        );
        if (!result) {
          return;
        }

        const data = getGraphDataByParticipants(result?.channels?.[0]);

        const mergedData = mergeAndSortArray(graphData[0].data, data.graphData[0].data, 'x');

        chart.data.datasets[0].data = mergedData;
        chart.stop();
        chart.update('none');

        const IsEmpty = chart.data.datasets[0].data.findIndex((item: any) => {
          return item.x > from && item.x < to;
        });

        setIsEmptyDataOnZoomChange(IsEmpty === -1);
      } else {
        setIsEmptyDataOnZoomChange(false);
      }
    },
    [graphData, channelId, channel, setIsEmptyDataOnZoomChange]
  );

  const { handleChartClick, handleDateClick } = useChartDateControl(
    chartRef,
    handleZoomChange,
    now,
    'participants',
    dispatch,
    channelId
  );

  return {
    isLoadingParticipants: isLoadingParticipants || isLoadingParticipantsDetailed,
    isLoadingChannel,
    participantsStatistic,
    participantsAll,
    participantsToday,
    participantsYesterday,
    participantsWeek,
    participantsMonth,
    handleDateClick,
    chartRef,
    graphData,
    handleZoomChange,
    handleChartClick,
    isEmptyDataOnZoomChange
  };
};
