import { FC, memo, MouseEventHandler } from 'react';

import Form from './Form';

import * as S from '../../../../Row/styles';

const Price: FC = () => {
  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => e.stopPropagation(),
    []
  );

  return (
    <S.PriceCell className="usiteful__catalog__price-cell" onClick={handleClick}>
      <Form />
    </S.PriceCell>
  );
};

export default memo(Price);
