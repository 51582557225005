import styled from 'styled-components';

type IconProps = {
  width?: number;
  color: string;
};

export const FakeIcon = styled.div<IconProps>`
  background-color: ${(props) => props.color};
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : '24px')};
  height: ${(props) => (props.width ? `${props.width}px` : '24px')};
  min-width: ${(props) => (props.width ? `${props.width}px` : '24px')};
  min-height: ${(props) => (props.width ? `${props.width}px` : '24px')};
  color: ${({ theme }) => theme.white};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;
