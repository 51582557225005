import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextWrapper = styled.div`
  margin-top: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.blackRock};
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
`;

export const LinkText = styled.a`
  color: ${({ theme }) => theme.blueRibbon};

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  border-bottom: 1px solid ${({ theme }) => theme.blueRibbon};
  cursor: pointer;
`;
