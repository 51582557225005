import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal, { ModalFullPage } from 'fsd/shared/ui/Modal';

import { useChannelPageLogic } from '../channel/ChannelPage';
import { MobileViewsBlock } from '../channelMobile/components/DataBlocks';
import { Header } from '../channelMobile/components/Header';

import * as S from '../channelMobile/styles';

const ViewsMobile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const channelId = String(params.id);
  const { channel, isLoadingChannel, nowTimestamp, chartRef } = useChannelPageLogic(channelId);

  return (
    <ModalFullPage
      open
      mask={false}
      onCancel={() => navigate(-1)}
      closable
      closeIcon={<S.CloseModalIcon />}
      className="channel-modal hide-overflow"
    >
      <Header channel={channel?.info} channelId={channelId} navigate={navigate} />

      <MobileViewsBlock
        channel={channel?.info}
        isLoadingChannel={isLoadingChannel}
        channelId={channelId}
        now={nowTimestamp}
        compact
      />
    </ModalFullPage>
  );
};

export default memo(ViewsMobile);
