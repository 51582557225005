import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { ReactComponent as ChannelsIcon } from 'fsd/shared/icons/channels.svg';
import { Input } from 'fsd/shared/ui/Input';
import styled from 'styled-components';

import { theme } from 'theme/theme';

export const SearchInput = styled(Input)`
  background-color: ${({ theme }) => theme.alpineGoat};

  .ant-input {
    background-color: ${({ theme }) => theme.alpineGoat};
  }

  .ant-input-prefix {
    margin-right: 9px;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    background-color: ${({ theme }) => theme.white};

    .ant-input {
      background-color: ${({ theme }) => theme.white};
    }
  }
`;

export const InputWrapper = styled.div`
  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 100%;
  }
`;

export const SearchIcon = styled(ChannelsIcon)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.bayFog};
`;

export const InputBoundariesWrapper = styled.div`
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 8px;
  padding: 16px;
  box-shadow:
    0px -4px 64px rgb(0 0 0 / 7%),
    0px 100px 80px rgb(179 175 214 / 7%);
`;

export const InlineSearchResultWrapper = styled.div`
  margin-top: 12px;
  border-radius: 20px;
  background: transparent;
  box-shadow:
    0px -4px 64px rgb(0 0 0 / 7%),
    0px 100px 80px rgb(179 175 214 / 7%);
  color: ${theme.blackRock};
`;
