/* eslint-disable no-unused-vars */
import {
  api,
  GetV1ExchangePurchasesContainersByContainerIdApiResponse,
  PurchasesListContainer,
  useGetV1ExchangePurchasesContainersQuery
} from 'api/api';

const useGetContainersPurchases = (containerIds?: string[]) => {
  const dispatch = useAppDispatch();

  const get = useCallback(
    async (containerId: string) => {
      try {
        const result = await dispatch(
          api.endpoints.getV1ExchangePurchasesContainersByContainerId.initiate({
            containerId
          })
        ).unwrap();
        return {
          purchases: result,
          containerId
        };
      } catch (error) {
        return {
          purchases: [],
          containerId
        };
      }
    },
    [dispatch]
  );
  const [purchases, setPurchases] = useState<Awaited<ReturnType<typeof get>>[]>([]);

  const containerIdsDep = containerIds?.join();

  const call = useCallback(() => {
    if (!containerIds?.length) {
      setPurchases([]);
    } else {
      Promise.all(containerIds.map((id) => get(id))).then(setPurchases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerIdsDep, get]);

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerIdsDep, call]);

  return purchases;
};

type DataSource = {
  container: PurchasesListContainer;
  purchases: GetV1ExchangePurchasesContainersByContainerIdApiResponse;
}[];

export type UseListDataOptions = {
  filter?: (record: DataSource[0]) => boolean;
};

export default ({ filter = () => true }: UseListDataOptions) => {
  const containers = useGetV1ExchangePurchasesContainersQuery({
    isActive: true
  });

  const containersIds = containers.data?.map((container) => container.container_id);

  const containersPurchases = useGetContainersPurchases(containersIds);

  const dataSource = useMemo((): DataSource => {
    return (
      containers.data
        ?.map((container) => ({
          container,
          purchases:
            containersPurchases.find((data) => data.containerId === container.container_id)
              ?.purchases ?? []
        }))
        .filter(filter) ?? []
    );
  }, [containers.data, containersPurchases, filter]);

  return {
    containers,
    containersPurchases,
    dataSource
  };
};
