import { FC } from 'react';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';

import { ChannelTabs } from '../../components/ChannelTabs';

import Catalog from './components/Catalog';
import { CatalogProps } from './index';

const CatalogPage: FC<CatalogProps> = ({ catalogProps }) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Catalog catalogProps={catalogProps} />
  ) : (
    <ChannelTabs catalogProps={catalogProps} />
  );
};

export default memo(CatalogPage);
