import React, { RefObject } from 'react';
import { Chart } from 'chart.js';
import { IconUsers } from 'fsd/shared/icons';

import { ChartJS } from 'components/ChartWithZoom';

import * as S from './styles';

type PropsChartParticipiantsMobile = {
  chartRef: RefObject<Chart>;
  handleChartClick?: (dataPoint: { x: number; y: number } | undefined) => void;
  handleZoomChange: (from: number, to: number, chart: any) => Promise<void>;
  graphData: {
    type: string;
    label: string;
    borderWidth: number;
    lineTension: number;
    borderColor: string;
    data: { x: number; y: number }[];
  }[];
};

const GraphBlockParticipiants = (props: PropsChartParticipiantsMobile) => {
  return (
    <S.Wrapper>
      <S.Title>
        <S.InteractiveChartFilter>
          <S.BigIconTableParamsBlue>
            <IconUsers />
          </S.BigIconTableParamsBlue>
          <S.ChartFilterLabel>Подписчики</S.ChartFilterLabel>
        </S.InteractiveChartFilter>
      </S.Title>
      <div>
        <ChartJS
          chartref={props.chartRef}
          data={props.graphData}
          onZoomChange={props.handleZoomChange}
          onClick={props.handleChartClick}
          isDashed
        />
      </div>
    </S.Wrapper>
  );
};

export default GraphBlockParticipiants;
