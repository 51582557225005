import { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router';
import { Table as SharedTable } from 'fsd/shared/ui/Table';

import { ListContext } from '../../lib/listContext';
import useColumns, { ColumnData } from '../../lib/useColumns';

const Table = () => {
  const navigate = useNavigate();
  const listData = useContext(ListContext);
  const columns = useColumns();

  const onRow = useCallback(
    (record: ColumnData): HTMLAttributes<HTMLTableRowElement> => {
      return {
        style: { height: 68, cursor: 'pointer' },
        onClick: () => {
          navigate(`/campaigns/${record.container.container_id}`);
        }
      };
    },
    [navigate]
  );

  return (
    listData && (
      <SharedTable.Root
        scroll={{
          x: 1400
        }}
        rowKey={(record) => record.container.container_id}
        columns={columns}
        dataSource={listData.dataSource}
        loading={listData.containers.isFetching}
        pagination={false}
        onRow={onRow}
        tableLayout="auto"
      />
    )
  );
};

export default memo(Table);
