import styled from 'styled-components';

import Gradient from './assets/Gradient.png';
import Pattern from './assets/Pattern.png';

import * as Item from '../Item/styles';

export const Root = styled.div<{ $type?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;

  padding: 16px;
  max-height: 100%;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: ${({ $type }) =>
    $type === 'tg'
      ? `url(${Pattern}) repeat center,
        url(${Gradient}) center`
      : ({ theme }) => theme.alpineGoat};
  background-size: ${({ $type }) => ($type === 'tg' ? '120%, cover' : 'initial')};
  overflow-y: auto;
  scrollbar-width: none;

  gap: 10px;
  ${Item.Root} {
    max-width: 100%;
    width: ${({ $type }) => ($type === 'tg' ? '302px' : '100%')};
  }
`;
