import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { IconAccount } from 'fsd/shared/icons';
import styled from 'styled-components';

export const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

export const Paper = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  padding: 40px 60px 45px;
  max-width: 519px;
  flex-grow: 1;

  margin-top: 57px;
  position: relative;

  margin-left: -64px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-left: 0;
    margin-top: 0;
    padding: 32px 16px;
    left: 0;
  }
`;

export const Limiter = styled.div`
  max-width: 399px;
`;

export const Block = styled.div`
  margin-bottom: 32px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin-bottom: 24px;
  }
`;

export const MainTitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    display: none;
  }
`;

export const StyledAccountIcon = styled(IconAccount)`
  width: 32px;
  margin-right: 10px;
  color: ${({ theme }) => theme.persianBlue};
`;

export const MainTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.blackRock};
  margin-bottom: 0;
`;

export const InputWrapper = styled.div`
  position: relative;
`;
