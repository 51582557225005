import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { PostSendColdLeadArg } from './types';

export const channelTemporaryApi = createApi({
  reducerPath: 'channelTemporaryApi',
  tagTypes: ['ColdLead'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://temporary-api.telespace.systems/',
    prepareHeaders: (headers) => {
      headers.set('hypothesis', `INFRA-593`);
      return headers;
    }
  }),
  endpoints: (build) => ({
    sendColdLead: build.mutation<any, PostSendColdLeadArg>({
      query: (queryArg) => ({ url: `sendColdLead`, body: queryArg, method: 'POST' })
    })
  })
});

export const { useSendColdLeadMutation } = channelTemporaryApi;
