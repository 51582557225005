import { Tooltip } from 'antd';
import { IconClock, IconUsers } from 'fsd/shared/icons';

import * as S from '../../styles';
import * as LS from './styles';

export const TableHeader = () => (
  <thead className="virtual-table-sticky-header">
    <tr>
      <LS.Time>
        <S.ColumnDiv1>
          <Tooltip overlayClassName="tooltip-custom-height" title="Время события">
            <LS.IconWrap>
              <IconClock />
            </LS.IconWrap>
          </Tooltip>
        </S.ColumnDiv1>
      </LS.Time>

      <LS.Number>
        <S.ColumnDiv2>
          <Tooltip
            title={
              <>
                Прирост подписчиков
                <br /> с учётом отписок
              </>
            }
          >
            <LS.IconWrap>
              <IconUsers />
            </LS.IconWrap>
          </Tooltip>
        </S.ColumnDiv2>
      </LS.Number>

      <LS.Event>
        <S.ColumnDiv3>Событие</S.ColumnDiv3>
      </LS.Event>
      <td style={{ padding: 0 }}>
        <S.ColumnDiv4 />
      </td>
    </tr>
  </thead>
);
