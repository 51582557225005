import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PostLink = styled(Link)`
  width: 18px;
  height: 18px;
  margin-left: 17px;

  background: linear-gradient(180deg, #8655ff 0%, #7037ff 25%, #6121ff 57.81%, #4003d9 100%);
  border: none;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${MEDIA_DEVICE.mobileL.to} {
    margin: 0;
  }
`;

export const TRow = styled.div`
  background-color: ${({ theme }) => theme.alpineGoat};
  height: 32px;
`;

export const Hour = styled.td`
  padding: 0;
  width: 50px;
  max-width: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.majesticMount};

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;

    width: 40px;
    max-width: 40px;
  }
`;

export const Number = styled.td`
  padding: 0;
  width: 100px;
  max-width: 100px;
  min-width: 100px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    min-width: 70px;
    width: 70px;
    max-width: 70px;
  }
`;

export const Event = styled.td`
  padding: 0;
`;
export const Post = styled.td`
  padding: 0;
  width: 50px;
`;

export const Description = styled.span<{ isRegular?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  border-bottom: 1px solid ${({ theme }) => theme.blackRock};
  cursor: pointer;
  color: ${({ theme }) => theme.blackRock};

  ${(props) =>
    props &&
    props.isRegular &&
    `
    color: ${theme.persianBlue};
    border-bottom: none;
  `};

  @media ${MEDIA_DEVICE.mobileL.to} {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;

    max-width: 102px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;

  @media ${MEDIA_DEVICE.mobileL.to} {
    gap: 8px;
  }
`;

export const Count = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.color};
`;

export const BigIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  color: ${({ theme }) => theme.persianBlue};

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: 12px;
    height: 12px;
  }
`;

export const BigIconPost = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  color: ${({ theme }) => theme.persianBlue};

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

export const Tooltip = styled.div`
  position: relative;
  will-change: transform;
  transfrom: translate3d(0, 0, 0);

  &:hover::after,
  &:hover::before {
    content: '';
    position: absolute;
    bottom: 25px;
    left: 50%;
  }

  &:hover::after {
    content: attr(content);
    z-index: 1073;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    background: white;
    color: black;
    padding: 6px 8px;
    border-radius: 8px;
    white-space: nowrap;
    box-shadow:
      0px 38px 188px rgba(0, 0, 0, 0.09),
      0px 15.8755px 78.5419px rgba(0, 0, 0, 0.0646969),
      0px 8.4878px 41.9923px rgba(0, 0, 0, 0.0536497),
      0px 4.75819px 23.5405px rgba(0, 0, 0, 0.045),
      0px 2.52704px 12.5022px rgba(0, 0, 0, 0.0363503),
      0px 1.05156px 5.20244px rgba(0, 0, 0, 0.0253031);
  }
`;

export const TooltipLeft = styled.div`
  position: relative;
  will-change: transform;
  transfrom: translate3d(0, 0, 0);

  &:hover::after,
  &:hover::before {
    content: '';
    position: absolute;
    bottom: 25px;
    right: 50%;
  }

  &:hover::after {
    content: attr(title);
    z-index: 1073;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    background: white;
    color: black;
    padding: 6px 8px;
    border-radius: 8px;
    white-space: nowrap;
    box-shadow:
      0px 38px 188px rgba(0, 0, 0, 0.09),
      0px 15.8755px 78.5419px rgba(0, 0, 0, 0.0646969),
      0px 8.4878px 41.9923px rgba(0, 0, 0, 0.0536497),
      0px 4.75819px 23.5405px rgba(0, 0, 0, 0.045),
      0px 2.52704px 12.5022px rgba(0, 0, 0, 0.0363503),
      0px 1.05156px 5.20244px rgba(0, 0, 0, 0.0253031);
  }
`;
