import { HTMLAttributes } from 'react';

const linkProps: HTMLAttributes<HTMLAnchorElement> & { href: string } = {
  href: '#',
  style: { cursor: 'default', userSelect: 'none' }
};

export function replaceLinksInText(text: string | undefined) {
  if (!text) {
    return null;
  }

  // lookbehind doesnt work in older browsers, especially in safari.
  // substituted regexs doesnt implement exactly the same logic,
  // and may behave unexpectedly in some edge cases such as special
  // characters preceding the pattern of interest. But overall it should work fine
  // const urlRegex =
  //   /(?<!\S)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)(?!\S)/gi;
  // const emailRegex = /\S+@\S+\.\S+/gi;
  // const telegramRegex = /(?<![._-])@[a-zA-Z0-9_-]{5,32}(?![.\w-])/gi;
  // const breaksRegex = /\n/gi;
  const urlRegex = /(?:^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)(?!\S)/gi;
  const emailRegex = /\S+@\S+\.\S+/gi;
  const telegramRegex = /(?:[^._-])@[a-zA-Z0-9_-]{5,32}(?![.\w-])/gi;
  const breaksRegex = /\n/gi;

  const jsxElements = [];
  let lastIndex = 0;

  text.replace(
    new RegExp(
      `${urlRegex.source}|${emailRegex.source}|${telegramRegex.source}|${breaksRegex.source}`,
      'gi'
    ),
    (match) => {
      const offset = text.indexOf(match, lastIndex);
      const beforeText = text.substring(lastIndex, offset);
      jsxElements.push(beforeText);

      if (match.match(urlRegex)) {
        const url = match.includes('http') ? match : `//${match.trim()}`;
        jsxElements.push(
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key={offset} {...linkProps}>
            {match}
          </a>
        );
      } else if (match.match(emailRegex)) {
        jsxElements.push(
          <a key={offset} {...linkProps}>
            {match}
          </a>
        );
      } else if (match.match(telegramRegex)) {
        let username = match.slice(1);
        if (username.includes('@')) {
          // Если есть "@", заменяем "@" на пустую строку
          username = username.replace('@', '');
        }
        jsxElements.push(
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key={offset} {...linkProps}>
            {match}
          </a>
        );
      } else if (match.match(breaksRegex)) {
        jsxElements.push(<br key={offset} />);
      }

      lastIndex = offset + match.length;

      return '';
    }
  );

  if (lastIndex < text.length) {
    const remainingText = text.substring(lastIndex);
    jsxElements.push(remainingText);
  }

  return jsxElements;
}
