import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import * as S from './styles';

type Props = {
  startIcon?: ReactNode;
  startIconColor?: string;
  endIcon?: ReactNode;
  endIconColor?: string;
  size?: 'small' | 'medium' | 'large';
  status?: string | boolean;
} & HTMLAttributes<HTMLDivElement>;

const Tag: FC<PropsWithChildren<Props>> = ({
  children,
  status,
  endIcon,
  endIconColor,
  startIcon,
  size,
  startIconColor,
  ...restProps
}) => {
  return (
    <S.Root $size={size} {...restProps}>
      {status && <S.Status $color={typeof status === 'string' ? status : undefined} />}
      {startIcon && <S.IconWrapper color={startIconColor}>{startIcon}</S.IconWrapper>}
      {children}
      {endIcon && <S.IconWrapper color={endIconColor}>{endIcon}</S.IconWrapper>}
    </S.Root>
  );
};

export default memo(Tag);
