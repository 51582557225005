import { IconCircle } from 'fsd/shared/icons';
import { isDev } from 'fsd/shared/lib/featureFlags';
import { colNames } from 'fsd/shared/lib/helpers/constants/channelConstants';

import { TableDataContext } from '../../../lib/tableDataContext';
import SortableTitle from '../../SortableTitle';

const Title = () => {
  const tableDataContext = useContext(TableDataContext);
  return (
    tableDataContext && (
      <SortableTitle
        className="usiteful__column-price"
        icon={<IconCircle fill={theme.persianBlue} />}
        text={colNames.price}
        name="cpp"
        form={tableDataContext.form}
        values={tableDataContext.formValues}
      />
    )
  );
};

export default Title;
