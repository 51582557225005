import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { Input } from 'fsd/shared/ui/Input';
import { Select } from 'fsd/shared/ui/LegacyUIComponents';
import styled from 'styled-components';

export const SubmitWrapper = styled.div`
  padding: 24px 0;
`;

export const CurrencyOption = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* color: ${({ theme }) => theme.blackRock}; */

  .ant-select-selection-item:hover {
    color: ${({ theme }) => theme.persianBlue};
  }
`;

export const CurrencyWrapper = styled.div`
  padding: 4px;
`;

export const CurrencySelect = styled(Select)`
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.persianBlue}!important;
  }

  .ant-select-selector {
    background-color: transparent !important;
    outline: 4px solid transparent !important;
  }
`;

export const BudgetRow = styled.div`
  display: flex;
  gap: 16px;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 340px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media ${MEDIA_DEVICE.mobileL.to} {
    padding: 0;
    background: transparent;
  }
`;

export const SubjectItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
  color: ${({ active, theme }) => (active ? theme.delftBlue : 'transparent')};
  order: ${({ active }) => (active ? 0 : 1)};

  label {
    display: flex;
    gap: 8px;
  }
`;

export const PanelHeader = styled.span`
  color: ${({ theme }) => theme.blackRock};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: block;

  padding-top: 0;
  text-align: left;
`;

export const Label = styled.p`
  font-size: 12px;
  margin-bottom: 6px;
`;

export const Channel = styled.div`
  padding-bottom: 15px;
`;

export const SearchInput = styled(Input)`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  height: 36px;

  .ant-input-prefix {
    margin-right: 9px;
  }

  .ant-input {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ListInput = styled(Input)`
  height: 48px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 8px;
  margin-bottom: 14px;
`;

export const BudgetRowInput = styled(Input)`
  height: 48px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

export const SearchInputContainer = styled.div`
  padding: 4px;

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.alpineGoat};
  }
`;

export const SearchInputContainerInner = styled.div`
  margin: 8px 8px 12px 8px;
`;

export const PanelWrapper = styled.div`
  padding-top: 3px;
  text-align: left;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: initial;
  word-wrap: break-word;
`;

export const NoDataDescription = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.majesticMount};
`;

export const TabsWrapper = styled.div`
  width: 410px;
  background-color: ${({ theme }) => theme.white};
  border: none;
  box-shadow: 0px 4px 32px rgba(0, 16, 61, 0.16);
  border-radius: 8px;
  .ant-collapse {
    width: 100%;
    max-width: none !important;
  }
  @media ${MEDIA_DEVICE.mobileL.to} {
    width: calc(100vw - 32px);
    max-width: none;
  }
`;

export const NoValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 4px;

  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.alpineGoat};
`;

export const MyPurchaseText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  color: ${({ theme }) => theme.blackRock};
`;

export const NoValueText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  color: ${({ theme }) => theme.blackRock};
`;

export const NoValueDescription = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: ${({ theme }) => theme.blackRock};
`;
