import { FC } from 'react';

import useGetUserBalance from '../../lib/useGetUserBalance';
import { BalanceTypes } from '../../model/types';

import Info from '.';

type Props = {
  type: BalanceTypes;
};

const InfoActive: FC<Props> = ({ type }) => {
  const balance = useGetUserBalance();
  return <Info type={type} value={balance[type]} />;
};

export default memo(InfoActive);
