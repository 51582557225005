import { PropsWithChildren } from 'react';

import * as S from './styles';

const Header = forwardRef<HTMLTableSectionElement, PropsWithChildren>(
  ({ children, ...restProps }, ref) => {
    return (
      <S.Root {...restProps} ref={ref}>
        {children}
      </S.Root>
    );
  }
);

export default memo(Header);
