export const theme = {
  white: '#fff',
  alpineGoat: '#f2f3f7',
  maWhite: '#f5f5ff',
  sweetFlower: '#e2e2ea',
  violetPowder: '#c6cbd9',
  lilacHint: '#d0d0dA',
  paisley: '#70707c',
  bayFog: '#9a9aaf',
  majesticMount: '#7e7e8f',
  gunPowder: '#535362',
  londonGrey: '#656575',
  blackRock: '#2e2e3a',
  blackGrey: '#262631',
  reversedGrey: '#07070c',
  black: '#000000',
  darkGray: '#0F0F13',
  gray4: '#8A8A98',
  darkTetriary: '#1E1E24',
  darkTetriaryHover: '#40404A',
  cerulean: '#4ea4f6',
  brightGreek: '#323eff',
  delftBlue: '#3506ef',
  blueRibbon: '#0062ff',
  persianBlue: '#4807ea',
  primaryBtnHover: '#6C32FD',
  orangeBtnHover: '#EC722E',
  baingani: '#8676FF',
  venetianNights: '#7b61ff',
  indivioletSunset: '#a162f7',
  persianViolet: '#5200F3',
  hotspot: '#fd4438',
  mangoTango: '#ff8743',
  strawberryRed: '#db3031',
  bethlehemRed: '#eb001b',
  americanGreen: '#24ca49',
  americanGreenLight: '#EFFFE7',
  pastelGreen: '#72e78c',
  pinkDiminishing: '#fff4f1',
  violetPoint: '#8220FF',
  selectRow: '#F8F4FF',
  icyGray: '#F1F2F6',
  violetLight: '#F0E7FF',
  orange: '#FF7A2F',
  orangeLight: '#FCEEDA'
};

export type ThemeType = typeof theme;

// declare module "styled-components" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   export interface DefaultTheme extends ThemeType {}
// }

// https://colornamer.robertcooper.me/
