import { Collapse } from 'antd';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const BlockContainer = styled.div<{ pointClip?: boolean }>`
  background: ${({ theme }) => theme.white};
  box-shadow:
    0px -4px 64px rgba(0, 0, 0, 0.07),
    0px 100px 80px rgba(179, 175, 214, 0.07);
  border-radius: 20px;
  padding: 24px 24px 24px 24px;
  height: 100%;
  width: 100%;

  ${({ pointClip }) => pointClip && `padding: 24px 14px 16px 24px;`}
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media ${MEDIA_DEVICE.laptopL.to} {
    gap: 10px;
  }
`;

export const MetaInfoContainer = styled.div`
  width: calc(35% - 24px);

  @media ${MEDIA_DEVICE.laptopL.to} {
    width: calc(40% - 10px);
  }
`;

export const ChartContainer = styled.div`
  width: 65%;

  @media ${MEDIA_DEVICE.laptopL.to} {
    width: 60%;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    margin-top: 5px;
  }

  @media ${MEDIA_DEVICE.desktopS.from} {
    margin-top: 10px;
  }
`;

export const ChartContainerWithNotifications = styled.div`
  width: 65%;

  @media ${MEDIA_DEVICE.laptopL.to} {
    width: 60%;
  }
`;
export const ChartWrapper = styled.div`
  position: relative;
  height: calc(100% - 28px);
`;

export const TotalInfo = styled.div`
  margin-bottom: 32px;

  @media ${MEDIA_DEVICE.laptop.from} {
    gap: 8px;
    margin-bottom: 32px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    gap: 16px;
    margin-bottom: 24px;
  }
`;

export const TotalInfoWithERM = styled.div`
  margin-bottom: 32px;
  display: flex;
  width: 100%;

  @media ${MEDIA_DEVICE.laptop.from} {
    margin-bottom: 32px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    margin-bottom: 24px;
  }
`;

export const DetailedInfo = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 58% 45%;
  width: 100%;
  row-gap: 26px;
`;

export const Count = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  color: ${(props) => props.color};

  @media ${MEDIA_DEVICE.laptopL.from} {
    font-size: 20px;
  }
`;

export const HeaderDesciption = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

export const BigIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  color: ${({ theme }) => theme.persianBlue};

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  @media ${MEDIA_DEVICE.laptopS.to} {
    width: 14px;
    height: 14px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    width: 16px;
    height: 16px;
  }
`;

export const BigIconCalendar = styled(BigIcon)`
  @media ${MEDIA_DEVICE.laptop.from} {
    width: 16px;
    height: 16px;
  }
`;

export const BigIconTableParams = styled(BigIcon)`
  color: ${({ theme }) => theme.blueRibbon};
  @media ${MEDIA_DEVICE.laptop.from} {
    width: 14px;
    height: 14px;
  }
`;

export const BigIconTableParamsBlue = styled(BigIconTableParams)`
  color: ${({ theme }) => theme.persianBlue};
`;

export const BigIconTableParamsNotify = styled(BigIcon)`
  @media ${MEDIA_DEVICE.laptop.from} {
    width: 14px;
    height: 14px;
  }
`;

export const CountDesciption = styled.div`
  margin-top: 4px;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.gunPowder};

  @media ${MEDIA_DEVICE.laptop.from} {
    font-size: 14px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    font-size: 16px;
  }
`;

export const IconWrapper = styled.div``;

export const CountDesciptionLabel = styled.div`
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.gunPowder};

  @media ${MEDIA_DEVICE.laptop.from} {
    font-size: 14px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    font-size: 16px;
  }
`;

export const ChartFilter = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const InteractiveChartFilter = styled.div<{
  isActive: boolean;
  isDisabledToggle: boolean;
}>`
  margin-right: 8px;
  cursor: ${(props) => (props.isDisabledToggle ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  filter: ${(props) => (!props.isActive ? 'opacity(0.5) grayscale(1)' : '')};
`;

export const ChartFilterLabel = styled.span`
  margin-left: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: ${({ theme }) => theme.blackRock};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: 9px;
`;

export const TitleWithCalendar = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: 9px;
  justify-content: space-between;
`;

export const DetailedInfoItem = styled.div``;

export const ChartToggle = styled.div`
  display: flex;
`;

export const MonthOption = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.blackRock};
  border: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 12px 22px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.alpineGoat};
  }
`;

export const YearOption = styled(MonthOption)`
  padding: 16px 18px;
  display: block;
  width: 100%;
`;

export const DropdownRoot = styled.div`
  padding: 4px;
  width: 200px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 32px rgba(0, 16, 61, 0.16);
  border-radius: 8px;
`;

export const StyledCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &.ant-collapse > .ant-collapse-item {
    border-bottom: 0;
  }

  &.ant-collapse {
    background-color: ${({ theme }) => theme.white};
  }

  .ant-collapse-header-text {
    width: 100%;
    display: block;
  }
`;

export const CalendarContainer = styled.div`
  cursor: pointer;
`;

export const TotalNumber = styled.div`
  width: 58%;
`;

export const ErContainer = styled.div`
  width: 42%;
  display: flex;
  gap: 12px;
`;

export const ErDesciptionLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.gunPowder};

  @media ${MEDIA_DEVICE.laptop.from} {
    font-size: 14px;
  }

  @media ${MEDIA_DEVICE.laptopL.from} {
    font-size: 16px;
  }
`;

export const Pointer = styled.div`
  cursor: pointer;
`;
