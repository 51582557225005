import { ListContext } from '../../lib/listContext';

import Row from './Row';

import * as S from './styles';

const MobileRows = () => {
  const listData = useContext(ListContext);
  return (
    <S.List>
      {listData?.dataSource.map((data) => <Row key={data.container.container_id} record={data} />)}
    </S.List>
  );
};

export default memo(MobileRows);
