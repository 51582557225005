import { FC, PropsWithChildren } from 'react';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled, { css } from 'styled-components';

type Props = {
  width?: number;
  mobileWidth?: number;
  height?: number;
  mobileHeight?: number;
  color?: string;
  rotateAngle?: number;
  translateY?: number;
  inline?: boolean;
  style?: Record<string, unknown>;
  onClick?: (...args: any[]) => void;
  onMouseEnter?: (...args: any[]) => void;
  onMouseLeave?: (...args: any[]) => void;
};

const IconRoot = styled.div<Props>`
  line-height: 100%;
  width: ${({ width }) => width}px;
  flex: none;
  min-width: ${({ width }) => width}px;
  height: ${({ width, height }) => height || width}px;
  color: ${({ color }) => color ?? 'currentColor'};

  ${({ translateY }) =>
    translateY &&
    css`
      transition: 0.2s ease-in-out;
      transform: scaleY(${translateY});
    `}

  ${({ inline }) =>
    inline &&
    css`
      display: inline-block;
    `}

  ${({ rotateAngle }) =>
    rotateAngle &&
    css`
      transform: rotate(${rotateAngle}deg);
    `}

  svg {
    width: 100%;
    height: 100%;
    *path[fill] {
      fill: currentColor;
    }
  }

  @media ${MEDIA_DEVICE.mobileL.to} {
    width: ${({ mobileWidth, width }) => mobileWidth || width}px;
    min-width: ${({ mobileWidth, width }) => mobileWidth || width}px;

    height: ${({ mobileWidth, mobileHeight, width }) => mobileHeight || mobileWidth || width}px;
  }
`;

const Icon: FC<PropsWithChildren<Props>> = ({ width = 20, ...restProps }) => {
  return <IconRoot width={width} {...restProps} />;
};

export const IconAbsolute = styled(Icon)`
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default memo(Icon);
