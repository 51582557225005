import { FC } from 'react';

import { IMedia } from '../types';

import * as S from './styles';

type MediaProps = {
  media: IMedia;
};

// TODO: extend to accept video files
const Media: FC<MediaProps> = ({ media }) => {
  if (media.type === 'image') return <S.Image src={media.src} alt="" />;
  return <div />;
};

export { Media };
