import React, { FC, useEffect, useState } from 'react';
import { Channels, Id, useLazyGetV1ChannelsListQuery } from 'api/api';
import styled from 'styled-components';

import ChannelCard from './components/ChannelCard';

import * as S from './styles';

type Props = {
  channels: Id[];
  searchStr: string;
  onItemClick?(channelId: string): void;
  type: 'channel' | 'description';
  initiallyDisplayedNumber?: number;
};

const Root = styled.div`
  width: 100%;
`;

const SearchListWrapper: FC<Props> = ({
  channels,
  searchStr,
  onItemClick,
  type,
  initiallyDisplayedNumber = 8
}) => {
  const [getChannelList] = useLazyGetV1ChannelsListQuery();
  const [channelsData, setChannelsData] = useState<Channels>([]);
  const [channelsWithPaginator, setChannelsWithPaginator] = useState<Id[]>([]);
  const [channelPaginator, setChannelPaginator] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const pageSize = 5;

  useEffect(() => {
    if (channelsWithPaginator.length > 0) return;

    const isLongList = channels && channels.length > pageSize;
    const channelsBetween = isLongList ? channels.slice(0, initiallyDisplayedNumber) : channels;

    setChannelsWithPaginator(channelsBetween);

    getChannelList({
      ids: channelsBetween
    }).then((response) => {
      if (response.data?.channels) setChannelsData(response.data.channels);
    });

    setShowLoadMore(isLongList);
  }, [channels, pageSize, searchStr, channelsWithPaginator]);

  function loadMoreChannels() {
    if (channels) {
      const startIndex = initiallyDisplayedNumber + channelPaginator * pageSize;
      const endIndex = startIndex + pageSize;

      const channelsBetween = channels.slice(startIndex, endIndex);

      setChannelPaginator(channelPaginator + 1);
      setChannelsWithPaginator((prevChannels) => [...prevChannels, ...channelsBetween]);

      getChannelList({
        ids: channelsBetween
      }).then((response) => {
        if (response.data?.channels) {
          const channelsArr = response.data.channels;

          setChannelsData((prevChannels) => [...prevChannels, ...channelsArr]);
        }
      });

      if (endIndex >= channels.length) {
        setShowLoadMore(false);
      }
    }
  }

  return (
    <>
      <Root>
        {channelsWithPaginator.map((channelId) => {
          const channel = channelsData?.find((channel) => channel.id == channelId);

          return (
            <ChannelCard
              key={channelId}
              onItemClick={onItemClick}
              channelId={channelId}
              channel={channel}
              searchStr={searchStr}
              type={type}
            />
          );
        })}
      </Root>
      {showLoadMore && <S.LoadMore onClick={loadMoreChannels}>Загрузить еще</S.LoadMore>}
    </>
  );
};

export default SearchListWrapper;
