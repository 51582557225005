import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconCart } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import { theme } from 'theme/theme';

import { PurchaseMiniTabStyles } from '../Tab';

const PurchaseListTab: FC = () => {
  const navigate = useNavigate();

  return (
    <PurchaseMiniTabStyles.Root
      className="usiteful__purchases-tab"
      onClick={() => navigate('/campaigns')}
      style={{
        width: 200
      }}
    >
      <Icon width={20} color={theme.persianBlue}>
        <IconCart />
      </Icon>
      <PurchaseMiniTabStyles.Name>Мои кампании</PurchaseMiniTabStyles.Name>
    </PurchaseMiniTabStyles.Root>
  );
};

export default memo(PurchaseListTab);
