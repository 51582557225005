import { Channel } from 'api/api';
import { copyURL } from 'fsd/shared/lib/helpers/tools';

import * as S from './styles';

type Props = {
  channel: Channel;
  openActiveServiceTab: () => void;
  openNotActiveServiceTab: () => void;
  openActiveBrowserTab: () => void;
  openActiveBrowserWindow: () => void;
};

const ContextMenu = ({
  channel,
  openActiveServiceTab,
  openNotActiveServiceTab,
  openActiveBrowserTab,
  openActiveBrowserWindow
}: Props): any => {
  return [
    {
      label: (
        <S.ContextMenuItem>
          Открыть в активной вкладке сервиса
          <S.ContextMenuHotkeyText>
            <S.ContextHotkeyText style={{ paddingLeft: 6, paddingRight: 6 }}>
              LMB
            </S.ContextHotkeyText>
          </S.ContextMenuHotkeyText>
        </S.ContextMenuItem>
      ),
      onClick: () => openActiveServiceTab(),
      key: '1'
    },
    {
      label: (
        <S.ContextMenuItem>
          Открыть в неактивной вкладке сервиса
          <S.ContextMenuHotkeyText>
            <S.ContextHotkeyText>
              <S.MacosIcon>⌘</S.MacosIcon>
              <span> / Ctrl</span>
            </S.ContextHotkeyText>
            +{' '}
            <S.ContextHotkeyText style={{ paddingLeft: 6, paddingRight: 6 }}>
              LMB
            </S.ContextHotkeyText>{' '}
            or
            <S.ContextHotkeyText style={{ paddingLeft: 8, paddingRight: 8 }}>
              Scrl
            </S.ContextHotkeyText>
          </S.ContextMenuHotkeyText>
        </S.ContextMenuItem>
      ),
      onClick: () => {
        openNotActiveServiceTab();
      },
      key: '2'
    },
    {
      label: (
        <S.ContextMenuItem>
          Открыть в активной вкладке браузера
          <S.ContextMenuHotkeyText>
            <S.ContextHotkeyText>
              <S.MacosIcon>⌘</S.MacosIcon>
              <span> / Ctrl</span>
            </S.ContextHotkeyText>
            +{' '}
            <S.ContextHotkeyText style={{ paddingLeft: 6, paddingRight: 6 }}>
              Shift
            </S.ContextHotkeyText>{' '}
            +
            <S.ContextHotkeyText style={{ paddingLeft: 8, paddingRight: 8 }}>
              LMB
            </S.ContextHotkeyText>
          </S.ContextMenuHotkeyText>
        </S.ContextMenuItem>
      ),
      onClick: () => {
        openActiveBrowserTab();
      },
      key: '3'
    },
    {
      label: (
        <S.ContextMenuItem>
          Открыть в новом окне браузера
          <S.ContextMenuHotkeyText>
            <S.ContextHotkeyText style={{ paddingLeft: 4, paddingRight: 4 }}>
              Shift
            </S.ContextHotkeyText>
            <span>+</span>
            <S.ContextHotkeyText style={{ paddingLeft: 6, paddingRight: 6 }}>
              LMB
            </S.ContextHotkeyText>
          </S.ContextMenuHotkeyText>
        </S.ContextMenuItem>
      ),
      onClick: () => {
        openActiveBrowserWindow();
      },
      key: '4'
    },
    {
      label: <S.ContextMenuItem>Скопировать ссылку на канал в Tgmarket</S.ContextMenuItem>,
      onClick: () => {
        copyURL(
          undefined,
          `${window.location.origin}/channel/${channel.id}`,
          'Ссылка на канал скопирована'
        );
      },
      key: '5'
    }
  ];
};

export default ContextMenu;
