import styled from 'styled-components';

export const Text = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  letter-spacing: -0.144px;
  color: ${({ theme }) => theme.blackGrey};
`;
